import React from "react";
import { useSelector } from "react-redux";
import "../index.css";
import { Navigate } from "react-router-dom";
import App from "../App";

export default function PrivateRoutes() {
	const currentUserEmail = useSelector((state) => state.auth.currentUserEmail);
	return currentUserEmail != null ? <App /> : <Navigate to="/sign-in" replace />;
}
