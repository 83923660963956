/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { createRoot } from "react-dom/client";
import { Provider, useDispatch, useSelector } from "react-redux";
import { store } from "./redux/store";
import "./index.css";
import { ChakraProvider, Progress, useToast } from "@chakra-ui/react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import SignInPage from "./Pages/SignIn/SignIn";
import SignUpPage from "./Pages/SignUp/SignUp";
import NotFoundPage from "./Pages/NotFound/NotFoundPage";
import Dashboard from "./Pages/Dashboard/Dashboard";
import TenantDashboard from "./Pages/TenantPages/Dashboard";
import OwnerDashboard from "./Pages/OwnerPages/Dashboard";
import { config } from "./utils/config";
import { AWSAuth } from "./utils/auth";
import { signIn } from "./redux/auth/authSlice";
import Properties from "./Pages/Properties/Properties";
import Profile from "./Pages/Profile/Profile";
import PrivateRoutes from "./Components/PrivateRoutes";
import Subscription from "./Pages/Subscription/Subscription";
import theme from "./theme/theme";
import { getDashboardAsync } from "./redux/user/userSlice";
import { Amplify } from "aws-amplify";
import MassNotification from "./Pages/MassNotification/MassNotification";
import Tickets from "./Pages/Tickets/Tickets";
import Property from "./Components/PropertyModal/Property";
import FinancialStatus from "./Pages/FinancialStatus/FinancialStatus";
import Owner from "./Pages/Owner/Owner";
import Tenant from "./Pages/Tenant/Tenant";
import Utilities from "./Pages/TenantPages/Utilities/Utilities";
import TenantPayments from "./Pages/TenantPages/Payments/Payments";
import OwnerPayments from "./Pages/OwnerPages/Payments/Payments";
import OwnerFinancialStatus from "./Pages/OwnerPages/FinancialStatus/FinancialStatus";
import Outstanding from "./Pages/Outstanding/Outstanding";
import UtilitiesPage from "./Pages/Utilities/Utilities";
import TenantDocuments from "./Pages/TenantPages/Documents/Documents";
import TenantTickets from "./Pages/TenantPages/Tickets/Tickets";
import TenantProfile from "./Pages/TenantPages/Profile/Profile";

import SecurityDeposits from "./Pages/SecurityDeposits/SecurityDeposits";
import Documents from "./Pages/Documents/Documents";
import Statements from "./Pages/Statements/Statements";
import OwnerMessages from "./Pages/OwnerPages/Messages/Messages";
import TenantMessages from "./Pages/TenantPages/Messages/Messages";
import OwnerTickets from "./Pages/OwnerPages/Tickets/Tickets";
import OwnerDocuments from "./Pages/OwnerPages/Documents/Documents";
import "./utils/i18n";
import { getAccType } from "./utils/utils";
import UserNotFound from "./Pages/UserNotFound/UserNotFound";
import TermsAndContitions from "./Pages/TermsAndConditions/TermsAndContitions";
import CustomOwnerPlans from "./Pages/OwnerPages/CustomOwnerPlans/CustomOwnerPlans";
const container = document.getElementById("root");
const root = createRoot(container);
const awsconfig = config.awsConfig;
Amplify.configure(awsconfig);

root.render(
	<ChakraProvider theme={theme}>
		<Provider store={store}>
			<Router>
				<RoutesWrapper>
					<Route path="/sign-in" element={<SignInPage />} />
					<Route path="/sign-up" element={<SignUpPage />} />
					<Route path="*" element={<NotFoundPage />} />
				</RoutesWrapper>
			</Router>
		</Provider>
	</ChakraProvider>
);
export function RoutesWrapper({ children }) {
	const dispatch = useDispatch();
	const status = useSelector((state) => state.auth.status);

	const currentUserEmail = useSelector((state) => state.auth.currentUserEmail);
	const accountType = useSelector((state) => state.user.user?.type);

	const toast = useToast();

	useEffect(() => {
		if (currentUserEmail) {
			// navigate("/");
			console.log("Getting dashboard info for user ", currentUserEmail);
			dispatch(getDashboardAsync())
				.then((r) => {
					console.log("User dashboard info: ", r.payload);
					if (r.error?.message)
						toast({
							title: "Error encountered",
							description: r.error.message,
							status: "error",
							duration: 9000,
							isClosable: true,
						});
					else {
						const accType = r.payload?.type;
						document.title = `${getAccType(accType)} | RentIt`;
						toast({
							title: "Success!",
							description: "Dashboard info success",
							status: "success",
							duration: 2000,
							isClosable: true,
						});
					}
				})
				.catch((e) => {
					console.log(e);
					toast({
						title: "Error encountered",
						description: e.message,
						status: "error",
						duration: 9000,
						isClosable: true,
					});
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentUserEmail]);

	useEffect(() => {
		AWSAuth.currentAuthenticatedUser()
			.then((user) => {
				console.log(user);
				const r = {
					email: user.attributes.email,
					idToken: user.signInUserSession.idToken.jwtToken,
					authorization: user.signInUserSession.accessToken.jwtToken,
				};
				dispatch(signIn(r));
			})
			.catch((e) => {
				console.log(e);
			});
	}, [AWSAuth]);
	return (
		<>
			<Progress
				size="xs"
				isIndeterminate
				hidden={status === "idle"}
				style={{
					position: "absolute",
					top: 0,
					left: 0,
					width: "100%",
				}}
			/>
			<Routes>
				<Route element={<PrivateRoutes />}>
					<Route path="/" element={<Navigate to="/dashboard" replace />} />

					{/* Pages with agent account type access */}
					{(accountType === "agent" || accountType === "agency") && (
						<>
							<Route path="terms-and-conditions" element={<TermsAndContitions />} />
							<Route path="dashboard" element={<Dashboard />} />
							<Route path="mass-notification" element={<MassNotification />} />
							<Route path="tickets" element={<Tickets />} />
							<Route path="properties" element={<Properties />} />
							<Route path="outstanding" element={<Outstanding />} />
							<Route path="documents" element={<Documents />} />
							<Route path="utilities" element={<UtilitiesPage />} />
							<Route path="profile" element={<Profile />} />
							<Route path="subscription" element={<Subscription />} />
							<Route path="properties/view/:id" element={<Property />} />
							<Route path="owner/view/:id" element={<Owner />} />
							<Route path="tenant/view/:propertyId/:tenantId" element={<Tenant />} />
							<Route path="financial-status" element={<FinancialStatus />} />
							<Route path="statements" element={<Statements />} />
							<Route path="security-deposits" element={<SecurityDeposits />} />
						</>
					)}
					{/* Pages with tennant account type access */}
					{accountType === "tenant" && (
						<>
							<Route path="terms-and-conditions" element={<TermsAndContitions />} />
							<Route path="dashboard" element={<TenantDashboard />} />
							<Route path="utilities" element={<Utilities />} />
							<Route path="payments" element={<TenantPayments />} />
							<Route path="documents" element={<TenantDocuments />} />
							<Route path="tickets" element={<TenantTickets />} />
							<Route path="messages" element={<TenantMessages />} />
							<Route path="profile" element={<TenantProfile />} />
						</>
					)}
					{/* Pages with owner account access */}
					{accountType === "owner" && (
						<>
							<Route path="terms-and-conditions" element={<TermsAndContitions />} />
							<Route path="dashboard" element={<OwnerDashboard />} />
							<Route path="financial-status" element={<OwnerFinancialStatus />} />
							<Route path="payments" element={<OwnerPayments />} />
							<Route path="documents" element={<OwnerDocuments />} />
							<Route path="tickets" element={<OwnerTickets />} />
							<Route path="messages" element={<OwnerMessages />} />
							<Route path="custom-owner-plans" element={<CustomOwnerPlans />} />
						</>
					)}
					{accountType === "notAssigned" && <Route path="*" element={<UserNotFound />} />}
				</Route>
				{children}
			</Routes>
		</>
	);
}
