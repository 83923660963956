import React from "react";
import { Card, CardBody, CardHeader, Heading, List, ListItem, Text } from "@chakra-ui/react";

export default function TermsAndConditions() {
	return (
		<Card>
			<CardHeader>
				<Heading as="h2" size="lg">
					Terms and Conditions
				</Heading>
			</CardHeader>
			<CardBody>
				<Text mb="4">
					These terms and conditions ("Terms") govern your use of the Rental Property Web App (the "App") as a Tenant or Owner. By accessing or using the App,
					you agree to be bound by these Terms. Please read these Terms carefully before using the App.
				</Text>

				<Heading as="h2" size="md" mb="2">
					1. Acceptance of Terms
				</Heading>
				<Text mb="4">By accessing or using the App, you agree to be bound by these Terms. If you do not agree to these Terms, you may not use the App.</Text>

				<Heading as="h2" size="md" mb="2">
					2. Use of the App
				</Heading>
				<Text mb="4">
					<strong>2.1. Eligibility:</strong> By using the App, you affirm that you are at least 18 years of age and are fully able and competent to enter into
					the terms, conditions, obligations, affirmations, representations, and warranties set forth in these Terms.
				</Text>
				<Text mb="4">
					<strong>2.2. User Accounts:</strong> To access certain features of the App, you may be required to create a user account. You are responsible for
					maintaining the confidentiality of your account credentials and for all activities that occur under your account.
				</Text>
				<Text mb="4">
					<strong>2.3. Accuracy of Information:</strong> You agree to provide accurate, current, and complete information during the registration process and to
					update such information to keep it accurate, current, and complete.
				</Text>
				<Text mb="4">
					<strong>2.4. Prohibited Activities:</strong> You agree not to engage in any of the following prohibited activities:
					<List styleType="disc" ml="4">
						<ListItem>Violating any laws or regulations;</ListItem>
						<ListItem>Impersonating any person or entity or falsely stating or otherwise misrepresenting your affiliation with a person or entity;</ListItem>
						<ListItem>Interfering with or disrupting the operation of the App;</ListItem>
						<ListItem>Attempting to gain unauthorized access to the App or its related systems or networks;</ListItem>
						<ListItem>Using the App for any commercial purpose without our prior written consent.</ListItem>
					</List>
				</Text>

				{/* Add other sections similarly */}

				<Heading as="h2" size="md" mb="2">
					3. Rental Agreements
				</Heading>
				<Text mb="4">
					<strong>3.1. Tenant Agreements:</strong> As a Tenant, you agree to abide by the terms of any rental agreements entered into with Owners through the
					App, including payment of rent and adherence to property rules and regulations.
				</Text>
				<Text mb="4">
					<strong>3.2. Owner Agreements:</strong> As an Owner, you agree to provide accurate information about your rental properties and to abide by any rental
					agreements entered into with Tenants through the App.
				</Text>

				<Heading as="h2" size="md" mb="2">
					4. Payments and Fees
				</Heading>
				<Text mb="4">
					<strong>4.1. Payment Processing:</strong> Payments made through the App are processed securely using third-party payment processors. By using the App,
					you agree to abide by the terms and conditions of these payment processors.
				</Text>
				<Text mb="4">
					<strong>4.2. Fees:</strong> We may charge fees for certain features or services offered through the App. Any such fees will be clearly disclosed to
					you prior to your use of the feature or service.
				</Text>

				<Heading as="h2" size="md" mb="2">
					5. Privacy
				</Heading>
				<Text mb="4">
					<strong>5.1. Privacy Policy:</strong> Your use of the App is subject to our Privacy Policy, which governs the collection, use, and disclosure of your
					personal information. By using the App, you consent to the terms of our Privacy Policy.
				</Text>

				<Heading as="h2" size="md" mb="2">
					6. Termination
				</Heading>
				<Text mb="4">
					<strong>6.1. Termination by Us:</strong> We reserve the right to terminate or suspend your access to the App at any time and for any reason without
					prior notice.
				</Text>
				<Text mb="4">
					<strong>6.2. Termination by You:</strong> You may terminate your account at any time by contacting us or using the account termination feature in the
					App.
				</Text>

				<Heading as="h2" size="md" mb="2">
					7. Disclaimer of Warranties
				</Heading>
				<Text mb="4">
					<strong>7.1. No Warranties:</strong> The App is provided on an "as is" and "as available" basis, without any warranties of any kind, either express or
					implied.
				</Text>

				<Heading as="h2" size="md" mb="2">
					8. Limitation of Liability
				</Heading>
				<Text mb="4">
					<strong>8.1. Limitation of Liability:</strong> In no event shall we be liable for any indirect, incidental, special, consequential, or punitive
					damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, arising out of or in connection with your use
					of the App.
				</Text>

				<Heading as="h2" size="md" mb="2">
					9. Governing Law
				</Heading>
				<Text mb="4">
					<strong>9.1. Governing Law:</strong> These Terms shall be governed by and construed in accordance with the laws of [jurisdiction], without regard to
					its conflict of law principles.
				</Text>

				<Heading as="h2" size="md" mb="2">
					10. Changes to Terms
				</Heading>
				<Text mb="4">
					<strong>10.1. Changes:</strong> We reserve the right to modify these Terms at any time. Any changes to these Terms will be effective immediately upon
					posting on the App. Your continued use of the App after the posting of any revised Terms constitutes your acceptance of such changes.
				</Text>

				<Heading as="h2" size="md" mb="2">
					11. Contact Us
				</Heading>
				<Text mb="4">
					<strong>11.1. Contact:</strong> If you have any questions about these Terms, please contact us at [contact email or address].
				</Text>
			</CardBody>
		</Card>
	);
}
