import {
	Box,
	Button,
	Stack,
	useDisclosure,
	Modal,
	ModalOverlay,
	ModalHeader,
	ModalContent,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	Table,
	Tbody,
	Text,
	Th,
	Thead,
	Tr,
	FormControl,
	FormLabel,
	Input,
	Select,
	Grid,
	GridItem,
	Card,
	CardHeader,
	CardBody,
	Icon,
	Flex,
	WrapItem,
	Wrap,
	Image,
	Checkbox,
	useToast,
	Tooltip,
	Divider,
	IconButton,
	FormHelperText,
	Badge,
	Textarea,
} from "@chakra-ui/react";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";

import React, { useEffect, useState } from "react";
import Dropzone from "../Dropzone/Dropzone";
import { useDispatch, useSelector } from "react-redux";
import { addUtilityPaymentAsync, markMultiplePaymentsAsPaidAsync, requestMeterPictureAsync, updateMeterInformationAsync } from "../../redux/user/userSlice";
import UtilityMeterTableRow from "../TableRows/UtilityMeterTableRow";
import moment from "moment";
import { GiWaterDrop } from "react-icons/gi";
import { AiFillFire } from "react-icons/ai";
import { BsFillLightningChargeFill } from "react-icons/bs";
import ImageViewer from "react-simple-image-viewer";
import { useCallback } from "react";
import { API } from "../../utils/http";
import { makrMultiplePAymentsAsPaidVerifier, getPropertyAddress, addUtilityMeterVerifier } from "../../utils/verifier";
import InvoicesRow from "../TableRows/InvoicesRow";
import { AddIcon, EditIcon } from "@chakra-ui/icons";
import { getPaymentStatus, getPaymentStatusColor, getRequestPeriodBadge } from "../../utils/utils";
import { fullDateTimeFormat } from "../../utils/types";
import { useTranslation } from "react-i18next";
function _arrayBufferToBase64(buffer) {
	var binary = "";
	var bytes = new Uint8Array(buffer);
	var len = bytes.byteLength;
	for (var i = 0; i < len; i++) {
		binary += String.fromCharCode(bytes[i]);
	}
	return window.btoa(binary);
}
export default function UtilityTab({ property }) {
	const isArchived = property.archived;
	const { t } = useTranslation();
	// const loading = useSelector((state) => state.user.status) === "loading";
	const [loading, setLoading] = useState(false);
	const initialMeterInformation = { propertyId: property.id, meterType: "", meterIndex: "" };
	const initialEditMeterInformation = { propertyId: property.id, meterType: "", meter: { requestPeriod: {} } };
	const initialUtilityInvoiceInformation = {
		propertyId: property.id,
		utilityType: "",
		dueDate: "",
		amount: "",
		currency: "ron",
		invoice: { invoiceData: "", contentType: "", documentName: "" },
	};
	const initialRequestMeterPictureInformation = {
		gas: false,
		water: false,
		electricity: false,
	};
	const [availableUtilitiesInformation, setAvailableUtilitiesInformation] = useState(property.utilities.availableUtilities);
	const [utilityInvoiceInformation, setUtilityInvoiceInformation] = useState(initialUtilityInvoiceInformation);
	const [meterInformation, setMeterInformation] = useState(initialMeterInformation);
	const [requestMeterPictureInformation, setRequestMeterPictureInformation] = useState(initialRequestMeterPictureInformation);
	const [editMeterInformation, setEditMeterInformation] = useState(initialEditMeterInformation);
	const { isOpen: isMeterOpen, onOpen: onMeterOpen, onClose: onMeterClose } = useDisclosure();
	const { isOpen: isInvoiceOpen, onOpen: onInvoiceOpen, onClose: onInvoiceClose } = useDisclosure();
	const { isOpen: isHistoryOpen, onOpen: onHistoryOpen, onClose: onHistoryClose } = useDisclosure();
	const { isOpen: isRequestMeterOpen, onOpen: onRequestMeterOpen, onClose: onRequestMeterClose } = useDisclosure();
	const { isOpen: isEditMeterOpen, onOpen: onEditMeterOpen, onClose: onEditMeterClose } = useDisclosure();
	const [currentImage, setCurrentImage] = useState(0);
	const [isViewerOpen, setIsViewerOpen] = useState(false);
	const [images, setImages] = useState([]);
	const [query, setquery] = useState("");
	const utilityPayments = property.payments
		.filter((payment) => payment.type.toLowerCase().includes("utility"))
		.filter((payment) => {
			return (
				payment.type.toLowerCase().includes(query.toLowerCase()) ||
				payment.amount.toLowerCase().includes(query.toLowerCase()) ||
				payment.paymentMethod.toLowerCase().includes(query.toLowerCase())
			);
		});
	const [error, setError] = useState({});
	const toast = useToast();
	const handleSearchChange = (e) => {
		setquery(e.target.value);
	};
	const openImageViewer = useCallback((index) => {
		setCurrentImage(index);
		setIsViewerOpen(true);
	}, []);
	const closeImageViewer = () => {
		setCurrentImage(0);
		setIsViewerOpen(false);
	};
	const handleRequestMeterPictureChange = (e) => {
		setRequestMeterPictureInformation({ ...requestMeterPictureInformation, [e.target.name]: e.target.checked });
	};
	const handleChangeCheckboxes = (e) => {
		setAvailableUtilitiesInformation({ ...availableUtilitiesInformation, [e.target.name]: e.target.checked });
	};
	const saveCheckboxes = () => {
		const payload = { propertyId: property.id, availableUtilities: availableUtilitiesInformation };
		console.log(payload);
		dispatch(updateMeterInformationAsync(payload))
			.then((r) => {
				if (r.error?.message)
					toast({
						title: "Error encountered",
						description: r.error.message,
						status: "error",
						duration: 9000,
						isClosable: true,
					});
				else {
					toast({
						title: "Success!",
						description: "Update successful",
						status: "success",
						duration: 2000,
						isClosable: true,
					});
				}
			})
			.finally(() => {});
	};
	useEffect(() => {
		console.log("Getting meter pictures urls");
		const payload = { propertyId: property.id, media: property.utilities.meterPictureHistory };
		API.put("get-meter-picture-urls", payload).then((r) => {
			setImages(r.data.media);
		});
	}, [property.utilities.meterPictureHistory, property.id]);
	const dispatch = useDispatch();
	const saveMeterInformation = () => {
		console.log(editMeterInformation);

		dispatch(updateMeterInformationAsync(editMeterInformation))
			.then((r) => {
				if (r.error?.message)
					toast({
						title: "Error encountered",
						description: r.error.message,
						status: "error",
						duration: 9000,
						isClosable: true,
					});
				else {
					toast({
						title: "Success!",
						description: "Update successful",
						status: "success",
						duration: 2000,
						isClosable: true,
					});
				}
			})
			.finally(() => {
				onEditMeterClose();
				setEditMeterInformation(initialEditMeterInformation);
			});
	};
	const addUtilityMeter = () => {
		setLoading(true);
		console.log("added meter");
		console.log(meterInformation);
		const errors = addUtilityMeterVerifier(meterInformation);
		if (Object.entries(errors).length !== 0) {
			setError(errors);
			toast({
				title: "Error encountered.",
				description: "Invalid information",
				status: "error",
				duration: 2000,
				isClosable: true,
			});
			return;
		}
		setError({});
		//TODO: add meter info to database
		dispatch(updateMeterInformationAsync(meterInformation))
			.then((r) => {
				if (r.error?.message)
					toast({
						title: "Error encountered",
						description: r.error.message,
						status: "error",
						duration: 9000,
						isClosable: true,
					});
				else {
					toast({
						title: "Success!",
						description: "Update successful",
						status: "success",
						duration: 2000,
						isClosable: true,
					});
				}
			})
			.finally(() => {
				onMeterClose();
				setMeterInformation(initialMeterInformation);
				setLoading(false);
			});
	};
	const addUtilityInvoice = () => {
		setLoading(true);
		console.log("add utility invoice");
		//TODO: call api and dispatch
		console.log(utilityInvoiceInformation);
		const errors = addUtilityMeterVerifier(utilityInvoiceInformation);
		if (Object.entries(errors).length !== 0) {
			setError(errors);
			toast({
				title: "Error encountered.",
				description: "Invalid information",
				status: "error",
				duration: 2000,
				isClosable: true,
			});
			return;
		}
		setError({});
		dispatch(addUtilityPaymentAsync(utilityInvoiceInformation))
			.then((r) => {
				if (r.error?.message)
					toast({
						title: "Error encountered",
						description: r.error.message,
						status: "error",
						duration: 9000,
						isClosable: true,
					});
				else {
					toast({
						title: "Success!",
						description: "Invoice Added!",
						status: "success",
						duration: 2000,
						isClosable: true,
					});
				}
			})
			.finally(() => {
				onInvoiceClose();
				setUtilityInvoiceInformation(initialUtilityInvoiceInformation);
				setLoading(false);
			});
	};

	const { isOpen: isMarkMultiplePaymentsAsPaidOpen, onOpen: onMarkMultiplePaymentsAsPaidOpen, onClose: onMarkMultiplePaymentsAsPaidClose } = useDisclosure();
	const initialMultiplePaymentsAsPaidInformation = {
		payments: [],
		document: {},
		paymentDate: moment().format("yyyy-MM-DD"),
		documentNumber: "",
		paymentMethod: "op",
		billedTo: {},
	};
	const [markMultiplePaymentsAsPaidInformation, setMarkMultiplePaymentsAsPaidInformation] = useState(initialMultiplePaymentsAsPaidInformation);
	const handleBilledToChange = (e) => {
		const billedToEmail = e.target.value;
		console.log("email: ", billedToEmail);
		if (property.owner.email === billedToEmail) {
			setMarkMultiplePaymentsAsPaidInformation({
				...markMultiplePaymentsAsPaidInformation,
				billedTo: { name: property.owner.name, email: billedToEmail, type: "owner" },
			});
			return;
		}
		if (property.contacts.find((c) => c.email === billedToEmail)) {
			const contact = property.contacts.find((c) => c.email === billedToEmail);
			console.log(contact);
			setMarkMultiplePaymentsAsPaidInformation({
				...markMultiplePaymentsAsPaidInformation,
				billedTo: { name: contact.name, email: billedToEmail, type: contact.contactType },
			});
			return;
		}
		if (property.leaseContacts.find((c) => c.id === billedToEmail)) {
			const contact = property.leaseContacts.find((c) => c.id === billedToEmail);
			console.log(contact);
			setMarkMultiplePaymentsAsPaidInformation({
				...markMultiplePaymentsAsPaidInformation,
				billedTo: { name: contact.name, email: billedToEmail, type: contact.contactType },
			});
			return;
		}
		if (property.tenants.find((c) => c.email === billedToEmail)) {
			const tenant = property.tenants.find((c) => c.email === billedToEmail);
			console.log(tenant);
			setMarkMultiplePaymentsAsPaidInformation({
				...markMultiplePaymentsAsPaidInformation,
				billedTo: { name: tenant.name, email: billedToEmail, type: "tenant" },
			});
			return;
		}
	};
	const handleMarkMultiplePaymentsAsPaidChange = (e) => {
		console.log(e.target.value);
		if (e.target.checked) {
			setMarkMultiplePaymentsAsPaidInformation({
				...markMultiplePaymentsAsPaidInformation,
				payments: [...markMultiplePaymentsAsPaidInformation.payments, e.target.value],
			});
		} else {
			setMarkMultiplePaymentsAsPaidInformation({
				...markMultiplePaymentsAsPaidInformation,
				payments: markMultiplePaymentsAsPaidInformation.payments.filter((item) => item !== e.target.value),
			});
		}
	};
	const handleChangeMarkMultiplePaymentsAsPaidInformation = (e) => {
		setMarkMultiplePaymentsAsPaidInformation({ ...markMultiplePaymentsAsPaidInformation, [e.target.name]: e.target.value });
	};
	const onDocumentDrop = async (acceptedFiles) => {
		console.log(acceptedFiles);
		const buffer = await acceptedFiles[0].arrayBuffer();
		const name = acceptedFiles[0].name;
		const contentType = acceptedFiles[0].type;
		const document = { documentData: _arrayBufferToBase64(buffer), documentName: name, contentType };
		setMarkMultiplePaymentsAsPaidInformation({ ...markMultiplePaymentsAsPaidInformation, document });
	};
	const markMultiplePaymentsAsPaid = () => {
		setLoading(true);
		console.log("mark multiple payments as paid");
		const payload = {
			propertyId: property.id,
			...markMultiplePaymentsAsPaidInformation,
		};
		console.log(payload);
		const errors = makrMultiplePAymentsAsPaidVerifier(markMultiplePaymentsAsPaidInformation);
		console.log(errors);
		if (Object.entries(errors).length !== 0) {
			setError(errors);
			toast({
				title: "Error encountered.",
				description: "Invalid information",
				status: "error",
				duration: 2000,
				isClosable: true,
			});
			return;
		}
		setError({});
		dispatch(markMultiplePaymentsAsPaidAsync(payload))
			.then((r) => {
				if (r.error?.message)
					toast({
						title: "Error encountered",
						description: r.error.message,
						status: "error",
						duration: 9000,
						isClosable: true,
					});
				else {
					toast({
						title: "Success!",
						description: "Update successful",
						status: "success",
						duration: 2000,
						isClosable: true,
					});
				}
			})
			.finally(() => {
				onMarkMultiplePaymentsAsPaidClose();
				setMarkMultiplePaymentsAsPaidInformation(initialMultiplePaymentsAsPaidInformation);
				setLoading(false);
			});
	};
	const markMultiplePaymentsAsPaidModal = () => {
		return (
			<Modal isOpen={isMarkMultiplePaymentsAsPaidOpen} onClose={onMarkMultiplePaymentsAsPaidClose} size={"xl"}>
				<ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
				<ModalContent>
					<ModalHeader>
						<Text>Mark Multiple Payments as Paid</Text>
					</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Stack>
							<FormControl>
								<FormLabel display={"inline"}>Payments:</FormLabel>
								<Stack>
									{utilityPayments
										.filter((p) => {
											return !p.paymentDate;
										})
										.map((payment, index) => {
											const paymentStatus = getPaymentStatus({ paymentDate: payment.paymentDate, dueDate: payment.dueDate });
											return (
												<Checkbox
													key={index}
													name={payment.id}
													onChange={handleMarkMultiplePaymentsAsPaidChange}
													value={payment.id}
													isChecked={markMultiplePaymentsAsPaidInformation.payments.some((item) => item === payment.id)}
												>
													{/* payment checkbox text has to include type, amount, currency, issuedDate and due date */}
													<Stack>
														<Text>
															{payment.type} - {payment.amount} {payment.currency} -{" "}
															<Badge colorScheme={getPaymentStatusColor(paymentStatus)}>{paymentStatus}</Badge> -{" "}
															{moment(payment.dueDate).format("DD MMM YYYY")}
														</Text>
													</Stack>
												</Checkbox>
											);
										})}
								</Stack>
								{error?.payments && <FormHelperText color="red">{error.payments}</FormHelperText>}
							</FormControl>
							<FormControl>
								<FormLabel display={"inline"}>Paid Date:</FormLabel>
								<Input
									width={"fit-content"}
									type="date"
									name="paymentDate"
									placeholder="Payment Date"
									_placeholder={{ color: "gray.500" }}
									value={markMultiplePaymentsAsPaidInformation.paymentDate}
									onChange={handleChangeMarkMultiplePaymentsAsPaidInformation}
								/>
							</FormControl>
							<FormControl>
								<FormLabel display={"inline"}>Document Number:</FormLabel>
								<Input
									isInvalid={error?.documentNumber}
									width={"fit-content"}
									type="text"
									name="documentNumber"
									placeholder="Document Number"
									_placeholder={{ color: "gray.500" }}
									value={markMultiplePaymentsAsPaidInformation.documentNumber}
									onChange={handleChangeMarkMultiplePaymentsAsPaidInformation}
								/>
							</FormControl>
							<FormControl display={"flex"} alignItems="center">
								<FormLabel display={"inline"}>Method:</FormLabel>
								<Select
									width={"fit-content"}
									onChange={handleChangeMarkMultiplePaymentsAsPaidInformation}
									type="text"
									name="paymentMethod"
									_placeholder={{ color: "gray.500" }}
									value={markMultiplePaymentsAsPaidInformation.paymentMethod}
								>
									<option value="op">OP</option>
									<option value="cash">Cash</option>
									<option value="personal">Personal</option>
								</Select>
							</FormControl>
							<FormControl display={"flex"} alignItems="center">
								<FormLabel display={"inline"}>Billed To:</FormLabel>
								<Select width={"fit-content"} onChange={handleBilledToChange} name="billedTo" placeholder="Billed To" _placeholder={{ color: "gray.500" }}>
									<option value={property.owner.email}>{property.owner.name}</option>
									{property.tenants?.map((c, index) => (
										<option key={index} value={c.email}>
											{c.name}
										</option>
									))}
									{property.contacts?.map((c, index) => (
										<option key={index} value={c.email}>
											{c.name}
										</option>
									))}
									{property.leaseContacts?.map((c, index) => (
										<option key={index} value={c.id}>
											{c.name}
										</option>
									))}
								</Select>
							</FormControl>
							<FormControl>
								<FormLabel display={"inline"}>Billed To Name:</FormLabel>
								<Input
									isInvalid={error?.billedToName}
									width={"fit-content"}
									type="text"
									name="name"
									placeholder="Name"
									_placeholder={{ color: "gray.500" }}
									value={markMultiplePaymentsAsPaidInformation.billedTo.name}
									onChange={(e) => {
										setMarkMultiplePaymentsAsPaidInformation({
											...markMultiplePaymentsAsPaidInformation,
											billedTo: { ...markMultiplePaymentsAsPaidInformation.billedTo, name: e.target.value },
										});
									}}
								/>
							</FormControl>
							<FormControl>
								<FormLabel display={"inline"}>Billed To Type:</FormLabel>
								<Input
									isInvalid={error?.billedToType}
									width={"fit-content"}
									type="text"
									name="type"
									placeholder="Type"
									_placeholder={{ color: "gray.500" }}
									value={markMultiplePaymentsAsPaidInformation.billedTo.type}
									onChange={(e) => {
										setMarkMultiplePaymentsAsPaidInformation({
											...markMultiplePaymentsAsPaidInformation,
											billedTo: { ...markMultiplePaymentsAsPaidInformation.billedTo, type: e.target.value },
										});
									}}
								/>
							</FormControl>
							<FormControl>
								<FormLabel display={"inline"}>Description:</FormLabel>
								<Textarea
									isInvalid={error?.description}
									width={"fit-content"}
									type="text"
									name="description"
									placeholder="Description"
									_placeholder={{ color: "gray.500" }}
									value={markMultiplePaymentsAsPaidInformation.description}
									onChange={handleChangeMarkMultiplePaymentsAsPaidInformation}
								/>
							</FormControl>
							{/* document upload */}
							<FormControl>
								<FormLabel>Document:</FormLabel>
								<Dropzone
									onDrop={onDocumentDrop}
									accept={{
										"application/pdf": [".pdf"],
										"application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
										"application/msword": [".doc"],
									}}
									description="Upload PDF,DOC or DOCX Format"
									maxFiles={1}
								/>
							</FormControl>
						</Stack>
					</ModalBody>
					<ModalFooter>
						<Button
							isLoading={loading}
							colorScheme="red"
							onClick={() => {
								onMarkMultiplePaymentsAsPaidClose();
								setMarkMultiplePaymentsAsPaidInformation(initialMultiplePaymentsAsPaidInformation);
							}}
							mr={3}
						>
							{t("misc.cancel")}
						</Button>
						<Button colorScheme="blue" onClick={markMultiplePaymentsAsPaid} isLoading={loading}>
							Mark as Paid
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		);
	};
	const requestMeterPicture = () => {
		const payload = { meters: requestMeterPictureInformation, propertyId: property.id };
		console.log(payload);

		dispatch(requestMeterPictureAsync(payload))
			.then((r) => {
				if (r.error?.message)
					toast({
						title: "Error encountered",
						description: r.error.message,
						status: "error",
						duration: 9000,
						isClosable: true,
					});
				else
					toast({
						title: "Request sent.",
						description: "Request sent.",
						status: "info",
						duration: 2000,
						isClosable: true,
					});
			})
			.finally(() => {
				onRequestMeterClose();
				setRequestMeterPictureInformation(initialRequestMeterPictureInformation);
			});
	};
	const onInvoiceDrop = async (acceptedFiles) => {
		console.log(acceptedFiles);
		const buffer = await acceptedFiles[0].arrayBuffer();
		const name = acceptedFiles[0].name;
		const contentType = acceptedFiles[0].type;
		const invoice = { invoiceData: _arrayBufferToBase64(buffer), documentName: name, contentType };
		setUtilityInvoiceInformation({ ...utilityInvoiceInformation, invoice });
	};

	const handleUtilityMeterChange = (e) => {
		setMeterInformation({ ...meterInformation, [e.target.name]: e.target.value });
	};
	const handleEditMeterChange = (e) => {
		setEditMeterInformation({ ...editMeterInformation, meter: { ...editMeterInformation.meter, [e.target.name]: e.target.value } });
	};
	const handleEditMeterRequestPeriodChange = (e) => {
		setEditMeterInformation({
			...editMeterInformation,
			meter: { ...editMeterInformation.meter, requestPeriod: { ...editMeterInformation.meter.requestPeriod, [e.target.name]: e.target.value } },
		});
	};
	const handleUtilityInvoiceChange = (e) => {
		console.log(e.target.value);
		setUtilityInvoiceInformation({ ...utilityInvoiceInformation, [e.target.name]: e.target.value });
	};
	const renderAddUtilityInvoiceModal = () => (
		<Modal isOpen={isInvoiceOpen} onClose={onInvoiceClose} size={"xl"}>
			<ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
			<ModalContent>
				<ModalHeader>
					<Text>Add Utility Invoice</Text>
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Stack>
						<FormControl display={"flex"} alignItems="center">
							<FormLabel display={"inline"}>Type</FormLabel>
							<Select
								onChange={handleUtilityInvoiceChange}
								width={"fit-content"}
								name="utilityType"
								placeholder="Meter type"
								isInvalid={error?.utilityType}
								value={utilityInvoiceInformation.utilityType}
								_placeholder={{ color: "gray.500" }}
							>
								<option value="electricity">Electricity</option>
								<option value="gas">Gas</option>
								<option value="water">Water</option>
								<option value="sanitation">Sanitation</option>
								<option value="internet">Internet</option>
								<option value="cleaning">Cleaning</option>
							</Select>
						</FormControl>
						<FormControl>
							<FormLabel display={"inline"}>Due Date:</FormLabel>
							<Input
								onChange={handleUtilityInvoiceChange}
								width={"fit-content"}
								type="date"
								name="dueDate"
								isInvalid={error?.dueDate}
								value={utilityInvoiceInformation.dueDate}
								_placeholder={{ color: "gray.500" }}
							/>
						</FormControl>
						<FormControl>
							<FormLabel display={"inline"}>Amount:</FormLabel>
							<Input
								onChange={handleUtilityInvoiceChange}
								width={"fit-content"}
								type="number"
								name="amount"
								isInvalid={error?.amount}
								value={utilityInvoiceInformation.amount}
								placeholder="Amount"
								_placeholder={{ color: "gray.500" }}
							/>
						</FormControl>
						<FormControl display={"flex"} alignItems="center">
							<FormLabel display={"inline"}>Currency:</FormLabel>
							<Select
								onChange={handleUtilityInvoiceChange}
								value={utilityInvoiceInformation.currency}
								width={"fit-content"}
								type="number"
								name="currency"
								placeholder="Currency"
								isInvalid={error?.currency}
								_placeholder={{ color: "gray.500" }}
							>
								<option value="eur">EUR</option>
								<option value="ron">RON</option>
							</Select>
						</FormControl>
						<FormControl>
							<FormLabel>Invoice:</FormLabel>
							<Dropzone
								onDrop={onInvoiceDrop}
								accept={{
									"application/pdf": [".pdf"],
									"application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
									"application/msword": [".doc"],
								}}
								description="Upload PDF,DOC or DOCX Format"
								maxFiles={1}
							/>
						</FormControl>
					</Stack>
				</ModalBody>
				<ModalFooter>
					<Button
						colorScheme="red"
						onClick={() => {
							onInvoiceClose();
							setError({});
						}}
						mr={3}
					>
						{t("misc.cancel")}
					</Button>
					<Button colorScheme="blue" onClick={addUtilityInvoice} isDisabled={loading}>
						{t("misc.save")}
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
	const renderUtilityHistoryTable = (utility) => {
		return (
			<Table variant="simple">
				<Thead>
					<Tr my=".8rem">
						<Th>Index</Th>
						<Th>Date</Th>
						<Th></Th>
					</Tr>
				</Thead>
				<Tbody>
					{utility.map((u, index) => (
						<UtilityMeterTableRow
							key={index}
							meterIndex={u.meterIndex}
							timestamp={u.timestamp}
							isCurrent={index === 0}
							additionalMeterIndex={u.additionalMeterIndex}
						/>
					))}
				</Tbody>
			</Table>
		);
	};
	const renderUtilityPictureHistory = () => {
		return (
			<Stack>
				{images.length === 0 && <Text>No meter pictures yet.</Text>}
				<Wrap>
					{images.map((i, index) => (
						<WrapItem key={index}>
							<Box position="relative" key={index}>
								<Image
									src={i.url}
									onClick={() => openImageViewer(index)}
									height={"200px"}
									style={{ margin: "2px" }}
									alt="meter index picture"
									cursor={"pointer"}
									className="ticketDocument"
								/>
								<Text className="ticketDocumentDownloadButton" style={{ position: "absolute", top: "5%", left: "5%", display: "none" }} fontWeight={"bold"}>
									{moment(i.addedDate).format("DD MMM YYYY - HH:mm")}
								</Text>
							</Box>
						</WrapItem>
					))}

					{isViewerOpen && (
						<ImageViewer
							src={images.map((i) => i.url)}
							currentIndex={currentImage}
							onClose={closeImageViewer}
							disableScroll={false}
							backgroundStyle={{
								backgroundColor: "rgba(0,0,0,0.9)",
							}}
							closeOnClickOutside={true}
						/>
					)}
				</Wrap>
			</Stack>
		);
	};
	const renderUtilityMeterHistoryModal = () => (
		<Modal isOpen={isHistoryOpen} onClose={onHistoryClose} size={"2xl"}>
			<ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
			<ModalContent>
				<ModalHeader>
					<Text>Utility Meter History</Text>
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Tabs variant="enclosed">
						<TabList>
							<Tab>Gas</Tab>
							<Tab>Electricity</Tab>
							<Tab>Water</Tab>
							<Tab>Meter Pictures</Tab>
						</TabList>
						<TabPanels>
							<TabPanel>
								{renderUtilityHistoryTable([...property.utilities.gas.indexes].sort((a, b) => moment(b.timestamp).unix() - moment(a.timestamp).unix()))}
							</TabPanel>
							<TabPanel>
								{renderUtilityHistoryTable([...property.utilities.electricity.indexes].sort((a, b) => moment(b.timestamp).unix() - moment(a.timestamp).unix()))}
							</TabPanel>
							<TabPanel>
								{renderUtilityHistoryTable([...property.utilities.water.indexes].sort((a, b) => moment(b.timestamp).unix() - moment(a.timestamp).unix()))}
							</TabPanel>
							<TabPanel>{renderUtilityPictureHistory(property.utilities.meterPictureHistory)}</TabPanel>
						</TabPanels>
					</Tabs>
				</ModalBody>
				<ModalFooter>
					<Button colorScheme="red" onClick={onHistoryClose} mr={3}>
						Close
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
	const renderRequestMeterPictureModal = () => (
		<Modal isOpen={isRequestMeterOpen} onClose={onRequestMeterClose} size={"xl"}>
			<ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
			<ModalContent>
				<ModalHeader>
					<Text>Request Meter Picture</Text>
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Stack>
						<FormControl>
							<FormLabel display={"inline"}>Meter types to request:</FormLabel>
							<Stack>
								<Checkbox name="water" onChange={handleRequestMeterPictureChange}>
									<Text>Water Meter</Text>
								</Checkbox>
								<Checkbox name="electricity" onChange={handleRequestMeterPictureChange}>
									<Text>Electricity Meter</Text>
								</Checkbox>
								<Checkbox name="gas" onChange={handleRequestMeterPictureChange}>
									<Text>Gas Meter</Text>
								</Checkbox>
							</Stack>
						</FormControl>
					</Stack>
				</ModalBody>
				<ModalFooter>
					<Button colorScheme="red" onClick={onRequestMeterClose} mr={3}>
						{t("misc.cancel")}
					</Button>
					<Button colorScheme="blue" onClick={requestMeterPicture}>
						Request
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
	const renderAddUtilityMeterModal = () => (
		<Modal isOpen={isMeterOpen} onClose={onMeterClose} size={"xl"}>
			<ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
			<ModalContent>
				<ModalHeader>
					<Text>Add Utility Index</Text>
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Stack>
						<FormControl display={"flex"} alignItems="center">
							<FormLabel display={"inline"}>Type</FormLabel>
							<Select
								width={"fit-content"}
								name="meterType"
								isInvalid={error?.meterType}
								value={meterInformation.meterType}
								onChange={handleUtilityMeterChange}
								placeholder="Meter type"
								_placeholder={{ color: "gray.500" }}
							>
								<option value="electricity">Electricity</option>
								<option value="gas">Gas</option>
								<option value="water">Water</option>
							</Select>
						</FormControl>
						<FormControl>
							<FormLabel display={"inline"}>New Index:</FormLabel>
							<Input
								width={"fit-content"}
								type="number"
								name="meterIndex"
								onChange={handleUtilityMeterChange}
								isInvalid={error?.meterIndex}
								value={meterInformation.meterIndex}
								placeholder="Index"
								_placeholder={{ color: "gray.500" }}
							/>
						</FormControl>
						{meterInformation.meterType === "water" && (
							<FormControl>
								<FormLabel display={"inline"}>Additional Index (optional):</FormLabel>
								<Input
									width={"fit-content"}
									type="number"
									name="additionalMeterIndex"
									onChange={handleUtilityMeterChange}
									// isInvalid={error?.meterIndex}
									value={meterInformation.additionalMeterIndex}
									placeholder="Additional Index"
									_placeholder={{ color: "gray.500" }}
								/>
							</FormControl>
						)}
					</Stack>
				</ModalBody>
				<ModalFooter>
					<Button
						colorScheme="red"
						onClick={() => {
							onMeterClose();
							setError({});
						}}
						mr={3}
					>
						{t("misc.cancel")}
					</Button>
					<Button colorScheme="blue" onClick={addUtilityMeter}>
						{t("misc.save")}
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
	const renderEditMeterModal = () => (
		<Modal isOpen={isEditMeterOpen} onClose={onEditMeterClose} size={"xl"}>
			<ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
			<ModalContent>
				<ModalHeader>
					<Text>Edit {editMeterInformation.meterType} Meter</Text>
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Stack>
						{editMeterInformation.meterType === "electricity" && (
							<>
								<FormControl>
									<FormLabel display={"inline"}>Cod NLC:</FormLabel>
									<Input
										width={"fit-content"}
										name="nlcCode"
										onChange={handleEditMeterChange}
										value={editMeterInformation.meter.nlcCode}
										placeholder="NLC Code"
										_placeholder={{ color: "gray.500" }}
									/>
								</FormControl>
								<FormControl>
									<FormLabel display={"inline"}>Cod ATM:</FormLabel>
									<Input
										width={"fit-content"}
										name="atmCode"
										onChange={handleEditMeterChange}
										value={editMeterInformation.meter.atmCode}
										placeholder="ATM Code"
										_placeholder={{ color: "gray.500" }}
									/>
								</FormControl>
							</>
						)}
						{editMeterInformation.meterType === "gas" && (
							<>
								<FormControl>
									<FormLabel display={"inline"}>Cod Incasare:</FormLabel>
									<Input
										width={"fit-content"}
										name="paymentCode"
										onChange={handleEditMeterChange}
										value={editMeterInformation.meter.paymentCode}
										placeholder="Payment Code"
										_placeholder={{ color: "gray.500" }}
									/>
								</FormControl>
								<FormControl>
									<FormLabel display={"inline"}>Cod Client:</FormLabel>
									<Input
										width={"fit-content"}
										name="clientCode"
										onChange={handleEditMeterChange}
										value={editMeterInformation.meter.clientCode}
										placeholder="Client Code"
										_placeholder={{ color: "gray.500" }}
									/>
								</FormControl>
							</>
						)}
						<FormControl>
							<FormLabel display={"inline"}>Collection Period:</FormLabel>
							<Input
								maxW={24}
								width={"fit-content"}
								name="firstDay"
								onChange={handleEditMeterRequestPeriodChange}
								value={editMeterInformation.meter?.requestPeriod.firstDay}
								placeholder="First Day"
								_placeholder={{ color: "gray.500" }}
							/>
							<Text as="span" marginX={2}>
								-
							</Text>
							<Input
								maxW={24}
								width={"fit-content"}
								name="lastDay"
								onChange={handleEditMeterRequestPeriodChange}
								value={editMeterInformation.meter?.requestPeriod.lastDay}
								placeholder="Last Day"
								_placeholder={{ color: "gray.500" }}
							/>
							<FormHelperText>Enter a day between 1 and 31.</FormHelperText>
						</FormControl>
					</Stack>
				</ModalBody>
				<ModalFooter>
					<Button
						colorScheme="red"
						onClick={() => {
							onEditMeterClose();
							setError({});
						}}
						mr={3}
					>
						{t("misc.cancel")}
					</Button>
					<Button colorScheme="blue" onClick={saveMeterInformation}>
						{t("misc.save")}
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);

	const getCurrentIndexTimestamp = (utilityType) => {
		return utilityType.indexes.length > 0
			? moment([...utilityType.indexes].sort((a, b) => moment(b.timestamp).unix() - moment(a.timestamp).unix())[0].timestamp).format(fullDateTimeFormat)
			: "Not available";
	};
	const getAdditionalIndex = (utilityType) => {
		return utilityType.indexes.length > 0
			? [...utilityType.indexes].sort((a, b) => moment(b.timestamp).unix() - moment(a.timestamp).unix())[0].additionalMeterIndex || "Not available"
			: "Not available";
	};
	const getCurrentIndex = (utilityType) => {
		return utilityType.indexes.length > 0
			? [...utilityType.indexes].sort((a, b) => moment(b.timestamp).unix() - moment(a.timestamp).unix())[0].meterIndex
			: "Not available";
	};
	const handleEditIndex = (type) => {
		onEditMeterOpen();
		console.log({ meter: property.utilities[type], type });
		setEditMeterInformation({ ...editMeterInformation, meter: property.utilities[type], meterType: type });
	};
	const getUnpaidUtilityPayments = () => {
		//get the utility payments with the status
		const utilityPayments = property.payments.filter((payment) => payment.type.toLowerCase().includes("utility"));
		console.log(utilityPayments);
		const overdue = utilityPayments.filter((payment) => getPaymentStatus({ paymentDate: payment.paymentDate, dueDate: payment.dueDate }) === "Overdue");
		const due = utilityPayments.filter((payment) => getPaymentStatus({ paymentDate: payment.paymentDate, dueDate: payment.dueDate }) === "Due");
		//calculate the total amount in eur and ron
		const overdueAmount = overdue.reduce(
			(acc, payment) => {
				if (payment.currency === "eur") return { eur: acc.eur + Number(payment.amount), ron: acc.ron };
				else return { eur: acc.eur, ron: acc.ron + Number(payment.amount) };
			},
			{ eur: 0, ron: 0 }
		);
		//calculate the total amount in eur and ron
		const dueAmount = due.reduce(
			(acc, payment) => {
				if (payment.currency === "eur") return { eur: acc.eur + Number(payment.amount), ron: acc.ron };
				else return { eur: acc.eur, ron: acc.ron + Number(payment.amount) };
			},
			{ eur: 0, ron: 0 }
		);

		return {
			overdue: overdueAmount,
			due: dueAmount,
		};
	};
	const unpaidUtilityPayments = getUnpaidUtilityPayments();
	return (
		<Stack spacing={6}>
			<Wrap>
				<WrapItem>
					<Button onClick={onMeterOpen} colorScheme="blue" isDisabled={isArchived}>
						{t("property.addIndex")}
					</Button>
				</WrapItem>
				<WrapItem>
					<Button onClick={onInvoiceOpen} colorScheme="blue" isDisabled={isArchived}>
						{t("property.addUtilityInvoice")}
					</Button>
				</WrapItem>
				<WrapItem>
					<Button onClick={onHistoryOpen} colorScheme="blackAlpha" isDisabled={isArchived}>
						{t("property.history")}
					</Button>
				</WrapItem>
				<WrapItem>
					<Tooltip label="Add tenants to enable" isDisabled={property.tenants.filter((t) => !t.former).length !== 0}>
						<Button onClick={onRequestMeterOpen} colorScheme="blackAlpha" isDisabled={isArchived}>
							{t("property.requestMeterPicture")}
						</Button>
					</Tooltip>
				</WrapItem>
			</Wrap>
			<Divider />
			<Wrap>
				<WrapItem>
					<Checkbox name="gas" isChecked={availableUtilitiesInformation.gas} onChange={handleChangeCheckboxes}>
						<Text>{t("property.gas")}</Text>
					</Checkbox>
				</WrapItem>
				<WrapItem>
					<Checkbox name="electricity" isChecked={availableUtilitiesInformation.electricity} onChange={handleChangeCheckboxes}>
						<Text>{t("property.electricity")}</Text>
					</Checkbox>
				</WrapItem>
				<WrapItem>
					<Checkbox name="water" isChecked={availableUtilitiesInformation.water} onChange={handleChangeCheckboxes}>
						<Text>{t("property.water")}</Text>
					</Checkbox>
				</WrapItem>
				<WrapItem>
					<Checkbox name="sanitation" isChecked={availableUtilitiesInformation.sanitation} onChange={handleChangeCheckboxes}>
						<Text>{t("property.sanitation")}</Text>
					</Checkbox>
				</WrapItem>
				<WrapItem>
					<Checkbox name="internet" isChecked={availableUtilitiesInformation.internet} onChange={handleChangeCheckboxes}>
						<Text>{t("property.internet")}</Text>
					</Checkbox>
				</WrapItem>
				<WrapItem>
					<Box>
						<Button onClick={saveCheckboxes} colorScheme="blue" isDisabled={isArchived}>
							{t("misc.save")}
						</Button>
					</Box>
				</WrapItem>
			</Wrap>
			<Divider />
			<Grid templateColumns="repeat(3, 1fr)" gap="24px">
				<GridItem colSpan={{ lg: "1", base: "6" }}>
					<Card variant={"elevated"}>
						<CardHeader fontSize="xl" pb={0}>
							<Flex justifyContent={"space-between"}>
								<Flex alignItems={"center"}>
									<Text fontWeight={"bold"}>{t("property.gasIndex")}</Text> <Icon as={AiFillFire} color="orange.400" />
								</Flex>
								<Text as="span">{getCurrentIndexTimestamp(property.utilities.gas)}</Text>
							</Flex>
						</CardHeader>
						<CardBody>
							<Stack direction={"column"}>
								<Stack direction="row">
									<Text fontWeight={"bold"}>{t("property.index")}:</Text>
									<Text>{getCurrentIndex(property.utilities.gas)}</Text>
								</Stack>
								<Stack direction="row">
									<Text>{t("property.collectionCode")}:</Text>
									<Text>{property.utilities.gas.paymentCode ? property.utilities.gas.paymentCode : "Not available"}</Text>
								</Stack>
								<Stack direction="row">
									<Text>{t("property.clientCode")}:</Text>
									<Text>{property.utilities.gas.clientCode ? property.utilities.gas.clientCode : "Not available"}</Text>
								</Stack>
								<Stack direction="row">
									<Text>{t("property.requestPeriod")}:</Text>
									<Box>{getRequestPeriodBadge(property, "gas", moment().format())}</Box>
								</Stack>
								<Flex direction="row" justifyContent={"space-between"}>
									<Tooltip label="Add index">
										<IconButton
											icon={<AddIcon />}
											colorScheme="green"
											onClick={() => {
												onMeterOpen();
												setMeterInformation({ ...meterInformation, meterType: "gas" });
											}}
											isDisabled={isArchived}
										/>
									</Tooltip>
									<IconButton
										isDisabled={isArchived}
										onClick={() => {
											handleEditIndex("gas");
										}}
									>
										<EditIcon />
									</IconButton>
								</Flex>
							</Stack>
						</CardBody>
					</Card>
				</GridItem>
				<GridItem colSpan={{ lg: "1", base: "6" }}>
					<Card variant={"elevated"}>
						<CardHeader fontSize="xl" pb={0}>
							<Flex justifyContent={"space-between"}>
								<Flex alignItems={"center"}>
									<Text fontWeight="bold">{t("property.electricityIndex")}</Text>
									<Icon as={BsFillLightningChargeFill} color="yellow.400" />
								</Flex>
								<Text as="span">{getCurrentIndexTimestamp(property.utilities.electricity)}</Text>
							</Flex>
						</CardHeader>
						<CardBody>
							<Stack direction="column">
								<Stack direction="row">
									<Text fontWeight={"bold"}>{t("property.index")}:</Text>
									<Text>{getCurrentIndex(property.utilities.electricity)}</Text>
								</Stack>
								<Stack direction="row">
									<Text>{t("property.nlcCode")}:</Text>
									<Text>{property.utilities.electricity.nlcCode ? property.utilities.electricity.nlcCode : "Not available"}</Text>
								</Stack>
								<Stack direction="row">
									<Text>{t("property.atmCode")}:</Text>
									<Text>{property.utilities.electricity.atmCode ? property.utilities.electricity.atmCode : "Not available"}</Text>
								</Stack>
								<Stack direction="row">
									<Text>{t("property.requestPeriod")}:</Text>
									<Box>{getRequestPeriodBadge(property, "electricity", moment().format())}</Box>
								</Stack>
								<Flex direction="row" justifyContent={"space-between"}>
									<Tooltip label="Add index">
										<IconButton
											icon={<AddIcon />}
											colorScheme="green"
											onClick={() => {
												onMeterOpen();
												setMeterInformation({ ...meterInformation, meterType: "electricity" });
											}}
											isDisabled={isArchived}
										/>
									</Tooltip>
									<IconButton
										onClick={() => {
											handleEditIndex("electricity");
										}}
										isDisabled={isArchived}
									>
										<EditIcon />
									</IconButton>
								</Flex>
							</Stack>
						</CardBody>
					</Card>
				</GridItem>
				<GridItem colSpan={{ lg: "1", base: "6" }}>
					<Card variant={"elevated"}>
						<CardHeader fontSize="xl" pb={0}>
							<Flex justifyContent={"space-between"}>
								<Flex alignItems={"center"}>
									<Text fontWeight="bold">{t("property.waterIndex")}</Text>
									<Icon as={GiWaterDrop} color="blue.300" />
								</Flex>
								<Text as="span">{getCurrentIndexTimestamp(property.utilities.water)}</Text>
							</Flex>
						</CardHeader>
						<CardBody>
							<Stack direction="column">
								<Stack direction="row">
									<Text fontWeight={"bold"}>{t("property.index")}:</Text> <Text>{getCurrentIndex(property.utilities.water)}</Text>
								</Stack>
								<Stack direction="row">
									<Text fontWeight={"bold"}>{t("property.additionalIndex")}:</Text> <Text>{getAdditionalIndex(property.utilities.water)}</Text>
								</Stack>
								<Stack direction="row">
									<Text>{t("property.requestPeriod")}:</Text>
									<Box>{getRequestPeriodBadge(property, "water", moment().format())}</Box>
								</Stack>
								<Flex direction="row" justifyContent={"space-between"}>
									<Tooltip label="Add index">
										<IconButton
											icon={<AddIcon />}
											colorScheme="green"
											onClick={() => {
												onMeterOpen();
												setMeterInformation({ ...meterInformation, meterType: "water" });
											}}
											isDisabled={isArchived}
										/>
									</Tooltip>
									<IconButton
										onClick={() => {
											handleEditIndex("water");
										}}
										isDisabled={isArchived}
									>
										<EditIcon />
									</IconButton>
								</Flex>
							</Stack>
						</CardBody>
					</Card>
				</GridItem>
			</Grid>
			<Box overflowX="auto">
				<Flex justifyContent={"space-between"}>
					<Stack direction={"row"} alignItems="center">
						<Text fontSize="xl" fontWeight="bold">
							{t("property.utilityPayments")}
						</Text>
						<Box>
							<Input type="text" placeholder="Search..." value={query} onChange={handleSearchChange} />
						</Box>
						<Button
							colorScheme="blue"
							onClick={onMarkMultiplePaymentsAsPaidOpen}
							isDisabled={utilityPayments.filter((p) => !p.paymentDate).length < 2 || isArchived}
						>
							{t("property.markMultipleAsPaid")}
						</Button>
					</Stack>
					<Stack direction="row" alignItems="center">
						<Badge display="flex" alignItems="center" textTransform={"none"} colorScheme="yellow">
							<Stack direction="row">
								<Text>{t("property.totalDue")}:</Text>
								<Text>{unpaidUtilityPayments.due.ron} ron,</Text>
								<Text>{unpaidUtilityPayments.due.eur} eur</Text>
							</Stack>
						</Badge>
						<Badge display="flex" alignItems="center" textTransform={"none"} colorScheme="red">
							<Stack direction="row">
								<Text>{t("property.totalOverdue")}:</Text>
								<Text>{unpaidUtilityPayments.overdue.ron} ron,</Text>
								<Text>{unpaidUtilityPayments.overdue.eur} eur</Text>
							</Stack>
						</Badge>
					</Stack>
				</Flex>
				<Table variant="simple" size="small">
					<Thead>
						<Tr my=".8rem" pl="0px">
							<Th pl="0px">{t("table.issuedDate")}</Th>
							<Th>{t("table.status")}</Th>
							<Th>{t("table.dueDate")}</Th>
							<Th>{t("table.amount")}</Th>
							<Th>{t("table.method")}</Th>
							<Th>{t("table.type")}</Th>
							<Th>{t("table.paidDate")}</Th>
							<Th></Th>
						</Tr>
					</Thead>
					<Tbody>
						{utilityPayments
							.filter((p) => !p.paymentDate)
							.map((row, index) => {
								return (
									<InvoicesRow
										id={row.id}
										key={index}
										date={row.issuedDate}
										address={getPropertyAddress(property)}
										paymentDate={row.paymentDate}
										code={row.number}
										amount={row.amount}
										type={row.type}
										dueDate={row.dueDate}
										logo={row.logo}
										format={row.format}
										method={row.paymentMethod}
										documents={row.documents}
										paymentDirection={row.paymentDirection}
										property={property}
										currency={row.currency}
										description={row.description}
										exchange={row.exchange}
									/>
								);
							})}
						{utilityPayments
							.filter((p) => p.paymentDate)
							.map((row, index) => {
								return (
									<InvoicesRow
										id={row.id}
										key={index}
										date={row.issuedDate}
										address={getPropertyAddress(property)}
										paymentDate={row.paymentDate}
										code={row.number}
										amount={row.amount}
										type={row.type}
										logo={row.logo}
										format={row.format}
										method={row.paymentMethod}
										documents={row.documents}
										paymentDirection={row.paymentDirection}
										property={property}
										currency={row.currency}
										description={row.description}
										exchange={row.exchange}
										dueDate={row.dueDate}
									/>
								);
							})}
					</Tbody>
				</Table>
			</Box>
			{renderAddUtilityMeterModal()}
			{renderAddUtilityInvoiceModal()}
			{renderUtilityMeterHistoryModal()}
			{renderRequestMeterPictureModal()}
			{renderEditMeterModal()}
			{markMultiplePaymentsAsPaidModal()}
		</Stack>
	);
}
