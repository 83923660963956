import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
	Button,
	Flex,
	Modal,
	ModalOverlay,
	ModalHeader,
	ModalContent,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	Box,
	Grid,
	GridItem,
	Text,
	Divider,
	Card,
	CardHeader,
	CardBody,
	Tooltip,
	useDisclosure,
	Select,
	Wrap,
	WrapItem,
	useToast,
	Badge,
	RadioGroup,
	Radio,
	InputGroup,
} from "@chakra-ui/react";

import { FormControl, FormLabel, Input, Stack, IconButton } from "@chakra-ui/react";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import SpecsTab from "./SpecsTab";
import Contacts from "./Contacts";
import PaymentsTab from "./PaymentsTab";
import { Image } from "@chakra-ui/react";
import ImagesTab from "./ImagesTab";
import ActivitiesTab from "./ActivitiesTab";
import ContactPopover from "../ContactPopover/ContactPopover";
import BuildingSuperPopover from "../ContactPopover/OwnerPopover";
import UtilityTab from "./UtilityTab";
import DocumentsTab from "./DocumentsTab";
import AccountsTab from "./AccountsTab";
import { editRentVerifier, getPropertyAddress } from "../../utils/verifier";
import TenantsTab from "./TenantsTab";
import { EditIcon } from "@chakra-ui/icons";
import { archivePropertyAsync, restorePropertyAsync, selectAllProperties, updateRentAsync } from "../../redux/user/userSlice";
import moment from "moment";
import TenantPopover from "../ContactPopover/TenantPopover";
import { API } from "../../utils/http";
import NotificationsTab from "./NotificationsTab";
import TicketsTab from "./TicketsTab";
import AdministrationStatusTooltipLabel from "../StatusTooltipLabel/AdministrationStatusTooltipLabel";
import FinancialStatementsTab from "./FinancialStatementsTab";
import SecurityDepositTab from "./SecurityDepositTab";
import { getAdministrationStatus, getFinancialStatementStatus, getLeaseStatus } from "../../utils/utils";
import LeaseStatusTooltipLabel from "../StatusTooltipLabel/LeaseStatusTooltipLabel";
import MessagesTab from "./MessagesTab";
import StatementStatusTooltipLabel from "../StatusTooltipLabel/StatementStatusTooltipLabel";
import { useTranslation } from "react-i18next";
import GeneralnTab from "./General";
export default function Property() {
	const { id } = useParams();
	const property = useSelector(selectAllProperties)?.find((p) => p.id === id);
	const isArchived = property.archived;
	const navigate = useNavigate();
	const { isOpen: isConfirmRestoreModalOpen, onOpen: onConfirmRestoreModalOpen, onClose: onConfirmRestoreModalClose } = useDisclosure();
	const { t } = useTranslation();
	const status = useSelector((state) => state.user.status) === "loading";
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { isOpen: isArchiveOpen, onOpen: onArchiveOpen, onClose: onArchiveClose } = useDisclosure();
	const initialRentInfo = { ...property?.rent };
	const [rentInfo, setRentInfo] = useState(initialRentInfo);
	const dispatch = useDispatch();
	const [error, setError] = useState({});
	const toast = useToast();
	const [thumbnailImage, setThumbnailImage] = useState("");
	const location = useLocation();
	useEffect(() => {
		console.log("get media urls");
		const thumbnail = property?.media?.filter((m) => m.contentType.includes("image"))[0];
		if (thumbnail) {
			const payload = { propertyId: property.id, media: [thumbnail] };
			API.put("get-media-urls", payload).then((r) => {
				console.log(r.data);
				setThumbnailImage(r.data.media[0]);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [property?.media]);
	if (!property) {
		alert("Property not found");
		navigate(-1, { replace: true });
		return <>Redirecting...</>;
	}
	const confirmArchive = () => {
		console.log("archive property");
		dispatch(archivePropertyAsync({ propertyId: property.id })).then((r) => {
			if (r.error?.message)
				toast({
					title: "Error encountered",
					description: r.error.message,
					status: "error",
					duration: 9000,
					isClosable: true,
				});
			else {
				toast({
					title: "Success!",
					description: "Property archived!",
					status: "success",
					duration: 2000,
					isClosable: true,
				});
				navigate(`/properties`);
			}
		});
	};

	const queryParams = new URLSearchParams(location.search);
	const getDefaultTabIndex = () => {
		const tab = queryParams.get("tab");
		if (tab) {
			const index = tabs.findIndex((t) => t.id.toLowerCase() === tab.toLowerCase());
			if (index !== -1) return index;
		}
		return 0;
	};
	const tabs = [
		{
			id: "general",
			component: <GeneralnTab property={property} />,
		},
		{
			id: "specs",
			component: <SpecsTab property={property} />,
		},
		{
			id: "images",
			component: <ImagesTab property={property} />,
		},
		{
			id: "contacts",
			component: <Contacts property={property} loading={status} />,
		},
		{
			id: "utilities",
			component: <UtilityTab property={property} />,
		},
		{
			id: "payments",
			component: <PaymentsTab property={property} />,
		},
		{
			id: "securityDeposits",
			component: <SecurityDepositTab property={property} />,
		},
		{
			id: "financialStatements",
			component: <FinancialStatementsTab property={property} loading={status} />,
		},
		{
			id: "documents",
			component: <DocumentsTab property={property} />,
		},
		{
			id: "accounts",
			component: <AccountsTab property={property} />,
		},
		{
			id: "activities",
			component: <ActivitiesTab property={property} />,
		},
		{
			id: "tenants",
			component: <TenantsTab property={property} />,
		},
		{
			id: "notifications",
			component: <NotificationsTab property={property} />,
		},
		{
			id: "tickets",
			component: <TicketsTab property={property} />,
		},
		{
			id: "messages",
			component: <MessagesTab property={property} />,
		},
	];
	const getStatusText = (status) => {
		if (Object.values(status).includes("empty")) return "empty";
		if (Object.values(status).includes("incomplete")) return "incomplete";
		if (Object.values(status).includes("expired")) return "expired";
		if (Object.values(status).includes("warning")) return "warning";
		return "complete";
	};
	const getAdministrationColorScheme = (status) => {
		switch (getStatusText(status)) {
			case "incomplete":
				return "red";
			case "expired":
				return "red";
			case "warning":
				return "yellow";
			default:
				return "green";
		}
	};
	const getLeaseColorScheme = (status) => {
		switch (getStatusText(status)) {
			case "incomplete":
				return "red";
			case "expired":
				return "red";
			case "warning":
				return "yellow";
			case "empty":
				return "blue";
			default:
				return "green";
		}
	};
	const getStatementColorScheme = (status) => {
		switch (getStatusText(status)) {
			case "incomplete":
				return "red";
			case "expired":
				return "red";
			case "warning":
				return "yellow";
			case "empty":
				return "blue";
			default:
				return "green";
		}
	};
	const saveRent = () => {
		const payload = { propertyId: property.id, rent: rentInfo };
		console.log("save rent", payload);
		const errors = editRentVerifier(rentInfo);
		console.log(errors);
		if (Object.entries(errors).length !== 0) {
			setError(errors);
			toast({
				title: "Error encountered.",
				description: "Invalid information",
				status: "error",
				duration: 2000,
				isClosable: true,
			});
			return;
		}
		setError({});
		// return;
		dispatch(updateRentAsync(payload))
			.then((r) => {
				if (r.error?.message)
					toast({
						title: "Error encountered",
						description: r.error.message,
						status: "error",
						duration: 9000,
						isClosable: true,
					});
				else {
					toast({
						title: "Success!",
						description: "Update successful",
						status: "success",
						duration: 2000,
						isClosable: true,
					});
				}
			})
			.finally(() => {
				onClose();
				setRentInfo(initialRentInfo);
			});
	};
	const handleEditRentChange = (e) => {
		setRentInfo({ ...rentInfo, [e.target.name]: e.target.value });
	};

	const renderEditRent = () => (
		<Modal isOpen={isOpen} onClose={onClose} size={"xl"}>
			<ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
			<ModalContent>
				<ModalHeader>
					<Text>Edit Rent</Text>
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Stack>
						<FormControl display={"flex"} alignItems="center">
							<FormLabel display={"inline"}>Currency</FormLabel>
							<Select
								width={"fit-content"}
								name="currency"
								value={rentInfo.currency}
								onChange={handleEditRentChange}
								placeholder="Currency"
								isInvalid={error?.currency}
								_placeholder={{ color: "gray.500" }}
							>
								<option value="eur">EUR</option>
								<option value="ron">RON</option>
							</Select>
						</FormControl>
						<FormControl>
							<FormLabel display={"inline"}>Amount:</FormLabel>
							<Input
								width={"fit-content"}
								type="number"
								name="amount"
								onChange={handleEditRentChange}
								value={rentInfo.amount}
								isInvalid={error?.amount}
								placeholder="Amount"
								_placeholder={{ color: "gray.500" }}
							/>
						</FormControl>
						<FormControl>
							<FormLabel display={"inline"}>Rent payment interval:</FormLabel>
							<Input
								onChange={handleEditRentChange}
								type="number"
								mr="10px"
								value={rentInfo.firstRentDay}
								name="firstRentDay"
								placeholder="First Day"
								isInvalid={error?.firstRentDay}
								_placeholder={{ color: "gray.500" }}
								maxW={24}
							/>
							<Input
								maxW={24}
								onChange={handleEditRentChange}
								type="number"
								name="lastRentDay"
								value={rentInfo.lastRentDay}
								isInvalid={error?.lastRentDay}
								placeholder="Last Day"
								_placeholder={{ color: "gray.500" }}
							/>
						</FormControl>
						<FormControl display={"flex"} alignItems="center">
							<FormLabel display={"inline"}>Rent exchange rate:</FormLabel>
							<RadioGroup
								name="exchangeRateType"
								onChange={(e) => {
									console.log(e);
									if (e === "fixedExchangeRate") setRentInfo({ ...rentInfo, exchangeRateType: e, fixedExchangeRate: 4.9 });
									else {
										const tempRent = { ...rentInfo };
										delete tempRent.fixedExchangeRate;
										setRentInfo({ ...tempRent, exchangeRateType: e });
									}
								}}
								value={rentInfo.exchangeRateType}
							>
								<Stack>
									<Radio value="bnr">BNR din ziua platii</Radio>
									<Radio value="bnr2">BNR+2% din ziua platii</Radio>
									<Radio value="fixedExchangeRate">Curs fix</Radio>
								</Stack>
							</RadioGroup>
						</FormControl>
						<FormControl display={"flex"} alignItems="center" hidden={rentInfo.exchangeRateType !== "fixedExchangeRate"}>
							<FormLabel display={"inline"}>Fixed Exchange rate:</FormLabel>
							<Input
								isDisabled={rentInfo.exchangeRateType !== "fixedExchangeRate"}
								maxW={24}
								width={"fit-content"}
								type="number"
								name="fixedExchangeRate"
								onChange={handleEditRentChange}
								value={rentInfo.fixedExchangeRate}
								isInvalid={error?.fixedExchangeRate}
								placeholder="Exchange Rate"
								_placeholder={{ color: "gray.500" }}
							/>
						</FormControl>
						<FormControl display={"flex"} alignItems="center">
							<FormLabel whiteSpace={"nowrap"}>Fixed administration fee:</FormLabel>
							<InputGroup>
								<Input
									maxW={24}
									isInvalid={error?.fixedAdministrationFeeAmount}
									name="fixedAdministrationFeeAmount"
									placeholder="Amount"
									value={rentInfo.fixedAdministrationFeeAmount}
									onChange={handleEditRentChange}
									_placeholder={{ color: "gray.500" }}
									type="number"
								/>
								<Select maxW={24} name="fixedAdministrationFeeCurrency" onChange={handleEditRentChange} value={rentInfo.fixedAdministrationFeeCurrency}>
									<option value="">Currency</option>
									<option value="eur">EUR</option>
									<option value="ron">RON</option>
								</Select>
							</InputGroup>
						</FormControl>
					</Stack>
				</ModalBody>
				<ModalFooter>
					<Button
						colorScheme="red"
						onClick={() => {
							onClose();
							setError({});
							setRentInfo(initialRentInfo);
						}}
						mr={3}
					>
						{t("misc.cancel")}
					</Button>
					<Button colorScheme="blue" onClick={saveRent}>
						{t("misc.save")}
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
	const renderPersistentInfo = () => {
		const administrationStatus = getAdministrationStatus(property);
		const leaseStatus = getLeaseStatus(property);
		const financialStatementStatus = getFinancialStatementStatus(property);

		return (
			<Box>
				<Stack>
					<Box>
						<Image fallbackSrc="https://via.placeholder.com/250" src={thumbnailImage.url} />
					</Box>
					<Text fontWeight={"bold"}>Status</Text>
					<Box>
						<Text as={"span"} fontWeight={"bold"}>
							{t("property.administration")}:
						</Text>{" "}
						<Tooltip label={<AdministrationStatusTooltipLabel property={property} administrationStatus={administrationStatus} />}>
							<Badge colorScheme={getAdministrationColorScheme(administrationStatus)} fontSize="16px">
								{t(`table.${getStatusText(administrationStatus)}`)}
							</Badge>
						</Tooltip>
					</Box>
					<Box>
						<Text as={"span"} fontWeight={"bold"}>
							{t("property.lease")}:
						</Text>{" "}
						<Tooltip label={<LeaseStatusTooltipLabel property={property} leaseStatus={leaseStatus} />}>
							<Badge colorScheme={getLeaseColorScheme(leaseStatus)} fontSize="16px">
								{t(`table.${getStatusText(leaseStatus)}`)}
							</Badge>
						</Tooltip>
					</Box>
					<Box>
						<Text as={"span"} fontWeight={"bold"}>
							{t("property.financial")}:
						</Text>{" "}
						<Tooltip label={<StatementStatusTooltipLabel statementStatus={financialStatementStatus} />}>
							<Badge colorScheme={getStatementColorScheme(financialStatementStatus)} fontSize="16px">
								{t(`table.${getStatusText(financialStatementStatus)}`)}
							</Badge>
						</Tooltip>
					</Box>
					<Divider />
					<Box>
						<Text as={"span"} fontWeight={"bold"}>
							{t("property.rentPrice")}:
						</Text>{" "}
						{property.rent.amount ? property.rent.amount + " " + property.rent.currency : "Not available"}
						<Tooltip label={t("property.editRent")}>
							<IconButton
								isDisabled={isArchived}
								marginLeft={2}
								icon={<EditIcon />}
								colorScheme="teal"
								onClick={() => {
									onOpen();
									setRentInfo({ ...rentInfo, ...property.rent });
								}}
							/>
						</Tooltip>
					</Box>
					<Box>
						<Text as={"span"} fontWeight={"bold"}>
							{t("property.rentCollection")}:
						</Text>{" "}
						<Text>
							{property.rent.firstRentDay && property.rent.lastRentDay
								? moment().format(`${property.rent.firstRentDay} MMM YYYY`) +
								  " - " +
								  (property.rent.lastRentDay > moment().daysInMonth()
										? moment().endOf("month").format("DD MMM YYYY")
										: moment().format(`${property.rent.lastRentDay} MMM YYYY`))
								: "Not available"}
						</Text>
					</Box>
					<Box hidden={!property.rent.fixedAdministrationFeeAmount}>
						<Text as={"span"} fontWeight={"bold"}>
							{t("property.fixedAdministrationFee")}:
						</Text>{" "}
						{property.rent.fixedAdministrationFeeAmount + " " + property.rent.fixedAdministrationFeeCurrency}
					</Box>
					<Box>
						<Text as={"span"} fontWeight={"bold"}>
							{t("property.rentExchangeRate")}:
						</Text>{" "}
						{property.rent.exchangeRateType === "fixedExchangeRate"
							? `Curs fix 1 EUR = ${property.rent.fixedExchangeRate} RON`
							: property.rent.exchangeRateType === "bnr2"
							? `BNR+2% din ziua platii`
							: property.rent.exchangeRateType === "bnr"
							? `BNR din ziua platii`
							: "Not available"}
					</Box>
					<Divider />
					<Box>
						<Text as={"span"} fontWeight={"bold"}>
							{t("property.agent")}:
						</Text>{" "}
						{property.agent}
					</Box>
					<Box>
						<Text as={"span"} fontWeight={"bold"}>
							{t("property.dateAdded")}:
						</Text>{" "}
						{moment(property.addedDate).format("DD MMM YYYY")}
					</Box>
					{/* <Box>
						<Text as={"span"} fontWeight={"bold"}>
							Date modified:
						</Text>{" "}
						01-11-2021
					</Box> */}
					<Box>
						<Stack direction={"row"}>
							<Text as={"span"} fontWeight={"bold"}>
								{t("property.contacts")}:
							</Text>{" "}
							<Wrap>
								{property.buildingSuper.email ? (
									<WrapItem>
										<BuildingSuperPopover contact={property.buildingSuper} />
									</WrapItem>
								) : (
									<></>
								)}
								{property.owner.email ? (
									<WrapItem>
										<BuildingSuperPopover contact={property.owner} />
									</WrapItem>
								) : (
									<></>
								)}
								{property.contacts?.map((c, index) => (
									<WrapItem key={index}>
										<ContactPopover contact={c} property={property} />
									</WrapItem>
								))}
								{property.tenants
									?.filter((t) => !t.former)
									.map((t, index) => (
										<WrapItem>
											<TenantPopover key={index} contact={t} propertyId={property.id} />
										</WrapItem>
									))}
							</Wrap>
						</Stack>
					</Box>
				</Stack>
			</Box>
		);
	};
	const renderArchiveProperty = () => {
		return (
			<Modal isOpen={isArchiveOpen} onClose={onArchiveClose} size={"xl"}>
				<ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
				<ModalContent>
					<ModalHeader>
						<Text>Archive property</Text>
					</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Stack>
							<Text>Are you sure you want to archive this property?</Text>
						</Stack>
					</ModalBody>
					<ModalFooter>
						<Stack direction="row">
							<Button colorScheme="red" onClick={confirmArchive}>
								Archive
							</Button>
							<Button colorScheme="blue" onClick={onArchiveClose} mr={3}>
								{t("misc.cancel")}
							</Button>
						</Stack>
					</ModalFooter>
				</ModalContent>
			</Modal>
		);
	};
	const handleRestoreProperty = () => {
		dispatch(restorePropertyAsync({ propertyId: property.id }))
			.then((r) => {
				if (r.error?.message)
					toast({
						title: "Error encountered",
						description: r.error.message,
						status: "error",
						duration: 9000,
						isClosable: true,
					});
				else {
					toast({
						title: "Success!",
						description: "Property restored!",
						status: "success",
						duration: 2000,
						isClosable: true,
					});
				}
			})
			.finally(() => {
				onConfirmRestoreModalClose();
			});
	};

	const renderConfirmRestoreModal = () => {
		return (
			<Modal isOpen={isConfirmRestoreModalOpen} onClose={onConfirmRestoreModalClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Restore Property</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Text>Are you sure you want to restore this property?</Text>
					</ModalBody>

					<ModalFooter>
						<Button colorScheme="blue" mr={3} onClick={onConfirmRestoreModalClose}>
							Close
						</Button>
						<Button colorScheme="blue" variant="outline" size="sm" onClick={handleRestoreProperty}>
							Restore
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		);
	};
	return (
		<Card>
			<CardHeader>
				<Flex direction={"row"} alignItems="center" justifyContent={"space-between"}>
					<Box>
						<Text fontSize="xl" fontWeight="bold">
							Property Details
						</Text>
						<Text>{getPropertyAddress(property)}</Text>
					</Box>
					<Box>
						{property.archived ? (
							<Button colorScheme="blue" variant="outline" size="sm" onClick={onConfirmRestoreModalOpen}>
								{t("property.restore")}
							</Button>
						) : (
							<Button colorScheme="red" onClick={onArchiveOpen}>
								{t("property.archive")}
							</Button>
						)}
						{renderConfirmRestoreModal()}
						{renderArchiveProperty()}
					</Box>
				</Flex>
			</CardHeader>
			<CardBody>
				<Tabs variant="enclosed" defaultIndex={getDefaultTabIndex()} isLazy>
					<TabList overflowX="auto" overflowY="hidden">
						{tabs.map((tab, index) => (
							<Tab key={index}>{t(`property.${tab.id}`)}</Tab>
						))}
					</TabList>
					<TabPanels>
						{tabs.map((tab, index) => (
							<TabPanel key={index}>
								<Grid templateColumns="repeat(6, 1fr)" gap="24px">
									<GridItem colSpan={{ md: "6", lg: "1", base: "6" }}>{renderPersistentInfo()}</GridItem>
									<GridItem colSpan={{ md: "6", lg: "5", base: "6" }}>
										<Card variant={"elevated"}>
											<CardHeader fontWeight="bold" fontSize="xl" pb={0}>
												{t(`property.${tab.id}`)}
											</CardHeader>
											<CardBody>{tab.component}</CardBody>
										</Card>
									</GridItem>
								</Grid>
							</TabPanel>
						))}
					</TabPanels>
				</Tabs>
				{renderEditRent()}
			</CardBody>
		</Card>
	);
}
