import React, { useState } from "react";
import { Table, Tbody, Text, Th, Thead, Tr, Box, Stack, Td, Badge, useColorModeValue } from "@chakra-ui/react";
import { Input } from "@chakra-ui/react";
import { Card, CardHeader, CardBody } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { getPropertyAddress } from "../../utils/verifier";
import SecurityDepositRow from "../../Components/TableRows/SecurityDepositRow";
import { useNavigate } from "react-router-dom";
import TablePagination from "../../Components/Pagination/TablePagination";
import { selectActiveProperties } from "../../redux/user/userSlice";
import { useTranslation } from "react-i18next";

export default function SecurityDeposits() {
	const properties = useSelector(selectActiveProperties);
	const { t } = useTranslation();
	const [query, setquery] = useState("");
	const handleSearchChange = (e) => {
		setquery(e.target.value);
	};
	const securityDeposits = properties?.map((p) => p.securityDeposits).flat();
	const securityDepositlocations = ["custodyCash", "custodyBank", "owner", "total", "returned", "returnedTenant"];
	const getSecurityDepositsAmount = (currency, location) => {
		if (location === "total")
			return securityDeposits.filter((sd) => sd.currency === currency && sd.location !== "returned").reduce((acc, sd) => acc + Number(sd.amount), 0);
		return securityDeposits.filter((sd) => sd.currency === currency && sd.location === location).reduce((acc, sd) => acc + Number(sd.amount), 0);
	};
	const navigate = useNavigate();
	const goToProperty = (id) => {
		navigate(`/properties/view/${id}?tab=securityDeposits`);
	};
	const hoverColor = useColorModeValue("gray.100", "gray.700");
	//table pagination
	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 10; // Number of items to display per page

	// Calculate the starting and ending indices for the current page
	const startIndex = (currentPage - 1) * itemsPerPage;
	const endIndex = currentPage * itemsPerPage;

	const filteredProperties = properties?.filter((p) => {
		const address = getPropertyAddress(p);

		return (
			address.toLowerCase().includes(query.toLowerCase()) ||
			p.owner.name.toLowerCase().includes(query.toLowerCase()) ||
			p.tenants.find((t) => t.name.toLowerCase().includes(query.toLowerCase())) ||
			p.contacts.find((t) => t.name.toLowerCase().includes(query.toLowerCase()))
		);
	});
	// Assuming `properties` contains your data
	const dataToShow = filteredProperties.slice(startIndex, endIndex);
	// Function to handle page change
	const handlePageChange = (newPage) => {
		setCurrentPage(newPage);
	};
	return (
		<>
			<Card overflowX={{ sm: "scroll", xl: "hidden" }}>
				<CardHeader fontSize="xl" fontWeight="bold">
					<Stack direction={"row"} alignItems="center">
						<Text>{t("securityDeposits.securityDeposits")}</Text>
						<Box>
							<Input type="text" placeholder="Search..." value={query} onChange={handleSearchChange} />
						</Box>
					</Stack>
				</CardHeader>
				<CardBody>
					<Stack>
						<Stack direction="row">
							<Text fontWeight={"bold"}>{t("securityDeposits.totals")}:</Text>
							{securityDepositlocations.map((l, index) => (
								<Badge display="flex" alignItems="center" textTransform={"none"}>
									<Stack direction="row">
										<Text>{t(`types.${l}`)}:</Text>
										<Text>{Number(getSecurityDepositsAmount("ron", l)).toFixed(2)} ron,</Text>
										<Text>{Number(getSecurityDepositsAmount("eur", l)).toFixed(2)} eur</Text>
									</Stack>
								</Badge>
							))}
						</Stack>
						<Table variant="simple" size="small">
							<Thead>
								<Tr my=".8rem" pl="0px">
									<Th>{t("table.addedDate")}</Th>
									<Th>{t("table.location")}</Th>
									<Th>{t("table.amount")}</Th>
									<Th>{t("table.custodian")}</Th>
									<Th>{t("table.description")}</Th>
									<Th>{t("table.updatedDate")}</Th>
									<Th></Th>
								</Tr>
							</Thead>
							<Tbody>
								{dataToShow.map((property, index) => {
									const securityDeposits = property.securityDeposits;
									return (
										<>
											<Tr
												pl="0px"
												fontWeight={"bold"}
												onClick={() => {
													goToProperty(property.id);
												}}
												_hover={{ bg: hoverColor }}
											>
												<Td pl="0px">{property.owner.name}</Td>
												<Td pl="0px" colSpan={6}>
													{getPropertyAddress(property)}
												</Td>
											</Tr>
											{securityDeposits.map((row, index) => {
												return (
													<SecurityDepositRow
														id={row.id}
														key={index}
														addedDate={row.addedDate}
														updatedDate={row.updatedDate}
														documentNumber={row.documentNumber}
														amount={row.amount}
														location={row.location}
														custodian={row.custodian}
														description={row.description}
														property={property}
														currency={row.currency}
														propertyId={property.id}
													/>
												);
											})}
											{securityDeposits.length === 0 && (
												<Tr>
													<Td colSpan={7}>{t("table.noData")}</Td>
												</Tr>
											)}
										</>
									);
								})}
							</Tbody>
						</Table>
						<TablePagination totalItems={filteredProperties.length} itemsPerPage={itemsPerPage} onPageChange={handlePageChange} />
					</Stack>
				</CardBody>
			</Card>
		</>
	);
}
