import { Flex, Td, Text, Tr, useColorModeValue, Icon, Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";

import { HamburgerIcon } from "@chakra-ui/icons";
import React from "react";
import { useDispatch } from "react-redux";
import { removeDocumentAsync, updateDocumentActiveAsync } from "../../redux/user/userSlice";
import { API } from "../../utils/http";
import moment from "moment";
import { getDocumentBadge } from "../../utils/utils";
import { fullDateFormat, fullDateTimeFormat } from "../../utils/types";
import { useTranslation } from "react-i18next";
function DocumentsTableRow(props) {
	const { row, propertyId } = props;
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const textColor = useColorModeValue("gray.700", "white");
	const deleteDocument = (documentId) => {
		console.log("delete: ", { documentId, propertyId });
		dispatch(removeDocumentAsync({ documentId, propertyId }));
	};
	const getDocument = (documentId, documentName) => {
		const payload = { documentId, propertyId, documentName };
		console.log("view: ", payload);
		API.put("get-document", payload).then((r) => {
			console.log(r);
			window.open(r.data.url, "_blank");
		});
	};
	const markAsActive = (documentId) => {
		const payload = { documentId, propertyId, type: row.type, active: true };
		console.log("mark document as active: ", payload);
		dispatch(updateDocumentActiveAsync(payload));
	};
	const markAsInactive = (documentId) => {
		const payload = { documentId, propertyId, type: row.type, active: false };
		console.log("mark document as active: ", payload);
		dispatch(updateDocumentActiveAsync(payload));
	};

	return (
		<Tr>
			<Td minWidth={{ sm: "200px" }}>
				<Flex direction={"column"}>
					<Text fontSize="md" color={textColor}>
						{moment(row.addedDate).format(fullDateTimeFormat)}
					</Text>
					{getDocumentBadge(row)}
				</Flex>
			</Td>
			<Td>
				<Text fontSize="md" color={textColor} pb=".5rem">
					{row.name || "-"}
				</Text>
			</Td>
			<Td>
				<Text fontSize="md" color={textColor} pb=".5rem">
					{t(`types.${row.type}`) || "-"}
				</Text>
			</Td>
			<Td>
				<Text fontSize="md" color={textColor} pb=".5rem">
					{row.number || "-"}
				</Text>
			</Td>
			<Td>
				<Text fontSize="md" color={textColor} pb=".5rem" whiteSpace={"nowrap"}>
					{row.startingDate ? moment(row.startingDate).format(fullDateFormat) : "-"}
				</Text>
			</Td>
			<Td>
				<Text fontSize="md" color={textColor} pb=".5rem" whiteSpace={"nowrap"}>
					{row.expirationDate ? moment(row.expirationDate).format(fullDateFormat) : "-"}
				</Text>
			</Td>
			<Td>
				<Menu closeOnSelect={false}>
					<MenuButton>
						<Icon as={HamburgerIcon} />
					</MenuButton>
					<MenuList>
						<MenuItem
							isDisabled={!row.name}
							onClick={() => {
								getDocument(row.id, row.name);
							}}
						>
							Download
						</MenuItem>
						{row.active ? (
							<MenuItem
								onClick={() => {
									markAsInactive(row.id);
								}}
							>
								Mark as inactive
							</MenuItem>
						) : (
							<MenuItem
								onClick={() => {
									markAsActive(row.id);
								}}
							>
								Mark as Active
							</MenuItem>
						)}

						<MenuItem
							onClick={() => {
								deleteDocument(row.id);
							}}
						>
							Delete
						</MenuItem>
					</MenuList>
				</Menu>
				{/* <Stack direction="row">
					<Button
						onClick={() => {
							getDocument(row.id);
						}}
					>
						<Tooltip label="Download">
							<Icon as={DownloadIcon} color={"teal.400"} boxSize={6}></Icon>
						</Tooltip>
					</Button>
					<Button
						colorScheme={"red"}
						onClick={() => {
							deleteDocument(row.id);
						}}
					>
						<Tooltip label="Download">
							<Icon as={DeleteIcon} boxSize={6}></Icon>
						</Tooltip>
					</Button>
				</Stack> */}
			</Td>
		</Tr>
	);
}

export default DocumentsTableRow;
