/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useSelector } from "react-redux";
import { Text, Stack } from "@chakra-ui/react";

import { Card, CardHeader } from "@chakra-ui/react";

import PropertyUtilitiesCard from "./PropertyUtilitiesCard";
import { useTranslation } from "react-i18next";

export default function Utilities() {
	const properties = useSelector((state) => state.user?.user?.properties);
	const { t } = useTranslation();
	return (
		<Stack>
			<Card overflowX={"auto"}>
				<CardHeader>
					<Text fontSize="xl" fontWeight="bold">
						{t("navbarMenu.utilities")}
					</Text>
				</CardHeader>
			</Card>
			{properties.map((p) => (
				<PropertyUtilitiesCard property={p} />
			))}
		</Stack>
	);
}
