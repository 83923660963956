import {
	Button,
	Flex,
	Modal,
	ModalOverlay,
	ModalHeader,
	ModalContent,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	Td,
	Text,
	Tr,
	useColorModeValue,
	Box,
	Icon,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	Stack,
} from "@chakra-ui/react";
import { DownloadIcon, HamburgerIcon } from "@chakra-ui/icons";
import React, { useState } from "react";
import { useDisclosure } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import moment from "moment";
import { API } from "../../utils/http";
import { removeReportAsync, updateReportActiveAsync } from "../../redux/user/userSlice";
import Gallery from "../Gallery/Gallery";
import { getDocumentBadge } from "../../utils/utils";
import { fullDateFormat } from "../../utils/types";
import { useTranslation } from "react-i18next";
function ReportsTableRow(props) {
	const { row, propertyId } = props;
	const dispatch = useDispatch();
	const textColor = useColorModeValue("gray.700", "white");
	const deleteDocument = (reportId) => {
		console.log("delete: ", { reportId, propertyId });
		dispatch(removeReportAsync({ reportId, propertyId }));
		//TODO: dispatch remove report
	};
	const { t } = useTranslation();
	const handleGetDocument = () => {
		const payload = { propertyId, reportId: row.id, reportName: row.name };
		console.log(payload);
		API.put("get-report", payload).then((r) => {
			console.log(r);
			window.open(r.data.url, "_blank");
		});
	};
	const markAsActive = (documentId) => {
		const payload = { documentId, propertyId, type: row.type, active: true };
		console.log("mark document as active: ", payload);
		dispatch(updateReportActiveAsync(payload));
	};
	const markAsInactive = (documentId) => {
		const payload = { documentId, propertyId, type: row.type, active: false };
		console.log("mark document as active: ", payload);
		dispatch(updateReportActiveAsync(payload));
	};
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [images, setImages] = useState([]);
	const getReportPictures = () => {
		const payload = { propertyId, media: row.media, reportId: row.id };
		API.put("get-report-picture-urls", payload).then((r) => {
			console.log(r.data);
			setImages(r.data.media);
		});
	};

	const renderViewModal = () => {
		return (
			<Modal isOpen={isOpen} onClose={onClose} size="3xl">
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Documents</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Box>
							<Gallery items={images} />
						</Box>
						<Stack direction={"row"}>
							<Text fontWeight={"bold"}>Report: </Text>
							<Text
								color="teal.500"
								textDecorationLine={"underline"}
								cursor={"pointer"}
								onClick={() => {
									handleGetDocument(row.id);
								}}
								width={"fit-content"}
							>
								{row.name}
								<DownloadIcon marginLeft={2} />
							</Text>
						</Stack>
					</ModalBody>

					<ModalFooter>
						<Button colorScheme="blue" mr={3} onClick={onClose}>
							Close
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		);
	};
	return (
		<Tr>
			<Td minWidth={{ sm: "250px" }} pl="0px">
				<Flex direction={"column"}>
					<Text fontSize="md" color={textColor}>
						{moment(row.addedDate).format(fullDateFormat)}
					</Text>
					{getDocumentBadge(row)}
				</Flex>
			</Td>
			<Td>
				<Text fontSize="md" color={textColor} pb=".5rem">
					{row.name}
				</Text>
			</Td>
			<Td>
				<Text fontSize="md" color={textColor} pb=".5rem">
					{t(`types.${row.type}`)}
				</Text>
			</Td>
			<Td>
				<Text fontSize="md" color={textColor} pb=".5rem">
					{row.description}
				</Text>
			</Td>
			<Td>
				<Text fontSize="md" color={textColor} pb=".5rem">
					{row.number}
				</Text>
			</Td>

			<Td>
				<Menu closeOnSelect={false}>
					<MenuButton>
						<Icon as={HamburgerIcon} />
					</MenuButton>
					<MenuList>
						<MenuItem
							onClick={() => {
								onOpen();
								getReportPictures();
							}}
						>
							View
						</MenuItem>
						{row.active ? (
							<MenuItem
								onClick={() => {
									markAsInactive(row.id);
								}}
							>
								Mark as inactive
							</MenuItem>
						) : (
							<MenuItem
								onClick={() => {
									markAsActive(row.id);
								}}
							>
								Mark as Active
							</MenuItem>
						)}
						<MenuItem
							onClick={() => {
								deleteDocument(row.id);
							}}
						>
							Delete
						</MenuItem>
					</MenuList>
				</Menu>
				{renderViewModal()}
			</Td>
		</Tr>
	);
}

export default ReportsTableRow;
