import {
	Box,
	Button,
	Card,
	CardBody,
	CardHeader,
	Center,
	Flex,
	Stack,
	Text,
	Modal,
	ModalOverlay,
	ModalHeader,
	ModalContent,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	FormControl,
	useDisclosure,
	FormLabel,
	Select,
	Tooltip,
	Input,
	useToast,
	Badge,
} from "@chakra-ui/react";
import Dropzone from "../../../Components/Dropzone/Dropzone";
import React, { useState } from "react";
import { Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { DownloadIcon } from "@chakra-ui/icons";
import { useEffect } from "react";
import { finalizeMonthVerifier, getPropertyAddress } from "../../../utils/verifier";
import { API } from "../../../utils/http";
import { _arrayBufferToBase64 } from "../../../utils/convertors";
import { selectActiveProperties, updateFinancialStatusAsync } from "../../../redux/user/userSlice";
import { fullDateFormat } from "../../../utils/types";
import { useTranslation } from "react-i18next";

export default function FinancialStatus() {
	const properties = useSelector(selectActiveProperties);
	const owner = useSelector((state) => state.user.user);
	const { t } = useTranslation();
	const months = moment.months();
	const [year, setYear] = useState(moment().year());
	const [month, setMonth] = useState();
	const [error, setError] = useState({});
	const financialStatus = owner.financialStatus;
	const initialFinalizeMonthInformation = {
		year: "",
		month: "",
		status: "",
		ownerEmail: owner.email,
		amount: "",
		document: {},
		date: moment().format("yyyy-MM-DD"),
		currency: "",
	};
	const [finalizeMonthInformation, setFinalizeMonthInformation] = useState(initialFinalizeMonthInformation);
	const [status, setStatus] = useState();
	const ownerCurrency = owner.bankDetails.currency;
	const dispatch = useDispatch();
	const changeMonthStatus = () => {
		console.log(`year: ${year} month ${month} new status ${status}`);
		const errors = finalizeMonthVerifier(finalizeMonthInformation);
		console.log(errors);
		setError(errors);
		if (Object.keys(errors).length > 0) {
			toast({
				title: "Error encountered",
				description: "Invalid information",
				status: "error",
				duration: 9000,
				isClosable: true,
			});
			return;
		}
		dispatch(updateFinancialStatusAsync(finalizeMonthInformation)).finally(() => {
			onClose();
			setFinalizeMonthInformation(initialFinalizeMonthInformation);
		});
	};
	useEffect(() => {
		console.log(finalizeMonthInformation);
	}, [finalizeMonthInformation]);
	const onDocumentDrop = async (acceptedFiles) => {
		console.log(acceptedFiles);
		const buffer = await acceptedFiles[0].arrayBuffer();
		const documentName = acceptedFiles[0].name;
		const contentType = acceptedFiles[0].type;

		setFinalizeMonthInformation({ ...finalizeMonthInformation, document: { documentData: _arrayBufferToBase64(buffer), documentName, contentType } });
	};
	const toast = useToast();
	const handleGetDocument = (documentId, documentName) => {
		const payload = { documentId, documentName, ownerEmail: owner.email };
		console.log(payload);
		API.put("get-owner-financial-status-url", payload)
			.then((r) => {
				console.log(r);
				window.open(r.data.url, "_blank");
			})
			.catch((e) => {
				console.log(e);
				toast({
					title: "Error encountered",
					description: e.response.data.error.message,
					status: "error",
					duration: 9000,
					isClosable: true,
				});
			});
	};
	const { isOpen, onOpen, onClose } = useDisclosure();
	const renderChangeMonthStatusModal = () => (
		<Modal isOpen={isOpen} onClose={onClose} size={"xl"}>
			<ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
			<ModalContent>
				<ModalHeader>
					<Text>Finalize month {months[month]}</Text>
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Stack>
						{/* <FormControl>
							<FormLabel display={"inline"}>User Name</FormLabel>
							<Input
								width={"fit-content"}
								type="text"
								name="userName"
								placeholder="User Name"
								_placeholder={{ color: "gray.500" }}
								value={accountInformation.userName}
								onChange={handleChange}
							/>
						</FormControl> */}

						<FormControl display={"flex"} alignItems="center">
							<FormLabel display={"inline"}>Month Status:</FormLabel>
							<Select
								isInvalid={error?.status}
								width={"fit-content"}
								onChange={(e) => {
									setStatus(e.target.value);
									setFinalizeMonthInformation({ ...finalizeMonthInformation, status: e.target.value });
								}}
								value={finalizeMonthInformation?.status}
								placeholder="Month Status"
								_placeholder={{ color: "gray.500" }}
							>
								<option value={"custodie"}>Custodie</option>
								<option value={"transferat"}>Transfer Bancar</option>
								<option value={"predareCash"}>Predare Cash</option>
								<option value={"ownerAccount"}>Cont Proprietar</option>
							</Select>
						</FormControl>

						<Box>
							<Text as="span">Amount: </Text>
							<Text as="span">{finalizeMonthInformation.amount}</Text>
						</Box>
						<Box>
							<Text as="span">Currency: </Text>
							<Text as="span">{finalizeMonthInformation.currency} </Text>
						</Box>
						<FormControl>
							<FormLabel display={"inline"}>Date:</FormLabel>
							<Input
								// isInvalid={error?.expirationDate}
								width={"fit-content"}
								onChange={(e) => {
									setFinalizeMonthInformation({ ...finalizeMonthInformation, date: e.target.value });
								}}
								type="date"
								name="date"
								value={finalizeMonthInformation.date}
								placeholder="Type your message here..."
								_placeholder={{ color: "gray.500" }}
							/>
						</FormControl>
						<Box hidden={!finalizeMonthInformation.document?.documentName}>
							<Text as="span">Document: </Text>
							<Text
								color="teal.500"
								textDecorationLine={"underline"}
								cursor={"pointer"}
								onClick={() => {
									handleGetDocument(finalizeMonthInformation.document.id, finalizeMonthInformation.document.documentName);
								}}
							>
								{finalizeMonthInformation.document?.documentName}
								<DownloadIcon marginLeft={2} />
							</Text>
						</Box>
						<FormControl>
							<FormLabel display={"inline"}>Payment Proof:</FormLabel>
							<Dropzone
								onDrop={onDocumentDrop}
								accept={{
									"application/pdf": [".pdf"],
									"application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
									"application/msword": [".doc"],
								}}
								description="Upload PDF,DOC or DOCX Format"
							/>
						</FormControl>
					</Stack>
				</ModalBody>
				<ModalFooter>
					<Button colorScheme="red" onClick={onClose} mr={3}>
						Cancel
					</Button>
					<Button colorScheme="blue" onClick={changeMonthStatus}>
						Save
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
	const payments = properties.reduce(
		(accumulator, pr) => [
			...accumulator,
			...pr.payments.map((p) => {
				return { ...p, address: getPropertyAddress(pr) };
			}),
		],
		[]
	);
	const getRentColorScheme = (rentAmount, monthIndex) => {
		//if rent from this month is 0 then status is red
		//if rent from this month is not 0 then status is yellow
		//if rent from this month is not 0 and status is transferat then status is green
		if (financialStatus[year] && financialStatus[year][monthIndex]?.status) return "green";
		if (rentAmount === 0) {
			return "red";
		} else {
			if (financialStatus[year]) return financialStatus[year][monthIndex]?.status ? "green" : "yellow";
			return "yellow";
		}
	};
	return (
		<Card overflowX={{ sm: "scroll", xl: "hidden" }}>
			<CardHeader>
				<Text fontSize="xl" fontWeight="bold">
					{t("navbarMenu.financialStatus")}
				</Text>
			</CardHeader>
			<CardBody>
				<Stack>
					{renderChangeMonthStatusModal()}
					<Flex direction="row" justifyContent={"space-around"}>
						<Button
							onClick={() => {
								setYear(year - 1);
							}}
						>
							Prev year
						</Button>
						<Text>{year}</Text>
						<Button
							isDisabled={year === moment().year()}
							onClick={() => {
								setYear(year + 1);
							}}
						>
							Next year
						</Button>
					</Flex>
					<TableContainer>
						<Table size="sm">
							<Thead>
								<Tr>
									<Th>Luna</Th>
									<Th>Address</Th>
									<Th colSpan={2}>Rent Received</Th>
									<Th>Method</Th>
									<Th colSpan={2}>Administration fee</Th>
									<Th colSpan={2}>Tax</Th>
									<Th colSpan={2}>Central Heating</Th>
									<Th colSpan={2}>Insurance</Th>
									<Th colSpan={2}>Others</Th>
									<Th colSpan={2}>Sub-Total</Th>
									<Th colSpan={1}>Status</Th>
								</Tr>
								<Tr>
									<Th></Th>
									<Th></Th>
									{/*  */}
									<Th>Eur</Th>
									<Th>Ron</Th>
									{/*  */}
									<Th></Th>
									<Th>Eur</Th>
									<Th>Ron</Th>
									{/*  */}
									<Th>Eur</Th>
									<Th>Ron</Th>
									{/*  */}
									<Th>Eur</Th>
									<Th>Ron</Th>
									{/*  */}
									<Th>Eur</Th>
									<Th>Ron</Th>
									{/*  */}
									<Th>Eur</Th>
									<Th>Ron</Th>
									{/*  */}
									<Th>Eur</Th>
									<Th>Ron</Th>
									{/*  */}
									<Th></Th>
								</Tr>
							</Thead>
							<Tbody>
								{months.map((m, index) => {
									const invoicesThisMonth = payments.filter((p) => moment(p.paymentDate).month() === index && moment(p.paymentDate).year() === year);
									// console.log(`invoices for month: ${m}: `, invoicesThisMonth);
									// const addressesThisMonth = [...new Set([...payments.map((i) => i.address)])];
									// const addresses = properties.map((p) => getPropertyAddress(p));

									// console.log(addresses);

									const invoicesOnAddress = properties.map((p) => {
										const a = getPropertyAddress(p);
										const invoices = invoicesThisMonth.filter((i) => i.address === a);
										// console.log(`invoices for address ${a} in month ${m}: `, invoices);
										const rentInvoice = invoices
											.filter((i) => i.type === "rent")
											.reduce(
												(accumulator, currentValue) => {
													let amount = 0;
													let exchangeAmount = 0;
													let exchangeCurrency = "";
													if (currentValue.currency === ownerCurrency) {
														amount = Number(currentValue.amount);
														exchangeAmount = Number(currentValue.exchange?.amount);
														exchangeCurrency = currentValue.exchange?.currency;
													} else {
														amount = Number(currentValue.exchange.amount);
														exchangeAmount = Number(currentValue.amount);
														exchangeCurrency = currentValue.currency;
													}
													return {
														...accumulator,
														description: [
															...accumulator.description,
															`${amount} ${ownerCurrency} ${currentValue.paymentMethod}${
																exchangeAmount ? " - " + exchangeAmount + " " + exchangeCurrency : ""
															} - ${currentValue.description}`,
														],
														amount: accumulator.amount + amount,
														paymentMethod: [...accumulator.paymentMethod, currentValue.paymentMethod],
														currency: ownerCurrency,
													};
												},
												{ amount: 0, currency: ownerCurrency, paymentMethod: [], description: [] }
											);
										const administrationFee = invoices
											.filter((i) => i.type === "administrationFee")
											.reduce(
												(accumulator, currentValue) => {
													if (currentValue.currency === ownerCurrency)
														return {
															...accumulator,
															[ownerCurrency]:
																accumulator[ownerCurrency] + Number(currentValue.amount) * (currentValue.paymentDirection === "outgoing" ? -1 : 1),
														};
													else
														return {
															...accumulator,
															[ownerCurrency]:
																accumulator[ownerCurrency] + Number(currentValue.exchange.amount) * (currentValue.paymentDirection === "outgoing" ? -1 : 1),
														};
												},
												{ eur: 0, ron: 0 }
											);
										// console.log("rent total: ", rentInvoice);
										let v = { eur: { amount: 500, paymentMethods: ["cash"] }, ron: { amount: 100, paymentMethods: ["cash"] } }; //asa trebe sa arate obiectul cu totalul facturilor de orice fel
										// const taxInvoice = invoices.find((i) => i.type === "tax");
										const taxInvoice = invoices
											.filter((i) => i.type === "tax")
											.reduce(
												(accumulator, currentValue) => {
													if (currentValue.currency === ownerCurrency)
														return {
															...accumulator,
															[ownerCurrency]:
																accumulator[ownerCurrency] + Number(currentValue.amount) * (currentValue.paymentDirection === "outgoing" ? -1 : 1),
														};
													else
														return {
															...accumulator,
															[ownerCurrency]:
																accumulator[ownerCurrency] + Number(currentValue.exchange.amount) * (currentValue.paymentDirection === "outgoing" ? -1 : 1),
														};
												},
												{ eur: 0, ron: 0 }
											);
										// console.log("tax invoices: ", taxInvoice);
										const insuranceInvoice = invoices
											.filter((i) => i.type === "insurance")
											.reduce(
												(accumulator, currentValue) => {
													if (currentValue.currency === ownerCurrency)
														return {
															...accumulator,
															[ownerCurrency]:
																accumulator[ownerCurrency] + Number(currentValue.amount) * (currentValue.paymentDirection === "outgoing" ? -1 : 1),
														};
													else
														return {
															...accumulator,
															[ownerCurrency]:
																accumulator[ownerCurrency] + Number(currentValue.exchange.amount) * (currentValue.paymentDirection === "outgoing" ? -1 : 1),
														};
												},
												{ eur: 0, ron: 0 }
											);
										const centralHeatingVerificationInvoice = invoices
											.filter((i) => i.type === "centralHeatingVerification")
											.reduce(
												(accumulator, currentValue) => {
													if (currentValue.currency === ownerCurrency)
														return {
															...accumulator,
															[ownerCurrency]:
																accumulator[ownerCurrency] + Number(currentValue.amount) * (currentValue.paymentDirection === "outgoing" ? -1 : 1),
														};
													else
														return {
															...accumulator,
															[ownerCurrency]:
																accumulator[ownerCurrency] + Number(currentValue.exchange.amount) * (currentValue.paymentDirection === "outgoing" ? -1 : 1),
														};
												},
												{ eur: 0, ron: 0 }
											);
										const otherDescription = invoices
											.filter((i) => i.type === "other")
											.map((p) => {
												return `${p.paymentDirection === "incoming" ? "+" : "-"}${p.amount} ${p.currency} - ${p.description || "Fara descriere"}`;
											});
										const otherInvoice = invoices
											.filter((i) => i.type === "other")
											.reduce(
												(accumulator, currentValue) => {
													if (currentValue.currency === ownerCurrency)
														return {
															...accumulator,
															[ownerCurrency]:
																accumulator[ownerCurrency] + Number(currentValue.amount) * (currentValue.paymentDirection === "outgoing" ? -1 : 1),
														};
													else
														return {
															...accumulator,
															[ownerCurrency]:
																accumulator[ownerCurrency] + Number(currentValue.exchange.amount) * (currentValue.paymentDirection === "outgoing" ? -1 : 1),
														};
												},
												{ eur: 0, ron: 0 }
											);
										return {
											invoicesOnAddress: invoices,
											address: a,
											rent: {
												amount: rentInvoice?.amount || 0,
												currency: rentInvoice?.currency || "",
												paymentMethod: rentInvoice?.paymentMethod || "-",
												description: rentInvoice?.description || [],
											},
											tax: taxInvoice,
											insurance: insuranceInvoice,
											centralHeatingVerification: centralHeatingVerificationInvoice,
											other: otherInvoice,
											otherDescription,
											administrationFee: administrationFee,
										};
									});
									const totalProperties = {
										allInvoices: invoicesOnAddress,
										rent: {
											eur: invoicesOnAddress
												.filter((i) => i.rent.currency === "eur")
												.reduce((accumulator, currentValue) => {
													return accumulator + Number(currentValue.rent.amount);
												}, 0),
											ron: invoicesOnAddress
												.filter((i) => i.rent.currency === "ron")
												.reduce((accumulator, currentValue) => {
													return accumulator + Number(currentValue.rent.amount);
												}, 0),
										},
										administrationFee: {
											eur: invoicesOnAddress.reduce((accumulator, currentValue) => {
												return accumulator + Number(currentValue.administrationFee.eur);
											}, 0),
											ron: invoicesOnAddress.reduce((accumulator, currentValue) => {
												return accumulator + Number(currentValue.administrationFee.ron);
											}, 0),
										},
										tax: {
											eur: invoicesOnAddress.reduce((accumulator, currentValue) => {
												return accumulator + Number(currentValue.tax.eur);
											}, 0),
											ron: invoicesOnAddress.reduce((accumulator, currentValue) => {
												return accumulator + Number(currentValue.tax.ron);
											}, 0),
										},
										insurance: {
											eur: invoicesOnAddress.reduce((accumulator, currentValue) => {
												return accumulator + Number(currentValue.insurance.eur);
											}, 0),
											ron: invoicesOnAddress.reduce((accumulator, currentValue) => {
												return accumulator + Number(currentValue.insurance.ron);
											}, 0),
										},
										centralHeatingVerification: {
											eur: invoicesOnAddress.reduce((accumulator, currentValue) => {
												return accumulator + Number(currentValue.centralHeatingVerification.eur);
											}, 0),
											ron: invoicesOnAddress.reduce((accumulator, currentValue) => {
												return accumulator + Number(currentValue.centralHeatingVerification.ron);
											}, 0),
										},
										other: {
											eur: invoicesOnAddress.reduce((accumulator, currentValue) => {
												return accumulator + Number(currentValue.other.eur);
											}, 0),
											ron: invoicesOnAddress.reduce((accumulator, currentValue) => {
												return accumulator + Number(currentValue.other.ron);
											}, 0),
										},
									};
									return (
										<>
											{properties.map((p, index2) => {
												const a = getPropertyAddress(p);
												const invoicesOnAddressTotal = invoicesOnAddress.find((i) => i.address === a);

												return (
													<Tr key={index2}>
														<Td fontWeight={"bold"}>{index2 === 0 ? m : ""}</Td>
														<Td>{a}</Td>
														{/*  */}
														<Td>
															<Tooltip label={invoicesOnAddressTotal?.rent?.description.join("\n")} whiteSpace={"pre-line"}>
																<Text>{invoicesOnAddressTotal?.rent?.currency === "eur" ? Number(invoicesOnAddressTotal?.rent?.amount).toFixed(2) : "0"}</Text>
															</Tooltip>
														</Td>
														<Td>
															<Tooltip label={invoicesOnAddressTotal?.rent?.description.join("\n")} whiteSpace={"pre-line"}>
																<Text>{invoicesOnAddressTotal?.rent?.currency === "ron" ? Number(invoicesOnAddressTotal?.rent?.amount).toFixed(2) : "0"}</Text>
															</Tooltip>
														</Td>
														{/*  */}
														<Td>{invoicesOnAddressTotal?.rent?.paymentMethod.join(", ")}</Td>
														{/*  */}
														<Td>{Number(invoicesOnAddressTotal?.administrationFee.eur).toFixed(2)}</Td>
														<Td>{Number(invoicesOnAddressTotal?.administrationFee.ron).toFixed(2)}</Td>
														{/*  */}
														<Td>{Number(invoicesOnAddressTotal?.tax.eur).toFixed(2)}</Td>
														<Td>{Number(invoicesOnAddressTotal?.tax.ron).toFixed(2)}</Td>
														{/*  */}
														<Td>{Number(invoicesOnAddressTotal?.centralHeatingVerification.eur).toFixed(2)}</Td>
														<Td>{Number(invoicesOnAddressTotal?.centralHeatingVerification.ron).toFixed(2)}</Td>
														{/*  */}
														<Td>{Number(invoicesOnAddressTotal?.insurance.eur).toFixed(2)}</Td>
														<Td>{Number(invoicesOnAddressTotal?.insurance.ron).toFixed(2)}</Td>
														{/*  */}
														<Td>
															<Tooltip label={invoicesOnAddressTotal?.otherDescription.join("\n")} whiteSpace={"pre-line"}>
																<Text>{Number(invoicesOnAddressTotal?.other.eur).toFixed(2)}</Text>
															</Tooltip>
														</Td>
														<Td>
															<Tooltip label={invoicesOnAddressTotal?.otherDescription.join("\n")} whiteSpace={"pre-line"}>
																<Text>{Number(invoicesOnAddressTotal?.other.ron).toFixed(2)}</Text>
															</Tooltip>
														</Td>
														{/*  */}
														<Td>
															{invoicesOnAddressTotal?.rent?.currency === "eur"
																? Number(
																		invoicesOnAddressTotal?.rent?.amount -
																			invoicesOnAddressTotal?.administrationFee.eur +
																			invoicesOnAddressTotal?.tax.eur +
																			invoicesOnAddressTotal?.centralHeatingVerification.eur +
																			invoicesOnAddressTotal?.insurance.eur +
																			invoicesOnAddressTotal?.other.eur
																  ).toFixed(2)
																: "0.00"}
														</Td>
														<Td>
															{invoicesOnAddressTotal?.rent?.currency === "ron"
																? Number(
																		invoicesOnAddressTotal?.rent?.amount -
																			invoicesOnAddressTotal?.administrationFee.ron +
																			invoicesOnAddressTotal?.tax.ron +
																			invoicesOnAddressTotal?.centralHeatingVerification.ron +
																			invoicesOnAddressTotal?.insurance.ron +
																			invoicesOnAddressTotal?.other.ron
																  ).toFixed(2)
																: "0.00"}
														</Td>
													</Tr>
												);
											})}
											<Tr
												bg="teal"
												fontWeight={"bold"}
												key={index}
												style={{ cursor: "pointer" }}
												_hover={{ bg: "teal.500" }}
												onClick={() => {
													setMonth(index);
													console.log("=========================================================");
													// const allInvoices = totalProperties.allInvoices.map((a) => a.invoicesOnAddress).flat();
													// console.log(allInvoices);
													const bankAccountCurrency = owner.bankDetails.currency;
													// const totalAmountInAccountCurrency = allInvoices.reduce((accumulator, currentValue) => {
													// 	if (bankAccountCurrency === currentValue.currency) {
													// 		console.log("This invoice is in the correct currency: ", currentValue);
													// 		console.log("Amount: ", Number(currentValue.amount) * (currentValue.paymentDirection === "incoming" ? 1 : -1));
													// 		return accumulator + Number(currentValue.amount) * (currentValue.paymentDirection === "incoming" ? 1 : -1);
													// 	}
													// 	console.log("This invoice is NOT in the correct currency: ", currentValue);
													// 	console.log("Amount: ", Number(currentValue.exchange?.amount || 0) * (currentValue.paymentDirection === "incoming" ? 1 : -1));

													// 	return accumulator + Number(currentValue.exchange?.amount || 0) * (currentValue.paymentDirection === "incoming" ? 1 : -1);
													// }, 0);

													setFinalizeMonthInformation({
														...finalizeMonthInformation,
														month: index,
														year: year,
														currency: owner.bankDetails.currency,
														amount: Number(
															bankAccountCurrency === "ron"
																? totalProperties?.rent?.ron -
																		totalProperties?.administrationFee.ron +
																		totalProperties?.tax.ron +
																		totalProperties?.centralHeatingVerification.ron +
																		totalProperties?.insurance.ron +
																		totalProperties?.other.ron
																: totalProperties?.rent?.eur -
																		totalProperties?.administrationFee.eur +
																		totalProperties?.tax.eur +
																		totalProperties?.centralHeatingVerification.eur +
																		totalProperties?.insurance.eur +
																		totalProperties?.other.eur
														).toFixed(2),
														status: financialStatus[year] ? financialStatus[year][index]?.status : "",
														date: financialStatus[year] ? financialStatus[year][index]?.date : "" || initialFinalizeMonthInformation.date,
														document: financialStatus[year] ? financialStatus[year][index]?.document : {},
													});
													// onOpen();
												}}
											>
												<Td>
													<Text>Total</Text>
												</Td>
												<Td></Td>
												{/*  */}
												<Td>
													{owner.bankDetails.currency === "eur" ? (
														<Badge colorScheme={getRentColorScheme(totalProperties?.rent.eur, index)}>{Number(totalProperties?.rent.eur).toFixed(2)}</Badge>
													) : (
														<Text>{Number(totalProperties?.rent.eur).toFixed(2)}</Text>
													)}
												</Td>
												<Td>
													{owner.bankDetails.currency === "ron" ? (
														<Badge colorScheme={getRentColorScheme(totalProperties?.rent.ron, index)}>{Number(totalProperties?.rent.ron).toFixed(2)}</Badge>
													) : (
														<Text>{Number(totalProperties?.rent.ron).toFixed(2)}</Text>
													)}
												</Td>
												{/*  */}
												<Td></Td>
												{/*  */}
												<Td>{Number(totalProperties?.administrationFee.eur).toFixed(2)}</Td>
												<Td>{Number(totalProperties?.administrationFee.ron).toFixed(2)}</Td>
												{/*  */}
												<Td>{Number(totalProperties?.tax.eur).toFixed(2)}</Td>
												<Td>{Number(totalProperties?.tax.ron).toFixed(2)}</Td>
												{/*  */}
												<Td>{Number(totalProperties?.centralHeatingVerification.eur).toFixed(2)}</Td>
												<Td>{Number(totalProperties?.centralHeatingVerification.ron).toFixed(2)}</Td>
												{/*  */}
												<Td>{Number(totalProperties?.insurance.eur).toFixed(2)}</Td>
												<Td>{Number(totalProperties?.insurance.ron).toFixed(2)}</Td>
												{/*  */}
												<Td>{Number(totalProperties?.other.eur).toFixed(2)}</Td>
												<Td>{Number(totalProperties?.other.ron).toFixed(2)}</Td>
												{/*  */}
												<Td>
													{Number(
														totalProperties?.rent?.eur -
															totalProperties?.administrationFee.eur +
															totalProperties?.tax.eur +
															totalProperties?.centralHeatingVerification.eur +
															totalProperties?.insurance.eur +
															totalProperties?.other.eur
													).toFixed(2)}
												</Td>
												<Td>
													{Number(
														totalProperties?.rent?.ron -
															totalProperties?.administrationFee.ron +
															totalProperties?.tax.ron +
															totalProperties?.centralHeatingVerification.ron +
															totalProperties?.insurance.ron +
															totalProperties?.other.ron
													).toFixed(2)}
												</Td>
												<Td>
													{financialStatus[year]
														? financialStatus[year][index]
															? `${financialStatus[year][index].status} - ${moment(financialStatus[year][index].date).format(fullDateFormat)}`
															: "-"
														: "-"}
												</Td>
											</Tr>
										</>
									);
								})}
							</Tbody>
							<Tfoot>
								<Tr>
									<Th></Th>
									<Th></Th>
									{/*  */}
									<Th>Eur</Th>
									<Th>Ron</Th>
									{/*  */}
									<Th></Th>
									<Th>Eur</Th>
									<Th>Ron</Th>
									{/*  */}
									<Th>Eur</Th>
									<Th>Ron</Th>
									{/*  */}
									<Th>Eur</Th>
									<Th>Ron</Th>
									{/*  */}
									<Th>Eur</Th>
									<Th>Ron</Th>
									{/*  */}
									<Th>Ron</Th>
									<Th>Eur</Th>
									{/*  */}
									<Th>Ron</Th>
									<Th>Eur</Th>
									{/*  */}
									<Th></Th>
								</Tr>
								<Tr>
									<Th>Luna</Th>

									<Th>Address</Th>
									<Th colSpan={2}>Rent Received</Th>
									<Th>Method</Th>
									<Th colSpan={2}>Administration fee</Th>
									<Th colSpan={2}>Tax</Th>
									<Th colSpan={2}>Central Heating</Th>
									<Th colSpan={2}>Insurance</Th>
									<Th colSpan={2}>Others</Th>
									<Th colSpan={2}>Sub-Total</Th>
									<Th>Status</Th>
								</Tr>
							</Tfoot>
						</Table>
					</TableContainer>
				</Stack>
			</CardBody>
		</Card>
	);
}
