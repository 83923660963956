import { Card, CardBody, CardHeader, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import FinancialStatus from "./FinancialStatus";
import PersonalInformation from "./PersonalInformation";
import BillingInformation from "./BillingInformation";
import AdministrationFees from "./AdministrationFees";
import { selectActiveProperties } from "../../redux/user/userSlice";

export default function Owner() {
	const { id } = useParams();
	const owner = useSelector(selectActiveProperties)?.find((p) => p.owner.id === id).owner;
	const properties = useSelector(selectActiveProperties).filter((p) => owner.properties.includes(p.id));
	const tabs = [
		{ title: "Personal Information", component: <PersonalInformation owner={owner} /> },
		{ title: "Financial Status", component: <FinancialStatus owner={owner} properties={properties} /> },
		{ title: "Billing Information", component: <BillingInformation owner={owner} properties={properties} /> },
		{ title: "Administration Fees", component: <AdministrationFees owner={owner} properties={properties} /> },
	];
	useEffect(() => {
		console.log("properties: ", properties);
		console.log("owner: ", owner);
	}, [owner, properties]);
	return (
		<Card>
			<CardHeader fontSize="xl" fontWeight="bold">
				Owner Details
			</CardHeader>
			<CardBody>
				<Tabs variant="enclosed">
					<TabList overflowX="auto" overflowY="hidden">
						{tabs.map((t, index) => (
							<Tab key={index}>{t.title}</Tab>
						))}
					</TabList>
					<TabPanels>
						{tabs.map((t, index) => (
							<TabPanel key={index}>
								<Card>
									<CardHeader fontWeight="bold" fontSize="xl" pb={0}>
										{t.title}
									</CardHeader>
									<CardBody>{t.component}</CardBody>
								</Card>
							</TabPanel>
						))}
					</TabPanels>
				</Tabs>
			</CardBody>
		</Card>
	);
}
