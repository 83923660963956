import { Box, Button, Select, Stack, Td, Textarea, useDisclosure, useToast } from "@chakra-ui/react";
import {
	Flex,
	Table,
	Tbody,
	Text,
	Th,
	Thead,
	Tr,
	Modal,
	ModalOverlay,
	ModalHeader,
	ModalContent,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	Heading,
	FormControl,
	FormLabel,
	Input,
} from "@chakra-ui/react";

import React, { useState } from "react";

import ActivitiesTableRow from "../TableRows/ActivitiesTableRow";
import { addActivityVerifier } from "../../utils/verifier";
import { useDispatch } from "react-redux";
import { addActivityAsync } from "../../redux/user/userSlice";
import { useTranslation } from "react-i18next";

export default function ActivitiesTab({ property }) {
	const { t } = useTranslation();
	const isArchived = property.archived;
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [error, setError] = useState({});
	const initialActivityInformation = {
		type: "",
		title: "",
		description: "",
	};
	const [activityInformation, setActivityInformation] = useState(initialActivityInformation);
	const toast = useToast();
	const dispatch = useDispatch();
	const addActivity = () => {
		const payload = { ...activityInformation, propertyId: property.id };
		console.log(payload);
		const errors = addActivityVerifier(activityInformation);
		if (Object.keys(errors).length > 0) {
			toast({
				title: "Error encountered",
				description: "Please fill all the fields",
				status: "error",
				duration: 9000,
				isClosable: true,
			});
			setError(errors);
			return;
		}
		setError({});
		dispatch(addActivityAsync(payload)).then((r) => {
			if (r.error?.message)
				toast({
					title: "Error encountered",
					description: r.error.message,
					status: "error",
					duration: 9000,
					isClosable: true,
				});
			else {
				toast({
					title: "Success!",
					description: "Activity added successfully",
					status: "success",
					duration: 2000,
					isClosable: true,
				});
				onClose();
			}
		});
	};
	const handleActivityInformationChange = (e) => {
		const { name, value } = e.target;
		setActivityInformation({ ...activityInformation, [name]: value });
	};
	const renderAddActivityModal = () => (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
			<ModalContent>
				<ModalHeader>
					<Stack>
						<Heading>Activity Details</Heading>
					</Stack>
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Flex align={"center"}>
						<Stack spacing={2}>
							<FormControl display="flex" alignItems={"center"}>
								<FormLabel fontWeight="bold">Title:</FormLabel>
								<Input
									name="title"
									placeholder="Title..."
									_placeholder={{ color: "gray.500" }}
									type="text"
									value={activityInformation.title}
									onChange={handleActivityInformationChange}
								/>
							</FormControl>
							<FormControl display="flex" alignItems={"center"}>
								<FormLabel fontWeight="bold">Description:</FormLabel>
								<Textarea
									name="description"
									placeholder="Description..."
									_placeholder={{ color: "gray.500" }}
									value={activityInformation.description}
									onChange={handleActivityInformationChange}
								/>
							</FormControl>
							<FormControl display="flex" alignItems={"center"}>
								<FormLabel fontWeight="bold">Type:</FormLabel>
								<Select
									width={"fit-content"}
									type="number"
									name="type"
									placeholder="Type"
									_placeholder={{ color: "gray.500" }}
									value={activityInformation.type}
									onChange={handleActivityInformationChange}
								>
									<option value="insurance">Asigurare obligatorie</option>
									<option value="administrationContract">Contract de administrare</option>
									<option value="lease">Contract de inchiriere</option>
									<option value="email">Email</option>
									<option value="whatsapp">Whatsapp</option>
									<option value="conversation">Convorbire</option>
									<option value="other">Altele</option>
								</Select>
							</FormControl>
						</Stack>
					</Flex>
				</ModalBody>
				<ModalFooter>
					<Button colorScheme="red" onClick={onClose} mr={3}>
						{t("misc.cancel")}
					</Button>
					<Button colorScheme="blue" onClick={addActivity}>
						{t("misc.save")}
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
	return (
		<Stack>
			<Box>
				<Button colorScheme={"blue"} onClick={onOpen} isDisabled={isArchived}>
					{t("property.addActivity")}
				</Button>
				{renderAddActivityModal()}
			</Box>
			<Box overflowX="auto">
				<Table variant="simple" size="sm">
					<Thead>
						<Tr my=".8rem" pl="0px">
							<Th>{t("table.addedDate")}</Th>
							<Th>{t("table.title")}</Th>
							<Th>{t("table.type")}</Th>
							<Th>{t("table.description")}</Th>
							<Th></Th>
						</Tr>
					</Thead>
					<Tbody>
						{property.activities?.map((a, index) => (
							<ActivitiesTableRow key={index} activity={a} property={property} />
						))}
						{property.activities?.length === 0 && (
							<Tr>
								<Td colSpan={5}>
									<Text>{t("table.noData")}</Text>
								</Td>
							</Tr>
						)}
					</Tbody>
				</Table>
			</Box>
		</Stack>
	);
}
