import React, { useState } from "react";
import "./style.css";
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	Button,
	Image,
	Stack,
	Box,
	Badge,
	Text,
} from "@chakra-ui/react";
import moment from "moment";
import { fullDateFormat } from "../../utils/types";
export default function Gallery({ items, handleMediaDelete }) {
	console.log("items:");
	console.log(items);
	const [selectedItem, setSelectedItem] = useState(null);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const handleItemClick = (item) => {
		setSelectedItem(item);
		onOpen();
	};
	return (
		<Box className="gallery">
			{items.map((item) => (
				<div key={item.id} onClick={() => handleItemClick(item)}>
					{item.contentType.includes("image") ? (
						<Box>
							<Badge colorScheme="teal" variant={"outline"} position="absolute">
								Image
							</Badge>
							{item.type && (
								<Badge colorScheme="red" variant={"outline"} position="absolute" top="0" right="0">
									{item.type}
								</Badge>
							)}
							{item.addedDate && (
								<Badge colorScheme="green" variant={"outline"} position="absolute" top="100%" left="0">
									{moment(item.addedDate).format(fullDateFormat)}
								</Badge>
							)}
							<Image src={item.url} alt={item.name} />
						</Box>
					) : item.contentType.includes("pdf") ? (
						<iframe src={item.url} width="100%" height="600px"></iframe>
					) : (
						<Box>
							<Badge colorScheme="yellow" variant={"outline"}>
								Video
							</Badge>
							<video src={item.url} style={{ maxHeight: "400px", margin: "0 auto" }} />
						</Box>
					)}
				</div>
			))}
			{selectedItem && (
				<Modal onClose={onClose} isOpen={isOpen} isCentered size={"6xl"} scrollBehavior="inside">
					<ModalOverlay />
					<ModalContent>
						<ModalHeader>
							<Stack>
								<Text>Preview</Text>
								<Box>
									{selectedItem.type && (
										<Text>
											Type: <Badge colorScheme="red">{selectedItem.type}</Badge>
										</Text>
									)}
									{selectedItem.addedDate && (
										<Text>
											Added Date:<Badge colorScheme="green">{moment(selectedItem.addedDate).format(fullDateFormat)}</Badge>
										</Text>
									)}
								</Box>
							</Stack>
						</ModalHeader>
						<ModalCloseButton />
						<ModalBody>
							{selectedItem.contentType.includes("image") ? (
								<Image src={selectedItem.url} alt={selectedItem.name} margin="0 auto" maxHeight={"400px"} />
							) : selectedItem.contentType.includes("pdf") ? (
								<iframe src={selectedItem.url} width="100%" height="600px"></iframe>
							) : (
								<video src={selectedItem.url} controls style={{ maxHeight: "400px", margin: "0 auto" }} />
							)}
						</ModalBody>
						<ModalFooter>
							<Stack direction={"row"}>
								<Button
									hidden={typeof handleMediaDelete != "function"}
									onClick={() => {
										onClose();
										handleMediaDelete(selectedItem);
									}}
									colorScheme="red"
								>
									Delete
								</Button>
								<Button
									onClick={() => {
										onClose();
									}}
								>
									Close
								</Button>
							</Stack>
						</ModalFooter>
					</ModalContent>
				</Modal>
			)}
		</Box>
	);
}
