import React from "react";
import {
	Popover,
	PopoverTrigger,
	PopoverContent,
	PopoverHeader,
	PopoverBody,
	PopoverArrow,
	PopoverCloseButton,
	Button,
	Stack,
	Text,
	Box,
	Link,
	Divider,
} from "@chakra-ui/react";
import { NavLink as ReactRouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function OwnerPopover({ contact }) {
	const { t } = useTranslation();
	return (
		<Popover>
			<PopoverTrigger>
				<Button width={"fit-content"} size={"sm"} colorScheme="blue" textTransform={"uppercase"}>
					{contact.name}
				</Button>
			</PopoverTrigger>
			<PopoverContent>
				<PopoverArrow />
				<PopoverCloseButton />
				<PopoverHeader>
					{t("property.contactInformation")}-{" "}
					<Text as="span" fontWeight={"bold"}>
						{t("table.owner")}
					</Text>
				</PopoverHeader>
				<PopoverBody>
					<Stack style={{ whiteSpace: "normal" }}>
						<Box>
							<Text as={"span"} fontWeight={"bold"}>
								{t("property.name")}:
							</Text>{" "}
							{contact.name}
						</Box>
						<Box>
							<Text as={"span"} fontWeight={"bold"}>
								{t("property.email")}:
							</Text>{" "}
							{contact.email}
						</Box>
						<Box>
							<Text as={"span"} fontWeight={"bold"}>
								{t("property.phoneNumber")}:
							</Text>{" "}
							{contact.phoneNumber}
						</Box>
						<Box>
							<Text as={"span"} fontWeight={"bold"}>
								{t("property.phoneNumber2")}:
							</Text>{" "}
							{contact.phoneNumber2 || "-"}
						</Box>
						<Divider />
						<Box>
							<Link as={ReactRouterLink} to={`/owner/view/${contact.id}`}>
								<Button colorScheme="blue">{t("table.view")}</Button>
							</Link>
						</Box>
					</Stack>
				</PopoverBody>
			</PopoverContent>
		</Popover>
	);
}
