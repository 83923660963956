import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
	addAccountApi,
	addActivityApi,
	addAdministrationFeePaymentApi,
	addAgentApi,
	addAssociationApi,
	addContactApi,
	addCustomOwnerPlanApi,
	addDocumentApi,
	addFinancialStatementApi,
	addLeaseContactApi,
	addMediaApi,
	addMessageApi,
	addOwnerDocumentApi,
	addPaymentApi,
	addPaymentDocumentApi,
	addProtocolApi,
	addRentPaymentApi,
	addReplyToMessageApi,
	addReplyToTicketApi,
	addReportApi,
	addSecurityDepositApi,
	addTenantApi,
	addTenantDocumentApi,
	addTicketApi,
	addUtilityMeterPictureApi,
	addUtilityPaymentApi,
	archivePropertyApi,
	createPropertyApi,
	createUserApi,
	generateCombinedAdministrationFeePaymentApi,
	getDashboardApi,
	markMultiplePaymentAsPaidApi,
	markPaymentAsPaidApi,
	markRentAsPaidApi,
	markTenantPaymentAsPaidApi,
	markTicketAsDoneApi,
	removeAccountApi,
	removeActivityApi,
	removeAssociationApi,
	removeContactApi,
	removeCustomOwnerPlanApi,
	removeDocumentApi,
	removeFinancialStatementApi,
	removeMediaApi,
	removeOwnerDocumentApi,
	removePaymentApi,
	removeProtocolApi,
	removeReportApi,
	removeSecurityDepositApi,
	removeTenantDocumentApi,
	removeTicketApi,
	requestMeterPictureApi,
	restorePropertyApi,
	sendMassNotificationApi,
	updateAdditionalStepsApi,
	updateAgencyApi,
	updateAssociationApi,
	updateCustomOwnerPlanApi,
	updateDetailsApi,
	updateDocumentActiveApi,
	updateFinancialStatementActiveApi,
	updateFinancialStatusApi,
	updateMeterInformationApi,
	updateOwnerApi,
	updatePropertyCustomOwnerPlanApi,
	updateRentApi,
	updateReportActiveApi,
	updateSecurityDepositApi,
	updateSpecsApi,
	updateTemplatesApi,
	updateTenantApi,
	updateTenantFormerApi,
	uploadOwnerIdApi,
	uploadSmartContractApi,
	uploadTenantIdApi,
} from "./userAPI";
import { getAccType } from "../../utils/utils";
const initialState = {
	user: null,
	status: "idle",
};
// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const getDashboardAsync = createAsyncThunk("user/getDashboard", async () => {
	const response = await getDashboardApi();
	// The value we return becomes the `fulfilled` action payload
	return response;
});
export const createPropertyAsync = createAsyncThunk("user/createProperty", async (request) => {
	const response = await createPropertyApi(request);
	// The value we return becomes the `fulfilled` action payload
	return response;
});
export const archivePropertyAsync = createAsyncThunk("user/archiveProperty", async (request) => {
	const response = await archivePropertyApi(request);
	// The value we return becomes the `fulfilled` action payload
	return response;
});
export const restorePropertyAsync = createAsyncThunk("user/restoreProperty", async (request) => {
	const response = await restorePropertyApi(request);
	// The value we return becomes the `fulfilled` action payload
	return response;
});
export const uploadSmartContractAsync = createAsyncThunk("user/uploadSmartContract", async (request) => {
	const response = await uploadSmartContractApi(request);
	// The value we return becomes the `fulfilled` action payload
	return response;
});
export const addDocumentAsync = createAsyncThunk("user/addDocument", async (request) => {
	const response = await addDocumentApi(request);
	// The value we return becomes the `fulfilled` action payload
	return response;
});
export const addReportAsync = createAsyncThunk("user/addReport", async (request) => {
	const response = await addReportApi(request);
	// The value we return becomes the `fulfilled` action payload
	return response;
});
export const addProtocolAsync = createAsyncThunk("user/addProtocol", async (request) => {
	const response = await addProtocolApi(request);
	// The value we return becomes the `fulfilled` action payload
	return response;
});
export const addPaymentAsync = createAsyncThunk("user/addPayment", async (request) => {
	const response = await addPaymentApi(request);
	// The value we return becomes the `fulfilled` action payload
	return response;
});
export const addRentPaymentAsync = createAsyncThunk("user/addRentPayment", async (request) => {
	const response = await addRentPaymentApi(request);
	// The value we return becomes the `fulfilled` action payload
	return response;
});
export const addAdministrationFeePaymentAsync = createAsyncThunk("user/addAdministrationFeePayment", async (request) => {
	const response = await addAdministrationFeePaymentApi(request);
	// The value we return becomes the `fulfilled` action payload
	return response;
});
export const addPaymentDocumentAsync = createAsyncThunk("user/addDocumentPayment", async (request) => {
	const response = await addPaymentDocumentApi(request);
	// The value we return becomes the `fulfilled` action payload
	return response;
});
export const addFinancialStatementAsync = createAsyncThunk("user/addFinancialStatement", async (request) => {
	const response = await addFinancialStatementApi(request);
	// The value we return becomes the `fulfilled` action payload
	return response;
});
export const addSecurityDepositAsync = createAsyncThunk("user/addSecurityDeposit", async (request) => {
	const response = await addSecurityDepositApi(request);
	// The value we return becomes the `fulfilled` action payload
	return response;
});
export const updateSecurityDepositAsync = createAsyncThunk("user/updateSecurityDeposit", async (request) => {
	const response = await updateSecurityDepositApi(request);
	// The value we return becomes the `fulfilled` action payload
	return response;
});
export const addUtilityPaymentAsync = createAsyncThunk("user/addUtilityPayment", async (request) => {
	const response = await addUtilityPaymentApi(request);
	// The value we return becomes the `fulfilled` action payload
	return response;
});
export const addContactAsync = createAsyncThunk("user/addContact", async (request) => {
	const response = await addContactApi(request);
	// The value we return becomes the `fulfilled` action payload
	return response;
});
export const addActivityAsync = createAsyncThunk("user/addActivity", async (request) => {
	const response = await addActivityApi(request);
	// The value we return becomes the `fulfilled` action payload
	return response;
});
export const addAssociationAsync = createAsyncThunk("user/addAssociation", async (request) => {
	const response = await addAssociationApi(request);
	// The value we return becomes the `fulfilled` action payload
	return response;
});
export const updateAssociationAsync = createAsyncThunk("user/updateAssociation", async (request) => {
	const response = await updateAssociationApi(request);
	// The value we return becomes the `fulfilled` action payload
	return response;
});
export const removeAssociationAsync = createAsyncThunk("user/removeAssociation", async (request) => {
	const response = await removeAssociationApi(request);
	// The value we return becomes the `fulfilled` action payload
	return response;
});
export const addMediaAsync = createAsyncThunk("user/addMedia", async (request) => {
	const response = await addMediaApi(request);
	// The value we return becomes the `fulfilled` action payload
	return response;
});
export const markTenantPaymentAsPaidAsync = createAsyncThunk("user/markTenantPaymentAsPaid", async (request) => {
	const response = await markTenantPaymentAsPaidApi(request);
	// The value we return becomes the `fulfilled` action payload
	return response;
});
export const markPaymentAsPaidAsync = createAsyncThunk("user/markPaymentAsPaid", async (request) => {
	const response = await markPaymentAsPaidApi(request);
	// The value we return becomes the `fulfilled` action payload
	return response;
});
export const markMultiplePaymentsAsPaidAsync = createAsyncThunk("user/markMultiplePaymentAsPaid", async (request) => {
	const response = await markMultiplePaymentAsPaidApi(request);
	// The value we return becomes the `fulfilled` action payload
	return response;
});
export const markRentAsPaidAsync = createAsyncThunk("user/markRentAsPaid", async (request) => {
	const response = await markRentAsPaidApi(request);
	// The value we return becomes the `fulfilled` action payload
	return response;
});
export const addTenantAsync = createAsyncThunk("user/addTenant", async (request) => {
	const response = await addTenantApi(request);
	// The value we return becomes the `fulfilled` action payload
	return response;
});
export const addLeaseContactAsync = createAsyncThunk("user/addLeaseContact", async (request) => {
	const response = await addLeaseContactApi(request);
	// The value we return becomes the `fulfilled` action payload
	return response;
});
export const updateTenantFormerAsync = createAsyncThunk("user/updateTenantFormer", async (request) => {
	const response = await updateTenantFormerApi(request);
	// The value we return becomes the `fulfilled` action payload
	return response;
});
export const addAccountAsync = createAsyncThunk("user/addAccount", async (request) => {
	const response = await addAccountApi(request);
	// The value we return becomes the `fulfilled` action payload
	return response;
});
export const addAgentAsync = createAsyncThunk("user/addAgent", async (request) => {
	const response = await addAgentApi(request);
	// The value we return becomes the `fulfilled` action payload
	return response;
});
export const addCustomOwnerPlanAsync = createAsyncThunk("user/addCustomOwnerPlan", async (request) => {
	const response = await addCustomOwnerPlanApi(request);
	// The value we return becomes the `fulfilled` action payload
	return response;
});
export const updateCustomOwnerPlanAsync = createAsyncThunk("user/updateCustomOwnerPlan", async (request) => {
	const response = await updateCustomOwnerPlanApi(request);
	// The value we return becomes the `fulfilled` action payload
	return response;
});
export const removeCustomOwnerPlanAsync = createAsyncThunk("user/removeCustomOwnerPlan", async (request) => {
	const response = await removeCustomOwnerPlanApi(request);
	// The value we return becomes the `fulfilled` action payload
	return response;
});
export const updatePropertyCustomOwnerPlanAsync = createAsyncThunk("user/updatePropertyCustomOwnerPlan", async (request) => {
	const response = await updatePropertyCustomOwnerPlanApi(request);
	// The value we return becomes the `fulfilled` action payload
	return response;
});
export const updateAdditionalStepsAsync = createAsyncThunk("user/updateAdditionalSteps", async (request) => {
	const response = await updateAdditionalStepsApi(request);
	// The value we return becomes the `fulfilled` action payload
	return response;
});
export const updateDocumentActiveAsync = createAsyncThunk("user/updateDocumentActive", async (request) => {
	const response = await updateDocumentActiveApi(request);
	// The value we return becomes the `fulfilled` action payload
	return response;
});
export const updateReportActiveAsync = createAsyncThunk("user/updateReportActive", async (request) => {
	const response = await updateReportActiveApi(request);
	// The value we return becomes the `fulfilled` action payload
	return response;
});
export const updateFinancialStatementActiveAsync = createAsyncThunk("user/updateFinancialStatementActive", async (request) => {
	const response = await updateFinancialStatementActiveApi(request);
	// The value we return becomes the `fulfilled` action payload
	return response;
});
export const updateSpecsAsync = createAsyncThunk("user/updateSpecs", async (request) => {
	const response = await updateSpecsApi(request);
	// The value we return becomes the `fulfilled` action payload
	return response;
});
export const updateRentAsync = createAsyncThunk("user/updateRent", async (request) => {
	const response = await updateRentApi(request);
	// The value we return becomes the `fulfilled` action payload
	return response;
});
export const updateDetailsAsync = createAsyncThunk("user/updateDetails", async (request) => {
	const response = await updateDetailsApi(request);
	// The value we return becomes the `fulfilled` action payload
	return response;
});
export const updateMeterInformationAsync = createAsyncThunk("user/updateMeterInformation", async (request) => {
	const response = await updateMeterInformationApi(request);
	// The value we return becomes the `fulfilled` action payload
	return response;
});
export const requestMeterPictureAsync = createAsyncThunk("user/requestMeterPicture", async (request) => {
	const response = await requestMeterPictureApi(request);
	// The value we return becomes the `fulfilled` action payload
	return response;
});
export const updateFinancialStatusAsync = createAsyncThunk("user/updateFinancialStatus", async (request) => {
	const response = await updateFinancialStatusApi(request);
	// The value we return becomes the `fulfilled` action payload
	return response;
});
export const uploadOwnerIdAsync = createAsyncThunk("user/uploadOwnerId", async (request) => {
	const response = await uploadOwnerIdApi(request);
	// The value we return becomes the `fulfilled` action payload
	return response;
});
export const uploadTenantIdAsync = createAsyncThunk("user/uploadTenantId", async (request) => {
	const response = await uploadTenantIdApi(request);
	// The value we return becomes the `fulfilled` action payload
	return response;
});
export const updateOwnerAsync = createAsyncThunk("user/updateOwner", async (request) => {
	const response = await updateOwnerApi(request);
	// The value we return becomes the `fulfilled` action payload
	return response;
});
export const generateCombinedAdministrationFeePaymentAsync = createAsyncThunk("user/generateCombinedAdministrationFeePayment", async (request) => {
	const response = await generateCombinedAdministrationFeePaymentApi(request);
	// The value we return becomes the `fulfilled` action payload
	return response;
});
export const updateAgencyAsync = createAsyncThunk("user/updateAgency", async (request) => {
	const response = await updateAgencyApi(request);
	// The value we return becomes the `fulfilled` action payload
	return response;
});
export const updateTemplatesAsync = createAsyncThunk("user/updateTemplates", async (request) => {
	const response = await updateTemplatesApi(request);
	// The value we return becomes the `fulfilled` action payload
	return response;
});
export const updateTenantAsync = createAsyncThunk("user/updateTenant", async (request) => {
	const response = await updateTenantApi(request);
	// The value we return becomes the `fulfilled` action payload
	return response;
});
export const addTenantDocumentAsync = createAsyncThunk("user/addTenantDocument", async (request) => {
	const response = await addTenantDocumentApi(request);
	// The value we return becomes the `fulfilled` action payload
	return response;
});
export const addOwnerDocumentAsync = createAsyncThunk("user/addOwnerDocument", async (request) => {
	const response = await addOwnerDocumentApi(request);
	// The value we return becomes the `fulfilled` action payload
	return response;
});
export const removeDocumentAsync = createAsyncThunk("user/removeDocument", async (request) => {
	const response = await removeDocumentApi(request);
	// The value we return becomes the `fulfilled` action payload
	return response;
});
export const removeActivityAsync = createAsyncThunk("user/removeActivity", async (request) => {
	const response = await removeActivityApi(request);
	// The value we return becomes the `fulfilled` action payload
	return response;
});
export const removeTenantDocumentAsync = createAsyncThunk("user/removeTenantDocument", async (request) => {
	const response = await removeTenantDocumentApi(request);
	// The value we return becomes the `fulfilled` action payload
	return response;
});
export const removeOwnerDocumentAsync = createAsyncThunk("user/removeOwnerDocument", async (request) => {
	const response = await removeOwnerDocumentApi(request);
	// The value we return becomes the `fulfilled` action payload
	return response;
});
export const removePaymentAsync = createAsyncThunk("user/removePayment", async (request) => {
	const response = await removePaymentApi(request);
	// The value we return becomes the `fulfilled` action payload
	return response;
});
export const removeSecurityDepositAsync = createAsyncThunk("user/removeSecurityDeposit", async (request) => {
	const response = await removeSecurityDepositApi(request);
	// The value we return becomes the `fulfilled` action payload
	return response;
});
export const removeFinancialStatementAsync = createAsyncThunk("user/removeFinancialStatement", async (request) => {
	const response = await removeFinancialStatementApi(request);
	// The value we return becomes the `fulfilled` action payload
	return response;
});
export const removeReportAsync = createAsyncThunk("user/removeReport", async (request) => {
	const response = await removeReportApi(request);
	// The value we return becomes the `fulfilled` action payload
	return response;
});
export const removeProtocolAsync = createAsyncThunk("user/removeProtocol", async (request) => {
	const response = await removeProtocolApi(request);
	// The value we return becomes the `fulfilled` action payload
	return response;
});
export const removeMediaAsync = createAsyncThunk("user/removeMedia", async (request) => {
	const response = await removeMediaApi(request);
	// The value we return becomes the `fulfilled` action payload
	return response;
});
export const removeContactAsync = createAsyncThunk("user/removeContact", async (request) => {
	const response = await removeContactApi(request);
	// The value we return becomes the `fulfilled` action payload
	return response;
});
export const removeAccountAsync = createAsyncThunk("user/removeAccount", async (request) => {
	const response = await removeAccountApi(request);
	// The value we return becomes the `fulfilled` action payload
	return response;
});
export const createUserAsync = createAsyncThunk("user/createUser", async (request) => {
	const response = await createUserApi(request);
	// The value we return becomes the `fulfilled` action payload
	return response;
});
export const sendMassNotificationsAsync = createAsyncThunk("user/sendMassNotifications", async (request) => {
	const response = await sendMassNotificationApi(request);
	// The value we return becomes the `fulfilled` action payload
	return response;
});
export const addMessageAsync = createAsyncThunk("user/addMessage", async (request) => {
	const response = await addMessageApi(request);
	// The value we return becomes the `fulfilled` action payload
	return response;
});
export const addReplyToTicketAsync = createAsyncThunk("user/addReplyToTicket", async (request) => {
	const response = await addReplyToTicketApi(request);
	// The value we return becomes the `fulfilled` action payload
	return response;
});
export const addReplyToMessageAsync = createAsyncThunk("user/addReplyToMessage", async (request) => {
	const response = await addReplyToMessageApi(request);
	// The value we return becomes the `fulfilled` action payload
	return response;
});
export const markTicketAsDoneAsync = createAsyncThunk("user/markTicketAsDone", async (request) => {
	const response = await markTicketAsDoneApi(request);
	// The value we return becomes the `fulfilled` action payload
	return response;
});
export const removeTicketAsync = createAsyncThunk("user/removeTicket", async (request) => {
	const response = await removeTicketApi(request);
	// The value we return becomes the `fulfilled` action payload
	return response;
});
export const agencyAddTicketAsync = createAsyncThunk("user/agencyAddTicket", async (request) => {
	const response = await addTicketApi(request);
	// The value we return becomes the `fulfilled` action payload
	return response;
});
// Tenant requests

export const addTicketAsync = createAsyncThunk("user/addTicket", async (request) => {
	const response = await addTicketApi(request);
	// The value we return becomes the `fulfilled` action payload
	return response;
});
export const tenantAddReplyToTicketAsync = createAsyncThunk("user/tenantAddReplyToTicket", async (request) => {
	const response = await addReplyToTicketApi(request);
	// The value we return becomes the `fulfilled` action payload
	return response;
});
export const tenantAddUtilityMeterPictureAsync = createAsyncThunk("user/addUtilityMeterPicture", async (request) => {
	const response = await addUtilityMeterPictureApi(request);
	// The value we return becomes the `fulfilled` action payload
	return response;
});
export const userSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		signIn: (state, action) => {
			state.currentUser = action.payload;
		},
		logout: (state) => {
			state.currentUser = null;
			state.user = null;
		},
		setUser: (state, action) => {
			state.user = action.payload;
		},
		updateStatus: (state, action) => {
			state.status = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getDashboardAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(getDashboardAsync.fulfilled, (state, action) => {
				state.status = "idle";
				state.user = action.payload;
				const accType = action.payload.type;
				document.title = `${getAccType(accType)} | RentIt`;
				// state.user.properties = state.user.properties.map((p) => [p, p, p, p]).flat();
			})
			.addCase(getDashboardAsync.rejected, (state, action) => {
				state.status = "idle";
				// state.currentUser = action.payload;
				console.log("Error");
				// throw new Error("Error getting dashboard information");
			})
			.addCase(createPropertyAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(createPropertyAsync.fulfilled, (state, action) => {
				state.status = "idle";
				const newOwner = action.payload.property.owner;
				state.user.properties = [action.payload.property, ...state.user.properties];
				//replace owner obj to all of his properties
				state.user.properties.filter((p) => p.owner.email === newOwner.email).forEach((p) => (p.owner = newOwner));
			})
			.addCase(createPropertyAsync.rejected, (state, action) => {
				state.status = "idle";
				// state.currentUser = action.payload;
				console.log("Error");
				// throw new Error("Error getting dashboard information");
			})
			.addCase(archivePropertyAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(archivePropertyAsync.fulfilled, (state, action) => {
				state.status = "idle";
				//replace owner obj to all of his properties
				state.user.properties[state.user.properties.findIndex((e) => e.id === action.payload.propertyId)].archived = action.payload.archived;
			})
			.addCase(archivePropertyAsync.rejected, (state, action) => {
				state.status = "idle";
				// state.currentUser = action.payload;
				console.log("Error");
				// throw new Error("Error getting dashboard information");
			})
			.addCase(restorePropertyAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(restorePropertyAsync.fulfilled, (state, action) => {
				state.status = "idle";
				//replace owner obj to all of his properties
				state.user.properties[state.user.properties.findIndex((e) => e.id === action.payload.propertyId)].archived = action.payload.archived;
			})
			.addCase(restorePropertyAsync.rejected, (state, action) => {
				state.status = "idle";
				// state.currentUser = action.payload;
				console.log("Error");
				// throw new Error("Error getting dashboard information");
			})
			.addCase(uploadSmartContractAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(uploadSmartContractAsync.fulfilled, (state, action) => {
				state.status = "idle";
				const newOwner = action.payload.property.owner;
				state.user.properties = [...state.user.properties, action.payload.property];
				//replace owner obj to all of his properties
				state.user.properties.filter((p) => p.owner.email === newOwner.email).forEach((p) => (p.owner = newOwner));
			})
			.addCase(uploadSmartContractAsync.rejected, (state, action) => {
				state.status = "idle";
				// state.currentUser = action.payload;
				console.log("Error");
				// throw new Error("Error getting dashboard information");
			})
			.addCase(addDocumentAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(addDocumentAsync.fulfilled, (state, action) => {
				state.status = "idle";

				state.user.properties[state.user.properties.findIndex((e) => e.id === action.payload.propertyId)].documents = action.payload.documents;
				state.user.properties[state.user.properties.findIndex((e) => e.id === action.payload.propertyId)].financialStatements =
					action.payload.financialStatements;
				state.user.properties[state.user.properties.findIndex((e) => e.id === action.payload.propertyId)].additionalSteps = action.payload.additionalSteps;
				if (action.payload.rent.amount)
					state.user.properties[state.user.properties.findIndex((e) => e.id === action.payload.propertyId)].rent = action.payload.rent;
			})
			.addCase(addDocumentAsync.rejected, (state, action) => {
				state.status = "idle";
				// state.currentUser = action.payload;
				console.log("Error");
				// throw new Error("Error getting dashboard information");
			})
			.addCase(addReportAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(addReportAsync.fulfilled, (state, action) => {
				state.status = "idle";

				state.user.properties[state.user.properties.findIndex((e) => e.id === action.payload.propertyId)].reports = action.payload.reports;
				state.user.properties[state.user.properties.findIndex((e) => e.id === action.payload.propertyId)].financialStatements =
					action.payload.financialStatements;
				state.user.properties[state.user.properties.findIndex((e) => e.id === action.payload.propertyId)].additionalSteps = action.payload.additionalSteps;
				state.user.properties[state.user.properties.findIndex((e) => e.id === action.payload.propertyId)].documents = action.payload.documents;
				state.user.properties[state.user.properties.findIndex((e) => e.id === action.payload.propertyId)].tenants = action.payload.tenants;
				state.user.properties[state.user.properties.findIndex((e) => e.id === action.payload.propertyId)].formerTenants = action.payload.formerTenants;
				state.user.properties[state.user.properties.findIndex((e) => e.id === action.payload.propertyId)].leaseContacts = action.payload.leaseContacts;
			})
			.addCase(addReportAsync.rejected, (state, action) => {
				state.status = "idle";
				// state.currentUser = action.payload;
				console.log("Error");
				// throw new Error("Error getting dashboard information");
			})
			.addCase(updateTenantFormerAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(updateTenantFormerAsync.fulfilled, (state, action) => {
				state.status = "idle";
				state.user.properties[state.user.properties.findIndex((e) => e.id === action.payload.propertyId)].tenants = action.payload.tenants;
				state.user.properties[state.user.properties.findIndex((e) => e.id === action.payload.propertyId)].formerTenants = action.payload.formerTenants;
			})
			.addCase(updateTenantFormerAsync.rejected, (state, action) => {
				state.status = "idle";
				// state.currentUser = action.payload;
				console.log("Error");
				// throw new Error("Error getting dashboard information");
			})
			.addCase(addProtocolAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(addProtocolAsync.fulfilled, (state, action) => {
				state.status = "idle";
				state.user.properties[state.user.properties.findIndex((e) => e.id === action.payload.propertyId)].protocols = action.payload.protocols;
			})
			.addCase(addProtocolAsync.rejected, (state, action) => {
				state.status = "idle";
				// state.currentUser = action.payload;
				console.log("Error");
				// throw new Error("Error getting dashboard information");
			})
			.addCase(addPaymentAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(addPaymentAsync.fulfilled, (state, action) => {
				state.status = "idle";
				state.user.properties[state.user.properties.findIndex((e) => e.id === action.payload.propertyId)].payments = action.payload.payments;
			})
			.addCase(addPaymentAsync.rejected, (state, action) => {
				state.status = "idle";
				console.log("Error");
			})
			.addCase(addRentPaymentAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(addRentPaymentAsync.fulfilled, (state, action) => {
				state.status = "idle";
				state.user.properties[state.user.properties.findIndex((e) => e.id === action.payload.propertyId)].payments = action.payload.payments;
				//same for systemNotifications
				state.user.properties[state.user.properties.findIndex((e) => e.id === action.payload.propertyId)].systemNotifications =
					action.payload.systemNotifications;
			})
			.addCase(addRentPaymentAsync.rejected, (state, action) => {
				state.status = "idle";
				console.log("Error");
			})
			.addCase(addAdministrationFeePaymentAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(addAdministrationFeePaymentAsync.fulfilled, (state, action) => {
				state.status = "idle";
				state.user.properties[state.user.properties.findIndex((e) => e.id === action.payload.propertyId)].payments = action.payload.payments;
			})
			.addCase(addAdministrationFeePaymentAsync.rejected, (state, action) => {
				state.status = "idle";
				console.log("Error");
			})
			.addCase(addPaymentDocumentAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(addPaymentDocumentAsync.fulfilled, (state, action) => {
				state.status = "idle";
				state.user.properties[state.user.properties.findIndex((e) => e.id === action.payload.propertyId)].payments = action.payload.payments;
			})
			.addCase(addPaymentDocumentAsync.rejected, (state, action) => {
				state.status = "idle";
				// state.currentUser = action.payload;
				console.log("Error");
				// throw new Error("Error getting dashboard information");
			})
			.addCase(addFinancialStatementAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(addFinancialStatementAsync.fulfilled, (state, action) => {
				state.status = "idle";

				state.user.properties[state.user.properties.findIndex((e) => e.id === action.payload.propertyId)].financialStatements =
					action.payload.financialStatements;
			})
			.addCase(addFinancialStatementAsync.rejected, (state, action) => {
				state.status = "idle";
				console.log("Error");
			})
			.addCase(addSecurityDepositAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(addSecurityDepositAsync.fulfilled, (state, action) => {
				state.status = "idle";

				state.user.properties[state.user.properties.findIndex((e) => e.id === action.payload.propertyId)].securityDeposits = action.payload.securityDeposits;
			})
			.addCase(addSecurityDepositAsync.rejected, (state, action) => {
				state.status = "idle";
				console.log("Error");
			})
			.addCase(updateSecurityDepositAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(updateSecurityDepositAsync.fulfilled, (state, action) => {
				state.status = "idle";

				state.user.properties[state.user.properties.findIndex((e) => e.id === action.payload.propertyId)].securityDeposits = action.payload.securityDeposits;
			})
			.addCase(updateSecurityDepositAsync.rejected, (state, action) => {
				state.status = "idle";
				console.log("Error");
			})
			.addCase(markPaymentAsPaidAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(markPaymentAsPaidAsync.fulfilled, (state, action) => {
				state.status = "idle";

				state.user.properties[state.user.properties.findIndex((e) => e.id === action.payload.propertyId)].payments = action.payload.payments;
			})
			.addCase(markPaymentAsPaidAsync.rejected, (state, action) => {
				state.status = "idle";
				// state.currentUser = action.payload;
				console.log("Error");
				// throw new Error("Error getting dashboard information");
			})
			.addCase(markTenantPaymentAsPaidAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(markTenantPaymentAsPaidAsync.fulfilled, (state, action) => {
				state.status = "idle";
				state.user.properties[state.user.properties.findIndex((e) => e.id === action.payload.propertyId)].payments = action.payload.payments;
			})
			.addCase(markTenantPaymentAsPaidAsync.rejected, (state) => {
				state.status = "idle";
				console.log("Error");
			})
			.addCase(markMultiplePaymentsAsPaidAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(markMultiplePaymentsAsPaidAsync.fulfilled, (state, action) => {
				state.status = "idle";
				state.user.properties[state.user.properties.findIndex((e) => e.id === action.payload.propertyId)].payments = action.payload.payments;
			})
			.addCase(markMultiplePaymentsAsPaidAsync.rejected, (state, action) => {
				state.status = "idle";
				// state.currentUser = action.payload;
				console.log("Error");
				// throw new Error("Error getting dashboard information");
			})
			.addCase(markRentAsPaidAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(markRentAsPaidAsync.fulfilled, (state, action) => {
				state.status = "idle";

				state.user.properties[state.user.properties.findIndex((e) => e.id === action.payload.propertyId)].payments = action.payload.payments;
			})
			.addCase(markRentAsPaidAsync.rejected, (state, action) => {
				state.status = "idle";
				// state.currentUser = action.payload;
				console.log("Error");
				// throw new Error("Error getting dashboard information");
			})
			.addCase(addUtilityPaymentAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(addUtilityPaymentAsync.fulfilled, (state, action) => {
				state.status = "idle";

				state.user.properties[state.user.properties.findIndex((e) => e.id === action.payload.propertyId)].payments = action.payload.payments;
			})
			.addCase(addUtilityPaymentAsync.rejected, (state, action) => {
				state.status = "idle";
				// state.currentUser = action.payload;
				console.log("Error");
				// throw new Error("Error getting dashboard information");
			})
			.addCase(addContactAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(addContactAsync.fulfilled, (state, action) => {
				state.status = "idle";

				state.user.properties[state.user.properties.findIndex((e) => e.id === action.payload.propertyId)].contacts = [
					...state.user.properties[state.user.properties.findIndex((e) => e.id === action.payload.propertyId)].contacts,
					action.payload.contact,
				];
			})
			.addCase(addContactAsync.rejected, (state, action) => {
				state.status = "idle";
				// state.currentUser = action.payload;
				console.log("Error");
				// throw new Error("Error getting dashboard information");
			})
			.addCase(addActivityAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(addActivityAsync.fulfilled, (state, action) => {
				state.status = "idle";

				state.user.properties[state.user.properties.findIndex((e) => e.id === action.payload.propertyId)].activities = action.payload.activities;
			})
			.addCase(addActivityAsync.rejected, (state, action) => {
				state.status = "idle";
				// state.currentUser = action.payload;
				console.log("Error");
				// throw new Error("Error getting dashboard information");
			})
			.addCase(addLeaseContactAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(addLeaseContactAsync.fulfilled, (state, action) => {
				state.status = "idle";
				state.user.properties[state.user.properties.findIndex((e) => e.id === action.payload.propertyId)].leaseContacts = action.payload.leaseContacts;
			})
			.addCase(addLeaseContactAsync.rejected, (state, action) => {
				state.status = "idle";
				// state.currentUser = action.payload;
				console.log("Error");
				// throw new Error("Error getting dashboard information");
			})
			.addCase(addTicketAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(addTicketAsync.fulfilled, (state, action) => {
				state.status = "idle";
				state.user.properties[state.user.properties.findIndex((e) => e.id === action.payload.propertyId)].tickets = action.payload.tickets;
			})
			.addCase(addTicketAsync.rejected, (state, action) => {
				state.status = "idle";
				// state.currentUser = action.payload;
				console.log("Error");
				// throw new Error("Error getting dashboard information");
			})
			.addCase(agencyAddTicketAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(agencyAddTicketAsync.fulfilled, (state, action) => {
				state.status = "idle";
				const propertyId = action.payload.propertyId;
				const tickets = action.payload.tickets;
				state.user.properties[state.user.properties.findIndex((e) => e.id === propertyId)].tickets = tickets;
			})
			.addCase(agencyAddTicketAsync.rejected, (state, action) => {
				state.status = "idle";
				// state.currentUser = action.payload;
				console.log("Error");
				// throw new Error("Error getting dashboard information");
			})
			.addCase(tenantAddReplyToTicketAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(tenantAddReplyToTicketAsync.fulfilled, (state, action) => {
				state.status = "idle";
				const newTickets = action.payload.tickets;
				const propertyId = action.payload.propertyId;
				console.log(`newTickets: ${newTickets}, propertyId: ${propertyId}`);
				console.log(state.user.properties.findIndex((e) => e.id === propertyId));

				state.user.properties[state.user.properties.findIndex((e) => e.id === propertyId)].tickets = newTickets;
			})
			.addCase(tenantAddReplyToTicketAsync.rejected, (state, action) => {
				state.status = "idle";
				// state.currentUser = action.payload;
				console.log("Error");
				// throw new Error("Error getting dashboard information");
			})
			.addCase(addReplyToMessageAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(addReplyToMessageAsync.fulfilled, (state, action) => {
				state.status = "idle";
				state.user.properties[state.user.properties.findIndex((e) => e.id === action.payload.propertyId)].messages = action.payload.messages;
			})
			.addCase(addReplyToMessageAsync.rejected, (state, action) => {
				state.status = "idle";
				// state.currentUser = action.payload;
				console.log("Error");
				// throw new Error("Error getting dashboard information");
			})
			.addCase(tenantAddUtilityMeterPictureAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(tenantAddUtilityMeterPictureAsync.fulfilled, (state, action) => {
				state.status = "idle";

				// state.user.property.utilities.meterPictureHistory = action.payload.meterPictureHistory; //TODO: handle one tenant on multiple properties
				state.user.properties[state.user.properties.findIndex((e) => e.id === action.payload.propertyId)].utilities.meterPictureHistory =
					action.payload.meterPictureHistory;
			})
			.addCase(tenantAddUtilityMeterPictureAsync.rejected, (state, action) => {
				state.status = "idle";
				// state.currentUser = action.payload;
				console.log("Error");
				// throw new Error("Error getting dashboard information");
			})
			.addCase(addReplyToTicketAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(addReplyToTicketAsync.fulfilled, (state, action) => {
				state.status = "idle";
				state.user.properties[state.user.properties.findIndex((e) => e.id === action.payload.propertyId)].tickets = action.payload.tickets;
			})
			.addCase(addReplyToTicketAsync.rejected, (state, action) => {
				state.status = "idle";
				// state.currentUser = action.payload;
				console.log("Error");
				// throw new Error("Error getting dashboard information");
			})
			.addCase(markTicketAsDoneAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(markTicketAsDoneAsync.fulfilled, (state, action) => {
				state.status = "idle";
				state.user.properties[state.user.properties.findIndex((e) => e.id === action.payload.propertyId)].tickets = action.payload.tickets;
			})
			.addCase(markTicketAsDoneAsync.rejected, (state, action) => {
				state.status = "idle";
				// state.currentUser = action.payload;
				console.log("Error");
				// throw new Error("Error getting dashboard information");
			})
			.addCase(addMediaAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(addMediaAsync.fulfilled, (state, action) => {
				state.status = "idle";
				state.user.properties[state.user.properties.findIndex((e) => e.id === action.payload.propertyId)].media = action.payload.media;
			})
			.addCase(addMediaAsync.rejected, (state, action) => {
				state.status = "idle";
				// state.currentUser = action.payload;
				console.log("Error");
				// throw new Error("Error getting dashboard information");
			})
			.addCase(addAccountAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(addAccountAsync.fulfilled, (state, action) => {
				state.status = "idle";

				state.user.properties[state.user.properties.findIndex((e) => e.id === action.payload.propertyId)].accounts = [
					...state.user.properties[state.user.properties.findIndex((e) => e.id === action.payload.propertyId)].accounts,
					action.payload.account,
				];
			})
			.addCase(addAccountAsync.rejected, (state, action) => {
				state.status = "idle";
				// state.currentUser = action.payload;
				console.log("Error");
				// throw new Error("Error getting dashboard information");
			})
			.addCase(addAgentAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(addAgentAsync.fulfilled, (state, action) => {
				state.status = "idle";
				state.user.agents = action.payload.agents;
			})
			.addCase(addAgentAsync.rejected, (state, action) => {
				state.status = "idle";
				// state.currentUser = action.payload;
				console.log("Error");
				// throw new Error("Error getting dashboard information");
			})
			.addCase(updateCustomOwnerPlanAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(updateCustomOwnerPlanAsync.fulfilled, (state, action) => {
				state.status = "idle";
				state.user.customOwnerPlans = action.payload.customOwnerPlans;
			})
			.addCase(updateCustomOwnerPlanAsync.rejected, (state, action) => {
				state.status = "idle";
				// state.currentUser = action.payload;
				console.log("Error");
				// throw new Error("Error getting dashboard information");
			})
			.addCase(removeCustomOwnerPlanAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(removeCustomOwnerPlanAsync.fulfilled, (state, action) => {
				state.status = "idle";
				state.user.customOwnerPlans = action.payload.customOwnerPlans;
				const updatedProperties = action.payload.properties;
				updatedProperties.forEach((p) => {
					state.user.properties[state.user.properties.findIndex((e) => e.id === p.id)].customOwnerPlans = p.customOwnerPlans;
				});
			})
			.addCase(removeCustomOwnerPlanAsync.rejected, (state, action) => {
				state.status = "idle";
				// state.currentUser = action.payload;
				console.log("Error");
				// throw new Error("Error getting dashboard information");
			})
			.addCase(updatePropertyCustomOwnerPlanAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(updatePropertyCustomOwnerPlanAsync.fulfilled, (state, action) => {
				state.status = "idle";
				const propertyIndex = state.user.properties.findIndex((e) => e.id === action.payload.propertyId);
				state.user.properties[propertyIndex].customOwnerPlans = action.payload.customOwnerPlans;
			})
			.addCase(updatePropertyCustomOwnerPlanAsync.rejected, (state, action) => {
				state.status = "idle";
				// state.currentUser = action.payload;
				console.log("Error");
				// throw new Error("Error getting dashboard information");
			})
			.addCase(addCustomOwnerPlanAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(addCustomOwnerPlanAsync.fulfilled, (state, action) => {
				state.status = "idle";
				state.user.customOwnerPlans = action.payload.customOwnerPlans;
			})
			.addCase(addCustomOwnerPlanAsync.rejected, (state, action) => {
				state.status = "idle";
				// state.currentUser = action.payload;
				console.log("Error");
				// throw new Error("Error getting dashboard information");
			})
			.addCase(updateAgencyAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(updateAgencyAsync.fulfilled, (state, action) => {
				state.status = "idle";
				state.user.billingInformation = action.payload.billingInformation;
			})
			.addCase(updateAgencyAsync.rejected, (state, action) => {
				state.status = "idle";
				// state.currentUser = action.payload;
				console.log("Error");
				// throw new Error("Error getting dashboard information");
			})
			.addCase(updateTemplatesAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(updateTemplatesAsync.fulfilled, (state, action) => {
				state.status = "idle";
				state.user.templates = action.payload.templates;
			})
			.addCase(updateTemplatesAsync.rejected, (state, action) => {
				state.status = "idle";
				// state.currentUser = action.payload;
				console.log("Error");
				// throw new Error("Error getting dashboard information");
			})
			.addCase(uploadOwnerIdAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(uploadOwnerIdAsync.fulfilled, (state, action) => {
				state.status = "idle";

				const newOwner = action.payload.owner;
				newOwner.properties.forEach((pr) => {
					state.user.properties[state.user.properties.findIndex((e) => e.id === pr)].owner = newOwner;
				});
			})
			.addCase(uploadOwnerIdAsync.rejected, (state, action) => {
				state.status = "idle";
				// state.currentUser = action.payload;
				console.log("Error");
				// throw new Error("Error getting dashboard information");
			})
			.addCase(removeOwnerDocumentAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(removeOwnerDocumentAsync.fulfilled, (state, action) => {
				state.status = "idle";

				const newOwner = action.payload.owner;
				newOwner.properties.forEach((pr) => {
					state.user.properties[state.user.properties.findIndex((e) => e.id === pr)].owner = newOwner;
				});
			})
			.addCase(removeOwnerDocumentAsync.rejected, (state, action) => {
				state.status = "idle";
				// state.currentUser = action.payload;
				console.log("Error");
				// throw new Error("Error getting dashboard information");
			})
			.addCase(addOwnerDocumentAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(addOwnerDocumentAsync.fulfilled, (state, action) => {
				state.status = "idle";

				const newOwner = action.payload.owner;
				newOwner.properties.forEach((pr) => {
					state.user.properties[state.user.properties.findIndex((e) => e.id === pr)].owner = newOwner;
				});
			})
			.addCase(addOwnerDocumentAsync.rejected, (state, action) => {
				state.status = "idle";
				// state.currentUser = action.payload;
				console.log("Error");
				// throw new Error("Error getting dashboard information");
			})
			.addCase(uploadTenantIdAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(uploadTenantIdAsync.fulfilled, (state, action) => {
				state.status = "idle";
				const tenantProperties = action.payload.tenant.properties;
				tenantProperties.forEach((pr) => {
					const propertyIndex = state.user.properties.findIndex((e) => e.id === pr);
					const tenantIndex = state.user.properties[propertyIndex].tenants.findIndex((t) => t.email === action.payload.tenant.email);
					state.user.properties[propertyIndex].tenants[tenantIndex] = action.payload.tenant;
				});
			})
			.addCase(uploadTenantIdAsync.rejected, (state, action) => {
				state.status = "idle";
				// state.currentUser = action.payload;
				console.log("Error");
				// throw new Error("Error getting dashboard information");
			})
			.addCase(removeTenantDocumentAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(removeTenantDocumentAsync.fulfilled, (state, action) => {
				state.status = "idle";
				const tenantProperties = action.payload.tenant.properties;
				tenantProperties.forEach((pr) => {
					const propertyIndex = state.user.properties.findIndex((e) => e.id === pr);
					const tenantIndex = state.user.properties[propertyIndex].tenants.findIndex((t) => t.email === action.payload.tenant.email);
					state.user.properties[propertyIndex].tenants[tenantIndex] = action.payload.tenant;
				});
			})
			.addCase(removeTenantDocumentAsync.rejected, (state, action) => {
				state.status = "idle";
				// state.currentUser = action.payload;
				console.log("Error");
				// throw new Error("Error getting dashboard information");
			})
			.addCase(updateOwnerAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(updateOwnerAsync.fulfilled, (state, action) => {
				state.status = "idle";
				const newOwner = action.payload.owner;
				newOwner.properties.forEach((pr) => {
					state.user.properties[state.user.properties.findIndex((e) => e.id === pr)].owner = newOwner;
				});
			})
			.addCase(generateCombinedAdministrationFeePaymentAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(generateCombinedAdministrationFeePaymentAsync.rejected, (state) => {
				state.status = "idle";
			})
			.addCase(generateCombinedAdministrationFeePaymentAsync.fulfilled, (state, action) => {
				state.status = "idle";
				const newOwner = action.payload.owner;
				newOwner.properties.forEach((pr) => {
					state.user.properties[state.user.properties.findIndex((e) => e.id === pr)].owner = newOwner;
				});
			})
			.addCase(addAssociationAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(addAssociationAsync.fulfilled, (state, action) => {
				state.status = "idle";
				const newAssociation = action.payload.association;
				const propertyId = action.payload.propertyId;
				const propertyIndex = state.user.properties.findIndex((e) => e.id === propertyId);
				state.user.properties[propertyIndex].association = newAssociation;
			})
			.addCase(addAssociationAsync.rejected, (state, action) => {
				state.status = "idle";
				console.log("Error");
			})
			.addCase(removeAssociationAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(removeAssociationAsync.fulfilled, (state, action) => {
				state.status = "idle";
				const propertyId = action.payload.propertyId;
				const propertyIndex = state.user.properties.findIndex((e) => e.id === propertyId);
				state.user.properties[propertyIndex].association = "";
			})
			.addCase(removeAssociationAsync.rejected, (state, action) => {
				state.status = "idle";
				console.log("Error");
			})
			.addCase(updateAssociationAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(updateAssociationAsync.fulfilled, (state, action) => {
				state.status = "idle";
				const newAssociation = action.payload.association;
				//find properties where the association is equal to newAssociation.id and replace it with newAssociation
				state.user.properties.forEach((pr, index) => {
					if (pr.association?.id === newAssociation.id) {
						state.user.properties[index].association = newAssociation;
					}
				});
				state.user.associations[state.user.associations.findIndex((e) => e.id === newAssociation.id)] = newAssociation;
			})
			.addCase(updateAssociationAsync.rejected, (state, action) => {
				state.status = "idle";
				console.log("Error");
			})
			.addCase(updateFinancialStatusAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(updateFinancialStatusAsync.fulfilled, (state, action) => {
				state.status = "idle";
				const newOwner = action.payload.owner;
				console.log(newOwner);
				newOwner.properties.forEach((pr) => {
					const index = state.user.properties.findIndex((e) => e.id === pr);
					console.log(pr);
					console.log(index);
					state.user.properties[index].owner = newOwner;
				});
			})
			.addCase(updateFinancialStatusAsync.rejected, (state, action) => {
				state.status = "idle";
				// state.currentUser = action.payload;
				console.log("Error");
				// throw new Error("Error getting dashboard information");
			})
			.addCase(updateTenantAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(updateTenantAsync.fulfilled, (state, action) => {
				state.status = "idle";
				const tenantProperties = action.payload.tenant.properties;
				tenantProperties.forEach((pr) => {
					const propertyIndex = state.user.properties.findIndex((e) => e.id === pr);
					const tenantIndex = state.user.properties[propertyIndex].tenants.findIndex((t) => t.email === action.payload.tenant.email);
					state.user.properties[propertyIndex].tenants[tenantIndex] = action.payload.tenant;
				});
			})
			.addCase(updateTenantAsync.rejected, (state, action) => {
				state.status = "idle";
				// state.currentUser = action.payload;
				console.log("Error");
				// throw new Error("Error getting dashboard information");
			})
			.addCase(addTenantDocumentAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(addTenantDocumentAsync.fulfilled, (state, action) => {
				state.status = "idle";
				const tenantProperties = action.payload.tenant.properties;
				tenantProperties.forEach((pr) => {
					const propertyIndex = state.user.properties.findIndex((e) => e.id === pr);
					const tenantIndex = state.user.properties[propertyIndex].tenants.findIndex((t) => t.email === action.payload.tenant.email);
					state.user.properties[propertyIndex].tenants[tenantIndex] = action.payload.tenant;
				});
			})
			.addCase(addTenantDocumentAsync.rejected, (state, action) => {
				state.status = "idle";
				console.log("Error");
			})
			.addCase(addTenantAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(addTenantAsync.fulfilled, (state, action) => {
				state.status = "idle";
				state.user.properties[state.user.properties.findIndex((e) => e.id === action.payload.propertyId)].tenants = [
					...state.user.properties[state.user.properties.findIndex((e) => e.id === action.payload.propertyId)].tenants,
					action.payload.tenant,
				];
			})
			.addCase(addTenantAsync.rejected, (state, action) => {
				state.status = "idle";
				// state.currentUser = action.payload;
				console.log("Error");
				// throw new Error("Error getting dashboard information");
			})
			.addCase(updateAdditionalStepsAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(updateAdditionalStepsAsync.fulfilled, (state, action) => {
				state.status = "idle";
				state.user.properties[state.user.properties.findIndex((e) => e.id === action.payload.propertyId)].additionalSteps = action.payload.additionalSteps;
			})
			.addCase(updateAdditionalStepsAsync.rejected, (state, action) => {
				state.status = "idle";
				// state.currentUser = action.payload;
				console.log("Error");
				// throw new Error("Error getting dashboard information");
			})
			.addCase(updateDocumentActiveAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(updateDocumentActiveAsync.fulfilled, (state, action) => {
				state.status = "idle";
				state.user.properties[state.user.properties.findIndex((e) => e.id === action.payload.propertyId)].documents = action.payload.documents;
			})
			.addCase(updateDocumentActiveAsync.rejected, (state, action) => {
				state.status = "idle";
				// state.currentUser = action.payload;
				console.log("Error");
				// throw new Error("Error getting dashboard information");
			})
			.addCase(updateReportActiveAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(updateReportActiveAsync.fulfilled, (state, action) => {
				state.status = "idle";
				state.user.properties[state.user.properties.findIndex((e) => e.id === action.payload.propertyId)].reports = action.payload.reports;
			})
			.addCase(updateReportActiveAsync.rejected, (state, action) => {
				state.status = "idle";
				// state.currentUser = action.payload;
				console.log("Error");
				// throw new Error("Error getting dashboard information");
			})
			.addCase(updateFinancialStatementActiveAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(updateFinancialStatementActiveAsync.fulfilled, (state, action) => {
				state.status = "idle";
				state.user.properties[state.user.properties.findIndex((e) => e.id === action.payload.propertyId)].financialStatements =
					action.payload.financialStatements;
			})
			.addCase(updateFinancialStatementActiveAsync.rejected, (state, action) => {
				state.status = "idle";
				// state.currentUser = action.payload;
				console.log("Error");
				// throw new Error("Error getting dashboard information");
			})
			.addCase(updateSpecsAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(updateSpecsAsync.fulfilled, (state, action) => {
				state.status = "idle";
				state.user.properties[state.user.properties.findIndex((e) => e.id === action.payload.propertyId)].specs = action.payload.specs;
			})
			.addCase(updateSpecsAsync.rejected, (state, action) => {
				state.status = "idle";
				// state.currentUser = action.payload;
				console.log("Error");
				// throw new Error("Error getting dashboard information");
			})
			.addCase(updateRentAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(updateRentAsync.fulfilled, (state, action) => {
				state.status = "idle";
				state.user.properties[state.user.properties.findIndex((e) => e.id === action.payload.propertyId)].rent = action.payload.rent;
			})
			.addCase(updateRentAsync.rejected, (state, action) => {
				state.status = "idle";
				// state.currentUser = action.payload;
				console.log("Error");
				// throw new Error("Error getting dashboard information");
			})
			.addCase(updateDetailsAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(updateDetailsAsync.fulfilled, (state, action) => {
				state.status = "idle";
				const propertyIndex = state.user.properties.findIndex((e) => e.id === action.payload.propertyId);
				state.user.properties[propertyIndex].description = action.payload.description;
				state.user.properties[propertyIndex].keys = action.payload.keys;
				// state.user.properties[state.user.properties.findIndex((e) => e.id === action.payload.propertyId)].bankDetails = action.payload.bankDetails;
				state.user.properties[propertyIndex].securityDeposit = action.payload.securityDeposit;
				state.user.properties[propertyIndex].location = action.payload.location;

				//go through the properties and change the owner
				state.user.properties
					.filter((p) => p.owner.email === state.user.properties[propertyIndex].owner.email)
					.forEach((p) => {
						p.owner.bankDetails = action.payload.bankDetails;
					});
			})
			.addCase(updateDetailsAsync.rejected, (state, action) => {
				state.status = "idle";
				// state.currentUser = action.payload;
				console.log("Error");
				// throw new Error("Error getting dashboard information");
			})
			.addCase(updateMeterInformationAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(updateMeterInformationAsync.fulfilled, (state, action) => {
				state.status = "idle";
				state.user.properties[state.user.properties.findIndex((e) => e.id === action.payload.propertyId)].utilities = action.payload.utilities;
			})
			.addCase(updateMeterInformationAsync.rejected, (state, action) => {
				state.status = "idle";
				// state.currentUser = action.payload;
				console.log("Error");
				// throw new Error("Error getting dashboard information");
			})
			.addCase(requestMeterPictureAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(requestMeterPictureAsync.fulfilled, (state, action) => {
				state.status = "idle";
				state.user.properties[state.user.properties.findIndex((e) => e.id === action.payload.propertyId)].systemNotifications =
					action.payload.systemNotifications;
			})
			.addCase(requestMeterPictureAsync.rejected, (state, action) => {
				state.status = "idle";
				// state.currentUser = action.payload;
				console.log("Error");
				// throw new Error("Error getting dashboard information");
			})
			.addCase(removeDocumentAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(removeDocumentAsync.fulfilled, (state, action) => {
				state.status = "idle";

				state.user.properties[state.user.properties.findIndex((e) => e.id === action.payload.propertyId)].documents = action.payload.documents;
			})
			.addCase(removeDocumentAsync.rejected, (state, action) => {
				state.status = "idle";
				// state.currentUser = action.payload;
				console.log("Error");
				// throw new Error("Error getting dashboard information");
			})
			.addCase(removeActivityAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(removeActivityAsync.fulfilled, (state, action) => {
				state.status = "idle";
				state.user.properties[state.user.properties.findIndex((e) => e.id === action.payload.propertyId)].activities = action.payload.activities;
			})
			.addCase(removeActivityAsync.rejected, (state, action) => {
				state.status = "idle";
				// state.currentUser = action.payload;
				console.log("Error");
				// throw new Error("Error getting dashboard information");
			})
			.addCase(removePaymentAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(removePaymentAsync.fulfilled, (state, action) => {
				state.status = "idle";

				state.user.properties[state.user.properties.findIndex((e) => e.id === action.payload.propertyId)].payments = action.payload.payments;
			})
			.addCase(removePaymentAsync.rejected, (state, action) => {
				state.status = "idle";
				// state.currentUser = action.payload;
				console.log("Error");
				// throw new Error("Error getting dashboard information");
			})
			.addCase(removeSecurityDepositAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(removeSecurityDepositAsync.fulfilled, (state, action) => {
				state.status = "idle";

				state.user.properties[state.user.properties.findIndex((e) => e.id === action.payload.propertyId)].securityDeposits = action.payload.securityDeposits;
			})
			.addCase(removeSecurityDepositAsync.rejected, (state, action) => {
				state.status = "idle";
				// state.currentUser = action.payload;
				console.log("Error");
				// throw new Error("Error getting dashboard information");
			})
			.addCase(removeTicketAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(removeTicketAsync.fulfilled, (state, action) => {
				state.status = "idle";

				state.user.properties[state.user.properties.findIndex((e) => e.id === action.payload.propertyId)].tickets = action.payload.tickets;
			})
			.addCase(removeTicketAsync.rejected, (state, action) => {
				state.status = "idle";
				// state.currentUser = action.payload;
				console.log("Error");
				// throw new Error("Error getting dashboard information");
			})
			.addCase(removeFinancialStatementAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(removeFinancialStatementAsync.fulfilled, (state, action) => {
				state.status = "idle";

				state.user.properties[state.user.properties.findIndex((e) => e.id === action.payload.propertyId)].financialStatements =
					action.payload.financialStatements;
			})
			.addCase(removeFinancialStatementAsync.rejected, (state, action) => {
				state.status = "idle";
				// state.currentUser = action.payload;
				console.log("Error");
				// throw new Error("Error getting dashboard information");
			})
			.addCase(removeReportAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(removeReportAsync.fulfilled, (state, action) => {
				state.status = "idle";
				state.user.properties[state.user.properties.findIndex((e) => e.id === action.payload.propertyId)].reports = action.payload.reports;
			})
			.addCase(removeReportAsync.rejected, (state, action) => {
				state.status = "idle";
				// state.currentUser = action.payload;
				console.log("Error");
				// throw new Error("Error getting dashboard information");
			})
			.addCase(removeProtocolAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(removeProtocolAsync.fulfilled, (state, action) => {
				state.status = "idle";
				state.user.properties[state.user.properties.findIndex((e) => e.id === action.payload.propertyId)].protocols = action.payload.protocols;
			})
			.addCase(removeProtocolAsync.rejected, (state, action) => {
				state.status = "idle";
				// state.currentUser = action.payload;
				console.log("Error");
				// throw new Error("Error getting dashboard information");
			})
			.addCase(removeMediaAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(removeMediaAsync.fulfilled, (state, action) => {
				state.status = "idle";

				state.user.properties[state.user.properties.findIndex((e) => e.id === action.payload.propertyId)].media = action.payload.media;
			})
			.addCase(removeMediaAsync.rejected, (state, action) => {
				state.status = "idle";
				// state.currentUser = action.payload;
				console.log("Error");
				// throw new Error("Error getting dashboard information");
			})
			.addCase(removeContactAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(removeContactAsync.fulfilled, (state, action) => {
				state.status = "idle";
				const newContacts = action.payload.contacts;
				const newLeaseContacts = action.payload.leaseContacts;
				if (newContacts) state.user.properties[state.user.properties.findIndex((e) => e.id === action.payload.propertyId)].contacts = newContacts;
				if (newLeaseContacts)
					state.user.properties[state.user.properties.findIndex((e) => e.id === action.payload.propertyId)].leaseContacts = newLeaseContacts;
			})
			.addCase(removeContactAsync.rejected, (state, action) => {
				state.status = "idle";
				// state.currentUser = action.payload;
				console.log("Error");
				// throw new Error("Error getting dashboard information");
			})
			.addCase(removeAccountAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(removeAccountAsync.fulfilled, (state, action) => {
				state.status = "idle";
				state.user.properties[state.user.properties.findIndex((e) => e.id === action.payload.propertyId)].accounts = action.payload.accounts;
			})
			.addCase(removeAccountAsync.rejected, (state, action) => {
				state.status = "idle";
				// state.currentUser = action.payload;
				console.log("Error");
				// throw new Error("Error getting dashboard information");
			})
			.addCase(sendMassNotificationsAsync.pending, (state) => {
				// state.status = "loading";
			})
			.addCase(sendMassNotificationsAsync.fulfilled, (state, action) => {
				state.status = "idle";
				if (action.payload.propertyId)
					state.user.properties[state.user.properties.findIndex((e) => e.id === action.payload.propertyId)].notifications = action.payload.notifications;
				else state.user.notifications = action.payload.notifications;
				// state.user.notifications = action.payload.notifications;
			})
			.addCase(sendMassNotificationsAsync.rejected, (state, action) => {
				state.status = "idle";
				// state.currentUser = action.payload;
				console.log("Error");
				// throw new Error("Error getting dashboard information");
			})
			.addCase(addMessageAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(addMessageAsync.fulfilled, (state, action) => {
				state.status = "idle";
				state.user.properties[state.user.properties.findIndex((e) => e.id === action.payload.propertyId)].messages = action.payload.messages;
			})
			.addCase(addMessageAsync.rejected, (state, action) => {
				state.status = "idle";
				// state.currentUser = action.payload;
				console.log("Error");
				// throw new Error("Error getting dashboard information");
			});
	},
});
export const selectActiveProperties = (state) => {
	return state.user?.user?.properties.filter((p) => !p.archived);
};
export const selectArchivedProperties = (state) => {
	return state.user?.user?.properties.filter((p) => p.archived);
};
export const selectAllProperties = (state) => {
	return state.user?.user?.properties;
};

export const { signIn, logout, updateStatus } = userSlice.actions;

// // We can also write thunks by hand, which may contain both sync and async logic.
// // Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd = (amount) => (dispatch, getState) => {
// 	const currentValue = selectCount(getState());
// 	if (currentValue % 2 ===== 1) {
// 		dispatch(incrementByAmount(amount));
// 	}
// };

export default userSlice.reducer;
