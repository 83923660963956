import React, { useState, useEffect } from "react";
import {
	Box,
	Button,
	Text,
	Divider,
	Input,
	Stack,
	FormControl,
	FormLabel,
	Select,
	useDisclosure,
	Modal,
	ModalOverlay,
	ModalHeader,
	ModalContent,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	Wrap,
	WrapItem,
	Popover,
	PopoverTrigger,
	PopoverContent,
	PopoverHeader,
	PopoverBody,
	PopoverFooter,
	PopoverArrow,
	PopoverCloseButton,
	useToast,
} from "@chakra-ui/react";
import { addAccountAsync, removeAccountAsync } from "../../redux/user/userSlice";
import { useDispatch } from "react-redux";
import { addAccountVerifier } from "../../utils/verifier";
import { useTranslation } from "react-i18next";
const accountTypes = [
	{ id: "cityHall", label: "Primarie" },
	{ id: "finances", label: "Finante" },
	{ id: "water", label: "Apa" },
	{ id: "gas", label: "Gaz" },
	{ id: "electricity", label: "Curent" },
	{ id: "internet", label: "Internet" },
];
export default function AccountsTab({ property }) {
	const { t } = useTranslation();
	const isArchived = property.archived;
	const { isOpen, onOpen, onClose } = useDisclosure();
	const accounts = property.accounts || [];
	const initialAccountInformation = { userName: "", type: "", password: "", propertyId: property.id, provider: "" };
	const [accountInformation, setAccountInformation] = useState(initialAccountInformation);
	const dispatch = useDispatch();
	const [error, setError] = useState({});
	const toast = useToast();
	useEffect(() => {
		console.log(accountInformation);
	}, [accountInformation]);
	const addAccount = () => {
		console.log(accountInformation);
		const errors = addAccountVerifier(accountInformation);
		if (Object.entries(errors).length !== 0) {
			setError(errors);
			toast({
				title: "Error encountered.",
				description: "Invalid information",
				status: "error",
				duration: 2000,
				isClosable: true,
			});
			return;
		}
		setError({});
		dispatch(addAccountAsync(accountInformation))
			.then((r) => {
				if (r.error?.message)
					toast({
						title: "Error encountered",
						description: r.error.message,
						status: "error",
						duration: 9000,
						isClosable: true,
					});
				else {
					toast({
						title: "Success!",
						description: "Account added!",
						status: "success",
						duration: 2000,
						isClosable: true,
					});
				}
			})
			.finally(() => {
				setAccountInformation(initialAccountInformation);
				onClose();
			});
	};
	const handleAccountDelete = (account) => {
		//TODO: add account uuid so i can delete it later
		const payload = { ...account, propertyId: property.id };
		console.log(payload);
		dispatch(removeAccountAsync(payload));
		//dispatch delete account
	};
	const handleChange = (e) => {
		console.log("changeee");
		setAccountInformation({ ...accountInformation, [e.target.name]: e.target.value });
	};
	const renderAccountPopover = (account) => (
		<Popover>
			<PopoverTrigger>
				<Button width={"fit-content"}>{t(`types.${account.type}`)}</Button>
			</PopoverTrigger>
			<PopoverContent>
				<PopoverArrow />
				<PopoverCloseButton />
				<PopoverHeader>
					{t("property.accountInformation")} -{" "}
					<Text as="span" fontWeight={"bold"}>
						{t(`types.${account.type}`)}
					</Text>
				</PopoverHeader>
				<PopoverBody>
					<Stack>
						<Box>
							<Text as={"span"} fontWeight={"bold"}>
								{t("property.provider")}:
							</Text>{" "}
							{account.provider || "-"}
						</Box>
						<Box>
							<Text as={"span"} fontWeight={"bold"}>
								{t("property.userName")}:
							</Text>{" "}
							{account.userName}
						</Box>
						<Box>
							<Text as={"span"} fontWeight={"bold"}>
								{t("property.password")}:
							</Text>{" "}
							{account.password}
						</Box>
					</Stack>
				</PopoverBody>
				<PopoverFooter border="0" display="flex" alignItems="center" justifyContent="space-between" pb={4}>
					<Button
						colorScheme="red"
						onClick={() => {
							handleAccountDelete(account);
						}}
					>
						{t("table.delete")}
					</Button>
				</PopoverFooter>
			</PopoverContent>
		</Popover>
	);
	const renderAddAccountModal = () => (
		<Modal isOpen={isOpen} onClose={onClose} size={"xl"}>
			<ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
			<ModalContent>
				<ModalHeader>
					<Text>Add a Contact</Text>
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Stack>
						<FormControl>
							<FormLabel display={"inline"}>User Name</FormLabel>
							<Input
								width={"fit-content"}
								type="text"
								name="userName"
								placeholder="User Name"
								isInvalid={error?.userName}
								_placeholder={{ color: "gray.500" }}
								value={accountInformation.userName}
								onChange={handleChange}
							/>
						</FormControl>
						<FormControl>
							<FormLabel display={"inline"}>Password</FormLabel>
							<Input
								width={"fit-content"}
								type="text"
								name="password"
								placeholder="Password"
								isInvalid={error?.password}
								_placeholder={{ color: "gray.500" }}
								value={accountInformation.phoneNumber}
								onChange={handleChange}
							/>
						</FormControl>
						<FormControl>
							<FormLabel display={"inline"}>Provider</FormLabel>
							<Input
								width={"fit-content"}
								type="text"
								name="provider"
								placeholder="Provider"
								isInvalid={error?.provider}
								_placeholder={{ color: "gray.500" }}
								value={accountInformation.provider}
								onChange={handleChange}
							/>
						</FormControl>
						<FormControl display={"flex"} alignItems="center">
							<FormLabel display={"inline"}>Type:</FormLabel>
							<Select
								width={"fit-content"}
								isInvalid={error?.type}
								onChange={handleChange}
								name="type"
								placeholder="Account Type"
								_placeholder={{ color: "gray.500" }}
							>
								{accountTypes.map((t, index) => (
									<option key={index} value={t.id}>
										{t.label}
									</option>
								))}
							</Select>
						</FormControl>
					</Stack>
				</ModalBody>
				<ModalFooter>
					<Button
						colorScheme="red"
						onClick={() => {
							onClose();
							setError({});
							setAccountInformation(initialAccountInformation);
						}}
						mr={3}
					>
						{t("misc.cancel")}
					</Button>
					<Button colorScheme="blue" onClick={addAccount}>
						{t("misc.save")}
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
	return (
		<Stack>
			<Box>
				<Button colorScheme={"blue"} onClick={onOpen} isDisabled={isArchived}>
					{t("property.addAccount")}
				</Button>
				{renderAddAccountModal()}
			</Box>
			<Divider />
			<Wrap>
				{accounts.map((a, index) => (
					<WrapItem key={index}>{renderAccountPopover(a)}</WrapItem>
				))}
				{accounts.length === 0 && <Text>{t("table.noData")}</Text>}
			</Wrap>
		</Stack>
	);
}
