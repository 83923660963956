import React from "react";
import { useSelector } from "react-redux";
import { Grid, Stack, Table, Tbody, Text, Th, Thead, Tr } from "@chakra-ui/react";

import { Card, CardHeader, CardBody } from "@chakra-ui/react";
import PaymentsRow from "./PaymentsRow";
import { selectActiveProperties } from "../../../redux/user/userSlice";
import PropertyPaymentsCard from "./PropertiesPaymentCard";
import { getPropertyAddress } from "../../../utils/verifier";
import { useTranslation } from "react-i18next";
export default function Payments() {
	const properties = useSelector(selectActiveProperties);
	const payments = properties
		.map((pr) =>
			pr.payments.map((p) => {
				return { ...p, property: pr };
			})
		)
		.flat();
	const { t } = useTranslation();
	return (
		<Stack>
			<Card overflowX={{ sm: "scroll", xl: "hidden" }}>
				<CardHeader>
					<Text fontSize="xl" fontWeight="bold">
						{t("navbarMenu.payments")}
					</Text>
				</CardHeader>
			</Card>
			{properties.map((property) => {
				const payments = property.payments;
				return <PropertyPaymentsCard payments={payments} address={getPropertyAddress(property)} propertyId={property.id} />;
			})}
		</Stack>
	);
}
