import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	Grid,
	useColorModeValue,
	Table,
	Tbody,
	Text,
	Th,
	Thead,
	Tr,
	Button,
	Stack,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	FormControl,
	FormLabel,
	Input,
	useDisclosure,
	ModalFooter,
	Textarea,
	useToast,
	Td,
	TableCaption,
	Box,
} from "@chakra-ui/react";

import { Card, CardHeader, CardBody } from "@chakra-ui/react";

import Dropzone from "../../Components/Dropzone/Dropzone";
import { _arrayBufferToBase64 } from "../../utils/convertors";
import { addTicketAsync, selectActiveProperties } from "../../redux/user/userSlice";
import { getPropertyAddress } from "../../utils/verifier";
import TicketsTableRow from "./Tickets/TicketsTableRow";
import PaymentsRow from "./Payments/PaymentsRow";
import { useTranslation } from "react-i18next";
export default function Dashboard() {
	const textColor = useColorModeValue("gray.700", "white");
	const properties = useSelector(selectActiveProperties);
	const email = useSelector((state) => state.user.user?.email);
	const phoneNumber = useSelector((state) => state.user.user?.phoneNumber);
	const name = useSelector((state) => state.user.user?.name);
	const tickets = properties
		.map((p) =>
			p.tickets.map((t) => {
				return { ...t, address: getPropertyAddress(p), propertyId: p.id };
			})
		)
		.flat();
	const outstandingPayments = properties
		.map((property) => {
			const address = getPropertyAddress(property);
			return property.payments
				.filter((payment) => !payment.paymentDate)
				.map((payment) => {
					return { ...payment, address, propertyId: property.id };
				});
		})
		.flat();
	const initialAddTicketInformation = { title: "", description: "", media: [] };
	const [addTicketInformation, setAddTicketInformation] = useState(initialAddTicketInformation);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const toast = useToast();
	const dispatch = useDispatch();
	const handleChange = (e) => {
		setAddTicketInformation({ ...addTicketInformation, [e.target.name]: e.target.value });
	};
	const addTicket = (propertyId) => {
		const payload = { ticket: addTicketInformation, propertyId, author: { email, name, phoneNumber } };
		console.log(payload);
		dispatch(addTicketAsync(payload))
			.then((r) => {
				if (r.error?.message)
					toast({
						title: "Error encountered",
						description: r.error.message,
						status: "error",
						duration: 9000,
						isClosable: true,
					});
				else {
					toast({
						title: "Success!",
						description: "Document Added!",
						status: "success",
						duration: 2000,
						isClosable: true,
					});
				}
			})
			.finally(() => {
				setAddTicketInformation(initialAddTicketInformation);
				onClose();
			});
	};
	const onDocumentDrop = async (acceptedFiles) => {
		console.log(acceptedFiles);
		let documents = [];
		for (const file of acceptedFiles) {
			const buffer = await file.arrayBuffer();
			const documentName = file.name;
			const contentType = file.type;
			documents.push({ documentData: _arrayBufferToBase64(buffer), documentName, contentType });
		}
		setAddTicketInformation({ ...addTicketInformation, media: documents });
	};
	const renderAddTicketModal = (propertyId) => {
		return (
			<Modal isOpen={isOpen} onClose={onClose} size={"xl"}>
				<ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
				<ModalContent>
					<ModalHeader>
						<Text>Add a Ticket</Text>
					</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Stack>
							<FormControl>
								<FormLabel display={"inline"}>Title:</FormLabel>
								<Input width={"fit-content"} onChange={handleChange} type="text" name="title" placeholder="Ttile" _placeholder={{ color: "gray.500" }} />
							</FormControl>
							<FormControl>
								<FormLabel display={"inline"}>Description:</FormLabel>
								<Textarea onChange={handleChange} type="number" name="description" placeholder="Description" _placeholder={{ color: "gray.500" }} />
							</FormControl>

							<FormControl>
								<FormLabel>Media:</FormLabel>
								<Dropzone onDrop={onDocumentDrop} accept={{ "image/*": [], "video/*": [] }} description="Upload an Image or a Video" />
							</FormControl>
						</Stack>
					</ModalBody>
					<ModalFooter>
						<Button
							colorScheme="red"
							onClick={() => {
								onClose();
								setAddTicketInformation(initialAddTicketInformation);
							}}
							mr={3}
						>
							Cancel
						</Button>
						<Button
							colorScheme="blue"
							onClick={() => {
								addTicket(propertyId);
							}}
						>
							Save
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		);
	};
	const renderPropertyTickets = (tickets, address, propertyId) => {
		return (
			<Box>
				<Button onClick={onOpen}>Add Ticket</Button>
				{renderAddTicketModal(propertyId)}
				<Table variant="simple">
					<TableCaption placement="top">Address: {address}</TableCaption>
					<Thead>
						<Tr my=".8rem" pl="0px">
							<Th>Added date</Th>
							<Th>Status</Th>
							<Th>Title</Th>
							<Th>Author</Th>
							<Th></Th>
						</Tr>
					</Thead>
					<Tbody>
						{tickets?.map((t, index) => (
							<TicketsTableRow key={index} row={t} author={{ name: name, phoneNumber, email }} owner={t.owner} />
						))}
						{tickets?.length === 0 && (
							<Tr>
								<Td colSpan={5}>No Tickets Yet</Td>
							</Tr>
						)}
					</Tbody>
				</Table>
			</Box>
		);
	};
	const { t } = useTranslation();
	return (
		<React.Fragment>
			<Stack>
				<Card overflowX={{ sm: "scroll", xl: "hidden" }}>
					<CardHeader>
						<Text fontSize="xl" fontWeight="bold">
							{t("navbarMenu.outstanding")}
						</Text>
					</CardHeader>
					<CardBody>
						<Table variant="simple" size="sm">
							<Thead>
								<Tr my=".8rem">
									<Th pl="0px">{t("table.issuedDate")}</Th>
									<Th>{t("table.status")}</Th>
									<Th>{t("table.dueDate")}</Th>
									<Th>{t("table.amount")}</Th>
									<Th>{t("table.method")}</Th>
									<Th>{t("table.type")}</Th>
									<Th>{t("table.paidDate")}</Th>
									<Th></Th>
								</Tr>
							</Thead>
							<Tbody>
								{outstandingPayments?.map((row, index) => (
									<PaymentsRow
										id={row.id}
										key={index}
										date={row.issuedDate}
										address={row.address}
										paymentDate={row.paymentDate}
										code={row.number}
										amount={row.amount}
										type={row.type}
										status={row.paymentDate ? "paid" : "due"}
										method={row.paymentMethod}
										documents={row.documents}
										paymentDirection={row.paymentDirection}
										currency={row.currency}
										propertyId={row.propertyId}
										billedTo={{ name, email, phoneNumber }}
										dueDate={row.dueDate}
									/>
								))}
								{outstandingPayments.length === 0 && (
									<Tr>
										<Th colSpan="7">{t("table.noData")}</Th>
									</Tr>
								)}
							</Tbody>
						</Table>
					</CardBody>
				</Card>
				<Card overflowX={{ sm: "scroll", xl: "hidden" }}>
					<CardHeader>
						<Text fontSize="xl" color={textColor} fontWeight="bold">
							{t("dashboard.openTickets")}
						</Text>
					</CardHeader>
					<CardBody>
						<Table variant="simple" size="sm">
							<Thead>
								<Tr my=".8rem">
									<Th>Added date</Th>
									<Th>Status</Th>
									<Th>Title</Th>
									<Th>Author</Th>
									<Th></Th>
								</Tr>
							</Thead>
							<Tbody>
								{tickets?.map((t, index) => (
									<TicketsTableRow key={index} row={t} author={{ email, phoneNumber, name }} propertyId={t.propertyId} />
								))}
								{tickets.length === 0 && (
									<Tr>
										<Th colSpan="4" textAlign="center">
											{t("table.noData")}
										</Th>
									</Tr>
								)}
							</Tbody>
						</Table>
					</CardBody>
				</Card>
			</Stack>
			<Grid marginBottom={4} hidden>
				<Card overflowX={{ sm: "scroll", xl: "hidden" }}>
					<CardHeader>
						<Text fontSize="xl" color={textColor} fontWeight="bold">
							Open Tickets
						</Text>
					</CardHeader>
					<CardBody>
						{properties.map((p) => {
							const a = getPropertyAddress(p);
							const t = p.tickets.map((t) => {
								return { ...t, address: getPropertyAddress(p, a), propertyId: p.id, owner: p.owner };
							});
							return renderPropertyTickets(t, a, p.id);
						})}
					</CardBody>
				</Card>
			</Grid>
		</React.Fragment>
	);
}
