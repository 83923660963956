import {
	Badge,
	Button,
	Flex,
	Td,
	Text,
	Tr,
	useColorModeValue,
	ListItem,
	UnorderedList,
	Link,
	Wrap,
	WrapItem,
	Tooltip,
	Popover,
	PopoverTrigger,
	PopoverHeader,
	PopoverContent,
	PopoverBody,
} from "@chakra-ui/react";
import { Stack, IconButton } from "@chakra-ui/react";
import React from "react";

import { NavLink as ReactRouterLink } from "react-router-dom";
import { getPropertyAddress } from "../../utils/verifier";
import OwnerPopover from "../ContactPopover/OwnerPopover";
import TenantPopover from "../ContactPopover/TenantPopover";
import AdministrationStatusTooltipLabel from "../StatusTooltipLabel/AdministrationStatusTooltipLabel";
import moment from "moment";
import { getPropertyStatusText } from "../../utils/utils";
import LeaseStatusTooltipLabel from "../StatusTooltipLabel/LeaseStatusTooltipLabel";
import StatementStatusTooltipLabel from "../StatusTooltipLabel/StatementStatusTooltipLabel";
import { fullDateFormat } from "../../utils/types";
import { CalendarIcon } from "@chakra-ui/icons";
import { useTranslation } from "react-i18next";
function PropertiesTableRow(props) {
	const { row } = props;
	const textColor = useColorModeValue("gray.700", "white");
	const { t } = useTranslation();

	const getAdministrationColorScheme = (status) => {
		switch (getPropertyStatusText(status)) {
			case "incomplete":
				return "red";
			case "expired":
				return "red";
			case "warning":
				return "yellow";
			default:
				return "green";
		}
	};
	const getLeaseColorScheme = (status) => {
		switch (getPropertyStatusText(status)) {
			case "incomplete":
				return "red";
			case "expired":
				return "red";
			case "warning":
				return "yellow";
			case "empty":
				return "blue";
			default:
				return "green";
		}
	};
	const getStatementColorScheme = (status) => {
		switch (getPropertyStatusText(status)) {
			case "incomplete":
				return "red";
			case "expired":
				return "red";
			case "warning":
				return "yellow";
			case "empty":
				return "blue";
			default:
				return "green";
		}
	};

	const admStatus = row.admStatus;

	const leaseStatus = row.leaseStatus;

	const financialStatementStatus = row.financialStatementStatus;
	return (
		<>
			<Tr>
				<Td minWidth={{ sm: "250px" }} pl="0px">
					<Flex align="center" minWidth="100%" flexWrap="nowrap">
						<Flex direction="column">
							<Text fontSize="md" color={textColor} fontWeight="bold" minWidth="100%">
								{getPropertyAddress(row)}
							</Text>
							<Text fontSize="sm" fontWeight="normal">
								{moment(row.addedDate).format("DD MMM YYYY - HH:mm")}
							</Text>
						</Flex>
					</Flex>
				</Td>
				<Td>
					<OwnerPopover contact={row.owner} />
				</Td>
				<Td>
					<Tooltip label={<AdministrationStatusTooltipLabel property={row} administrationStatus={admStatus} />}>
						<Badge colorScheme={getAdministrationColorScheme(admStatus)} fontSize="16px">
							{t(`table.${getPropertyStatusText(admStatus)}`)}
						</Badge>
					</Tooltip>
				</Td>
				<Td>
					<Tooltip label={<LeaseStatusTooltipLabel property={row} leaseStatus={leaseStatus} />}>
						<Badge colorScheme={getLeaseColorScheme(leaseStatus)} fontSize="16px">
							{t(`table.${getPropertyStatusText(leaseStatus)}`)}
						</Badge>
					</Tooltip>
				</Td>

				<Td>
					<Tooltip label={<StatementStatusTooltipLabel statementStatus={financialStatementStatus} />}>
						<Badge colorScheme={getStatementColorScheme(financialStatementStatus)} fontSize="16px">
							{t(`table.${getPropertyStatusText(financialStatementStatus)}`)}
						</Badge>
					</Tooltip>
				</Td>
				<Td>
					<Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
						{row.rent.amount} {row.rent.currency}
					</Text>
				</Td>
				<Td>
					<Wrap fontSize="md" color={textColor} pb=".5rem">
						{row.tenants
							?.filter((t) => !t.former)
							.map((t, index) => (
								<WrapItem key={index}>
									<TenantPopover contact={t} propertyId={row.id} />
								</WrapItem>
							))}
					</Wrap>
				</Td>
				<Td>
					<Stack direction="row">
						<Popover>
							<PopoverTrigger>
								<IconButton icon={<CalendarIcon />} />
							</PopoverTrigger>
							<PopoverContent>
								<PopoverHeader fontWeight="semibold">{t("table.lastActivities")}</PopoverHeader>
								<PopoverBody>
									{row.activities?.length === 0 && t("table.noData")}
									<UnorderedList whiteSpace={"normal"}>
										{row.activities?.slice(0, 10).map((activity, index) => (
											<ListItem key={index}>
												{activity.title} - {moment(activity.addedDate).format(fullDateFormat)}
											</ListItem>
										))}
									</UnorderedList>
								</PopoverBody>
							</PopoverContent>
						</Popover>

						<Link as={ReactRouterLink} to={`/properties/view/${row.id}`}>
							<Button>{t("table.view")}</Button>
						</Link>
					</Stack>
				</Td>
			</Tr>
		</>
	);
}

export default PropertiesTableRow;
