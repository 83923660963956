import React from "react";
import "./App.css";
import Navbar from "./Components/Navbar/Navbar";
import { Outlet } from "react-router-dom";
import ErrorBoundary from "./Components/ErrorBoundry/ErrorBoundry";
import FallbackComponent from "./Components/ErrorBoundry/FallbackComponent";
import ReportButton from "./Components/ReportButton/ReportButton";

function App() {
	return (
		<div className="App">
			<ErrorBoundary fallback={<FallbackComponent />}>
				<Navbar>
					<Outlet />
				</Navbar>
				<ReportButton />
			</ErrorBoundary>
		</div>
	);
}

export default App;
