import {
	Box,
	Button,
	Image,
	Stack,
	Wrap,
	WrapItem,
	useDisclosure,
	Modal,
	ModalOverlay,
	ModalHeader,
	ModalContent,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	FormControl,
	FormLabel,
	Link,
	Input,
	Heading,
	Flex,
	Select,
	Tooltip,
	Icon,
	useToast,
	Divider,
} from "@chakra-ui/react";
import "./imagesTab.css";
import React, { useCallback, useState } from "react";
import ImageViewer from "react-simple-image-viewer";
import { _arrayBufferToBase64 } from "../../utils/convertors";
import Dropzone from "../Dropzone/Dropzone";
import { useDispatch } from "react-redux";
import { addMediaAsync, removeMediaAsync } from "../../redux/user/userSlice";
import { useEffect } from "react";
import { API } from "../../utils/http";
import { CloseIcon, DownloadIcon } from "@chakra-ui/icons";
import Gallery from "../Gallery/Gallery";
import { useTranslation } from "react-i18next";
export default function ImagesTab({ property }) {
	const isArchived = property.archived;
	const [currentImage, setCurrentImage] = useState(0);
	const { t } = useTranslation();
	const [isViewerOpen, setIsViewerOpen] = useState(false);
	const [images, setImages] = useState([]);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [media, setMedia] = useState([]);
	const toast = useToast();
	const dispatch = useDispatch();
	const openImageViewer = useCallback((index) => {
		setCurrentImage(index);
		setIsViewerOpen(true);
	}, []);
	useEffect(() => {
		console.log("Getting property media urls");
		const payload = { propertyId: property.id, media: property.media };
		API.put("get-media-urls", payload).then((r) => {
			setImages(r.data.media);
		});
	}, [property.media]);

	const closeImageViewer = () => {
		setCurrentImage(0);
		setIsViewerOpen(false);
	};
	const onMediaDrop = async (acceptedFiles) => {
		console.log(acceptedFiles);
		let documents = [];
		for (const file of acceptedFiles) {
			const buffer = await file.arrayBuffer();
			const documentName = file.name;
			const contentType = file.type;
			documents.push({ documentData: _arrayBufferToBase64(buffer), documentName, contentType });
		}

		setMedia([...documents]);
	};
	const addMedia = () => {
		const payload = { propertyId: property.id, media };
		console.log(payload);
		//dispatch add media
		dispatch(addMediaAsync(payload))
			.then((r) => {
				if (r.error?.message)
					toast({
						title: "Error encountered",
						description: r.error.message,
						status: "error",
						duration: 9000,
						isClosable: true,
					});
				else {
					toast({
						title: "Success!",
						description: "Media added!",
						status: "success",
						duration: 2000,
						isClosable: true,
					});
				}
			})
			.finally(() => {
				onClose();
			});
	};
	const handleDeleteMedia = (media) => {
		const payload = { id: media.id, propertyId: property.id };
		console.log("delete: ", payload);
		dispatch(removeMediaAsync(payload));
		//dispatch delete media
	};
	const renderAddActivityModal = () => (
		<Modal isOpen={isOpen} onClose={onClose} size={"3xl"}>
			<ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
			<ModalContent>
				<ModalHeader>{t("property.addMedia")}</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Flex align={"center"} justify={"center"}>
						<Stack spacing={2} w={"full"} rounded={"xl"} p={3}>
							<FormControl>
								<FormLabel>Media:</FormLabel>
								<Dropzone onDrop={onMediaDrop} accept={{ "image/*": [], "video/*": [] }} description="Upload an Image or a Video" />
							</FormControl>
						</Stack>
					</Flex>
				</ModalBody>
				<ModalFooter>
					<Button colorScheme="red" onClick={onClose} mr={3}>
						{t("misc.cancel")}
					</Button>
					<Button colorScheme="blue" onClick={addMedia}>
						{t("misc.save")}
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
	return (
		<Stack>
			<Stack>
				<Button width={"fit-content"} colorScheme="blue" onClick={onOpen} isDisabled={isArchived}>
					{t("property.addMedia")}
				</Button>
				<Divider />
				{renderAddActivityModal()}
			</Stack>
			<Gallery items={images} handleMediaDelete={handleDeleteMedia} />
			<Wrap hidden={true}>
				{images.map((i, index) => (
					<WrapItem key={index}>
						<Box position="relative" key={index}>
							<Image
								src={i.url}
								onClick={() => openImageViewer(index)}
								height={"200px"}
								style={{ margin: "2px" }}
								alt=""
								cursor={"pointer"}
								className="ticketDocument"
							/>
							<Button
								className="ticketDocumentDownloadButton"
								style={{ position: "absolute", top: "10%", left: "90%", transform: "translate(-50%,-50%)", display: "none" }}
								onClick={() => {
									handleDeleteMedia(i);
								}}
								isDisabled={isArchived}
							>
								<Tooltip label="Delete" fontSize="md">
									<Icon as={CloseIcon} color={"red.400"} boxSize={6}></Icon>
								</Tooltip>
							</Button>
						</Box>
					</WrapItem>
				))}
				{isViewerOpen && (
					<ImageViewer
						src={images.map((i) => i.url)}
						currentIndex={currentImage}
						onClose={closeImageViewer}
						disableScroll={false}
						backgroundStyle={{
							backgroundColor: "rgba(0,0,0,0.9)",
						}}
						closeOnClickOutside={true}
					/>
				)}
			</Wrap>
		</Stack>
	);
}
