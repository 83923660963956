import React from "react";
import { Box, Heading, Text, Button, Stack } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { signOutAsync } from "../../redux/auth/authSlice";

export default function UserNotFound() {
	const dispatch = useDispatch();
	const handleSignOut = async () => {
		dispatch(signOutAsync());
	};

	return (
		<Box textAlign="center" py={10} px={6}>
			<Heading display="inline-block" as="h2" size="2xl" bgGradient="linear(to-r, teal.400, teal.600)" backgroundClip="text" mb={6}>
				User Not Associated
			</Heading>
			<Text color={"gray.500"} mb={6}>
				It appears you're not associated with any agency in our system. Please contact your agency to create your account and access our services.
			</Text>
			<Stack alignItems={"center"}>
				{/* <Link to="/" width={"fit-content"}>
					<Button colorScheme="teal" bgGradient="linear(to-r, teal.400, teal.500, teal.600)" color="white" variant="solid" width={"fit-content"}>
						Go To Dashboard
					</Button>
				</Link> */}
				<Button width={"fit-content"} onClick={handleSignOut} variant="solid" colorScheme="teal">
					Sign Out
				</Button>
			</Stack>
		</Box>
	);
}
