import React from "react";
import { List, ListItem, ListIcon } from "@chakra-ui/react";

import { MdCheckCircle, MdSettings } from "react-icons/md";
import { IoMdCloseCircle } from "react-icons/io";
import moment from "moment/moment";
import { fullDateFormat } from "../../utils/types";
import { useTranslation } from "react-i18next";

export default function LeaseStatusTooltipLabel({ property, leaseStatus }) {
	const { t } = useTranslation();
	// const getListIcon = (bool) => (bool ? <ListIcon as={MdCheckCircle} color="green.500" /> : <ListIcon as={IoMdCloseCircle} color="red.500" />);
	const getListIcon = (status) => {
		switch (status) {
			case "warning":
				return <ListIcon as={MdSettings} color="yellow.500" />;
			case "complete":
				return <ListIcon as={MdCheckCircle} color="green.500" />;
			case "incomplete":
				return <ListIcon as={IoMdCloseCircle} color="red.500" />;
			case "expired":
				return <ListIcon as={IoMdCloseCircle} color="red.500" />;
			default:
				return <ListIcon as={IoMdCloseCircle} color="red.500" />;
		}
	};
	const getLeaseExpirationDate = () => {
		const lease = property.documents.find((doc) => doc.type === "lease" && doc.active);
		const extension = property.documents.find((doc) => doc.type === "extension" && doc.active);
		if (!lease) return "";
		if (extension) return `${moment(lease.startingDate).format(fullDateFormat)} - ${moment(extension.expirationDate).format(fullDateFormat)}`;
		return `${moment(lease.startingDate).format(fullDateFormat)} - ${moment(lease.expirationDate).format(fullDateFormat)}`;
	};
	return (
		<List spacing={3}>
			{property.specs.apartmentFeatures.type === "garage" ? (
				<ListItem>
					{getListIcon(leaseStatus.lease)}
					{t("types.lease")}
				</ListItem>
			) : (
				<>
					<ListItem>
						{getListIcon(leaseStatus.lease)}
						{t("types.lease")}: {getLeaseExpirationDate()}
					</ListItem>
					<ListItem>
						{getListIcon(leaseStatus.entryReport)}
						{t("types.entryReport")}
					</ListItem>
					{leaseStatus.exitReport && (
						<>
							<ListItem>
								{getListIcon(leaseStatus.exitReport)}
								{t("types.exitReport")}
							</ListItem>
							<ListItem>
								{getListIcon(leaseStatus.termination)}
								{t("types.termination")}
							</ListItem>
						</>
					)}
					<ListItem>
						{getListIcon(leaseStatus.sanitationDeclaration)}
						{t("types.sanitationDeclaration")}
					</ListItem>
					<ListItem>
						{getListIcon(leaseStatus.tenantAssociationAnnouncement)}
						{t("types.tenantAssociationAnnouncement")}
					</ListItem>
					<ListItem>
						{getListIcon(leaseStatus.tenants)}
						{t("types.tenants")}
					</ListItem>
					{property.tenants
						.filter((t) => !t.former)
						.map((tenant, index) => {
							console.log(leaseStatus[tenant.email]);
							return (
								<ListItem key={index}>
									{getListIcon(leaseStatus[tenant.email])}
									{tenant.name} - {t("types.jobCertificate")}
								</ListItem>
							);
						})}
				</>
			)}
		</List>
	);
}
