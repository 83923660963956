import React from "react";
import { Box, Card, CardBody, CardHeader, Stack, Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import ReportsTableRow from "./ReportsTableRow";
import DocumentsTableRow from "./DocumentsTableRow";
import { useTranslation } from "react-i18next";

export default function PropertyDocumentsCard({ documents, reports, address, propertyId }) {
	const { t } = useTranslation();
	return (
		<Card overflowX={{ sm: "scroll", xl: "hidden" }}>
			<CardHeader>
				<Text fontSize="xl">Address: {address}</Text>
			</CardHeader>
			<CardBody>
				<Stack>
					<Box overflowX="auto">
						<Text fontWeight={"bold"} fontSize={"large"} margin={"20px 0px 20px 0px"}>
							Reports
						</Text>
						<Table variant="simple" size="sm">
							<Thead>
								<Tr my=".8rem" pl="0px">
									<Th pl="0px">{t("table.addedDate")}</Th>
									<Th>{t("table.name")}</Th>
									<Th>{t("table.type")}</Th>
									<Th>{t("table.number")}</Th>
									<Th>{t("table.actions")}</Th>
								</Tr>
							</Thead>
							<Tbody>
								{reports.length !== 0 ? (
									reports.map((row, index) => {
										return <ReportsTableRow key={index} row={row} propertyId={propertyId} />;
									})
								) : (
									<Tr>
										<Td colSpan={6}>No Reports Yet</Td>
									</Tr>
								)}
							</Tbody>
						</Table>
					</Box>
					<Box overflowX="auto">
						<Text fontWeight={"bold"} fontSize={"large"} margin={"20px 0px 20px 0px"}>
							Lease
						</Text>

						<Table variant="simple" size="sm">
							<Thead>
								<Tr my=".8rem" pl="0px" whiteSpace={"nowrap"}>
									<Th pl="0px">{t("table.addedDate")}</Th>
									<Th>{t("table.name")}</Th>
									<Th>{t("table.type")}</Th>
									<Th>{t("table.number")}</Th>
									<Th>{t("table.startingDate")}</Th>
									<Th>{t("table.expirationDate")}</Th>
									<Th>{t("table.actions")}</Th>
								</Tr>
							</Thead>
							<Tbody>
								{documents.length !== 0 ? (
									<>
										{documents
											.filter((d) => d.active)
											.map((row, index) => {
												return <DocumentsTableRow key={index} row={row} propertyId={propertyId} />;
											})}
										{documents
											.filter((d) => !d.active)
											.map((row, index) => {
												return <DocumentsTableRow key={index} row={row} propertyId={propertyId} />;
											})}
									</>
								) : (
									<Tr>
										<Td colSpan={7}>No Documents Yet</Td>
									</Tr>
								)}
							</Tbody>
						</Table>
					</Box>
				</Stack>
			</CardBody>
		</Card>
	);
}
