import { Center, Heading } from "@chakra-ui/react";
import { Button, FormControl, Flex, Input, Stack, useColorModeValue, HStack } from "@chakra-ui/react";
import { PinInput, PinInputField } from "@chakra-ui/react";
import { useState, useEffect } from "react";

export default function VerificationCode({ email, verifyCode }) {
	const [pin, setPin] = useState("");
	useEffect(() => {
		console.log(pin);
	}, [pin]);

	return (
		<Stack spacing={4} w={"full"} maxW={"sm"} bg={useColorModeValue("white", "gray.700")} rounded={"xl"} boxShadow={"lg"} p={6} my={10}>
			<Center>
				<Heading lineHeight={1.1} fontSize={{ base: "2xl", md: "3xl" }}>
					Verificare Email
				</Heading>
			</Center>
			<Center fontSize={{ base: "sm", sm: "md" }} color={useColorModeValue("gray.800", "gray.400")}>
				Am trimis un cod pe e-mailul dvs., care poate dura până la 5 minute. Vă rugăm să verificați căsuța de e-mail, inclusiv dosarul de spam și să introduceți
				codul de mai jos odată ce ați primit.
			</Center>
			<Center fontSize={{ base: "sm", sm: "md" }} fontWeight="bold" color={useColorModeValue("gray.800", "gray.400")}>
				{email}
			</Center>
			<FormControl>
				<Center>
					<HStack>
						<PinInput
							value={pin}
							onChange={(v) => {
								setPin(v);
							}}
						>
							<PinInputField />
							<PinInputField />
							<PinInputField />
							<PinInputField />
							<PinInputField />
							<PinInputField />
						</PinInput>
					</HStack>
				</Center>
			</FormControl>
			<Stack spacing={6}>
				<Button
					colorScheme="blue"
					onClick={() => {
						verifyCode(pin);
					}}
				>
					Confirmare
				</Button>
			</Stack>
		</Stack>
	);
}
