import React from "react";
import { Box, Spinner, Text } from "@chakra-ui/react";

const Loader = ({ text }) => {
	return (
		<Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" padding={4}>
			<Spinner size="xl" color="teal.500" />
			{text && <Text mt={4}>{text}</Text>}
		</Box>
	);
};

export default Loader;
