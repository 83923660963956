import React from "react";
import { Tooltip, Box, Stack, Text } from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";

export default function InfoTooltip({ searchOptions }) {
	return (
		<>
			<Tooltip
				label={
					<Box width="fit-content">
						<Text>Search criteria:</Text>
						{!searchOptions && <Text>No search criteria</Text>}
						{searchOptions?.map((option) => (
							<Stack direction="row">
								<Text fontWeight="bold">{option.label}:</Text>
								<Text>{option.description}</Text>
							</Stack>
						))}
					</Box>
				}
			>
				<Box as={InfoIcon} cursor="pointer" _hover={{ color: "blue.500" }} />
			</Tooltip>
		</>
	);
}
