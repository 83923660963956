import { Box, Flex, Text, Input, Stack } from "@chakra-ui/react";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Card, CardHeader, CardBody } from "@chakra-ui/react";
import { getPropertyAddress } from "../../../utils/verifier";
import PropertyPaymentsCard from "./PropertyPaymentsCard";
import { selectActiveProperties } from "../../../redux/user/userSlice";
import { useTranslation } from "react-i18next";

export default function Payments() {
	const [query, setquery] = useState("");
	const { t } = useTranslation();
	const stripeCustomerId = useSelector((state) => state.user.user?.stripeCustomerId);
	const email = useSelector((state) => state.user.user?.email);
	const name = useSelector((state) => state.user.user?.name);
	const phoneNumber = useSelector((state) => state.user.user?.phoneNumber);
	const properties = useSelector(selectActiveProperties);

	const handleSearchChange = (e) => {
		setquery(e.target.value);
	};
	return (
		<Stack>
			<Card overflowX={{ sm: "scroll", xl: "hidden" }}>
				<CardHeader>
					<Flex direction={"row"}>
						<Box style={{ marginRight: "15px" }}>
							<Text fontSize="xl" fontWeight="bold">
								{t("navbarMenu.payments")}
							</Text>
						</Box>
						{/* <Box>
							<Input type="text" placeholder="Search..." value={query} onChange={handleSearchChange} />
						</Box> */}
					</Flex>
				</CardHeader>
			</Card>
			{properties.map((property) => {
				const payments = property.payments;
				return (
					<PropertyPaymentsCard
						payments={payments}
						address={getPropertyAddress(property)}
						propertyId={property.id}
						stripeCustomerId={stripeCustomerId}
						name={name}
						email={email}
						phoneNumber={phoneNumber}
					/>
				);
			})}
		</Stack>
	);
}
