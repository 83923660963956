import { Text, Badge, Tr, Td } from "@chakra-ui/react";
import moment from "moment";
import React from "react";
import { fullDateTimeFormat } from "../../../utils/types";

export default function UtilityMeterTableRow(props) {
	const { meterIndex, timestamp, isCurrent } = props;

	return (
		<Tr>
			<Td>
				<Text fontSize="md">{meterIndex}</Text>
			</Td>
			<Td>
				<Text fontSize="md">{moment(timestamp).format(fullDateTimeFormat)}</Text>
			</Td>
			<Td>
				<Badge hidden={!isCurrent} colorScheme="green" fontSize="16px">
					Current index
				</Badge>
			</Td>
		</Tr>
	);
}
