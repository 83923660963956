import { Button, Flex, Link, Td, Text, Tr } from "@chakra-ui/react";
import React from "react";
import { getPropertyAddress } from "../../utils/verifier";
import moment from "moment";
import OwnerPopover from "../ContactPopover/OwnerPopover";

import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function ArchivedPropertiesTableRow({ row }) {
	const { t } = useTranslation();
	return (
		<Tr>
			<Td>
				<Flex align="center" minWidth="100%" flexWrap="nowrap">
					<Flex direction="column">
						<Text fontSize="md" fontWeight="bold" minWidth="100%">
							{getPropertyAddress(row)}
						</Text>
						<Text fontSize="sm" fontWeight="normal">
							{moment(row.addedDate).format("DD MMM YYYY - HH:mm")}
						</Text>
					</Flex>
				</Flex>
			</Td>
			<Td>
				<OwnerPopover contact={row.owner} />
			</Td>
			<Td>
				<Link as={NavLink} to={`/properties/view/${row.id}`}>
					<Button>{t("table.view")}</Button>
				</Link>
			</Td>
		</Tr>
	);
}
