import {
	Button,
	Flex,
	Modal,
	ModalOverlay,
	ModalHeader,
	ModalContent,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	Td,
	Text,
	Tr,
	useColorModeValue,
	Tooltip,
	Icon,
} from "@chakra-ui/react";

import { Stack } from "@chakra-ui/react";
import { DownloadIcon, DeleteIcon, ViewIcon } from "@chakra-ui/icons";
import React, { useEffect, useState } from "react";
import { useDisclosure } from "@chakra-ui/react";
import { useDispatch } from "react-redux";

import { API } from "../../utils/http";
import { removeProtocolAsync } from "../../redux/user/userSlice";
import Gallery from "../Gallery/Gallery";
import { fullDateFormat } from "../../utils/types";
import moment from "moment";
import { useTranslation } from "react-i18next";
import WithConfirmation from "../WithConfirmation/WithConfirmation";
function ProtocolsTableRow(props) {
	const { row, propertyId } = props;
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const textColor = useColorModeValue("gray.700", "white");
	const deleteDocument = (reportId) => {
		console.log("delete: ", { reportId, propertyId });
		dispatch(removeProtocolAsync({ reportId, propertyId }));
	};
	const handleGetDocument = () => {
		const payload = { propertyId, reportId: row.id, name: row.name };
		console.log(payload);
		API.put("get-protocol", payload).then((r) => {
			console.log(r);
			window.open(r.data.url, "_blank");
		});
	};
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [images, setImages] = useState([]);
	const getProtocolPictures = () => {
		const payload = { propertyId, media: row.media, reportId: row.id };
		API.put("get-protocol-picture-urls", payload).then((r) => {
			console.log(r.data);
			setImages(r.data.media);
		});
	};
	const renderViewModal = () => {
		return (
			<Modal isOpen={isOpen} onClose={onClose} size="3xl">
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Documents</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Gallery items={images} />
						<Stack direction={"row"}>
							<Text fontWeight={"bold"}>Report: </Text>
							<Text
								color="teal.500"
								textDecorationLine={"underline"}
								cursor={"pointer"}
								onClick={() => {
									handleGetDocument(row.id);
								}}
								width={"fit-content"}
							>
								{row.name}
								<DownloadIcon marginLeft={2} />
							</Text>
						</Stack>
					</ModalBody>

					<ModalFooter>
						<Button colorScheme="blue" mr={3} onClick={onClose}>
							Close
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		);
	};
	return (
		<Tr>
			<Td minWidth={{ sm: "250px" }} pl="0px">
				<Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
					<Text fontSize="md" color={textColor} minWidth="100%">
						{moment(row.addedDate).format(fullDateFormat)}
					</Text>
				</Flex>
			</Td>
			<Td>
				<Text fontSize="md" color={textColor} pb=".5rem">
					{row.name}
				</Text>
			</Td>
			<Td>
				<Text fontSize="md" color={textColor} pb=".5rem">
					{t(`types.${row.type}`)}
				</Text>
			</Td>
			<Td>
				<Text fontSize="md" color={textColor} pb=".5rem">
					{row.number}
				</Text>
			</Td>

			<Td>
				<Stack direction="row">
					<Button
						onClick={() => {
							onOpen();
							getProtocolPictures();
						}}
					>
						<Tooltip label="View">
							<Icon as={ViewIcon} color={"teal.400"} boxSize={6}></Icon>
						</Tooltip>
					</Button>

					<WithConfirmation onConfirm={() => deleteDocument(row.id)}>
						<Button colorScheme={"red"}>
							<Tooltip label="Delete">
								<Icon as={DeleteIcon} boxSize={6}></Icon>
							</Tooltip>
						</Button>
					</WithConfirmation>
					{renderViewModal()}
				</Stack>
			</Td>
		</Tr>
	);
}

export default ProtocolsTableRow;
