import React, { useState, useEffect } from "react";
import {
	Box,
	Button,
	Table,
	Tbody,
	Text,
	Th,
	Thead,
	Tr,
	Divider,
	Input,
	Stack,
	FormControl,
	FormLabel,
	Select,
	useDisclosure,
	Modal,
	ModalOverlay,
	ModalHeader,
	ModalContent,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	useToast,
	Textarea,
	Td,
} from "@chakra-ui/react";
import Dropzone from "../Dropzone/Dropzone";
import { useDispatch } from "react-redux";
import { addSecurityDepositAsync } from "../../redux/user/userSlice";
import { addSecurityDepositVerifier } from "../../utils/verifier";
import SecurityDepositRow from "../TableRows/SecurityDepositRow";
import { _arrayBufferToBase64 } from "../../utils/convertors";
import { useTranslation } from "react-i18next";

export default function SecurityDepositTab({ property }) {
	const { t } = useTranslation();
	const isArchived = property.archived;
	const initialPaymentInformation = {
		location: "custodyBank",
		custodian: "",
		documentNumber: "",
		propertyId: property.id,
		amount: "",
		currency: "",
		documents: [],
	};
	const dispatch = useDispatch();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [paymentInformation, setPaymentInformation] = useState(initialPaymentInformation);
	const [error, setError] = useState({});
	const toast = useToast();
	const onDocumentDrop = async (acceptedFiles) => {
		console.log(acceptedFiles);
		let documents = [];
		for (const file of acceptedFiles) {
			const buffer = await file.arrayBuffer();
			const documentName = file.name;
			const contentType = file.type;
			documents.push({ documentData: _arrayBufferToBase64(buffer), documentName, contentType });
		}
		setPaymentInformation({ ...paymentInformation, documents });
	};
	useEffect(() => {
		console.log(paymentInformation);
	}, [paymentInformation]);
	const addPayment = () => {
		const errors = addSecurityDepositVerifier(paymentInformation);
		console.log(paymentInformation);
		if (Object.entries(errors).length != 0) {
			console.log(errors);
			setError(errors);
			toast({
				title: "Error encountered.",
				description: "Invalid information",
				status: "error",
				duration: 2000,
				isClosable: true,
			});
			return;
		}
		setError({});
		dispatch(addSecurityDepositAsync(paymentInformation))
			.then((r) => {
				if (r.error?.message)
					toast({
						title: "Error encountered",
						description: r.error.message,
						status: "error",
						duration: 9000,
						isClosable: true,
					});
				else {
					toast({
						title: "Success!",
						description: "Security Deposit added!",
						status: "success",
						duration: 2000,
						isClosable: true,
					});
				}
			})
			.finally(() => {
				onClose();
				setPaymentInformation(initialPaymentInformation);
			});
	};
	const handleChange = (e) => {
		console.log("changeee");
		setPaymentInformation({ ...paymentInformation, [e.target.name]: e.target.value });
	};
	const handleBilledToChange = (e) => {
		console.log("changeee");
		const billedToEmail = e.target.value;
		console.log("email: ", billedToEmail);

		if (property.owner.email === billedToEmail) {
			setPaymentInformation({ ...paymentInformation, billedTo: { name: property.owner.name, email: billedToEmail, type: "owner" } });
			return;
		}
		if (property.contacts.find((c) => c.email === billedToEmail)) {
			const contact = property.contacts.find((c) => c.email === billedToEmail);
			console.log(contact);
			setPaymentInformation({ ...paymentInformation, billedTo: { name: contact.name, email: billedToEmail, type: contact.contactType } });
			return;
		}
		if (property.tenants.find((c) => c.email === billedToEmail)) {
			const tenant = property.tenants.find((c) => c.email === billedToEmail);
			console.log(tenant);
			setPaymentInformation({ ...paymentInformation, billedTo: { name: tenant.name, email: billedToEmail, type: "tenant" } });
			return;
		}
	};

	const renderAddPaymentModal = () => (
		<Modal isOpen={isOpen} onClose={onClose} size={"xl"}>
			<ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
			<ModalContent>
				<ModalHeader>
					<Text>Add a Security Deposit</Text>
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Stack>
						<FormControl>
							<FormLabel display={"inline"}>Document Number:</FormLabel>
							<Input
								width={"fit-content"}
								onChange={handleChange}
								isInvalid={error?.documentNumber}
								type="text"
								name="documentNumber"
								placeholder="Document Number"
								_placeholder={{ color: "gray.500" }}
							/>
						</FormControl>
						<FormControl display={"flex"} alignItems="center">
							<FormLabel display={"inline"}>Location:</FormLabel>
							<Select
								width={"fit-content"}
								isInvalid={error?.location}
								onChange={handleChange}
								type="text"
								name="location"
								_placeholder={{ color: "gray.500" }}
							>
								<option value="custodyBank">Custody Bank</option>
								<option value="custodyCash">Custody Cash</option>
								<option value="returned">Returned to Owner</option>
								<option value="returnedTenant">Returned to Tenant</option>
								<option value="owner">Owner Custody</option>
							</Select>
						</FormControl>
						<FormControl>
							<FormLabel display={"inline"}>Amount:</FormLabel>
							<Input
								isInvalid={error?.amount}
								width={"fit-content"}
								type="number"
								name="amount"
								placeholder="Amount"
								_placeholder={{ color: "gray.500" }}
								value={paymentInformation.amount}
								onChange={handleChange}
							/>
						</FormControl>
						<FormControl display={"flex"} alignItems="center">
							<FormLabel display={"inline"}>Currency:</FormLabel>
							<Select
								width={"fit-content"}
								type="number"
								name="currency"
								placeholder="Currency"
								isInvalid={error?.currency}
								_placeholder={{ color: "gray.500" }}
								value={paymentInformation.currency}
								onChange={handleChange}
							>
								<option value="eur">EUR</option>
								<option value="ron">RON</option>
							</Select>
						</FormControl>

						<FormControl>
							<FormLabel display={"inline"}>Custodian:</FormLabel>
							<Input
								width={"fit-content"}
								type="text"
								name="custodian"
								placeholder="Custodian"
								isInvalid={error?.custodian}
								_placeholder={{ color: "gray.500" }}
								onChange={handleChange}
							/>
						</FormControl>
						<FormControl>
							<FormLabel display={"inline"}>Description:</FormLabel>
							<Textarea
								name="description"
								placeholder="Description"
								isInvalid={error?.description}
								_placeholder={{ color: "gray.500" }}
								onChange={handleChange}
							/>
						</FormControl>
						<FormControl>
							<FormLabel>Documents:</FormLabel>
							<Dropzone
								onDrop={onDocumentDrop}
								accept={{
									"application/pdf": [".pdf"],
									"application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
									"application/msword": [".doc"],
								}}
								description="Upload PDF,DOC or DOCX Format"
							/>
						</FormControl>
					</Stack>
				</ModalBody>
				<ModalFooter>
					<Button
						colorScheme="red"
						onClick={() => {
							onClose();
							setPaymentInformation(initialPaymentInformation);
							setError({});
						}}
						mr={3}
					>
						{t("misc.cancel")}
					</Button>
					<Button colorScheme="blue" onClick={addPayment}>
						{t("misc.save")}
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);

	return (
		<Stack>
			<Box>
				<Button onClick={onOpen} colorScheme="blue" isDisabled={isArchived}>
					{t("property.addSecurityDeposit")}
				</Button>
			</Box>
			<Divider />
			{renderAddPaymentModal()}
			<Box overflowX="auto">
				<Table variant="simple" size="sm">
					<Thead>
						<Tr my=".8rem" pl="0px">
							<Th pl="0px">{t("table.addedDate")}</Th>
							<Th>{t("table.location")}</Th>
							<Th>{t("table.amount")}</Th>
							<Th>{t("table.custodian")}</Th>
							<Th>{t("table.description")}</Th>
							<Th>{t("table.updatedDate")}</Th>
							<Th></Th>
						</Tr>
					</Thead>
					<Tbody>
						{property.securityDeposits?.map((row, index) => {
							return (
								<SecurityDepositRow
									id={row.id}
									documentNumber={row.documentNumber}
									key={index}
									addedDate={row.addedDate}
									updatedDate={row.updatedDate}
									location={row.location}
									amount={row.amount}
									currency={row.currency}
									custodian={row.custodian}
									description={row.description}
									updateDate={row.updatedDate}
									propertyId={property.id}
									documents={row.documents}
								/>
							);
						})}
						{property.securityDeposits.length === 0 && (
							<Tr>
								<Td colSpan={7}>{t("table.noData")}</Td>
							</Tr>
						)}
					</Tbody>
				</Table>
			</Box>
		</Stack>
	);
}
