import React, { useState } from "react";
import { Flex, Table, Tbody, Text, Th, Thead, Tr, useColorModeValue, Box, Button, Stack, Td, Badge, TableContainer } from "@chakra-ui/react";
import { Card, CardHeader, CardBody } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { getPropertyAddress } from "../../utils/verifier";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { getRequestPeriodBadge } from "../../utils/utils";
import Loader from "../../Components/Loader/Loader";
import SearchBox from "../Properties/SearchBox";
import { selectActiveProperties } from "../../redux/user/userSlice";
import { useTranslation } from "react-i18next";

export default function Utilities() {
	const { t } = useTranslation();
	const textColor = useColorModeValue("gray.700", "white");
	const properties = useSelector(selectActiveProperties);
	const [query, setquery] = useState("");
	const [date, setDate] = useState([moment().year(), moment().month()]);
	const navigate = useNavigate();
	const [loading, setLoading] = useState(true);
	const goToProperty = (id) => {
		navigate(`/properties/view/${id}?tab=utilities`);
	};
	const handleSearch = (query) => {
		setquery(query);
	};
	useEffect(() => {
		setLoading(false);
	}, []);
	const hoverColor = useColorModeValue("gray.100", "gray.700");
	return (
		<>
			<Stack direction="column" spacing={2}>
				{/* <Flex direction="column" pt={{ base: "120px", md: "75px" }}> */}
				<Card overflowX={{ sm: "scroll", xl: "hidden" }}>
					<CardHeader fontSize="xl" color={textColor} fontWeight="bold">
						<Flex alignItems="center" justifyContent={"space-between"}>
							<Stack direction={"row"} alignItems="center">
								<Text>{t("utilities.utilities")}</Text>
								<Box>
									<SearchBox
										handleSearch={handleSearch}
										searchOptions={[
											{
												label: "Address",
												description: "Search by address",
											},
											{
												label: "Owner",
												description: "Search by owner name",
											},
											{
												label: "Tenant",
												description: "Search by tenant name",
											},
											{
												label: "Contact",
												description: "Search by contact name",
											},
										]}
									/>
								</Box>
							</Stack>
							<Stack>
								<Text fontSize={"sm"}>{t("misc.legend")}:</Text>
								<Badge colorScheme="green">Factura incarcata si platita</Badge>
								<Badge colorScheme="yellow">Chiriasul trebuie sa plateasaca</Badge>
								<Badge colorScheme="red">Trebuie emisa factura</Badge>
							</Stack>
						</Flex>
					</CardHeader>
					<CardBody>
						{loading ? (
							<Loader text="Loading Utilities..." />
						) : (
							<>
								<Flex direction="row" justifyContent={"space-around"}>
									<Button
										onClick={() => {
											const newDate = moment(date).subtract(1, "months");
											console.log(newDate.format());
											setDate([newDate.year(), newDate.month()]);
										}}
									>
										{t("table.previousMonth")}
									</Button>
									<Text fontWeight={"bold"}>{moment(date).format("MMM YYYY")}</Text>
									<Button
										isDisabled={moment().isSame(moment(date), "year") && moment().isSame(moment(date), "month")}
										onClick={() => {
											const newDate = moment(date).add(1, "months");
											console.log(newDate.format());
											setDate([newDate.year(), newDate.month()]);
										}}
									>
										{t("table.nextMonth")}
									</Button>
								</Flex>
								<TableContainer overflowX="auto">
									<Table variant="simple" size="sm">
										<Thead>
											<Tr my=".8rem" pl="0px">
												<Th>{t("table.name")}</Th>
												<Th pl="0px">{t("table.address")}</Th>
												<Th>{t("utilities.gas")}</Th>
												<Th>{t("utilities.electricity")}</Th>
												<Th>{t("utilities.water")}</Th>
												<Th>{t("utilities.sanitation")}</Th>
												<Th>{t("utilities.internet")}</Th>
											</Tr>
										</Thead>
										<Tbody>
											{properties
												?.filter((p) => {
													const address = getPropertyAddress(p);

													return (
														address.toLowerCase().includes(query.toLowerCase()) ||
														p.owner.name.toLowerCase().includes(query.toLowerCase()) ||
														p.tenants.find((t) => t.name.toLowerCase().includes(query.toLowerCase())) ||
														p.contacts.find((t) => t.name.toLowerCase().includes(query.toLowerCase()))
													);
												})
												.sort((a, b) => {
													//sort the properties based on if we are currently in the request period interval

													const aRequestPeriodWater =
														moment().date() >= a.utilities.water.requestPeriod.firstDay && moment().date() <= a.utilities.water.requestPeriod.lastDay;
													const bRequestPeriodWater =
														moment().date() >= b.utilities.water.requestPeriod.firstDay && moment().date() <= b.utilities.water.requestPeriod.lastDay;
													const aRequestPeriodGas =
														moment().date() >= a.utilities.gas.requestPeriod.firstDay && moment().date() <= a.utilities.gas.requestPeriod.lastDay;
													const bRequestPeriodGas =
														moment().date() >= b.utilities.gas.requestPeriod.firstDay && moment().date() <= b.utilities.gas.requestPeriod.lastDay;
													const aRequestPeriodElectricity =
														moment().date() >= a.utilities.electricity.requestPeriod.firstDay &&
														moment().date() <= a.utilities.electricity.requestPeriod.lastDay;
													const bRequestPeriodElectricity =
														moment().date() >= b.utilities.electricity.requestPeriod.firstDay &&
														moment().date() <= b.utilities.electricity.requestPeriod.lastDay;

													const aRequestPeriod = aRequestPeriodWater || aRequestPeriodGas || aRequestPeriodElectricity;
													const bRequestPeriod = bRequestPeriodWater || bRequestPeriodGas || bRequestPeriodElectricity;
													if (aRequestPeriod && !bRequestPeriod) return -1;
													if (!aRequestPeriod && bRequestPeriod) return 1;
													return 0;
												})
												.map((property, index) => {
													const payments = property.payments;
													const getLastPaymentOfType = (type) =>
														payments.find(
															(p) =>
																p.type.toLowerCase().includes(type) &&
																moment(p.issuedDate).isSame(moment(date), "year") &&
																moment(p.issuedDate).isSame(moment(date), "month")
														);

													const getUtilityStatus = (payment, type) => {
														if (payment === undefined)
															return property.utilities.availableUtilities[type] ? <Badge colorScheme="red">Neemis</Badge> : <Badge>N/a</Badge>;
														if (payment.paymentDate)
															return (
																<Badge colorScheme="green">
																	{payment.amount} {payment.currency}
																</Badge>
															);
														else
															return (
																<Badge colorScheme="yellow">
																	{payment.amount} {payment.currency}
																</Badge>
															);
													};
													return (
														<Tr
															whiteSpace={"nowrap"}
															key={index}
															onClick={() => {
																goToProperty(property.id);
															}}
															cursor={"pointer"}
															_hover={{ background: hoverColor }}
														>
															<Td>{property.owner.name}</Td>
															<Td>{getPropertyAddress(property)}</Td>
															<Td>
																{getUtilityStatus(getLastPaymentOfType("gas"), "gas")}
																{moment().isSame(date, "month") && <> | {getRequestPeriodBadge(property, "gas", date)}</>}
															</Td>
															<Td>
																{getUtilityStatus(getLastPaymentOfType("electricity"), "electricity")}
																{moment().isSame(date, "month") && <> | {getRequestPeriodBadge(property, "electricity", date)}</>}
															</Td>
															<Td>
																{getUtilityStatus(getLastPaymentOfType("water"), "water")}
																{moment().isSame(date, "month") && <> | {getRequestPeriodBadge(property, "water", date)}</>}
															</Td>
															<Td>{getUtilityStatus(getLastPaymentOfType("sanitation"), "sanitation")} </Td>
															<Td>{getUtilityStatus(getLastPaymentOfType("internet"), "internet")} </Td>
														</Tr>
													);
												})}
										</Tbody>
									</Table>
								</TableContainer>
							</>
						)}
					</CardBody>
				</Card>
			</Stack>
		</>
	);
}
