import { DownloadIcon, HamburgerIcon } from "@chakra-ui/icons";
import { Button, Flex, Icon, Text, Badge, Tr, Td, UnorderedList, ListItem, useDisclosure, useToast } from "@chakra-ui/react";
import React from "react";
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton } from "@chakra-ui/react";
import { Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import moment from "moment/moment";
import { API } from "../../utils/http";
import { useDispatch } from "react-redux";
import { removeFinancialStatementAsync, updateFinancialStatementActiveAsync } from "../../redux/user/userSlice";
import { fullDateTimeFormat } from "../../utils/types";
import { useTranslation } from "react-i18next";
function FinancialStatementRow(props) {
	const { date, active, year, type, description, name, documents, property, id } = props;
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { isOpen: isDeleteConfirmationOpen, onOpen: onDeleteConfirmationOpen, onClose: onDeleteConfirmationClose } = useDisclosure();
	const toast = useToast();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const markAsActive = (financialStatementId) => {
		const payload = { financialStatementId, propertyId: property.id, active: true };
		console.log("mark financial statement as active: ", payload);
		dispatch(updateFinancialStatementActiveAsync(payload));
	};
	const markAsInactive = (financialStatementId) => {
		const payload = { financialStatementId, propertyId: property.id, active: false };
		console.log("mark financial statement as active: ", payload);
		dispatch(updateFinancialStatementActiveAsync(payload));
	};
	const handleGetDocument = (documentId, documentName) => {
		const payload = { propertyId: property.id, documentId, financialStatementId: id, documentName };
		console.log(payload);
		API.put("get-financial-statement-document", payload)
			.then((r) => {
				console.log(r);
				window.open(r.data.url, "_blank");
			})
			.catch((e) => {
				console.log(e);
				toast({
					title: "Error encountered",
					description: e.response.data.error.message,
					status: "error",
					duration: 9000,
					isClosable: true,
				});
			});
	};
	const handleDeletePayment = () => {
		const payload = { propertyId: property.id, financialStatementId: id };
		console.log(payload);
		//dispatch remove payment
		onDeleteConfirmationClose();
		dispatch(removeFinancialStatementAsync(payload));
	};

	const renderDeleteConfirmationModal = () => {
		return (
			<Modal isOpen={isDeleteConfirmationOpen} onClose={onDeleteConfirmationClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Confirm delete?</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Text>Are you sure you want to delete this payment? </Text>
						<Text>
							This action{" "}
							<Text as="span" fontWeight={"bold"}>
								cannot
							</Text>{" "}
							be undone!{" "}
						</Text>
					</ModalBody>

					<ModalFooter>
						<Button colorScheme="red" mr={3} onClick={handleDeletePayment}>
							Delete
						</Button>
						<Button colorScheme="blue" mr={3} onClick={onDeleteConfirmationClose}>
							Close
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		);
	};
	const renderViewDocumentsModal = () => {
		return (
			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Documents</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<UnorderedList>
							{documents?.map((d, index) => (
								<ListItem key={index}>
									<Text
										color="teal.500"
										textDecorationLine={"underline"}
										cursor={"pointer"}
										onClick={() => {
											handleGetDocument(d.id, d.documentName);
										}}
									>
										{d.documentName}
										<DownloadIcon marginLeft={2} />
									</Text>
								</ListItem>
							))}
						</UnorderedList>
					</ModalBody>

					<ModalFooter>
						<Button colorScheme="blue" mr={3} onClick={onClose}>
							Close
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		);
	};
	return (
		<Tr>
			<Td minWidth={{ sm: "150px" }} pl="0px">
				<Flex align="center" minWidth="100%" flexWrap="nowrap">
					{/* <Avatar src={logo} w="50px" borderRadius="12px" me="18px" /> */}
					<Flex direction="column">
						<Text fontSize="md" minWidth="100%">
							{moment(date).format(fullDateTimeFormat)}
						</Text>
						<Badge colorScheme="green" width={"fit-content"} hidden={!active}>
							Active
						</Badge>
					</Flex>
				</Flex>
			</Td>
			<Td>
				<Text fontSize="md" pb=".5rem">
					{name}
				</Text>
			</Td>
			<Td>
				<Text fontSize="md" pb=".5rem">
					{year}
				</Text>
			</Td>
			<Td>
				<Text fontSize="md" pb=".5rem">
					{t(`table.${type}`)}
				</Text>
			</Td>
			<Td>
				<Text fontSize="md" pb=".5rem">
					{description}
				</Text>
			</Td>

			<Td>
				<Menu closeOnSelect={false}>
					<MenuButton>
						<Icon as={HamburgerIcon} />
					</MenuButton>
					<MenuList>
						{active ? (
							<MenuItem
								onClick={() => {
									markAsInactive(id);
								}}
							>
								Mark as inactive
							</MenuItem>
						) : (
							<MenuItem
								onClick={() => {
									markAsActive(id);
								}}
							>
								Mark as Active
							</MenuItem>
						)}
						<MenuItem onClick={onOpen}>View Documents</MenuItem>
						<MenuItem onClick={onDeleteConfirmationOpen}>Delete</MenuItem>
					</MenuList>
				</Menu>
				{renderViewDocumentsModal()}
				{renderDeleteConfirmationModal()}
			</Td>
		</Tr>
	);
}

export default FinancialStatementRow;
