import {
	Button,
	FormControl,
	FormLabel,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Stack,
	Table,
	Tbody,
	Td,
	Text,
	Textarea,
	Th,
	Thead,
	Tr,
	useDisclosure,
	useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TicketsTableRow from "../../Components/TableRows/TicketsTableRow";
import { getPropertyAddress } from "../../utils/verifier";
import { _arrayBufferToBase64 } from "../../utils/convertors";
import { agencyAddTicketAsync } from "../../redux/user/userSlice";
import Dropzone from "../Dropzone/Dropzone";
import { useTranslation } from "react-i18next";
export default function TicketsTab({ property }) {
	const { t } = useTranslation();
	const isArchived = property.archived;
	const tickets = property.tickets.map((t) => {
		return { ...t, propertyId: property.id, address: getPropertyAddress(property) };
	});
	const user = useSelector((state) => state.user?.user);
	const author = { email: user.email, name: user.name, phoneNumber: user.phoneNumber };
	const initialAddTicketInformation = { title: "", description: "", media: [] };
	const [addTicketInformation, setAddTicketInformation] = useState(initialAddTicketInformation);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const toast = useToast();
	const dispatch = useDispatch();
	const handleChange = (e) => {
		setAddTicketInformation({ ...addTicketInformation, [e.target.name]: e.target.value });
	};
	const addTicket = () => {
		const payload = { ticket: addTicketInformation, propertyId: property.id, author };
		console.log(payload);
		dispatch(agencyAddTicketAsync(payload))
			.then((r) => {
				if (r.error?.message)
					toast({
						title: "Error encountered",
						description: r.error.message,
						status: "error",
						duration: 9000,
						isClosable: true,
					});
				else {
					toast({
						title: "Success!",
						description: "Document Added!",
						status: "success",
						duration: 2000,
						isClosable: true,
					});
				}
			})
			.finally(() => {
				setAddTicketInformation(initialAddTicketInformation);
				onClose();
			});
	};
	const onDocumentDrop = async (acceptedFiles) => {
		console.log(acceptedFiles);
		let documents = [];
		for (const file of acceptedFiles) {
			const buffer = await file.arrayBuffer();
			const documentName = file.name;
			const contentType = file.type;
			documents.push({ documentData: _arrayBufferToBase64(buffer), documentName, contentType });
		}
		setAddTicketInformation({ ...addTicketInformation, media: documents });
	};
	const renderAddTicketModal = () => {
		return (
			<Modal isOpen={isOpen} onClose={onClose} size={"xl"}>
				<ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
				<ModalContent>
					<ModalHeader>
						<Text>{t("property.addTicket")}</Text>
					</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Stack>
							<FormControl>
								<FormLabel display={"inline"}>Title:</FormLabel>
								<Input width={"fit-content"} onChange={handleChange} type="text" name="title" placeholder="Ttile" _placeholder={{ color: "gray.500" }} />
							</FormControl>
							<FormControl>
								<FormLabel display={"inline"}>Description:</FormLabel>
								<Textarea onChange={handleChange} type="number" name="description" placeholder="Description" _placeholder={{ color: "gray.500" }} />
							</FormControl>

							<FormControl>
								<FormLabel>Media:</FormLabel>
								<Dropzone onDrop={onDocumentDrop} accept={{ "image/*": [], "video/*": [] }} description="Upload an Image or a Video" />
							</FormControl>
						</Stack>
					</ModalBody>
					<ModalFooter>
						<Button
							colorScheme="red"
							onClick={() => {
								onClose();
								setAddTicketInformation(initialAddTicketInformation);
							}}
							mr={3}
						>
							{t("misc.cancel")}
						</Button>
						<Button colorScheme="blue" onClick={addTicket}>
							{t("misc.save")}
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		);
	};
	return (
		<Stack>
			<Stack direction="row">
				<Button colorScheme="blue" onClick={onOpen} isDisabled={isArchived}>
					{t("property.addTicket")}
				</Button>
				{renderAddTicketModal()}
			</Stack>
			<Table variant="simple" size="sm">
				<Thead>
					<Tr my=".8rem" pl="0px">
						<Th>{t("table.addedDate")}</Th>
						<Th>{t("table.owner")}</Th>
						<Th pl="0px">{t("table.address")}</Th>
						<Th>{t("table.status")}</Th>
						<Th>{t("table.title")}</Th>
						<Th>{t("table.author")}</Th>
						<Th></Th>
					</Tr>
				</Thead>
				<Tbody>
					{tickets?.map((t, index) => (
						<TicketsTableRow key={index} row={t} author={author} owner={property.owner} />
					))}
					{tickets?.length === 0 && (
						<Tr>
							<Td colSpan={7}>{t("table.noData")}</Td>
						</Tr>
					)}
				</Tbody>
			</Table>
		</Stack>
	);
}
