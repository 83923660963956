/* eslint-disable react-hooks/exhaustive-deps */
import {
	Button,
	Flex,
	Modal,
	ModalOverlay,
	ModalHeader,
	ModalContent,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	Td,
	Text,
	Tr,
	Box,
	Image,
	Tooltip,
	Icon,
	WrapItem,
	Wrap,
} from "@chakra-ui/react";
import { Stack } from "@chakra-ui/react";
import { DownloadIcon, ViewIcon } from "@chakra-ui/icons";
import React, { useCallback, useEffect, useState } from "react";
import { useDisclosure } from "@chakra-ui/react";

import ImageViewer from "react-simple-image-viewer";
import moment from "moment";
import { API } from "../../../utils/http";
import { fullDateTimeFormat } from "../../../utils/types";
import { useTranslation } from "react-i18next";
function ReportsTableRow(props) {
	const { row, propertyId } = props;
	const { t } = useTranslation();
	const handleGetDocument = () => {
		const payload = { propertyId, reportId: row.id, reportName: row.name };
		console.log(payload);
		API.put("get-report", payload).then((r) => {
			console.log(r);
			window.open(r.data.url, "_blank");
		});
	};
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [currentImage, setCurrentImage] = useState(0);
	const [isViewerOpen, setIsViewerOpen] = useState(false);
	const [images, setImages] = useState([]);
	const openImageViewer = useCallback((index) => {
		setCurrentImage(index);
		setIsViewerOpen(true);
	}, []);
	const closeImageViewer = () => {
		setCurrentImage(0);
		setIsViewerOpen(false);
	};
	useEffect(() => {
		console.log("Getting report media urls");
		const payload = { propertyId, media: row.media, reportId: row.id };
		API.put("get-report-picture-urls", payload).then((r) => {
			setImages(r.data.media);
		});
	}, [row.media]);
	const renderViewModal = () => {
		return (
			<Modal isOpen={isOpen} onClose={onClose} size="3xl">
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Documents</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Wrap>
							{images.map((i, index) => (
								<WrapItem key={index}>
									<Box position="relative" key={index}>
										<Image
											src={i.url}
											onClick={() => openImageViewer(index)}
											height={"200px"}
											style={{ margin: "2px" }}
											alt="meter index picture"
											cursor={"pointer"}
											className="ticketDocument"
										/>
										<Text className="ticketDocumentDownloadButton" style={{ position: "absolute", top: "5%", left: "5%", display: "none" }} fontWeight={"bold"}>
											{moment(i.addedDate).format(fullDateTimeFormat)}
										</Text>
									</Box>
								</WrapItem>
							))}
						</Wrap>
						{isViewerOpen && (
							<ImageViewer
								src={images.map((i) => i.url)}
								currentIndex={currentImage}
								onClose={closeImageViewer}
								disableScroll={false}
								backgroundStyle={{
									backgroundColor: "rgba(0,0,0,0.9)",
								}}
								closeOnClickOutside={true}
							/>
						)}
						<Stack direction={"row"}>
							<Text fontWeight={"bold"}>Report: </Text>
							<Text
								color="teal.500"
								textDecorationLine={"underline"}
								cursor={"pointer"}
								onClick={() => {
									handleGetDocument(row.id);
								}}
								width={"fit-content"}
							>
								{row.name}
								<DownloadIcon marginLeft={2} />
							</Text>
						</Stack>
					</ModalBody>

					<ModalFooter>
						<Button colorScheme="blue" mr={3} onClick={onClose}>
							Close
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		);
	};
	return (
		<Tr>
			<Td minWidth={{ sm: "250px" }} pl="0px">
				<Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
					<Text fontSize="md" minWidth="100%">
						{moment(row.addedDate).format(fullDateTimeFormat)}
					</Text>
				</Flex>
			</Td>
			<Td>
				<Text fontSize="md" pb=".5rem">
					{row.name}
				</Text>
			</Td>
			<Td>
				<Text fontSize="md" pb=".5rem">
					{t(`types.${row.type}`) || "-"}
				</Text>
			</Td>
			<Td>
				<Text fontSize="md" pb=".5rem">
					{row.number}
				</Text>
			</Td>

			<Td>
				<Stack direction="row">
					<Button onClick={onOpen}>
						<Tooltip label="View">
							<Icon as={ViewIcon} color={"teal.400"} boxSize={6}></Icon>
						</Tooltip>
					</Button>
					{renderViewModal()}
				</Stack>
			</Td>
		</Tr>
	);
}

export default ReportsTableRow;
