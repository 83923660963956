import {
	Button,
	Flex,
	Modal,
	ModalOverlay,
	ModalHeader,
	ModalContent,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	Td,
	Text,
	Tr,
	useColorModeValue,
	Icon,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import React, { useState } from "react";
import { useDisclosure } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { removeDocumentAsync, updateDocumentActiveAsync } from "../../redux/user/userSlice";
import { API } from "../../utils/http";
import moment from "moment";
import { getDocumentBadge } from "../../utils/utils";
import { fullDateFormat, fullDateTimeFormat } from "../../utils/types";
import { useTranslation } from "react-i18next";
export default function LeaseTableRow(props) {
	const { row, propertyId, payments } = props;
	const [rentAmount, setRentAmount] = useState({ ron: 0, eur: 0 });
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const textColor = useColorModeValue("gray.700", "white");
	const deleteDocument = (documentId) => {
		console.log("delete: ", { documentId, propertyId });
		dispatch(removeDocumentAsync({ documentId, propertyId }));
	};
	const getRentAmount = (start, end) => {
		console.log(`getting amount from ${start} to ${end}`);

		const rentPayments = payments.filter(
			(p) => p.type === "rent" && moment(p.issuedDate).unix() > moment(start).unix() && moment(p.issuedDate).unix() < moment(end).unix()
		);
		console.log(rentPayments);
		const amountInRon = rentPayments.filter((p) => p.currency === "ron").reduce((accumulator, currentValue) => accumulator + Number(currentValue.amount), 0);
		const amountInEur = rentPayments.filter((p) => p.currency === "eur").reduce((accumulator, currentValue) => accumulator + Number(currentValue.amount), 0);
		console.log(amountInRon);
		console.log(amountInEur);
		setRentAmount({ eur: amountInEur, ron: amountInRon });
		onOpen();
	};
	const getDocument = (documentId, documentName) => {
		const payload = { documentId, propertyId, documentName };
		console.log("view: ", payload);
		API.put("get-document", payload).then((r) => {
			console.log(r);
			window.open(r.data.url, "_blank");
		});
	};
	const markAsActive = (documentId) => {
		const payload = { documentId, propertyId, type: row.type, active: true };
		console.log("mark document as active: ", payload);
		dispatch(updateDocumentActiveAsync(payload));
	};
	const markAsInactive = (documentId) => {
		const payload = { documentId, propertyId, type: row.type, active: false };
		console.log("mark document as active: ", payload);
		dispatch(updateDocumentActiveAsync(payload));
	};
	const renderRentAmountModal = () => (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>
					{row.startingDate} - {row.expirationDate}
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Text>
						Aceasta proprietate a generat <Text as="span">{rentAmount.eur} EUR</Text> si <Text as="span">{rentAmount.ron} RON</Text>
					</Text>
				</ModalBody>

				<ModalFooter>
					<Button
						colorScheme="blue"
						mr={3}
						onClick={() => {
							onClose();
							setRentAmount({ eur: 0, ron: 0 });
						}}
					>
						Close
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
	return (
		<Tr>
			<Td minWidth={{ sm: "200px" }}>
				<Flex direction={"column"}>
					<Text fontSize="md" color={textColor}>
						{moment(row.addedDate).format(fullDateTimeFormat)}
					</Text>
					{getDocumentBadge(row)}
				</Flex>
			</Td>
			<Td>
				<Text fontSize="md" color={textColor} pb=".5rem">
					{row.name}
				</Text>
			</Td>
			<Td>
				<Text fontSize="md" color={textColor} pb=".5rem">
					{t(`types.${row.type}`)}
				</Text>
			</Td>
			<Td>
				<Text fontSize="md" color={textColor} pb=".5rem">
					{row.number}
				</Text>
			</Td>
			<Td>
				<Text fontSize="md" color={textColor} pb=".5rem" whiteSpace={"nowrap"}>
					{row.signatureDate ? moment(row.signatureDate).format(fullDateFormat) : "-"}
				</Text>
			</Td>
			<Td>
				<Text fontSize="md" color={textColor} pb=".5rem" whiteSpace={"nowrap"}>
					{row.startingDate ? moment(row.startingDate).format(fullDateFormat) : "-"}
				</Text>
			</Td>
			<Td>
				<Text fontSize="md" color={textColor} pb=".5rem" whiteSpace={"nowrap"}>
					{row.expirationDate ? moment(row.expirationDate).format(fullDateFormat) : "-"}
				</Text>
			</Td>
			<Td>
				<Text fontSize="md" pb=".5rem">
					{row.description || "-"}
				</Text>
			</Td>
			<Td>
				<Menu closeOnSelect={false}>
					<MenuButton>
						<Icon as={HamburgerIcon} />
					</MenuButton>
					<MenuList>
						<MenuItem
							onClick={() => {
								getDocument(row.id, row.name);
							}}
						>
							Download
						</MenuItem>
						{row.active ? (
							<MenuItem
								onClick={() => {
									markAsInactive(row.id);
								}}
							>
								Mark as inactive
							</MenuItem>
						) : (
							<MenuItem
								onClick={() => {
									markAsActive(row.id);
								}}
							>
								Mark as Active
							</MenuItem>
						)}

						<MenuItem
							onClick={() => {
								deleteDocument(row.id);
							}}
						>
							Delete
						</MenuItem>
						<MenuItem
							onClick={() => {
								getRentAmount(row.startingDate, row.expirationDate);
							}}
						>
							Get Rent Amount
						</MenuItem>
					</MenuList>
				</Menu>
				{renderRentAmountModal()}
			</Td>
		</Tr>
	);
}
