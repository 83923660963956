import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setHeaders } from "../../utils/http";
import { AWSAuth } from "../../utils/auth";
const initialState = {
	currentUserEmail: null,
	idToken: null,
	authorization: null,
	status: "idle",
	verifyEmail: false,
};
// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const signInAsync = createAsyncThunk("auth/signIn", async ({ email, password }) => {
	// const response = await signInWithEmailAndPassword(auth, email, password);
	const response = await AWSAuth.signIn(email, password);
	console.log(response);
	const r = {
		email: response.attributes.email,
		idToken: response.signInUserSession.idToken.jwtToken,
		authorization: response.signInUserSession.accessToken.jwtToken,
	};
	// The value we return becomes the `fulfilled` action payload
	return r;
});
export const confirmSignUpAsync = createAsyncThunk("auth/confirmSignUp", async ({ email, code }) => {
	// const response = await signInWithEmailAndPassword(auth, email, password);

	const response = await AWSAuth.confirmSignUp(email, code);
	console.log(response);

	return response;
});

export const signUpAsync = createAsyncThunk("auth/signUp", async ({ email, password }) => {
	// const response = await signInWithEmailAndPassword(auth, email, password);
	const { user } = await AWSAuth.signUp({
		username: email,
		password,
		autoSignIn: {
			// optional - enables auto sign in after user is confirmed
			enabled: true,
		},
	});
	//create entry in database
	console.log(user);
	// The value we return becomes the `fulfilled` action payload
	return user.username;
});
export const signOutAsync = createAsyncThunk("auth/signOut", async () => {
	// const response = await signOutApi();
	try {
		await AWSAuth.signOut();
	} catch (error) {
		console.log("error signing out: ", error);
	}
	// return response;
});
const handleLogin = (state, action) => {
	console.log("set data");
	state.currentUserEmail = action.payload.email;
	state.authorization = action.payload.authorization;
	state.idToken = action.payload.idToken;
	localStorage.setItem("authorization", action.payload.authorization);
	localStorage.setItem("idtoken", action.payload.idToken);
	setHeaders({ Authorization: action.payload.authorization, idToken: action.payload.idToken });
};
const handleLogout = (state) => {
	console.log("set data");
	state.currentUserEmail = null;
	state.authorization = null;
	state.idToken = null;
	localStorage.removeItem("authorization");
};
export const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		signIn: (state, action) => {
			// state.currentUserEmail = action.payload.email;
			// state.authorization = action.payload.authorization;
			// state.idToken = action.payload.idToken;
			handleLogin(state, action);
		},
		logout: (state) => {
			handleLogout(state);
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(signInAsync.pending, (state) => {
				state.status = "loading";
			})
			.addCase(signInAsync.fulfilled, (state, action) => {
				state.status = "idle";
				handleLogin(state, action);
				console.log("login2");
			})
			.addCase(signInAsync.rejected, (state, action) => {
				state.status = "idle";
				console.error(action.error);
			})
			.addCase(signOutAsync.pending, (state) => {
				state.status = "loading";
				console.log("signout pending");
			})
			.addCase(signOutAsync.fulfilled, (state) => {
				state.status = "idle";
				handleLogout(state);
				console.log("signout fulfilled");
			})
			.addCase(signUpAsync.pending, (state) => {
				state.status = "loading";
				console.log("Signup pending");
			})
			.addCase(signUpAsync.fulfilled, (state, action) => {
				state.status = "idle";
				state.verifyEmail = true;
				console.log("Signup fulfilled, waiting verification");
			})
			.addCase(signUpAsync.rejected, (state, action) => {
				state.status = "idle";
				state.verifyEmail = false;
				console.error(action.error.message);
			})
			.addCase(confirmSignUpAsync.pending, (state) => {
				state.status = "loading";
				console.log("Confirm signup pending");
			})
			.addCase(confirmSignUpAsync.fulfilled, (state, action) => {
				state.status = "idle";
				state.verifyEmail = false;
				handleLogin(state, action);
				console.log("Confirm signup fulfilled");
			})
			.addCase(confirmSignUpAsync.rejected, (state, action) => {
				state.status = "idle";
				state.verifyEmail = true;
				console.error(action.error.message);
			});
	},
});

export const { signIn, logout } = authSlice.actions;

// // We can also write thunks by hand, which may contain both sync and async logic.
// // Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd = (amount) => (dispatch, getState) => {
// 	const currentValue = selectCount(getState());
// 	if (currentValue % 2 === 1) {
// 		dispatch(incrementByAmount(amount));
// 	}
// };

export default authSlice.reducer;
