import {
	Avatar,
	Badge,
	Button,
	Flex,
	Modal,
	ModalOverlay,
	ModalHeader,
	ModalContent,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	Td,
	Text,
	Tr,
	useColorModeValue,
	List,
	ListItem,
	ListIcon,
	OrderedList,
	UnorderedList,
	Box,
	Image,
	Tooltip,
	Icon,
	Textarea,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
} from "@chakra-ui/react";
import { MdCheckCircle } from "react-icons/md";
import { IoMdCloseCircle } from "react-icons/io";
import { FormControl, FormLabel, Heading, Input, Stack, AvatarBadge, IconButton, Center } from "@chakra-ui/react";
import { DownloadIcon, DeleteIcon, HamburgerIcon } from "@chakra-ui/icons";
import React, { useState } from "react";
import { useDisclosure } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { removeDocumentAsync, removeTenantDocumentAsync, updateDocumentActiveAsync } from "../../redux/user/userSlice";
import { API } from "../../utils/http";
import moment from "moment";
function TenantsDocumentsTableRow(props) {
	const { row, tenantEmail } = props;
	const dispatch = useDispatch();
	const textColor = useColorModeValue("gray.700", "white");
	const deleteDocument = (documentId) => {
		const payload = { documentId, tenantEmail };
		// console.log("delete: ", { documentId, propertyId });
		dispatch(removeTenantDocumentAsync(payload));
	};
	const getDocument = (documentId, documentName) => {
		const payload = { documentId, tenantEmail, documentName };
		console.log("view: ", payload);
		API.put("get-tenant-document-url", payload).then((r) => {
			console.log(r);
			window.open(r.data.url, "_blank");
		});
	};

	return (
		<Tr>
			<Td minWidth={{ sm: "200px" }}>
				<Flex py=".8rem" direction={"column"}>
					<Text fontSize="md" color={textColor} fontWeight="bold">
						{moment(row.addedDate).format("DD MMM YYYY HH:mm")}
					</Text>
				</Flex>
			</Td>
			<Td>
				<Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
					{row.name}
				</Text>
			</Td>
			<Td>
				<Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
					{row.type || "-"}
				</Text>
			</Td>

			<Td>
				<Menu closeOnSelect={false}>
					<MenuButton>
						<Icon as={HamburgerIcon} />
					</MenuButton>
					<MenuList>
						<MenuItem
							onClick={() => {
								getDocument(row.id, row.name);
							}}
						>
							Download
						</MenuItem>
						<MenuItem
							onClick={() => {
								deleteDocument(row.id);
							}}
						>
							Delete
						</MenuItem>
					</MenuList>
				</Menu>
				{/* <Stack direction="row">
					<Button
						onClick={() => {
							getDocument(row.id);
						}}
					>
						<Tooltip label="Download">
							<Icon as={DownloadIcon} color={"teal.400"} boxSize={6}></Icon>
						</Tooltip>
					</Button>
					<Button
						colorScheme={"red"}
						onClick={() => {
							deleteDocument(row.id);
						}}
					>
						<Tooltip label="Download">
							<Icon as={DeleteIcon} boxSize={6}></Icon>
						</Tooltip>
					</Button>
				</Stack> */}
			</Td>
		</Tr>
	);
}

export default TenantsDocumentsTableRow;
