import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { en } from "./translations/en";
import { ro } from "./translations/ro";

i18n

	// pass the i18n instance to react-i18next.
	.use(initReactI18next)
	// init i18next
	// for all options read: https://www.i18next.com/overview/configuration-options
	.init({
		debug: true,
		fallbackLng: "ro",
		resources: {
			en: {
				translation: en,
			},
			ro: {
				translation: ro,
			},
		},
	});

export default i18n;
