import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	Grid,
	useColorModeValue,
	Table,
	Tbody,
	Text,
	Th,
	Thead,
	Tr,
	Box,
	Checkbox,
	useToast,
	Button,
	Stack,
	Divider,
	Wrap,
	WrapItem,
	Td,
} from "@chakra-ui/react";

import { Card, CardHeader, CardBody } from "@chakra-ui/react";

import { getPropertyAddress } from "../../utils/verifier";

import TicketsTableRow from "./Tickets/TicketsTableRow";
import { selectActiveProperties, updatePropertyCustomOwnerPlanAsync } from "../../redux/user/userSlice";
import PaymentsRow from "./Payments/PaymentsRow";
import { useTranslation } from "react-i18next";
export default function Dashboard() {
	const textColor = useColorModeValue("gray.700", "white");
	const properties = useSelector(selectActiveProperties);
	const customOwnerPlansAvailable = useSelector((state) => state.user.user?.customOwnerPlans);
	const tickets = properties
		.map((p) =>
			p.tickets.map((t) => {
				return { ...t, address: getPropertyAddress(p), propertyId: p.id };
			})
		)
		.flat();
	const outstandingPayments = properties
		.map((property) => {
			const address = getPropertyAddress(property);
			return property.payments
				.filter((payment) => !payment.paymentDate)
				.map((payment) => {
					return { ...payment, address, propertyId: property.id };
				});
		})
		.flat();
	const email = useSelector((state) => state.user.user?.email);
	const phoneNumber = useSelector((state) => state.user.user?.phoneNumber);
	const name = useSelector((state) => state.user.user?.name);
	const { t } = useTranslation();
	return (
		<React.Fragment>
			<Grid marginBottom={4} gap={2}>
				<Card overflowX={{ sm: "scroll", xl: "hidden" }}>
					<CardHeader>
						<Text fontSize="xl" color={textColor} fontWeight="bold">
							{t("navbarMenu.outstanding")}
						</Text>
					</CardHeader>
					<CardBody>
						<Table variant="simple" size="sm">
							<Thead>
								<Tr my=".8rem" pl="0px">
									<Th pl="0px">{t("table.issuedDate")}</Th>
									<Th>{t("table.status")}</Th>
									<Th>{t("table.dueDate")}</Th>
									<Th>{t("table.amount")}</Th>
									<Th>{t("table.method")}</Th>
									<Th>{t("table.type")}</Th>
									<Th>{t("table.paidDate")}</Th>
									<Th></Th>
								</Tr>
							</Thead>
							<Tbody>
								{outstandingPayments?.map((row, index) => (
									<PaymentsRow
										id={row.id}
										key={index}
										date={row.issuedDate}
										address={row.address}
										paymentDate={row.paymentDate}
										code={row.number}
										amount={row.amount}
										type={row.type}
										status={row.paymentDate ? "paid" : "due"}
										method={row.paymentMethod}
										documents={row.documents}
										paymentDirection={row.paymentDirection}
										currency={row.currency}
										propertyId={row.propertyId}
									/>
								))}
								{outstandingPayments.length === 0 && (
									<Tr>
										<Th colSpan="4" textAlign="center">
											No outstanding payments
										</Th>
									</Tr>
								)}
							</Tbody>
						</Table>
					</CardBody>
				</Card>
				<Card overflowX={{ sm: "scroll", xl: "hidden" }}>
					<CardHeader>
						<Text fontSize="xl" color={textColor} fontWeight="bold">
							{t("navbarMenu.tickets")}
						</Text>
					</CardHeader>
					<CardBody>
						<Table variant="simple" size="sm">
							<Thead>
								<Tr my=".8rem" pl="0px">
									<Th>Added date</Th>
									<Th>Status</Th>
									<Th>Title</Th>
									<Th>Author</Th>
									<Th></Th>
								</Tr>
							</Thead>
							<Tbody>
								{tickets?.map((t, index) => (
									<TicketsTableRow key={index} row={t} author={{ email, phoneNumber, name }} propertyId={t.propertyId} />
								))}
								{tickets.length === 0 && (
									<Tr>
										<Th colSpan="4" textAlign="center">
											No tickets
										</Th>
									</Tr>
								)}
							</Tbody>
						</Table>
					</CardBody>
				</Card>
			</Grid>
		</React.Fragment>
	);
}
