import React, { useState, useEffect } from "react";
import {
	Box,
	Button,
	Text,
	Divider,
	Input,
	Stack,
	FormControl,
	FormLabel,
	Select,
	useDisclosure,
	Modal,
	ModalOverlay,
	ModalHeader,
	ModalContent,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	Wrap,
	WrapItem,
	useToast,
	Textarea,
	IconButton,
	Tooltip,
	RadioGroup,
	Radio,
} from "@chakra-ui/react";
import { Select as ChakraSelect } from "chakra-react-select";

import ContactPopover from "../ContactPopover/ContactPopover";
import { addAssociationAsync, addContactAsync, removeAssociationAsync, updateAssociationAsync } from "../../redux/user/userSlice";
import { useDispatch, useSelector } from "react-redux";
import OwnerPopover from "../ContactPopover/OwnerPopover";
import { addContactVerifier } from "../../utils/verifier";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import { useTranslation } from "react-i18next";
const contactTypes = [
	{ id: "ownerSpouse", label: "Partener proprietar" },
	{ id: "buildingSuper", label: "Administrator bloc" },
	{ id: "headOfTenantAssociation", label: "Sef asociatie de locatari" },
	{ id: "other", label: "Altele" },
];
export default function Contacts({ property, loading }) {
	const isArchived = property.archived;
	const { t } = useTranslation();
	const initialContactInformation = {
		name: "",
		email: "",
		phoneNumber: "",
		phoneNumber2: "",
		propertyId: property.id,
		contactType: "",
	};
	const initialAssociationInformation = {
		name: "",
		email: "",
		phoneNumber: "",
		administratorName: "",
		iban: "",
		description: "",
	};
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { isOpen: isAddAssociationOpen, onOpen: onAddAssociationOpen, onClose: onAddAssociationClose } = useDisclosure();
	const { isOpen: isEditAssociationOpen, onOpen: onEditAssociationOpen, onClose: onEditAssociationClose } = useDisclosure();
	const { isOpen: isDeleteConfirmationOpen, onOpen: onDeleteConfirmationOpen, onClose: onDeleteConfirmationClose } = useDisclosure();
	const [contactInformation, setContactInformation] = useState(initialContactInformation);
	const [associationInformation, setAssociationInformation] = useState(initialAssociationInformation);
	const [editAssociationInformation, setEditAssociationInformation] = useState({ ...property.association });
	const [error, setError] = useState({});
	const user = useSelector((state) => state.user?.user);

	const associations = user.associations;

	const toast = useToast();
	const [addAssociationSource, setAddAssociationSource] = useState("existing");
	const dispatch = useDispatch();
	useEffect(() => {
		console.log(contactInformation);
	}, [contactInformation]);
	const handleClose = () => {
		onClose();
		setError({});
		setContactInformation(initialContactInformation);
	};
	const handleAddAssociationClose = () => {
		onAddAssociationClose();
		setError({});
		setAssociationInformation(initialAssociationInformation);
	};
	const handleEditAssociationClose = () => {
		onEditAssociationClose();
		setError({});
		setAssociationInformation(initialAssociationInformation);
	};
	const handleDelete = () => {
		const payload = { propertyId: property.id, associationId: property.association.id };
		dispatch(removeAssociationAsync(payload))
			.then((r) => {
				if (r.error?.message)
					toast({
						title: "Error encountered",
						description: r.error.message,
						status: "error",
						duration: 9000,
						isClosable: true,
					});
				else {
					toast({
						title: "Success!",
						description: "Contact Added!",
						status: "success",
						duration: 2000,
						isClosable: true,
					});
				}
			})
			.finally(() => {
				onDeleteConfirmationClose();
			});
	};
	const saveAssociation = () => {
		const payload = { ...editAssociationInformation, propertyId: property.id };
		console.log(payload);
		const errors = addContactVerifier(payload);
		console.log(errors);
		if (Object.entries(errors).length !== 0) {
			setError(errors);
			toast({
				title: "Error encountered.",
				description: "Invalid information",
				status: "error",
				duration: 2000,
				isClosable: true,
			});
			return;
		}
		setError({});
		dispatch(updateAssociationAsync(payload))
			.then((r) => {
				if (r.error?.message)
					toast({
						title: "Error encountered",
						description: r.error.message,
						status: "error",
						duration: 9000,
						isClosable: true,
					});
				else {
					toast({
						title: "Success!",
						description: "Contact Added!",
						status: "success",
						duration: 2000,
						isClosable: true,
					});
				}
			})
			.finally(() => {
				onEditAssociationClose();
				setEditAssociationInformation(initialAssociationInformation);
			});
	};
	const addAssociation = () => {
		const payload = { ...associationInformation, propertyId: property.id };
		console.log(payload);
		const errors = addContactVerifier(payload);
		if (Object.entries(errors).length !== 0) {
			setError(errors);
			toast({
				title: "Error encountered.",
				description: "Invalid information",
				status: "error",
				duration: 2000,
				isClosable: true,
			});
			return;
		}
		setError({});
		dispatch(addAssociationAsync(payload))
			.then((r) => {
				if (r.error?.message)
					toast({
						title: "Error encountered",
						description: r.error.message,
						status: "error",
						duration: 9000,
						isClosable: true,
					});
				else {
					toast({
						title: "Success!",
						description: "Contact Added!",
						status: "success",
						duration: 2000,
						isClosable: true,
					});
				}
			})
			.finally(() => {
				onAddAssociationClose();
				setContactInformation(initialContactInformation);
			});
	};
	const handleAddAssociationChange = (e) => {
		setAssociationInformation({ ...associationInformation, [e.target.name]: e.target.value });
	};
	const handleEditAssociationChange = (e) => {
		setEditAssociationInformation({ ...editAssociationInformation, [e.target.name]: e.target.value });
	};
	const addContact = () => {
		console.log(contactInformation);
		const errors = addContactVerifier(contactInformation);
		if (Object.entries(errors).length !== 0) {
			setError(errors);
			toast({
				title: "Error encountered.",
				description: "Invalid information",
				status: "error",
				duration: 2000,
				isClosable: true,
			});
			return;
		}
		setError({});
		dispatch(addContactAsync(contactInformation))
			.then((r) => {
				if (r.error?.message)
					toast({
						title: "Error encountered",
						description: r.error.message,
						status: "error",
						duration: 9000,
						isClosable: true,
					});
				else {
					toast({
						title: "Success!",
						description: "Contact Added!",
						status: "success",
						duration: 2000,
						isClosable: true,
					});
				}
			})
			.finally(() => {
				onClose();
				setContactInformation(initialContactInformation);
			});
	};
	const handleChange = (e) => {
		console.log("changeee");
		setContactInformation({ ...contactInformation, [e.target.name]: e.target.value });
	};
	const renderAddContactModal = () => (
		<Modal isOpen={isOpen} onClose={onClose} size={"xl"}>
			<ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
			<ModalContent>
				<ModalHeader>
					<Text>Add a Contact</Text>
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Stack>
						<FormControl>
							<FormLabel display={"inline"}>Name</FormLabel>
							<Input
								width={"fit-content"}
								type="text"
								name="name"
								placeholder="Name"
								_placeholder={{ color: "gray.500" }}
								value={contactInformation.name}
								isInvalid={error.name}
								onChange={handleChange}
							/>
						</FormControl>
						<FormControl>
							<FormLabel display={"inline"}>Email</FormLabel>
							<Input
								width={"fit-content"}
								type="text"
								name="email"
								placeholder="Email"
								_placeholder={{ color: "gray.500" }}
								value={contactInformation.email}
								isInvalid={error.email}
								onChange={handleChange}
							/>
						</FormControl>
						<FormControl>
							<FormLabel display={"inline"}>Phone Number</FormLabel>
							<Input
								width={"fit-content"}
								type="number"
								name="phoneNumber"
								placeholder="Phone Number"
								_placeholder={{ color: "gray.500" }}
								value={contactInformation.phoneNumber}
								isInvalid={error.phoneNumber}
								onChange={handleChange}
							/>
						</FormControl>
						<FormControl>
							<FormLabel display={"inline"}>Alternative Phone Number</FormLabel>
							<Input
								width={"fit-content"}
								type="number"
								name="phoneNumber2"
								placeholder="Phone Number"
								_placeholder={{ color: "gray.500" }}
								value={contactInformation.phoneNumber2}
								isInvalid={error.phoneNumber2}
								onChange={handleChange}
							/>
						</FormControl>

						<FormControl display={"flex"} alignItems="center">
							<FormLabel display={"inline"}>Type:</FormLabel>
							<Select
								width={"fit-content"}
								onChange={handleChange}
								type="number"
								name="contactType"
								placeholder="Contact type"
								isInvalid={error.contactType}
								_placeholder={{ color: "gray.500" }}
							>
								{contactTypes.map((ct, index) => (
									<option key={index} value={ct.id}>
										{ct.label}
									</option>
								))}
							</Select>
						</FormControl>
					</Stack>
				</ModalBody>
				<ModalFooter>
					<Button colorScheme="red" onClick={handleClose} mr={3}>
						{t("misc.cancel")}
					</Button>
					<Button colorScheme="blue" onClick={addContact} isDisabled={loading}>
						{t("misc.save")}
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
	const renderAddAssociationModal = () => (
		<Modal isOpen={isAddAssociationOpen} onClose={onAddAssociationClose} size={"xl"}>
			<ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
			<ModalContent>
				<ModalHeader>
					<Text>Add Association</Text>
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<RadioGroup
						defaultValue={addAssociationSource}
						onChange={(newValue) => {
							setAddAssociationSource(newValue);
							setAssociationInformation(initialAssociationInformation);
						}}
					>
						<Stack direction="row" marginBottom={4}>
							<Radio value="existing" name="associationType">
								Existing
							</Radio>
							<Radio value="new" name="associationType">
								New
							</Radio>
						</Stack>
					</RadioGroup>
					{addAssociationSource === "new" ? (
						<Stack>
							<FormControl>
								<FormLabel display={"inline"}>Name:</FormLabel>
								<Textarea
									type="text"
									name="name"
									placeholder="Name"
									_placeholder={{ color: "gray.500" }}
									value={associationInformation.name}
									isInvalid={error.name}
									onChange={handleAddAssociationChange}
								/>
							</FormControl>
							<FormControl>
								<FormLabel display={"inline"}>Administrator Name:</FormLabel>
								<Input
									width={"fit-content"}
									type="text"
									name="administratorName"
									placeholder="Name"
									_placeholder={{ color: "gray.500" }}
									value={associationInformation.administratorName}
									isInvalid={error.administratorName}
									onChange={handleAddAssociationChange}
								/>
							</FormControl>
							<FormControl>
								<FormLabel display={"inline"}>Email:</FormLabel>
								<Input
									width={"fit-content"}
									type="text"
									name="email"
									placeholder="Email"
									_placeholder={{ color: "gray.500" }}
									value={associationInformation.email}
									isInvalid={error.email}
									onChange={handleAddAssociationChange}
								/>
							</FormControl>
							<FormControl>
								<FormLabel display={"inline"}>Phone Number:</FormLabel>
								<Input
									width={"fit-content"}
									type="number"
									name="phoneNumber"
									placeholder="Phone Number"
									_placeholder={{ color: "gray.500" }}
									value={associationInformation.phoneNumber}
									isInvalid={error.phoneNumber}
									onChange={handleAddAssociationChange}
								/>
							</FormControl>
							<FormControl>
								<FormLabel display={"inline"}>Iban:</FormLabel>
								<Input
									width={"fit-content"}
									type="text"
									name="iban"
									placeholder="IBAN"
									_placeholder={{ color: "gray.500" }}
									value={associationInformation.iban}
									isInvalid={error.iban}
									onChange={handleAddAssociationChange}
								/>
							</FormControl>

							<FormControl display={"flex"} alignItems="center">
								<FormLabel display={"inline"}>Description:</FormLabel>
								<Textarea value={associationInformation.description} onChange={handleAddAssociationChange} name="description" />
							</FormControl>
						</Stack>
					) : (
						<ChakraSelect
							options={associations.map((a) => {
								return { label: a.name, value: a };
							})}
							onChange={(a) => {
								setAssociationInformation(a.value);
							}}
						/>
					)}
				</ModalBody>
				<ModalFooter>
					<Button colorScheme="red" onClick={handleAddAssociationClose} mr={3}>
						{t("misc.cancel")}
					</Button>
					<Button colorScheme="blue" onClick={addAssociation} isDisabled={loading}>
						{t("misc.save")}
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
	const renderEditAssociationModal = () => (
		<Modal isOpen={isEditAssociationOpen} onClose={onEditAssociationClose} size={"xl"}>
			<ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
			<ModalContent>
				<ModalHeader>
					<Text>Edit Association</Text>
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Stack>
						<FormControl>
							<FormLabel display={"inline"}>Name:</FormLabel>
							<Textarea
								width={"fit-content"}
								type="text"
								name="name"
								placeholder="Name"
								_placeholder={{ color: "gray.500" }}
								value={editAssociationInformation.name}
								isInvalid={error.name}
								onChange={handleEditAssociationChange}
							/>
						</FormControl>
						<FormControl>
							<FormLabel display={"inline"}>Administrator Name:</FormLabel>
							<Input
								width={"fit-content"}
								type="text"
								name="administratorName"
								placeholder="Name"
								_placeholder={{ color: "gray.500" }}
								value={editAssociationInformation.administratorName}
								isInvalid={error.administratorName}
								onChange={handleEditAssociationChange}
							/>
						</FormControl>
						<FormControl>
							<FormLabel display={"inline"}>Email:</FormLabel>
							<Input
								width={"fit-content"}
								type="text"
								name="email"
								placeholder="Email"
								_placeholder={{ color: "gray.500" }}
								value={editAssociationInformation.email}
								isInvalid={error.email}
								onChange={handleEditAssociationChange}
							/>
						</FormControl>
						<FormControl>
							<FormLabel display={"inline"}>Phone Number:</FormLabel>
							<Input
								width={"fit-content"}
								type="number"
								name="phoneNumber"
								placeholder="Phone Number"
								_placeholder={{ color: "gray.500" }}
								value={editAssociationInformation.phoneNumber}
								isInvalid={error.phoneNumber}
								onChange={handleEditAssociationChange}
							/>
						</FormControl>
						<FormControl>
							<FormLabel display={"inline"}>Iban:</FormLabel>
							<Input
								width={"fit-content"}
								type="text"
								name="iban"
								placeholder="IBAN"
								_placeholder={{ color: "gray.500" }}
								value={editAssociationInformation.iban}
								isInvalid={error.iban}
								onChange={handleEditAssociationChange}
							/>
						</FormControl>

						<FormControl display={"flex"} alignItems="center">
							<FormLabel display={"inline"}>Description:</FormLabel>
							<Textarea value={editAssociationInformation.description} onChange={handleEditAssociationChange} name="description" />
						</FormControl>
					</Stack>
				</ModalBody>
				<ModalFooter>
					<Button colorScheme="red" onClick={handleEditAssociationClose} mr={3}>
						{t("misc.cancel")}
					</Button>
					<Button colorScheme="blue" onClick={saveAssociation} isDisabled={loading}>
						{t("misc.save")}
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
	const renderDeleteConfirmationModal = () => {
		return (
			<Modal isOpen={isDeleteConfirmationOpen} onClose={onDeleteConfirmationClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Confirm delete?</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Text>Are you sure you want to remove the association from this property? </Text>
						<Text>
							This action{" "}
							<Text as="span" fontWeight={"bold"}>
								cannot
							</Text>{" "}
							be undone!{" "}
						</Text>
					</ModalBody>

					<ModalFooter>
						<Button colorScheme="red" mr={3} onClick={handleDelete}>
							Delete
						</Button>
						<Button colorScheme="blue" mr={3} onClick={onDeleteConfirmationClose}>
							Close
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		);
	};
	return (
		<Stack>
			<Stack direction="row">
				<Box>
					<Button colorScheme={"blue"} onClick={onOpen} isDisabled={isArchived}>
						{t("property.addContact")}
					</Button>
					{renderAddContactModal()}
				</Box>
				<Box>
					<Button colorScheme={"blue"} onClick={onAddAssociationOpen} isDisabled={property.association?.id || isArchived}>
						{t("property.addAssociation")}
					</Button>
					{renderAddAssociationModal()}
				</Box>
			</Stack>
			<Divider />
			<Wrap>
				<WrapItem hidden={!property.buildingSuper.email}>
					<ContactPopover contact={property.buildingSuper} property={property} />
				</WrapItem>
				<WrapItem>
					<OwnerPopover contact={property.owner} />
				</WrapItem>
				{property.contacts.map((c, index) => (
					<WrapItem key={index}>
						<ContactPopover contact={c} property={property} />
					</WrapItem>
				))}
			</Wrap>

			{property.association?.id && (
				<Box>
					<Divider />
					<Stack>
						<Text fontWeight="bold" fontSize="xl">
							{t("property.association")}{" "}
							<Tooltip label={t("property.editAssociation")}>
								<IconButton
									marginLeft={2}
									icon={<EditIcon />}
									size="xs"
									onClick={() => {
										onEditAssociationOpen();
										setEditAssociationInformation({ ...editAssociationInformation, ...property.association });
									}}
								/>
							</Tooltip>
							{renderEditAssociationModal()}
							<Tooltip label={t("property.removeAssociation")}>
								<IconButton
									colorScheme="red"
									marginLeft={2}
									icon={<DeleteIcon />}
									size="xs"
									onClick={() => {
										onDeleteConfirmationOpen();
									}}
								/>
							</Tooltip>
							{renderDeleteConfirmationModal()}
						</Text>
						<Stack direction="row">
							<Text fontWeight={"bold"}>{t("property.name")}:</Text>
							<Text>{property.association.name}</Text>
						</Stack>
						<Stack direction="row">
							<Text fontWeight={"bold"}>{t("property.administratorName")}:</Text>
							<Text>{property.association.administratorName}</Text>
						</Stack>
						<Stack direction="row">
							<Text fontWeight={"bold"}>{t("property.email")}:</Text>
							<Text>{property.association.email}</Text>
						</Stack>
						<Stack direction="row">
							<Text fontWeight={"bold"}>{t("property.phoneNumber")}:</Text>
							<Text>{property.association.phoneNumber}</Text>
						</Stack>
						<Stack direction="row">
							<Text fontWeight={"bold"}>{t("property.iban")}:</Text>
							<Text>{property.association.iban}</Text>
						</Stack>
						<Stack direction="row">
							<Text fontWeight={"bold"}>{t("property.description")}:</Text>
							<Text>{property.association.description}</Text>
						</Stack>
					</Stack>
				</Box>
			)}
		</Stack>
	);
}
