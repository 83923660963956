import React, { useEffect } from "react";
import { Flex, Box } from "@chakra-ui/react";
import usePagination from "../../Hooks/usePagination";

const TablePagination = ({ totalItems, itemsPerPage, onPageChange }) => {
	const { currentPage, totalPages, handlePageChange } = usePagination(totalItems, itemsPerPage);

	const renderPageNumbers = () => {
		const pageNumbers = [];

		for (let i = 1; i <= totalPages; i++) {
			pageNumbers.push(i);
		}

		return pageNumbers;
	};
	useEffect(() => {
		onPageChange(currentPage);
	}, [currentPage]);
	return (
		<Flex justifyContent="center" alignItems="center" mt="1rem">
			{renderPageNumbers().map((pageNumber) => (
				<Box
					key={pageNumber}
					onClick={() => {
						handlePageChange(pageNumber);
					}}
					cursor="pointer"
					borderRadius="full"
					bg={pageNumber === currentPage ? "blue.500" : "gray.200"}
					color={pageNumber === currentPage ? "white" : "black"}
					px={2}
					py={1}
					mx={1}
					fontSize="sm"
					_hover={{
						bg: pageNumber === currentPage ? "blue.600" : "gray.300",
						transform: "scale(1.05)",
						transition: "transform 0.2s",
					}}
					transition="background-color 0.2s"
				>
					{pageNumber}
				</Box>
			))}
		</Flex>
	);
};

export default TablePagination;
