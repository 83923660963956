import {
	Box,
	Button,
	Card,
	CardBody,
	CardHeader,
	FormControl,
	FormLabel,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Stack,
	Table,
	TableCaption,
	Tbody,
	Td,
	Text,
	Textarea,
	Th,
	Thead,
	Tr,
	useDisclosure,
	useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import TicketsTableRow from "./TicketsTableRow";
import { _arrayBufferToBase64 } from "../../../utils/convertors";
import { addTicketAsync } from "../../../redux/user/userSlice";
import { useDispatch } from "react-redux";
import Dropzone from "../../../Components/Dropzone/Dropzone";
import { useTranslation } from "react-i18next";
export default function PropertyTicketsCard({ tickets, address, author, propertyId }) {
	const initialAddTicketInformation = { title: "", description: "", media: [] };
	const [addTicketInformation, setAddTicketInformation] = useState(initialAddTicketInformation);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const toast = useToast();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const handleChange = (e) => {
		setAddTicketInformation({ ...addTicketInformation, [e.target.name]: e.target.value });
	};
	const addTicket = (propertyId) => {
		const payload = { ticket: addTicketInformation, propertyId, author };
		console.log(payload);
		dispatch(addTicketAsync(payload))
			.then((r) => {
				if (r.error?.message)
					toast({
						title: "Error encountered",
						description: r.error.message,
						status: "error",
						duration: 9000,
						isClosable: true,
					});
				else {
					toast({
						title: "Success!",
						description: "Document Added!",
						status: "success",
						duration: 2000,
						isClosable: true,
					});
				}
			})
			.finally(() => {
				setAddTicketInformation(initialAddTicketInformation);
				onClose();
			});
	};
	const onDocumentDrop = async (acceptedFiles) => {
		console.log(acceptedFiles);
		let documents = [];
		for (const file of acceptedFiles) {
			const buffer = await file.arrayBuffer();
			const documentName = file.name;
			const contentType = file.type;
			documents.push({ documentData: _arrayBufferToBase64(buffer), documentName, contentType });
		}
		setAddTicketInformation({ ...addTicketInformation, media: documents });
	};
	const renderAddTicketModal = (propertyId) => {
		return (
			<Modal isOpen={isOpen} onClose={onClose} size={"xl"}>
				<ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
				<ModalContent>
					<ModalHeader>
						<Text>Add a Ticket</Text>
					</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Stack>
							<FormControl>
								<FormLabel display={"inline"}>Title:</FormLabel>
								<Input width={"fit-content"} onChange={handleChange} type="text" name="title" placeholder="Ttile" _placeholder={{ color: "gray.500" }} />
							</FormControl>
							<FormControl>
								<FormLabel display={"inline"}>Description:</FormLabel>
								<Textarea onChange={handleChange} type="number" name="description" placeholder="Description" _placeholder={{ color: "gray.500" }} />
							</FormControl>

							<FormControl>
								<FormLabel>Media:</FormLabel>
								<Dropzone onDrop={onDocumentDrop} accept={{ "image/*": [], "video/*": [] }} description="Upload an Image or a Video" />
							</FormControl>
						</Stack>
					</ModalBody>
					<ModalFooter>
						<Button
							colorScheme="red"
							onClick={() => {
								onClose();
								setAddTicketInformation(initialAddTicketInformation);
							}}
							mr={3}
						>
							Cancel
						</Button>
						<Button
							colorScheme="blue"
							onClick={() => {
								addTicket(propertyId);
							}}
						>
							Save
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		);
	};
	return (
		<Card overflowX={{ sm: "scroll", xl: "hidden" }}>
			<CardHeader>
				<Stack>
					<Text fontSize="xl">Address: {address}</Text>
					<Button onClick={onOpen} width="fit-content" colorScheme="blue">
						{t("property.addTicket")}
					</Button>
					{renderAddTicketModal(propertyId)}
				</Stack>
			</CardHeader>
			<CardBody>
				<Table variant="simple">
					<Thead>
						<Tr my=".8rem" pl="0px">
							<Th>Added date</Th>
							<Th>Status</Th>
							<Th>Title</Th>
							<Th>Author</Th>
							<Th></Th>
						</Tr>
					</Thead>
					<Tbody>
						{tickets?.map((t, index) => (
							<TicketsTableRow key={index} row={t} author={author} owner={t.owner} propertyId={t.propertyId} />
						))}
						{tickets?.length === 0 && (
							<Tr>
								<Td colSpan={5}>No Tickets Yet</Td>
							</Tr>
						)}
					</Tbody>
				</Table>
			</CardBody>
		</Card>
	);
}
