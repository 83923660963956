import React from "react";
import { Button, AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter } from "@chakra-ui/react";

const WithConfirmation = ({ children, onConfirm, ...props }) => {
	const [isOpen, setIsOpen] = React.useState(false);
	const onClose = () => setIsOpen(false);
	const cancelRef = React.useRef();

	const handleClick = () => {
		setIsOpen(true);
	};

	const handleConfirm = () => {
		onClose();
		onConfirm();
	};

	return (
		<>
			{React.cloneElement(children, { onClick: handleClick })}
			<AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize="lg" fontWeight="bold">
							Confirmare
						</AlertDialogHeader>

						<AlertDialogBody>Sunteți sigur că doriți să efectuați această acțiune?</AlertDialogBody>

						<AlertDialogFooter>
							<Button ref={cancelRef} onClick={onClose}>
								Anulează
							</Button>
							<Button colorScheme="red" onClick={handleConfirm} ml={3}>
								Confirmă
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
		</>
	);
};

export default WithConfirmation;
