import React from "react";
import {
	Popover,
	PopoverTrigger,
	PopoverContent,
	PopoverHeader,
	PopoverBody,
	PopoverArrow,
	PopoverCloseButton,
	Button,
	Stack,
	Text,
	Box,
	Link,
	Divider,
} from "@chakra-ui/react";
import { NavLink as ReactRouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function TenantPopover({ contact, propertyId, former, handleFormer }) {
	const { t } = useTranslation();
	return (
		<Popover>
			<PopoverTrigger>
				<Button width={"fit-content"} size={"sm"} colorScheme={former ? "gray" : "green"} textTransform={"uppercase"}>
					{contact.name}
				</Button>
			</PopoverTrigger>
			<PopoverContent>
				<PopoverArrow />
				<PopoverCloseButton />
				<PopoverHeader>
					Contact information -{" "}
					<Text as="span" fontWeight={"bold"}>
						Tenant
					</Text>
				</PopoverHeader>
				<PopoverBody>
					<Stack style={{ whiteSpace: "normal" }}>
						<Box>
							<Text as={"span"} fontWeight={"bold"}>
								Name:
							</Text>{" "}
							<Text as={"span"}>{contact.name}</Text>
						</Box>
						<Box>
							<Text as={"span"} fontWeight={"bold"}>
								Email:
							</Text>{" "}
							{contact.email}
						</Box>
						<Box>
							<Text as={"span"} fontWeight={"bold"}>
								Phone Number:
							</Text>{" "}
							{contact.phoneNumber}
						</Box>
						<Box>
							<Text as={"span"} fontWeight={"bold"}>
								Alternative Phone Number:
							</Text>{" "}
							{contact.phoneNumber2 || "-"}
						</Box>
						<Divider />
						<Stack direction={"row"}>
							{handleFormer && (
								<Button
									onClick={() => {
										handleFormer(contact, propertyId, former);
									}}
									w={"fit-content"}
								>
									{t(`property.${former ? "markActive" : "markFormer"}`)}
								</Button>
							)}
							<Link as={ReactRouterLink} to={`/tenant/view/${propertyId}/${contact.id}`}>
								<Button colorScheme="blue">{t("table.view")}</Button>
							</Link>
						</Stack>
					</Stack>
				</PopoverBody>
			</PopoverContent>
		</Popover>
	);
}
