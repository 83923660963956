import { Stack, Text, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import { useSelector } from "react-redux";
import { Card, CardHeader } from "@chakra-ui/react";
import { getPropertyAddress } from "../../../utils/verifier";
import PropertyTicketsCard from "./PropertyTicketsCard";
import { selectActiveProperties } from "../../../redux/user/userSlice";
import { useTranslation } from "react-i18next";

export default function Tickets() {
	const textColor = useColorModeValue("gray.700", "white");
	const properties = useSelector(selectActiveProperties);
	const name = useSelector((state) => state.user.user?.name);
	const email = useSelector((state) => state.user.user?.email);
	const phoneNumber = useSelector((state) => state.user.user?.phoneNumber);
	const { t } = useTranslation();
	return (
		<Stack direction={"column"}>
			<Card overflowX={{ sm: "scroll", xl: "hidden" }}>
				<CardHeader>
					<Text fontSize="xl" color={textColor} fontWeight="bold">
						{t("navbarMenu.tickets")}
					</Text>
				</CardHeader>
			</Card>
			{properties.map((p) => {
				const a = getPropertyAddress(p);
				const t = p.tickets.map((t) => {
					return { ...t, address: a, propertyId: p.id, owner: p.owner };
				});
				return <PropertyTicketsCard tickets={t} address={a} author={{ name: name, phoneNumber, email }} />;
			})}
		</Stack>
	);
}
