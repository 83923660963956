import {
	Badge,
	Button,
	Flex,
	Modal,
	ModalOverlay,
	ModalHeader,
	ModalContent,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	Td,
	Text,
	Tr,
	useColorModeValue,
	List,
	ListItem,
	Box,
	Textarea,
} from "@chakra-ui/react";
import { FormControl, FormLabel, Heading, Stack } from "@chakra-ui/react";
import React, { useState } from "react";
import { useDisclosure } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { addReplyToMessageAsync } from "../../redux/user/userSlice";

import moment from "moment";
import { getPropertyAddress } from "../../utils/verifier";
import { t } from "i18next";
import { fullDateTimeFormat } from "../../utils/types";

export default function MessagesTableRow(props) {
	const { row, author, property } = props;
	const textColor = useColorModeValue("gray.700", "white");
	const pendingStatusBackground = "blue";
	const resolvedStatusBackground = "green";

	const { isOpen, onOpen, onClose } = useDisclosure();
	const dispatch = useDispatch();
	const initialReply = { message: "" };
	const [reply, setReply] = useState(initialReply);

	const handleChange = (e) => {
		setReply({ message: e.target.value });
	};
	const saveTicket = (e) => {
		const payload = { author, reply, messageId: row.id, propertyId: property.id };
		dispatch(addReplyToMessageAsync(payload)).finally(() => {
			onClose(e);
		});
	};
	const latestReply = row?.replies.toSorted((a, b) => moment(b.timestamp).diff(moment(a.timestamp)))[0]?.timestamp;
	const renderTicketModal = () => (
		<Modal isOpen={isOpen} onClose={onClose} size={"xl"}>
			<ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
			<ModalContent>
				<ModalHeader>
					<Stack>
						<Heading size="md">Message details</Heading>

						<Heading>
							{row.title}
							<Badge
								colorScheme={row.type === "tenant" ? resolvedStatusBackground : pendingStatusBackground}
								fontSize="16px"
								p="3px 10px"
								borderRadius="8px"
								marginLeft={2}
							>
								{row.type}
							</Badge>
						</Heading>

						<Heading size="sm" fontWeight={"medium"}>
							~ {row.author.name}, {moment(row.addedDate).format("DD MMMM YYYY - HH:mm")}
						</Heading>
					</Stack>
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Flex align={"center"} justify={"center"}>
						<Stack spacing={2} w={"full"} rounded={"xl"} p={3}>
							<Box>
								<Text fontWeight={"bold"}>Description:</Text>
								<Text>{row.description}</Text>
							</Box>
							<List spacing={3}>
								{row.replies.map((m, index) => {
									return (
										<ListItem key={index}>
											{/* <ListIcon
												as={tenant.paymentStatus === "valid" ? MdCheckCircle : IoMdCloseCircle}
												color={tenant.paymentStatus === "valid" ? "green.500" : "red.500"}
											/> */}
											<Stack>
												<Text fontWeight={"bold"}>
													{m.author.name} at {moment(m.timestamp).format("DD MMM YYYY - HH:mm")}
												</Text>
												<Text pl={2}>{m.message}</Text>
											</Stack>
										</ListItem>
									);
								})}
							</List>
							<FormControl hidden={row.status === "done"}>
								<FormLabel>Your response:</FormLabel>
								<Textarea
									name="rooms"
									placeholder="Type your message here..."
									// value={propertyInformation?.rooms}
									onChange={handleChange}
									_placeholder={{ color: "gray.500" }}
									type="text"
								/>
							</FormControl>
						</Stack>
					</Flex>
				</ModalBody>
				<ModalFooter>
					<Flex gap={2} justifyContent={"flex-end"} width={"100%"}>
						{/* <Button colorScheme="blue" onClick={markTicketAsDone} isDisabled={row.status === "done"}>
							Mark as Done
						</Button> */}
						<Flex gap={2}>
							<Button colorScheme="red" onClick={onClose}>
								{t("misc.cancel")}
							</Button>
							<Button colorScheme="blue" onClick={saveTicket} isDisabled={row.status === "done"}>
								{t("misc.save")}
							</Button>
						</Flex>
					</Flex>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
	return (
		<Tr>
			<Td>
				<Text fontSize="md" minWidth="100%">
					{latestReply ? moment(latestReply).format(fullDateTimeFormat) : "No replies yet"}
				</Text>
			</Td>
			<Td>
				<Text fontSize="md" minWidth="100%">
					{property.owner.name}
				</Text>
			</Td>
			{/* <Td>
				<Text fontSize="md" minWidth="100%">
					{getPropertyAddress(property)}
				</Text>
			</Td> */}
			<Td>
				<Text fontSize="md" color={textColor} pb=".5rem">
					{row.title}
				</Text>
			</Td>
			<Td>
				<Text fontSize="md" color={textColor} pb=".5rem">
					{row.author.name}
				</Text>
			</Td>

			<Td>
				<Badge colorScheme={row.type === "tenant" ? resolvedStatusBackground : pendingStatusBackground} fontSize="16px" p="3px 10px" borderRadius="8px">
					{t(`table.${row.type}`)}
				</Badge>
			</Td>
			<Td>
				<Text fontSize="md" color={textColor} pb=".5rem">
					{moment(row.addedDate).format("DD MMMM YYYY - HH:mm")}
				</Text>
			</Td>
			<Td>
				<Button onClick={onOpen} colorScheme="blue">
					<Text fontSize="md">{t("table.view")}</Text>
				</Button>
				{renderTicketModal()}
			</Td>
		</Tr>
	);
}
