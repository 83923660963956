// LoadingPage.js
import React from "react";
import Lottie from "lottie-react";
import animationData from "./loading-animation.json"; // Replace with the actual path to your animation JSON
import "./pageLoader.css"; // You can create a CSS file for styling if needed

const LoadingPage = () => {
	return (
		<div className="loading-page">
			<Lottie animationData={animationData} width={200} height={200} />
		</div>
	);
};

export default LoadingPage;
