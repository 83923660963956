import React, { useEffect, useState } from "react";
import {
	Box,
	Button,
	Table,
	Tbody,
	Text,
	Th,
	Thead,
	Tr,
	Divider,
	Input,
	Stack,
	FormControl,
	FormLabel,
	Select,
	useDisclosure,
	Modal,
	ModalOverlay,
	ModalHeader,
	ModalContent,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	Checkbox,
	Td,
	useToast,
	Tooltip,
} from "@chakra-ui/react";
import Dropzone from "../Dropzone/Dropzone";
import { useDispatch } from "react-redux";
import { addDocumentAsync, addProtocolAsync, updateAdditionalStepsAsync } from "../../redux/user/userSlice";
import DocumentsTableRow from "../TableRows/DocumentsTableRow";
import { documentTypes } from "../../utils/types";
import { _arrayBufferToBase64 } from "../../utils/convertors";
import moment from "moment";
import { addDocumentVerifier } from "../../utils/verifier";
import ProtocolsTableRow from "../TableRows/ProtocolsTableRow";
import DocumentsTooltipLabel from "../DocumentsTooltipLabel/DocumentsTooltipLabel";
import { InfoOutlineIcon } from "@chakra-ui/icons";
import { useTranslation } from "react-i18next";

export default function DocumentsTab({ property }) {
	const isArchived = property.archived;
	const initialDocumentInformation = {
		startingDate: "",
		expirationDate: "",
		documentNumber: "",
		documentType: "",
		documentData: "",
		propertyId: property.id,
		documentName: "",
		contentType: "",
		active: true,
	};
	const initialReportInformation = {
		propertyId: property.id,
		addedDate: moment().format("yyyy-MM-DD"),
		documentNumber: "",
		documentType: "protocol",
		documentData: "",
		documentName: "",
		contentType: "",
		media: [],
		// expirationDate: "",
	};
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const documents = property.documents.filter((d) => d.type != "lease" && d.type != "extension" && d.type != "termination" && d.type != "rentModification");
	const reports = property.protocols;
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { isOpen: isReportOpen, onOpen: onReportOpen, onClose: onReportClose } = useDisclosure();
	const [documentInformation, setDocumentInformation] = useState(initialDocumentInformation);
	const [reportInformation, setReportInformation] = useState(initialReportInformation);
	const [additionalStepsInformation, setAdditionalStepsInformation] = useState(property.additionalSteps);
	const [error, setError] = useState({});
	const toast = useToast();
	useEffect(() => {
		console.log(documentInformation);
	}, [documentInformation]);
	const saveCheckboxes = () => {
		console.log(additionalStepsInformation);
		//dispatch save additional steps
		dispatch(updateAdditionalStepsAsync({ ...additionalStepsInformation, propertyId: property.id })).then((r) => {
			if (r.error?.message)
				toast({
					title: "Error encountered",
					description: r.error.message,
					status: "error",
					duration: 9000,
					isClosable: true,
				});
			else {
				toast({
					title: "Success!",
					description: "Update Successful!",
					status: "success",
					duration: 2000,
					isClosable: true,
				});
			}
		});
	};
	const handleChangeCheckboxes = (e) => {
		setAdditionalStepsInformation({ ...additionalStepsInformation, [e.target.name]: e.target.checked });
	};
	const onDocumentDrop = async (acceptedFiles) => {
		console.log(acceptedFiles);
		const buffer = await acceptedFiles[0].arrayBuffer();
		const documentName = acceptedFiles[0].name;
		const contentType = acceptedFiles[0].type;
		setDocumentInformation({ ...documentInformation, documentData: _arrayBufferToBase64(buffer), documentName, contentType });
	};
	const onMediaDrop = async (acceptedFiles) => {
		const media = [];
		console.log(acceptedFiles);
		for (const file of acceptedFiles) {
			const buffer = await file.arrayBuffer();
			const documentName = file.name;
			const contentType = file.type;
			const doc = { documentData: _arrayBufferToBase64(buffer), documentName, contentType };
			console.log(doc);
			media.push(doc);
		}
		setReportInformation({ ...reportInformation, media });
	};
	const onReportDrop = async (acceptedFiles) => {
		console.log(acceptedFiles);
		const buffer = await acceptedFiles[0].arrayBuffer();
		const documentName = acceptedFiles[0].name;
		const contentType = acceptedFiles[0].type;
		setReportInformation({ ...reportInformation, documentData: _arrayBufferToBase64(buffer), documentName, contentType });
	};
	const addDocument = () => {
		console.log(documentInformation);
		const errors = addDocumentVerifier(documentInformation);
		console.log(errors);
		if (Object.entries(errors).length != 0) {
			setError(errors);
			toast({
				title: "Error encountered.",
				description: "Invalid information",
				status: "error",
				duration: 2000,
				isClosable: true,
			});
			return;
		}
		setError({});
		dispatch(addDocumentAsync(documentInformation))
			.then((r) => {
				if (r.error?.message)
					toast({
						title: "Error encountered",
						description: r.error.message,
						status: "error",
						duration: 9000,
						isClosable: true,
					});
				else {
					toast({
						title: "Success!",
						description: "Document Added!",
						status: "success",
						duration: 2000,
						isClosable: true,
					});
				}
			})
			.finally(() => {
				setDocumentInformation(initialDocumentInformation);
				onClose();
			});
	};
	const addReport = () => {
		console.log(reportInformation);
		const errors = addDocumentVerifier(reportInformation);
		if (Object.entries(errors).length != 0) {
			setError(errors);
			toast({
				title: "Error encountered.",
				description: "Invalid information",
				status: "error",
				duration: 2000,
				isClosable: true,
			});
			return;
		}
		setError({});
		dispatch(addProtocolAsync(reportInformation))
			.then((r) => {
				if (r.error?.message)
					toast({
						title: "Error encountered",
						description: r.error.message,
						status: "error",
						duration: 9000,
						isClosable: true,
					});
				else {
					toast({
						title: "Success!",
						description: "Update successful",
						status: "success",
						duration: 2000,
						isClosable: true,
					});
				}
			})
			.finally(() => {
				onReportClose();
				setReportInformation(initialReportInformation);
			});
	};
	const handleChange = (e) => {
		console.log("changeee");
		setDocumentInformation({ ...documentInformation, [e.target.name]: e.target.value });
	};
	const handleAddReportChange = (e) => {
		console.log("changeee");
		setReportInformation({ ...reportInformation, [e.target.name]: e.target.value });
	};
	const renderAddReportModal = () => (
		<Modal isOpen={isReportOpen} onClose={onReportClose} size={"xl"}>
			<ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
			<ModalContent>
				<ModalHeader>
					<Text>Add Report</Text>
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Stack>
						<FormControl>
							<FormLabel display={"inline"}>Added Date:</FormLabel>
							<Input
								width={"fit-content"}
								isInvalid={error?.addedDate}
								onChange={handleAddReportChange}
								type="date"
								value={reportInformation.addedDate}
								name="addedDate"
							/>
						</FormControl>
						<FormControl>
							<FormLabel display={"inline"}>Document Number:</FormLabel>
							<Input
								width={"fit-content"}
								isInvalid={error?.documentNumber}
								onChange={handleAddReportChange}
								type="text"
								name="documentNumber"
								placeholder="Document Number"
								_placeholder={{ color: "gray.500" }}
							/>
						</FormControl>
						{/* <FormControl display={"flex"} alignItems="center">
							<FormLabel display={"inline"}>Type:</FormLabel>
							<Select
								isInvalid={error?.documentType}
								width={"fit-content"}
								onChange={handleAddReportChange}
								type="number"
								name="documentType"
								placeholder="Document type"
								_placeholder={{ color: "gray.500" }}
							>
								{reportTypes.map((d, index) => (
									<option key={index} value={d.id}>
										{d.label}
									</option>
								))}
							</Select>
						</FormControl> */}
						<FormControl>
							<FormLabel>Report:</FormLabel>
							<Dropzone
								onDrop={onReportDrop}
								accept={{
									"application/pdf": [".pdf"],
									"application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
									"application/msword": [".doc"],
									"image/*": [],
								}}
								description="Upload PDF,DOC or DOCX Format"
								maxFiles={1}
								error={error?.documentData}
							/>
						</FormControl>
						<FormControl>
							<FormLabel>Media:</FormLabel>
							<Dropzone onDrop={onMediaDrop} accept={{ "image/*": [], "video/*": [] }} description="Upload an Image or a Video" error={error?.documentData} />
						</FormControl>
					</Stack>
				</ModalBody>
				<ModalFooter>
					<Button colorScheme="red" onClick={onReportClose} mr={3}>
						{t("misc.cancel")}
					</Button>
					<Button colorScheme="blue" onClick={addReport}>
						Add
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
	const renderAddDocumentModal = () => (
		<Modal isOpen={isOpen} onClose={onClose} size={"xl"}>
			<ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
			<ModalContent>
				<ModalHeader>
					<Text>{t("property.addDocument")}</Text>
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Stack>
						<FormControl>
							<FormLabel display={"inline"}>Starting Date:</FormLabel>
							<Input
								isInvalid={error?.startingDate}
								width={"fit-content"}
								onChange={handleChange}
								type="date"
								name="startingDate"
								placeholder="Type your message here..."
								_placeholder={{ color: "gray.500" }}
							/>
						</FormControl>
						<FormControl>
							<FormLabel display={"inline"}>Expiration Date:</FormLabel>
							<Input
								isInvalid={error?.expirationDate}
								width={"fit-content"}
								onChange={handleChange}
								type="date"
								name="expirationDate"
								placeholder="Type your message here..."
								_placeholder={{ color: "gray.500" }}
							/>
						</FormControl>
						<FormControl>
							<FormLabel display={"inline"}>Document Number:</FormLabel>
							<Input
								isInvalid={error?.documentNumber}
								width={"fit-content"}
								onChange={handleChange}
								type="text"
								name="documentNumber"
								placeholder="Document Number"
								_placeholder={{ color: "gray.500" }}
							/>
						</FormControl>
						<FormControl display={"flex"} alignItems="center">
							<FormLabel display={"inline"}>Type:</FormLabel>
							<Select
								isInvalid={error?.documentType}
								width={"fit-content"}
								onChange={handleChange}
								type="number"
								name="documentType"
								placeholder="Document type"
								_placeholder={{ color: "gray.500" }}
							>
								{documentTypes.map((d, index) => (
									<option key={index} value={d.id}>
										{d.label}
									</option>
								))}
							</Select>
						</FormControl>
						<Checkbox
							name="active"
							onChange={(e) => {
								setDocumentInformation({ ...documentInformation, active: e.target.checked });
							}}
							isChecked={documentInformation.active}
						>
							<Text>Is Active?</Text>
						</Checkbox>
						<FormControl>
							<FormLabel>Document:</FormLabel>
							<Dropzone
								onDrop={onDocumentDrop}
								accept={{
									"application/pdf": [".pdf"],
									"application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
									"application/msword": [".doc"],
									"image/*": [],
								}}
								description="Upload PDF,DOC or DOCX Format"
								maxFiles={1}
								error={error?.documentData}
							/>
						</FormControl>
					</Stack>
				</ModalBody>
				<ModalFooter>
					<Button
						colorScheme="red"
						onClick={() => {
							onClose();
							setError({});
						}}
						mr={3}
					>
						{t("misc.cancel")}
					</Button>
					<Button colorScheme="blue" onClick={addDocument}>
						Add
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);

	return (
		<Stack>
			<Stack direction={"row"}>
				<Button onClick={onOpen} colorScheme="blue" width={"fit-content"} isDisabled={isArchived}>
					{t("property.addDocument")}
				</Button>
				<Button onClick={onReportOpen} colorScheme="blue" width={"fit-content"} isDisabled={isArchived}>
					{t("property.addProtocol")}
				</Button>
			</Stack>

			<Stack>
				{/* <Checkbox width="fit-content" name="taxDeclaration" isChecked={additionalStepsInformation.taxDeclaration} onChange={handleChangeCheckboxes}>
					<Text>Declaratie Salubritate</Text>
				</Checkbox> */}
				<Checkbox
					width="fit-content"
					name="tenantAssociationAnnouncement"
					isChecked={additionalStepsInformation.tenantAssociationAnnouncement}
					onChange={handleChangeCheckboxes}
				>
					<Text>{t("property.tenantAssociationNotification")}</Text>
				</Checkbox>
				{/* <Checkbox name="c168" isChecked={additionalStepsInformation.c168} onChange={handleChangeCheckboxes}>
					<Text>C168 Finante</Text>
				</Checkbox> */}
				<Box>
					<Button onClick={saveCheckboxes} colorScheme="blue" isDisabled={isArchived}>
						{t("misc.save")}
					</Button>
				</Box>
			</Stack>
			<Divider />
			{renderAddDocumentModal()}
			{renderAddReportModal()}

			<Box overflowX="auto">
				<Text fontWeight={"bold"} fontSize={"large"} margin={"20px 0px 20px 0px"}>
					{t("property.documents")}{" "}
					<Tooltip label={<DocumentsTooltipLabel property={property} />}>
						<InfoOutlineIcon />
					</Tooltip>
				</Text>

				<Table variant="simple" size="sm">
					<Thead>
						<Tr pl="0px" whiteSpace={"nowrap"}>
							<Th pl="0px">{t("table.addedDate")}</Th>
							<Th>{t("table.name")}</Th>
							<Th>{t("table.type")}</Th>
							<Th>{t("table.number")}</Th>
							<Th>{t("table.startingDate")}</Th>
							<Th>{t("table.expirationDate")}</Th>
							<Th>{t("table.actions")}</Th>
						</Tr>
					</Thead>
					<Tbody>
						{documents.length !== 0 ? (
							<>
								{documents
									.filter((d) => d.active)
									.map((row, index) => {
										return <DocumentsTableRow key={index} row={row} propertyId={property.id} />;
									})}
								{documents
									.filter((d) => !d.active)
									.map((row, index) => {
										return <DocumentsTableRow key={index} row={row} propertyId={property.id} />;
									})}
							</>
						) : (
							<Tr>
								<Td>{t("table.noData")}</Td>
							</Tr>
						)}
					</Tbody>
				</Table>
			</Box>
			<Box overflowX="auto">
				<Text fontWeight={"bold"} fontSize={"large"} margin={"20px 0px 20px 0px"}>
					{t("property.protocols")}
				</Text>
				<Table variant="simple" size="sm">
					<Thead>
						<Tr my=".8rem" pl="0px">
							<Th pl="0px">{t("table.addedDate")}</Th>
							<Th>{t("table.name")}</Th>
							<Th>{t("table.type")}</Th>
							<Th>{t("table.number")}</Th>
							<Th>{t("table.actions")}</Th>
						</Tr>
					</Thead>
					<Tbody>
						{reports?.length !== 0 ? (
							reports?.map((row, index) => {
								return <ProtocolsTableRow key={index} row={row} propertyId={property.id} />;
							})
						) : (
							<Tr>
								<Td colSpan={6}>{t("table.noData")}</Td>
							</Tr>
						)}
					</Tbody>
				</Table>
			</Box>
		</Stack>
	);
}
