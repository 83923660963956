import {
	Button,
	Card,
	CardBody,
	CardHeader,
	FormControl,
	FormLabel,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Select,
	Stack,
	Tab,
	TabList,
	TabPanel,
	TabPanels,
	Table,
	Tabs,
	Tbody,
	Text,
	Th,
	Td,
	Thead,
	Tr,
	useDisclosure,
	useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { addUtilityMeterPictureVerifier, getPropertyAddress } from "../../../utils/verifier";
import { _arrayBufferToBase64 } from "../../../utils/convertors";
import { useDispatch } from "react-redux";
import { tenantAddUtilityMeterPictureAsync } from "../../../redux/user/userSlice";
import moment from "moment";
import Gallery from "../../../Components/Gallery/Gallery";
import Dropzone from "../../../Components/Dropzone/Dropzone";
import { API } from "../../../utils/http";
import UtilityMeterTableRow from "./UtilityMeterTableRow";
import { useTranslation } from "react-i18next";
export default function PropertyUtilitiesCard({ property }) {
	const { isOpen: isInvoiceOpen, onOpen: onInvoiceOpen, onClose: onInvoiceClose } = useDisclosure();
	const dispatch = useDispatch();
	const [images, setImages] = useState([]);
	const toast = useToast();
	const [error, setError] = useState({});
	const { t } = useTranslation();
	useEffect(() => {
		console.log("get media urls");
		console.log(property);
		const payload = { propertyId: property.id, media: property.utilities.meterPictureHistory };
		API.put("get-meter-picture-urls", payload).then((r) => {
			console.log(r.data);
			setImages(r.data.media);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [property.utilities.meterPictureHistory]);
	const initialUtilityInvoiceInformation = {
		propertyId: property.id,
		utilityType: "",
		picture: { documentData: "", contentType: "", documentName: "" },
	};
	const [utilityInvoiceInformation, setUtilityInvoiceInformation] = useState(initialUtilityInvoiceInformation);
	const handleUtilityInvoiceChange = (e) => {
		setUtilityInvoiceInformation({ ...utilityInvoiceInformation, [e.target.name]: e.target.value });
	};
	const onInvoiceDrop = async (acceptedFiles) => {
		console.log(acceptedFiles);
		const buffer = await acceptedFiles[0].arrayBuffer();
		const name = acceptedFiles[0].name;
		const contentType = acceptedFiles[0].type;
		const picture = { documentData: _arrayBufferToBase64(buffer), documentName: name, contentType };
		setUtilityInvoiceInformation({ ...utilityInvoiceInformation, picture });
	};
	const addUtilityInvoice = () => {
		console.log("add utility invoice");
		console.log(utilityInvoiceInformation);
		const errors = addUtilityMeterPictureVerifier(utilityInvoiceInformation);
		setError(errors);
		console.log(errors);
		if (Object.entries(errors).length !== 0) {
			toast({
				title: "Error encountered.",
				description: "Invalid information",
				status: "error",
				duration: 2000,
				isClosable: true,
			});
			return;
		}
		//TODO: call api and dispatch

		dispatch(tenantAddUtilityMeterPictureAsync(utilityInvoiceInformation))
			.then((r) => {
				if (r.error?.message)
					toast({
						title: "Error encountered",
						description: r.error.message,
						status: "error",
						duration: 9000,
						isClosable: true,
					});
				else {
					toast({
						title: "Success!",
						description: "Document Added!",
						status: "success",
						duration: 2000,
						isClosable: true,
					});
				}
			})
			.finally(() => {
				setUtilityInvoiceInformation(initialUtilityInvoiceInformation);
				onInvoiceClose();
			});
	};
	const renderAddUtilityInvoiceModal = () => (
		<Modal isOpen={isInvoiceOpen} onClose={onInvoiceClose} size={"xl"}>
			<ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
			<ModalContent>
				<ModalHeader>
					<Text>Add Meter Picture</Text>
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Stack>
						<FormControl display={"flex"} alignItems="center">
							<FormLabel display={"inline"}>Type</FormLabel>
							<Select
								onChange={handleUtilityInvoiceChange}
								width={"fit-content"}
								name="utilityType"
								placeholder="Meter type"
								value={utilityInvoiceInformation.utilityType}
								_placeholder={{ color: "gray.500" }}
								isInvalid={error?.utilityType}
							>
								<option value="electricity">Electricity</option>
								<option value="gas">Gas</option>
								<option value="water">Water</option>
							</Select>
						</FormControl>
						<FormControl>
							<FormLabel>Picture:</FormLabel>
							<Dropzone onDrop={onInvoiceDrop} accept={{ "image/*": [] }} description="Upload an Image" maxFiles={1} error={error?.picture} />
						</FormControl>
					</Stack>
				</ModalBody>
				<ModalFooter>
					<Button colorScheme="red" onClick={onInvoiceClose} mr={3}>
						Cancel
					</Button>
					<Button colorScheme="blue" onClick={addUtilityInvoice}>
						Save
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
	const renderUtilityHistoryTable = (utility) => {
		console.log(utility);
		return (
			<Table variant="simple">
				<Thead>
					<Tr my=".8rem" pl="0px">
						<Th pl="0px">Index</Th>
						<Th>Date</Th>
						<Th></Th>
					</Tr>
				</Thead>
				<Tbody>
					{utility.map((u, index) => (
						<UtilityMeterTableRow key={index} meterIndex={u.meterIndex} timestamp={u.timestamp} isCurrent={index === 0} />
					))}
					{utility.length === 0 && (
						<Tr>
							<Td colSpan={2}>{t("table.noData")}</Td>
						</Tr>
					)}
				</Tbody>
			</Table>
		);
	};
	const renderUtilityPictureHistory = () => {
		return (
			<Stack>
				<Gallery items={images} />
			</Stack>
		);
	};
	return (
		<Card>
			<CardHeader>
				<Stack>
					<Text fontSize="xl">Address: {getPropertyAddress(property)}</Text>
					<Button colorScheme="blue" width="fit-content" onClick={onInvoiceOpen}>
						Add Meter Picture
					</Button>
					{renderAddUtilityInvoiceModal()}
				</Stack>
			</CardHeader>
			<CardBody>
				<Tabs variant="enclosed">
					<TabList>
						<Tab>{t("types.gas")}</Tab>
						<Tab>{t("types.electricity")}</Tab>
						<Tab>{t("types.water")}</Tab>
						<Tab>{t("types.meterPictures")}</Tab>
					</TabList>
					<TabPanels>
						<TabPanel>
							{renderUtilityHistoryTable([...property.utilities.gas.indexes].sort((a, b) => moment(b.timestamp).unix() - moment(a.timestamp).unix()))}
						</TabPanel>
						<TabPanel>
							{renderUtilityHistoryTable([...property.utilities.electricity.indexes].sort((a, b) => moment(b.timestamp).unix() - moment(a.timestamp).unix()))}
						</TabPanel>
						<TabPanel>
							{renderUtilityHistoryTable([...property.utilities.water.indexes].sort((a, b) => moment(b.timestamp).unix() - moment(a.timestamp).unix()))}
						</TabPanel>
						<TabPanel>{renderUtilityPictureHistory(property.utilities.meterPictureHistory)}</TabPanel>
					</TabPanels>
				</Tabs>
			</CardBody>
		</Card>
	);
}
