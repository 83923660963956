import React, { useEffect } from "react";
import {
	Popover,
	PopoverTrigger,
	PopoverContent,
	PopoverHeader,
	PopoverBody,
	PopoverFooter,
	PopoverArrow,
	PopoverCloseButton,
	PopoverAnchor,
	Button,
	Stack,
	Text,
	Box,
	Wrap,
	WrapItem,
	useToast,
	Divider,
} from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { removeContactAsync } from "../../redux/user/userSlice";

export default function ContactPopover({ contact, property }) {
	const dispatch = useDispatch();
	const toast = useToast();
	const handleContactDelete = (contact) => {
		const payload = { ...contact, propertyId: property.id };
		console.log(contact);
		dispatch(removeContactAsync(payload)).then((r) => {
			if (r.error?.message)
				toast({
					title: "Error encountered",
					description: r.error.message,
					status: "error",
					duration: 9000,
					isClosable: true,
				});
			else {
				toast({
					title: "Success!",
					description: "Contact Removed!",
					status: "success",
					duration: 2000,
					isClosable: true,
				});
			}
		});
	};
	return (
		<Popover>
			<PopoverTrigger>
				<Button width={"fit-content"} size={"sm"} textTransform={"uppercase"}>
					{contact.name}
				</Button>
			</PopoverTrigger>
			<PopoverContent>
				<PopoverArrow />
				<PopoverCloseButton />
				<PopoverHeader>Contact information</PopoverHeader>
				<PopoverBody>
					<Stack style={{ whiteSpace: "normal" }}>
						<Box>
							<Text as={"span"} fontWeight={"bold"}>
								Type:
							</Text>{" "}
							{contact.contactType}
						</Box>
						<Box>
							<Text as={"span"} fontWeight={"bold"}>
								Name:
							</Text>{" "}
							{contact.name}
						</Box>
						<Box>
							<Text as={"span"} fontWeight={"bold"}>
								Email:
							</Text>{" "}
							{contact.email}
						</Box>
						<Box>
							<Text as={"span"} fontWeight={"bold"}>
								Phone Number:
							</Text>{" "}
							{contact.phoneNumber}
						</Box>
						<Box>
							<Text as={"span"} fontWeight={"bold"}>
								Alternative Phone Number:
							</Text>{" "}
							{contact.phoneNumber2 || "-"}
						</Box>
						<Divider />
					</Stack>
				</PopoverBody>
				<PopoverFooter border="0" display="flex" alignItems="center" justifyContent="space-between" pb={4}>
					<Button
						colorScheme="red"
						onClick={() => {
							handleContactDelete(contact);
						}}
					>
						Delete
					</Button>
				</PopoverFooter>
			</PopoverContent>
		</Popover>
	);
}
