import { useEffect, useState } from "react";

export default function usePagination(totalItems, itemsPerPage) {
	const [currentPage, setCurrentPage] = useState(1);
	const totalPages = Math.ceil(totalItems / itemsPerPage);

	const handlePageChange = (newPage) => {
		setCurrentPage(newPage);
	};
	useEffect(() => {
		setCurrentPage(1);
	}, [totalItems]);

	return { currentPage, totalPages, handlePageChange };
}
