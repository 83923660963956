import React from "react";
import { List, ListItem, ListIcon } from "@chakra-ui/react";

import { MdCheckCircle, MdSettings } from "react-icons/md";
import { IoMdCloseCircle } from "react-icons/io";

export default function StatementStatusTooltipLabel({ statementStatus }) {
	const getListIcon = (status) => {
		switch (status) {
			case "warning":
				return <ListIcon as={MdSettings} color="yellow.500" />;
			case "complete":
				return <ListIcon as={MdCheckCircle} color="green.500" />;
			case "incomplete":
				return <ListIcon as={IoMdCloseCircle} color="red.500" />;
			case "expired":
				return <ListIcon as={IoMdCloseCircle} color="red.500" />;
			default:
				return <ListIcon as={IoMdCloseCircle} color="red.500" />;
		}
	};
	return (
		<List spacing={3}>
			<ListItem>
				{getListIcon(statementStatus.c168)}
				C168
			</ListItem>
			<ListItem>
				{getListIcon(statementStatus.statement)}
				Declaratie
			</ListItem>
		</List>
	);
}
