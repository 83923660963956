import { DownloadIcon, HamburgerIcon } from "@chakra-ui/icons";
import {
	Button,
	Icon,
	Text,
	Badge,
	Tr,
	Td,
	UnorderedList,
	ListItem,
	useDisclosure,
	Stack,
	FormControl,
	FormLabel,
	Input,
	Select,
	useToast,
	Textarea,
} from "@chakra-ui/react";
import React from "react";
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton } from "@chakra-ui/react";
import { Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import moment from "moment/moment";
import Dropzone from "../Dropzone/Dropzone";
import { useState } from "react";
import { _arrayBufferToBase64 } from "../../utils/convertors";
import { API } from "../../utils/http";
import { useDispatch } from "react-redux";
import { removeSecurityDepositAsync, updateSecurityDepositAsync } from "../../redux/user/userSlice";
import { addSecurityDepositVerifier } from "../../utils/verifier";
import { useTranslation } from "react-i18next";
export default function SecurityDepositRow(props) {
	const { addedDate, updatedDate, amount, location, custodian, description, id, currency, propertyId, documents } = props;
	const { isOpen: isMarkAsPaidOpen, onOpen: onMarkAsPaidOpen, onClose: onMarkAsPaidClose } = useDisclosure();
	const { isOpen: isDeleteConfirmationOpen, onOpen: onDeleteConfirmationOpen, onClose: onDeleteConfirmationClose } = useDisclosure();
	const [error, setError] = useState({});
	const toast = useToast();
	const dispatch = useDispatch();
	const initialMarkAsPaidInformation = {
		location: location,
		custodian: custodian,
		description: description,
		propertyId: propertyId,
		securityDepositId: id,
		documents: [],
	};
	const { t } = useTranslation();
	const [markAsPaidInformation, setMarkAsPaidInformation] = useState(initialMarkAsPaidInformation);
	const handleChangeMarkAsPaidInformation = (e) => {
		setMarkAsPaidInformation({ ...markAsPaidInformation, [e.target.name]: e.target.value });
	};
	const onDocumentDrop = async (acceptedFiles) => {
		console.log(acceptedFiles);
		let documents = [];
		for (const file of acceptedFiles) {
			const buffer = await file.arrayBuffer();
			const documentName = file.name;
			const contentType = file.type;
			documents.push({ documentData: _arrayBufferToBase64(buffer), documentName, contentType });
		}

		setMarkAsPaidInformation({ ...markAsPaidInformation, documents });
	};

	const handleSaveMarkAsPaid = () => {
		console.log(markAsPaidInformation);
		const errors = addSecurityDepositVerifier(markAsPaidInformation);
		console.log(errors);
		setError(errors);
		if (Object.keys(errors).length > 0) {
			toast({
				title: "Error encountered",
				description: "Please fill in all the required fields",
				status: "error",
				duration: 9000,
				isClosable: true,
			});
			return;
		}
		dispatch(updateSecurityDepositAsync(markAsPaidInformation))
			.then((r) => {
				if (r.error?.message)
					toast({
						title: "Error encountered",
						description: r.error.message,
						status: "error",
						duration: 9000,
						isClosable: true,
					});
				else {
					toast({
						title: "Success!",
						description: "Security Deposit updated!",
						status: "success",
						duration: 2000,
						isClosable: true,
					});
				}
			})
			.finally(() => {
				// setMarkAsPaidInformation(initialMarkAsPaidInformation);
				onMarkAsPaidClose();
			});
	};

	const handleGetDocument = (documentId, documentName) => {
		const payload = { propertyId, documentId, securityDepositId: id, documentName };
		console.log(payload);
		API.put("get-security-deposit-document", payload)
			.then((r) => {
				console.log(r);
				window.open(r.data.url, "_blank");
			})
			.catch((e) => {
				console.log(e);
				toast({
					title: "Error encountered",
					description: e.response.data.error.message,
					status: "error",
					duration: 9000,
					isClosable: true,
				});
			});
	};
	const handleDeletePayment = () => {
		const payload = { propertyId, securityDepositId: id };
		console.log(payload);
		onDeleteConfirmationClose();
		dispatch(removeSecurityDepositAsync(payload));
	};
	const getLocationColorScheme = (location) => {
		if (location === "custodyBank") return "yellow";
		if (location === "custodyCash") return "green";
		if (location === "returned") return "red";
		if (location === "owner") return "blue";
	};
	const renderMarkAsPaidModal = () => {
		return (
			<Modal isOpen={isMarkAsPaidOpen} onClose={onMarkAsPaidClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Details</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Stack>
							<FormControl>
								<FormLabel display={"inline"}>Custodian</FormLabel>
								<Input
									isInvalid={error?.custodian}
									width={"fit-content"}
									type="text"
									name="custodian"
									placeholder="Custodian"
									_placeholder={{ color: "gray.500" }}
									value={markAsPaidInformation.custodian}
									onChange={handleChangeMarkAsPaidInformation}
								/>
							</FormControl>
							<FormControl>
								<FormLabel display={"inline"}>Description</FormLabel>
								<Textarea
									isInvalid={error?.description}
									width={"fit-content"}
									name="description"
									placeholder="Description"
									_placeholder={{ color: "gray.500" }}
									value={markAsPaidInformation.description}
									onChange={handleChangeMarkAsPaidInformation}
								/>
							</FormControl>
							<FormControl display={"flex"} alignItems="center">
								<FormLabel display={"inline"}>Location:</FormLabel>
								<Select
									width={"fit-content"}
									onChange={handleChangeMarkAsPaidInformation}
									name="location"
									_placeholder={{ color: "gray.500" }}
									value={markAsPaidInformation.location}
								>
									<option value="custodyBank">Custody Bank</option>
									<option value="custodyCash">Custody Cash</option>
									<option value="returned">Returned to Owner</option>
									<option value="returnedTenant">Returned to Tenant</option>
									<option value="owner">Owner</option>
								</Select>
							</FormControl>

							<UnorderedList>
								{documents?.map((d, index) => (
									<ListItem key={index}>
										<Text
											color="teal.500"
											textDecorationLine={"underline"}
											cursor={"pointer"}
											onClick={() => {
												handleGetDocument(d.id, d.documentName);
											}}
										>
											{d.documentName}
											<DownloadIcon marginLeft={2} />
										</Text>
									</ListItem> //TODO: make documentName clickable and download the clicked document
								))}
							</UnorderedList>
							<FormControl>
								<FormLabel display={"inline"}>Add document:</FormLabel>
								<Dropzone
									onDrop={onDocumentDrop}
									accept={{
										"application/pdf": [".pdf"],
										"application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
										"application/msword": [".doc"],
									}}
									description="Upload PDF,DOC or DOCX Format"
								/>
							</FormControl>
						</Stack>
					</ModalBody>

					<ModalFooter>
						<Button colorScheme="red" mr={3} onClick={onMarkAsPaidClose}>
							Close
						</Button>
						<Button colorScheme="blue" mr={3} onClick={handleSaveMarkAsPaid}>
							{t("misc.save")}
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		);
	};
	const renderDeleteConfirmationModal = () => {
		return (
			<Modal isOpen={isDeleteConfirmationOpen} onClose={onDeleteConfirmationClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Confirm delete?</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Text>Are you sure you want to delete this security deposit? </Text>
						<Text>
							This action{" "}
							<Text as="span" fontWeight={"bold"}>
								cannot
							</Text>{" "}
							be undone!{" "}
						</Text>
					</ModalBody>

					<ModalFooter>
						<Button colorScheme="red" mr={3} onClick={handleDeletePayment}>
							Delete
						</Button>
						<Button colorScheme="blue" mr={3} onClick={onDeleteConfirmationClose}>
							Close
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		);
	};
	return (
		<Tr>
			<Td>
				<Text fontSize="md" minWidth="100%">
					{moment(addedDate).format("DD MMM YYYY - HH:mm")}
				</Text>
			</Td>
			<Td>
				<Badge fontSize="md" colorScheme={getLocationColorScheme(location)} textTransform={"none"}>
					{t(`types.${location}`)}
				</Badge>
			</Td>
			<Td>
				<Text fontSize="md">
					{amount} {currency}
				</Text>
			</Td>
			<Td>
				<Text fontSize="md">{custodian}</Text>
			</Td>
			<Td>
				<Text fontSize="md">{description}</Text>
			</Td>

			<Td>
				<Text fontSize="md">{moment(updatedDate).format("DD MMM YYYY - HH:mm")}</Text>
			</Td>
			<Td>
				<Menu closeOnSelect={false}>
					<MenuButton>
						<Icon as={HamburgerIcon} />
					</MenuButton>
					<MenuList>
						<MenuItem onClick={onMarkAsPaidOpen}>Change Status</MenuItem>
						<MenuItem onClick={onDeleteConfirmationOpen}>Delete</MenuItem>
					</MenuList>
				</Menu>
				{renderMarkAsPaidModal()}
				{renderDeleteConfirmationModal()}
			</Td>
		</Tr>
	);
}
