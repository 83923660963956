const awsConfigDev = {
	aws_cognito_region: "eu-central-1",
	aws_user_pools_id: "eu-central-1_sdUGlXGu7",
	aws_user_pools_web_client_id: "7aftb1ecupfgvfnt1t4unvfl61",
};
const awsConfigProd = {
	aws_cognito_region: "eu-central-1",
	aws_user_pools_id: "eu-central-1_qdfJS2x7I",
	aws_user_pools_web_client_id: "436l37q6tji0jf7ii20f3hujhl",
};
const devConfig = { apiUrl: "http://localhost:5001/api", awsConfig: awsConfigDev, appUrl: "http://localhost:3000" };
const prodConfig = { apiUrl: "https://app.e-rentit.ro/api", awsConfig: awsConfigProd, appUrl: "https://app.e-rentit.ro" };

console.log(process.env.REACT_APP_environment);

const config = process.env.REACT_APP_environment === "DEV" ? devConfig : prodConfig;

export { config };
