import React from "react";
import ReportsTableRow from "./ReportsTableRow";
import DocumentsTableRow from "./DocumentsTableRow";
import { Box, Card, CardBody, CardHeader, Stack, Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { selectActiveProperties } from "../../../redux/user/userSlice";
import PropertyDocumentsCard from "./PropertyDocumentsCard";
import { getPropertyAddress } from "../../../utils/verifier";
import { useTranslation } from "react-i18next";

export default function Documents() {
	const properties = useSelector(selectActiveProperties);
	const { t } = useTranslation();
	return (
		<Stack>
			<Card>
				<CardHeader>
					<Text fontSize="xl" fontWeight="bold">
						{t("navbarMenu.documents")}
					</Text>
				</CardHeader>
			</Card>
			{properties.map((property) => (
				<PropertyDocumentsCard documents={property.documents} reports={property.reports} address={getPropertyAddress(property)} propertyId={property.id} />
			))}
		</Stack>
	);
}
