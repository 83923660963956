import {
	Box,
	Grid,
	GridItem,
	Text,
	Card,
	CardHeader,
	CardBody,
	Stack,
	Input,
	Flex,
	Checkbox,
	Button,
	useToast,
	Select,
	RadioGroup,
	Radio,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateAdditionalStepsAsync, updateSpecsAsync } from "../../redux/user/userSlice";
function EquipmentCheckbox({ name, isEditing, value, label, onChange, category }) {
	return (
		<Checkbox
			isChecked={value}
			disabled={!isEditing}
			name={name}
			size="sm"
			onChange={(e) => {
				onChange(e, category);
			}}
		>
			<Text fontSize={"small"}>{label}</Text>
		</Checkbox>
	);
}
export default function SpecsTab({ property }) {
	const isArchived = property.archived;
	const [isEditing, setIsEditing] = useState(false);
	const dispatch = useDispatch();
	const toast = useToast();
	const apartmentFeatures = property.specs.apartmentFeatures || {};
	const propertyFeatures = property.specs.propertyFeatures || {};
	const rooms = property.specs.rooms || {};
	const equipment = property.specs.equipment || {};
	const [specsInformation, setSpecsInformation] = useState({ apartmentFeatures, propertyFeatures, rooms, equipment });
	const handleCheckboxChange = (e, category) => {
		setSpecsInformation({
			...specsInformation,
			equipment: { ...specsInformation.equipment, [category]: { ...specsInformation.equipment[category], [e.target.name]: e.target.checked } },
		});
	};
	const handleSpecsInfoChange = (e, category) => {
		setSpecsInformation({
			...specsInformation,
			[category]: { ...specsInformation[category], [e.target.name]: e.target.value },
		});
	};

	const handleEditButtonClick = () => {
		if (isEditing) {
			console.log("Save new data");
			const payload = { ...specsInformation, propertyId: property.id };
			console.log(payload);
			dispatch(updateSpecsAsync(payload)).then((r) => {
				if (r.error?.message)
					toast({
						title: "Error encountered",
						description: r.error.message,
						status: "error",
						duration: 9000,
						isClosable: true,
					});
				else {
					toast({
						title: "Success!",
						description: "Update successful",
						status: "success",
						duration: 2000,
						isClosable: true,
					});
				}
			});
		} else {
			console.log("Toggle edit button");
		}
		setIsEditing(!isEditing);
	};
	return (
		<Stack>
			<Box>
				<Button colorScheme={"blue"} onClick={handleEditButtonClick} isDisabled={isArchived}>
					{isEditing ? "Save" : "Edit"}
				</Button>
			</Box>
			<Grid templateColumns={{ sm: "1fr", md: "repeat(3,1fr)" }} gap="24px">
				<GridItem rowSpan={1}>
					<Card>
						<CardHeader>
							<Text fontWeight="bold">Apartment Features</Text>
						</CardHeader>
						<CardBody>
							<Stack fontSize={"small"}>
								<Grid templateColumns={"1fr 1fr"} alignItems="center">
									<GridItem textAlign="right">
										<Text as="span" mr={2} fontStyle="italic">
											Type:
										</Text>
									</GridItem>
									<GridItem>
										{isEditing ? (
											<Select
												size="xs"
												value={specsInformation.apartmentFeatures.type}
												name="type"
												onChange={(e) => {
													handleSpecsInfoChange(e, "apartmentFeatures");
												}}
											>
												<option value="apartment">Apartament</option>
												<option value="house">Casa</option>
												<option value="space">Spatiu</option>
												<option value="garage">Garaj</option>
												<option value="hall">Hala</option>
												<option value="other">Altele</option>
											</Select>
										) : (
											<Text>{specsInformation.apartmentFeatures.type || "-"}</Text>
										)}
									</GridItem>
								</Grid>
								<Grid templateColumns={"1fr 1fr"} alignItems="center">
									<GridItem textAlign="right">
										<Text as="span" mr={2} fontStyle="italic">
											Water:
										</Text>
									</GridItem>
									<GridItem>
										{isEditing ? (
											<RadioGroup
												size={"sm"}
												onChange={(newValue) => {
													setSpecsInformation({
														...specsInformation,
														apartmentFeatures: { ...specsInformation.apartmentFeatures, water: newValue },
													});
												}}
												value={specsInformation.apartmentFeatures.water}
											>
												<Stack direction="column">
													<Radio value="waterContract">Contract de apa</Radio>
													<Radio value="association">Asociatie/Administrator</Radio>
												</Stack>
											</RadioGroup>
										) : (
											<Text>{specsInformation.apartmentFeatures.water || "-"}</Text>
										)}
									</GridItem>
								</Grid>
								<Grid templateColumns={"1fr 1fr"} alignItems="center">
									<GridItem textAlign="right">
										<Text as="span" mr={2} fontStyle="italic">
											Availability:
										</Text>
									</GridItem>
									<GridItem>
										{isEditing ? (
											<Input
												size="xs"
												value={specsInformation.apartmentFeatures.availability}
												onChange={(e) => {
													handleSpecsInfoChange(e, "apartmentFeatures");
												}}
												name="availability"
											/>
										) : (
											<Text>{specsInformation.apartmentFeatures.availability || "-"}</Text>
										)}
									</GridItem>
								</Grid>
								<Grid templateColumns={"1fr 1fr"} alignItems="center">
									<GridItem textAlign="right">
										<Text as="span" mr={2} fontStyle="italic">
											Partitioning:
										</Text>
									</GridItem>
									<GridItem>
										{isEditing ? (
											<Input
												size="xs"
												value={specsInformation.apartmentFeatures.partitioning}
												onChange={(e) => {
													handleSpecsInfoChange(e, "apartmentFeatures");
												}}
												name="partitioning"
											/>
										) : (
											<Text>{specsInformation.apartmentFeatures.partitioning || "-"}</Text>
										)}
									</GridItem>
								</Grid>
								<Grid templateColumns={"1fr 1fr"} alignItems="center">
									<GridItem textAlign="right">
										<Text as="span" mr={2} fontStyle="italic">
											Floor:
										</Text>
									</GridItem>
									<GridItem>
										{isEditing ? (
											<Input
												size="xs"
												value={specsInformation.apartmentFeatures.floor}
												onChange={(e) => {
													handleSpecsInfoChange(e, "apartmentFeatures");
												}}
												name="floor"
											/>
										) : (
											<Text>{specsInformation.apartmentFeatures.floor || "-"}</Text>
										)}
									</GridItem>
								</Grid>
								<Grid templateColumns={"1fr 1fr"} alignItems="center">
									<GridItem textAlign="right">
										<Text as="span" mr={2} fontStyle="italic">
											Comfort:
										</Text>
									</GridItem>
									<GridItem>
										{isEditing ? (
											<Input
												size="xs"
												value={specsInformation.apartmentFeatures.comfort}
												onChange={(e) => {
													handleSpecsInfoChange(e, "apartmentFeatures");
												}}
												name="comfort"
											/>
										) : (
											<Text>{specsInformation.apartmentFeatures.comfort || "-"}</Text>
										)}
									</GridItem>
								</Grid>
								<Grid templateColumns={"1fr 1fr"} alignItems="center">
									<GridItem textAlign="right">
										<Text as="span" mr={2} fontStyle="italic">
											Interior State:
										</Text>
									</GridItem>
									<GridItem>
										{isEditing ? (
											<Input
												size="xs"
												value={specsInformation.apartmentFeatures.interiorState}
												onChange={(e) => {
													handleSpecsInfoChange(e, "apartmentFeatures");
												}}
												name="interiorState"
											/>
										) : (
											<Text>{specsInformation.apartmentFeatures.interiorState || "-"}</Text>
										)}
									</GridItem>
								</Grid>
								<Grid templateColumns={"1fr 1fr"} alignItems="center">
									<GridItem textAlign="right">
										<Text as="span" mr={2} fontStyle="italic">
											Useful Surface:
										</Text>
									</GridItem>
									<GridItem>
										{isEditing ? (
											<Input
												size="xs"
												value={specsInformation.apartmentFeatures.usefulSurface}
												onChange={(e) => {
													handleSpecsInfoChange(e, "apartmentFeatures");
												}}
												name="usefulSurface"
											/>
										) : (
											<Text>{specsInformation.apartmentFeatures.usefulSurface || "-"}</Text>
										)}
									</GridItem>
								</Grid>
								<Grid templateColumns={"1fr 1fr"} alignItems="center">
									<GridItem textAlign="right">
										<Text as="span" mr={2} fontStyle="italic">
											Built Surface:
										</Text>
									</GridItem>
									<GridItem>
										{isEditing ? (
											<Input
												size="xs"
												value={specsInformation.apartmentFeatures.builtSurface}
												onChange={(e) => {
													handleSpecsInfoChange(e, "apartmentFeatures");
												}}
												name="builtSurface"
											/>
										) : (
											<Text>{specsInformation.apartmentFeatures.builtSurface || "-"}</Text>
										)}
									</GridItem>
								</Grid>
								<Grid templateColumns={"1fr 1fr"} alignItems="center">
									<GridItem textAlign="right">
										<Text as="span" mr={2} fontStyle="italic">
											Parking:
										</Text>
									</GridItem>
									<GridItem>
										{isEditing ? (
											<Checkbox
												value={specsInformation.apartmentFeatures.parking}
												onChange={(e) => {
													setSpecsInformation({
														...specsInformation,
														apartmentFeatures: {
															...specsInformation.apartmentFeatures,
															parking: e.target.checked,
														},
													});
												}}
												name="parking"
											/>
										) : (
											<Text>{specsInformation.apartmentFeatures.parking ? "Yes" : "No"}</Text>
										)}
									</GridItem>
								</Grid>
							</Stack>
						</CardBody>
					</Card>
				</GridItem>
				<GridItem rowSpan={{ sm: "1", lg: "3" }} colSpan={{ sm: "1", lg: "2" }}>
					<Card height={"100%"}>
						<CardHeader>
							<Text fontWeight="bold">Equipment</Text>
						</CardHeader>
						<CardBody>
							<Flex direction={"column"} flexWrap="wrap" overflowX="auto" height={"700px"} gap="24px">
								{/* TODO: modificat conditia */}
								<Stack direction={"column"} hidden={!Object.values(equipment.features).includes(true) && !isEditing}>
									<Text>Equipment</Text>
									<EquipmentCheckbox
										category="features"
										name="airConditioning"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Air Conditioning"
										value={specsInformation.equipment.features.airConditioning}
									/>
									<EquipmentCheckbox
										category="features"
										name="alarmSystem"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Alarm System"
										value={specsInformation.equipment.features.alarmSystem}
									/>
									<EquipmentCheckbox
										category="features"
										name="bathtub"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Bathtub"
										value={specsInformation.equipment.features.bathtub}
									/>
									<EquipmentCheckbox
										category="features"
										name="bidet"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Bidet"
										value={specsInformation.equipment.features.bidet}
									/>
									<EquipmentCheckbox
										category="features"
										name="dishwasher"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Dishwasher"
										value={specsInformation.equipment.features.dishwasher}
									/>
									<EquipmentCheckbox
										category="features"
										name="dressing"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Dressing"
										value={specsInformation.equipment.features.dressing}
									/>
									<EquipmentCheckbox
										category="features"
										name="externalShower"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="External Shower"
										value={specsInformation.equipment.features.externalShower}
									/>
									<EquipmentCheckbox
										category="features"
										name="fireplace"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Fireplace"
										value={specsInformation.equipment.features.fireplace}
									/>
									<EquipmentCheckbox
										category="features"
										name="gasCooker"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Gas Cooker"
										value={specsInformation.equipment.features.gasCooker}
									/>
									<EquipmentCheckbox
										category="features"
										name="heater"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Heater"
										value={specsInformation.equipment.features.heater}
									/>
									<EquipmentCheckbox
										category="features"
										name="hood"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Hood"
										value={specsInformation.equipment.features.hood}
									/>
									<EquipmentCheckbox
										category="features"
										name="interiorStairs"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Interior Stairs"
										value={specsInformation.equipment.features.interiorStairs}
									/>
									<EquipmentCheckbox
										category="features"
										name="jacuzzi"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Jacuzzi"
										value={specsInformation.equipment.features.jacuzzi}
									/>
									<EquipmentCheckbox
										category="features"
										name="refrigerator"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Refrigerator"
										value={specsInformation.equipment.features.refrigerator}
									/>
									<EquipmentCheckbox
										category="features"
										name="showerCabin"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Shower Cabin"
										value={specsInformation.equipment.features.showerCabin}
									/>
									<EquipmentCheckbox
										category="features"
										name="tv"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="TV"
										value={specsInformation.equipment.features.tv}
									/>
									<EquipmentCheckbox
										category="features"
										name="washingMachine"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Washing Machine"
										value={specsInformation.equipment.features.washingMachine}
									/>
								</Stack>
								<Stack direction={"column"} hidden={!Object.values(equipment.streetFeatures).includes(true) && !isEditing}>
									<Text>Street Features</Text>
									<EquipmentCheckbox
										category="streetFeatures"
										name="cobbledStreet"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Cobbled Street"
										value={specsInformation.equipment.streetFeatures.cobbledStreet}
									/>
									<EquipmentCheckbox
										category="streetFeatures"
										name="concreteStreet"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Concrete Street"
										value={specsInformation.equipment.streetFeatures.concreteStreet}
									/>
									<EquipmentCheckbox
										category="streetFeatures"
										name="dirtRoad"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Dirt Road"
										value={specsInformation.equipment.streetFeatures.dirtRoad}
									/>
									<EquipmentCheckbox
										category="streetFeatures"
										name="pavedStreet"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Paved Street"
										value={specsInformation.equipment.streetFeatures.pavedStreet}
									/>
									<EquipmentCheckbox
										category="streetFeatures"
										name="publicTransport"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Public Transport"
										value={specsInformation.equipment.streetFeatures.publicTransport}
									/>
									<EquipmentCheckbox
										category="streetFeatures"
										name="streetLighting"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Street Lighting"
										value={specsInformation.equipment.streetFeatures.streetLighting}
									/>
									<EquipmentCheckbox
										category="streetFeatures"
										name="unequippedStreet"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Unequipped Street"
										value={specsInformation.equipment.streetFeatures.unequippedStreet}
									/>
								</Stack>
								<Stack direction={"column"} hidden={!Object.values(equipment.generalFeatures).includes(true) && !isEditing}>
									<Text>General Features</Text>
									<EquipmentCheckbox
										category="generalFeatures"
										name="CATV"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="CATV"
										value={specsInformation.equipment.generalFeatures.CATV}
									/>
									<EquipmentCheckbox
										category="generalFeatures"
										name="electricity"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Electricity"
										value={specsInformation.equipment.generalFeatures.electricity}
									/>
									<EquipmentCheckbox
										category="generalFeatures"
										name="gas"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Gas"
										value={specsInformation.equipment.generalFeatures.gas}
									/>
									<EquipmentCheckbox
										category="generalFeatures"
										name="internet"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Internet"
										value={specsInformation.equipment.generalFeatures.internet}
									/>
									<EquipmentCheckbox
										category="generalFeatures"
										name="phone"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Phone"
										value={specsInformation.equipment.generalFeatures.phone}
									/>
									<EquipmentCheckbox
										category="generalFeatures"
										name="septicTank"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Septic Tank"
										value={specsInformation.equipment.generalFeatures.septicTank}
									/>
									<EquipmentCheckbox
										category="generalFeatures"
										name="sewage"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Sewage"
										value={specsInformation.equipment.generalFeatures.sewage}
									/>
									<EquipmentCheckbox
										category="generalFeatures"
										name="solarPanels"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Solar Panels"
										value={specsInformation.equipment.generalFeatures.solarPanels}
									/>
									<EquipmentCheckbox
										category="generalFeatures"
										name="threePhaseCurrent"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Three Phase Current"
										value={specsInformation.equipment.generalFeatures.threePhaseCurrent}
									/>
									<EquipmentCheckbox
										category="generalFeatures"
										name="water"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Water"
										value={specsInformation.equipment.generalFeatures.water}
									/>
								</Stack>
								<Stack direction={"column"} hidden={!Object.values(equipment.thermalInsulation).includes(true) && !isEditing}>
									<Text>Thermal Insulation</Text>
									<EquipmentCheckbox
										category="thermalInsulation"
										name="externalInsulation"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="External Insulation"
										value={specsInformation.equipment.thermalInsulation.externalInsulation}
									/>
									<EquipmentCheckbox
										category="thermalInsulation"
										name="internalInsulation"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Internal Insulation"
										value={specsInformation.equipment.thermalInsulation.internalInsulation}
									/>
								</Stack>
								<Stack direction={"column"} hidden={!Object.values(specsInformation.equipment.buildingFeatures).includes(true) && !isEditing}>
									<Text>Building Features</Text>
									<EquipmentCheckbox
										category="buildingFeatures"
										name="bicycleParking"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Bicycle Parking"
										value={specsInformation.equipment.buildingFeatures.bicycleParking}
									/>
									<EquipmentCheckbox
										category="buildingFeatures"
										name="carGateRemoteControl"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Car Gate Remote Control"
										value={specsInformation.equipment.buildingFeatures.carGateRemoteControl}
									/>
									<EquipmentCheckbox
										category="buildingFeatures"
										name="communalPool"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Communal Pool"
										value={specsInformation.equipment.buildingFeatures.communalPool}
									/>
									<EquipmentCheckbox
										category="buildingFeatures"
										name="dryer"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Dryer"
										value={specsInformation.equipment.buildingFeatures.dryer}
									/>
									<EquipmentCheckbox
										category="buildingFeatures"
										name="externalPool"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="External Pool"
										value={specsInformation.equipment.buildingFeatures.externalPool}
									/>
									<EquipmentCheckbox
										category="buildingFeatures"
										name="fitness"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Fitness"
										value={specsInformation.equipment.buildingFeatures.fitness}
									/>
									<EquipmentCheckbox
										category="buildingFeatures"
										name="guard"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Guard"
										value={specsInformation.equipment.buildingFeatures.guard}
									/>
									<EquipmentCheckbox
										category="buildingFeatures"
										name="handicappedAccess"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Handicapped Access"
										value={specsInformation.equipment.buildingFeatures.handicappedAccess}
									/>
									<EquipmentCheckbox
										category="buildingFeatures"
										name="internalPool"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Internal Pool"
										value={specsInformation.equipment.buildingFeatures.internalPool}
									/>
									<EquipmentCheckbox
										category="buildingFeatures"
										name="interphone"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Interphone"
										value={specsInformation.equipment.buildingFeatures.interphone}
									/>
									<EquipmentCheckbox
										category="buildingFeatures"
										name="laundry"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Laundry"
										value={specsInformation.equipment.buildingFeatures.laundry}
									/>
									<EquipmentCheckbox
										category="buildingFeatures"
										name="leisureSpaces"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Leisure Spaces"
										value={specsInformation.equipment.buildingFeatures.leisureSpaces}
									/>
									<EquipmentCheckbox
										category="buildingFeatures"
										name="permanentGuard"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Permanent Guard"
										value={specsInformation.equipment.buildingFeatures.permanentGuard}
									/>
									<EquipmentCheckbox
										category="buildingFeatures"
										name="privatePool"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Private Pool"
										value={specsInformation.equipment.buildingFeatures.privatePool}
									/>
									<EquipmentCheckbox
										category="buildingFeatures"
										name="roof"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Roof"
										value={specsInformation.equipment.buildingFeatures.roof}
									/>
									<EquipmentCheckbox
										category="buildingFeatures"
										name="smokeSensor"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Smoke Sensor"
										value={specsInformation.equipment.buildingFeatures.smokeSensor}
									/>
									<EquipmentCheckbox
										category="buildingFeatures"
										name="spa"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Spa"
										value={specsInformation.equipment.buildingFeatures.spa}
									/>
									<EquipmentCheckbox
										category="buildingFeatures"
										name="steamRoom"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Steam Room"
										value={specsInformation.equipment.buildingFeatures.steamRoom}
									/>
									<EquipmentCheckbox
										category="buildingFeatures"
										name="videoInterphone"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Video Interphone"
										value={specsInformation.equipment.buildingFeatures.videoInterphone}
									/>
									<EquipmentCheckbox
										category="buildingFeatures"
										name="videoSurveillance"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Video Surveillance"
										value={specsInformation.equipment.buildingFeatures.videoSurveillance}
									/>
									<EquipmentCheckbox
										category="buildingFeatures"
										name="wireless"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Wireless"
										value={specsInformation.equipment.buildingFeatures.wireless}
									/>
								</Stack>
								<Stack direction={"column"} hidden={!Object.values(equipment.parking).includes(true) && !isEditing}>
									<Text>Parking</Text>
									<EquipmentCheckbox
										category="parking"
										name="coveredParking"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Covered Parking"
										value={specsInformation.equipment.parking.coveredParking}
									/>
									<EquipmentCheckbox
										category="parking"
										name="garage"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Garage"
										value={specsInformation.equipment.parking.garage}
									/>
									<EquipmentCheckbox
										category="parking"
										name="openParking"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Open Parking"
										value={specsInformation.equipment.parking.openParking}
									/>
									<EquipmentCheckbox
										category="parking"
										name="undergroundGarake"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Underground Garage"
										value={specsInformation.equipment.parking.undergroundGarake}
									/>
								</Stack>
								<Stack
									direction={"column"}
									hidden={
										!Object.values(specsInformation.equipment.heatingSystem).includes(true) &&
										!isEditing &&
										!specsInformation.equipment.heatingSystem.centralHeatingType
									}
								>
									<Text>Heating System</Text>
									<Stack direction="row">
										<Text as="span" fontStyle="italic">
											Type:
										</Text>
										{isEditing ? (
											<Input
												size="xs"
												value={specsInformation.equipment.heatingSystem.centralHeatingType}
												onChange={(e) => {
													// handleSpecsInfoChange(e, "apartmentFeatures");
													setSpecsInformation({
														...specsInformation,
														equipment: {
															...specsInformation.equipment,
															heatingSystem: { ...specsInformation.equipment.heatingSystem, centralHeatingType: e.target.value },
														},
													});
												}}
												name="centralHeatingType"
											/>
										) : (
											<Text>{specsInformation.equipment.heatingSystem.centralHeatingType || "-"}</Text>
										)}
									</Stack>
									<EquipmentCheckbox
										category="heatingSystem"
										name="buildingCentralHeating"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Building Central Heating"
										value={specsInformation.equipment.heatingSystem.buildingCentralHeating}
									/>
									<EquipmentCheckbox
										category="heatingSystem"
										name="centralHeating"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Central Heating"
										value={specsInformation.equipment.heatingSystem.centralHeating}
									/>
									<EquipmentCheckbox
										category="heatingSystem"
										name="dieselHeating"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Diesel Heating"
										value={specsInformation.equipment.heatingSystem.dieselHeating}
									/>
									<EquipmentCheckbox
										category="heatingSystem"
										name="fanConvectors"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Fan Convectors"
										value={specsInformation.equipment.heatingSystem.fanConvectors}
									/>
									<EquipmentCheckbox
										category="heatingSystem"
										name="heatPump"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Heat Pump"
										value={specsInformation.equipment.heatingSystem.heatPump}
									/>
									<EquipmentCheckbox
										category="heatingSystem"
										name="pelletCentralHeating"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Pallet Central Heating"
										value={specsInformation.equipment.heatingSystem.pelletCentralHeating}
									/>
									<EquipmentCheckbox
										category="heatingSystem"
										name="radiators"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Radiators"
										value={specsInformation.equipment.heatingSystem.radiators}
									/>
									<EquipmentCheckbox
										category="heatingSystem"
										name="thermofication"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Thermofication"
										value={specsInformation.equipment.heatingSystem.thermofication}
									/>
									<EquipmentCheckbox
										category="heatingSystem"
										name="tileStove"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Tile Stove"
										value={specsInformation.equipment.heatingSystem.tileStove}
									/>
									<EquipmentCheckbox
										category="heatingSystem"
										name="underfloorHeating"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Under Floor Heating"
										value={specsInformation.equipment.heatingSystem.underfloorHeating}
									/>
									<EquipmentCheckbox
										category="heatingSystem"
										name="woodCentralHeating"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Wood Central Heating"
										value={specsInformation.equipment.heatingSystem.woodCentralHeating}
									/>
								</Stack>
								<Stack direction={"column"} hidden={!Object.values(equipment.view).includes(true) && !isEditing}>
									<Text>View</Text>
									<EquipmentCheckbox
										category="view"
										name="cityView"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="City View"
										value={specsInformation.equipment.view.cityView}
									/>
									<EquipmentCheckbox
										category="view"
										name="lakeView"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Lake View"
										value={specsInformation.equipment.view.lakeView}
									/>
									<EquipmentCheckbox
										category="view"
										name="mountainView"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Mountain View"
										value={specsInformation.equipment.view.mountainView}
									/>
									<EquipmentCheckbox
										category="view"
										name="panoramicView"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Panoramic View"
										value={specsInformation.equipment.view.panoramicView}
									/>
									{/* TODO: see->sea view */}
									<EquipmentCheckbox
										category="view"
										name="seaView"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Sea View"
										value={specsInformation.equipment.view.seaView}
									/>
								</Stack>
								<Stack direction={"column"} hidden={!Object.values(equipment.entranceDoor).includes(true) && !isEditing}>
									<Text>Entrance Door</Text>
									<EquipmentCheckbox
										category="entranceDoor"
										name="metalEntranceDoor"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Metal Door"
										value={specsInformation.equipment.entranceDoor.metalEntranceDoor}
									/>
									<EquipmentCheckbox
										category="entranceDoor"
										name="paleEntranceDoor"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Pale Door"
										value={specsInformation.equipment.entranceDoor.paleEntranceDoor}
									/>
									<EquipmentCheckbox
										category="entranceDoor"
										name="pvcEntranceDoor"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="PVC Door"
										value={specsInformation.equipment.entranceDoor.pvcEntranceDoor}
									/>
									<EquipmentCheckbox
										category="entranceDoor"
										name="woodEntranceDoor"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Wooden Door"
										value={specsInformation.equipment.entranceDoor.woodEntranceDoor}
									/>
								</Stack>
								<Stack direction={"column"} hidden={!Object.values(equipment.walls).includes(true) && !isEditing}>
									<Text>Walls</Text>
									<EquipmentCheckbox
										category="walls"
										name="vinarom"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Vinarom"
										value={specsInformation.equipment.walls.vinarom}
									/>
									<EquipmentCheckbox
										category="walls"
										name="clay"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Clay"
										value={specsInformation.equipment.walls.clay}
									/>
									<EquipmentCheckbox
										category="walls"
										name="faience"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Faiance"
										value={specsInformation.equipment.walls.faience}
									/>
									<EquipmentCheckbox
										category="walls"
										name="lime"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Lime"
										value={specsInformation.equipment.walls.lime}
									/>
									<EquipmentCheckbox
										category="walls"
										name="naturalStone"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Natural Stone"
										value={specsInformation.equipment.walls.naturalStone}
									/>
									<EquipmentCheckbox
										category="walls"
										name="wainscot"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Wainscot"
										value={specsInformation.equipment.walls.wainscot}
									/>
									<EquipmentCheckbox
										category="walls"
										name="wallpaper"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Wallpaper"
										value={specsInformation.equipment.walls.wallpaper}
									/>
									<EquipmentCheckbox
										category="walls"
										name="washablePaint"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Washable Paint"
										value={specsInformation.equipment.walls.washablePaint}
									/>
								</Stack>
								<Stack direction={"column"} hidden={!Object.values(equipment.furnished).includes(true) && !isEditing}>
									<Text>Furnished</Text>
									<EquipmentCheckbox
										category="furnished"
										name="fullyFurnished"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Fully Furnished"
										value={specsInformation.equipment.furnished.fullyFurnished}
									/>
									<EquipmentCheckbox
										category="furnished"
										name="luxuryFurnish"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Luxury Furnish"
										value={specsInformation.equipment.furnished.luxuryFurnish}
									/>
									<EquipmentCheckbox
										category="furnished"
										name="partiallyFurnished"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Partially Furnished"
										value={specsInformation.equipment.furnished.partiallyFurnished}
									/>
									<EquipmentCheckbox
										category="furnished"
										name="unfurnished"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Unfirnished"
										value={specsInformation.equipment.furnished.unfurnished}
									/>
								</Stack>
								<Stack direction={"column"} hidden={!Object.values(equipment.kitchen).includes(true) && !isEditing}>
									<Text>Kitchen</Text>
									<EquipmentCheckbox
										category="kitchen"
										name="closedKitchen"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Closed Kitchen"
										value={specsInformation.equipment.kitchen.closedKitchen}
									/>
									<EquipmentCheckbox
										category="kitchen"
										name="equippedKitchen"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Equipped Kitchen"
										value={specsInformation.equipment.kitchen.equippedKitchen}
									/>
									<EquipmentCheckbox
										category="kitchen"
										name="furnishedKitchen"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Furnished Kitchen"
										value={specsInformation.equipment.kitchen.furnishedKitchen}
									/>
									<EquipmentCheckbox
										category="kitchen"
										name="kitchenette"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Kitchinette"
										value={specsInformation.equipment.kitchen.kitchenette}
									/>
									<EquipmentCheckbox
										category="kitchen"
										name="openKitchen"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Open Kitchen"
										value={specsInformation.equipment.kitchen.openKitchen}
									/>
									<EquipmentCheckbox
										category="kitchen"
										name="partiallyEquippedKitchen"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Partially Equipped Kitchen"
										value={specsInformation.equipment.kitchen.partiallyEquippedKitchen}
									/>
									<EquipmentCheckbox
										category="kitchen"
										name="partiallyFurnishedKitchen"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Partially Furnished Kitchen"
										value={specsInformation.equipment.kitchen.partiallyFurnishedKitchen}
									/>
								</Stack>
								<Stack direction={"column"} hidden={!Object.values(equipment.windows).includes(true) && !isEditing}>
									<Text>Windows</Text>
									<EquipmentCheckbox
										category="windows"
										name="aluminiumWindows"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Aluminium Windows"
										value={specsInformation.equipment.windows.aluminiumWindows}
									/>
									<EquipmentCheckbox
										category="windows"
										name="doubleGlazedWindows"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Double Glazed Windows"
										value={specsInformation.equipment.windows.doubleGlazedWindows}
									/>
									<EquipmentCheckbox
										category="windows"
										name="openingWindows"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Opening Windows"
										value={specsInformation.equipment.windows.openingWindows}
									/>
									<EquipmentCheckbox
										category="windows"
										name="pvcWindows"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="PVC Windows"
										value={specsInformation.equipment.windows.pvcWindows}
									/>
									<EquipmentCheckbox
										category="windows"
										name="soundInsulatedWindows"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Sound Insulated Windows"
										value={specsInformation.equipment.windows.soundInsulatedWindows}
									/>
									<EquipmentCheckbox
										category="windows"
										name="tripanWindows"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Tripan Windows"
										value={specsInformation.equipment.windows.tripanWindows}
									/>
									<EquipmentCheckbox
										category="windows"
										name="uvProtectionWindows"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="UV Protected Windows"
										value={specsInformation.equipment.windows.uvProtectionWindows}
									/>
									<EquipmentCheckbox
										category="windows"
										name="woodWindows"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Wooden Windows"
										value={specsInformation.equipment.windows.woodWindows}
									/>
								</Stack>
								<Stack direction={"column"} hidden={!Object.values(equipment.metering).includes(true) && !isEditing}>
									<Text>Metering</Text>
									<EquipmentCheckbox
										category="metering"
										name="electricityMeter"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Electricity Meter"
										value={specsInformation.equipment.metering.electricityMeter}
									/>
									<EquipmentCheckbox
										category="metering"
										name="gasMeter"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Gas Meter"
										value={specsInformation.equipment.metering.gasMeter}
									/>
									<EquipmentCheckbox
										category="metering"
										name="heatMeter"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Heat Meter"
										value={specsInformation.equipment.metering.heatMeter}
									/>
									<EquipmentCheckbox
										category="metering"
										name="waterMeter"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Water Meter"
										value={specsInformation.equipment.metering.waterMeter}
									/>
								</Stack>
								<Stack direction={"column"} hidden={!Object.values(equipment.interiorDoors).includes(true) && !isEditing}>
									<Text>Interior Doors</Text>
									<EquipmentCheckbox
										category="interiorDoors"
										name="interiorGlassDoors"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Interior Glass Doors"
										value={specsInformation.equipment.interiorDoors.interiorGlassDoors}
									/>
									<EquipmentCheckbox
										category="interiorDoors"
										name="interiorPanelDoors"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Interior Panel Doors"
										value={specsInformation.equipment.interiorDoors.interiorPanelDoors}
									/>
									<EquipmentCheckbox
										category="interiorDoors"
										name="interiorPvcDoors"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Interior PVC Doors"
										value={specsInformation.equipment.interiorDoors.interiorPvcDoors}
									/>
									<EquipmentCheckbox
										category="interiorDoors"
										name="internalCellularDoors"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Interior Cellular Doors"
										value={specsInformation.equipment.interiorDoors.internalCellularDoors}
									/>
									<EquipmentCheckbox
										category="interiorDoors"
										name="internalMdfDoors"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Interior MDF Doors"
										value={specsInformation.equipment.interiorDoors.internalMdfDoors}
									/>
									<EquipmentCheckbox
										category="interiorDoors"
										name="internalWoodDoors"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Interior Wooden Doors"
										value={specsInformation.equipment.interiorDoors.internalWoodDoors}
									/>
								</Stack>
								<Stack direction={"column"} hidden={!Object.values(equipment.flooring).includes(true) && !isEditing}>
									<Text>Flooring</Text>
									<EquipmentCheckbox
										category="flooring"
										name="carpet"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Carpet"
										value={specsInformation.equipment.flooring.carpet}
									/>
									<EquipmentCheckbox
										category="flooring"
										name="decking"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Decking"
										value={specsInformation.equipment.flooring.decking}
									/>
									<EquipmentCheckbox
										category="flooring"
										name="floorTiles"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Floor Tiles"
										value={specsInformation.equipment.flooring.floorTiles}
									/>
									<EquipmentCheckbox
										category="flooring"
										name="granitFlooring"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Granite Flooring"
										value={specsInformation.equipment.flooring.granitFlooring}
									/>
									<EquipmentCheckbox
										category="flooring"
										name="laminatedParquet"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Laminated Parquet"
										value={specsInformation.equipment.flooring.laminatedParquet}
									/>
									<EquipmentCheckbox
										category="flooring"
										name="linoleum"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Linoleum"
										value={specsInformation.equipment.flooring.linoleum}
									/>
									<EquipmentCheckbox
										category="flooring"
										name="marmorFlooring"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Marmor Flooring"
										value={specsInformation.equipment.flooring.marmorFlooring}
									/>
									<EquipmentCheckbox
										category="flooring"
										name="naturalStoneFlooring"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Natural Stone Flooring"
										value={specsInformation.equipment.flooring.naturalStoneFlooring}
									/>
									<EquipmentCheckbox
										category="flooring"
										name="parquet"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Parquet Flooring"
										value={specsInformation.equipment.flooring.parquet}
									/>
									<EquipmentCheckbox
										category="flooring"
										name="screed"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Screed"
										value={specsInformation.equipment.flooring.screed}
									/>
								</Stack>
								<Stack direction={"column"} hidden={!Object.values(equipment.otherSpaces).includes(true) && !isEditing}>
									<Text>Other Spaces</Text>
									<EquipmentCheckbox
										category="otherSpaces"
										name="barbequeArea"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Barbeque Area"
										value={specsInformation.equipment.otherSpaces.barbequeArea}
									/>
									<EquipmentCheckbox
										category="otherSpaces"
										name="basementBox"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Basement Box"
										value={specsInformation.equipment.otherSpaces.basementBox}
									/>
									<EquipmentCheckbox
										category="otherSpaces"
										name="cellar"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Cellar"
										value={specsInformation.equipment.otherSpaces.cellar}
									/>
									<EquipmentCheckbox
										category="otherSpaces"
										name="childrenPlayground"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Children Playground"
										value={specsInformation.equipment.otherSpaces.childrenPlayground}
									/>
									<EquipmentCheckbox
										category="otherSpaces"
										name="commonYard"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Common Yard"
										value={specsInformation.equipment.otherSpaces.commonYard}
									/>
									<EquipmentCheckbox
										category="otherSpaces"
										name="elevator"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Elevator"
										value={specsInformation.equipment.otherSpaces.elevator}
									/>
									<EquipmentCheckbox
										category="otherSpaces"
										name="irrigations"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Irrigations"
										value={specsInformation.equipment.otherSpaces.irrigations}
									/>
									<EquipmentCheckbox
										category="otherSpaces"
										name="privateGarden"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Private Garden"
										value={specsInformation.equipment.otherSpaces.privateGarden}
									/>
									<EquipmentCheckbox
										category="otherSpaces"
										name="sauna"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Sauna"
										value={specsInformation.equipment.otherSpaces.sauna}
									/>
									<EquipmentCheckbox
										category="otherSpaces"
										name="serviceWc"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Service WC"
										value={specsInformation.equipment.otherSpaces.serviceWc}
									/>
									<EquipmentCheckbox
										category="otherSpaces"
										name="shed"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Shed"
										value={specsInformation.equipment.otherSpaces.shed}
									/>
									<EquipmentCheckbox
										category="otherSpaces"
										name="sportFacilities"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Sport Facilities"
										value={specsInformation.equipment.otherSpaces.sportFacilities}
									/>
									<EquipmentCheckbox
										category="otherSpaces"
										name="storageCloset"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Storage Closet"
										value={specsInformation.equipment.otherSpaces.storageCloset}
									/>
									<EquipmentCheckbox
										category="otherSpaces"
										name="yard"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Yard"
										value={specsInformation.equipment.otherSpaces.yard}
									/>
								</Stack>
								<Stack direction={"column"} hidden={!Object.values(equipment.shutters).includes(true) && !isEditing}>
									<Text>Shutters</Text>
									<EquipmentCheckbox
										category="shutters"
										name="aluminiumShutters"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Aluminium Shutters"
										value={specsInformation.equipment.shutters.aluminiumShutters}
									/>
									<EquipmentCheckbox
										category="shutters"
										name="blinds"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Blinds"
										value={specsInformation.equipment.shutters.blinds}
									/>
									<EquipmentCheckbox
										category="shutters"
										name="electricShutters"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Electric Shutters"
										value={specsInformation.equipment.shutters.electricShutters}
									/>
									<EquipmentCheckbox
										category="shutters"
										name="manualShutters"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Manual Shutters"
										value={specsInformation.equipment.shutters.manualShutters}
									/>
									<EquipmentCheckbox
										category="shutters"
										name="pvcShutters"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="PVC Shutters"
										value={specsInformation.equipment.shutters.pvcShutters}
									/>
									<EquipmentCheckbox
										category="shutters"
										name="woodShutters"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Wooden Shutters"
										value={specsInformation.equipment.shutters.woodShutters}
									/>
								</Stack>
								<Stack direction={"column"} hidden={!Object.values(equipment.blinds).includes(true) && !isEditing}>
									<Text>Blinds</Text>
									<EquipmentCheckbox
										category="blinds"
										name="horizontalBlinds"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Horizontal Blinds"
										value={specsInformation.equipment.blinds.horizontalBlinds}
									/>
									<EquipmentCheckbox
										category="blinds"
										name="verticalBlinds"
										onChange={handleCheckboxChange}
										isEditing={isEditing}
										label="Vertical Blinds"
										value={specsInformation.equipment.blinds.verticalBlinds}
									/>
								</Stack>
							</Flex>
						</CardBody>
					</Card>
				</GridItem>
				<GridItem rowSpan={1}>
					<Card>
						<CardHeader>
							<Text fontWeight="bold">Rooms</Text>
						</CardHeader>
						<CardBody>
							<Stack fontSize={"small"}>
								<Grid templateColumns={"1fr 1fr"} alignItems="center">
									<GridItem textAlign="right">
										<Text as="span" mr={2} fontStyle="italic">
											Rooms:
										</Text>
									</GridItem>
									<GridItem>
										{isEditing ? (
											<Input
												size="xs"
												value={specsInformation.rooms.roomsNumber}
												onChange={(e) => {
													handleSpecsInfoChange(e, "rooms");
												}}
												name="roomsNumber"
											/>
										) : (
											<Text>{specsInformation.rooms.roomsNumber || "-"}</Text>
										)}
									</GridItem>
								</Grid>
								<Grid templateColumns={"1fr 1fr"} alignItems="center">
									<GridItem textAlign="right">
										<Text as="span" mr={2} fontStyle="italic">
											Bedrooms:
										</Text>
									</GridItem>
									<GridItem>
										{isEditing ? (
											<Input
												size="xs"
												value={specsInformation.rooms.bedrooms}
												onChange={(e) => {
													handleSpecsInfoChange(e, "rooms");
												}}
												name="bedrooms"
											/>
										) : (
											<Text>{specsInformation.rooms.bedrooms || "-"}</Text>
										)}
									</GridItem>
								</Grid>
								<Grid templateColumns={"1fr 1fr"} alignItems="center">
									<GridItem textAlign="right">
										<Text as="span" mr={2} fontStyle="italic">
											Kitchens:
										</Text>
									</GridItem>
									<GridItem>
										{isEditing ? (
											<Input
												size="xs"
												value={specsInformation.rooms.kitchens}
												onChange={(e) => {
													handleSpecsInfoChange(e, "rooms");
												}}
												name="kitchens"
											/>
										) : (
											<Text>{specsInformation.rooms.kitchens || "-"}</Text>
										)}
									</GridItem>
								</Grid>
								<Grid templateColumns={"1fr 1fr"} alignItems="center">
									<GridItem textAlign="right">
										<Text as="span" mr={2} fontStyle="italic">
											Bathrooms:
										</Text>
									</GridItem>
									<GridItem>
										{isEditing ? (
											<Input
												size="xs"
												value={specsInformation.rooms.bathrooms}
												onChange={(e) => {
													handleSpecsInfoChange(e, "rooms");
												}}
												name="bathrooms"
											/>
										) : (
											<Text>{specsInformation.rooms.bathrooms || "-"}</Text>
										)}
									</GridItem>
								</Grid>
								<Grid templateColumns={"1fr 1fr"} alignItems="center">
									<GridItem textAlign="right">
										<Text as="span" mr={2} fontStyle="italic">
											Bathroom Window:
										</Text>
									</GridItem>
									<GridItem>
										{isEditing ? (
											<Checkbox
												value={specsInformation.rooms.bathroomWindow}
												onChange={(e) => {
													handleSpecsInfoChange(e, "rooms");
												}}
												name="bathroomWindow"
											/>
										) : (
											<Text>{specsInformation.rooms.bathroomWindow ? "Yes" : "No"}</Text>
										)}
									</GridItem>
								</Grid>
							</Stack>
						</CardBody>
					</Card>
				</GridItem>
				<GridItem rowSpan={1}>
					<Card>
						<CardHeader>
							<Text fontWeight="bold">Building Features</Text>
						</CardHeader>
						<CardBody>
							<Stack fontSize={"small"}>
								<Grid templateColumns={"1fr 1fr"} alignItems="center">
									<GridItem textAlign="right">
										<Text as="span" mr={2} fontStyle="italic">
											Type:
										</Text>
									</GridItem>
									<GridItem>
										{isEditing ? (
											<Input
												size="xs"
												value={specsInformation.propertyFeatures.type}
												onChange={(e) => {
													handleSpecsInfoChange(e, "propertyFeatures");
												}}
												name="type"
											/>
										) : (
											<Text>{specsInformation.propertyFeatures.type || "-"}</Text>
										)}
									</GridItem>
								</Grid>
								<Grid templateColumns={"1fr 1fr"} alignItems="center">
									<GridItem textAlign="right">
										<Text as="span" mr={2} fontStyle="italic">
											Construction Year:
										</Text>
									</GridItem>
									<GridItem>
										{isEditing ? (
											<Input
												size="xs"
												value={specsInformation.propertyFeatures.constructionYear}
												onChange={(e) => {
													handleSpecsInfoChange(e, "propertyFeatures");
												}}
												name="constructionYear"
											/>
										) : (
											<Text>{specsInformation.propertyFeatures.constructionYear || "-"}</Text>
										)}
									</GridItem>
								</Grid>
								<Grid templateColumns={"1fr 1fr"} alignItems="center">
									<GridItem textAlign="right">
										<Text as="span" mr={2} fontStyle="italic">
											Construction State:
										</Text>
									</GridItem>
									<GridItem>
										{isEditing ? (
											<Input
												size="xs"
												value={specsInformation.propertyFeatures.constructionState}
												onChange={(e) => {
													handleSpecsInfoChange(e, "propertyFeatures");
												}}
												name="constructionState"
											/>
										) : (
											<Text>{specsInformation.propertyFeatures.constructionState || "-"}</Text>
										)}
									</GridItem>
								</Grid>
								<Grid templateColumns={"1fr 1fr"} alignItems="center">
									<GridItem textAlign="right">
										<Text as="span" mr={2} fontStyle="italic">
											Building Floors:
										</Text>
									</GridItem>
									<GridItem>
										{isEditing ? (
											<Input
												size="xs"
												value={specsInformation.propertyFeatures.buildingFloors}
												onChange={(e) => {
													handleSpecsInfoChange(e, "propertyFeatures");
												}}
												name="buildingFloors"
											/>
										) : (
											<Text>{specsInformation.propertyFeatures.buildingFloors || "-"}</Text>
										)}
									</GridItem>
								</Grid>
							</Stack>
						</CardBody>
					</Card>
				</GridItem>
			</Grid>
		</Stack>
	);
}
