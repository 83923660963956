import { Card, CardBody, CardHeader, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PersonalInformation from "./PersonalInformation";
import { selectActiveProperties } from "../../redux/user/userSlice";

export default function Tenant() {
	const { propertyId, tenantId } = useParams();
	const properties = useSelector(selectActiveProperties);
	const tenant =
		properties?.find((p) => p.id === propertyId).tenants.find((t) => t.id === tenantId) ||
		properties?.find((p) => p.id === propertyId).formerTenants.find((t) => t.id === tenantId);
	console.log(tenant);
	const tabs = [{ title: "Personal Information", component: <PersonalInformation tenant={tenant} propertyId={propertyId} /> }];
	useEffect(() => {
		console.log("tenant: ", tenant);
	}, [tenant]);
	return (
		<Card>
			<CardHeader fontSize="xl" fontWeight="bold">
				Tenant Details
			</CardHeader>
			<CardBody>
				<Tabs variant="enclosed">
					<TabList overflowX="auto" overflowY="hidden">
						{tabs.map((t, index) => (
							<Tab key={index}>{t.title}</Tab>
						))}
					</TabList>
					<TabPanels>
						{tabs.map((t, index) => (
							<TabPanel key={index}>
								<Card>
									<CardHeader fontWeight="bold" fontSize="xl" pb={0}>
										{t.title}
									</CardHeader>
									<CardBody>{t.component}</CardBody>
								</Card>
							</TabPanel>
						))}
					</TabPanels>
				</Tabs>
			</CardBody>
		</Card>
	);
}
