import React, { useState } from "react";
import {
	IconButton,
	Avatar,
	Box,
	CloseButton,
	Flex,
	HStack,
	VStack,
	Icon,
	useColorModeValue,
	Link,
	Drawer,
	DrawerContent,
	Text,
	useDisclosure,
	Menu,
	MenuButton,
	MenuDivider,
	MenuItem,
	MenuList,
	useColorMode,
	Button,
	Progress,
	Spinner,
	useToast,
	Image,
	Stack,
	Select,
	ModalOverlay,
	Modal,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	FormControl,
	FormLabel,
	Input,
	ModalFooter,
	Tooltip,
} from "@chakra-ui/react";
import { NavLink as ReactRouterLink, useNavigate } from "react-router-dom";
import { AiOutlineAudit } from "react-icons/ai";
import { FiTrendingUp, FiMenu, FiBell, FiChevronDown } from "react-icons/fi";
import { TbCalendarTime } from "react-icons/tb";
import { CgFileDocument, CgProfile } from "react-icons/cg";
import { MdOutlineMessage, MdOutlinePayments } from "react-icons/md";
import { BsSun, BsMoonStarsFill, BsClipboardPlus, BsSpeedometer2 } from "react-icons/bs";
import { FiRefreshCw } from "react-icons/fi";
import { TbFileInvoice } from "react-icons/tb";
import { BiBuildingHouse } from "react-icons/bi";
import { RiDashboardLine, RiLuggageDepositLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { signOutAsync } from "../../redux/auth/authSlice";
import { getDashboardAsync } from "../../redux/user/userSlice";
// import RentITLogo from "../../assets/img/rentit-logo.png";
import CustomScroll from "react-custom-scroll";
import { useTranslation } from "react-i18next";
import i18n from "../../utils/i18n";
import { setHeaders, getHeaders, API } from "../../utils/http";
import { MdOutlineEventRepeat } from "react-icons/md";

export default function Navbar({ children }) {
	const { isOpen, onOpen, onClose } = useDisclosure();

	return (
		<Box minH="100vh" bg={useColorModeValue("gray.100", "gray.900")}>
			<SidebarContent onClose={() => onClose} display={{ base: "none", md: "block" }} />
			<Drawer autoFocus={false} isOpen={isOpen} placement="left" onClose={onClose} returnFocusOnClose={false} onOverlayClick={onClose} size="full">
				<DrawerContent>
					<SidebarContent onClose={onClose} />
				</DrawerContent>
			</Drawer>
			{/* mobilenav */}
			<MobileNav onOpen={onOpen} />
			<Box ml={{ base: 0, md: 60 }} p="4">
				{children}
			</Box>
		</Box>
	);
}

const SidebarContent = ({ onClose, ...rest }) => {
	const accountType = useSelector((state) => state.user.user?.type);
	const { t } = useTranslation();
	const TenantLinkItems = [
		{ name: t("navbarMenu.dashboard"), icon: RiDashboardLine, path: "/dashboard" },
		{ name: t("navbarMenu.utilities"), icon: BiBuildingHouse, path: "/utilities" },
		{ name: t("navbarMenu.payments"), icon: MdOutlinePayments, path: "/payments" },
		{ name: t("navbarMenu.documents"), icon: CgFileDocument, path: "/documents" },
		{ name: t("navbarMenu.tickets"), icon: BsClipboardPlus, path: "/tickets" },
		{ name: t("navbarMenu.messages"), icon: MdOutlineMessage, path: "/messages" },
	];
	const AgentLinkItems = [
		{ name: t("navbarMenu.dashboard"), icon: RiDashboardLine, path: "/dashboard" },
		{ name: t("navbarMenu.properties"), icon: BiBuildingHouse, path: "/properties" },
		{ name: t("navbarMenu.outstanding"), icon: MdOutlinePayments, path: "/outstanding" },
		{ name: t("navbarMenu.documents"), icon: TbFileInvoice, path: "/documents" },
		{ name: t("navbarMenu.utilities"), icon: BsSpeedometer2, path: "/utilities" },
		{ name: t("navbarMenu.financialStatus"), icon: FiTrendingUp, path: "/financial-status" },
		{ name: t("navbarMenu.statements"), icon: AiOutlineAudit, path: "/statements" },
		{ name: t("navbarMenu.notifications"), icon: FiBell, path: "/mass-notification" },
		{ name: t("navbarMenu.tickets"), icon: BsClipboardPlus, path: "/tickets" },
		{ name: t("navbarMenu.securityDeposits"), icon: RiLuggageDepositLine, path: "/security-deposits" },
		{ name: t("navbarMenu.profile"), icon: CgProfile, path: "/profile" },
	];
	const AgencyLinkItems = [...AgentLinkItems, { name: t("navbarMenu.subscription"), icon: TbCalendarTime, path: "/subscription" }];
	const OwnerLinkItems = [
		{ name: t("navbarMenu.dashboard"), icon: RiDashboardLine, path: "/dashboard" },
		{ name: t("navbarMenu.payments"), icon: MdOutlinePayments, path: "/payments" },
		{ name: t("navbarMenu.financialStatus"), icon: FiTrendingUp, path: "/financial-status" },
		{ name: t("navbarMenu.documents"), icon: CgFileDocument, path: "/documents" },
		{ name: t("navbarMenu.tickets"), icon: BsClipboardPlus, path: "/tickets" },
		{ name: t("navbarMenu.messages"), icon: MdOutlineMessage, path: "/messages" },
		{ name: t("navbarMenu.customOwnerPlans"), icon: MdOutlineEventRepeat, path: "/custom-owner-plans" },
	];
	const LinkItems = {
		owner: OwnerLinkItems,
		tenant: TenantLinkItems,
		agent: AgentLinkItems,
		agency: AgencyLinkItems,
	};
	return (
		<Box
			transition="3s ease"
			bg={useColorModeValue("white", "gray.900")}
			borderRight="1px"
			borderRightColor={useColorModeValue("gray.200", "gray.700")}
			w={{ base: "full", md: 60 }}
			pos="fixed"
			h="full"
			{...rest}
		>
			<CustomScroll heightRelativeToParent="100%">
				<Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
					<Stack direction={"row"} height="100%" alignItems="center">
						{/* <Image src={RentITLogo} height="100%" />
						<Text fontSize="2xl" fontFamily="monospace" fontWeight="bold">
							RentIT
						</Text> */}
						<Image src="https://rentit-images.s3.eu-central-1.amazonaws.com/logo.png" />
					</Stack>

					<CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
				</Flex>

				{LinkItems[accountType]?.map((link) => (
					<NavItem key={link.name} icon={link.icon} path={link.path} onClick={onClose}>
						{link.name}
					</NavItem>
				))}
			</CustomScroll>
		</Box>
	);
};

const NavItem = ({ icon, path, children, ...rest }) => {
	return (
		<Link
			as={ReactRouterLink}
			to={path}
			// borderRadius="lg"
			style={({ isActive }) => {
				return {
					display: "block",
					margin: "1rem 0",
					textDecoration: "none",
				};
			}}
			_focus={{ boxShadow: "none" }}
		>
			{({ isActive }) => {
				return (
					<Flex
						align="center"
						p="3"
						mx="4"
						borderRadius="lg"
						role="group"
						cursor="pointer"
						bg={isActive ? "teal.400" : ""}
						color={isActive ? "white" : ""}
						{...rest}
					>
						{icon && (
							<Icon
								mr="4"
								fontSize="16"
								// _groupHover={{
								// 	color: "white",
								// }}
								as={icon}
							/>
						)}
						{children}
					</Flex>
				);
			}}
		</Link>
	);
};

const MobileNav = ({ onOpen, ...rest }) => {
	const { colorMode, toggleColorMode } = useColorMode();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const handleSignOut = async () => {
		dispatch(signOutAsync());
	};
	const status = useSelector((state) => state.user.status);
	const currentUserEmail = useSelector((state) => state.auth.currentUserEmail);
	const accountType = useSelector((state) => state.user.user?.type);
	const toast = useToast();
	const handleChangeLanguage = (e) => {
		console.log(e.target.value);
		i18n.changeLanguage(e.target.value);
	};
	const refreshDashboard = () => {
		dispatch(getDashboardAsync()).then((r) => {
			console.log(r);
			if (r.error?.message)
				toast({
					title: "Error encountered",
					description: r.error.message,
					status: "error",
					duration: 9000,
					isClosable: true,
				});
			else {
				toast({
					title: "Success!",
					description: "Dashboard info success",
					status: "success",
					duration: 2000,
					isClosable: true,
				});
			}
		});
	};
	const [onBehalfOf, setOnBehalfOf] = useState("");
	const handleChangeOnBehalfOf = (e) => {
		setOnBehalfOf(e.target.value);
	};

	const saveOnBehalfOf = () => {
		setHeaders({ ...getHeaders(), onbehalfof: onBehalfOf });
		dispatch(getDashboardAsync())
			.then((r) => {
				console.log(r);
				if (r.error?.message)
					toast({
						title: "Error encountered",
						description: r.error.message,
						status: "error",
						duration: 9000,
						isClosable: true,
					});
				else {
					toast({
						title: "Success!",
						description: "Dashboard info success",
						status: "success",
						duration: 2000,
						isClosable: true,
					});
				}
			})
			.finally(() => {
				navigate(`/`);
				onBehalfOfClose();
			});
	};
	const { isOpen: isOnBehalfOfOpen, onOpen: onOnBehalfOfOpen, onClose: onBehalfOfClose } = useDisclosure();

	const setRenderOnBehalfOfModal = () => (
		<Modal isOpen={isOnBehalfOfOpen} onClose={onBehalfOfClose} size={"xl"}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>
					<Text>Set on behalf of</Text>
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<FormControl>
						<FormLabel display={"inline"}>Email</FormLabel>
						<Input
							width={"fit-content"}
							type="text"
							name="email"
							placeholder="On Behalf Of Email"
							_placeholder={{ color: "gray.500" }}
							value={onBehalfOf}
							onChange={handleChangeOnBehalfOf}
						/>
					</FormControl>
				</ModalBody>
				<ModalFooter>
					<Button colorScheme="red" onClick={onBehalfOfClose} mr={3}>
						Cancel
					</Button>
					<Button colorScheme="blue" onClick={saveOnBehalfOf}>
						Save
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);

	const [changeEmail, setChangeEmail] = useState({
		fromEmail: "",
		toEmail: "",
	});
	const handleChangeChangeEmail = (e) => {
		setChangeEmail({
			...changeEmail,
			[e.target.name]: e.target.value,
		});
	};

	const saveChangeEmail = () => {
		console.log(changeEmail);
		API.put("/change-email", changeEmail)
			.then((r) => {
				console.log(r);
				if (r.error?.message)
					toast({
						title: "Error encountered",
						description: r.error.message,
						status: "error",
						duration: 9000,
						isClosable: true,
					});
				else {
					toast({
						title: "Success!",
						description: "Email change success",
						status: "success",
						duration: 2000,
						isClosable: true,
					});
					setChangeEmail({
						fromEmail: "",
						toEmail: "",
					});
				}
			})
			.catch((e) => {
				toast({
					title: "Error encountered",
					description: e.message,
					status: "error",
					duration: 9000,
					isClosable: true,
				});
			});
	};
	const { isOpen: isChangeEmailOpen, onOpen: onChangeEmailOpen, onClose: onChangeEmailClose } = useDisclosure();

	const renderChangeEmailModal = () => (
		<Modal isOpen={isChangeEmailOpen} onClose={onChangeEmailClose} size={"xl"}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>
					<Text>Change email</Text>
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<FormControl>
						<FormLabel display={"inline"}>From email</FormLabel>
						<Input
							width={"fit-content"}
							type="text"
							name="fromEmail"
							_placeholder={{ color: "gray.500" }}
							value={changeEmail.fromEmail}
							onChange={handleChangeChangeEmail}
						/>
					</FormControl>
					<FormControl>
						<FormLabel display={"inline"}>To email</FormLabel>
						<Input
							width={"fit-content"}
							type="text"
							name="toEmail"
							_placeholder={{ color: "gray.500" }}
							value={changeEmail.toEmail}
							onChange={handleChangeChangeEmail}
						/>
					</FormControl>
				</ModalBody>
				<ModalFooter>
					<Button colorScheme="red" onClick={onChangeEmailClose} mr={3}>
						Cancel
					</Button>
					<Button colorScheme="blue" onClick={saveChangeEmail}>
						Save
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);

	return (
		<>
			<Flex
				ml={{ base: 0, md: 60 }}
				px={{ base: 4, md: 4 }}
				height="20"
				alignItems="center"
				bg={useColorModeValue("white", "gray.900")}
				borderBottomWidth="1px"
				borderBottomColor={useColorModeValue("gray.200", "gray.700")}
				justifyContent={{ base: "space-between", md: "flex-end" }}
				{...rest}
			>
				<IconButton display={{ base: "flex", md: "none" }} onClick={onOpen} variant="outline" aria-label="open menu" icon={<FiMenu />} />

				<Image display={{ base: "flex", md: "none" }} src="https://rentit-images.s3.eu-central-1.amazonaws.com/logo.png" maxH={"80%"} />
				<HStack spacing={{ base: "0", md: "6" }}>
					<Tooltip label="Refresh dashboard" aria-label="Refresh dashboard">
						<Button _focus={{ boxShadow: "none" }} w="fit-content" onClick={refreshDashboard}>
							{status === "loading" ? <Spinner /> : <FiRefreshCw />}
						</Button>
					</Tooltip>
					<Tooltip label="Toggle color mode" aria-label="Toggle color mode">
						<Button aria-label="Toggle Color Mode" onClick={toggleColorMode} _focus={{ boxShadow: "none" }} w="fit-content">
							{colorMode === "light" ? <BsMoonStarsFill /> : <BsSun />}
						</Button>
					</Tooltip>
					{/* language selector */}
					<Select onClick={handleChangeLanguage} width={"fit-content"}>
						<option value="ro">Ro</option>
						<option value="en">En</option>
					</Select>
					{/* <IconButton size="lg" variant="ghost" aria-label="open menu" icon={<FiBell />} /> */}
					<Flex alignItems={"center"}>
						{setRenderOnBehalfOfModal()}
						{renderChangeEmailModal()}
						<Menu>
							<MenuButton py={2} transition="all 0.3s" _focus={{ boxShadow: "none" }}>
								<HStack>
									<Avatar size={"sm"} src={`https://ui-avatars.com/api/?name=${currentUserEmail}&length=1`} />
									<VStack display={{ base: "none", md: "flex" }} alignItems="flex-start" spacing="1px" ml="2">
										<Text fontSize="sm">{currentUserEmail}</Text>
										<Text fontSize="xs" color="gray.600">
											{accountType}
										</Text>
									</VStack>
									<Box display={{ base: "none", md: "flex" }}>
										<FiChevronDown />
									</Box>
								</HStack>
							</MenuButton>
							<MenuList bg={useColorModeValue("white", "gray.900")} borderColor={useColorModeValue("gray.200", "gray.700")}>
								<MenuItem
									hidden={currentUserEmail !== "omihai00@gmail.com"}
									onClick={() => {
										onOnBehalfOfOpen();
									}}
								>
									Set on behalf of
								</MenuItem>
								<MenuItem
									hidden={currentUserEmail !== "omihai00@gmail.com"}
									onClick={() => {
										onChangeEmailOpen();
									}}
								>
									Change email
								</MenuItem>
								<MenuItem
									onClick={() => {
										navigate("/profile");
									}}
									hidden={accountType === "owner" || accountType === "tenant"}
								>
									Profile
								</MenuItem>

								<MenuDivider />
								<MenuItem
									onClick={() => {
										handleSignOut();
									}}
								>
									Sign out
								</MenuItem>
							</MenuList>
						</Menu>
					</Flex>
				</HStack>
			</Flex>
			<Progress
				size="xs"
				isIndeterminate
				hidden={status === "idle"}
				style={{
					position: "absolute",
					top: 0,
					left: 0,
					width: "100%",
				}}
			/>
		</>
	);
};
