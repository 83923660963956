export const ro = {
	navbarMenu: {
		dashboard: "Panou de control",
		properties: "Proprietăți",
		outstanding: "Plăți",
		documents: "Documente",
		utilities: "Utilități",
		financialStatus: "Situație financiară",
		statements: "Finante",
		notifications: "Notificări",
		tickets: "Tichete",
		securityDeposits: "Garanții",
		profile: "Profil",
		subscription: "Abonament",
		payments: "Plăți",
		messages: "Mesaje",
		customOwnerPlans: "Pachete de servicii",
	},
	types: {
		tax: "Impozit",
		centralHeatingVerification: "Verificare centrala",
		insurance: "Asigurare",
		rent: "Chirie",
		other: "Altele",
		administrationContract: "Contract de administrare",
		gasContract: "Contract de gaz",
		electricityContract: "Contract de curent",
		waterContract: "Contract de apa",
		optionalInsurance: "Asigurare Facultativa",
		gasVerification: "Verificare gaz",
		gasRevision: "Revizie Gaz",
		lastGasInvoice: "Copie factura gaz",
		lastElectricityInvoice: "Copie factura curent",
		lastWaterInvoice: "Copie factura apa",
		tenantAssociationCertificate: "Adeverinta asociatie locatari",
		sanitationDeclaration: "Declaratie de salubritate",
		aquisitionAct: "Act de dobandire",
		entryReport: "Proces verbal de intrare",
		exitReport: "Proces verbal de iesire",
		observationReport: "Constatare",
		formerTenant: "Fost chirias",
		markFormer: "Marchează ca fost chiriaș",
		markActive: "Marchează ca activ",
		ownerSpouse: "Partener proprietar",
		buildingSuper: "Administrator bloc",
		headOfTenantAssociation: "Sef asociatie de locatari",
		cityHall: "Primarie",
		finances: "Finante",
		water: "Apa",
		gas: "Gaz",
		electricity: "Curent",
		internet: "Internet",
		gasUtility: "Gaz",
		electricityUtility: "Curent",
		waterUtility: "Apa",
		internetUtility: "Internet",
		sanitationUtility: "Salubritate",
		cleaningUtility: "Curatenie",
		administrationFee: "Comision de administrare",
		protocol: "Proces verbal",
		lease: "Contract de închiriere",
		custodyCash: "Custodie numerar",
		custodyBank: "Custodie bancară",
		total: "Total",
		owner: "Proprietar",
		returned: "Returnat",
		returnedTenant: "Returnat chiriașului",
		extension: "Prelungire",
		termination: "Reziliere",
		rentModification: "Modificare chirie",
		pending: "În așteptare",
		done: "Finalizat",
		new: "Nou",
		gasAccount: "Cont de gaz",
		electricityAccount: "Cont de curent",
		waterAssociation: "Asociatie apa",
		ownerIdentificationDocument: "Buletin proprietar",
		tenantAssociationAnnouncement: "Anunt asociatie locatari",
		tenants: "Chiriași",
		jobCertificate: "Adeverinta de serviciu",
		meterPictures: "Poze contor",
	},
	dashboard: {
		expiringLeases: "Închirieri care expiră",
		expiringDocuments: "Documente care expiră",
		revenue: "Venituri",
		properties: "Proprietăți",
		securityDeposits: "Garantii",
		monthlyRevenue: "Venit lunar",
		administrationFee: "Taxă de administrare",
		openTickets: "Tichete deschise",
		occupied: "Ocupat",
		empty: "Gol",
	},
	table: {
		date: "Data",
		recipients: "Destinatari",
		addedDate: "Data adăugării",
		issuedDate: "Data emiterii",
		dueDate: "Data scadentă",
		paidDate: "Data plății",
		method: "Metoda",
		type: "Tip",
		owner: "Proprietar",
		address: "Adresă",
		status: "Stare",
		title: "Titlu",
		author: "Autor",
		endDate: "Data de încheiere",
		amount: "Sumă",
		tenant: "Chiriaș",
		currency: "Monedă",
		view: "Vizualizare",
		delete: "Ștergere",
		noData: "Nu există date de afișat",
		previousMonth: "Luna anterioară",
		nextMonth: "Luna următoare",
		rent: "Chirie",
		tenants: "Chiriași",
		lease: "Închiriere",
		statements: "Finante",
		administration: "Admin.",
		incomplete: "Incomplet",
		complete: "Complet",
		warning: "Atenție",
		empty: "Gol",
		expired: "Expirat",
		last10Activities: "Ultimele 10 activități",
		markAsPaid: "Marchează ca plătit",
		name: "Nume",
		number: "Număr",
		startingDate: "Data început",
		signatureDate: "Data semnării",
		expirationDate: "Data expirării",
		actions: "Acțiuni",
		download: "Descărcare",
		markAsActive: "Marchează ca activ",
		markAsInactive: "Marchează ca inactiv",
		due: "Scadent",
		overDue: "În întârziere",
		paid: "Plătit",
		rentReceived: "Chirie primită",
		administrationFee: "Comision de administrare",
		tax: "Taxe",
		centralHeating: "Centrală",
		insurance: "Asigurare",
		others: "Altele",
		subTotal: "Subtotal",
		year: "An",
		nextYear: "Anul viitor",
		previousYear: "Anul trecut",
		description: "Descriere",
		location: "Locație",
		custodian: "Custode",
		updatedDate: "Data actualizării",
		lastActivities: "Ultimele activități",
		statement: "Declaratie",
		c168: "C168",
		lastMessage: "Ultimul mesaj",
		price: "Preț",
	},
	properties: {
		properties: "Proprietăți",
		addProperty: "Adaugă Proprietate",
		archivedProperties: "Proprietăți Arhivate",
		location: "Locație",
		keys: "Chei",
		agent: "Agent",
		bankDetails: "Detalii Bancare",
		owner: "Proprietar",
		street: "Stradă",
		buildingNumber: "Număr Clădire",
		blockNumber: "Număr Bloc",
		apartmentNumber: "Număr Apartament",
		entrance: "Intrare",
		area: "Suprafață",
		city: "Oraș",
		county: "Județ",
		IBAN: "IBAN",
		administrationFeePercentage: "Procent comision de administrare",
		currency: "Monedă",
		name: "Nume",
		email: "Email",
		phoneNumber: "Număr de Telefon",
		verifyEmail: "Important: Verificați cu atenție adresa de email; nu poate fi modificată ulterior.",
		total: "Total",
		occupied: "Ocupat",
		empty: "Gol",
		iban: "IBAN",
		administrationFeePercent: "Procent comision de administrare",
	},
	outstanding: {
		outstanding: "De plată",
		missingRentPayment: "Plată chirii lipsă",
		noOutstandingPayments: "Nu există plăți restante",
	},
	utilities: {
		utilities: "Utilități",
		gas: "Gaz",
		water: "Apă",
		electricity: "Electricitate",
		sanitation: "Salubritate",
		internet: "Internet",
	},

	documents: {
		documents: "Documente",
		exportAsCsv: "Exportă ca CSV",
		type: "Tip",
	},

	financialStatus: {
		financialStatus: "Stare financiară",
	},
	statements: {
		statements: "Finante",
	},

	tickets: {
		tickets: "Tichete",
	},

	securityDeposits: {
		securityDeposits: "Garantii",
		totals: "Totaluri",
		custodyCash: "Custodie numerar",
		custodyBank: "Custodie bancară",
		owner: "Proprietar",
		total: "Total",
		returned: "Returnat",
		returnedTenant: "Returnat chiriașului",
	},
	property: {
		propertyDetails: "Detalii proprietate",
		archive: "Arhiveaza",
		restore: "Restaurează",
		general: "General",
		specs: "Specificații",
		images: "Imagini",
		contacts: "Contacte",
		utilities: "Utilități",
		payments: "Plăți",
		securityDeposits: "Garantii",
		financialStatements: "Finante",
		documents: "Documente",
		accounts: "Conturi",
		activities: "Activități",
		tenants: "Chiriași",
		notifications: "Notificări",
		tickets: "Tichete",
		messages: "Mesaje",
		location: "Locație",
		county: "Județ",
		city: "Oraș",
		area: "Zonă",
		street: "Stradă",
		buildingNumber: "Număr clădire",
		blockNumber: "Număr bloc",
		apartmentNumber: "Număr apartament",
		entrance: "Intrare",
		keys: "Chei",
		description: "Descriere",
		bankAccount: "Cont bancar",
		bankAccountCurrency: "Monedă cont bancar",
		administrationFeePercentage: "Procent comision de administrare",
		administration: "Administrație",
		lease: "Închiriere",
		financial: "Financiar",
		rentPrice: "Preț chirie",
		rentCollection: "Perioada Chirie",
		rentExchangeRate: "Curs Valutar Chirie",
		fixedAdministrationFee: "Comision de administrare fix",
		agent: "Agent",
		dateAdded: "Data adăugării",
		editRent: "Editează Chirie",
		//images
		addMedia: "Adaugă Media",
		//contacts
		contactInformation: "Informații Contact",
		addContact: "Adaugă Contact",
		addAssociation: "Adaugă Asociație",
		association: "Asociație",
		editAssociation: "Editează Asociația",
		removeAssociation: "Șterge Asociația",
		name: "Nume",
		email: "Email",
		phoneNumber: "Număr de Telefon",
		phoneNumber2: "Număr de Telefon secundar",
		administratorName: "Nume Administrator",
		iban: "IBAN",
		//utilities
		index: "Index",
		additionalIndex: "Additional Index",
		addIndex: "Adaugă Index",
		addUtilityInvoice: "Adaugă Factură de utilități",
		gas: "Gaz",
		electricity: "Electricitate",
		water: "Apă",
		sanitation: "Salubritate",
		internet: "Internet",
		history: "Istoric",
		requestMeterPicture: "Solicită Poza Contorului",
		gasIndex: "Index Gaz",
		electricityIndex: "Index Electricitate",
		waterIndex: "Index Apă",
		utilityPayments: "Plăți Utilități",
		markMultipleAsPaid: "Marchează Mai Multe ca Plătite",
		totalDue: "Total de plată",
		totalOverdue: "Total restant",
		requestPeriod: "Perioada de solicitare",
		collectionCode: "Cod Incasare",
		clientCode: "Cod Client",
		nlcCode: "Cod NLC",
		atmCode: "Cod ATM",
		//plăți
		addPayment: "Adaugă Plată",
		addRentPayment: "Adaugă Chirie",
		addAdministrationFeePayment: "Adaugă Comision de Administrare",
		editCustomOwnerPlanList: "Editează Pachetul de servicii",
		paymentDate: "Data plății",
		paymentDirection: "Direcția plății",
		documentNumber: "Număr Document",
		method: "Metoda",
		amount: "Sumă",
		currency: "Monedă",
		type: "Tip",
		billedTo: "Facturat către",
		billedToName: "Nume Facturat",
		billedToType: "Tip Facturat",
		//depozite de securitate
		addSecurityDeposit: "Adaugă Garantie",
		//extrasuri de cont financiar
		addFinancialStatement: "Adaugă Declaratie Finante",
		ownerHandlesFinancials: "Proprietarul Se Ocupă de Finante",
		//documente
		addDocument: "Adaugă Document",
		addProtocol: "Adaugă Proces Verbal",
		tenantAssociationNotification: "Notificare Asociație Chiriași",
		protocols: "Procese Verbale",
		//conturi
		addAccount: "Adaugă Cont",
		accountInformation: "Informații Cont",
		provider: "Furnizor",
		userName: "Nume Utilizator",
		password: "Parolă",
		//activități
		addActivity: "Adaugă Activitate",
		//chiriași
		addLease: "Adaugă Contract de Închiriere",
		addAdditionalDocument: "Adaugă Document Adițional",
		addTenant: "Adaugă Chiriaș",
		addReport: "Adaugă Proces Verbal",
		ownerHandlesSanitationDeclaration: "Proprietarul Se Ocupă de Declaratia de Salubritate",
		formerTenants: "Chiriași Anteriori",
		formerContacts: "Contacte Anterioare",
		reports: "Procese Verbale",
		//notificări
		sendNotification: "Trimite Notificare",
		systemNotifications: "Notificări de Sistem",
		//tichete
		addTicket: "Adaugă Tichet",
		//mesaje
		sendMessage: "Trimite Mesaj",
	},

	misc: {
		loading: "Se încarcă...",
		search: "Căutare...",
		legend: "Legendă",
		cancel: "Anulare",
		save: "Salvare",
		edit: "Editează",
	},
};
