import { Card, CardBody, CardHeader, Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import PaymentsRow from "./PaymentsRow";
import React from "react";
import { useTranslation } from "react-i18next";

export default function PropertyPaymentsCard({ payments, address, propertyId, stripeCustomerId, name, email, phoneNumber }) {
	const outstandingPayments = payments.filter((payment) => !payment.paymentDate);
	const paidPayments = payments.filter((payment) => payment.paymentDate);
	const { t } = useTranslation();
	return (
		<Card overflowX={{ sm: "scroll", xl: "hidden" }}>
			<CardHeader>
				<Text fontSize="xl">Address: {address}</Text>
			</CardHeader>
			<CardBody>
				<Table variant="simple" size="sm">
					<Thead>
						<Tr my=".8rem" pl="0px">
							<Th pl="0px">{t("table.issuedDate")}</Th>
							<Th>{t("table.status")}</Th>
							<Th>{t("table.dueDate")}</Th>
							<Th>{t("table.amount")}</Th>
							<Th>{t("table.method")}</Th>
							<Th>{t("table.type")}</Th>
							<Th>{t("table.paidDate")}</Th>
							<Th></Th>
						</Tr>
					</Thead>
					<Tbody>
						{outstandingPayments.map((row, index) => {
							return (
								<PaymentsRow
									customerId={stripeCustomerId}
									propertyId={propertyId}
									key={index}
									id={row.id}
									documents={row.documents}
									date={row.issueDate || row.issuedDate}
									address={row.address}
									paymentDate={row.paymentDate}
									code={row.invoiceID || row.number}
									amount={row.amount}
									currency={row.currency}
									type={row.type}
									status={row.paymentDate ? "paid" : "due"}
									logo={row.logo}
									format={row.format}
									method={row.paymentMethod}
									dueDate={row.dueDate}
									billedTo={{ name, email, phoneNumber }}
								/>
							);
						})}
						{paidPayments.map((row, index) => {
							return (
								<PaymentsRow
									customerId={stripeCustomerId}
									propertyId={propertyId}
									key={index}
									id={row.id}
									documents={row.documents}
									date={row.issueDate || row.issuedDate}
									address={row.address}
									paymentDate={row.paymentDate}
									code={row.invoiceID || row.number}
									amount={row.amount}
									currency={row.currency}
									type={row.type}
									status={row.paymentDate ? "paid" : "due"}
									logo={row.logo}
									format={row.format}
									method={row.paymentMethod}
									billedTo={{ name, email, phoneNumber }}
									dueDate={row.dueDate}
								/>
							);
						})}
						{payments.length === 0 && (
							<Tr>
								<Td colSpan="8">{t("table.noData")}</Td>
							</Tr>
						)}
					</Tbody>
				</Table>
			</CardBody>
		</Card>
	);
}
