import React from "react";
import { useSelector } from "react-redux";
import { Card, CardHeader, Stack, Text } from "@chakra-ui/react";
import PropertyMessagesCard from "./PropertyMessagesCard";
import { getPropertyAddress } from "../../../utils/verifier";
import { selectActiveProperties } from "../../../redux/user/userSlice";
import { useTranslation } from "react-i18next";

export default function Messages() {
	const properties = useSelector(selectActiveProperties);
	const user = useSelector((state) => state.user?.user);
	const { t } = useTranslation();
	return (
		<Stack>
			<Card>
				<CardHeader>
					<Text fontSize="xl" fontWeight="bold">
						{t("navbarMenu.messages")}
					</Text>
				</CardHeader>
			</Card>
			{properties.map((property) => (
				<PropertyMessagesCard
					address={getPropertyAddress(property)}
					propertyId={property.id}
					messages={property.messages}
					name={user.name}
					email={user.email}
				/>
			))}
		</Stack>
	);
}
