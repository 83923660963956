import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Card, CardBody, CardHeader, Stack, Text } from "@chakra-ui/react";

import { useTranslation } from "react-i18next";
import PropertyCustomOwnerPlansCard from "./PropertyCustomOwnerPlansCard";
import { getPropertyAddress } from "../../../utils/verifier";
import { selectActiveProperties } from "../../../redux/user/userSlice";

export default function CustomOwnerPlans() {
	const properties = useSelector(selectActiveProperties);
	const user = useSelector((state) => state.user?.user);
	const customOwnerPlansAvailable = useSelector((state) => state.user.user?.customOwnerPlans);
	const { t } = useTranslation();
	return (
		<Stack>
			<Card>
				<CardHeader>
					<Text fontSize="xl" fontWeight="bold">
						{t("navbarMenu.customOwnerPlans")}
					</Text>
				</CardHeader>
				<CardBody>
					<Text>
						Selectati pachetele de servicii dorite pentru fiecare proprietate. Acestea se vor adauga automat la valoarea comisionului de administrare lunar
						aferent proprietatii.
					</Text>
				</CardBody>
			</Card>
			{properties.map((property) => (
				<PropertyCustomOwnerPlansCard
					address={getPropertyAddress(property)}
					propertyId={property.id}
					customOwnerPlans={property.customOwnerPlans || []}
					customOwnerPlansAvailable={customOwnerPlansAvailable}
				/>
			))}
			{customOwnerPlansAvailable.length === 0 && (
				<Card>
					<CardBody>
						<Text>Nu exista pachete de servicii disponibile. Va rugam sa contactati administratorul pentru mai multe informatii.</Text>
					</CardBody>
				</Card>
			)}
		</Stack>
	);
}
