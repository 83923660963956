import { DownloadIcon, HamburgerIcon } from "@chakra-ui/icons";
import {
	Box,
	Button,
	Flex,
	Icon,
	Spacer,
	Text,
	useColorModeValue,
	Badge,
	Tr,
	Td,
	Tooltip,
	UnorderedList,
	Popover,
	PopoverTrigger,
	PopoverContent,
	PopoverArrow,
	PopoverCloseButton,
	PopoverHeader,
	PopoverBody,
	ListItem,
	useDisclosure,
	Stack,
	FormControl,
	FormLabel,
	Input,
	Select,
	Link,
	Collapse,
	Checkbox,
	useToast,
	Textarea,
	Spinner,
	InputGroup,
	InputRightElement,
} from "@chakra-ui/react";
import React from "react";
import { BsThreeDots } from "react-icons/bs";
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton } from "@chakra-ui/react";
import { Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import moment from "moment/moment";
import Dropzone from "../Dropzone/Dropzone";
import { useState } from "react";
import { _arrayBufferToBase64 } from "../../utils/convertors";
import { API } from "../../utils/http";
import { useDispatch, useSelector } from "react-redux";
import { addPaymentDocumentAsync, markPaymentAsPaidAsync, markRentAsPaidAsync, removePaymentAsync, updateStatus } from "../../redux/user/userSlice";
import { markAsPaidVerifier } from "../../utils/verifier";
import { getPaymentStatus } from "../../utils/utils";
import { fullDateFormat, fullDateTimeFormat } from "../../utils/types";
function CombinedPaymentsRow(props) {
	const { payment, owner } = props;
	const { amount, issuedDate, currency, method, type, number, id, payments, documents, paymentDirection } = payment;
	const loading = useSelector((state) => state.user.status) === "loading";
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { isOpen: isDeleteConfirmationOpen, onOpen: onDeleteConfirmationOpen, onClose: onDeleteConfirmationClose } = useDisclosure();
	const [addDocumentInformation, setAddDocumentInformation] = useState({ documentName: "", documentData: "", contentType: "" });
	const toast = useToast();
	const dispatch = useDispatch();
	const handleGetDocument = (documentId, documentName) => {
		const payload = { ownerEmail: owner.email, documentId, paymentId: id, documentName };
		console.log(payload);
		API.put("get-owner-payment-document", payload)
			.then((r) => {
				console.log(r);
				window.open(r.data.url, "_blank");
			})
			.catch((e) => {
				console.log(e);
				toast({
					title: "Error encountered",
					description: e.response.data.error.message,
					status: "error",
					duration: 9000,
					isClosable: true,
				});
			});
	};
	const handleDeletePayment = () => {
		const payload = { ownerEmail: owner.email, paymentId: id };
		console.log(payload);
		//dispatch remove payment
		dispatch(removePaymentAsync(payload))
			.then((r) => {
				if (r.error) {
					toast({
						title: "Error encountered",
						description: r.error.message,
						status: "error",
						duration: 9000,
						isClosable: true,
					});
				} else {
					toast({
						title: "Success",
						description: "Payment deleted successfully",
						status: "success",
						duration: 9000,
						isClosable: true,
					});
				}
			})
			.catch((e) => {
				console.log(e);
			})
			.finally(() => {
				onDeleteConfirmationClose();
			});
	};
	const { isOpen: isDifferentCurrencyOpen, onToggle: onDifferentCurrencyToggle, onClose: onDifferentCurrencyClose } = useDisclosure();

	const renderDeleteConfirmationModal = () => {
		return (
			<Modal isOpen={isDeleteConfirmationOpen} onClose={onDeleteConfirmationClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Confirm delete?</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Text>Are you sure you want to delete this payment? </Text>
						<Text>
							This action{" "}
							<Text as="span" fontWeight={"bold"}>
								cannot
							</Text>{" "}
							be undone!{" "}
						</Text>
					</ModalBody>

					<ModalFooter>
						<Button colorScheme="red" mr={3} onClick={handleDeletePayment}>
							Delete
						</Button>
						<Button colorScheme="blue" mr={3} onClick={onDeleteConfirmationClose}>
							Close
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		);
	};
	const renderViewDocumentsModal = () => {
		return (
			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Details</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Stack>
							<Box>
								<Text as={"span"} fontWeight={"bold"}>
									Description:
								</Text>{" "}
								{/* <Text as={"span"}>{description || "-"}</Text> */}
								IMPLEMENT DESCRIPTION
							</Box>
							<Box>
								<Text as={"span"} fontWeight={"bold"}>
									Type:
								</Text>{" "}
								<Text as={"span"}>{type}</Text>
							</Box>
							<Box>
								<Text as={"span"} fontWeight={"bold"}>
									Issued Date:
								</Text>{" "}
								<Text as={"span"}>{moment(issuedDate).format(fullDateTimeFormat)}</Text>
							</Box>
							<Box>
								<Text as={"span"} fontWeight={"bold"}>
									Amount:
								</Text>{" "}
								<Text as={"span"}>
									{amount} {currency}
								</Text>
							</Box>
							<UnorderedList>
								{documents?.map((d, index) => (
									<ListItem key={index}>
										<Text
											color="teal.500"
											textDecorationLine={"underline"}
											cursor={"pointer"}
											onClick={() => {
												handleGetDocument(d.id, d.documentName);
											}}
										>
											{d.documentName}
											<DownloadIcon marginLeft={2} />
										</Text>
									</ListItem> //TODO: make documentName clickable and download the clicked document
								))}
							</UnorderedList>
							{/* <FormControl>
								<FormLabel display={"inline"}>Add document:</FormLabel>
								<Dropzone
									onDrop={onAddDocumentDrop}
									accept={{
										"application/pdf": [".pdf"],
										"application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
										"application/msword": [".doc"],
									}}
									description="Upload PDF,DOC or DOCX Format"
								/>
							</FormControl> */}
						</Stack>
					</ModalBody>

					<ModalFooter>
						<Button colorScheme="red" mr={3} onClick={onClose}>
							Close
						</Button>
						{/* <Button colorScheme="blue" mr={3} onClick={handleAddDocument}>
							Add document
						</Button> */}
					</ModalFooter>
				</ModalContent>
			</Modal>
		);
	};
	return (
		<Tr>
			<Td minWidth={{ sm: "150px" }} pl="0px">
				<Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
					{/* <Avatar src={logo} w="50px" borderRadius="12px" me="18px" /> */}
					<Flex direction="column">
						<Text fontSize="md" minWidth="100%">
							{moment(issuedDate).format(fullDateTimeFormat)}
						</Text>
						<Text fontSize="sm" fontWeight="normal" minWidth="100%" hidden={!number}>
							Document Number: {number}
						</Text>
					</Flex>
				</Flex>
			</Td>
			<Td>
				<Text>
					{paymentDirection === "outgoing" || type === "administrationFee" ? "-" : "+"}
					{amount} {currency}
				</Text>
			</Td>
			<Td>
				<Text fontSize="md">{method}</Text>
			</Td>
			<Td>
				<Text fontSize="md">{type}</Text>
			</Td>

			<Td>
				<Menu closeOnSelect={false}>
					<MenuButton>
						<Icon as={HamburgerIcon} />
					</MenuButton>
					<MenuList>
						<MenuItem onClick={onOpen}>View Details</MenuItem>
						{/* <MenuItem onClick={onDeleteConfirmationOpen}>Delete</MenuItem> */}
					</MenuList>
				</Menu>
				{renderViewDocumentsModal()}
				{renderDeleteConfirmationModal()}
			</Td>
		</Tr>
	);
}

export default CombinedPaymentsRow;
