import {
	Box,
	Button,
	Stack,
	useDisclosure,
	Modal,
	ModalOverlay,
	ModalHeader,
	ModalContent,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	Table,
	Tbody,
	Text,
	Th,
	Thead,
	Tr,
	FormControl,
	FormLabel,
	Input,
	Select,
	useToast,
	WrapItem,
	Wrap,
	Td,
	Textarea,
	InputGroup,
	InputRightAddon,
	Badge,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import Dropzone from "../../Components/Dropzone/Dropzone";
import { addOwnerDocumentAsync, updateOwnerAsync, uploadOwnerIdAsync } from "../../redux/user/userSlice";
import { _arrayBufferToBase64 } from "../../utils/convertors";
import Gallery from "../../Components/Gallery/Gallery";
import { API } from "../../utils/http";
import OwnerDocumentsTableRow from "../../Components/TableRows/OwnerDocumentsTableRow";
import moment from "moment";
import { fullDateFormat } from "../../utils/types";

export default function PersonalInformation({ owner }) {
	const initialIdInformation = { idData: "", contentType: "", ownerEmail: owner.email, expirationDate: "", dateOfBirth: "" };
	const initialOwnerInformation = {
		name: owner.name,
		phoneNumber: owner.phoneNumber,
		phoneNumber2: owner.phoneNumber2,
		ownerEmail: owner.email,
		expirationDate: owner.identificationDocument?.expirationDate,
		dateOfBirth: owner.identificationDocument?.dateOfBirth,
		bankAccount: owner.bankDetails.account,
		administrationFeePercent: owner.bankDetails.administrationFeePercent,
		bankAccountCurrency: owner.bankDetails.currency,
	};
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { isOpen: isAddDocumentOpen, onOpen: onAddDocumentOpen, onClose: onAddDocumentClose } = useDisclosure();
	const [isEditing, setIsEditing] = useState(false);
	const [idInformation, setIdInformation] = useState(initialIdInformation);
	const [documentInformation, setDocumentInformation] = useState();
	const [id, setId] = useState([]);
	const [ownerInformation, setOwnerInformation] = useState(initialOwnerInformation);
	const dispatch = useDispatch();
	const toast = useToast();
	const uploadId = () => {
		console.log(idInformation);
		dispatch(uploadOwnerIdAsync(idInformation))
			.then((r) => {
				if (r.error?.message)
					toast({
						title: "Error encountered",
						description: r.error.message,
						status: "error",
						duration: 9000,
						isClosable: true,
					});
				else {
					toast({
						title: "Success!",
						description: "ID Added!",
						status: "success",
						duration: 2000,
						isClosable: true,
					});
				}
			})
			.finally(() => {
				setIdInformation(initialIdInformation);
				onClose();
			});
	};
	const addDocument = () => {
		console.log(documentInformation);
		const payload = { ...documentInformation, email: owner.email };
		console.log(payload);
		dispatch(addOwnerDocumentAsync(payload))
			.then((r) => {
				if (r.error?.message)
					toast({
						title: "Error encountered",
						description: r.error.message,
						status: "error",
						duration: 9000,
						isClosable: true,
					});
				else {
					toast({
						title: "Success!",
						description: "Document Added!",
						status: "success",
						duration: 2000,
						isClosable: true,
					});
				}
			})
			.finally(() => {
				setDocumentInformation();
				onAddDocumentClose();
			});
	};
	const onDocumentDrop = async (acceptedFiles) => {
		console.log(acceptedFiles);
		const buffer = await acceptedFiles[0].arrayBuffer();
		const contentType = acceptedFiles[0].type;
		const documentName = acceptedFiles[0].name;
		// setIdInformation({ ...idInformation, idData: _arrayBufferToBase64(buffer), contentType });
		setDocumentInformation({ ...documentInformation, documentData: _arrayBufferToBase64(buffer), documentName, contentType });
	};
	useEffect(() => {
		console.log("getting owner id");
		if (owner.identificationDocument.id)
			API.put("get-owner-document-url", { ownerEmail: owner.email, documentId: owner.identificationDocument.id }).then((r) => {
				console.log(r.data);
				console.log(r.data.id);
				setId([{ ...owner.identificationDocument, url: r.data.url }]);
			});
	}, [owner]);
	const onIdDrop = async (acceptedFiles) => {
		console.log(acceptedFiles);
		const buffer = await acceptedFiles[0].arrayBuffer();
		const contentType = acceptedFiles[0].type;
		setIdInformation({ ...idInformation, idData: _arrayBufferToBase64(buffer), contentType });
	};
	const handleSave = () => {
		console.log(ownerInformation);
		dispatch(updateOwnerAsync(ownerInformation));
	};
	const handleIdInformationChange = (e) => {
		setIdInformation({ ...idInformation, [e.target.name]: e.target.value });
	};
	const handleOwnerInformationChange = (e) => {
		setOwnerInformation({ ...ownerInformation, [e.target.name]: e.target.value });
	};
	const renderAddDocumentModal = () => (
		<Modal isOpen={isAddDocumentOpen} onClose={onAddDocumentClose} size={"xl"}>
			<ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
			<ModalContent>
				<ModalHeader>
					<Text>Add Document</Text>
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Stack>
						<FormControl display={"flex"} alignItems="center">
							<FormLabel display={"inline"}>Type:</FormLabel>
							<Select
								width={"fit-content"}
								onChange={(e) => {
									setDocumentInformation({ ...documentInformation, type: e.target.value });
								}}
								name="type"
								placeholder="Type"
								_placeholder={{ color: "gray.500" }}
							>
								<option value="certificate">Adeverinta</option>
								<option value="passport">Pasaport</option>
								<option value="passport">Extras de Cont</option>
								<option value="other">Altele</option>
							</Select>
						</FormControl>
						<Dropzone onDrop={onDocumentDrop} accept={{ "application/*": [".pdf"], "image/*": [] }} description="Upload a Document" maxFiles={1} />
					</Stack>
				</ModalBody>
				<ModalFooter>
					<Button colorScheme="red" onClick={onAddDocumentClose} mr={3}>
						Cancel
					</Button>
					<Button colorScheme="blue" onClick={addDocument}>
						Upload
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
	const renderUploadIdModal = () => (
		<Modal isOpen={isOpen} onClose={onClose} size={"xl"}>
			<ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
			<ModalContent>
				<ModalHeader>
					<Text>Add ID</Text>
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Text fontWeight={"bold"} marginBottom="20px">
						Add ID
					</Text>
					<Stack>
						<FormControl>
							<FormLabel display={"inline"}>Expiration Date:</FormLabel>
							<Input width={"fit-content"} type="date" value={idInformation.expirationDate} name="expirationDate" onChange={handleIdInformationChange} />
						</FormControl>
						<FormControl>
							<FormLabel display={"inline"}>Date of Birth:</FormLabel>
							<Input width={"fit-content"} type="date" value={idInformation.dateOfBirth} name="dateOfBirth" onChange={handleIdInformationChange} />
						</FormControl>
						<FormControl>
							<FormLabel>ID:</FormLabel>
							<Dropzone
								onDrop={onIdDrop}
								accept={{ "application/*": [".pdf"], "image/*": [".jpeg", ".jpg", ".png"] }}
								description="Upload an Image"
								maxFiles={1}
							/>
						</FormControl>
					</Stack>
				</ModalBody>
				<ModalFooter>
					<Button colorScheme="red" onClick={onClose} mr={3}>
						Cancel
					</Button>
					<Button colorScheme="blue" onClick={uploadId}>
						Upload
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
	return (
		<Wrap spacing={20}>
			<WrapItem>
				<Stack>
					<Stack direction={"row"} alignItems="center">
						<Text fontWeight={"bold"}>Name:</Text>
						{isEditing ? (
							<Input size="sm" value={ownerInformation.name} width="fit-content" name="name" onChange={handleOwnerInformationChange} />
						) : (
							<Text>{ownerInformation.name}</Text>
						)}
					</Stack>
					<Stack direction={"row"} alignItems="center">
						<Text fontWeight={"bold"}>Phone Number:</Text>
						{isEditing ? (
							<Input size="sm" value={ownerInformation.phoneNumber} width="fit-content" name="phoneNumber" onChange={handleOwnerInformationChange} />
						) : (
							<Text>{ownerInformation.phoneNumber}</Text>
						)}
					</Stack>
					<Stack direction={"row"} alignItems="center">
						<Text fontWeight={"bold"}>Alternative Phone Number:</Text>
						{isEditing ? (
							<Input size="sm" value={ownerInformation.phoneNumber2} width="fit-content" name="phoneNumber2" onChange={handleOwnerInformationChange} />
						) : (
							<Text>{ownerInformation.phoneNumber2 || "-"}</Text>
						)}
					</Stack>
					<Stack direction={"row"}>
						<Text fontWeight={"bold"}>Email:</Text>
						<Text>{owner.email}</Text>
					</Stack>
					<Stack direction="row">
						<Text fontWeight={"bold"}>Bank Account:</Text>
						{isEditing ? (
							<Textarea size="xs" value={ownerInformation.bankAccount} onChange={handleOwnerInformationChange} name="bankAccount" />
						) : (
							<Text>{ownerInformation.bankAccount || "-"}</Text>
						)}
					</Stack>
					<Stack diretion="row">
						<Text fontWeight={"bold"}>Bank Account Currency:</Text>
						{isEditing ? (
							<Select
								// size="xs"
								value={ownerInformation.bankAccountCurrency}
								onChange={handleOwnerInformationChange}
								name="bankAccountCurrency"
							>
								<option value="eur">EUR</option>
								<option value="ron">RON</option>
							</Select>
						) : (
							<Text>{ownerInformation.bankAccountCurrency || "-"}</Text>
						)}
					</Stack>
					<Stack direction="row">
						<Text fontWeight={"bold"}>Administration Fee Percent:</Text>
						{isEditing ? (
							// <Textarea size="xs" value={details.administrationFeePercent} onChange={handleDetailsChange} name="administrationFeePercent" />
							<InputGroup>
								<Input
									name="administrationFeePercent"
									placeholder="Administration fee percent"
									value={ownerInformation.administrationFeePercent}
									onChange={handleOwnerInformationChange}
									_placeholder={{ color: "gray.500" }}
									type="number"
								/>
								<InputRightAddon children="%" />
							</InputGroup>
						) : (
							<Text>{ownerInformation.administrationFeePercent || "-"} %</Text>
						)}
					</Stack>
					<Stack direction={"row"}>
						<Text fontWeight={"bold"}>Id Expiration Date:</Text>
						{owner.identificationDocument?.expirationDate ? (
							<Badge colorScheme={moment(owner.identificationDocument.expirationDate).unix() < moment().unix() ? "red" : "green"}>
								{moment(owner.identificationDocument.expirationDate).format(fullDateFormat)}
							</Badge>
						) : (
							<Text>No ID uploaded</Text>
						)}
					</Stack>
					<Stack direction={"row"}>
						<Text fontWeight={"bold"}>Date of Birth:</Text>
						<Text>{owner.identificationDocument ? moment(owner.identificationDocument?.dateOfBirth).format(fullDateFormat) : "No ID uploaded"}</Text>
					</Stack>
					<Box>
						<Text fontWeight={"bold"}>Id:</Text>
						<Gallery items={id} />
					</Box>
					<Stack>
						<Button width={"fit-content"} onClick={onOpen} colorScheme={"blue"}>
							Upload Id
						</Button>
						{renderUploadIdModal()}
					</Stack>
					<Stack>
						<Button
							width={"fit-content"}
							colorScheme={"green"}
							onClick={() => {
								if (isEditing) handleSave();
								setIsEditing(!isEditing);
							}}
						>
							{isEditing ? "Save" : "Edit"}
						</Button>
					</Stack>
				</Stack>
			</WrapItem>
			<WrapItem>
				<Box overflowX="auto">
					<Stack direction={"row"} alignItems={"center"}>
						<Text fontWeight={"bold"} fontSize={"large"}>
							Additional Documents
						</Text>
						<Button colorScheme="blue" onClick={onAddDocumentOpen}>
							Add Document
						</Button>
						{renderAddDocumentModal()}
					</Stack>
					<Table variant="simple">
						<Thead>
							<Tr whiteSpace={"nowrap"}>
								<Th>Added Date</Th>
								<Th>Name</Th>
								<Th>Type</Th>
								<Th>Actions</Th>
							</Tr>
						</Thead>
						<Tbody>
							{owner.documents?.length !== 0 ? (
								<>
									{owner.documents?.map((row, index) => {
										return <OwnerDocumentsTableRow key={index} row={row} ownerEmail={owner.email} />;
									})}
								</>
							) : (
								<Tr>
									<Td>No Documents Yet</Td>
								</Tr>
							)}
						</Tbody>
					</Table>
				</Box>
			</WrapItem>
		</Wrap>
	);
}
