import { DownloadIcon, HamburgerIcon } from "@chakra-ui/icons";
import {
	Button,
	Flex,
	Icon,
	Text,
	useColorModeValue,
	Badge,
	Tr,
	Td,
	UnorderedList,
	ListItem,
	useDisclosure,
	useToast,
	Stack,
	FormControl,
	FormLabel,
	Box,
	Input,
	Textarea,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton } from "@chakra-ui/react";
import { Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import moment from "moment/moment";
import { API } from "../../../utils/http";
import { useTranslation } from "react-i18next";
import { markAsPaidVerifier } from "../../../utils/verifier";
import { markPaymentAsPaidAsync, markRentAsPaidAsync, markTenantPaymentAsPaidAsync } from "../../../redux/user/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { fullDateFormat, fullDateTimeFormat } from "../../../utils/types";
import WithConfirmationPayment from "../../../Components/WithConfirmation/WithConfirmationPayment";
function PaymentsRow(props) {
	const loading = useSelector((state) => state.user.status) === "loading";
	const currentUserEmail = useSelector((state) => state.auth.currentUserEmail);

	const { date, code, status, amount, type, paymentDate, address, method, documents, propertyId, id, currency, customerId, billedTo, dueDate } = props;
	const bgStatus = useColorModeValue("red.400", "red.700");
	const colorStatus = useColorModeValue("white", "white");
	const initialMarkAsPaidInformation = {
		billedTo: {
			...billedTo,
			type: "tenant",
		},
		documents: [],
		paymentDate: moment().format("yyyy-MM-DD"),
		paymentMethod: "personal",
		propertyId: propertyId,
		description: "",
	};
	const { isOpen: isMarkAsPaidOpen, onOpen: onMarkAsPaidOpen, onClose: onMarkAsPaidClose } = useDisclosure();

	const [error, setError] = useState({});
	const toast = useToast();
	const dispatch = useDispatch();
	const [markAsPaidInformation, setMarkAsPaidInformation] = useState(initialMarkAsPaidInformation);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { t } = useTranslation();
	const handleGetDocument = (documentId) => {
		const payload = { propertyId, documentId, paymentId: id };
		console.log(payload);
		API.put("get-payment-document", payload).then((r) => {
			console.log(r);
			window.open(r.data.url, "_blank");
		});
	};
	const handleChangeMarkAsPaidInformation = (e) => {
		setMarkAsPaidInformation({ ...markAsPaidInformation, [e.target.name]: e.target.value });
	};
	const handleSaveMarkAsPaid = () => {
		//get these from api
		// const admCtr = property.documents.find((d) => d.type === "administrationContract" && d.active);
		// const leaseCtr = property.documents.find((d) => d.type === "lease" && d.active);
		console.log(markAsPaidInformation);
		console.log("payment id: ", id);
		const payload = {
			...markAsPaidInformation,
			paymentId: id,
			// administrationContractDate: admCtr?.startingDate || "Not available",
			// administrationContractNumber: admCtr?.number || "Not available",
			// leaseNumber: leaseCtr?.number || "Not available",
			// leaseDate: leaseCtr?.startingDate || "Not available",
		};
		console.log(payload);
		const errors = markAsPaidVerifier(payload);
		console.log(errors);
		setError(errors);
		if (Object.keys(errors).length > 0) {
			toast({
				title: "Error encountered",
				description: "Please fill in all the required fields",
				status: "error",
				duration: 9000,
				isClosable: true,
			});
			return;
		}

		if (type === "rent") {
			console.log("mark rent as paid");
			// dispatch(markRentAsPaidAsync(payload)).finally(() => {
			// 	setMarkAsPaidInformation(initialMarkAsPaidInformation);
			// });
		} else {
			dispatch(markTenantPaymentAsPaidAsync(payload))
				.then((r) => {
					if (r.error) {
						toast({
							title: "Error encountered",
							description: r.error.message,
							status: "error",
							duration: 9000,
							isClosable: true,
						});
					} else {
						toast({
							title: "Success",
							description: "Payment updated successfully",
							status: "success",
							duration: 9000,
							isClosable: true,
						});
						onMarkAsPaidClose();
					}
				})
				.finally(() => {
					setMarkAsPaidInformation(initialMarkAsPaidInformation);
				});
			// onMarkAsPaidClose();
		}
	};
	const payPayment = async () => {
		console.log(customerId);

		// API.post("/create-payment-checkout-session", { customerId, paymentId: id, propertyId, billedTo, amount, currency }).then((r) => {
		// 	console.log(r.data);
		// 	window.open(r.data.sessionUrl, "_self");
		// });
		const r = await API.post("/create-payment-checkout-session", { customerId, paymentId: id, propertyId, billedTo, amount, currency });
		console.log(r.data);
		window.open(r.data.sessionUrl, "_self");
	};
	const renderViewDocumentsModal = () => {
		return (
			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Documents</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<UnorderedList>
							{documents?.map((d, index) => (
								<ListItem key={index}>
									<Text
										color="teal.500"
										textDecorationLine={"underline"}
										cursor={"pointer"}
										onClick={() => {
											handleGetDocument(d.id);
										}}
									>
										{d.documentName}
										<DownloadIcon marginLeft={2} />
									</Text>
								</ListItem> //TODO: make documentName clickable and download the clicked document
							))}
							{!documents?.length && <Text>{t("table.noData")}</Text>}
						</UnorderedList>
					</ModalBody>

					<ModalFooter>
						<Button colorScheme="blue" mr={3} onClick={onClose}>
							Close
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		);
	};
	const renderMarkAsPaidModal = () => {
		return (
			<Modal isOpen={isMarkAsPaidOpen} onClose={onMarkAsPaidClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Mark as Paid</ModalHeader>
					{/* <ModalCloseButton /> */}
					<ModalBody>
						<Stack>
							<Stack>
								<Box>
									<Text as={"span"} fontWeight={"bold"}>
										Amount:
									</Text>{" "}
									<Text as={"span"}>
										{amount} {currency}
									</Text>
								</Box>
								<Box>
									<Text as={"span"} fontWeight={"bold"}>
										Type:
									</Text>{" "}
									<Text as={"span"}>{t(`types.${type}`)}</Text>
								</Box>
							</Stack>
							<FormControl>
								<FormLabel display={"inline"}>Paid Date:</FormLabel>
								<Input
									width={"fit-content"}
									type="date"
									name="paymentDate"
									placeholder="Payment Date"
									_placeholder={{ color: "gray.500" }}
									value={markAsPaidInformation.paymentDate}
									onChange={handleChangeMarkAsPaidInformation}
								/>
							</FormControl>
							<FormControl>
								<FormLabel display={"inline"}>Description:</FormLabel>
								<Textarea
									isInvalid={error?.description}
									width={"fit-content"}
									type="text"
									name="description"
									placeholder="Description"
									_placeholder={{ color: "gray.500" }}
									value={markAsPaidInformation.description}
									onChange={handleChangeMarkAsPaidInformation}
								/>
							</FormControl>
							{/* <FormControl>
								<FormLabel display={"inline"}>Documents:</FormLabel>
								<Dropzone
									onDrop={onDocumentDrop}
									accept={{
										"application/pdf": [".pdf"],
										"application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
										"application/msword": [".doc"],
									}}
									description="Upload PDF,DOC or DOCX Format"
								/>
							</FormControl> */}
						</Stack>
					</ModalBody>

					<ModalFooter>
						<Button
							isLoading={loading}
							colorScheme="red"
							mr={3}
							onClick={() => {
								onMarkAsPaidClose();
							}}
						>
							{t("misc.cancel")}
						</Button>
						<Button colorScheme="blue" mr={3} onClick={handleSaveMarkAsPaid} isLoading={loading}>
							{t("misc.save")}
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		);
	};
	return (
		<Tr>
			<Td minWidth={{ sm: "150px" }} pl="0px">
				<Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
					{/* <Avatar src={logo} w="50px" borderRadius="12px" me="18px" /> */}
					<Flex direction="column">
						<Text fontSize="md" minWidth="100%">
							{moment(date).format(fullDateTimeFormat)}
						</Text>
						<Text fontSize="sm" fontWeight="normal" minWidth="100%" hidden={!code}>
							Document Number: {code}
						</Text>
					</Flex>
				</Flex>
			</Td>
			<Td>
				<Badge
					bg={status === "paid" ? "green.400" : bgStatus}
					color={status === "paid" ? "white" : colorStatus}
					fontSize="16px"
					p="3px 10px"
					borderRadius="8px"
				>
					{status}
				</Badge>
			</Td>
			<Td>
				<Text>{moment(dueDate).format(fullDateFormat)}</Text>
			</Td>
			<Td>
				<Text fontSize="md" pb=".5rem">
					{amount} {currency}
				</Text>
			</Td>
			<Td>
				<Text fontSize="md" pb=".5rem">
					{method ? method : "-"}
				</Text>
			</Td>
			<Td>
				<Text fontSize="md" pb=".5rem">
					{t(`types.${type}`)}
				</Text>
			</Td>
			<Td>
				<Text fontSize="md" pb=".5rem">
					{paymentDate ? moment(paymentDate).format(fullDateTimeFormat) : "-"}
				</Text>
			</Td>

			<Td>
				{/* <Button p="0px" bg="transparent">
					<Tooltip label="Download" fontSize="md">
						<Icon as={DownloadIcon} color={"teal.400"} boxSize={6}></Icon>
					</Tooltip>
				</Button> */}
				<Menu closeOnSelect={false}>
					{/* <MenuButton as={Icon} icon={<HamburgerIcon />} /> */}
					<MenuButton>
						<Icon as={HamburgerIcon} />
					</MenuButton>
					<MenuList>
						{/* <MenuItem
							isDisabled={status === "due"}
							onClick={() => {
								console.log("ceva");
							}}
						>
							Download disposition
						</MenuItem> */}
						<WithConfirmationPayment onConfirm={payPayment}>
							<MenuItem isDisabled={status !== "due"} hidden={currentUserEmail !== "omihai00@gmail.com"}>
								Pay
							</MenuItem>
						</WithConfirmationPayment>
						{/* <MenuItem
							isDisabled={status.toLowerCase() === "paid" || !type.toLowerCase().includes("utility")}
							onClick={() => {
								onMarkAsPaidOpen();
								console.log("payment id: ", id);
								console.log("payment type: ", type);
								console.log("payment amount: ", amount);
							}}
						>
							Mark as paid
						</MenuItem> */}
						<MenuItem onClick={onOpen}>View Documents</MenuItem>
						{/* <MenuItem onClick={onDeleteConfirmationOpen}>Delete</MenuItem> */}
					</MenuList>
				</Menu>
				{renderViewDocumentsModal()}
				{renderMarkAsPaidModal()}

				{/* {renderDeleteConfirmationModal()} */}
			</Td>
		</Tr>
	);
}

export default PaymentsRow;
