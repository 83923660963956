import {
	Button,
	Divider,
	FormControl,
	FormLabel,
	Icon,
	Input,
	InputGroup,
	ListItem,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Select,
	Stack,
	Td,
	Text,
	Textarea,
	Tr,
	UnorderedList,
	useDisclosure,
	useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";

import moment from "moment";
import { HamburgerIcon } from "@chakra-ui/icons";
import { useDispatch } from "react-redux";
import { removeCustomOwnerPlanAsync, updateCustomOwnerPlanAsync } from "../../redux/user/userSlice";
import { getPropertyAddress } from "../../utils/verifier";

export default function CustomOwnerPlansTableRow(props) {
	const toast = useToast();
	const { addedDate, name, description, price, id, properties } = props;
	const initialOwnerPlanInfo = {
		name: name,
		description: description,
		price: price,
		id: id,
		addedDate: addedDate,
	};
	const [editOwnerPlanInfo, setEditOwnerPlanInfo] = useState(initialOwnerPlanInfo);
	const dispatch = useDispatch();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();
	const { isOpen: isViewOpen, onOpen: onViewOpen, onClose: onViewClose } = useDisclosure();

	const [error, setError] = useState({});
	const handleChangeOwnerPlanInformation = (e) => {
		const { name, value } = e.target;
		setEditOwnerPlanInfo((prev) => ({ ...prev, [name]: value }));
	};
	const handleSave = () => {
		const errors = {};
		if (Object.keys(errors).length > 0) {
			setError(errors);
			return;
		}
		//save the data
		const payload = { ...editOwnerPlanInfo };
		console.log(payload);
		dispatch(updateCustomOwnerPlanAsync(payload))
			.then((r) => {
				if (r.error?.message)
					toast({
						title: "Error encountered",
						description: r.error.message,
						status: "error",
						duration: 9000,
						isClosable: true,
					});
				else {
					toast({
						title: "Success!",
						description: "Update!",
						status: "success",
						duration: 2000,
						isClosable: true,
					});
				}
			})
			.finally(() => {
				onClose();
			});
	};
	const handleDelete = () => {
		//delete the data
		console.log("Delete owner plan with id: ", id);
		const payload = { id: id };
		dispatch(removeCustomOwnerPlanAsync(payload))
			.then((r) => {
				if (r.error?.message)
					toast({
						title: "Error encountered",
						description: r.error.message,
						status: "error",
						duration: 9000,
						isClosable: true,
					});
				else {
					toast({
						title: "Success!",
						description: "Delete!",
						status: "success",
						duration: 2000,
						isClosable: true,
					});
				}
			})
			.finally(() => {
				onDeleteClose();
			});
	};
	const renderEditCustomOwnerPlanModal = () => {
		return (
			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Edit Owner Plan</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Stack>
							<FormControl>
								<FormLabel display={"inline"}>Name:</FormLabel>
								<Input
									isInvalid={error?.documentNumber}
									width={"fit-content"}
									type="text"
									name="name"
									placeholder="Name"
									_placeholder={{ color: "gray.500" }}
									value={editOwnerPlanInfo.name}
									onChange={handleChangeOwnerPlanInformation}
								/>
							</FormControl>
							<FormControl>
								<FormLabel display={"inline"}>Description:</FormLabel>
								<Textarea
									isInvalid={error?.documentNumber}
									width={"fit-content"}
									type="text"
									name="description"
									placeholder="Description"
									_placeholder={{ color: "gray.500" }}
									value={editOwnerPlanInfo.description}
									onChange={handleChangeOwnerPlanInformation}
								/>
							</FormControl>
							<FormControl display={"flex"} alignItems="center">
								<FormLabel display={"inline"}>Price:</FormLabel>
								<InputGroup>
									<Input
										maxW={24}
										isInvalid={error?.documentNumber}
										width={"fit-content"}
										type="number"
										name="amount"
										placeholder="Price"
										_placeholder={{ color: "gray.500" }}
										value={editOwnerPlanInfo.price.amount}
										onChange={(e) => {
											setEditOwnerPlanInfo((prev) => ({
												...prev,
												price: { ...prev.price, amount: e.target.value },
											}));
										}}
									/>
									<Select
										width={"fit-content"}
										name="currency"
										value={editOwnerPlanInfo.price.currency}
										onChange={(e) => {
											setEditOwnerPlanInfo((prev) => ({
												...prev,
												price: { ...prev.price, currency: e.target.value },
											}));
										}}
									>
										<option value="ron">RON</option>
										<option value="eur">EUR</option>
									</Select>
								</InputGroup>
							</FormControl>
						</Stack>
					</ModalBody>
					<ModalFooter>
						<Button colorScheme="red" mr={3} onClick={onClose}>
							Close
						</Button>
						<Button colorScheme="blue" mr={3} onClick={handleSave}>
							Save
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		);
	};
	const renderConfirmationModal = () => {
		return (
			<Modal isOpen={isDeleteOpen} onClose={onDeleteClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Delete Owner Plan</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Text>Are you sure you want to delete this owner plan?</Text>
					</ModalBody>
					<ModalFooter>
						<Button colorScheme="blue" mr={3} onClick={onDeleteClose}>
							Close
						</Button>
						<Button colorScheme="red" mr={3} onClick={handleDelete}>
							Delete
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		);
	};

	const renderViewCustomOwnerPlanModal = () => {
		// check how many properties are using this owner plan and show the property address and owner name
		return (
			<Modal isOpen={isViewOpen} onClose={onViewClose} size={"2xl"}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Vizualizare serviciu</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Text>Nume: {name}</Text>
						<Text>Descriere: {description}</Text>
						<Text>
							Pret: {price.amount} {price.currency}
						</Text>
						<Text>Data adaugare: {moment(addedDate).format("DD MMM YYYY - HH:mm")}</Text>
						<Divider />
						<Stack direction="row">
							<Text>Proprietati care au acest serviciu: </Text>
							<Text as="b">{properties.length}</Text>
						</Stack>
						<UnorderedList>
							{properties
								.sort((a, b) => a.owner.name > b.owner.name)
								.map((p) => (
									<ListItem key={p.id}>
										{p.owner.name} - {getPropertyAddress(p)}
									</ListItem>
								))}
						</UnorderedList>
					</ModalBody>
					<ModalFooter>
						<Button colorScheme="blue" mr={3} onClick={onViewClose}>
							Close
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		);
	};
	return (
		<>
			<Tr>
				<Td>
					<Text fontSize="md" pb=".5rem" whiteSpace={"nowrap"}>
						{name}
					</Text>
				</Td>
				<Td minWidth={{ sm: "250px" }}>
					<Text fontSize="md" pb=".5rem">
						{description}
					</Text>
				</Td>

				<Td>
					<Text fontSize="md" pb=".5rem">
						{price.amount} {price.currency}
					</Text>
				</Td>
				<Td pl="0px">
					<Text fontSize="sm" fontWeight="normal">
						{moment(addedDate).format("DD MMM YYYY - HH:mm")}
					</Text>
				</Td>
				<Td>
					<Menu closeOnSelect={false}>
						<MenuButton>
							<Icon as={HamburgerIcon} />
						</MenuButton>
						<MenuList>
							<MenuItem
								onClick={() => {
									onViewOpen();
								}}
							>
								View
							</MenuItem>
							<MenuItem
								onClick={() => {
									onOpen();
								}}
							>
								Edit
							</MenuItem>
							<MenuItem
								onClick={() => {
									onDeleteOpen();
								}}
							>
								Delete
							</MenuItem>
						</MenuList>
					</Menu>
					{renderEditCustomOwnerPlanModal()}
					{renderConfirmationModal()}
					{renderViewCustomOwnerPlanModal()}
				</Td>
			</Tr>
		</>
	);
}
