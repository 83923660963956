import { DownloadIcon, HamburgerIcon } from "@chakra-ui/icons";
import {
	Box,
	Button,
	Flex,
	Icon,
	Text,
	useColorModeValue,
	Badge,
	Tr,
	Td,
	UnorderedList,
	ListItem,
	useDisclosure,
	Stack,
	FormControl,
	FormLabel,
	Input,
	Select,
	Collapse,
	Checkbox,
	useToast,
	Textarea,
	Spinner,
	InputGroup,
	InputRightElement,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { BsThreeDots } from "react-icons/bs";
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton } from "@chakra-ui/react";
import { Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import moment from "moment/moment";
import Dropzone from "../Dropzone/Dropzone";
import { useState } from "react";
import { _arrayBufferToBase64 } from "../../utils/convertors";
import { API } from "../../utils/http";
import { useDispatch, useSelector } from "react-redux";
import { addPaymentDocumentAsync, markPaymentAsPaidAsync, markRentAsPaidAsync, removePaymentAsync, updateStatus } from "../../redux/user/userSlice";
import { markAsPaidVerifier } from "../../utils/verifier";
import { getPaymentStatus, getPaymentStatusColor } from "../../utils/utils";
import { fullDateFormat, fullDateTimeFormat } from "../../utils/types";
import { useTranslation } from "react-i18next";
import { set } from "firebase/database";
function InvoicesRow(props) {
	const {
		date,
		code,
		dueDate,
		exchange,
		status,
		amount,
		type,
		paymentDate,
		address,
		method,
		documents,
		property,
		id,
		currency,
		paymentDirection,
		description,
	} = props;
	const bgStatus = useColorModeValue("red.400", "red.700");
	const colorStatus = useColorModeValue("white", "white");
	const loading = useSelector((state) => state.user.status) === "loading";
	const [exchangeRateLoading, setExchangeRateLoading] = useState(false);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { isOpen: isMarkAsPaidOpen, onOpen: onMarkAsPaidOpen, onClose: onMarkAsPaidClose } = useDisclosure();
	const { isOpen: isDeleteConfirmationOpen, onOpen: onDeleteConfirmationOpen, onClose: onDeleteConfirmationClose } = useDisclosure();
	const { isOpen: isPartiallyPaidOpen, onToggle: onPartiallyPaidToggle, onClose: onPartiallyPaidClose } = useDisclosure();
	const [exchangeRate, setExchangeRate] = useState();
	const [error, setError] = useState({});
	const [addDocumentInformation, setAddDocumentInformation] = useState({ documentName: "", documentData: "", contentType: "" });
	const toast = useToast();
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const paymentStatus = getPaymentStatus({ paymentDate, dueDate });
	const initialMarkAsPaidInformation = {
		billedTo: {},
		documentNumber: "",
		documents: [],
		amount: amount,
		// amount: "",
		// currency: "ron",
		paymentDate: moment().format("yyyy-MM-DD"),
		paymentMethod: "cash",
		propertyId: property.id,
		description: "",
	};
	const [markAsPaidInformation, setMarkAsPaidInformation] = useState(initialMarkAsPaidInformation);
	const handleChangeMarkAsPaidInformation = (e) => {
		setMarkAsPaidInformation({ ...markAsPaidInformation, [e.target.name]: e.target.value });
	};
	useEffect(() => {
		console.log(markAsPaidInformation);
	}, [markAsPaidInformation]);
	const onDocumentDrop = async (acceptedFiles) => {
		console.log(acceptedFiles);
		let documents = [];
		for (const file of acceptedFiles) {
			const buffer = await file.arrayBuffer();
			const documentName = file.name;
			const contentType = file.type;
			documents.push({ documentData: _arrayBufferToBase64(buffer), documentName, contentType });
		}

		setMarkAsPaidInformation({ ...markAsPaidInformation, documents });
	};
	const onAddDocumentDrop = async (acceptedFiles) => {
		console.log(acceptedFiles);
		const buffer = await acceptedFiles[0].arrayBuffer();
		const documentName = acceptedFiles[0].name;
		const contentType = acceptedFiles[0].type;

		// setMarkAsPaidInformation({ ...markAsPaidInformation, documents });
		setAddDocumentInformation({ documentData: _arrayBufferToBase64(buffer), documentName, contentType });
	};
	const generateDisposition = () => {
		//call api to generate disposition based on the payment id
		const payload = { paymentId: id, propertyId: property.id };
		console.log(payload);
		dispatch(updateStatus("loading"));
		API.post("/generate-disposition", payload)
			.then((res) => {
				console.log(res);
				const pdfBuffer = res.data.disposition.data;
				const data = Uint8Array.from(pdfBuffer);
				var blob = new Blob([data.buffer], { type: "application/pdf" });
				var link = document.createElement("a");
				link.href = window.URL.createObjectURL(blob);
				var fileName = "dispozitie.pdf";
				link.download = fileName;
				link.click();
			})
			.finally(() => {
				dispatch(updateStatus("idle"));
			});
	};
	const handleBilledToChange = (e) => {
		const billedToEmail = e.target.value;
		console.log("email: ", billedToEmail);
		if (property.owner.email === billedToEmail) {
			setMarkAsPaidInformation({ ...markAsPaidInformation, billedTo: { name: property.owner.name, email: billedToEmail, type: "owner" } });
			return;
		}
		if (property.contacts.find((c) => c.email === billedToEmail)) {
			const contact = property.contacts.find((c) => c.email === billedToEmail);
			console.log(contact);
			setMarkAsPaidInformation({ ...markAsPaidInformation, billedTo: { name: contact.name, email: billedToEmail, type: contact.contactType } });
			return;
		}
		if (property.leaseContacts.find((c) => c.id === billedToEmail)) {
			const contact = property.leaseContacts.find((c) => c.id === billedToEmail);
			console.log(contact);
			setMarkAsPaidInformation({ ...markAsPaidInformation, billedTo: { name: contact.name, email: billedToEmail, type: contact.contactType } });
			return;
		}
		if (property.tenants.find((c) => c.email === billedToEmail)) {
			const tenant = property.tenants.find((c) => c.email === billedToEmail);
			console.log(tenant);
			setMarkAsPaidInformation({ ...markAsPaidInformation, billedTo: { name: tenant.name, email: billedToEmail, type: "tenant" } });
			return;
		}
	};
	const handleSaveMarkAsPaid = () => {
		const admCtr = property.documents.find((d) => d.type === "administrationContract" && d.active);
		const leaseCtr = property.documents.find((d) => d.type === "lease" && d.active);
		console.log(markAsPaidInformation);
		console.log("payment id: ", id);
		const payload = {
			...markAsPaidInformation,
			paymentId: id,
			administrationContractDate: admCtr?.startingDate || "Not available",
			administrationContractNumber: admCtr?.number || "Not available",
			leaseNumber: leaseCtr?.number || "Not available",
			leaseDate: leaseCtr?.signatureDate || "Not available",
		};
		console.log(payload);
		const errors = markAsPaidVerifier(payload);
		console.log(errors);
		setError(errors);
		if (Object.keys(errors).length > 0) {
			toast({
				title: "Error encountered",
				description: "Please fill in all the required fields",
				status: "error",
				duration: 9000,
				isClosable: true,
			});
			return;
		}

		if (type === "rent") {
			console.log("mark rent as paid");
			dispatch(markRentAsPaidAsync(payload)).finally(() => {
				onMarkAsPaidClose();
				setMarkAsPaidInformation(initialMarkAsPaidInformation);
			});
		} else {
			dispatch(markPaymentAsPaidAsync(payload)).finally(() => {
				onMarkAsPaidClose();
				setMarkAsPaidInformation(initialMarkAsPaidInformation);
			});
		}
	};
	const handleAddDocument = () => {
		console.log(addDocumentInformation);
		const payload = { ...addDocumentInformation, paymentId: id, propertyId: property.id };
		console.log(payload);
		dispatch(addPaymentDocumentAsync(payload)).finally(() => {
			onClose();
			setAddDocumentInformation({ documentData: "", documentName: "", contentType: "" });
		});
	};
	const handleGetDocument = (documentId, documentName) => {
		const payload = { propertyId: property.id, documentId, paymentId: id, documentName };
		console.log(payload);
		API.put("get-payment-document", payload)
			.then((r) => {
				console.log(r);
				window.open(r.data.url, "_blank");
			})
			.catch((e) => {
				console.log(e);
				toast({
					title: "Error encountered",
					description: e.response.data.error.message,
					status: "error",
					duration: 9000,
					isClosable: true,
				});
			});
	};
	const handleDeletePayment = () => {
		const payload = { propertyId: property.id, paymentId: id };
		console.log(payload);
		//dispatch remove payment
		dispatch(removePaymentAsync(payload))
			.then((r) => {
				if (r.error) {
					toast({
						title: "Error encountered",
						description: r.error.message,
						status: "error",
						duration: 9000,
						isClosable: true,
					});
				} else {
					toast({
						title: "Success",
						description: "Payment deleted successfully",
						status: "success",
						duration: 9000,
						isClosable: true,
					});
				}
			})
			.catch((e) => {
				console.log(e);
			})
			.finally(() => {
				onDeleteConfirmationClose();
			});
	};
	const { isOpen: isDifferentCurrencyOpen, onToggle: onDifferentCurrencyToggle, onClose: onDifferentCurrencyClose } = useDisclosure();

	const renderMarkAsPaidModal = () => {
		return (
			<Modal isOpen={isMarkAsPaidOpen} onClose={onMarkAsPaidClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader onClick={() => {}}>Mark as Paid</ModalHeader>
					{/* <ModalCloseButton /> */}
					<ModalBody>
						<Stack>
							{type.toLowerCase().includes("utility") ? (
								<FormControl display="flex" alignItems={"center"}>
									<FormLabel display={"inline"}>Amount:</FormLabel>
									<InputGroup width="fit-content">
										<Input
											isInvalid={error?.amount}
											type="number"
											name="amount"
											placeholder="Amount"
											_placeholder={{ color: "gray.500" }}
											value={markAsPaidInformation.amount}
											onChange={handleChangeMarkAsPaidInformation}
										/>
										<InputRightElement pointerEvents="none">{currency}</InputRightElement>
									</InputGroup>
								</FormControl>
							) : (
								<Stack>
									<Box>
										<Text as={"span"} fontWeight={"bold"}>
											Amount:
										</Text>{" "}
										<Text as={"span"}>
											{amount} {currency}
										</Text>
									</Box>
									<Box>
										<Text as={"span"} fontWeight={"bold"}>
											Type:
										</Text>{" "}
										<Text as={"span"}>{type}</Text>
									</Box>
								</Stack>
							)}
							<FormControl>
								<FormLabel display={"inline"}>Paid Date:</FormLabel>
								<Input
									width={"fit-content"}
									type="date"
									name="paymentDate"
									placeholder="Payment Date"
									_placeholder={{ color: "gray.500" }}
									value={markAsPaidInformation.paymentDate}
									onChange={handleChangeMarkAsPaidInformation}
								/>
							</FormControl>
							<FormControl>
								<FormLabel display={"inline"}>Document Number:</FormLabel>
								<Input
									isInvalid={error?.documentNumber}
									width={"fit-content"}
									type="text"
									name="documentNumber"
									placeholder="Document Number"
									_placeholder={{ color: "gray.500" }}
									value={markAsPaidInformation.documentNumber}
									onChange={handleChangeMarkAsPaidInformation}
								/>
							</FormControl>
							{/* <Checkbox
								hidden={type != "rent"}
								onChange={() => {
									onPartiallyPaidToggle();
									setMarkAsPaidInformation({ ...markAsPaidInformation, amount: "" });
								}}
							>
								Partially paid?
							</Checkbox>
							<Collapse in={isPartiallyPaidOpen} animateOpacity>
								<Stack>
									<FormControl>
										<FormLabel display={"inline"}>Paid Amount:</FormLabel>
										<Input
											width={"fit-content"}
											type="number"
											name="amount"
											placeholder="Paid Amount"
											_placeholder={{ color: "gray.500" }}
											value={markAsPaidInformation.amount}
											onChange={handleChangeMarkAsPaidInformation}
										/>
									</FormControl>
								</Stack>
							</Collapse> */}
							<Checkbox
								hidden={type != "rent"}
								isDisabled={exchangeRateLoading}
								isChecked={markAsPaidInformation.exchange}
								onChange={(e) => {
									if (!e.target.checked) {
										setMarkAsPaidInformation({ ...markAsPaidInformation, exchange: null });
										onDifferentCurrencyClose();
										return;
									}
									if (property.rent.exchangeRateType === "fixedExchangeRate") {
										console.log("fixed exchange rate");
										const destinationCurrency = currency === "ron" ? "eur" : "ron";
										setMarkAsPaidInformation({
											...markAsPaidInformation,
											exchange: {
												amount: Number(
													destinationCurrency === "ron" ? amount * property.rent.fixedExchangeRate : amount / property.rent.fixedExchangeRate
												).toFixed(2),
												currency: destinationCurrency,
												exchangeRate: property.rent.fixedExchangeRate,
												exchangeRateType: "fixedExchangeRate",
											},
										});
										onDifferentCurrencyToggle();
									} else {
										console.log("bnr exchange rate");
										setExchangeRateLoading(true);
										API.get(`get-exchange-rate?date=${markAsPaidInformation.paymentDate}`)
											.then((r) => {
												console.log(r);
												const rate = property.rent.exchangeRateType === "bnr" ? r.data.rate : r.data.rate * 1.02;
												const destinationCurrency = currency === "ron" ? "eur" : "ron";
												setMarkAsPaidInformation({
													...markAsPaidInformation,
													exchange: {
														amount: Number(destinationCurrency === "ron" ? amount * rate : amount / rate).toFixed(2),
														currency: destinationCurrency,
														exchangeRate: Number(rate).toFixed(4),
														exchangeRateType: property.rent.exchangeRateType,
													},
												});
												setExchangeRate(rate);
												onDifferentCurrencyToggle();
											})
											.catch((err) => {
												console.log(err);
												toast({
													title: "Error encountered",
													description: "Could not get exchange rate. Try again later.",
													status: "error",
													duration: 9000,
													isClosable: true,
												});
												setMarkAsPaidInformation({ ...markAsPaidInformation, exchange: null });
												onDifferentCurrencyClose();
											})
											.finally(() => {
												setExchangeRateLoading(false);
											});
									}
								}}
							>
								Billed in different currency <Spinner hidden={!exchangeRateLoading} />
							</Checkbox>
							<Collapse in={isDifferentCurrencyOpen} animateOpacity>
								<Stack>
									<Text>
										Exchange Rate: {`1 EUR = ${markAsPaidInformation.exchange?.exchangeRate} RON`}{" "}
										{markAsPaidInformation.exchange?.exchangeRateType === "fixedExchangeRate"
											? "(Curs Fix)"
											: markAsPaidInformation.exchange?.exchangeRateType === "bnr"
											? "(Curs BNR)"
											: "(Curs BNR + 2%)"}
									</Text>

									<FormControl display={"flex"} alignItems="center">
										<FormLabel display={"inline"}>Equivalent Amount:</FormLabel>
										<Text fontWeight={"bold"}>{markAsPaidInformation.exchange?.amount} </Text>
									</FormControl>
									<FormControl display={"flex"} alignItems="center">
										<FormLabel display={"inline"}>Equivalent Currency:</FormLabel>
										<Text fontWeight={"bold"}>{markAsPaidInformation.exchange?.currency}</Text>
									</FormControl>
								</Stack>
							</Collapse>
							<FormControl display={"flex"} alignItems="center">
								<FormLabel display={"inline"}>Method:</FormLabel>
								<Select
									width={"fit-content"}
									onChange={handleChangeMarkAsPaidInformation}
									type="text"
									name="paymentMethod"
									_placeholder={{ color: "gray.500" }}
									value={markAsPaidInformation.paymentMethod}
								>
									<option value="op">OP</option>
									<option value="cash">Cash</option>
									{type.toLowerCase().includes("utility") && <option value="personal">Personal</option>}
								</Select>
							</FormControl>
							<FormControl display={"flex"} alignItems="center">
								<FormLabel display={"inline"}>Billed To:</FormLabel>
								<Select width={"fit-content"} onChange={handleBilledToChange} name="billedTo" placeholder="Billed To" _placeholder={{ color: "gray.500" }}>
									<option value={property.owner.email}>{property.owner.name}</option>
									{property.tenants?.map((c, index) => (
										<option key={index} value={c.email}>
											{c.name}
										</option>
									))}
									{property.contacts?.map((c, index) => (
										<option key={index} value={c.email}>
											{c.name}
										</option>
									))}
									{property.leaseContacts?.map((c, index) => (
										<option key={index} value={c.id}>
											{c.name}
										</option>
									))}
								</Select>
							</FormControl>
							<FormControl>
								<FormLabel display={"inline"}>Billed To Name:</FormLabel>
								<Input
									isInvalid={error?.billedToName}
									width={"fit-content"}
									type="text"
									name="name"
									placeholder="Name"
									_placeholder={{ color: "gray.500" }}
									value={markAsPaidInformation.billedTo.name}
									onChange={(e) => {
										setMarkAsPaidInformation({ ...markAsPaidInformation, billedTo: { ...markAsPaidInformation.billedTo, name: e.target.value } });
									}}
								/>
							</FormControl>
							<FormControl>
								<FormLabel display={"inline"}>Billed To Type:</FormLabel>
								<Input
									isInvalid={error?.billedToType}
									width={"fit-content"}
									type="text"
									name="type"
									placeholder="Type"
									_placeholder={{ color: "gray.500" }}
									value={markAsPaidInformation.billedTo.type}
									onChange={(e) => {
										setMarkAsPaidInformation({ ...markAsPaidInformation, billedTo: { ...markAsPaidInformation.billedTo, type: e.target.value } });
									}}
								/>
							</FormControl>
							<FormControl>
								<FormLabel display={"inline"}>Description:</FormLabel>
								<Textarea
									isInvalid={error?.description}
									width={"fit-content"}
									type="text"
									name="description"
									placeholder="Description"
									_placeholder={{ color: "gray.500" }}
									value={markAsPaidInformation.description}
									onChange={handleChangeMarkAsPaidInformation}
								/>
							</FormControl>
							<FormControl>
								<FormLabel display={"inline"}>Documents:</FormLabel>
								<Dropzone
									onDrop={onDocumentDrop}
									accept={{
										"application/pdf": [".pdf"],
										"application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
										"application/msword": [".doc"],
									}}
									description="Upload PDF,DOC or DOCX Format"
								/>
							</FormControl>
						</Stack>
					</ModalBody>

					<ModalFooter>
						<Button
							isLoading={loading}
							colorScheme="red"
							mr={3}
							onClick={() => {
								onMarkAsPaidClose();
								onDifferentCurrencyClose();
							}}
						>
							Close
						</Button>
						<Button colorScheme="blue" mr={3} onClick={handleSaveMarkAsPaid} isLoading={loading}>
							{t("misc.save")}
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		);
	};
	const renderDeleteConfirmationModal = () => {
		return (
			<Modal isOpen={isDeleteConfirmationOpen} onClose={onDeleteConfirmationClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Confirm delete?</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Text>Are you sure you want to delete this payment? </Text>
						<Text>
							This action{" "}
							<Text as="span" fontWeight={"bold"}>
								cannot
							</Text>{" "}
							be undone!{" "}
						</Text>
					</ModalBody>

					<ModalFooter>
						<Button colorScheme="red" mr={3} onClick={handleDeletePayment}>
							Delete
						</Button>
						<Button colorScheme="blue" mr={3} onClick={onDeleteConfirmationClose}>
							Close
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		);
	};
	const renderViewDocumentsModal = () => {
		return (
			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>
						<Stack direction="row" alignItems={"center"}>
							<Text>Detalii</Text>
							<Badge colorScheme={getPaymentStatusColor(paymentStatus)} fontSize="16px">
								{paymentStatus}
							</Badge>
						</Stack>
					</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Stack>
							<Box>
								<Text as={"span"} fontWeight={"bold"}>
									Descriere:
								</Text>{" "}
								<Text as={"span"}>{description || "-"}</Text>
							</Box>
							<Box>
								<Text as={"span"} fontWeight={"bold"}>
									Tip:
								</Text>{" "}
								<Text as={"span"}>{t(`types.${type}`)}</Text>
							</Box>
							<Box>
								<Text as={"span"} fontWeight={"bold"}>
									Data Emiterii:
								</Text>{" "}
								<Text as={"span"}>{moment(date).format(fullDateTimeFormat)}</Text>
							</Box>
							<Box hidden={!dueDate}>
								<Text as={"span"} fontWeight={"bold"}>
									Data Scadenta:
								</Text>{" "}
								<Text as={"span"}>{moment(dueDate).format(fullDateFormat)}</Text>
							</Box>
							<Box>
								<Text as={"span"} fontWeight={"bold"}>
									Data Platii:
								</Text>{" "}
								<Text as={"span"}>{paymentDate ? moment(paymentDate).format(fullDateTimeFormat) : "-"}</Text>
							</Box>
							<Box>
								<Text as={"span"} fontWeight={"bold"}>
									Suma:
								</Text>{" "}
								<Text as={"span"}>
									{amount} {currency}
								</Text>
							</Box>
							<Box hidden={!exchange}>
								<Text as={"span"} fontWeight={"bold"}>
									Exchange:
								</Text>{" "}
								<Text as={"span"}>
									{exchange?.amount} {exchange?.currency} (1 EUR = {Number(exchange?.exchangeRate).toFixed(4)} RON)
								</Text>
							</Box>
							<UnorderedList>
								{documents?.map((d, index) => (
									<ListItem key={index}>
										<Text
											color="teal.500"
											textDecorationLine={"underline"}
											cursor={"pointer"}
											onClick={() => {
												handleGetDocument(d.id, d.documentName);
											}}
										>
											{d.documentName}
											<DownloadIcon marginLeft={2} />
										</Text>
									</ListItem> //TODO: make documentName clickable and download the clicked document
								))}
							</UnorderedList>
							<FormControl>
								<FormLabel display={"inline"}>Adauga document:</FormLabel>
								<Dropzone
									onDrop={onAddDocumentDrop}
									accept={{
										"application/pdf": [".pdf"],
										"application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
										"application/msword": [".doc"],
									}}
									description="Upload PDF,DOC or DOCX Format"
								/>
							</FormControl>
						</Stack>
					</ModalBody>

					<ModalFooter>
						<Button colorScheme="red" mr={3} onClick={onClose}>
							Close
						</Button>
						<Button colorScheme="blue" mr={3} onClick={handleAddDocument}>
							Add document
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		);
	};
	return (
		<Tr>
			<Td minWidth={{ sm: "150px" }} pl="0px">
				<Flex align="center" minWidth="100%" flexWrap="nowrap">
					{/* <Avatar src={logo} w="50px" borderRadius="12px" me="18px" /> */}
					<Flex direction="column">
						<Text fontSize="md" minWidth="100%">
							{moment(date).format("DD MMMM YYYY - HH:mm")}
						</Text>
						<Text fontSize="sm" fontWeight="normal" minWidth="100%" hidden={!code}>
							Document Number: {code}
						</Text>
					</Flex>
				</Flex>
			</Td>
			{/* <Td minWidth={{ sm: "250px" }}>
				<Text>{address}</Text>
			</Td> */}
			<Td>
				<Badge colorScheme={getPaymentStatusColor(paymentStatus)} fontSize="16px">
					{paymentStatus}
				</Badge>
			</Td>
			<Td>
				<Text>{dueDate ? moment(dueDate).format(fullDateFormat) : "-"}</Text>
			</Td>
			<Td>
				<Text>
					{paymentDirection === "outgoing" || type === "administrationFee" ? "-" : "+"}
					{amount} {currency}
				</Text>
			</Td>
			<Td>
				<Text fontSize="md">{method}</Text>
			</Td>
			<Td>
				<Text fontSize="md">{t(`types.${type}`)}</Text>
			</Td>
			<Td>
				<Text fontSize="md">{paymentDate ? moment(paymentDate).format(fullDateFormat) : "-"}</Text>
			</Td>
			<Td>
				<Menu closeOnSelect={false}>
					<MenuButton>
						<Icon as={HamburgerIcon} />
					</MenuButton>
					<MenuList>
						<MenuItem
							isDisabled={paymentStatus === "Paid"}
							onClick={() => {
								onMarkAsPaidOpen();
								console.log("payment id: ", id);
								console.log("payment type: ", type);
								console.log("payment amount: ", amount);

								if (type === "rent") {
									const lastRentPayment = [...property.payments]
										.sort((a, b) => moment(b.issuedDate).unix() - moment(a.issuedDate).unix())
										.find((p) => p.type === "rent" && p.paymentDate);
									console.log(lastRentPayment);
									setMarkAsPaidInformation({ ...markAsPaidInformation, amount: amount, description: lastRentPayment.description || "" });
								} else {
									setMarkAsPaidInformation({ ...markAsPaidInformation, amount: amount });
								}
							}}
						>
							Mark as paid
						</MenuItem>
						<MenuItem onClick={onOpen}>View Details</MenuItem>
						<MenuItem onClick={generateDisposition} hidden={true} isDisabled={loading}>
							Generate Disposition {loading && <Spinner />}
						</MenuItem>
						<MenuItem onClick={onDeleteConfirmationOpen}>Delete</MenuItem>
					</MenuList>
				</Menu>
				{renderViewDocumentsModal()}
				{renderMarkAsPaidModal()}
				{renderDeleteConfirmationModal()}
			</Td>
		</Tr>
	);
}

export default InvoicesRow;
