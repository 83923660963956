import React, { useEffect, useState } from "react";
import {
	Accordion,
	AccordionItem,
	AccordionButton,
	AccordionPanel,
	AccordionIcon,
	Flex,
	Box,
	FormControl,
	FormLabel,
	Input,
	Textarea,
	Stack,
	Button,
	useToast,
	Text,
	Checkbox,
	useDisclosure,
	Modal,
	ModalOverlay,
	ModalHeader,
	ModalContent,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	Tooltip,
	Table,
	Thead,
	Tr,
	Th,
	Tbody,
	Td,
} from "@chakra-ui/react";

import { useDispatch } from "react-redux";
import { sendMassNotificationsAsync } from "../../redux/user/userSlice";
import moment from "moment";
import { fullDateTimeFormat } from "../../utils/types";
import { useTranslation } from "react-i18next";
export default function NotificationsTab({ property }) {
	const { t } = useTranslation();
	const isArchived = property.archived;
	// const notifications = useSelector((state) => state.user.user?.notifications);
	const initialNotificationInformation = {
		title: "",
		message: "",
		recipients: {
			type: "property",
			propertyId: property.id,
			notifyOwner: false,
		},
	};
	const [notificationInformation, setNotificationInformation] = useState(initialNotificationInformation);
	const notifications = property.notifications || [];
	const { isOpen, onOpen, onClose } = useDisclosure();

	const dispatch = useDispatch();
	const toast = useToast();
	const handleSendNotification = () => {
		console.log("sending notification:", notificationInformation);
		dispatch(sendMassNotificationsAsync({ notification: notificationInformation }))
			.then((r) => {
				console.log(r);
				if (r.error?.message)
					toast({
						title: "Error encountered",
						description: r.error.message,
						status: "error",
						duration: 9000,
						isClosable: true,
					});
				else {
					toast({
						title: "Success!",
						description: "Mass notification sent!",
						status: "success",
						duration: 2000,
						isClosable: true,
					});
					setNotificationInformation({});
				}
			})
			.finally(() => {
				onClose();
				setNotificationInformation(initialNotificationInformation);
			});
	};
	const handleNotificationInfoChange = (e) => {
		console.log(e.target.value);
		setNotificationInformation({ ...notificationInformation, [e.target.name]: e.target.value });
		console.log("sending notification:", notificationInformation);
	};
	useEffect(() => {
		console.log(notificationInformation);
	}, [notificationInformation]);

	const renderEmitNotificationModal = () => (
		<Modal isOpen={isOpen} onClose={onClose} size={"xl"}>
			<ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
			<ModalContent>
				<ModalHeader>
					<Text>Emit Notification</Text>
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Text fontWeight={"bold"} fontSize={"large"} margin={"20px 0px 20px 0px"}>
						Emit a notification
					</Text>

					<Stack direction={"column"} width={{ sm: "100%", md: "50%" }} spacing={2}>
						<FormControl>
							<FormLabel>Title</FormLabel>
							<Input type="text" placeholder="Title" name="title" onChange={handleNotificationInfoChange} />
							{/* <FormHelperText>We'll never share your email.</FormHelperText> */}
						</FormControl>
						<FormControl>
							<FormLabel>Message</FormLabel>
							<Textarea placeholder="Message..." name="message" onChange={handleNotificationInfoChange} />
							{/* <FormHelperText>We'll never share your email.</FormHelperText> */}
						</FormControl>
						<Checkbox
							name="notifyOwner"
							onChange={(e) => {
								setNotificationInformation({
									...notificationInformation,
									recipients: { ...notificationInformation.recipients, notifyOwner: e.target.checked },
								});
							}}
						>
							<Text>Notify Owner?</Text>
						</Checkbox>
					</Stack>
				</ModalBody>
				<ModalFooter>
					<Button colorScheme="red" onClick={onClose} mr={3}>
						{t("misc.cancel")}
					</Button>
					<Button colorScheme="blue" onClick={handleSendNotification}>
						Send
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);

	return (
		<Stack>
			<Stack direction={"row"}>
				<Tooltip label="Add tenants to enable" isDisabled={property.tenants.filter((t) => !t.former).length !== 0}>
					<Button onClick={onOpen} colorScheme="blue" width={"fit-content"} isDisabled={property.tenants.filter((t) => !t.former).length === 0 || isArchived}>
						{t("property.sendNotification")}
					</Button>
				</Tooltip>
				{renderEmitNotificationModal()}
			</Stack>
			<Box>
				<Accordion allowMultiple width="100%">
					{notifications
						.slice()
						.reverse()
						.map((n, index) => (
							<AccordionItem key={index}>
								<AccordionButton>
									<Flex flex="1" direction={"row"} alignItems={"center"} whiteSpace={"pre"}>
										<Text fontSize="md">{n.title} </Text>
										<Text fontSize={"small"}>~ {moment(n.timestamp).format("DD MMM YYYY - HH:mm")}</Text>
									</Flex>
									<AccordionIcon />
								</AccordionButton>

								<AccordionPanel pb={4}>
									<Stack spacing={2}>
										<Box>
											<Text fontSize={"large"}>Message:</Text>
											<Text>{n.text || n.message}</Text>
										</Box>
										<Box>
											<Text fontSize={"large"}>Recipients:</Text>
											<Text>{n.recipients?.join(", ")}</Text>
										</Box>
									</Stack>
								</AccordionPanel>
							</AccordionItem>
						))}
				</Accordion>
			</Box>
			<Box>
				<Text fontSize={"large"} fontWeight={"bold"}>
					{t("property.systemNotifications")}
				</Text>
				<Table variant="simple" size="sm">
					<Thead>
						<Tr my=".8rem" pl="0px">
							<Th>{t("table.date")}</Th>
							<Th>{t("table.description")}</Th>
							<Th>{t("table.type")}</Th>
							<Th>{t("table.recipients")}</Th>
						</Tr>
					</Thead>
					<Tbody>
						{property.systemNotifications?.map((n, index) => (
							<Tr key={index}>
								<Td whiteSpace={"nowrap"}>{moment(n.timestamp).format(fullDateTimeFormat)}</Td>
								<Td>{n.description || "-"}</Td>
								<Td>{n.type}</Td>
								<Td>{n.recipients?.join(", ") || "-"}</Td>
							</Tr>
						))}
						{property.systemNotifications?.length === 0 && (
							<Tr>
								<Td colSpan={4}>{t("table.noData")}</Td>
							</Tr>
						)}
					</Tbody>
				</Table>
			</Box>
		</Stack>
	);
}
