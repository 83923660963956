import { DownloadIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, Icon, Spacer, Text, useColorModeValue, Badge, Tr, Td, Tooltip, Stack, Divider } from "@chakra-ui/react";
import moment from "moment";
import React from "react";
import { fullDateTimeFormat } from "../../utils/types";

export default function UtilityMeterTableRow(props) {
	const { meterIndex, timestamp, isCurrent, additionalMeterIndex } = props;

	return (
		<Tr>
			<Td>
				<Stack direction={"row"}>
					<Text fontSize="md" pb=".5rem" fontWeight={isCurrent ? "bold" : "normal"}>
						{meterIndex}
					</Text>
					{additionalMeterIndex && (
						<>
							<Divider orientation="vertical" height="25px" />
							<Text>Secondary:</Text>
							<Text fontSize="md" pb=".5rem" fontWeight={isCurrent ? "bold" : "normal"}>
								{additionalMeterIndex}
							</Text>
						</>
					)}
				</Stack>
			</Td>
			<Td>
				<Text fontSize="md" pb=".5rem">
					{moment(timestamp).format(fullDateTimeFormat)}
				</Text>
			</Td>
			<Td>
				<Badge hidden={!isCurrent} colorScheme="green" fontSize="16px" p="3px 10px" borderRadius="8px">
					Current index
				</Badge>
			</Td>
		</Tr>
	);
}
