import {
	Flex,
	Box,
	FormControl,
	FormLabel,
	Input,
	InputGroup,
	InputRightElement,
	Stack,
	Button,
	Heading,
	Text,
	useColorModeValue,
	Link,
	InputLeftElement,
	Select,
	FormErrorMessage,
	Image,
	Img,
} from "@chakra-ui/react";
import { useState } from "react";
import { EmailIcon, PhoneIcon, ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { confirmSignUpAsync, signIn, signUpAsync } from "../../redux/auth/authSlice";
import { Hub } from "../../utils/auth";
import { useToast } from "@chakra-ui/react";
import { Link as ReactRouterLink, useNavigate } from "react-router-dom";
import { createUserAsync } from "../../redux/user/userSlice";
import { signUpVerifier } from "../../utils/verifier";
import VerificationCode from "../../Components/VerificationCode/VerificationCode";
import RentITLogo from "../../assets/img/rentit-logo.png";
import Banner from "../../Components/Banner/Banner";
export default function SignUpPage() {
	const [showPassword, setShowPassword] = useState(false);
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [phoneNumber, setPhoneNumber] = useState("");
	const [name, setName] = useState("");
	// const [firstName, setfirstName] = useState("");
	// const [lastName, setlastName] = useState("");
	const [accountType, setAccountType] = useState("");
	const dispatch = useDispatch();
	const [error, setError] = useState();
	const verifyEmail = useSelector((state) => state.auth.verifyEmail);
	const toast = useToast();
	const navigate = useNavigate();
	Hub.listen("auth", ({ payload }) => {
		const { event } = payload;
		if (event === "autoSignIn") {
			const response = payload.data;
			const r = {
				email: response.attributes.email,
				idToken: response.signInUserSession.idToken.jwtToken,
				authorization: response.signInUserSession.accessToken.jwtToken,
			};
			dispatch(signIn(r));
		} else if (event === "autoSignIn_failure") {
			console.log("Error auto sign in");
			// redirect to sign in page
		}
	});
	const handleSignUp = () => {
		// const formError = hasError({ email, password, phoneNumber, accountType });
		const formError = signUpVerifier({ email, password, name, phoneNumber, accountType });
		if (Object.entries(formError).length !== 0) {
			setError(formError);
			toast({
				title: "Error encountered.",
				description: "Invalid information",
				status: "error",
				duration: 9000,
				isClosable: true,
			});
			return;
		}
		setError({});

		dispatch(signUpAsync({ email, password })).then((r) => {
			console.log(r);
			if (r.error?.message) {
				setError(r.error.message);
				toast({
					title: "Error encountered.",
					description: r.error.message,
					status: "error",
					duration: 9000,
					isClosable: true,
				});
			} else {
				toast({
					title: "Success!",
					description: "Account created. Please verify it!",
					status: "success",
					duration: 9000,
					isClosable: true,
				});
			}
		});
	};
	const handleVerifyCode = (code) => {
		// dispatch(signUpAsync({ email, password }));
		console.log("code: ", code);
		console.log("email: ", email);
		dispatch(confirmSignUpAsync({ email, code })).then((r) => {
			console.log(r);
			if (r.error?.message) {
				setError(r.error.message);
				toast({
					title: "Error encountered.",
					description: r.error.message,
					status: "error",
					duration: 9000,
					isClosable: true,
				});
			} else {
				toast({
					title: "Success!",
					description: "Account verified!",
					status: "success",
					duration: 9000,
					isClosable: true,
				});
				console.log("create user in database", { email, name, phoneNumber, accountType });

				if (accountType === "agency") dispatch(createUserAsync({ email, name, phoneNumber, accountType }));
				navigate("/");
			}
		});
	};
	const bg = useColorModeValue("white", "gray.700");
	const renderSignupForm = (bg) => {
		return (
			<Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
				<Stack align={"center"}>
					<Image src={"https://rentit-images.s3.eu-central-1.amazonaws.com/logo.png"} alt="logo" height={20} />
					<Heading fontSize={"4xl"} textAlign={"center"}>
						Înregistrați-vă
					</Heading>
					<Text fontSize={"lg"} color={"gray.600"}>
						pentru a va bucura de funcțiile noastre interesante ✌️
					</Text>
				</Stack>
				<Box rounded={"lg"} bg={bg} boxShadow={"lg"} p={8}>
					<Stack spacing={4}>
						<FormControl id="type" isRequired isInvalid={error?.accountType}>
							<FormLabel>Tipul contului</FormLabel>
							<InputGroup>
								<Select
									value={accountType}
									onChange={(e) => {
										setAccountType(e.target.value);
									}}
								>
									<option value="" disabled>
										Selectati tipul contului
									</option>
									<option value="agency">Agentie</option>
									<option value="agent">Agent</option>
									<option value="tenant">Chirias</option>
									<option value="owner">Proprietar</option>
								</Select>
							</InputGroup>
							<FormErrorMessage>{error?.accountType}</FormErrorMessage>
						</FormControl>
						<FormControl id="email" isRequired isInvalid={error?.email}>
							<FormLabel>Email</FormLabel>
							<InputGroup>
								<InputLeftElement pointerEvents="none" children={<EmailIcon color="gray.300" />} />
								<Input
									type="email"
									value={email}
									onChange={(e) => {
										setEmail(e.target.value);
									}}
								/>
							</InputGroup>
							<FormErrorMessage>{error?.email}</FormErrorMessage>
						</FormControl>
						<Box hidden={accountType !== "agency"}>
							<FormControl id="name" isRequired isInvalid={error?.name}>
								<FormLabel>Nume</FormLabel>
								<Input
									type="text"
									value={name}
									onChange={(e) => {
										setName(e.target.value);
									}}
								/>
								<FormErrorMessage>{error?.name}</FormErrorMessage>
							</FormControl>

							<FormControl id="phoneNumber" isRequired isInvalid={error?.phoneNumber}>
								<FormLabel>Numar de telefon</FormLabel>
								<InputGroup>
									<InputLeftElement pointerEvents="none" children={<PhoneIcon color="gray.300" />} />
									<Input
										type="tel"
										placeholder="phone number"
										value={phoneNumber}
										onChange={(e) => {
											setPhoneNumber(e.target.value);
										}}
									/>
								</InputGroup>
								<FormErrorMessage>{error?.phoneNumber}</FormErrorMessage>
							</FormControl>
						</Box>
						<FormControl id="password" isRequired isInvalid={error?.password}>
							<FormLabel>Parola</FormLabel>
							<InputGroup>
								<Input
									type={showPassword ? "text" : "password"}
									value={password}
									onChange={(e) => {
										setPassword(e.target.value);
									}}
								/>
								<InputRightElement h={"full"}>
									<Button variant={"ghost"} onClick={() => setShowPassword((showPassword) => !showPassword)}>
										{showPassword ? <ViewIcon /> : <ViewOffIcon />}
									</Button>
								</InputRightElement>
							</InputGroup>
							<FormErrorMessage>{error?.password}</FormErrorMessage>
						</FormControl>
						<Stack spacing={10} pt={2}>
							<Button loadingText="Submitting" size="lg" colorScheme="blue" onClick={handleSignUp}>
								Inscrie-te
							</Button>
						</Stack>
						<Stack pt={6}>
							<Text align={"center"}>
								Sunteți deja utilizator?{" "}
								<Link as={ReactRouterLink} color={"blue.400"} to="/sign-in">
									Login
								</Link>
							</Text>
						</Stack>
					</Stack>
				</Box>
			</Stack>
		);
	};
	return (
		<Box minH={"100vh"}>
			{/* <Banner
				description={
					"Suntem conștienți de problemele care pot afecta conectarea/înregistrarea. Echipa noastră lucrează pentru a le remedia și pentru a asigura accesul fără probleme pentru toți utilizatorii. Vă mulțumim pentru înțelegere și răbdare."
				}
				status={"warning"}
			/> */}
			<Flex align={"center"} justify={"center"} bg={useColorModeValue("gray.50", "gray.800")}>
				{!verifyEmail ? renderSignupForm(bg) : <VerificationCode verifyCode={handleVerifyCode} email={email}></VerificationCode>}
			</Flex>
		</Box>
	);
}
