import React, { useState, useEffect } from "react";
import {
	Box,
	Button,
	Flex,
	Table,
	Tbody,
	Text,
	Th,
	Thead,
	Tr,
	Divider,
	Input,
	Stack,
	FormControl,
	FormLabel,
	Select,
	useDisclosure,
	Modal,
	ModalOverlay,
	ModalHeader,
	ModalContent,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	Checkbox,
	Textarea,
	useToast,
	Tooltip,
	Td,
} from "@chakra-ui/react";
import Dropzone from "../Dropzone/Dropzone";
import { useDispatch } from "react-redux";
import { addFinancialStatementAsync, updateOwnerAsync } from "../../redux/user/userSlice";
import { addFinancialStatementVerifier, getPropertyAddress, getPropertyStatus } from "../../utils/verifier";
import moment from "moment";
import FinancialStatementRow from "../TableRows/FinancialStatementRow";
import { useTranslation } from "react-i18next";
function _arrayBufferToBase64(buffer) {
	var binary = "";
	var bytes = new Uint8Array(buffer);
	var len = bytes.byteLength;
	for (var i = 0; i < len; i++) {
		binary += String.fromCharCode(bytes[i]);
	}
	return window.btoa(binary);
}

export default function FinancialStatementsTab({ property, loading }) {
	const { t } = useTranslation();
	const isArchived = property.archived;
	const initialPaymentInformation = {
		name: "",
		propertyId: property.id,
		documents: [],
		description: "",
		type: "",
		year: moment().year(),
	};
	const dispatch = useDispatch();
	const [year, setYear] = useState(moment().year());

	const { isOpen, onOpen, onClose } = useDisclosure();
	const [paymentInformation, setPaymentInformation] = useState(initialPaymentInformation);
	const { onClose: onDifferentCurrencyClose } = useDisclosure();
	const [error, setError] = useState({});
	const toast = useToast();
	const onDocumentDrop = async (acceptedFiles) => {
		console.log(acceptedFiles);
		let documents = [];
		for (const file of acceptedFiles) {
			const buffer = await file.arrayBuffer();
			const documentName = file.name;
			const contentType = file.type;
			documents.push({ documentData: _arrayBufferToBase64(buffer), documentName, contentType });
		}
		setPaymentInformation({ ...paymentInformation, documents });
	};
	useEffect(() => {
		console.log(paymentInformation);
	}, [paymentInformation]);
	const addPayment = () => {
		const errors = addFinancialStatementVerifier(paymentInformation);
		const payload = {
			...paymentInformation,
		};
		console.log(payload);
		if (Object.entries(errors).length !== 0) {
			console.log(errors);
			setError(errors);
			toast({
				title: "Error encountered.",
				description: "Invalid information",
				status: "error",
				duration: 2000,
				isClosable: true,
			});
			return;
		}
		setError({});
		dispatch(addFinancialStatementAsync(payload))
			.then((r) => {
				if (r.error?.message)
					toast({
						title: "Error encountered",
						description: r.error.message,
						status: "error",
						duration: 9000,
						isClosable: true,
					});
				else {
					toast({
						title: "Success!",
						description: "Documents added!",
						status: "success",
						duration: 2000,
						isClosable: true,
					});
				}
			})
			.finally(() => {
				onClose();
				setPaymentInformation(initialPaymentInformation);
			});
	};
	const handleChange = (e) => {
		console.log("changeee");
		setPaymentInformation({ ...paymentInformation, [e.target.name]: e.target.value });
	};

	const renderAddPaymentModal = () => (
		<Modal isOpen={isOpen} onClose={onClose} size={"xl"}>
			<ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
			<ModalContent>
				<ModalHeader>
					<Text>Add a Financial Statement</Text>
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Stack>
						<FormControl>
							<FormLabel display={"inline"}>Name:</FormLabel>
							<Input
								width={"fit-content"}
								onChange={handleChange}
								isInvalid={error?.name}
								type="text"
								name="name"
								placeholder="Name"
								_placeholder={{ color: "gray.500" }}
							/>
						</FormControl>
						<FormControl display={"flex"} alignItems={"center"}>
							<FormLabel display={"inline"}>Year:</FormLabel>
							<Select
								width={"fit-content"}
								onChange={handleChange}
								value={paymentInformation.year}
								isInvalid={error?.year}
								name="year"
								placeholder="Year"
								_placeholder={{ color: "gray.500" }}
							>
								{[...Array(10).keys()].map((i, index) => (
									<option key={index} value={moment().year() - i}>
										{moment().year() - i}
									</option>
								))}
							</Select>
						</FormControl>
						<FormControl display={"flex"} alignItems="center">
							<FormLabel display={"inline"}>Type:</FormLabel>
							<Select isInvalid={error?.type} width={"fit-content"} onChange={handleChange} name="type" placeholder="Type" _placeholder={{ color: "gray.500" }}>
								<option value="statement">Declaratie Unica</option>
								<option value="c168">C168</option>
							</Select>
						</FormControl>

						<FormControl>
							<FormLabel display={"inline"}>Description:</FormLabel>
							<Textarea
								width={"fit-content"}
								type="text"
								name="description"
								placeholder="Description"
								isInvalid={error?.description}
								_placeholder={{ color: "gray.500" }}
								value={paymentInformation.description}
								onChange={handleChange}
							/>
						</FormControl>
						<FormControl>
							<FormLabel>Documents:</FormLabel>
							<Dropzone
								onDrop={onDocumentDrop}
								accept={{
									"application/pdf": [".pdf"],
									"application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
									"application/msword": [".doc"],
								}}
								description="Upload PDF,DOC or DOCX Format"
							/>
						</FormControl>
					</Stack>
				</ModalBody>
				<ModalFooter>
					<Button
						colorScheme="red"
						onClick={() => {
							onClose();
							setPaymentInformation(initialPaymentInformation);
							onDifferentCurrencyClose();
							setError({});
						}}
						mr={3}
					>
						{t("misc.cancel")}
					</Button>
					<Button colorScheme="blue" onClick={addPayment} isDisabled={loading}>
						{t("misc.save")}
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);

	return (
		<Stack spacing={4}>
			<Box>
				<Tooltip isDisabled={getPropertyStatus(property) !== "inactive"} label="Add contracts to enable">
					<Button onClick={onOpen} colorScheme="blue" isDisabled={getPropertyStatus(property) === "inactive" || isArchived}>
						{t("property.addFinancialStatement")}
					</Button>
				</Tooltip>
			</Box>
			<Box>
				<Checkbox
					isChecked={property.owner?.handlesOwnFinancialStatements}
					onChange={(e) => {
						console.log("handles own financial statements:", e.target.checked);
						dispatch(updateOwnerAsync({ type: "handlesOwn", handlesOwnFinancialStatements: e.target.checked, ownerEmail: property.owner.email }));
					}}
				>
					{t("property.ownerHandlesFinancials")}
				</Checkbox>
			</Box>

			<Divider />
			{renderAddPaymentModal()}
			<Flex direction="row" justifyContent={"space-around"}>
				<Button
					onClick={() => {
						setYear(year - 1);
					}}
				>
					{t("table.previousYear")}
				</Button>
				<Text>{year}</Text>
				<Button
					isDisabled={year === moment().year()}
					onClick={() => {
						setYear(year + 1);
					}}
				>
					{t("table.nextYear")}
				</Button>
			</Flex>
			<Box overflowX="auto">
				<Table variant="simple" size="sm">
					<Thead>
						<Tr my=".8rem" pl="0px">
							<Th pl="0px">{t("table.addedDate")}</Th>
							<Th>{t("table.name")}</Th>
							<Th>{t("table.year")}</Th>
							<Th>{t("table.type")}</Th>
							<Th>{t("table.description")}</Th>
							<Th></Th>
						</Tr>
					</Thead>
					<Tbody>
						{property.financialStatements
							?.filter((f) => Number(f.year) === year)
							.map((row, index) => {
								return (
									<FinancialStatementRow
										id={row.id}
										key={index}
										name={row.name}
										date={row.addedDate}
										address={getPropertyAddress(property)}
										paymentDate={row.paymentDate}
										code={row.number}
										amount={row.amount}
										description={row.description}
										type={row.type}
										status={row.paymentDate ? "paid" : "due"}
										logo={row.logo}
										format={row.format}
										method={row.paymentMethod}
										documents={row.documents}
										paymentDirection={row.paymentDirection}
										property={property}
										currency={row.currency}
										active={row.active}
										year={row.year}
									/>
								);
							})}
						{property.financialStatements.length === 0 && (
							<Tr>
								<Td colSpan={6}>{t("table.noData")}</Td>
							</Tr>
						)}
					</Tbody>
				</Table>
			</Box>
		</Stack>
	);
}
