import React, { useState } from "react";
import {
	Box,
	Button,
	Card,
	CardBody,
	CardHeader,
	Stack,
	Table,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
	useDisclosure,
	useToast,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	FormControl,
	FormLabel,
	Input,
	Textarea,
	Select,
} from "@chakra-ui/react";
import MessagesTableRow from "./MessagesTableRow";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { addMessageAsync } from "../../../redux/user/userSlice";

export default function PropertyMessagesCard({ address, messages, propertyId, name, email }) {
	const { t } = useTranslation();
	const initialMessageInformation = {
		propertyId,
		author: {},
		title: "",
		description: "",
		type: "owner",
	};
	const [messageInformation, setMessageInformation] = useState(initialMessageInformation);
	const { isOpen, onOpen, onClose } = useDisclosure();

	const dispatch = useDispatch();
	const toast = useToast();
	const handleSendNotification = () => {
		const payload = { ...messageInformation, author: { name, email } };
		console.log("sending message:", payload);
		// return;
		dispatch(addMessageAsync(payload))
			.then((r) => {
				console.log(r);
				if (r.error?.message)
					toast({
						title: "Error encountered",
						description: r.error.message,
						status: "error",
						duration: 9000,
						isClosable: true,
					});
				else {
					toast({
						title: "Success!",
						description: "Mass notification sent!",
						status: "success",
						duration: 2000,
						isClosable: true,
					});
					setMessageInformation({});
				}
			})
			.finally(() => {
				onClose();
				setMessageInformation(initialMessageInformation);
			});
	};
	const handleNotificationInfoChange = (e) => {
		setMessageInformation({ ...messageInformation, [e.target.name]: e.target.value });
	};

	const renderEmitNotificationModal = () => (
		<Modal isOpen={isOpen} onClose={onClose} size={"xl"}>
			<ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
			<ModalContent>
				<ModalHeader>
					<Text>{t("property.sendMessage")}</Text>
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Stack direction={"column"} width={{ sm: "100%", md: "50%" }} spacing={2}>
						<FormControl>
							<FormLabel>Titlu</FormLabel>
							<Input type="text" placeholder="Title" name="title" onChange={handleNotificationInfoChange} />
						</FormControl>
						<FormControl>
							<FormLabel>Descriere</FormLabel>
							<Textarea placeholder="Message..." name="description" onChange={handleNotificationInfoChange} />
						</FormControl>
					</Stack>
				</ModalBody>
				<ModalFooter>
					<Button colorScheme="red" onClick={onClose} mr={3}>
						{t("misc.cancel")}
					</Button>
					<Button colorScheme="blue" onClick={handleSendNotification}>
						{t("misc.save")}
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
	return (
		<Card overflowX={{ sm: "scroll", xl: "hidden" }}>
			<CardHeader>
				<Stack>
					<Text fontSize="xl">Address: {address}</Text>
					<Button onClick={onOpen} width="fit-content" colorScheme="blue">
						{t("property.sendMessage")}
					</Button>
					{renderEmitNotificationModal(propertyId)}
				</Stack>
			</CardHeader>
			<CardBody>
				<Table variant="simple" size="sm">
					<Thead>
						<Tr my=".8rem" pl="0px">
							<Th>{t("table.title")}</Th>
							<Th>{t("table.author")}</Th>
							<Th>{t("table.type")}</Th>
							<Th>{t("table.date")}</Th>
							<Th></Th>
						</Tr>
					</Thead>
					<Tbody>
						{messages?.map((t, index) => (
							<MessagesTableRow key={index} row={t} author={{ name, email }} propertyId={propertyId} />
						))}
						{messages?.length === 0 && (
							<Tr>
								<Td>{t("table.noData")}</Td>
							</Tr>
						)}
					</Tbody>
				</Table>
			</CardBody>
		</Card>
	);
}
