import {
	Button,
	Flex,
	FormControl,
	FormHelperText,
	FormLabel,
	Grid,
	Input,
	InputGroup,
	InputRightAddon,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Select,
	Stack,
	Tooltip,
	useDisclosure,
	useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Select as ChakraSelect } from "chakra-react-select";
import { addPropertyVerifier } from "../../utils/verifier";
import { createPropertyAsync, selectActiveProperties } from "../../redux/user/userSlice";
import { useTranslation } from "react-i18next";

export default function NewPropertyModal() {
	const [error, setError] = useState({});
	const { t } = useTranslation();
	const currentUserEmail = useSelector((state) => state.auth.currentUserEmail);
	const properties = useSelector(selectActiveProperties);
	const { isOpen, onOpen, onClose } = useDisclosure();

	const owners = properties.map((property) => property.owner).filter((v, i, a) => a.findIndex((v2) => v2.id === v.id) === i);
	const billingInformation = useSelector((state) => state.user.user?.billingInformation);

	const initialPropertyInformation = {
		owner: { name: "", email: "", phoneNumber: "" },
		bankDetails: { administrationFeePercent: "", currency: "", account: "" },
		location: {
			apartmentNumber: "",
			area: "",
			blockNumber: "",
			buildingNumber: "",
			city: "",
			county: "",
			entrance: "",
			street: "",
		},
		keys: "",
		agent: currentUserEmail,
	};
	const toast = useToast();
	const dispatch = useDispatch();
	const handleChange = (e) => {
		setpropertyInformation({ ...propertyInformation, [e.target.name]: e.target.value });
	};
	const handleClose = () => {
		onClose();
		setpropertyInformation(initialPropertyInformation);
		setError({});
	};
	const handleSave = () => {
		console.log(propertyInformation);
		const errors = addPropertyVerifier(propertyInformation);
		console.log(errors);
		if (Object.entries(errors).length !== 0) {
			setError(errors);
			toast({
				title: "Error encountered.",
				description: "Invalid information",
				status: "error",
				duration: 2000,
				isClosable: true,
			});
			return;
		}
		dispatch(createPropertyAsync(propertyInformation))
			.then((r) => {
				if (r.error?.message)
					toast({
						title: "Error encountered",
						description: r.error.message,
						status: "error",
						duration: 9000,
						isClosable: true,
					});
				else {
					toast({
						title: "Success!",
						description: "Property added!",
						status: "success",
						duration: 2000,
						isClosable: true,
					});
				}
			})
			.finally(() => {
				setpropertyInformation(initialPropertyInformation);
				onClose();
			});
	};
	const [propertyInformation, setpropertyInformation] = useState(initialPropertyInformation);
	const renderAddPropertyModal = () => (
		<Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="6xl">
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>{t("properties.addProperty")}</ModalHeader>
				<ModalCloseButton />
				<ModalBody pb={6}>
					<Flex rounded={"xl"} p={6} direction={"column"}>
						<Grid templateColumns={{ md: "1fr", lg: "1fr 1fr" }} gap="24px">
							<FormControl isRequired>
								<FormLabel fontWeight={"bold"}>{t("properties.location")}</FormLabel>
								<Stack spacing={2}>
									<Input
										isInvalid={error?.location?.street}
										name="street"
										placeholder={t("properties.street")}
										value={propertyInformation?.location.street}
										onChange={(e) => {
											setpropertyInformation({ ...propertyInformation, location: { ...propertyInformation.location, street: e.target.value } });
										}}
										_placeholder={{ color: "gray.500" }}
										type="text"
									/>
									{/* <FormErrorMessage>{error?.location?.street}</FormErrorMessage> */}
									<Input
										name="buildingNumber"
										placeholder={t("properties.buildingNumber")}
										value={propertyInformation?.location.buildingNumber}
										isInvalid={error?.location?.buildingNumber}
										onChange={(e) => {
											setpropertyInformation({ ...propertyInformation, location: { ...propertyInformation.location, buildingNumber: e.target.value } });
										}}
										_placeholder={{ color: "gray.500" }}
										type="text"
									/>
									<Input
										name="blockNumber"
										placeholder={t("properties.blockNumber")}
										isInvalid={error?.location?.blockNumber}
										value={propertyInformation?.location.blockNumber}
										onChange={(e) => {
											setpropertyInformation({ ...propertyInformation, location: { ...propertyInformation.location, blockNumber: e.target.value } });
										}}
										_placeholder={{ color: "gray.500" }}
										type="text"
									/>
									<Input
										name="apartmentNumber"
										placeholder={t("properties.apartmentNumber")}
										isInvalid={error?.location?.apartmentNumber}
										value={propertyInformation?.location.apartmentNumber}
										onChange={(e) => {
											setpropertyInformation({ ...propertyInformation, location: { ...propertyInformation.location, apartmentNumber: e.target.value } });
										}}
										_placeholder={{ color: "gray.500" }}
										type="text"
									/>
									<Input
										name="entrance"
										placeholder={t("properties.entrance")}
										isInvalid={error?.location?.entrance}
										value={propertyInformation?.location.entrance}
										onChange={(e) => {
											setpropertyInformation({ ...propertyInformation, location: { ...propertyInformation.location, entrance: e.target.value } });
										}}
										_placeholder={{ color: "gray.500" }}
										type="text"
									/>
									<Input
										name="area"
										placeholder={t("properties.area")}
										isInvalid={error?.location?.area}
										value={propertyInformation?.location.area}
										onChange={(e) => {
											setpropertyInformation({ ...propertyInformation, location: { ...propertyInformation.location, area: e.target.value } });
										}}
										_placeholder={{ color: "gray.500" }}
										type="text"
									/>
									<Input
										name="city"
										placeholder={t("properties.city")}
										isInvalid={error?.location?.city}
										value={propertyInformation?.location.city}
										onChange={(e) => {
											setpropertyInformation({ ...propertyInformation, location: { ...propertyInformation.location, city: e.target.value } });
										}}
										_placeholder={{ color: "gray.500" }}
										type="text"
									/>
									<Input
										name="county"
										placeholder={t("properties.county")}
										isInvalid={error?.location?.county}
										value={propertyInformation?.location.county}
										onChange={(e) => {
											setpropertyInformation({ ...propertyInformation, location: { ...propertyInformation.location, county: e.target.value } });
										}}
										_placeholder={{ color: "gray.500" }}
										type="text"
									/>
								</Stack>
							</FormControl>

							<FormControl isRequired>
								<FormLabel fontWeight={"bold"}>{t("properties.keys")}</FormLabel>
								<Input
									name="keys"
									placeholder={t("properties.keys")}
									isInvalid={error?.keys}
									value={propertyInformation?.keys}
									onChange={handleChange}
									_placeholder={{ color: "gray.500" }}
								/>
							</FormControl>

							<FormControl isRequired>
								<FormLabel fontWeight={"bold"}>{t("properties.agent")}</FormLabel>
								<Input isDisabled name="agent" value={propertyInformation?.agent} onChange={handleChange} _placeholder={{ color: "gray.500" }} type="email" />
							</FormControl>
							<FormControl isRequired>
								<FormLabel fontWeight={"bold"}>{t("properties.bankDetails")}</FormLabel>
								<Stack>
									<Input
										name="iban"
										placeholder={t("properties.iban")}
										isInvalid={error?.bankDetails?.account}
										value={propertyInformation?.bankDetails.account}
										onChange={(e) => {
											setpropertyInformation({ ...propertyInformation, bankDetails: { ...propertyInformation.bankDetails, account: e.target.value } });
										}}
										_placeholder={{ color: "gray.500" }}
										type="text"
									/>
									<InputGroup>
										<Input
											name="administrationFeePercent"
											placeholder={t("properties.administrationFeePercent")}
											isInvalid={error?.bankDetails?.administrationFeePercent}
											value={propertyInformation?.bankDetails.administrationFeePercent}
											onChange={(e) => {
												setpropertyInformation({
													...propertyInformation,
													bankDetails: { ...propertyInformation.bankDetails, administrationFeePercent: e.target.value },
												});
											}}
											_placeholder={{ color: "gray.500" }}
											type="number"
										/>
										<InputRightAddon children="%" />
									</InputGroup>
									<Select
										width={"fit-content"}
										name="currency"
										placeholder={t("properties.currency")}
										_placeholder={{ color: "gray.500" }}
										isInvalid={error?.bankDetails?.currency}
										value={propertyInformation?.bankDetails.currency}
										onChange={(e) => {
											setpropertyInformation({ ...propertyInformation, bankDetails: { ...propertyInformation.bankDetails, currency: e.target.value } });
										}}
									>
										<option value="eur">EUR</option>
										<option value="ron">RON</option>
									</Select>
								</Stack>
							</FormControl>
							<FormControl isRequired>
								<FormLabel fontWeight={"bold"}>{t("properties.owner")}</FormLabel>
								<Stack spacing={2}>
									<ChakraSelect
										options={owners.map((o) => {
											return { label: o.name, value: o };
										})}
										onChange={(own) => {
											setpropertyInformation({
												...propertyInformation,
												owner: { ...propertyInformation.owner, email: own.value.email, name: own.value.name, phoneNumber: own.value.phoneNumber },
												bankDetails: {
													...propertyInformation.bankDetails,
													account: own.value.bankDetails.account,
													currency: own.value.bankDetails.currency,
													administrationFeePercent: own.value.bankDetails.administrationFeePercent,
												},
											});
										}}
									/>
									<Input
										name="name"
										placeholder={t("properties.name")}
										isInvalid={error?.owner?.name}
										value={propertyInformation?.owner.name}
										onChange={(e) => {
											setpropertyInformation({ ...propertyInformation, owner: { ...propertyInformation.owner, name: e.target.value } });
										}}
										_placeholder={{ color: "gray.500" }}
										type="text"
									/>
									<FormControl>
										<Input
											name="email"
											placeholder={t("properties.email")}
											isInvalid={error?.owner?.email}
											value={propertyInformation?.owner.email}
											onChange={(e) => {
												setpropertyInformation({ ...propertyInformation, owner: { ...propertyInformation.owner, email: e.target.value } });
											}}
											_placeholder={{ color: "gray.500" }}
											type="email"
										/>
										<FormHelperText>Important: Verify the email carefully; it can't be changed later.</FormHelperText>
									</FormControl>
									<Input
										name="phoneNumber"
										placeholder={t("properties.phoneNumber")}
										isInvalid={error?.owner?.phoneNumber}
										value={propertyInformation?.owner.phoneNumber}
										onChange={(e) => {
											setpropertyInformation({ ...propertyInformation, owner: { ...propertyInformation.owner, phoneNumber: e.target.value } });
										}}
										_placeholder={{ color: "gray.500" }}
										type="text"
									/>
								</Stack>
							</FormControl>
						</Grid>
					</Flex>
				</ModalBody>

				<ModalFooter>
					<Button colorScheme="blue" mr={3} onClick={handleSave}>
						{t("misc.save")}
					</Button>
					<Button onClick={handleClose} colorScheme="red">
						{t("misc.cancel")}
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
	// const renderUploadContractModal = () => {};
	return (
		<>
			<Stack direction={"row"}>
				<Tooltip label="Add billing information to the agency" isDisabled={Object.keys(billingInformation).length !== 0}>
					<Button onClick={onOpen} colorScheme={"teal"} isDisabled={Object.keys(billingInformation).length === 0}>
						Add property
					</Button>
				</Tooltip>
				{/* <Tooltip label="Add billing information to the agency" isDisabled={Object.keys(billingInformation).length != 0}>
					<Button colorScheme={"teal"} onClick={onUploadContractOpen} isDisabled={Object.keys(billingInformation).length === 0}>
						Upload Smart Contract
					</Button>
				</Tooltip> */}
			</Stack>
			{renderAddPropertyModal()}
		</>
	);
}
