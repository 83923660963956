import { Badge, Box, Button, Card, CardBody, CardHeader, Flex, Link, Stack, Text, Tooltip, Wrap, WrapItem } from "@chakra-ui/react";
import React, { useState } from "react";
import { Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableContainer } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import moment from "moment";
import { getPropertyAddress } from "../../utils/verifier";
import { NavLink as ReactRouterLink } from "react-router-dom";
import { fullDateFormat } from "../../utils/types";
import SearchBox from "../Properties/SearchBox";
import { selectActiveProperties } from "../../redux/user/userSlice";
import { useTranslation } from "react-i18next";

export default function FinancialStatus() {
	const properties = useSelector(selectActiveProperties);
	const { t } = useTranslation();
	const [query, setquery] = useState("");
	const handleSearch = (query) => {
		setquery(query);
	};
	const [date, setDate] = useState([moment().year(), moment().month()]);
	const getRentColorScheme = (rentAmount, monthIndex, financialStatus, property) => {
		//if rent from this month is 0 then status is red
		//if rent from this month is not 0 then status is yellow
		//if rent from this month is not 0 and status is transferat then status is green
		if (financialStatus[moment(date).year()] && financialStatus[moment(date).year()][monthIndex]?.status) return "green";
		if (property.tenants.filter((t) => !t.former).length === 0) return "blue";
		if (rentAmount === 0) return "red";
		if (financialStatus[moment(date).year()]) return financialStatus[moment(date).year()][monthIndex]?.status ? "green" : "yellow";
		return "yellow";
	};
	const owners = properties.map((property) => property.owner).filter((v, i, a) => a.findIndex((v2) => v2.id === v.id) === i);
	const ownerProperties = owners
		.map((owner) => {
			return { ...owner, properties: properties.filter((property) => property.owner.email === owner.email) };
		})
		.sort((a, b) => a.name.localeCompare(b.name, "ro", { sensitivity: "base" }));
	let status = {
		goale: ownerProperties.reduce((acc, owner) => {
			const financialStatus = owner.financialStatus;
			const thisOwnerCount = owner.properties.reduce((acc, property) => {
				const rentPaymentThisMonth = property.payments
					.filter(
						(payment) =>
							payment.type === "rent" && moment(payment.paymentDate).isSame(moment(date), "year") && moment(payment.paymentDate).isSame(moment(date), "month")
					)
					.reduce((acc, payment) => {
						return acc.amount + Number(payment.amount);
					}, 0);
				if (getRentColorScheme(rentPaymentThisMonth, moment(date).month(), financialStatus, property) === "blue") return acc + 1;
				return acc;
			}, 0);
			return acc + thisOwnerCount;
		}, 0),
		chirieTransferata: ownerProperties.reduce((acc, owner) => {
			const financialStatus = owner.financialStatus;
			const thisOwnerCount = owner.properties.reduce((acc, property) => {
				const rentPaymentThisMonth = property.payments
					.filter(
						(payment) =>
							payment.type === "rent" && moment(payment.paymentDate).isSame(moment(date), "year") && moment(payment.paymentDate).isSame(moment(date), "month")
					)
					.reduce((acc, payment) => {
						return acc.amount + Number(payment.amount);
					}, 0);
				if (getRentColorScheme(rentPaymentThisMonth, moment(date).month(), financialStatus, property) === "green") return acc + 1;
				return acc;
			}, 0);
			return acc + thisOwnerCount;
		}, 0),
		chirieNetransferata: ownerProperties.reduce((acc, owner) => {
			const financialStatus = owner.financialStatus;
			const thisOwnerCount = owner.properties.reduce((acc, property) => {
				const rentPaymentThisMonth = property.payments
					.filter(
						(payment) =>
							payment.type === "rent" && moment(payment.paymentDate).isSame(moment(date), "year") && moment(payment.paymentDate).isSame(moment(date), "month")
					)
					.reduce((acc, payment) => {
						return acc.amount + Number(payment.amount);
					}, 0);
				if (getRentColorScheme(rentPaymentThisMonth, moment(date).month(), financialStatus, property) === "yellow") return acc + 1;
				return acc;
			}, 0);
			return acc + thisOwnerCount;
		}, 0),
		chirieNeincasata: ownerProperties.reduce((acc, owner) => {
			const financialStatus = owner.financialStatus;
			const thisOwnerCount = owner.properties.reduce((acc, property) => {
				const rentPaymentThisMonth = property.payments
					.filter(
						(payment) =>
							payment.type === "rent" && moment(payment.paymentDate).isSame(moment(date), "year") && moment(payment.paymentDate).isSame(moment(date), "month")
					)
					.reduce((acc, payment) => {
						return acc.amount + Number(payment.amount);
					}, 0);
				if (getRentColorScheme(rentPaymentThisMonth, moment(date).month(), financialStatus, property) === "red") return acc + 1;
				return acc;
			}, 0);
			return acc + thisOwnerCount;
		}, 0),
	};

	const financialStatuses = ["custodie", "transferat", "ownerAccount", "predareCash"];
	const financialStatusesMonth = owners
		.map((owner) => {
			return owner.financialStatus[moment(date).year()] ? owner.financialStatus[moment(date).year()][moment(date).month()] : null;
		})
		.filter((f) => f);

	const getFinancialStatusesAmount = (currency, status) => {
		return financialStatusesMonth.filter((fs) => fs.currency === currency && fs.status === status).reduce((acc, fs) => acc + Number(fs.amount), 0);
	};

	return (
		<Card>
			<CardHeader>
				<Flex alignItems="center" justifyContent={"space-between"}>
					<Stack direction={"row"} alignItems="center">
						<Text fontSize="xl" fontWeight="bold">
							{t("financialStatus.financialStatus")}
						</Text>
						<Box>
							<SearchBox handleSearch={handleSearch} searchOptions={[{ label: "Name", description: "Search by owner name" }]} />
						</Box>
					</Stack>

					<Stack>
						<Text fontSize={"sm"}>{t("misc.legend")}:</Text>
						<Badge colorScheme="blue">Proprietate goala - {status.goale}</Badge>
						<Badge colorScheme="green">Chirie incasata si transferata - {status.chirieTransferata}</Badge>
						<Badge colorScheme="yellow">Chirie incasata si netransferata - {status.chirieNetransferata}</Badge>
						<Badge colorScheme="red">Chirie neincasata - {status.chirieNeincasata}</Badge>
					</Stack>
				</Flex>
			</CardHeader>
			<CardBody>
				<Stack spacing={3}>
					<Flex direction="row" justifyContent={"space-around"}>
						<Button
							onClick={() => {
								const newDate = moment(date).subtract(1, "months");
								console.log(newDate.format());
								setDate([newDate.year(), newDate.month()]);
							}}
						>
							{t("table.previousMonth")}
						</Button>
						<Text>{moment(date).format("MMMM YYYY")}</Text>
						<Button
							isDisabled={moment().isSame(moment(date), "year") && moment().isSame(moment(date), "month")}
							onClick={() => {
								const newDate = moment(date).add(1, "months");
								console.log(newDate.format());
								setDate([newDate.year(), newDate.month()]);
							}}
						>
							{t("table.nextMonth")}
						</Button>
					</Flex>
					<Wrap direction="row">
						<WrapItem>
							<Text fontWeight={"bold"}>Total month:</Text>
						</WrapItem>
						{financialStatuses.map((l, index) => (
							<WrapItem key={index}>
								<Badge display="flex" alignItems="center" textTransform={"none"}>
									<Stack direction="row">
										<Text>{l}:</Text>
										<Text>{Number(getFinancialStatusesAmount("ron", l)).toFixed(2)} ron,</Text>
										<Text>{Number(getFinancialStatusesAmount("eur", l)).toFixed(2)} eur</Text>
									</Stack>
								</Badge>
							</WrapItem>
						))}
					</Wrap>
					<TableContainer>
						<Table size="sm">
							<Thead>
								<Tr>
									<Th>{t("table.name")}</Th>
									<Th>{t("table.address")}</Th>
									<Th colSpan={2}>{t("table.rentReceived")}</Th>
									<Th>{t("table.method")}</Th>
									<Th colSpan={2}>{t("table.administrationFee")}</Th>
									<Th colSpan={2}>{t("table.tax")}</Th>
									<Th colSpan={2}>{t("table.centralHeating")}</Th>
									<Th colSpan={2}>{t("table.insurance")}</Th>
									<Th colSpan={2}>{t("table.others")}</Th>
									<Th colSpan={2}>{t("table.subTotal")}</Th>
									<Th>{t("table.status")}</Th>
								</Tr>
								<Tr>
									<Th></Th>
									<Th></Th>
									{/*  */}
									<Th>Eur</Th>
									<Th>Ron</Th>
									{/*  */}
									<Th></Th>
									<Th>Eur</Th>
									<Th>Ron</Th>
									{/*  */}
									<Th>Eur</Th>
									<Th>Ron</Th>
									{/*  */}
									<Th>Eur</Th>
									<Th>Ron</Th>
									{/*  */}
									<Th>Eur</Th>
									<Th>Ron</Th>
									{/*  */}
									<Th>Eur</Th>
									<Th>Ron</Th>
									{/*  */}
									<Th>Eur</Th>
									<Th>Ron</Th>
									{/*  */}
									<Th></Th>
									{/*  */}
									<Th></Th>
								</Tr>
							</Thead>
							<Tbody>
								{ownerProperties
									.filter((owner) => owner.name.toLowerCase().includes(query.toLowerCase()))
									.map((owner, index) => {
										const ownerCurrency = owner.bankDetails.currency;
										const properties = owner.properties;

										return properties.map((property, index) => {
											const payments = property.payments.filter(
												(payment) => moment(payment.paymentDate).isSame(moment(date), "year") && moment(payment.paymentDate).isSame(moment(date), "month")
											);
											const rentInvoice = payments
												.filter((i) => i.type === "rent")
												.reduce(
													(accumulator, currentValue) => {
														let amount = 0;
														let exchangeAmount = 0;
														let exchangeCurrency = "";
														if (currentValue.currency === ownerCurrency) {
															amount = Number(currentValue.amount);
															exchangeAmount = Number(currentValue.exchange?.amount);
															exchangeCurrency = currentValue.exchange?.currency;
														} else {
															amount = Number(currentValue.exchange.amount);
															exchangeAmount = Number(currentValue.amount);
															exchangeCurrency = currentValue.currency;
														}
														return {
															...accumulator,
															description: [
																...accumulator.description,
																`${moment(currentValue.paymentDate).format(fullDateFormat)} - ${amount} ${ownerCurrency} ${currentValue.paymentMethod}${
																	exchangeAmount ? " - " + exchangeAmount + " " + exchangeCurrency : ""
																} - ${currentValue.description}`,
															],
															amount: accumulator.amount + amount,
															paymentMethod: [...accumulator.paymentMethod, currentValue.paymentMethod],
															currency: ownerCurrency,
														};
													},
													{
														amount: 0,
														currency: ownerCurrency,
														paymentMethod: [],
														description: [
															property.rent.firstRentDay && property.rent.lastRentDay
																? `Perioada de incasare: ${property.rent.firstRentDay} - ${property.rent.lastRentDay}`
																: "",
														],
													}
												);

											const administrationFeeInvoice = payments
												.filter((i) => i.type === "administrationFee")
												.reduce(
													(accumulator, currentValue) => {
														if (currentValue.currency === ownerCurrency)
															return {
																...accumulator,
																[ownerCurrency]:
																	accumulator[ownerCurrency] + Number(currentValue.amount) * (currentValue.paymentDirection === "outgoing" ? -1 : 1),
															};
														else
															return {
																...accumulator,
																[ownerCurrency]:
																	accumulator[ownerCurrency] + Number(currentValue.exchange.amount) * (currentValue.paymentDirection === "outgoing" ? -1 : 1),
															};
													},
													{ eur: 0, ron: 0 }
												);
											const taxInvoice = payments
												.filter((i) => i.type === "tax")
												.reduce(
													(accumulator, currentValue) => {
														if (currentValue.currency === ownerCurrency)
															return {
																...accumulator,
																[ownerCurrency]:
																	accumulator[ownerCurrency] + Number(currentValue.amount) * (currentValue.paymentDirection === "outgoing" ? -1 : 1),
															};
														else
															return {
																...accumulator,
																[ownerCurrency]:
																	accumulator[ownerCurrency] + Number(currentValue.exchange.amount) * (currentValue.paymentDirection === "outgoing" ? -1 : 1),
															};
													},
													{ eur: 0, ron: 0 }
												);
											const insuranceInvoice = payments
												.filter((i) => i.type === "insurance")
												.reduce(
													(accumulator, currentValue) => {
														if (currentValue.currency === ownerCurrency)
															return {
																...accumulator,
																[ownerCurrency]:
																	accumulator[ownerCurrency] + Number(currentValue.amount) * (currentValue.paymentDirection === "outgoing" ? -1 : 1),
															};
														else
															return {
																...accumulator,
																[ownerCurrency]:
																	accumulator[ownerCurrency] + Number(currentValue.exchange.amount) * (currentValue.paymentDirection === "outgoing" ? -1 : 1),
															};
													},
													{ eur: 0, ron: 0 }
												);
											const centralHeatingVerificationInvoice = payments
												.filter((i) => i.type === "centralHeatingVerification")
												.reduce(
													(accumulator, currentValue) => {
														if (currentValue.currency === ownerCurrency)
															return {
																...accumulator,
																[ownerCurrency]:
																	accumulator[ownerCurrency] + Number(currentValue.amount) * (currentValue.paymentDirection === "outgoing" ? -1 : 1),
															};
														else
															return {
																...accumulator,
																[ownerCurrency]:
																	accumulator[ownerCurrency] + Number(currentValue.exchange.amount) * (currentValue.paymentDirection === "outgoing" ? -1 : 1),
															};
													},
													{ eur: 0, ron: 0 }
												);
											const otherDescription = payments
												.filter((i) => i.type === "other")
												.map((p) => {
													return `${p.paymentDirection === "incoming" ? "+" : "-"}${p.amount} ${p.currency} - ${p.description || "Fara descriere"}`;
												});
											const otherInvoice = payments
												.filter((i) => i.type === "other")
												.reduce(
													(accumulator, currentValue) => {
														if (currentValue.currency === ownerCurrency)
															return {
																...accumulator,
																[ownerCurrency]:
																	accumulator[ownerCurrency] + Number(currentValue.amount) * (currentValue.paymentDirection === "outgoing" ? -1 : 1),
															};
														else
															return {
																...accumulator,
																[ownerCurrency]:
																	accumulator[ownerCurrency] + Number(currentValue.exchange.amount) * (currentValue.paymentDirection === "outgoing" ? -1 : 1),
															};
													},
													{ eur: 0, ron: 0 }
												);
											return (
												<Tr key={index}>
													<Td>
														<Link as={ReactRouterLink} to={`/owner/view/${owner.id}`}>
															<Badge colorScheme={getRentColorScheme(rentInvoice?.amount, moment(date).month(), owner.financialStatus, property)}>
																{property.owner.name}
															</Badge>
														</Link>
													</Td>
													<Td>
														<Link as={ReactRouterLink} to={`/properties/view/${property.id}`}>
															<Badge colorScheme={getRentColorScheme(rentInvoice?.amount, moment(date).month(), owner.financialStatus, property)}>
																{getPropertyAddress(property)}
															</Badge>
														</Link>
													</Td>
													{/*  */}
													<Td>
														<Tooltip label={rentInvoice?.description.join("\n")} whiteSpace={"pre-line"}>
															<Text>{rentInvoice?.currency === "eur" ? Number(rentInvoice?.amount).toFixed(2) : "0.00"}</Text>
														</Tooltip>
													</Td>
													<Td>
														<Tooltip label={rentInvoice?.description.join("\n")} whiteSpace={"pre-line"}>
															<Text>{rentInvoice?.currency === "ron" ? Number(rentInvoice?.amount).toFixed(2) : "0.00"}</Text>
														</Tooltip>
													</Td>
													{/*  */}
													<Td>{rentInvoice?.paymentMethod.join(", ")}</Td>
													{/*  */}
													<Td>{Number(administrationFeeInvoice?.eur).toFixed(2)}</Td>
													<Td>{Number(administrationFeeInvoice?.ron).toFixed(2)}</Td>
													{/*  */}
													<Td>{Number(taxInvoice?.eur).toFixed(2)}</Td>
													<Td>{Number(taxInvoice?.ron).toFixed(2)}</Td>
													{/*  */}
													<Td>{Number(centralHeatingVerificationInvoice?.eur).toFixed(2)}</Td>
													<Td>{Number(centralHeatingVerificationInvoice?.ron).toFixed(2)}</Td>
													{/*  */}
													<Td>{Number(insuranceInvoice?.eur).toFixed(2)}</Td>
													<Td>{Number(insuranceInvoice?.ron).toFixed(2)}</Td>
													{/*  */}

													<Td>
														<Tooltip label={otherDescription.join("\n")} whiteSpace={"pre-line"}>
															<Text>{Number(otherInvoice?.eur).toFixed(2)}</Text>
														</Tooltip>
													</Td>
													<Td>
														<Tooltip label={otherDescription.join("\n")} whiteSpace={"pre-line"}>
															<Text>{Number(otherInvoice?.ron).toFixed(2)}</Text>
														</Tooltip>
													</Td>
													{/*  */}
													<Td>
														{rentInvoice?.currency === "eur"
															? Number(
																	rentInvoice?.amount -
																		administrationFeeInvoice?.eur +
																		taxInvoice?.eur +
																		centralHeatingVerificationInvoice?.eur +
																		insuranceInvoice?.eur +
																		otherInvoice?.eur
															  ).toFixed(2)
															: "0.00"}
													</Td>
													<Td>
														{rentInvoice?.currency === "ron"
															? Number(
																	rentInvoice?.amount -
																		administrationFeeInvoice?.ron +
																		taxInvoice?.ron +
																		centralHeatingVerificationInvoice?.ron +
																		insuranceInvoice?.ron +
																		otherInvoice?.ron
															  ).toFixed(2)
															: "0.00"}
													</Td>
													<Td>
														{owner.financialStatus[moment(date).year()]
															? owner.financialStatus[moment(date).year()][moment(date).month()]
																? `${owner.financialStatus[moment(date).year()][moment(date).month()].status} - ${moment(
																		owner.financialStatus[moment(date).year()][moment(date).month()].date
																  ).format(fullDateFormat)}`
																: "-"
															: "-"}
													</Td>
												</Tr>
											);
										});
									})}
							</Tbody>
							<Tfoot>
								<Tr>
									<Th></Th>
									<Th></Th>
									{/*  */}
									<Th>Eur</Th>
									<Th>Ron</Th>
									{/*  */}
									<Th></Th>
									<Th>Eur</Th>
									<Th>Ron</Th>
									{/*  */}
									<Th>Eur</Th>
									<Th>Ron</Th>
									{/*  */}
									<Th>Eur</Th>
									<Th>Ron</Th>
									{/*  */}
									<Th>Eur</Th>
									<Th>Ron</Th>
									{/*  */}
									<Th>Eur</Th>
									<Th>Ron</Th>
									{/*  */}
									<Th>Eur</Th>
									<Th>Ron</Th>
									{/*  */}
									<Th></Th>
								</Tr>
								<Tr>
									<Th>{t("table.name")}</Th>
									<Th>{t("table.address")}</Th>
									<Th colSpan={2}>{t("table.rentReceived")}</Th>
									<Th>{t("table.method")}</Th>
									<Th colSpan={2}>{t("table.administrationFee")}</Th>
									<Th colSpan={2}>{t("table.tax")}</Th>
									<Th colSpan={2}>{t("table.centralHeating")}</Th>
									<Th colSpan={2}>{t("table.insurance")}</Th>
									<Th colSpan={2}>{t("table.others")}</Th>
									<Th colSpan={2}>{t("table.subTotal")}</Th>
									<Th>{t("table.status")}</Th>
								</Tr>
							</Tfoot>
						</Table>
					</TableContainer>
				</Stack>
			</CardBody>
		</Card>
	);
}
