import React, { useEffect } from "react";
import {
	Box,
	Stack,
	HStack,
	Heading,
	Text,
	VStack,
	useColorModeValue,
	List,
	ListItem,
	ListIcon,
	Button,
	SimpleGrid,
	Card,
	CardBody,
	CardHeader,
} from "@chakra-ui/react";
import { FaCheckCircle } from "react-icons/fa";
import { Divider } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { API } from "../../utils/http";
import moment from "moment/moment";
import { fullDateFormat } from "../../utils/types";
function PriceWrapper({ children }) {
	return (
		<Box
			mb={4}
			shadow="base"
			borderWidth="1px"
			alignSelf={{ base: "center", lg: "flex-start" }}
			borderColor={useColorModeValue("gray.200", "gray.500")}
			bg={useColorModeValue("gray.50", "gray.700")}
			borderRadius={"xl"}
		>
			{children}
		</Box>
	);
}
export default function Subscription() {
	// const currentPlan = useSelector((state) => state.user.user.plan);
	const subscriptionPlan = useSelector((state) => state.user.user.subscriptionPlan);
	const customerId = useSelector((state) => state.user.user.stripeCustomerId);
	// const [plans, setPlans] = useState([]);
	useEffect(() => {
		//fetch plans from api
		API.get("/get-plans").then((r) => {
			console.log(r.data);
			// setPlans(r.data);
		});
		console.log("fetching plans...");
		// setPlans([{ price_id: "price_1MyHIzFi3qZeBet7ryI3Z7Kv" }]);
	}, []);
	const manageSubscription = () => {
		API.post("/create-portal-session", { customerId }).then((r) => {
			console.log(r.data);
			window.open(r.data.portalUrl, "_self");
		});
	};
	const subscribe = (plan) => {
		console.log("subscribe to plan: ", plan);
		API.post("/create-checkout-session", { priceId: "price_1O4qxWFi3qZeBet7f3MsYIle", customerId }).then((r) => {
			//prod price
			console.log(r.data);
			window.open(r.data.sessionUrl, "_self");
		});
	};
	return (
		<Stack>
			{subscriptionPlan && (
				<Card>
					<CardHeader>
						<Text fontWeight={"bold"} fontSize="2xl">
							Your Current Subscription
						</Text>
					</CardHeader>
					<CardBody>
						<Text fontSize="xl">Plan: {subscriptionPlan.planName}</Text>
						<Text fontSize="xl">
							Price: {subscriptionPlan.planPrice / 100} {subscriptionPlan.planCurrency}/month
						</Text>
						<Text fontSize="xl">Current Period Start: {moment.unix(subscriptionPlan.currentPeriodStart).format(fullDateFormat)}</Text>
						<Text fontSize="xl">Current Period End: {moment.unix(subscriptionPlan.currentPeriodEnd).format(fullDateFormat)}</Text>
						{subscriptionPlan.trialEnd && <Text fontSize="xl">Trial End: {subscriptionPlan.trialEnd}</Text>}
					</CardBody>
				</Card>
			)}
			<Box py={12}>
				<VStack spacing={2} textAlign="center">
					<Heading as="h1" fontSize="4xl">
						Plans that fit your need
					</Heading>

					{/* <Text fontSize="lg" color={"gray.500"}>
						Start with 14-day free trial. No credit card needed. Cancel at anytime.
					</Text> */}
				</VStack>
				<SimpleGrid columns={{ md: 1, xl: 3 }} spacing="24px" textAlign="center" justify="center" py={10}>
					<PriceWrapper>
						<Box py={4} px={12}>
							<Text fontWeight="500" fontSize="2xl">
								Basic
							</Text>
							<HStack justifyContent="center">
								<Text fontSize="3xl" fontWeight="600">
									RON
								</Text>
								<Text fontSize="5xl" fontWeight="900">
									250
								</Text>
								<Text fontSize="3xl" color="gray.500">
									/month
								</Text>
							</HStack>
						</Box>
						<Divider size="xl" />
						<VStack py={4} borderBottomRadius={"xl"}>
							<List spacing={3} textAlign="start" px={12}>
								<ListItem>
									<ListIcon as={FaCheckCircle} color="green.500" />
									unlimited build minutes
								</ListItem>
								<ListItem>
									<ListIcon as={FaCheckCircle} color="green.500" />
									Lorem, ipsum dolor.
								</ListItem>
								<ListItem>
									<ListIcon as={FaCheckCircle} color="green.500" />
									5TB Lorem, ipsum dolor.
								</ListItem>
							</List>
							<Box w="80%" pt={7}>
								{!subscriptionPlan ? (
									<Button
										w="full"
										colorScheme="red"
										variant="outline"
										onClick={() => {
											subscribe("basic");
										}}
									>
										Subscribe
									</Button>
								) : (
									<Button onClick={manageSubscription}>Manage subscription</Button>
								)}
							</Box>
						</VStack>
					</PriceWrapper>

					<PriceWrapper>
						<Box position="relative">
							<Box position="absolute" top="-16px" left="50%" style={{ transform: "translate(-50%)" }}>
								<Text
									textTransform="uppercase"
									bg={useColorModeValue("red.300", "red.700")}
									px={3}
									py={1}
									color={useColorModeValue("gray.900", "gray.300")}
									fontSize="sm"
									fontWeight="600"
									rounded="xl"
								>
									Most Popular
								</Text>
							</Box>
							<Box py={4} px={12}>
								<Text fontWeight="500" fontSize="2xl">
									Business
								</Text>
								<HStack justifyContent="center">
									<Text fontSize="3xl" fontWeight="600">
										$
									</Text>
									<Text fontSize="5xl" fontWeight="900">
										149
									</Text>
									<Text fontSize="3xl" color="gray.500">
										/month
									</Text>
								</HStack>
							</Box>
							<Divider size="xl" />
							<VStack bg={useColorModeValue("gray.50", "gray.700")} py={4} borderBottomRadius={"xl"}>
								<List spacing={3} textAlign="start" px={12}>
									<ListItem>
										<ListIcon as={FaCheckCircle} color="green.500" />
										unlimited build minutes
									</ListItem>
									<ListItem>
										<ListIcon as={FaCheckCircle} color="green.500" />
										Lorem, ipsum dolor.
									</ListItem>
									<ListItem>
										<ListIcon as={FaCheckCircle} color="green.500" />
										5TB Lorem, ipsum dolor.
									</ListItem>
									<ListItem>
										<ListIcon as={FaCheckCircle} color="green.500" />
										5TB Lorem, ipsum dolor.
									</ListItem>
									<ListItem>
										<ListIcon as={FaCheckCircle} color="green.500" />
										5TB Lorem, ipsum dolor.
									</ListItem>
								</List>
								<Box w="80%" pt={7}>
									{!subscriptionPlan ? (
										<Button
											w="full"
											colorScheme="red"
											variant="outline"
											onClick={() => {
												subscribe("business");
											}}
										>
											Subscribe
										</Button>
									) : (
										<Button onClick={manageSubscription}>Manage subscription</Button>
									)}
								</Box>
							</VStack>
						</Box>
					</PriceWrapper>
					<PriceWrapper>
						<Box py={4} px={12}>
							<Text fontWeight="500" fontSize="2xl">
								Enterprise
							</Text>
							<HStack justifyContent="center">
								<Text fontSize="3xl" fontWeight="600">
									$
								</Text>
								<Text fontSize="5xl" fontWeight="900">
									349
								</Text>
								<Text fontSize="3xl" color="gray.500">
									/month
								</Text>
							</HStack>
						</Box>
						<Divider size="xl" />
						<VStack bg={useColorModeValue("gray.50", "gray.700")} py={4} borderBottomRadius={"xl"}>
							<List spacing={3} textAlign="start" px={12}>
								<ListItem>
									<ListIcon as={FaCheckCircle} color="green.500" />
									unlimited build minutes
								</ListItem>
								<ListItem>
									<ListIcon as={FaCheckCircle} color="green.500" />
									Lorem, ipsum dolor.
								</ListItem>
								<ListItem>
									<ListIcon as={FaCheckCircle} color="green.500" />
									5TB Lorem, ipsum dolor.
								</ListItem>
							</List>
							<Box w="80%" pt={7}>
								{!subscriptionPlan ? (
									<Button
										w="full"
										colorScheme="red"
										variant="outline"
										onClick={() => {
											subscribe("enterprise");
										}}
									>
										Subscribe
									</Button>
								) : (
									<Button onClick={manageSubscription}>Manage subscription</Button>
								)}
							</Box>
						</VStack>
					</PriceWrapper>
				</SimpleGrid>
			</Box>
		</Stack>
	);
}
