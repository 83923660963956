import {
	Flex,
	Box,
	FormControl,
	FormLabel,
	Input,
	Stack,
	Link,
	Button,
	Heading,
	Text,
	useColorModeValue,
	useToast,
	FormErrorMessage,
	Image,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { confirmSignUpAsync, signInAsync } from "../../redux/auth/authSlice";
import { Link as ReactRouterLink } from "react-router-dom";
import { signInVerifier } from "../../utils/verifier";
import VerificationCode from "../../Components/VerificationCode/VerificationCode";
import { Auth } from "aws-amplify";
import RentITLogo from "../../assets/img/rentit-logo.png";

import ForgotPassword from "../../Components/ForgotPassword/ForgotPassword";
import Banner from "../../Components/Banner/Banner";
export default function SignInPage() {
	const dispatch = useDispatch();
	const status = useSelector((state) => state.auth.status);
	const user = useSelector((state) => state.auth.currentUserEmail);
	const [email, setEmail] = useState("");
	const [step, setStep] = useState("signin");
	const toast = useToast();
	const [error, setError] = useState({});
	const [password, setPassword] = useState("");
	const navigate = useNavigate();
	useEffect(() => {
		if (user) navigate("/");
	}, [navigate, user]);
	useEffect(() => {
		console.log(step);
	}, [step]);
	const verifyCode = (code) => {
		// dispatch(signUpAsync({ email, password }));
		console.log("code: ", code);
		console.log("email: ", email);
		dispatch(confirmSignUpAsync({ email, code })).then((r) => {
			console.log(r);
			if (r.error?.message) {
				setError(r.error.message);
				toast({
					title: "Error encountered.",
					description: r.error.message,
					status: "error",
					duration: 9000,
					isClosable: true,
				});
			} else {
				toast({
					title: "Success!",
					description: "Account verified!",
					status: "success",
					duration: 9000,
					isClosable: true,
				});
				navigate("/");
			}
		});
	};
	const submitForgotPassword = (code, newPassword) => {
		// dispatch(signUpAsync({ email, password }));
		console.log("code: ", code);
		console.log("email: ", email);
		Auth.forgotPasswordSubmit(email, code, newPassword)
			.then((data) => {
				console.log(data);
				toast({
					title: "Success!",
					description: "Password Changed!",
					status: "success",
					duration: 9000,
					isClosable: true,
				});
				navigate("/");
			})
			.catch((err) => {
				console.log(err);
				setError(err.message);
				toast({
					title: "Error encountered.",
					description: err.message,
					status: "error",
					duration: 9000,
					isClosable: true,
				});
			});
	};
	const handleSignIn = async () => {
		const errors = signInVerifier({ email, password });
		if (Object.entries(errors).length !== 0) {
			setError(errors);
			toast({
				title: "Error encountered.",
				description: "Invalid information",
				status: "error",
				duration: 9000,
				isClosable: true,
			});
			return;
		}
		setError({});
		dispatch(signInAsync({ email, password })).then((r) => {
			console.log(r);
			if (r.error?.message) {
				toast({
					title: "Error encountered",
					description: r.error.message,
					status: "error",
					duration: 9000,
					isClosable: true,
				});
				if (r.error.code === "UserNotConfirmedException") {
					handleConfirmUser();
				}
			} else {
				navigate("/");
				toast({
					title: "Success!",
					description: "Sign in successful!",
					status: "success",
					duration: 2000,
					isClosable: true,
				});
			}
		});
	};
	const renderContent = () => {
		switch (step) {
			case "signin":
				return renderSignInForm();
			case "verifyEmail":
				return renderVerifyEmailForm();
			case "forgotPassword":
				return renderForgotPasswordForm();

			default:
				break;
		}
	};
	const handleConfirmUser = async () => {
		console.log("handle confirm user");
		await Auth.resendSignUp(email);
		setStep("verifyEmail");
		//TODO: aici trebe trimis codul de verificare si aratat enter pin
	};
	const handleForgotPassword = async () => {
		if (email === "") {
			setError({ email: "This field is required" });
			toast({
				title: "Error encountered",
				description: "You need to fill your email address in order to reset your password",
				status: "error",
				duration: 9000,
				isClosable: true,
			});
			return;
		}
		console.log("handle forgot password");
		await Auth.forgotPassword(email);
		setStep("forgotPassword");
		//TODO: aici trebe trimis codul de verificare si aratat enter pin
	};
	const renderSignInForm = () => {
		return (
			<Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
				<Stack align={"center"}>
					<Image src={"https://rentit-images.s3.eu-central-1.amazonaws.com/logo.png"} alt="logo" height={20} />
					<Text fontSize="2xl" fontWeight="bold">
						Bine ați venit!
					</Text>

					{/* <Heading fontSize={"4xl"}>Sign in to your account</Heading> */}
					{/* <Text fontSize={"lg"} color={"gray.600"}>
						to enjoy all of our cool <Link color={"blue.400"}>{status}</Link> ✌️
					</Text> */}
				</Stack>
				<Box rounded={"lg"} boxShadow={"lg"} p={8}>
					<Stack spacing={4}>
						<FormControl id="email" isRequired isInvalid={error.email}>
							<FormLabel>Email</FormLabel>
							<Input
								onKeyDown={(e) => {
									if (e.key === "Enter") {
										handleSignIn();
									}
								}}
								type="email"
								value={email}
								onChange={(e) => {
									setEmail(e.target.value);
								}}
							/>
							<FormErrorMessage>{error?.email}</FormErrorMessage>
						</FormControl>
						<FormControl id="password" isRequired isInvalid={error.password}>
							<FormLabel>Parola</FormLabel>
							<Input
								onKeyDown={(e) => {
									if (e.key === "Enter") {
										handleSignIn();
									}
								}}
								type="password"
								value={password}
								onChange={(e) => {
									setPassword(e.target.value);
								}}
							/>
							<FormErrorMessage>{error?.password}</FormErrorMessage>
						</FormControl>
						<Stack spacing={10}>
							<Link onClick={handleForgotPassword} color={"blue.400"}>
								Ați uitat parola?
							</Link>

							<Button
								colorScheme="blue"
								onClick={() => {
									handleSignIn();
								}}
							>
								Conectare
							</Button>
						</Stack>
						<Stack pt={6}>
							<Text align={"center"}>
								Nu aveți un cont?{" "}
								<Link as={ReactRouterLink} color={"blue.400"} to="/sign-up">
									Înregistrați-vă
								</Link>{" "}
								acum.
							</Text>
						</Stack>
					</Stack>
				</Box>
			</Stack>
		);
	};
	const renderVerifyEmailForm = () => {
		return <VerificationCode email={email} verifyCode={verifyCode} />;
	};
	const renderForgotPasswordForm = () => {
		return <ForgotPassword email={email} submitForgotPassword={submitForgotPassword} />;
	};
	return (
		<Box minH={"100vh"}>
			{/* <Banner
				description={
					"Suntem conștienți de problemele care pot afecta conectarea/înregistrarea. Echipa noastră lucrează pentru a le remedia și pentru a asigura accesul fără probleme pentru toți utilizatorii. Vă mulțumim pentru înțelegere și răbdare."
				}
				status={"warning"}
			/> */}
			<Flex align={"center"} justify={"center"} bg={useColorModeValue("gray.50", "gray.800")} direction={"column"}>
				{renderContent()}
			</Flex>
		</Box>
	);
}
