import React, { useState } from "react";
import {
	Box,
	Button,
	Table,
	Tbody,
	Text,
	Th,
	Thead,
	Tr,
	Divider,
	Input,
	Stack,
	FormControl,
	FormLabel,
	Select,
	useDisclosure,
	Modal,
	ModalOverlay,
	ModalHeader,
	ModalContent,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	Checkbox,
	Wrap,
	WrapItem,
	useToast,
	Td,
	Tooltip,
	Textarea,
	RadioGroup,
	Radio,
	InputGroup,
	FormHelperText,
} from "@chakra-ui/react";
import { Select as ChakraSelect } from "chakra-react-select";

import Dropzone from "../Dropzone/Dropzone";
import { useDispatch, useSelector } from "react-redux";
import {
	addDocumentAsync,
	addLeaseContactAsync,
	addReportAsync,
	addTenantAsync,
	selectActiveProperties,
	updateOwnerAsync,
	updateTenantFormerAsync,
} from "../../redux/user/userSlice";
import { addDocumentVerifier, addLeaseContactVerifier, addLeaseVerifier, addTenantVerifier, getPropertyStatus } from "../../utils/verifier";
import ContactPopover from "../ContactPopover/ContactPopover";
import TenantPopover from "../ContactPopover/TenantPopover";
import moment from "moment";
import { reportTypes } from "../../utils/types";
import ReportsTableRow from "../TableRows/ReportsTableRow";
import LeaseTableRow from "../TableRows/LeaseTableRow";
import { useTranslation } from "react-i18next";
import { _arrayBufferToBase64 } from "../../utils/convertors";
function mergeAndDeduplicateObjects(arr) {
	const uniqueObjects = new Map();
	arr.forEach((item) => {
		if (!uniqueObjects.has(item.id)) {
			uniqueObjects.set(item.id, item);
		}
	});
	return [...uniqueObjects.values()];
}

export default function TenantsTab({ property }) {
	const isArchived = property.archived;
	const initialDocumentInformation = {
		startingDate: "",
		expirationDate: "",
		documentNumber: "",
		documentType: "lease",
		documentData: "",
		propertyId: property.id,
		documentName: "",
		contentType: "",
		amount: "",
		currency: "",
		firstRentDay: "",
		lastRentDay: "",
		exchangeRateType: "bnr",
		active: true,
	};
	const initialAdditionalDocumentInformation = {
		startingDate: "",
		expirationDate: "",
		documentNumber: "",
		documentType: "",
		documentData: "",
		propertyId: property.id,
		documentName: "",
		contentType: "",
		active: true,
	};
	const initialTenantInformation = {
		name: "",
		email: "",
		phoneNumber: "",
		propertyId: property.id,
		contactType: "tenant",
		existing: false,
	};
	const initialContactInformation = {
		name: "",
		email: "",
		phoneNumber: "",
		propertyId: property.id,
		contactType: "occupant",
	};
	const contactTypes = [
		{ id: "legalPerson", label: "Persoana Juridica" },
		{ id: "contactPerson", label: "Persoana de contact" },
		{ id: "occupant", label: "Occupant" },
		{ id: "accountant", label: "Accountant" },
		{ id: "tenantFather", label: "Tenant Father" },
		{ id: "tenantMother", label: "Tenant Mother" },
		{ id: "tenantSon", label: "Tenant Son" },
		{ id: "tenantDaughter", label: "Tenant Daughter" },
		{ id: "companyAdministrator", label: "Company Administrator" },
		{ id: "formerTenant", label: "Fost chirias" },
		{ id: "other", label: "Other" },
	];
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { isOpen: isAdditionalDocumentOpen, onOpen: onAdditionalDocumentOpen, onClose: onAdditionalDocumentClose } = useDisclosure();
	const { isOpen: isOpenAddTenant, onOpen: onOpenAddTenant, onClose: onCloseAddTenant } = useDisclosure();
	const { isOpen: isOpenAddContact, onOpen: onOpenAddContact, onClose: onCloseAddContact } = useDisclosure();
	const { isOpen: isReportOpen, onOpen: onReportOpen, onClose: onReportClose } = useDisclosure();
	const { isOpen: isExistingTenantOpen, onToggle: onExistingTenantToggle } = useDisclosure();

	const ownerBillingInformation = property.owner.billingInformation;
	const properties = useSelector(selectActiveProperties);
	const uniqueTenantsAndFormerTenants = properties.reduce((acc, property) => {
		const allPeople = [...property.tenants, ...property.formerTenants];
		return mergeAndDeduplicateObjects([...acc, ...allPeople]);
	}, []);
	console.log(uniqueTenantsAndFormerTenants);
	const user = useSelector((state) => state.user.user);
	const agencyBillingInformation = user.billingInformation;
	// const formerTenants = property.tenants.filter((t) => t.former);
	const formerTenants = property.formerTenants;
	const formerContacts = property.leaseContacts.filter((t) => t.former);
	const initialReportInformation = {
		propertyId: property.id,
		addedDate: moment().format("yyyy-MM-DD"),
		documentNumber: "",
		documentType: "",
		documentData: "",
		documentName: "",
		contentType: "",
		media: [],
		description: "",
	};
	const [reportInformation, setReportInformation] = useState(initialReportInformation);

	const [additionalDocumentInformation, setAdditionalDocumentInformation] = useState(initialAdditionalDocumentInformation);
	const [documentInformation, setDocumentInformation] = useState(initialDocumentInformation);
	const [tenantInformation, setTenantInformation] = useState(initialTenantInformation);
	const [contactInformation, setContactInformation] = useState(initialContactInformation);
	const [error, setError] = useState({});
	const toast = useToast();
	const reports = property.reports;
	const documents = property.documents.filter(
		(d) => d.type === "lease" || d.type === "extension" || d.type === "termination" || d.type === "sanitationDeclaration" || d.type === "rentModification"
	);
	const handleAddReportChange = (e) => {
		console.log("changeee");
		setReportInformation({ ...reportInformation, [e.target.name]: e.target.value });
	};
	const onDocumentDrop = async (acceptedFiles) => {
		console.log(acceptedFiles);
		const buffer = await acceptedFiles[0].arrayBuffer();
		const documentName = acceptedFiles[0].name;
		const contentType = acceptedFiles[0].type;
		setDocumentInformation({ ...documentInformation, documentData: _arrayBufferToBase64(buffer), documentName, contentType });
	};
	const onAdditionalDocumentDrop = async (acceptedFiles) => {
		console.log(acceptedFiles);
		const buffer = await acceptedFiles[0].arrayBuffer();
		const documentName = acceptedFiles[0].name;
		const contentType = acceptedFiles[0].type;
		setAdditionalDocumentInformation({ ...additionalDocumentInformation, documentData: _arrayBufferToBase64(buffer), documentName, contentType });
	};
	const addDocument = () => {
		console.log(documentInformation);
		const errors = addLeaseVerifier(documentInformation);
		if (Object.entries(errors).length !== 0) {
			setError(errors);
			toast({
				title: "Error encountered.",
				description: "Invalid information",
				status: "error",
				duration: 2000,
				isClosable: true,
			});
			return;
		}
		setError({});

		dispatch(addDocumentAsync(documentInformation))
			.then((r) => {
				if (r.error?.message)
					toast({
						title: "Error encountered",
						description: r.error.message,
						status: "error",
						duration: 9000,
						isClosable: true,
					});
				else {
					toast({
						title: "Success!",
						description: "Document Added!",
						status: "success",
						duration: 2000,
						isClosable: true,
					});
				}
			})
			.finally(() => {
				setDocumentInformation(initialDocumentInformation);
				onClose();
			});
	};
	const addAdditionalDocument = () => {
		console.log(additionalDocumentInformation);
		const errors = addDocumentVerifier(additionalDocumentInformation);
		if (Object.entries(errors).length !== 0) {
			setError(errors);
			toast({
				title: "Error encountered.",
				description: "Invalid information",
				status: "error",
				duration: 2000,
				isClosable: true,
			});
			return;
		}
		setError({});
		dispatch(addDocumentAsync(additionalDocumentInformation))
			.then((r) => {
				if (r.error?.message)
					toast({
						title: "Error encountered",
						description: r.error.message,
						status: "error",
						duration: 9000,
						isClosable: true,
					});
				else {
					toast({
						title: "Success!",
						description: "Document Added!",
						status: "success",
						duration: 2000,
						isClosable: true,
					});
				}
			})
			.finally(() => {
				setAdditionalDocumentInformation(initialAdditionalDocumentInformation);
				onAdditionalDocumentClose();
			});
	};
	const handleAddAdditionalDocumentChange = (e) => {
		console.log("changeee");
		setAdditionalDocumentInformation({ ...additionalDocumentInformation, [e.target.name]: e.target.value });
	};
	const updateTenantStatus = (contact, propertyId, former) => {
		console.log(`update tenant status: ${contact.email}`);

		const payload = {
			email: contact.email,
			former: !former,
			propertyId: propertyId,
		};
		console.log(payload);
		dispatch(updateTenantFormerAsync(payload)).then((r) => {
			if (r.error?.message)
				toast({
					title: "Error encountered",
					description: r.error.message,
					status: "error",
					duration: 9000,
					isClosable: true,
				});
			else {
				toast({
					title: "Success!",
					description: "Update successful",
					status: "success",
					duration: 2000,
					isClosable: true,
				});
			}
		});
	};

	const addContact = () => {
		console.log(contactInformation);
		const errors = addLeaseContactVerifier(contactInformation);
		if (Object.entries(errors).length !== 0) {
			setError(errors);
			toast({
				title: "Error encountered.",
				description: "Invalid information",
				status: "error",
				duration: 2000,
				isClosable: true,
			});
			return;
		}
		setError({});
		dispatch(addLeaseContactAsync(contactInformation))
			.then((r) => {
				if (r.error?.message)
					toast({
						title: "Error encountered",
						description: r.error.message,
						status: "error",
						duration: 9000,
						isClosable: true,
					});
				else {
					toast({
						title: "Success!",
						description: "Tenant Added!",
						status: "success",
						duration: 2000,
						isClosable: true,
					});
				}
			})
			.finally(() => {
				setContactInformation(initialContactInformation);
				onCloseAddContact();
			});
	};
	const addTenant = () => {
		console.log(tenantInformation);
		const errors = addTenantVerifier(tenantInformation);
		if (Object.entries(errors).length !== 0) {
			setError(errors);
			toast({
				title: "Error encountered.",
				description: "Invalid information",
				status: "error",
				duration: 2000,
				isClosable: true,
			});
			return;
		}
		setError({});
		dispatch(addTenantAsync(tenantInformation))
			.then((r) => {
				if (r.error?.message)
					toast({
						title: "Error encountered",
						description: r.error.message,
						status: "error",
						duration: 9000,
						isClosable: true,
					});
				else {
					toast({
						title: "Success!",
						description: "Tenant Added!",
						status: "success",
						duration: 2000,
						isClosable: true,
					});
				}
			})
			.finally(() => {
				setTenantInformation(initialTenantInformation);
				onCloseAddTenant();
			});
	};
	const onMediaDrop = async (acceptedFiles) => {
		const media = [];
		console.log(acceptedFiles);
		for (const file of acceptedFiles) {
			const buffer = await file.arrayBuffer();
			const documentName = file.name;
			const contentType = file.type;
			const doc = { documentData: _arrayBufferToBase64(buffer), documentName, contentType };
			console.log(doc);
			media.push(doc);
		}
		setReportInformation({ ...reportInformation, media });
	};
	const onReportDrop = async (acceptedFiles) => {
		console.log(acceptedFiles);
		const buffer = await acceptedFiles[0].arrayBuffer();
		const documentName = acceptedFiles[0].name;
		const contentType = acceptedFiles[0].type;
		setReportInformation({ ...reportInformation, documentData: _arrayBufferToBase64(buffer), documentName, contentType });
	};
	const addReport = () => {
		console.log(reportInformation);
		const errors = addDocumentVerifier(reportInformation);
		if (Object.entries(errors).length !== 0) {
			setError(errors);
			toast({
				title: "Error encountered.",
				description: "Invalid information",
				status: "error",
				duration: 2000,
				isClosable: true,
			});
			return;
		}
		setError({});
		dispatch(addReportAsync(reportInformation))
			.then((r) => {
				if (r.error?.message)
					toast({
						title: "Error encountered",
						description: r.error.message,
						status: "error",
						duration: 9000,
						isClosable: true,
					});
				else {
					toast({
						title: "Success!",
						description: "Update successful",
						status: "success",
						duration: 2000,
						isClosable: true,
					});
				}
			})
			.finally(() => {
				onReportClose();
				setReportInformation(initialReportInformation);
			});
	};
	const handleChangeTenantInformation = (e) => {
		console.log("changeee");
		setTenantInformation({ ...tenantInformation, [e.target.name]: e.target.value });
	};
	const handleChangeContactInformation = (e) => {
		console.log("changeee");
		setContactInformation({ ...contactInformation, [e.target.name]: e.target.value });
	};
	const handleChange = (e) => {
		console.log("changeee");
		setDocumentInformation({ ...documentInformation, [e.target.name]: e.target.value });
	};
	const renderAddAdditionalDocumentModal = () => (
		<Modal isOpen={isAdditionalDocumentOpen} onClose={onAdditionalDocumentClose} size={"3xl"}>
			<ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
			<ModalContent>
				<ModalHeader>
					<Text>Add Additional Document</Text>
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Stack>
						<FormControl>
							<FormLabel display={"inline"}>Signature Date:</FormLabel>
							<Input
								width={"fit-content"}
								onChange={handleAddAdditionalDocumentChange}
								isInvalid={error?.signatureDate}
								type="date"
								name="signatureDate"
								placeholder="Type your message here..."
								_placeholder={{ color: "gray.500" }}
							/>
						</FormControl>
						<FormControl>
							<FormLabel display={"inline"}>Starting Date:</FormLabel>
							<Input
								width={"fit-content"}
								onChange={handleAddAdditionalDocumentChange}
								isInvalid={error?.startingDate}
								type="date"
								name="startingDate"
								placeholder="Type your message here..."
								_placeholder={{ color: "gray.500" }}
							/>
						</FormControl>
						<FormControl>
							<FormLabel display={"inline"}>Expiration Date:</FormLabel>
							<Input
								width={"fit-content"}
								onChange={handleAddAdditionalDocumentChange}
								type="date"
								name="expirationDate"
								isInvalid={error?.expirationDate}
								placeholder="Type your message here..."
								_placeholder={{ color: "gray.500" }}
							/>
						</FormControl>
						<Checkbox
							name="active"
							onChange={(e) => {
								setAdditionalDocumentInformation({ ...additionalDocumentInformation, active: e.target.checked });
							}}
							isChecked={additionalDocumentInformation.active}
						>
							<Text>Is Active?</Text>
						</Checkbox>
						<FormControl>
							<FormLabel display={"inline"}>Document Number:</FormLabel>
							<Input
								width={"fit-content"}
								onChange={handleAddAdditionalDocumentChange}
								type="text"
								name="documentNumber"
								placeholder="Document Number"
								isInvalid={error?.documentNumber}
								_placeholder={{ color: "gray.500" }}
							/>
						</FormControl>
						<FormControl display={"flex"} alignItems="center">
							<FormLabel display={"inline"}>Document Type:</FormLabel>
							<Select
								width={"fit-content"}
								name="documentType"
								placeholder="Document Type"
								_placeholder={{ color: "gray.500" }}
								onChange={handleAddAdditionalDocumentChange}
								isInvalid={error?.documentType}
							>
								<option value="extension">Act aditional de prelungire</option>
								<option value="termination">Act aditional de reziliere</option>
								<option value="sanitationDeclaration">Declaratie salubritate</option>
								<option value="rentModification">Act modificare chirie</option>
							</Select>
						</FormControl>
						<FormControl>
							<FormLabel display={"inline"}>Description:</FormLabel>
							<Textarea
								onChange={handleAddAdditionalDocumentChange}
								name="description"
								placeholder="Description..."
								isInvalid={error?.documentNumber}
								_placeholder={{ color: "gray.500" }}
							/>
						</FormControl>
						<FormControl>
							<FormLabel>Document:</FormLabel>
							<Dropzone
								onDrop={onAdditionalDocumentDrop}
								accept={{
									"application/pdf": [".pdf"],
									"application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
									"application/msword": [".doc"],
								}}
								description="Upload PDF,DOC or DOCX Format"
								maxFiles={1}
							/>
						</FormControl>
					</Stack>
				</ModalBody>
				<ModalFooter>
					<Button
						colorScheme="red"
						onClick={() => {
							onAdditionalDocumentClose();
							setError({});
							setAdditionalDocumentInformation(initialAdditionalDocumentInformation);
						}}
						mr={3}
					>
						{t("misc.cancel")}
					</Button>
					<Button colorScheme="blue" onClick={addAdditionalDocument}>
						Add
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);

	const renderAddTenantModal = () => (
		<Modal isOpen={isOpenAddTenant} onClose={onCloseAddTenant} size={"xl"}>
			<ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
			<ModalContent>
				<ModalHeader>
					<Text>Add a Tenant</Text>
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Stack>
						<Button
							width={"fit-content"}
							onClick={(e) => {
								if (isExistingTenantOpen) {
									setTenantInformation(initialTenantInformation);
								}
								onExistingTenantToggle();
							}}
						>
							{!isExistingTenantOpen ? "Existing Tenant" : "New Tenant"}
						</Button>

						<Stack hidden={!isExistingTenantOpen}>
							<Text>Search Tenant: </Text>
							<ChakraSelect
								options={uniqueTenantsAndFormerTenants.map((t) => {
									return { label: t.name, value: t };
								})}
								onChange={(tenant) => {
									setTenantInformation({
										...tenantInformation,
										existing: true,
										name: tenant.value.name,
										email: tenant.value.email,
										phoneNumber: tenant.value.phoneNumber,
									});
								}}
							/>
							<Divider />
						</Stack>

						<FormControl>
							<FormLabel display={"inline"}>Name:</FormLabel>
							<Input
								disabled={isExistingTenantOpen}
								isInvalid={error?.name}
								width={"60%"}
								type="text"
								name="name"
								placeholder="Name"
								_placeholder={{ color: "gray.500" }}
								value={tenantInformation.name}
								onChange={handleChangeTenantInformation}
							/>
						</FormControl>
						<FormControl>
							<FormLabel display={"inline"}>Email:</FormLabel>
							<Input
								disabled={isExistingTenantOpen}
								width={"60%"}
								isInvalid={error?.email}
								type="text"
								name="email"
								placeholder="Email"
								_placeholder={{ color: "gray.500" }}
								value={tenantInformation.email}
								onChange={handleChangeTenantInformation}
							/>
							<FormHelperText>Important: Verify the email carefully; it can't be changed later.</FormHelperText>
						</FormControl>
						<FormControl>
							<FormLabel display={"inline"}>Phone Number:</FormLabel>
							<Input
								disabled={isExistingTenantOpen}
								width={"60%"}
								isInvalid={error?.phoneNumber}
								type="number"
								name="phoneNumber"
								placeholder="Phone Number"
								_placeholder={{ color: "gray.500" }}
								value={tenantInformation.phoneNumber}
								onChange={handleChangeTenantInformation}
							/>
						</FormControl>
					</Stack>
				</ModalBody>
				<ModalFooter>
					<Button
						colorScheme="red"
						onClick={() => {
							onCloseAddTenant();
							setError({});
							setTenantInformation(initialTenantInformation);
						}}
						mr={3}
					>
						{t("misc.cancel")}
					</Button>
					<Button colorScheme="blue" onClick={addTenant}>
						{t("misc.save")}
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
	const renderAddContactModal = () => (
		<Modal isOpen={isOpenAddContact} onClose={onCloseAddContact} size={"xl"}>
			<ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
			<ModalContent>
				<ModalHeader>
					<Text>Add a Contact</Text>
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Stack>
						<FormControl>
							<FormLabel display={"inline"}>Name:</FormLabel>
							<Input
								isInvalid={error?.name}
								width={"fit-content"}
								type="text"
								name="name"
								placeholder="Name"
								_placeholder={{ color: "gray.500" }}
								value={contactInformation.name}
								onChange={handleChangeContactInformation}
							/>
						</FormControl>
						<FormControl>
							<FormLabel display={"inline"}>Email:</FormLabel>
							<Input
								width={"fit-content"}
								isInvalid={error?.email}
								type="text"
								name="email"
								placeholder="Email"
								_placeholder={{ color: "gray.500" }}
								value={contactInformation.email}
								onChange={handleChangeContactInformation}
							/>
						</FormControl>
						<FormControl>
							<FormLabel display={"inline"}>Phone Number:</FormLabel>
							<Input
								width={"fit-content"}
								isInvalid={error?.phoneNumber}
								type="number"
								name="phoneNumber"
								placeholder="Phone Number"
								_placeholder={{ color: "gray.500" }}
								value={contactInformation.phoneNumber}
								onChange={handleChangeContactInformation}
							/>
						</FormControl>
						<FormControl display={"flex"} alignItems="center">
							<FormLabel display={"inline"}>Type:</FormLabel>
							<Select
								width={"fit-content"}
								isInvalid={error?.contactType}
								name="contactType"
								placeholder="Type"
								_placeholder={{ color: "gray.500" }}
								value={contactInformation.contactType}
								onChange={handleChangeContactInformation}
							>
								{contactTypes.map((type, index) => (
									<option key={index} value={type.id}>
										{type.label}
									</option>
								))}
							</Select>
						</FormControl>
					</Stack>
				</ModalBody>
				<ModalFooter>
					<Button
						colorScheme="red"
						onClick={() => {
							onCloseAddContact();
							setError({});
							setContactInformation(initialContactInformation);
						}}
						mr={3}
					>
						{t("misc.cancel")}
					</Button>
					<Button colorScheme="blue" onClick={addContact}>
						{t("misc.save")}
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
	const renderAddDocumentModal = () => (
		<Modal isOpen={isOpen} onClose={onClose} size={"3xl"}>
			<ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
			<ModalContent>
				<ModalHeader>
					<Text>Add a Lease</Text>
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Stack>
						<FormControl>
							<FormLabel display={"inline"}>Signature Date:</FormLabel>
							<Input
								width={"fit-content"}
								onChange={handleChange}
								isInvalid={error?.signatureDate}
								type="date"
								name="signatureDate"
								placeholder="Type your message here..."
								_placeholder={{ color: "gray.500" }}
							/>
						</FormControl>
						<FormControl>
							<FormLabel display={"inline"}>Starting Date:</FormLabel>
							<Input
								width={"fit-content"}
								onChange={handleChange}
								isInvalid={error?.startingDate}
								type="date"
								name="startingDate"
								placeholder="Type your message here..."
								_placeholder={{ color: "gray.500" }}
							/>
						</FormControl>
						<FormControl>
							<FormLabel display={"inline"}>Expiration Date:</FormLabel>
							<Input
								width={"fit-content"}
								onChange={handleChange}
								type="date"
								name="expirationDate"
								isInvalid={error?.expirationDate}
								placeholder="Type your message here..."
								_placeholder={{ color: "gray.500" }}
							/>
						</FormControl>
						<FormControl>
							<FormLabel display={"inline"}>Document Number:</FormLabel>
							<Input
								width={"fit-content"}
								onChange={handleChange}
								type="text"
								name="documentNumber"
								placeholder="Document Number"
								isInvalid={error?.documentNumber}
								_placeholder={{ color: "gray.500" }}
							/>
						</FormControl>
						<FormControl>
							<FormLabel display={"inline"}>Amount:</FormLabel>
							<Input
								width={"fit-content"}
								isInvalid={error?.amount}
								onChange={handleChange}
								type="number"
								name="amount"
								placeholder="Amount"
								_placeholder={{ color: "gray.500" }}
							/>
						</FormControl>
						<FormControl display={"flex"} alignItems="center">
							<FormLabel display={"inline"}>Currency:</FormLabel>
							<Select
								width={"fit-content"}
								type="number"
								name="currency"
								placeholder="Currency"
								_placeholder={{ color: "gray.500" }}
								// value={paymentInformation.currency}
								onChange={handleChange}
								isInvalid={error?.currency}
							>
								<option value="eur">EUR</option>
								<option value="ron">RON</option>
							</Select>
						</FormControl>
						<FormControl>
							<FormLabel display={"inline"}>Rent payment interval:</FormLabel>
							<Input
								onChange={handleChange}
								type="number"
								mr="10px"
								name="firstRentDay"
								placeholder="First Day"
								_placeholder={{ color: "gray.500" }}
								width="150px"
								isInvalid={error?.firstRentDay}
							/>
							<Input
								width="150px"
								onChange={handleChange}
								isInvalid={error?.lastRentDay}
								type="number"
								name="lastRentDay"
								placeholder="Last Day"
								_placeholder={{ color: "gray.500" }}
							/>
						</FormControl>
						<FormControl display={"flex"} alignItems="center">
							<FormLabel display={"inline"}>Rent exchange rate:</FormLabel>
							<RadioGroup
								name="exchangeRateType"
								value={documentInformation.exchangeRateType}
								onChange={(e) => {
									console.log(e);
									if (e === "fixedExchangeRate") setDocumentInformation({ ...documentInformation, exchangeRateType: e, fixedExchangeRate: 4.9 });
									else {
										const tempRent = { ...documentInformation };
										delete tempRent.fixedExchangeRate;
										setDocumentInformation({ ...tempRent, exchangeRateType: e });
									}
								}}
							>
								<Stack>
									<Radio value="bnr">BNR din ziua platii</Radio>
									<Radio value="bnr2">BNR+2% din ziua platii</Radio>
									<Radio value="fixedExchangeRate">Curs fix</Radio>
								</Stack>
							</RadioGroup>
						</FormControl>
						<FormControl display={"flex"} alignItems="center" hidden={documentInformation.exchangeRateType !== "fixedExchangeRate"}>
							<FormLabel display={"inline"}>Fixed Exchange rate:</FormLabel>
							<Input
								isDisabled={documentInformation.exchangeRateType !== "fixedExchangeRate"}
								maxW={24}
								width={"fit-content"}
								type="number"
								name="fixedExchangeRate"
								onChange={handleChange}
								isInvalid={error?.fixedExchangeRate}
								value={documentInformation.fixedExchangeRate}
								placeholder="Exchange Rate"
								_placeholder={{ color: "gray.500" }}
							/>
						</FormControl>
						<FormControl display={"flex"} alignItems="center">
							<FormLabel whiteSpace={"nowrap"}>Fixed administration fee (optional):</FormLabel>
							<InputGroup>
								<Input
									maxW={24}
									isInvalid={error?.fixedAdministrationFeeAmount}
									name="fixedAdministrationFeeAmount"
									placeholder="Amount"
									onChange={handleChange}
									_placeholder={{ color: "gray.500" }}
									type="number"
								/>
								<Select maxW={24} name="fixedAdministrationFeeCurrency" onChange={handleChange}>
									<option value="">Currency</option>
									<option value="eur">EUR</option>
									<option value="ron">RON</option>
								</Select>
							</InputGroup>
						</FormControl>
						<FormControl>
							<FormLabel>Lease:</FormLabel>
							<Dropzone
								onDrop={onDocumentDrop}
								accept={{
									"application/pdf": [".pdf"],
									"application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
									"application/msword": [".doc"],
								}}
								description="Upload PDF,DOC or DOCX Format"
								maxFiles={1}
							/>
						</FormControl>
					</Stack>
				</ModalBody>
				<ModalFooter>
					<Button
						colorScheme="red"
						onClick={() => {
							onClose();
							setError({});
							setDocumentInformation(initialDocumentInformation);
						}}
						mr={3}
					>
						{t("misc.cancel")}
					</Button>
					<Button colorScheme="blue" onClick={addDocument}>
						Add
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
	const renderAddReportModal = () => (
		<Modal isOpen={isReportOpen} onClose={onReportClose} size={"xl"}>
			<ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
			<ModalContent>
				<ModalHeader>
					<Text>Add Report</Text>
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Stack>
						<FormControl>
							<FormLabel display={"inline"}>Added Date:</FormLabel>
							<Input
								width={"fit-content"}
								isInvalid={error?.addedDate}
								onChange={handleAddReportChange}
								type="date"
								value={reportInformation.addedDate}
								name="addedDate"
							/>
						</FormControl>
						<FormControl>
							<FormLabel display={"inline"}>Document Number:</FormLabel>
							<Input
								width={"fit-content"}
								isInvalid={error?.documentNumber}
								onChange={handleAddReportChange}
								type="text"
								name="documentNumber"
								placeholder="Document Number"
								_placeholder={{ color: "gray.500" }}
							/>
						</FormControl>
						<FormControl>
							<FormLabel display={"inline"}>Description:</FormLabel>
							<Textarea
								width={"fit-content"}
								isInvalid={error?.description}
								onChange={handleAddReportChange}
								type="text"
								name="description"
								placeholder="Description"
								_placeholder={{ color: "gray.500" }}
							/>
						</FormControl>
						<FormControl display={"flex"} alignItems="center">
							<FormLabel display={"inline"}>Type:</FormLabel>
							<Select
								isInvalid={error?.documentType}
								width={"fit-content"}
								onChange={handleAddReportChange}
								type="number"
								name="documentType"
								placeholder="Document type"
								_placeholder={{ color: "gray.500" }}
							>
								{reportTypes.map((d, index) => (
									<option key={index} value={d.id}>
										{d.label}
									</option>
								))}
							</Select>
						</FormControl>
						<FormControl>
							<FormLabel>Report:</FormLabel>
							<Dropzone
								onDrop={onReportDrop}
								accept={{
									"application/pdf": [".pdf"],
									"application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
									"application/msword": [".doc"],
								}}
								description="Upload PDF,DOC or DOCX Format"
								maxFiles={1}
							/>
						</FormControl>
						<FormControl>
							<FormLabel>Media:</FormLabel>
							<Dropzone onDrop={onMediaDrop} accept={{ "image/*": [], "video/*": [] }} description="Upload an Image or a Video" />
						</FormControl>
					</Stack>
				</ModalBody>
				<ModalFooter>
					<Button colorScheme="red" onClick={onReportClose} mr={3}>
						{t("misc.cancel")}
					</Button>
					<Button colorScheme="blue" onClick={addReport}>
						Add
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
	return (
		<Stack>
			<Wrap>
				<WrapItem>
					<Box>
						<Tooltip
							label="Add billing information to the agency and owner"
							isDisabled={Object.keys(ownerBillingInformation).length !== 0 || Object.keys(agencyBillingInformation).length !== 0}
						>
							<Button
								onClick={onOpen}
								colorScheme="blue"
								isDisabled={Object.keys(ownerBillingInformation).length === 0 || Object.keys(agencyBillingInformation).length === 0 || isArchived}
							>
								{t("property.addLease")}
							</Button>
						</Tooltip>

						{renderAddDocumentModal()}
					</Box>
				</WrapItem>
				<WrapItem>
					<Box>
						<Button onClick={onAdditionalDocumentOpen} colorScheme="blue" isDisabled={isArchived}>
							{t("property.addAdditionalDocument")}
						</Button>
						{renderAddAdditionalDocumentModal()}
					</Box>
				</WrapItem>
				<WrapItem>
					<Box>
						<Tooltip label="Add Administration Contract to enable" isDisabled={getPropertyStatus(property) !== "inactive"}>
							<Button colorScheme="green" onClick={onOpenAddTenant} isDisabled={getPropertyStatus(property) === "inactive" || isArchived}>
								{t("property.addTenant")}
							</Button>
						</Tooltip>

						{renderAddTenantModal()}
					</Box>
				</WrapItem>
				<WrapItem>
					<Box>
						<Button colorScheme="blue" onClick={onOpenAddContact} isDisabled={isArchived}>
							{t("property.addContact")}
						</Button>
						{renderAddContactModal()}
					</Box>
				</WrapItem>
				<WrapItem>
					<Button onClick={onReportOpen} colorScheme="blue" width={"fit-content"} isDisabled={isArchived}>
						{t("property.addReport")}
					</Button>
					{renderAddReportModal()}
				</WrapItem>
			</Wrap>
			<Box>
				<Checkbox
					isDisabled={isArchived}
					isChecked={property.owner?.handlesOwnSanitationDeclaration}
					onChange={(e) => {
						console.log("handles own sanitation declaration:", e.target.checked);
						dispatch(updateOwnerAsync({ type: "handlesOwn", handlesOwnSanitationDeclaration: e.target.checked, ownerEmail: property.owner.email }));
					}}
				>
					{t("property.ownerHandlesSanitationDeclaration")}
				</Checkbox>
			</Box>
			<Wrap>
				{property.tenants
					.filter((t) => !t.former)
					.map((c, index) => (
						<WrapItem key={index}>
							<TenantPopover contact={c} propertyId={property.id} handleFormer={updateTenantStatus} />
						</WrapItem>
					))}

				{property.leaseContacts
					?.filter((c) => !c.former)
					.map((c, index) => (
						<WrapItem key={index}>
							<ContactPopover contact={c} property={property} />
						</WrapItem>
					))}
			</Wrap>
			<Divider />
			<Box hidden={!formerTenants.length}>
				<Text fontWeight={"bold"}>{t("property.formerTenants")}:</Text>
				<Wrap>
					{formerTenants.map((c, index) => (
						<WrapItem key={index}>
							<TenantPopover contact={c} propertyId={property.id} former={true} handleFormer={updateTenantStatus} />
						</WrapItem>
					))}
				</Wrap>
			</Box>
			<Box hidden={!formerContacts.length}>
				<Text fontWeight={"bold"}>{t("property.formerContacts")}:</Text>
				<Wrap>
					{formerContacts.map((c, index) => (
						<WrapItem key={index}>
							<ContactPopover contact={c} propertyId={property.id} />
						</WrapItem>
					))}
				</Wrap>
			</Box>
			<Divider />
			<Box overflowX="auto">
				<Text fontWeight={"bold"} fontSize={"large"} margin={"20px 0px 20px 0px"}>
					{t("property.reports")}
				</Text>
				<Table variant="simple" size="sm">
					<Thead>
						<Tr my=".8rem" pl="0px">
							<Th pl="0px">{t("table.addedDate")}</Th>
							<Th>{t("table.name")}</Th>
							<Th>{t("table.type")}</Th>
							<Th>{t("table.description")}</Th>
							<Th>{t("table.number")}</Th>
							<Th></Th>
							<Th></Th>
						</Tr>
					</Thead>
					<Tbody>
						{reports.length !== 0 ? (
							reports.map((row, index) => {
								return <ReportsTableRow key={index} row={row} propertyId={property.id} />;
							})
						) : (
							<Tr>
								<Td>{t("table.noData")}</Td>
							</Tr>
						)}
					</Tbody>
				</Table>
			</Box>
			<Box overflowX="auto">
				<Text fontWeight={"bold"} fontSize={"large"} margin={"20px 0px 20px 0px"}>
					{t("property.lease")}
				</Text>

				<Table variant="simple" size="sm">
					<Thead>
						<Tr my=".8rem" pl="0px" whiteSpace={"nowrap"}>
							<Th pl="0px">{t("table.addedDate")}</Th>
							<Th>{t("table.name")}</Th>
							<Th>{t("table.type")}</Th>
							<Th>{t("table.number")}</Th>
							<Th>{t("table.signatureDate")}</Th>
							<Th>{t("table.startingDate")}</Th>
							<Th>{t("table.expirationDate")}</Th>
							<Th>{t("table.description")}</Th>
							<Th>{t("table.actions")}</Th>
						</Tr>
					</Thead>
					<Tbody>
						{documents.length !== 0 ? (
							<>
								{documents
									.filter((d) => d.active)
									.map((row, index) => {
										return <LeaseTableRow key={index} row={row} propertyId={property.id} payments={property.payments} />;
									})}
								{documents
									.filter((d) => !d.active)
									.map((row, index) => {
										return <LeaseTableRow key={index} row={row} propertyId={property.id} payments={property.payments} />;
									})}
							</>
						) : (
							<Tr>
								<Td>{t("table.noData")}</Td>
							</Tr>
						)}
					</Tbody>
				</Table>
			</Box>
		</Stack>
	);
}
