import React from "react";
import {
	Button,
	AlertDialog,
	AlertDialogOverlay,
	AlertDialogContent,
	AlertDialogHeader,
	AlertDialogBody,
	AlertDialogFooter,
	Text,
	Stack,
	Checkbox,
	Link,
} from "@chakra-ui/react";
import { config } from "../../utils/config";
import { ExternalLinkIcon } from "@chakra-ui/icons";

const WithConfirmationPayment = ({ children, onConfirm, ...props }) => {
	const [isOpen, setIsOpen] = React.useState(false);
	const onClose = () => setIsOpen(false);
	const cancelRef = React.useRef();
	const [loading, setLoading] = React.useState(false);
	const [checked, setChecked] = React.useState({
		termsAndConditions: false,
		processingFee: false,
	});
	const handleClick = () => {
		setIsOpen(true);
	};

	const handleConfirm = async () => {
		setLoading(true);
		await onConfirm();
		setLoading(false);
		onClose();
	};

	return (
		<>
			{React.cloneElement(children, { onClick: handleClick })}
			<AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose} size={"xl"}>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize="lg" fontWeight="bold">
							Confirmare
						</AlertDialogHeader>

						<AlertDialogBody>
							<Stack>
								<Text>Pentru a plăti online, trebuie să citești și să accepți termenii și condițiile.</Text>
								<Checkbox
									colorScheme="red"
									onChange={(e) => {
										setChecked({
											...checked,
											processingFee: e.target.checked,
										});
									}}
								>
									<Link href={`${config.appUrl}/terms-and-conditions`} isExternal>
										<Stack direction="row" alignItems={"center"} color={"teal"}>
											<Text>Termeni si conditii</Text>
											<ExternalLinkIcon mx="2px" />
										</Stack>
									</Link>
								</Checkbox>
								<Stack spacing={5} direction="column">
									<Checkbox
										colorScheme="red"
										onChange={(e) => {
											setChecked({
												...checked,
												termsAndConditions: e.target.checked,
											});
										}}
									>
										<Text>Sunt de acord sa platesc 3.25% din valoarea facturii pentru costul de procesare a platilor online.</Text>
									</Checkbox>
								</Stack>
							</Stack>
						</AlertDialogBody>

						<AlertDialogFooter>
							<Button ref={cancelRef} onClick={onClose} isLoading={loading}>
								Anulează
							</Button>
							<Button colorScheme="red" onClick={handleConfirm} ml={3} isDisabled={Object.values(checked).some((c) => c == false)} isLoading={loading}>
								Confirmă
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
		</>
	);
};

export default WithConfirmationPayment;
