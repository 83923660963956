import { Flex, Table, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Card, CardHeader, CardBody } from "@chakra-ui/react";
import TicketsTableRow from "../../Components/TableRows/TicketsTableRow";
import { getPropertyAddress } from "../../utils/verifier";
import { selectActiveProperties } from "../../redux/user/userSlice";
import { useTranslation } from "react-i18next";

export default function Tickets() {
	const textColor = useColorModeValue("gray.700", "white");
	const [tickets, setTickets] = useState();
	const properties = useSelector(selectActiveProperties);
	const name = useSelector((state) => state.user.user?.name);
	const email = useSelector((state) => state.user.user?.email);
	const phoneNumber = useSelector((state) => state.user.user?.phoneNumber);
	const { t } = useTranslation();
	useEffect(() => {
		if (!properties) return;
		const t = properties
			.map((p) =>
				p.tickets.map((t) => {
					return { ...t, address: getPropertyAddress(p), propertyId: p.id, owner: p.owner };
				})
			)
			.flat();
		console.log(t);
		// sort the tickets based on status and if the status is the same, sort them based on the date they were added
		t.sort((a, b) => {
			if (a.status === b.status) {
				return new Date(b.addedDate) - new Date(a.addedDate);
			}
			// if the status is not the same, sort them based on the status. the status can be new, pending, resolved
			if (a.status === "new") return -1;
			if (b.status === "new") return 1;
			if (a.status === "pending") return -1;
			if (b.status === "pending") return 1;
			if (a.status === "resolved") return -1;
			if (b.status === "resolved") return 1;
			return 0;
		});
		setTickets(t);
	}, [properties]);
	return (
		<Flex direction={"column"}>
			<Card overflowX={{ sm: "scroll", xl: "hidden" }}>
				<CardHeader>
					<Text fontSize="xl" color={textColor} fontWeight="bold">
						{t("tickets.tickets")}
					</Text>
				</CardHeader>
				<CardBody>
					<Table variant="simple" color={textColor} size="sm">
						<Thead>
							<Tr my=".8rem" pl="0px">
								<Th>{t("table.addedDate")}</Th>
								<Th>{t("table.name")}</Th>
								<Th pl="0px">{t("table.address")}</Th>
								<Th>{t("table.status")}</Th>
								<Th>{t("table.title")}</Th>
								{/* <Th  >Contract</Th> */}
								<Th>{t("table.author")}</Th>
								<Th></Th>
							</Tr>
						</Thead>
						<Tbody>
							{tickets?.map((t, index) => (
								<TicketsTableRow key={index} row={t} author={{ name: name, phoneNumber, email }} owner={t.owner} />
							))}
							{tickets?.length === 0 && (
								<Tr>
									<Td>{t("table.noData")}</Td>
								</Tr>
							)}
						</Tbody>
					</Table>
				</CardBody>
			</Card>
		</Flex>
	);
}
