export const paymentTypes = [
	{ id: "tax", label: "Impozit" },
	{ id: "centralHeatingVerification", label: "Verificare centrala" },
	{ id: "insurance", label: "Asigurare" },
	{ id: "rent", label: "Chirie" },
	{ id: "other", label: "Altele" },
];
export const documentTypes = [
	{ id: "administrationContract", label: "Contract de administrare" },
	{ id: "gasContract", label: "Contract de gaz" },
	{ id: "electricityContract", label: "Contract de curent" },
	{ id: "waterContract", label: "Contract de apa" },
	{ id: "insurance", label: "Asigurare Obligatorie" },
	{ id: "optionalInsurance", label: "Asigurare Facultativa" },
	{ id: "centralHeatingVerification", label: "Verificare centrala" },
	{ id: "gasVerification", label: "Verificare gaz" },
	{ id: "gasRevision", label: "Revizie Gaz" },
	{ id: "lastGasInvoice", label: "Copie factura gaz" },
	{ id: "lastElectricityInvoice", label: "Copie factura curent" },
	{ id: "lastWaterInvoice", label: "Copie factura apa" },
	{ id: "tenantAssociationCertificate", label: "Adeverinta asociatie locatari" },
	{ id: "aquisitionAct", label: "Act de dobandire" },
	{ id: "other", label: "Altele" },
];
export const reportTypes = [
	{ id: "entryReport", label: "Entry Report" },
	{ id: "exitReport", label: "Exit Report" },
	{ id: "observationReport", label: "Observation Report" },
];
export const contactTypes = [
	{ id: "formerTenant", label: "Fost chirias" },
	{ id: "ownerSpouse", label: "Partener proprietar" },
	{ id: "buildingSuper", label: "Administrator bloc" },
	{ id: "headOfTenantAssociation", label: "Sef asociatie de locatari" },
];
export const accountTypes = [
	{ id: "cityHall", label: "Primarie" },
	{ id: "water", label: "Apa" },
	{ id: "gas", label: "Gaz" },
	{ id: "electricity", label: "Curent" },
	{ id: "internet", label: "Internet" },
];
export const fullDateFormat = "DD MMMM YYYY";
export const fullDateTimeFormat = "DD MMMM YYYY HH:mm";
