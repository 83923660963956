import React, { useState } from "react";
import InvoicesRow from "../TableRows/InvoicesRow";
import {
	Box,
	Button,
	Table,
	Tbody,
	Text,
	Th,
	Thead,
	Tr,
	Divider,
	Input,
	Stack,
	FormControl,
	FormLabel,
	Select,
	useDisclosure,
	Modal,
	ModalOverlay,
	ModalHeader,
	ModalContent,
	ModalBody,
	ModalFooter,
	Checkbox,
	Collapse,
	Textarea,
	useToast,
	Tooltip,
	Spinner,
	Wrap,
	WrapItem,
	Td,
} from "@chakra-ui/react";
import Dropzone from "../Dropzone/Dropzone";
import { useDispatch, useSelector } from "react-redux";
import { addAdministrationFeePaymentAsync, addPaymentAsync, addRentPaymentAsync, updatePropertyCustomOwnerPlanAsync } from "../../redux/user/userSlice";
import { addAdmFeePaymentVerifier, addPaymentVerifier, getPropertyAddress, getPropertyStatus } from "../../utils/verifier";
import moment from "moment";
import SearchBox from "../../Pages/Properties/SearchBox";
import { useTranslation } from "react-i18next";
function _arrayBufferToBase64(buffer) {
	var binary = "";
	var bytes = new Uint8Array(buffer);
	var len = bytes.byteLength;
	for (var i = 0; i < len; i++) {
		binary += String.fromCharCode(bytes[i]);
	}
	return window.btoa(binary);
}
const paymentTypes = [
	{ id: "tax", label: "Impozit" },
	{ id: "centralHeatingVerification", label: "Verificare centrala" },
	{ id: "insurance", label: "Asigurare" },
	// { id: "rent", label: "Chirie" },
	{ id: "other", label: "Altele" },
];
export default function PaymentsTab({ property }) {
	const { t } = useTranslation();
	const isArchived = property.archived;
	const initialPaymentInformation = {
		paymentDate: moment().format("yyyy-MM-DD"),
		paymentDirection: "incoming",
		paymentMethod: "op",
		paymentNumber: "",
		paymentType: "",
		propertyId: property.id,
		amount: "",
		currency: "",
		documents: [],
		billedTo: {},
		owner: property.owner.name,
		equivalentAmount: "",
		equivalentCurrency: "",
		description: "",
	};
	const [query, setquery] = useState("");
	const handleSearch = (query) => {
		setquery(query);
	};
	const payments = property.payments
		.filter((payment) => !payment.type.toLowerCase().includes("utility"))
		.filter((payment) => {
			return (
				payment.type?.toLowerCase().includes(query.toLowerCase()) ||
				payment.amount?.toLowerCase().includes(query.toLowerCase()) ||
				payment.paymentMethod?.toLowerCase().includes(query.toLowerCase())
			);
		});

	const dispatch = useDispatch();
	const ownerBillingInformation = property.owner.billingInformation;
	const user = useSelector((state) => state.user.user);
	const loading = useSelector((state) => state.user.status) === "loading";
	const agencyBillingInformation = user.billingInformation;
	const customOwnerPlans = user.customOwnerPlans || [];
	const [currentCustomOwnerPlans, setCurrentCustomOwnerPlans] = useState(property.customOwnerPlans || []);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { isOpen: isAddRentOpen, onOpen: onAddRentOpen, onClose: onAddRentClose } = useDisclosure();
	const { isOpen: isAddAdmFeeOpen, onOpen: onAddAdmFeeOpen, onClose: onAddAdmFeeClose } = useDisclosure();
	const { isOpen: isEditOwnerPlanListOpen, onOpen: onEditOwnerPlanListOpen, onClose: onEditOwnerPlanListClose } = useDisclosure();
	const [paymentInformation, setPaymentInformation] = useState(initialPaymentInformation);
	const { isOpen: isCustomAdmFeeAmountOpen, onToggle: onCustomAdmFeeAmountToggle, onClose: onCustomAdmFeeAmountClose } = useDisclosure();
	const { isOpen: isCustomRentAmountOpen, onToggle: onCustomRentAmountToggle, onClose: onCustomRentAmountClose } = useDisclosure();
	const [error, setError] = useState({});
	const toast = useToast();
	const onDocumentDrop = async (acceptedFiles) => {
		console.log(acceptedFiles);
		let documents = [];
		for (const file of acceptedFiles) {
			const buffer = await file.arrayBuffer();
			const documentName = file.name;
			const contentType = file.type;
			documents.push({ documentData: _arrayBufferToBase64(buffer), documentName, contentType });
		}
		setPaymentInformation({ ...paymentInformation, documents });
	};
	const initialAdmFeeInfo = { amount: 0, currency: "", alreadyPaid: true, customOwnerPlans: currentCustomOwnerPlans };
	const [admFeeInfo, setAdmFeeInfo] = useState(initialAdmFeeInfo);
	const initialRentInfo = { amount: 0, currency: "" };
	const [rentInfo, setRentInfo] = useState(initialRentInfo);
	const handleSaveEditOwnerPlanList = () => {
		const payload = {
			propertyId: property.id,
			customOwnerPlans: currentCustomOwnerPlans,
		};
		console.log(payload);
		dispatch(updatePropertyCustomOwnerPlanAsync(payload))
			.then((r) => {
				if (r.error?.message) {
					toast({
						title: "Error encountered",
						description: r.error.message,
						status: "error",
						duration: 9000,
						isClosable: true,
					});
				} else {
					toast({
						title: "Success!",
						description: "Saved!",
						status: "success",
						duration: 2000,
						isClosable: true,
					});
				}
			})
			.finally(() => {
				onEditOwnerPlanListClose();
			});
	};
	const addRentPayment = () => {
		const errors = addAdmFeePaymentVerifier(rentInfo);
		setError(errors);
		console.log(errors);

		const payload = { ...rentInfo, propertyId: property.id };
		//if there are errors show a toast and return
		if (Object.entries(errors).length !== 0) {
			toast({
				title: "Error encountered.",
				description: "Invalid information",
				status: "error",
				duration: 2000,
				isClosable: true,
			});
			return;
		}
		console.log(payload);
		dispatch(addRentPaymentAsync(payload))
			.then((r) => {
				if (r.error?.message)
					toast({
						title: "Error encountered",
						description: r.error.message,
						status: "error",
						duration: 9000,
						isClosable: true,
					});
				else {
					toast({
						title: "Success!",
						description: "Payment added!",
						status: "success",
						duration: 2000,
						isClosable: true,
					});
				}
			})
			.finally(() => {
				onAddRentClose();
				if (isCustomRentAmountOpen) {
					setRentInfo(initialRentInfo);
				}
				onCustomRentAmountClose();
			});
	};
	const addAdmFeePayment = async () => {
		const errors = addAdmFeePaymentVerifier(admFeeInfo);
		setError(errors);
		console.log(errors);
		if (Object.entries(errors).length !== 0) {
			toast({
				title: "Error encountered.",
				description: "Invalid information",
				status: "error",
				duration: 2000,
				isClosable: true,
			});
			return;
		}
		const payload = { ...admFeeInfo, propertyId: property.id };
		console.log(payload);
		dispatch(addAdministrationFeePaymentAsync(payload))
			.then((r) => {
				if (r.error?.message)
					toast({
						title: "Error encountered",
						description: r.error.message,
						status: "error",
						duration: 9000,
						isClosable: true,
					});
				else {
					toast({
						title: "Success!",
						description: "Payment added!",
						status: "success",
						duration: 2000,
						isClosable: true,
					});
				}
			})
			.finally(() => {
				onAddAdmFeeClose();
				if (isCustomAdmFeeAmountOpen) {
					setAdmFeeInfo(initialAdmFeeInfo);
				}
				onCustomAdmFeeAmountClose();
			});
	};
	const addPayment = () => {
		const errors = addPaymentVerifier(paymentInformation);
		const admCtr = property.documents.find((d) => d.type === "administrationContract" && d.active);
		const leaseCtr = property.documents.find((d) => d.type === "lease" && d.active) || {};
		const payload = {
			...paymentInformation,
			administrationContractDate: admCtr.startingDate,
			administrationContractNumber: admCtr.number,
			leaseNumber: leaseCtr.number,
			leaseDate: leaseCtr.startingDate,
		};
		console.log(payload);
		if (Object.entries(errors).length !== 0) {
			console.log(errors);
			setError(errors);
			toast({
				title: "Error encountered.",
				description: "Invalid information",
				status: "error",
				duration: 2000,
				isClosable: true,
			});
			return;
		}
		setError({});
		dispatch(addPaymentAsync(payload))
			.then((r) => {
				if (r.error?.message)
					toast({
						title: "Error encountered",
						description: r.error.message,
						status: "error",
						duration: 9000,
						isClosable: true,
					});
				else {
					toast({
						title: "Success!",
						description: "Payment added!",
						status: "success",
						duration: 2000,
						isClosable: true,
					});
				}
			})
			.finally(() => {
				onClose();
				setPaymentInformation(initialPaymentInformation);
			});
	};
	const handleChange = (e) => {
		setPaymentInformation({ ...paymentInformation, [e.target.name]: e.target.value });
	};
	const handleAdmFeeChange = (e) => {
		setAdmFeeInfo({ ...admFeeInfo, [e.target.name]: e.target.value });
	};
	const handleRentChange = (e) => {
		setRentInfo({ ...rentInfo, [e.target.name]: e.target.value });
	};
	const handleBilledToChange = (e) => {
		const billedToEmail = e.target.value;
		console.log("email: ", billedToEmail);

		if (property.owner.email === billedToEmail) {
			setPaymentInformation({ ...paymentInformation, billedTo: { name: property.owner.name, email: billedToEmail, type: "owner" } });
			return;
		}
		if (property.contacts.find((c) => c.email === billedToEmail)) {
			const contact = property.contacts.find((c) => c.email === billedToEmail);
			console.log(contact);
			setPaymentInformation({ ...paymentInformation, billedTo: { name: contact.name, email: billedToEmail, type: contact.contactType } });
			return;
		}
		if (property.leaseContacts.find((c) => c.id === billedToEmail)) {
			const contact = property.leaseContacts.find((c) => c.id === billedToEmail);
			console.log(contact);
			setPaymentInformation({ ...paymentInformation, billedTo: { name: contact.name, email: billedToEmail, type: contact.contactType } });
			return;
		}
		if (property.tenants.find((c) => c.email === billedToEmail)) {
			const tenant = property.tenants.find((c) => c.email === billedToEmail);
			console.log(tenant);
			setPaymentInformation({ ...paymentInformation, billedTo: { name: tenant.name, email: billedToEmail, type: "tenant" } });
			return;
		}
	};

	const renderAddPaymentModal = () => (
		<Modal isOpen={isOpen} onClose={onClose} size={"xl"}>
			<ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
			<ModalContent>
				<ModalHeader>
					<Text>{t("property.addPayment")}</Text>
				</ModalHeader>
				<ModalBody>
					<Stack>
						<FormControl>
							<FormLabel display={"inline"}>{t("property.paymentDate")}</FormLabel>
							<Input
								width={"fit-content"}
								type="date"
								name="paymentDate"
								placeholder="Payment Date"
								_placeholder={{ color: "gray.500" }}
								value={paymentInformation.paymentDate}
								onChange={handleChange}
							/>
						</FormControl>
						<FormControl display={"flex"} alignItems="center">
							<FormLabel display={"inline"}>{t("property.paymentDirection")}:</FormLabel>
							<Select
								width={"fit-content"}
								onChange={handleChange}
								isInvalid={error?.paymentDirection}
								type="text"
								name="paymentDirection"
								_placeholder={{ color: "gray.500" }}
								value={paymentInformation.paymentDirection}
							>
								<option value="incoming">Incasare</option>
								<option value="outgoing">Plata</option>
							</Select>
						</FormControl>
						<FormControl>
							<FormLabel display={"inline"}>{t("property.documentNumber")}:</FormLabel>
							<Input
								width={"fit-content"}
								onChange={handleChange}
								isInvalid={error?.paymentNumber}
								type="text"
								name="paymentNumber"
								placeholder={t("property.documentNumber")}
								_placeholder={{ color: "gray.500" }}
							/>
						</FormControl>

						<FormControl display={"flex"} alignItems="center">
							<FormLabel display={"inline"}>{t("property.method")}:</FormLabel>
							<Select
								width={"fit-content"}
								isInvalid={error?.paymentMethod}
								onChange={handleChange}
								type="text"
								name="paymentMethod"
								_placeholder={{ color: "gray.500" }}
							>
								<option value="op">OP</option>
								<option value="cash">Cash</option>
							</Select>
						</FormControl>
						<FormControl>
							<FormLabel display={"inline"}>{t("property.amount")}:</FormLabel>
							<Input
								isInvalid={error?.amount}
								width={"fit-content"}
								type="number"
								name="amount"
								placeholder={t("property.amount")}
								_placeholder={{ color: "gray.500" }}
								value={paymentInformation.amount}
								onChange={handleChange}
							/>
						</FormControl>
						<FormControl display={"flex"} alignItems="center">
							<FormLabel display={"inline"}>{t("property.currency")}:</FormLabel>
							<Select
								width={"fit-content"}
								type="number"
								name="currency"
								isInvalid={error?.currency}
								_placeholder={{ color: "gray.500" }}
								value={paymentInformation.currency}
								onChange={handleChange}
							>
								<option value="eur">EUR</option>
								<option value="ron">RON</option>
							</Select>
						</FormControl>

						<FormControl display={"flex"} alignItems="center">
							<FormLabel display={"inline"}>{t("property.type")}:</FormLabel>
							<Select
								width={"fit-content"}
								onChange={handleChange}
								type="number"
								isInvalid={error?.paymentType}
								name="paymentType"
								placeholder={t("property.type")}
								_placeholder={{ color: "gray.500" }}
							>
								{paymentTypes.map((d, index) => (
									<option key={index} value={d.id}>
										{d.label}
									</option>
								))}
							</Select>
						</FormControl>
						<FormControl display={"flex"} alignItems="center">
							<FormLabel>{t("property.billedTo")}:</FormLabel>
							<Select
								width={"fit-content"}
								onChange={handleBilledToChange}
								type="number"
								isInvalid={error?.billedTo}
								name="billedTo"
								placeholder="Billed to"
								_placeholder={{ color: "gray.500" }}
							>
								<option value={property.owner.email}>{property.owner.name}</option>
								{property.tenants?.map((c, index) => (
									<option key={index} value={c.email}>
										{c.name}
									</option>
								))}
								{property.contacts?.map((c, index) => (
									<option key={index} value={c.email}>
										{c.name}
									</option>
								))}
								{property.leaseContacts?.map((c, index) => (
									<option key={index} value={c.id}>
										{c.name}
									</option>
								))}
							</Select>
						</FormControl>
						<FormControl>
							<FormLabel display={"inline"}>{t("property.billedToName")}:</FormLabel>
							<Input
								isInvalid={error?.billedToName}
								width={"fit-content"}
								type="text"
								name="name"
								placeholder={t("property.billedToName")}
								_placeholder={{ color: "gray.500" }}
								value={paymentInformation.billedTo.name}
								onChange={(e) => {
									setPaymentInformation({ ...paymentInformation, billedTo: { ...paymentInformation.billedTo, name: e.target.value } });
								}}
							/>
						</FormControl>
						<FormControl>
							<FormLabel display={"inline"}>{t("property.billedToType")}:</FormLabel>
							<Input
								isInvalid={error?.billedToType}
								width={"fit-content"}
								type="text"
								name="type"
								placeholder={t("property.billedToType")}
								_placeholder={{ color: "gray.500" }}
								value={paymentInformation.billedTo.type}
								onChange={(e) => {
									setPaymentInformation({ ...paymentInformation, billedTo: { ...paymentInformation.billedTo, type: e.target.value } });
								}}
							/>
						</FormControl>
						<FormControl>
							<FormLabel display={"inline"}>{t("property.description")}:</FormLabel>
							<Textarea
								width={"fit-content"}
								type="text"
								name="description"
								placeholder={t("property.description")}
								isInvalid={error?.description}
								_placeholder={{ color: "gray.500" }}
								value={paymentInformation.description}
								onChange={handleChange}
							/>
						</FormControl>
						<FormControl>
							<FormLabel>{t("property.documents")}:</FormLabel>
							<Dropzone
								onDrop={onDocumentDrop}
								accept={{
									"application/pdf": [".pdf"],
									"application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
									"application/msword": [".doc"],
								}}
								description="Upload PDF,DOC or DOCX Format"
							/>
						</FormControl>
					</Stack>
				</ModalBody>
				<ModalFooter>
					<Button
						isDisabled={loading}
						colorScheme="red"
						onClick={() => {
							onClose();
							setPaymentInformation(initialPaymentInformation);
							onCustomAdmFeeAmountClose();
							setError({});
						}}
						mr={3}
					>
						{t("misc.cancel")}
					</Button>
					<Button colorScheme="blue" onClick={addPayment} isLoading={loading}>
						{t("misc.save")}
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
	const renderAddRentModal = () => (
		<Modal isOpen={isAddRentOpen} onClose={onAddRentClose} size={"xl"}>
			<ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
			<ModalContent>
				<ModalHeader>
					<Text>Add a Rent Payment</Text>
				</ModalHeader>
				<ModalBody>
					<Text>By issuing a rent payment manually, another one will not be issued this month automatically by the system.</Text>
					<Checkbox
						isChecked={isCustomRentAmountOpen}
						onChange={(e) => {
							if (isCustomRentAmountOpen) {
								setRentInfo(initialRentInfo);
							}
							onCustomRentAmountToggle();
						}}
					>
						Custom Amount?
					</Checkbox>
					<Collapse in={isCustomRentAmountOpen} animateOpacity>
						<Stack>
							<Text>Enter a custom amount: </Text>
							<FormControl>
								<FormLabel display={"inline"}>Amount:</FormLabel>
								<Input
									isInvalid={error?.amount}
									width={"fit-content"}
									type="number"
									name="amount"
									placeholder="Amount"
									_placeholder={{ color: "gray.500" }}
									value={rentInfo?.amount}
									onChange={handleRentChange}
								/>
							</FormControl>
							<FormControl display={"flex"} alignItems="center">
								<FormLabel display={"inline"}>Currency:</FormLabel>
								<Select
									width={"fit-content"}
									type="number"
									name="currency"
									placeholder="Currency"
									isInvalid={error?.currency}
									_placeholder={{ color: "gray.500" }}
									value={rentInfo?.currency}
									onChange={handleRentChange}
								>
									<option value="eur">EUR</option>
									<option value="ron">RON</option>
								</Select>
							</FormControl>
						</Stack>
					</Collapse>
				</ModalBody>
				<ModalFooter>
					<Button
						isDisabled={loading}
						colorScheme="red"
						onClick={() => {
							onAddRentClose();
							if (isCustomRentAmountOpen) {
								setRentInfo(initialRentInfo);
							}
							onCustomRentAmountClose();
						}}
						mr={3}
					>
						{t("misc.cancel")}
					</Button>
					<Button isDisabled={loading} colorScheme="blue" onClick={addRentPayment}>
						Confirm {loading && <Spinner />}
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
	const renderAddAdmFeeModal = () => (
		<Modal isOpen={isAddAdmFeeOpen} onClose={onAddAdmFeeClose} size={"xl"}>
			<ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
			<ModalContent>
				<ModalHeader>
					<Text>Adaugati factura de comision de administrare</Text>
				</ModalHeader>
				<ModalBody>
					<Text>
						Adaugati factura pentru comisionul de administrare standard, sau avand o valoare personalizata. Puteti include sau exclude pachete de servicii prin
						bifarea casutelor aferente
					</Text>
					<br />
					<Stack>
						<Checkbox
							hidden={true}
							onChange={(e) => {
								setAdmFeeInfo({ ...admFeeInfo, alreadyPaid: e.target.checked });
							}}
							isChecked={admFeeInfo.alreadyPaid}
						>
							Comision deja achitat?
						</Checkbox>

						<Box>
							<Checkbox
								onChange={(e) => {
									if (isCustomAdmFeeAmountOpen) {
										setAdmFeeInfo({ ...admFeeInfo, amount: 0, currency: "" });
									}
									onCustomAdmFeeAmountToggle();
								}}
							>
								Valoare personalizata?
							</Checkbox>

							<Collapse in={isCustomAdmFeeAmountOpen} animateOpacity>
								<br />

								<Stack>
									<Text>Introduceti valoarea: </Text>
									<FormControl>
										<FormLabel display={"inline"}>Amount:</FormLabel>
										<Input
											isInvalid={error?.amount}
											width={"fit-content"}
											type="number"
											name="amount"
											placeholder="Amount"
											_placeholder={{ color: "gray.500" }}
											value={admFeeInfo?.amount}
											onChange={handleAdmFeeChange}
										/>
									</FormControl>
									<FormControl display={"flex"} alignItems="center">
										<FormLabel display={"inline"}>Currency:</FormLabel>
										<Select
											width={"fit-content"}
											type="number"
											name="currency"
											placeholder="Currency"
											isInvalid={error?.currency}
											_placeholder={{ color: "gray.500" }}
											value={admFeeInfo?.currency}
											onChange={handleAdmFeeChange}
										>
											<option value="eur">EUR</option>
											<option value="ron">RON</option>
										</Select>
									</FormControl>
								</Stack>
							</Collapse>
						</Box>
						<Box hidden={currentCustomOwnerPlans.length === 0 || true}>
							<Divider />
							<Text>Selectati ce servicii doriti sa includeti in aceasta plata:</Text>
							<Table size="sm">
								<Thead>
									<Tr>
										<Th></Th>
										<Th>{t("table.name")}</Th>
										<Th>{t("table.description")}</Th>
										<Th>{t("table.price")}</Th>
									</Tr>
								</Thead>
								<Tbody>
									{currentCustomOwnerPlans.map((p, index) => {
										const plan = customOwnerPlans.find((pl) => pl.id === p);
										console.log("===================");
										console.log(plan);
										console.log(customOwnerPlans);
										console.log(currentCustomOwnerPlans);
										if (!plan) return null;
										return (
											<Tr key={index}>
												<Td>
													<Checkbox
														isChecked={admFeeInfo.customOwnerPlans?.some((p) => p === plan?.id)}
														onChange={(e) => {
															if (e.target.checked) {
																setAdmFeeInfo({ ...admFeeInfo, customOwnerPlans: admFeeInfo.customOwnerPlans.concat(plan.id) });
															} else {
																setAdmFeeInfo({ ...admFeeInfo, customOwnerPlans: admFeeInfo.customOwnerPlans.filter((p) => p !== plan.id) });
															}
														}}
													></Checkbox>
												</Td>
												<Td whiteSpace={"nowrap"}>{plan.name}</Td>
												<Td>{plan.description}</Td>
												<Td>
													{plan.price.amount} {plan.price.currency}
												</Td>
											</Tr>
										);
									})}
								</Tbody>
							</Table>
						</Box>
					</Stack>
				</ModalBody>
				<ModalFooter>
					<Button
						isDisabled={loading}
						colorScheme="red"
						onClick={() => {
							onAddAdmFeeClose();
							if (isCustomAdmFeeAmountOpen) {
								onCustomAdmFeeAmountToggle();
							}
						}}
						mr={3}
					>
						{t("misc.cancel")}
					</Button>
					<Button isLoading={loading} colorScheme="blue" onClick={addAdmFeePayment}>
						Confirm
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
	const renderEditCustomOwnerPlanListModal = () => {
		// return a list of custom owner plans that have a checkbox
		// when the user clicks save, the selected plans will be added to the owner plan list

		return (
			<Modal isOpen={isEditOwnerPlanListOpen} onClose={onEditOwnerPlanListClose} size={"xl"}>
				<ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
				<ModalContent>
					<ModalHeader>
						<Text>Editeaza pachetul de servicii lunare</Text>
					</ModalHeader>
					<ModalBody>
						<Text>
							Selectati pachetul de servicii dorite pentru aceasta proprietate. Acestea se vor adauga automat la valoarea comisionului de administrare lunar.
						</Text>
						<br />
						<Text>
							Total estimat:{" "}
							{customOwnerPlans.reduce((acc, plan) => {
								if (currentCustomOwnerPlans.includes(plan.id) && plan.price.currency === "ron") {
									return acc + Number(plan.price.amount);
								}
								return acc;
							}, 0)}{" "}
							ron,{" "}
							{customOwnerPlans.reduce((acc, plan) => {
								if (currentCustomOwnerPlans.includes(plan.id) && plan.price.currency === "eur") {
									return acc + Number(plan.price.amount);
								}
								return acc;
							}, 0)}{" "}
							eur pe luna
						</Text>
						<br />

						<Table size="sm">
							<Thead>
								<Tr>
									<Th></Th>
									<Th>{t("table.name")}</Th>
									<Th>{t("table.description")}</Th>
									<Th>{t("table.price")}</Th>
								</Tr>
							</Thead>
							<Tbody>
								{customOwnerPlans.map((plan, index) => {
									return (
										<Tr key={index}>
											<Td>
												<Checkbox
													isChecked={currentCustomOwnerPlans?.some((p) => p === plan.id)}
													onChange={(e) => {
														if (e.target.checked) {
															setCurrentCustomOwnerPlans(currentCustomOwnerPlans.concat(plan.id));
														} else {
															setCurrentCustomOwnerPlans(currentCustomOwnerPlans.filter((p) => p !== plan.id));
														}
													}}
												></Checkbox>
											</Td>
											<Td whiteSpace={"nowrap"}>{plan.name}</Td>
											<Td>{plan.description}</Td>
											<Td>
												{plan.price.amount} {plan.price.currency}
											</Td>
										</Tr>
									);
								})}
								{
									// if there are no custom owner plans, show a message
									customOwnerPlans.length === 0 && <Td colSpan={4}>{t("table.noData")}</Td>
								}
							</Tbody>
						</Table>
					</ModalBody>
					<ModalFooter>
						<Button colorScheme="red" mr={3} onClick={onEditOwnerPlanListClose}>
							{t("misc.cancel")}
						</Button>
						<Button colorScheme="blue" mr={3} onClick={handleSaveEditOwnerPlanList}>
							{t("misc.save")}
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		);
	};
	return (
		<Stack>
			<Wrap>
				<WrapItem>
					<SearchBox
						handleSearch={handleSearch}
						searchOptions={[
							{
								label: "Type",
								description: "Search by type",
							},
							{
								label: "Amount",
								description: "Search by amount",
							},
							{
								label: "Method",
								description: "Search by method",
							},
						]}
					/>
				</WrapItem>
				<WrapItem>
					<Tooltip
						isDisabled={
							getPropertyStatus(property) === "inactive" ||
							Object.keys(ownerBillingInformation).length !== 0 ||
							Object.keys(agencyBillingInformation).length !== 0
						}
						label={
							getPropertyStatus(property) === "inactive" ? "Add contracts to enable" : "You need to add billing information for owner and agency to enable"
						}
					>
						<Button
							onClick={onOpen}
							colorScheme="blue"
							isDisabled={
								getPropertyStatus(property) === "inactive" ||
								Object.keys(ownerBillingInformation).length === 0 ||
								Object.keys(agencyBillingInformation).length === 0 ||
								isArchived
							}
							width="fit-content"
						>
							{t("property.addPayment")}
						</Button>
					</Tooltip>
				</WrapItem>
				<WrapItem>
					<Tooltip
						isDisabled={
							getPropertyStatus(property) === "inactive" ||
							Object.keys(ownerBillingInformation).length !== 0 ||
							Object.keys(agencyBillingInformation).length !== 0
						}
						label={
							getPropertyStatus(property) === "inactive" ? "Add contracts to enable" : "You need to add billing information for owner and agency to enable"
						}
					>
						<Button
							onClick={onAddRentOpen}
							colorScheme="green"
							isDisabled={
								getPropertyStatus(property) === "inactive" ||
								Object.keys(ownerBillingInformation).length === 0 ||
								Object.keys(agencyBillingInformation).length === 0 ||
								isArchived
							}
							width="fit-content"
						>
							{t("property.addRentPayment")}
						</Button>
					</Tooltip>
				</WrapItem>
				<WrapItem>
					<Tooltip
						isDisabled={Object.keys(ownerBillingInformation).length !== 0 || Object.keys(agencyBillingInformation).length !== 0}
						label={"You need to add billing information for owner and agency to enable"}
					>
						<Button onClick={onAddAdmFeeOpen} colorScheme="blue" isDisabled={isArchived}>
							{t("property.addAdministrationFeePayment")}
						</Button>
					</Tooltip>
				</WrapItem>
				<WrapItem>
					<Tooltip
						isDisabled={Object.keys(ownerBillingInformation).length !== 0 || Object.keys(agencyBillingInformation).length !== 0}
						label={"You need to add billing information for owner and agency to enable"}
					>
						<Button onClick={onEditOwnerPlanListOpen} colorScheme="blue" isDisabled={isArchived}>
							{t("property.editCustomOwnerPlanList")}
						</Button>
					</Tooltip>
				</WrapItem>
			</Wrap>

			<Divider />
			{renderAddPaymentModal()}
			{renderAddRentModal()}
			{renderAddAdmFeeModal()}
			{renderEditCustomOwnerPlanListModal()}
			<Box overflowX="auto">
				<Table variant="simple" size="small">
					<Thead fontWeight={"bold"}>
						<Tr pl="0px">
							<Th pl="0px">{t("table.issuedDate")}</Th>
							<Th>{t("table.status")}</Th>
							<Th>{t("table.dueDate")}</Th>
							<Th>{t("table.amount")}</Th>
							<Th>{t("table.method")}</Th>
							<Th>{t("table.type")}</Th>
							<Th>{t("table.paidDate")}</Th>
							<Th></Th>
						</Tr>
					</Thead>
					<Tbody>
						{payments
							.filter((p) => !p.paymentDate)
							.map((row, index) => {
								return (
									<InvoicesRow
										id={row.id}
										key={index}
										date={row.issuedDate}
										address={getPropertyAddress(property)}
										paymentDate={row.paymentDate}
										code={row.number}
										amount={row.amount}
										type={row.type}
										dueDate={row.dueDate}
										logo={row.logo}
										format={row.format}
										method={row.paymentMethod}
										documents={row.documents}
										paymentDirection={row.paymentDirection}
										property={property}
										currency={row.currency}
										description={row.description}
										exchange={row.exchange}
									/>
								);
							})}
						{payments
							.filter((p) => p.paymentDate)
							.map((row, index) => {
								return (
									<InvoicesRow
										id={row.id}
										key={index}
										date={row.issuedDate}
										address={getPropertyAddress(property)}
										paymentDate={row.paymentDate}
										code={row.number}
										amount={row.amount}
										type={row.type}
										dueDate={row.dueDate}
										logo={row.logo}
										format={row.format}
										method={row.paymentMethod}
										documents={row.documents}
										paymentDirection={row.paymentDirection}
										property={property}
										currency={row.currency}
										description={row.description}
										exchange={row.exchange}
									/>
								);
							})}
					</Tbody>
				</Table>
			</Box>
		</Stack>
	);
}
