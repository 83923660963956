import {
	Box,
	Button,
	Stack,
	useDisclosure,
	Modal,
	ModalOverlay,
	ModalHeader,
	ModalContent,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	Table,
	Tbody,
	Text,
	Th,
	Thead,
	Tr,
	FormControl,
	FormLabel,
	Input,
	Select,
	useToast,
	WrapItem,
	Wrap,
	Td,
	Textarea,
	Flex,
	List,
	ListItem,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Dropzone from "../../Components/Dropzone/Dropzone";
import { addOwnerDocumentAsync, generateCombinedAdministrationFeePaymentAsync, updateOwnerAsync, uploadOwnerIdAsync } from "../../redux/user/userSlice";
import { _arrayBufferToBase64 } from "../../utils/convertors";
import Gallery from "../../Components/Gallery/Gallery";
import { API } from "../../utils/http";
import TenantsDocumentsTableRow from "../../Components/TableRows/TenantsDocumentsTableRow";
import OwnerDocumentsTableRow from "../../Components/TableRows/OwnerDocumentsTableRow";
import moment from "moment";
import CombinedPaymentsRow from "../../Components/TableRows/CombinedPaymentsRow";

export default function AdministrationFees({ owner, properties }) {
	const dispatch = useDispatch();
	const [date, setDate] = useState([moment().year(), moment().month()]);
	const loading = useSelector((state) => state.user.status) === "loading";
	const ownerPayments = owner.payments || [];
	const toast = useToast();
	const generateTotalInvoice = () => {
		dispatch(generateCombinedAdministrationFeePaymentAsync({ date, ownerEmail: owner.email }))
			.then((r) => {
				if (r.error?.message)
					toast({
						title: "Error encountered",
						description: r.error.message,
						status: "error",
						duration: 9000,
						isClosable: true,
					});
				else {
					toast({
						title: "Success!",
						description: "Payment added!",
						status: "success",
						duration: 2000,
						isClosable: true,
					});
				}
			})
			.finally(() => {});
		return;
		API.post("generate-combined-administration-fee-payment", { date, ownerEmail: owner.email })
			.then((res) => {
				console.log(res);
				const pdfBuffer = res.data.combinedAdministrationFeeInvoice.data;
				const data = Uint8Array.from(pdfBuffer);
				var blob = new Blob([data.buffer], { type: "application/pdf" });
				var link = document.createElement("a");
				link.href = window.URL.createObjectURL(blob);
				var fileName = "combinedInvoice.pdf";
				link.download = fileName;
				link.click();
			})
			.catch((e) => {
				toast({
					title: "Error",
					description: "Something went wrong",
					status: "error",
					duration: 5000,
					isClosable: true,
				});
			})
			.finally(() => {});
	};
	return (
		<Stack gap={4}>
			<Flex direction="row" justifyContent={"space-around"}>
				<Button
					onClick={() => {
						const newDate = moment(date).subtract(1, "months");
						console.log(newDate.format());
						setDate([newDate.year(), newDate.month()]);
					}}
				>
					Prev month
				</Button>
				<Button colorScheme="blue" width={"fit-content"} isDisabled={loading} onClick={generateTotalInvoice}>
					Generate for {moment(date).format("MMMM YYYY")}
				</Button>
				<Button
					isDisabled={moment().isSame(moment(date), "year") && moment().isSame(moment(date), "month")}
					onClick={() => {
						const newDate = moment(date).add(1, "months");
						console.log(newDate.format());
						setDate([newDate.year(), newDate.month()]);
					}}
				>
					Next month
				</Button>
			</Flex>
			<Stack>
				{ownerPayments.length === 0 ? (
					<Text>No combined administration fees</Text>
				) : (
					<Box overflowX="auto">
						<Table variant="simple" size="small">
							<Thead fontWeight={"bold"}>
								<Tr pl="0px">
									<Th pl="0px">Issued Date</Th>
									<Th>Amount</Th>
									<Th>Method</Th>
									<Th>Type</Th>
									<Th></Th>
								</Tr>
							</Thead>
							<Tbody>
								{ownerPayments.map((payment) => (
									<CombinedPaymentsRow payment={payment} owner={owner} />
								))}
							</Tbody>
						</Table>
					</Box>
				)}
			</Stack>
		</Stack>
	);
}
