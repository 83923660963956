import { Box, Button, Grid, GridItem, Input, InputGroup, InputRightAddon, Select, Stack, Text, Textarea, useToast } from "@chakra-ui/react";
import React, { useState } from "react";
import { updateDetailsAsync } from "../../redux/user/userSlice";
import { useDispatch } from "react-redux";
import { generalVerifier } from "../../utils/verifier";
import { useTranslation } from "react-i18next";

export default function GeneralnTab({ property }) {
	const { t } = useTranslation();
	const isArchived = property.archived;
	const [isEditing, setIsEditing] = useState(false);
	const initialData = {
		keys: property.keys,
		description: property.description,
		bankAccount: property.owner.bankDetails.account,
		bankAccountCurrency: property.owner.bankDetails.currency,
		administrationFeePercent: property.owner.bankDetails.administrationFeePercent,
		county: property.location.county,
		city: property.location.city,
		area: property.location.area,
		street: property.location.street,
		buildingNumber: property.location.buildingNumber,
		blockNumber: property.location.blockNumber,
		entrance: property.location.entrance,
		apartmentNumber: property.location.apartmentNumber,
	};
	const [error, setError] = useState({});
	const toast = useToast();
	const [details, setDetails] = useState(initialData);
	const dispatch = useDispatch();
	const handleEditButtonClick = () => {
		if (isEditing) {
			console.log("Save new data");
			const payload = { details, propertyId: property.id };
			console.log(payload);
			const errors = generalVerifier({
				bankAccount: payload.details.bankAccount,
				bankAccountCurrency: payload.details.bankAccountCurrency,
				administrationFeePercent: payload.details.administrationFeePercent,
			});
			if (Object.entries(errors).length !== 0) {
				setError(errors);
				toast({
					title: "Error encountered.",
					description: "Invalid information",
					status: "error",
					duration: 2000,
					isClosable: true,
				});
				return;
			}
			setError({});
			dispatch(updateDetailsAsync(payload)).then((r) => {
				if (r.error?.message)
					toast({
						title: "Error encountered",
						description: r.error.message,
						status: "error",
						duration: 9000,
						isClosable: true,
					});
				else {
					toast({
						title: "Success!",
						description: "Update successful",
						status: "success",
						duration: 2000,
						isClosable: true,
					});
				}
			});
		} else {
			console.log("Toggle edit button");
		}
		setIsEditing(!isEditing);
	};
	const handleDetailsChange = (e) => {
		setDetails({ ...details, [e.target.name]: e.target.value });
	};

	return (
		<Grid templateColumns={"repeat(6,1fr)"} gap={2}>
			<GridItem colSpan={{ sm: "6", lg: "3" }}>
				<Stack>
					<Text fontWeight={"bold"} fontSize="large">
						{t("property.location")}
					</Text>
					<Box width="fit-content" display={"flex"}>
						<Text fontWeight="bold" whiteSpace={"pre"}>
							{t("property.county")}:{" "}
						</Text>
						{isEditing ? (
							<Input size={"xs"} isInvalid={error?.county} value={details.county} onChange={handleDetailsChange} name="county" />
						) : (
							<Text>{property.location.county || "-"}</Text>
						)}
					</Box>
					<Box width="fit-content" display={"flex"}>
						<Text fontWeight="bold" whiteSpace={"pre"}>
							{t("property.city")}:{" "}
						</Text>
						{isEditing ? (
							<Input size={"xs"} isInvalid={error?.city} value={details.city} onChange={handleDetailsChange} name="city" />
						) : (
							<Text>{property.location.city || "-"}</Text>
						)}
					</Box>
					<Box width="fit-content" display={"flex"}>
						<Text fontWeight="bold" whiteSpace={"pre"}>
							{t("property.area")}:{" "}
						</Text>
						{isEditing ? (
							<Input size={"xs"} isInvalid={error?.area} value={details.area} onChange={handleDetailsChange} name="area" />
						) : (
							<Text>{property.location.area || "-"}</Text>
						)}
					</Box>
					<Box width="fit-content" display={"flex"}>
						<Text fontWeight="bold" whiteSpace={"pre"}>
							{t("property.street")}:{" "}
						</Text>
						{isEditing ? (
							<Input size={"xs"} isInvalid={error?.street} value={details.street} onChange={handleDetailsChange} name="street" />
						) : (
							<Text>{property.location.street || "-"}</Text>
						)}
					</Box>
					<Box width="fit-content" display={"flex"}>
						<Text fontWeight="bold" whiteSpace={"pre"}>
							{t("property.buildingNumber")}:{" "}
						</Text>
						{isEditing ? (
							<Input size={"xs"} isInvalid={error?.buildingNumber} value={details.buildingNumber} onChange={handleDetailsChange} name="buildingNumber" />
						) : (
							<Text>{property.location.buildingNumber || "-"}</Text>
						)}
					</Box>
					<Box width="fit-content" display={"flex"}>
						<Text fontWeight="bold" whiteSpace={"pre"}>
							{t("property.blockNumber")}:{" "}
						</Text>
						{isEditing ? (
							<Input size={"xs"} isInvalid={error?.blockNumber} value={details.blockNumber} onChange={handleDetailsChange} name="blockNumber" />
						) : (
							<Text>{property.location.blockNumber || "-"}</Text>
						)}
					</Box>
					<Box width="fit-content" display={"flex"}>
						<Text fontWeight="bold" whiteSpace={"pre"}>
							{t("property.entrance")}:{" "}
						</Text>
						{isEditing ? (
							<Input size={"xs"} isInvalid={error?.entrance} value={details.entrance} onChange={handleDetailsChange} name="entrance" />
						) : (
							<Text>{property.location.entrance || "-"}</Text>
						)}
					</Box>
					<Box width="fit-content" display={"flex"}>
						<Text fontWeight="bold" whiteSpace={"pre"}>
							{t("property.apartmentNumber")}:{" "}
						</Text>
						{isEditing ? (
							<Input size={"xs"} isInvalid={error?.apartmentNumber} value={details.apartmentNumber} onChange={handleDetailsChange} name="apartmentNumber" />
						) : (
							<Text>{property.location.apartmentNumber || "-"}</Text>
						)}
					</Box>
					<Box width="fit-content" display={"flex"}>
						<Text fontWeight="bold" whiteSpace={"pre"}>
							{t("property.keys")}:{" "}
						</Text>
						{isEditing ? (
							<Textarea size={"xs"} isInvalid={error?.keys} value={details.keys} onChange={handleDetailsChange} name="keys" />
						) : (
							<Text>{property.keys || "-"}</Text>
						)}
					</Box>
				</Stack>
			</GridItem>
			<GridItem colSpan={{ sm: "6", lg: "3" }}>
				<Stack>
					<Stack>
						<Box>
							<Text fontWeight={"bold"} fontSize="large">
								{t("property.description")}
							</Text>
							{isEditing ? (
								<Textarea size="xs" isInvalid={error?.description} value={details.description} onChange={handleDetailsChange} name="description" />
							) : (
								<Text>{property.description || "-"}</Text>
							)}
						</Box>
						<Box>
							<Text fontWeight={"bold"} fontSize="large">
								{t("property.bankAccount")}
							</Text>
							{isEditing ? (
								<Textarea size="xs" isInvalid={error?.bankAccount} value={details.bankAccount} onChange={handleDetailsChange} name="bankAccount" />
							) : (
								<Text>{property.owner.bankDetails.account || "-"}</Text>
							)}
						</Box>
						<Box>
							<Text fontWeight={"bold"} fontSize="large">
								{t("property.bankAccountCurrency")}
							</Text>
							{isEditing ? (
								// <Textarea
								// 	isInvalid={error?.bankAccountCurrency}
								// 	size="xs"
								// 	value={details.bankAccountCurrency}
								// 	onChange={handleDetailsChange}
								// 	name="bankAccountCurrency"
								// />
								<Select
									isInvalid={error?.bankAccountCurrency}
									// size="xs"
									value={details.bankAccountCurrency}
									onChange={handleDetailsChange}
									name="bankAccountCurrency"
								>
									<option value="eur">EUR</option>
									<option value="ron">RON</option>
								</Select>
							) : (
								<Text>{property.owner.bankDetails.currency || "-"}</Text>
							)}
						</Box>
						<Box>
							<Text fontWeight={"bold"} fontSize="large">
								{t("property.administrationFeePercentage")}
							</Text>
							{isEditing ? (
								// <Textarea size="xs" value={details.administrationFeePercent} onChange={handleDetailsChange} name="administrationFeePercent" />
								<InputGroup>
									<Input
										isInvalid={error?.administrationFeePercent}
										name="administrationFeePercent"
										placeholder="Administration fee percent"
										value={details.administrationFeePercent}
										onChange={handleDetailsChange}
										_placeholder={{ color: "gray.500" }}
										type="number"
									/>
									<InputRightAddon children="%" />
								</InputGroup>
							) : (
								<Text>{property.owner.bankDetails.administrationFeePercent || "-"}%</Text>
							)}
						</Box>
					</Stack>
					<Button width="fit-content" colorScheme={"blue"} onClick={handleEditButtonClick} isDisabled={isArchived}>
						{isEditing ? t("misc.save") : t("misc.edit")}
					</Button>
				</Stack>
			</GridItem>
		</Grid>
	);
}
