import React, { useState } from "react";
import { Button, Card, CardBody, CardHeader, Checkbox, Stack, Table, Tbody, Td, Text, Th, Thead, Tr, useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { updatePropertyCustomOwnerPlanAsync } from "../../../redux/user/userSlice";

export default function PropertyCustomOwnerPlansCard({ address, customOwnerPlans, propertyId, customOwnerPlansAvailable }) {
	const [currentCustomOwnerPlans, setCurrentCustomOwnerPlans] = useState(customOwnerPlans || []);
	const dispatch = useDispatch();
	const toast = useToast();
	const { t } = useTranslation();
	const onSave = () => {
		const payload = {
			propertyId,
			customOwnerPlans: currentCustomOwnerPlans,
		};
		console.log(payload);
		dispatch(updatePropertyCustomOwnerPlanAsync(payload)).then((r) => {
			if (r.error?.message) {
				toast({
					title: "Error encountered",
					description: r.error.message,
					status: "error",
					duration: 9000,
					isClosable: true,
				});
			} else {
				toast({
					title: "Success!",
					description: "Saved!",
					status: "success",
					duration: 2000,
					isClosable: true,
				});
			}
		});
	};
	return (
		<Card overflowX={{ sm: "scroll", xl: "hidden" }}>
			<CardHeader>
				<Stack>
					<Text fontSize="xl">Address: {address}</Text>
					<Button onClick={onSave} colorScheme="green" maxW={"fit-content"}>
						{t("misc.save")}
					</Button>
				</Stack>
			</CardHeader>
			<CardBody>
				<Table size="sm">
					<Thead>
						<Tr>
							<Th></Th>
							<Th>{t("table.name")}</Th>
							<Th>{t("table.description")}</Th>
							<Th>{t("table.price")}</Th>
						</Tr>
					</Thead>
					<Tbody>
						{customOwnerPlansAvailable.map((plan, index) => {
							return (
								<Tr key={index}>
									<Td>
										<Checkbox
											isChecked={currentCustomOwnerPlans?.some((p) => p === plan.id)}
											onChange={(e) => {
												if (e.target.checked) {
													setCurrentCustomOwnerPlans(currentCustomOwnerPlans.concat(plan.id));
												} else {
													setCurrentCustomOwnerPlans(currentCustomOwnerPlans.filter((p) => p !== plan.id));
												}
											}}
										></Checkbox>
									</Td>
									<Td>{plan.name}</Td>
									<Td>{plan.description}</Td>
									<Td>
										{plan.price.amount} {plan.price.currency}
									</Td>
								</Tr>
							);
						})}
					</Tbody>
				</Table>
			</CardBody>
		</Card>
	);
}
