import React from "react";
import { List, ListItem, ListIcon } from "@chakra-ui/react";

import { MdCheckCircle, MdSettings } from "react-icons/md";
import { IoMdCloseCircle } from "react-icons/io";
import moment from "moment";
import { useTranslation } from "react-i18next";

export default function AdministrationStatusTooltipLabel({ property, administrationStatus }) {
	const { t } = useTranslation();
	// const getListIcon = (bool) => (bool ? <ListIcon as={MdCheckCircle} color="green.500" /> : <ListIcon as={IoMdCloseCircle} color="red.500" />);
	const getListIcon = (status) => {
		switch (status) {
			case "warning":
				return <ListIcon as={MdSettings} color="yellow.500" />;
			case "complete":
				return <ListIcon as={MdCheckCircle} color="green.500" />;
			case "incomplete":
				return <ListIcon as={IoMdCloseCircle} color="red.500" />;
			case "expired":
				return <ListIcon as={IoMdCloseCircle} color="red.500" />;
			default:
				return <ListIcon as={IoMdCloseCircle} color="red.500" />;
		}
	};
	return (
		<List spacing={3}>
			{property.specs.apartmentFeatures.type === "garage" ? (
				<ListItem>
					{getListIcon(administrationStatus.administrationContract)}
					{t("types.administrationContract")}
				</ListItem>
			) : (
				<>
					<ListItem>
						{getListIcon(administrationStatus.administrationContract)}
						{t("types.administrationContract")}
					</ListItem>
					<ListItem>
						{getListIcon(administrationStatus.insurance)}
						{t("types.insurance")}
					</ListItem>
					<ListItem>
						{getListIcon(administrationStatus.centralHeatingVerification)}

						{t("types.centralHeatingVerification")}
					</ListItem>
					<ListItem>
						{getListIcon(administrationStatus.gasContract)}
						{t("types.gasContract")}
					</ListItem>
					<ListItem>
						{getListIcon(administrationStatus.electricityContract)}
						{t("types.electricityContract")}
					</ListItem>
					{property.specs.apartmentFeatures.water === "waterContract" ? (
						<>
							<ListItem>
								{getListIcon(administrationStatus.waterContract)} {t("types.waterContract")}
							</ListItem>
							<ListItem>
								{getListIcon(administrationStatus.lastWaterInvoice)}
								{t("types.lastWaterInvoice")}
							</ListItem>
						</>
					) : (
						<>
							<ListItem>
								{getListIcon(administrationStatus.tenantAssociationCertificate)} {t("types.tenantAssociationCertificate")}
							</ListItem>
							<ListItem>
								{getListIcon(administrationStatus.waterAssociation)} {t("types.waterAssociation")}
							</ListItem>
						</>
					)}
					<ListItem>
						{getListIcon(administrationStatus.protocol)}
						{t("types.protocol")}
					</ListItem>
					<ListItem>
						{getListIcon(administrationStatus.gasAccount)}
						{t("types.gasAccount")}
					</ListItem>
					<ListItem>
						{getListIcon(administrationStatus.electricityAccount)}
						{t("types.electricityAccount")}
					</ListItem>
					{/* <ListItem>
						{getListIcon(administrationStatus.cityHallAccount)}
						City Hall Account
					</ListItem> */}
					{/* <ListItem>
						{getListIcon(administrationStatus.financeAccount)}
						Finance Account
					</ListItem> */}
					<ListItem>
						{getListIcon(
							property.owner.identificationDocument.id && moment(property.owner.identificationDocument.expirationDate).unix() > moment().unix()
								? "complete"
								: "incomplete"
						)}
						{t("types.ownerIdentificationDocument")}
					</ListItem>
				</>
			)}
		</List>
	);
}
