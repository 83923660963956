import { DownloadIcon, HamburgerIcon } from "@chakra-ui/icons";
import { Button, Flex, Icon, Text, useColorModeValue, Badge, Tr, Td, UnorderedList, ListItem, useDisclosure, useToast } from "@chakra-ui/react";
import React from "react";
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton } from "@chakra-ui/react";
import { Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import moment from "moment/moment";
import { API } from "../../../utils/http";
import { useTranslation } from "react-i18next";
function PaymentsRow(props) {
	const { date, code, status, amount, type, paymentDate, address, method, documents, propertyId, id, currency, paymentDirection } = props;

	const { isOpen, onOpen, onClose } = useDisclosure();
	const toast = useToast();
	const { t } = useTranslation();
	const handleGetDocument = (documentId, documentName) => {
		const payload = { propertyId: propertyId, documentId, paymentId: id, documentName };
		console.log(payload);
		API.put("get-payment-document", payload)
			.then((r) => {
				console.log(r);
				window.open(r.data.url, "_blank");
			})
			.catch((e) => {
				console.log(e);
				toast({
					title: "Error encountered",
					description: e.response.data.error.message,
					status: "error",
					duration: 9000,
					isClosable: true,
				});
			});
	};

	const renderViewDocumentsModal = () => {
		return (
			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Documents</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<UnorderedList>
							{documents?.map((d, index) => (
								<ListItem key={index}>
									<Text
										color="teal.500"
										textDecorationLine={"underline"}
										cursor={"pointer"}
										onClick={() => {
											handleGetDocument(d.id, d.documentName);
										}}
									>
										{d.documentName}
										<DownloadIcon marginLeft={2} />
									</Text>
								</ListItem>
							))}
						</UnorderedList>
						{documents?.length === 0 && <Text>No documents found</Text>}
					</ModalBody>

					<ModalFooter>
						<Button colorScheme="blue" mr={3} onClick={onClose}>
							Close
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		);
	};
	return (
		<Tr>
			<Td minWidth={{ sm: "150px" }} pl="0px">
				<Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
					<Flex direction="column">
						<Text fontSize="md" minWidth="100%">
							{moment(date).format("DD MMM YYYY - HH:mm")}
						</Text>
						<Text fontSize="sm" fontWeight="normal" minWidth="100%" hidden={!code}>
							Document Number: {code}
						</Text>
					</Flex>
				</Flex>
			</Td>
			<Td minWidth={{ sm: "250px" }}>
				<Text fontSize="md" pb=".5rem">
					{address}
				</Text>
			</Td>
			<Td>
				<Badge colorScheme={status === "paid" ? "green" : "red"} fontSize="16px" p="3px 10px" borderRadius="8px">
					{status}
				</Badge>
			</Td>
			<Td>
				<Text fontSize="md" pb=".5rem">
					{paymentDirection === "incoming" ? "+" : "-"}
					{amount} {currency}
				</Text>
			</Td>
			<Td>
				<Text fontSize="md" pb=".5rem">
					{method}
				</Text>
			</Td>
			<Td>
				<Text fontSize="md" pb=".5rem">
					{t(`types.${type}`)}
				</Text>
			</Td>
			<Td>
				<Text fontSize="md" pb=".5rem">
					{paymentDate ? moment(paymentDate).format("DD MMM YYYY - HH:mm") : "-"}
				</Text>
			</Td>
			<Td>
				<Menu closeOnSelect={false}>
					<MenuButton>
						<Icon as={HamburgerIcon} />
					</MenuButton>
					<MenuList>
						<MenuItem onClick={onOpen}>View Documents</MenuItem>
					</MenuList>
				</Menu>
				{renderViewDocumentsModal()}
			</Td>
		</Tr>
	);
}

export default PaymentsRow;
