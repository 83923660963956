import { Input, InputGroup, InputRightAddon } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import useDebounce from "../../Components/Hooks/useDebounce";
import InfoTooltip from "../../Components/Tooltips/InfoTooltip";

export default function SearchBox({ handleSearch, searchOptions }) {
	const [query, setquery] = useState("");
	const debouncedValue = useDebounce(query, 500);
	const handleSearchChange = (e) => {
		setquery(e.target.value);
	};
	useEffect(() => {
		console.log("searching");
		handleSearch(debouncedValue);
	}, [debouncedValue, handleSearch]);
	return (
		<InputGroup>
			<Input type="text" placeholder="Search..." value={query} onChange={handleSearchChange} />
			<InputRightAddon children={<InfoTooltip searchOptions={searchOptions} />} />
		</InputGroup>
	);
}
