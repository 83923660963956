import {
	Badge,
	Box,
	Button,
	Card,
	CardBody,
	CardHeader,
	Flex,
	Input,
	Stack,
	Table,
	TableContainer,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
	useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import { useSelector } from "react-redux";
import { getPropertyAddress } from "../../utils/verifier";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import FinancialStatementRow from "../../Components/TableRows/FinancialStatementRow";
import moment from "moment";
import TablePagination from "../../Components/Pagination/TablePagination";
import { selectActiveProperties } from "../../redux/user/userSlice";
import { useTranslation } from "react-i18next";

export default function Statements() {
	const properties = useSelector(selectActiveProperties);
	const { t } = useTranslation();
	const [query, setquery] = useState("");
	const [year, setYear] = useState(moment().year());

	const handleSearchChange = (e) => {
		setquery(e.target.value);
	};
	const navigate = useNavigate();
	const goToProperty = (id) => {
		navigate(`/properties/view/${id}?tab=financialStatements`);
	};
	const hoverColor = useColorModeValue("gray.100", "gray.700");
	//table pagination
	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 10; // Number of items to display per page

	// Calculate the starting and ending indices for the current page
	const startIndex = (currentPage - 1) * itemsPerPage;
	const endIndex = currentPage * itemsPerPage;

	const filteredProperties = properties?.filter((p) => {
		const address = getPropertyAddress(p);

		return (
			address.toLowerCase().includes(query.toLowerCase()) ||
			p.owner.name.toLowerCase().includes(query.toLowerCase()) ||
			p.tenants.find((t) => t.name.toLowerCase().includes(query.toLowerCase())) ||
			p.contacts.find((t) => t.name.toLowerCase().includes(query.toLowerCase()))
		);
	});
	// Assuming `properties` contains your data
	const dataToShow = filteredProperties.slice(startIndex, endIndex);
	// Function to handle page change
	const handlePageChange = (newPage) => {
		setCurrentPage(newPage);
	};
	return (
		<Card overflowX={{ sm: "scroll", xl: "hidden" }}>
			<CardHeader fontSize="xl" fontWeight="bold">
				<Stack>
					<Stack direction={"row"} alignItems="center">
						<Text>{t("statements.statements")}</Text>
						<Box>
							<Input type="text" placeholder="Search..." value={query} onChange={handleSearchChange} />
						</Box>
					</Stack>
					<Flex direction="row" justifyContent={"space-around"}>
						<Button
							onClick={() => {
								setYear(year - 1);
							}}
						>
							{t("table.previousYear")}
						</Button>
						<Text>{year}</Text>
						<Button
							isDisabled={year === moment().year()}
							onClick={() => {
								setYear(year + 1);
							}}
						>
							{t("table.nextYear")}
						</Button>
					</Flex>
				</Stack>
			</CardHeader>
			<CardBody>
				<TableContainer>
					<Table variant="simple" size="small">
						<Thead>
							<Tr>
								<Th pl="0px">{t("table.addedDate")}</Th>
								<Th>{t("table.name")}</Th>
								<Th>{t("table.year")}</Th>
								<Th>{t("table.type")}</Th>
								<Th>{t("table.description")}</Th>
								<Th></Th>
							</Tr>
						</Thead>
						<Tbody>
							{dataToShow.map((property, index) => {
								const financialStatements = property.financialStatements.filter((fs) => Number(fs.year) === year);
								return (
									<>
										<Tr
											pl="0px"
											fontWeight={"bold"}
											onClick={() => {
												goToProperty(property.id);
											}}
											_hover={{ bg: hoverColor }}
										>
											<Td pl="0px">{property.owner.name}</Td>
											<Td pl="0px" colSpan={6}>
												{getPropertyAddress(property)}
											</Td>
										</Tr>
										{financialStatements.map((row, index) => {
											return (
												<FinancialStatementRow
													id={row.id}
													key={index}
													name={row.name}
													date={row.addedDate}
													address={getPropertyAddress(property)}
													paymentDate={row.paymentDate}
													code={row.number}
													amount={row.amount}
													description={row.description}
													type={row.type}
													status={row.paymentDate ? "paid" : "due"}
													logo={row.logo}
													format={row.format}
													method={row.paymentMethod}
													documents={row.documents}
													paymentDirection={row.paymentDirection}
													property={property}
													currency={row.currency}
													active={row.active}
													year={row.year}
												/>
											);
										})}
										{financialStatements.length === 0 && (
											<Tr>
												<Td colSpan={7}>{t("table.noData")}.</Td>
											</Tr>
										)}
									</>
								);
							})}
						</Tbody>
					</Table>
				</TableContainer>
				<TablePagination totalItems={filteredProperties.length} itemsPerPage={itemsPerPage} onPageChange={handlePageChange} />
			</CardBody>
		</Card>
	);
}
