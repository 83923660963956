import React, { useState } from "react";
// Chakra imports
import {
	Box,
	Button,
	Divider,
	Flex,
	FormControl,
	FormLabel,
	Grid,
	Input,
	InputGroup,
	ListItem,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Select,
	Stack,
	Table,
	TableContainer,
	Tbody,
	Td,
	Text,
	Textarea,
	Th,
	Thead,
	Tr,
	UnorderedList,
	useColorModeValue,
	useDisclosure,
	useToast,
} from "@chakra-ui/react";
// Custom components
import Dropzone from "../../Components/Dropzone/Dropzone";
import { Card, CardHeader, CardBody } from "@chakra-ui/react";

import { useDispatch, useSelector } from "react-redux";
import {
	addAgentAsync,
	addCustomOwnerPlanAsync,
	getDashboardAsync,
	selectActiveProperties,
	updateAgencyAsync,
	updateTemplatesAsync,
} from "../../redux/user/userSlice";
import { API, getHeaders, setHeaders } from "../../utils/http";
import { _arrayBufferToBase64 } from "../../utils/convertors";
import { useNavigate } from "react-router-dom";
import CustomOwnerPlansTableRow from "../../Components/TableRows/CustomOwnerPlansTableRow";
import { useTranslation } from "react-i18next";

function Profile() {
	const textColor = useColorModeValue("gray.700", "white");
	const user = useSelector((state) => state.user.user);
	const properties = useSelector(selectActiveProperties);
	const associations = user.associations;
	const { t } = useTranslation();
	const customOwnerPlans = user.customOwnerPlans || [];
	const initialCustomOwnerPlanInfo = {
		name: "",
		description: "",
		price: {
			amount: "",
			currency: "ron",
		},
	};
	const [editOwnerPlanInfo, setEditOwnerPlanInfo] = useState(initialCustomOwnerPlanInfo);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { isOpen: isUploadTemplateModalOpen, onOpen: onUploadTemplateModalOpen, onClose: onUploadTemplateModalClose } = useDisclosure();
	const { isOpen: isAddOwnerPlanModalOpen, onOpen: onAddOwnerPlanModalOpen, onClose: onAddOwnerPlanModalClose } = useDisclosure();
	const [isEditing, setIsEditing] = useState(false);
	const initialDocumentInformation = { documentData: "", documentName: "", contentType: "" };
	const [selectedTemplateType, setSelectedTemplateType] = useState("");
	const [documentInformation, setDocumentInformation] = useState(initialDocumentInformation);
	const templateTypes = [
		{ id: "disposition", label: "Disposition" },
		{ id: "dispositionSigned", label: "Disposition Signed" },
		{ id: "combinedAdministrationFee", label: "Combined Administration Fee" },
		{ id: "administrationFee", label: "Administration Fee" },
		// { id: "TICKET", label: "Ticket" },
		// { id: "TICKET_SIGNED", label: "Ticket Signed" },
	];
	const initialBillingInformation = {
		name: user?.billingInformation.name,
		tradeRegisterNumber: user?.billingInformation.tradeRegisterNumber,
		identificationNumber: user?.billingInformation.identificationNumber,
		address: user?.billingInformation.address,
		iban: user?.billingInformation.iban,
		bank: user?.billingInformation.bank,
		vat: user?.billingInformation.vat,
		admFeeSeries: user?.billingInformation.admFeeSeries,
		ticketSeries: user?.billingInformation.ticketSeries,
	};
	const templates = user?.templates;
	const [billingInformation, setBillingInformation] = useState(initialBillingInformation);

	const initialAgentInformation = { email: "" };
	const [agentInformation, setAgentInformation] = useState(initialAgentInformation);
	const toast = useToast();
	const dispatch = useDispatch();
	const handleAgentChange = (e) => {
		setAgentInformation({ ...agentInformation, [e.target.name]: e.target.value });
	};
	const handleBillingInformationChange = (e) => {
		setBillingInformation({ ...billingInformation, [e.target.name]: e.target.value });
	};
	const onDocumentDrop = async (acceptedFiles) => {
		console.log(acceptedFiles);
		const buffer = await acceptedFiles[0].arrayBuffer();
		const documentName = acceptedFiles[0].name;
		const contentType = acceptedFiles[0].type;
		setDocumentInformation({ ...documentInformation, documentData: _arrayBufferToBase64(buffer), documentName, contentType });
	};
	const addAgent = () => {
		const payload = { ...agentInformation, agencyEmail: user.email };
		console.log(payload);
		dispatch(addAgentAsync(payload))
			.then((r) => {
				if (r.error?.message)
					toast({
						title: "Error encountered",
						description: r.error.message,
						status: "error",
						duration: 9000,
						isClosable: true,
					});
				else {
					toast({
						title: "Success!",
						description: "Agent added!",
						status: "success",
						duration: 2000,
						isClosable: true,
					});
				}
			})
			.finally(() => {
				setAgentInformation(initialAgentInformation);
				onClose();
			});
	};
	const addCustomOwnerPlan = () => {
		const payload = {
			...editOwnerPlanInfo,
		};
		console.log(payload);

		dispatch(addCustomOwnerPlanAsync(payload))
			.then((r) => {
				if (r.error?.message)
					toast({
						title: "Error encountered",
						description: r.error.message,
						status: "error",
						duration: 9000,
						isClosable: true,
					});
				else {
					toast({
						title: "Success!",
						description: "Custom plan added!",
						status: "success",
						duration: 2000,
						isClosable: true,
					});
				}
			})
			.finally(() => {
				setEditOwnerPlanInfo(initialCustomOwnerPlanInfo);
				onAddOwnerPlanModalClose();
			});
	};
	const handleExportAssociations = () => {
		// Define the header row
		const header = "Name,Description,IBAN,Administrator Name,Email,Phone Number\n";

		// Create a csv file from the associations
		const csv = associations
			.map((a) => {
				// Wrap each field value in double quotes to handle commas
				return `"${a.name}","${a.description}","${a.iban}","${a.administratorName}","${a.email}","${a.phoneNumber}"\n`;
			})
			.join("");

		// Concatenate the header and data
		const csvData = header + csv;

		const blob = new Blob([csvData], { type: "text/csv" });
		const url = window.URL.createObjectURL(blob);
		const a = document.createElement("a");
		a.setAttribute("hidden", "");
		a.setAttribute("href", url);
		a.setAttribute("download", "associations.csv");
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	};

	const configureStripeAccount = () => {
		console.log("Configure stripe");
		API.post("create-connected-account").then((r) => {
			console.log(r.data);
			window.open(r.data.onboardingUrl, "_self");
		});
	};
	const goToStripeDashboard = () => {
		API.post("stripe-dashboard", { stripeConnectedAccountId: user.stripeConnectedAccountId }).then((r) => {
			console.log(r.data);
			window.open(r.data.loginLink, "_blank");
		});
	};
	const handleUploadTemplateType = (type) => {
		console.log(type);
		dispatch(updateTemplatesAsync({ templateType: type, ...documentInformation }))
			.then((r) => {
				if (r.error?.message)
					toast({
						title: "Error encountered",
						description: r.error.message,
						status: "error",
						duration: 9000,
						isClosable: true,
					});
				else {
					toast({
						title: "Success!",
						description: "Template uploaded!",
						status: "success",
						duration: 2000,
						isClosable: true,
					});
				}
			})
			.finally(() => {
				onUploadTemplateModalClose();
			});
		// API.put("upload-template", { templateType: type, ...documentInformation }).then((r) => {
		// 	console.log(r.data);
		// 	// setBillingInformation({ ...billingInformation, templates: { ...billingInformation.templates, [type]: r.data } });
		// 	// setDocumentInformation(initialDocumentInformation);
		// });
	};
	const handleSave = () => {
		console.log(billingInformation);
		dispatch(updateAgencyAsync({ type: "billingInformation", ...billingInformation }));
		// dispatch(updateAgencyAsync(billingInformation));
	};
	const renderAddAgentModal = () => (
		<Modal isOpen={isOpen} onClose={onClose} size={"xl"}>
			<ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
			<ModalContent>
				<ModalHeader>
					<Text>Add an Agent</Text>
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<FormControl>
						<FormLabel display={"inline"}>Email</FormLabel>
						<Input
							width={"fit-content"}
							type="text"
							name="email"
							placeholder="Agent email"
							_placeholder={{ color: "gray.500" }}
							value={agentInformation.email}
							onChange={handleAgentChange}
						/>
					</FormControl>
				</ModalBody>
				<ModalFooter>
					<Button colorScheme="red" onClick={onClose} mr={3}>
						Cancel
					</Button>
					<Button colorScheme="blue" onClick={addAgent}>
						Save
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
	const navigate = useNavigate();
	const [onBehalfOf, setOnBehalfOf] = useState("");
	const handleChangeOnBehalfOf = (e) => {
		setOnBehalfOf(e.target.value);
	};
	const handleChangeOwnerPlanInformation = (e) => {
		const { name, value } = e.target;
		setEditOwnerPlanInfo((prev) => ({ ...prev, [name]: value }));
	};
	const saveOnBehalfOf = () => {
		setHeaders({ ...getHeaders(), onbehalfof: onBehalfOf });
		dispatch(getDashboardAsync())
			.then((r) => {
				console.log(r);
				if (r.error?.message)
					toast({
						title: "Error encountered",
						description: r.error.message,
						status: "error",
						duration: 9000,
						isClosable: true,
					});
				else {
					toast({
						title: "Success!",
						description: "Dashboard info success",
						status: "success",
						duration: 2000,
						isClosable: true,
					});
				}
			})
			.finally(() => {
				navigate(`/`);
			});
	};
	const { isOpen: isOnBehalfOfOpen, onOpen: onOnBehalfOfOpen, onClose: onOnBehalfOfClose } = useDisclosure();

	const setRenderOnBehalfOfModal = () => (
		<Modal isOpen={isOnBehalfOfOpen} onClose={onOnBehalfOfClose} size={"xl"}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>
					<Text>Set on behalf of</Text>
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<FormControl>
						<FormLabel display={"inline"}>Email</FormLabel>
						<Input
							width={"fit-content"}
							type="text"
							name="email"
							placeholder="On Behalf Of Email"
							_placeholder={{ color: "gray.500" }}
							value={onBehalfOf}
							onChange={handleChangeOnBehalfOf}
						/>
					</FormControl>
				</ModalBody>
				<ModalFooter>
					<Button colorScheme="red" onClick={onClose} mr={3}>
						Cancel
					</Button>
					<Button colorScheme="blue" onClick={saveOnBehalfOf}>
						Save
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
	const renderAddOwmerPlanModal = () => {
		return (
			<Modal isOpen={isAddOwnerPlanModalOpen} onClose={onAddOwnerPlanModalClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Adauga pachet de servicii</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Stack>
							<FormControl>
								<FormLabel display={"inline"}>Name:</FormLabel>
								<Input
									width={"fit-content"}
									type="text"
									name="name"
									placeholder="Name"
									_placeholder={{ color: "gray.500" }}
									value={editOwnerPlanInfo.name}
									onChange={handleChangeOwnerPlanInformation}
								/>
							</FormControl>
							<FormControl>
								<FormLabel display={"inline"}>Description:</FormLabel>
								<Textarea
									width={"fit-content"}
									type="text"
									name="description"
									placeholder="Description"
									_placeholder={{ color: "gray.500" }}
									value={editOwnerPlanInfo.description}
									onChange={handleChangeOwnerPlanInformation}
								/>
							</FormControl>
							<FormControl display={"flex"} alignItems="center">
								<FormLabel display={"inline"}>Price:</FormLabel>
								<InputGroup>
									<Input
										maxW={24}
										// isInvalid={error?.documentNumber}
										width={"fit-content"}
										type="number"
										name="amount"
										placeholder="Price"
										_placeholder={{ color: "gray.500" }}
										value={editOwnerPlanInfo.price.amount}
										onChange={(e) => {
											setEditOwnerPlanInfo((prev) => ({
												...prev,
												price: { ...prev.price, amount: e.target.value },
											}));
										}}
									/>
									<Select
										width={"fit-content"}
										name="currency"
										value={editOwnerPlanInfo.price.currency}
										onChange={(e) => {
											setEditOwnerPlanInfo((prev) => ({
												...prev,
												price: { ...prev.price, currency: e.target.value },
											}));
										}}
									>
										<option value="ron">RON</option>
										<option value="eur">EUR</option>
									</Select>
								</InputGroup>
							</FormControl>
						</Stack>
					</ModalBody>
					<ModalFooter>
						<Button colorScheme="red" onClick={onAddOwnerPlanModalClose} mr={3}>
							Cancel
						</Button>
						<Button colorScheme="blue" onClick={addCustomOwnerPlan}>
							Save
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		);
	};

	const renderUploadTemplateModal = () => (
		<Modal isOpen={isUploadTemplateModalOpen} onClose={onUploadTemplateModalClose} size={"xl"}>
			<ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
			<ModalContent>
				<ModalHeader>
					<Text>Upload Template</Text>
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Dropzone
						onDrop={onDocumentDrop}
						accept={{
							"application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
							"application/msword": [".doc"],
						}}
						description="Upload DOC or DOCX Format"
					/>
				</ModalBody>
				<ModalFooter>
					<Button colorScheme="red" onClick={onUploadTemplateModalClose} mr={3}>
						Cancel
					</Button>
					<Button
						colorScheme="blue"
						onClick={() => {
							handleUploadTemplateType(selectedTemplateType);
						}}
					>
						Save
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
	return (
		<Stack direction="column">
			<Box width="100%" p={2} hidden={user.email !== "omihai00@gmail.com"}>
				<Button colorScheme="blue" onClick={onOnBehalfOfOpen}>
					Set on behalf of
				</Button>

				{setRenderOnBehalfOfModal()}
			</Box>
			<Grid templateColumns={{ sm: "1fr", xl: "repeat(2, 1fr)" }} gap="2">
				<Card p="16px" my={{ sm: "24px", xl: "0px" }}>
					<CardHeader mb="12px">
						<Text fontSize="lg" color={textColor} fontWeight="bold">
							Profile Information
						</Text>
					</CardHeader>
					<CardBody px="5px">
						<Flex direction="column">
							<Flex align="center" mb="18px">
								<Text fontSize="md" color={textColor} fontWeight="bold" me="10px">
									Name:{" "}
								</Text>
								<Text fontSize="md" color="gray.500" fontWeight="400">
									{user?.name || "-"}
								</Text>
							</Flex>
							<Flex align="center" mb="18px">
								<Text fontSize="md" color={textColor} fontWeight="bold" me="10px">
									Phone Number:{" "}
								</Text>
								<Text fontSize="md" color="gray.500" fontWeight="400">
									{user?.phoneNumber || "-"}
								</Text>
							</Flex>
							<Flex align="center" mb="18px">
								<Text fontSize="md" color={textColor} fontWeight="bold" me="10px">
									Email:{" "}
								</Text>
								<Text fontSize="md" color="gray.500" fontWeight="400">
									{user?.email}
								</Text>
							</Flex>
							<Divider />
							<Flex align="center" mb="18px">
								<Text fontSize="md" color={textColor} fontWeight="bold" me="10px">
									Contact Information:{" "}
								</Text>
								<Text fontSize="md" color="gray.500" fontWeight="400">
									{user?.contactEmail || "-"}
								</Text>
							</Flex>
							<Flex align="center" mb="18px">
								<Text fontSize="md" color={textColor} fontWeight="bold" me="10px">
									Contact Name:{" "}
								</Text>
								<Text fontSize="md" color="gray.500" fontWeight="400">
									{user?.contactName || "-"}
								</Text>
							</Flex>
							<Flex align="center" mb="18px">
								<Text fontSize="md" color={textColor} fontWeight="bold" me="10px">
									Contact Phone:{" "}
								</Text>
								<Text fontSize="md" color="gray.500" fontWeight="400">
									{user?.contactPhone || "-"}
								</Text>
							</Flex>
						</Flex>
						<Flex direction="column" w="100%">
							<Text fontSize="lg" color={textColor} fontWeight="bold">
								Agents
							</Text>
							<UnorderedList>
								{user.agents?.length === 0 ? (
									<>You currently have no agents.</>
								) : (
									user.agents.map((a) => {
										return <ListItem>{a}</ListItem>;
									})
								)}
							</UnorderedList>
						</Flex>
						<Stack direction={"row"}>
							{user.stripeConnectedAccountId ? (
								<Button colorScheme="green" onClick={goToStripeDashboard}>
									Payment Dashboard
								</Button>
							) : (
								<Button colorScheme="yellow" onClick={configureStripeAccount} isDisabled={user.type !== "agency"}>
									Configure payment account
								</Button>
							)}
							<Button colorScheme="blue" onClick={onOpen} isDisabled={user.type !== "agency"}>
								Add Agent
							</Button>
							{renderAddAgentModal()}
						</Stack>
					</CardBody>
				</Card>
				<Card p="16px">
					<CardHeader mb="12px">
						<Text fontSize="lg" fontWeight={"bold"}>
							Billing Information
						</Text>
					</CardHeader>
					<CardBody px="5px">
						<Stack>
							<Stack direction={"row"} alignItems="center">
								<Text fontWeight={"bold"}>Name:</Text>
								{isEditing ? (
									<Input size="sm" value={billingInformation.name} width="fit-content" name="name" onChange={handleBillingInformationChange} />
								) : (
									<Text>{user?.billingInformation.name || "-"}</Text>
								)}
							</Stack>
							<Stack direction={"row"} alignItems="center">
								<Text fontWeight={"bold"}>Trade Register Number:</Text>
								{isEditing ? (
									<Input
										size="sm"
										value={billingInformation.tradeRegisterNumber}
										width="fit-content"
										name="tradeRegisterNumber"
										onChange={handleBillingInformationChange}
									/>
								) : (
									<Text>{user?.billingInformation.tradeRegisterNumber || "-"}</Text>
								)}
							</Stack>
							<Stack direction={"row"} alignItems="center">
								<Text fontWeight={"bold"}>CIF:</Text>
								{isEditing ? (
									<Input
										size="sm"
										value={billingInformation.identificationNumber}
										width="fit-content"
										name="identificationNumber"
										onChange={handleBillingInformationChange}
									/>
								) : (
									<Text>{user?.billingInformation.identificationNumber || "-"}</Text>
								)}
							</Stack>
							<Stack direction={"row"} alignItems="center">
								<Text fontWeight={"bold"}>Address:</Text>
								{isEditing ? (
									<Textarea size="sm" value={billingInformation.address} width="fit-content" name="address" onChange={handleBillingInformationChange} />
								) : (
									<Text>{user?.billingInformation.address || "-"}</Text>
								)}
							</Stack>
							<Stack direction={"row"} alignItems="center">
								<Text fontWeight={"bold"}>IBAN:</Text>
								{isEditing ? (
									<Input size="sm" value={billingInformation.iban} width="fit-content" name="iban" onChange={handleBillingInformationChange} />
								) : (
									<Text>{user?.billingInformation.iban || "-"}</Text>
								)}
							</Stack>
							<Stack direction={"row"} alignItems="center">
								<Text fontWeight={"bold"}>Bank:</Text>
								{isEditing ? (
									<Input size="sm" value={billingInformation.bank} width="fit-content" name="bank" onChange={handleBillingInformationChange} />
								) : (
									<Text>{user?.billingInformation.bank || "-"}</Text>
								)}
							</Stack>
							<Stack direction={"row"} alignItems="center">
								<Text fontWeight={"bold"}>VAT:</Text>
								{isEditing ? (
									<Input size="sm" value={billingInformation.vat} width="fit-content" name="vat" onChange={handleBillingInformationChange} />
								) : (
									<Text>{user?.billingInformation.vat || "-"}</Text>
								)}
							</Stack>
							<Divider />
							<Stack direction={"row"} alignItems="center">
								<Text fontWeight={"bold"}>Administration Fee Invoice Series Prefix:</Text>
								{isEditing ? (
									<Input size="sm" value={billingInformation.admFeeSeries} width="fit-content" name="admFeeSeries" onChange={handleBillingInformationChange} />
								) : (
									<Text>{user?.billingInformation.admFeeSeries || "-"}</Text>
								)}
							</Stack>
							<Stack direction={"row"} alignItems="center">
								<Text fontWeight={"bold"}>Ticket Series Prefix:</Text>
								{isEditing ? (
									<Input size="sm" value={billingInformation.ticketSeries} width="fit-content" name="ticketSeries" onChange={handleBillingInformationChange} />
								) : (
									<Text>{user?.billingInformation.ticketSeries || "-"}</Text>
								)}
							</Stack>

							{/* <Signature handleChange={handleSignatureChange} signature={user?.billingInformation.signature || ""} /> */}
							<Stack>
								<Button
									width={"fit-content"}
									colorScheme={"green"}
									onClick={() => {
										if (isEditing) handleSave();
										setIsEditing(!isEditing);
									}}
									isDisabled={user.type !== "agency"}
								>
									{isEditing ? "Save" : "Edit"}
								</Button>
							</Stack>
						</Stack>
					</CardBody>
				</Card>
				<Card p="16px">
					<CardHeader mb="12px">
						<Text fontSize="lg" fontWeight={"bold"}>
							Templates
						</Text>
					</CardHeader>
					<CardBody px="5px">
						<Stack>
							{templateTypes.map((t) => (
								<Stack key={t.id} direction={"row"} alignItems="center">
									<Text fontWeight={"bold"}>{t.label}:</Text>
									<Text>{templates[t.id]?.documentName || "n/a"}</Text>
									<Button
										isDisabled={user.type !== "agency"}
										variant="outline"
										size="xs"
										onClick={(e) => {
											setSelectedTemplateType(t.id);
											onUploadTemplateModalOpen();
										}}
									>
										Upload
									</Button>
								</Stack>
							))}
						</Stack>
						{renderUploadTemplateModal()}
					</CardBody>
				</Card>
				<Card p="16px">
					<CardHeader mb="12px">
						<Text fontSize="lg" fontWeight={"bold"}>
							Associations
						</Text>
					</CardHeader>
					<CardBody px="5px">
						<Stack hidden>
							{associations.map((a) => (
								<Stack key={a.id}>
									<Stack direction={"row"}>
										<Text fontWeight={"bold"}>Name: </Text>
										<Text>{a.name}</Text>
									</Stack>
									<Stack direction={"row"}>
										<Text fontWeight={"bold"}>Description: </Text>
										<Text>{a.description}</Text>
									</Stack>
									<Stack direction={"row"}>
										<Text fontWeight={"bold"}>IBAN: </Text>
										<Text>{a.iban}</Text>
									</Stack>
									<Stack direction={"row"}>
										<Text fontWeight={"bold"}>Administrator Name: </Text>
										<Text>{a.administratorName}</Text>
									</Stack>
									<Stack direction={"row"}>
										<Text fontWeight={"bold"}>Email: </Text>
										<Text>{a.email}</Text>
									</Stack>
									<Stack direction={"row"}>
										<Text fontWeight={"bold"}>Phone Number: </Text>
										<Text>{a.phoneNumber}</Text>
									</Stack>
								</Stack>
							))}
						</Stack>
						<Button variant="outline" onClick={handleExportAssociations}>
							Export associations to CSV
						</Button>
						{renderUploadTemplateModal()}
					</CardBody>
				</Card>
			</Grid>
			<Grid templateColumns={{ sm: "1fr" }} gap="2">
				<Card p="16px">
					<CardHeader mb="12px">
						<Text fontSize="lg" fontWeight={"bold"}>
							Owner Plans
						</Text>
					</CardHeader>
					<CardBody px="5px">
						<Stack overflowX={"auto"}>
							<Button width="fit-content" colorScheme="blue" onClick={onAddOwnerPlanModalOpen}>
								<Text>Add Owner Plan</Text>
							</Button>
							{renderAddOwmerPlanModal()}
							{/* <TableContainer overflowY={"auto"}> */}
							<Table variant="simple" size="sm">
								<Thead position="sticky" top={0}>
									<Tr>
										<Th>{t("table.name")}</Th>
										<Th>{t("table.description")}</Th>
										<Th>{t("table.price")}</Th>
										<Th>{t("table.addedDate")}</Th>
										<Th></Th>
									</Tr>
								</Thead>
								<Tbody>
									{customOwnerPlans.map((row, index) => {
										return (
											<CustomOwnerPlansTableRow
												key={index}
												addedDate={row.addedDate}
												id={row.id}
												name={row.name}
												description={row.description}
												price={row.price}
												properties={properties.filter((p) => p.customOwnerPlans.includes(row.id))}
											/>
										);
									})}
									{customOwnerPlans.length === 0 && (
										<Tr>
											<Td colSpan={3}>{t("table.noData")}</Td>
										</Tr>
									)}
								</Tbody>
							</Table>
							{/* </TableContainer> */}
						</Stack>
					</CardBody>
				</Card>
			</Grid>
		</Stack>
	);
}

export default Profile;
