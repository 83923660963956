import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
	Flex,
	Grid,
	Stat,
	StatHelpText,
	StatLabel,
	StatNumber,
	useColorModeValue,
	Table,
	Tbody,
	Text,
	Th,
	Thead,
	Tr,
	useColorMode,
	Stack,
	Td,
	TableContainer,
	Badge,
} from "@chakra-ui/react";
import { Card, CardHeader, CardBody } from "@chakra-ui/react";
import Chart from "react-apexcharts";
import moment from "moment/moment";
import TicketsTableRow from "../../Components/TableRows/TicketsTableRow";
import { getPropertyAddress } from "../../utils/verifier";
import { useNavigate } from "react-router-dom";
import { documentTypes, fullDateFormat } from "../../utils/types";
import { selectActiveProperties } from "../../redux/user/userSlice";
import { useTranslation } from "react-i18next";
export default function Dashboard() {
	const textColor = useColorModeValue("gray.700", "white");
	const properties = useSelector(selectActiveProperties);
	const { t } = useTranslation();
	const name = useSelector((state) => state.user.user?.name);
	const email = useSelector((state) => state.user.user?.email);
	const phoneNumber = useSelector((state) => state.user.user?.phoneNumber);
	const { colorMode } = useColorMode();
	const securityDeposits = properties?.map((p) => p.securityDeposits).flat();
	const [tickets, setTickets] = useState();
	// const [availablePropertiesChart, setavailablePropertiesChart] = useState();
	const [monthlyRevenueChart, setmonthlyRevenueChart] = useState();
	const [monthlyAdministrationFeeChart, setMonthlyAdministrationFeeChart] = useState();
	const previousMonthRevenuePercentage = {
		ron: Math.round(
			(((monthlyRevenueChart?.series[0].data.slice().reverse()[0] * 100) / monthlyRevenueChart?.series[0].data.slice().reverse()[1] - 100) * 100) / 100
		),
		eur: Math.round(
			(((monthlyRevenueChart?.series[1].data.slice().reverse()[0] * 100) / monthlyRevenueChart?.series[1].data.slice().reverse()[1] - 100) * 100) / 100
		),
	};
	useEffect(() => {
		if (!properties) return;
		let mr = { ron: { months: [], revenue: [] }, eur: { months: [], revenue: [] } };
		for (let index = 0; index < 6; index++) {
			const month = moment().subtract(index, "months").format("M");
			mr.ron.months.unshift(moment().subtract(index, "months").format("MMMM"));
			mr.eur.months.unshift(moment().subtract(index, "months").format("MMMM"));
			let sRon = 0;
			let sEur = 0;
			for (let i = 0; i < properties.length; i++) {
				for (let j = 0; j < properties[i].payments.length; j++) {
					if (moment(properties[i].payments[j].paymentDate, "DD-MM-YYY").format("M") === month && properties[i].payments[j].type !== "administrationFee")
						if (properties[i].payments[j].currency === "ron")
							sRon += Number(properties[i].payments[j].amount) * (properties[i].payments[j].paymentDirection === "incoming" ? 1 : -1);
						else if (properties[i].payments[j].currency === "eur")
							sEur += Number(properties[i].payments[j].amount) * (properties[i].payments[j].paymentDirection === "incoming" ? 1 : -1);
				}
			}
			mr.ron.revenue.unshift(Number(sRon).toFixed(2));
			mr.eur.revenue.unshift(Number(sEur).toFixed(2));
		}
		let admFee = { ron: { months: [], revenue: [] }, eur: { months: [], revenue: [] } };
		for (let index = 0; index < 6; index++) {
			const month = moment().subtract(index, "months").format("M");
			admFee.ron.months.unshift(moment().subtract(index, "months").format("MMMM"));
			admFee.eur.months.unshift(moment().subtract(index, "months").format("MMMM"));
			let sRon = 0;
			let sEur = 0;
			for (let i = 0; i < properties.length; i++) {
				const property = properties[i];
				const ownerBankAccountCurrency = property.owner.bankDetails.currency;

				for (let j = 0; j < properties[i].payments.length; j++) {
					const payment = property.payments[j];
					if (moment(payment.paymentDate, "DD-MM-YYY").format("M") === month && payment.type === "administrationFee")
						if (ownerBankAccountCurrency === "ron") {
							if (payment.currency === "ron") sRon += Number(payment.amount);
							else if (payment.exchange?.currency === "ron") sRon += Number(payment.exchange.amount);
						} else {
							if (payment.currency === "eur") sEur += Number(payment.amount);
							else if (payment.exchange?.currency === "eur") sEur += Number(payment.exchange.amount);
						}
					// if (properties[i].payments[j].currency === "ron")
					// 	sRon += Number(properties[i].payments[j].amount) * (properties[i].payments[j].paymentDirection === "incoming" ? 1 : -1);
					// else if (properties[i].payments[j].currency === "eur")
					// 	sEur += Number(properties[i].payments[j].amount) * (properties[i].payments[j].paymentDirection === "incoming" ? 1 : -1);
				}
			}
			admFee.ron.revenue.unshift(Number(sRon).toFixed(2));
			admFee.eur.revenue.unshift(Number(sEur).toFixed(2));
		}
		const t = properties
			.map((p) =>
				p.tickets.map((t) => {
					return { ...t, address: getPropertyAddress(p), propertyId: p.id, owner: p.owner };
				})
			)
			.flat()
			.filter((t) => t.status.toLowerCase() !== "done");
		setTickets(t);
		// setavailablePropertiesChart({
		// 	series: [properties?.filter((p) => p.tenants.length).length, properties?.length - properties?.filter((p) => p.tenants.length).length],
		// 	options: {
		// 		colors: ["green", "red"],
		// 		fill: { colors: ["#48BB78", "#F56565"] },
		// 		labels: ["Occupied", "Vacant"],
		// 		legend: {
		// 			labels: { useSeriesColors: true },
		// 		},
		// 		responsive: [
		// 			{
		// 				breakpoint: 1980,
		// 				options: {
		// 					legend: {
		// 						position: "bottom",
		// 					},
		// 				},
		// 			},
		// 		],
		// 	},
		// });

		setmonthlyRevenueChart({
			options: {
				tooltip: {
					style: {
						backgroundColor: "#ccc !important",
						fontSize: "12px",
						fontFamily: undefined,
					},
					onDatasetHover: {
						style: {
							backgroundColor: "red !important",
							fontSize: "12px",
							fontFamily: undefined,
						},
					},
					// theme: colorMode,
				},
				xaxis: {
					// categories: ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
					categories: mr.ron.months,
					show: true,
					labels: {
						show: true,
						style: {
							fontSize: "12px",
						},
					},
					// axisBorder: {
					// 	show: false,
					// },
					axisTicks: {
						show: false,
					},
				},
				yaxis: {
					show: true,
					color: "#000",
					labels: {
						show: true,
						style: {
							fontSize: "14px",
						},
					},
				},
				grid: {
					show: true,
				},
				// fill: {
				// 	colors: "#fff",
				// },
				dataLabels: {
					enabled: false,
				},
				plotOptions: {
					bar: {
						borderRadius: 4,
						borderRadiusApplication: "end",
						columnWidth: "18px",
					},
				},
				responsive: [
					{
						breakpoint: 768,
						options: {
							plotOptions: {
								bar: {
									borderRadius: 0,
								},
							},
						},
					},
				],
			},
			series: [
				{
					data: mr.ron.revenue,
					name: "ron",
				},
				{
					data: mr.eur.revenue,
					name: "eur",
				},
			],
		});
		setMonthlyAdministrationFeeChart({
			options: {
				tooltip: {
					style: {
						backgroundColor: "#ccc !important",
						fontSize: "12px",
						fontFamily: undefined,
					},
					onDatasetHover: {
						style: {
							backgroundColor: "red !important",
							fontSize: "12px",
							fontFamily: undefined,
						},
					},
					// theme: colorMode,
				},
				xaxis: {
					// categories: ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
					categories: mr.ron.months,
					show: true,
					labels: {
						show: true,
						style: {
							fontSize: "12px",
						},
					},
					// axisBorder: {
					// 	show: false,
					// },
					axisTicks: {
						show: false,
					},
				},
				yaxis: {
					show: true,
					color: "#000",
					labels: {
						show: true,
						style: {
							fontSize: "14px",
						},
					},
				},
				grid: {
					show: true,
				},
				// fill: {
				// 	colors: "#fff",
				// },
				dataLabels: {
					enabled: false,
				},
				plotOptions: {
					bar: {
						borderRadius: 4,
						borderRadiusApplication: "end",
						columnWidth: "18px",
					},
				},
				responsive: [
					{
						breakpoint: 768,
						options: {
							plotOptions: {
								bar: {
									borderRadius: 0,
								},
							},
						},
					},
				],
			},
			series: [
				{
					data: admFee.ron.revenue,
					name: "ron",
				},
				{
					data: admFee.eur.revenue,
					name: "eur",
				},
			],
		});
	}, [properties]);
	const getSecurityDepositsAmount = (currency, location) => {
		if (location === "total")
			return securityDeposits.filter((sd) => sd.currency === currency && sd.location !== "returned").reduce((acc, sd) => acc + Number(sd.amount), 0);
		return securityDeposits.filter((sd) => sd.currency === currency && sd.location === location).reduce((acc, sd) => acc + Number(sd.amount), 0);
	};
	const expiringLeases = properties
		.map((property) => {
			const lease = property.documents.find((doc) => doc.type === "lease" && doc.active);
			const extension = property.documents.find((doc) => doc.type === "extension" && doc.active);
			if (!lease) return {};
			if (!extension) {
				// if (moment(lease.expirationDate).unix() < moment().unix()) return "expired";
				if (moment(lease.expirationDate).unix() - moment().unix() < 2592000)
					return {
						owner: property.owner.name,
						address: getPropertyAddress(property),
						expirationDate: lease.expirationDate,
						type: "lease",
						propertyId: property.id,
					};
				return {};
			}
			// if (moment(extension.expirationDate).unix() < moment().unix()) return "expired";
			if (moment(extension.expirationDate).unix() - moment().unix() < 2592000)
				return {
					owner: property.owner.name,
					address: getPropertyAddress(property),
					expirationDate: extension.expirationDate,
					type: "extension",
					propertyId: property.id,
				};
			return {};
		})
		.filter((e) => e.address)
		.sort((a, b) => moment(a.expirationDate).unix() - moment(b.expirationDate).unix());

	const expiringDocuments = properties
		.map((property) => {
			const documents = property.documents.filter((doc) => doc.active && doc.expirationDate && documentTypes.map((d) => d.id).includes(doc.type));
			const expDocs = documents.filter(
				(doc) => moment(doc.expirationDate).unix() - moment().unix() < (doc.type.toLowerCase().includes("insurance") ? 604800 : 2592000)
			);
			return expDocs.map((doc) => ({
				owner: property.owner.name,
				address: getPropertyAddress(property),
				expirationDate: doc.expirationDate,
				type: doc.type,
				propertyId: property.id,
			}));
		})
		.flat()
		.filter((e) => e.address)
		.sort((a, b) => moment(a.expirationDate).unix() - moment(b.expirationDate).unix());
	const navigate = useNavigate();
	const goToProperty = (id, tab) => {
		navigate(`/properties/view/${id}?tab=${tab}`);
	};
	const getDaysUntilExpiredBadge = (date) => {
		const daysUntilExpired = moment(date).diff(moment(), "days");
		if (daysUntilExpired < 0) return <Badge colorScheme="red">{moment(date).format(fullDateFormat)} - expired</Badge>;
		return (
			<Badge colorScheme="yellow">
				{moment(date).format(fullDateFormat)} ({daysUntilExpired} zile)
			</Badge>
		);
	};
	const hoverColor = useColorModeValue("gray.100", "gray.700");
	return (
		<React.Fragment>
			<Flex flexDirection="column" marginBottom={4}>
				<Grid templateColumns={{ md: "1fr", lg: "1.6fr 1.6fr 1fr" }} templateRows={{ md: "1fr auto", lg: "1fr" }} gap="24px">
					<Card>
						<CardHeader fontSize="sm" fontWeight="bold" pb=".1rem">
							{t("dashboard.expiringLeases")} ({expiringLeases.length})
						</CardHeader>
						<CardBody>
							<TableContainer maxHeight="400px" overflowY={"auto"}>
								<Table size="sm">
									<Thead>
										<Tr>
											<Th>{t("table.endDate")}</Th>
											<Th>{t("table.address")}</Th>
											<Th>{t("table.owner")}</Th>
										</Tr>
									</Thead>
									<Tbody>
										{expiringLeases.map((el) => (
											<Tr
												key={el.id}
												onClick={() => {
													goToProperty(el.propertyId, "tenants");
												}}
												_hover={{ bg: hoverColor, cursor: "pointer" }}
											>
												<Td>{getDaysUntilExpiredBadge(el.expirationDate)}</Td>
												<Td>{el.address}</Td>
												<Td>{el.owner}</Td>
											</Tr>
										))}
										{expiringLeases.length === 0 && (
											<Tr>
												<Td colSpan={3}>{t("table.noData")}</Td>
											</Tr>
										)}
									</Tbody>
								</Table>
							</TableContainer>
						</CardBody>
					</Card>
					{/* START EXPIRING DOCUMENTS */}
					<Card>
						<CardHeader fontSize="sm" fontWeight="bold" pb=".1rem">
							{t("dashboard.expiringDocuments")} ({expiringDocuments.length})
						</CardHeader>
						<CardBody>
							<TableContainer maxHeight="400px" overflowY={"auto"}>
								<Table size="sm">
									<Thead>
										<Tr>
											<Th>{t("table.endDate")}</Th>
											<Th>{t("table.type")}</Th>
											<Th>{t("table.address")}</Th>
											<Th>{t("table.owner")}</Th>
										</Tr>
									</Thead>
									<Tbody>
										{expiringDocuments.map((el) => (
											<Tr
												key={el.id}
												onClick={() => {
													goToProperty(el.propertyId, "documents");
												}}
												_hover={{ bg: hoverColor, cursor: "pointer" }}
											>
												<Td>{getDaysUntilExpiredBadge(el.expirationDate)}</Td>
												<Td>{t(`types.${el.type}`)}</Td>
												<Td>{el.address}</Td>
												<Td>{el.owner}</Td>
											</Tr>
										))}
										{expiringLeases.length === 0 && (
											<Tr>
												<Td colSpan={3}>{t("table.noData")}</Td>
											</Tr>
										)}
									</Tbody>
								</Table>
							</TableContainer>
						</CardBody>
					</Card>
					{/* END EXPIRING DOCUMENTS */}
					<Card hidden>
						<CardBody>
							<Flex flexDirection="row" align="center" justify="center" w="100%">
								<Stat me="auto">
									<StatLabel fontSize="sm" fontWeight="bold" pb=".1rem">
										{monthlyRevenueChart?.options.xaxis.categories.slice().reverse()[0]} {t("dashboard.revenue")}
									</StatLabel>
									<Stack direction="column">
										<Flex>
											<StatNumber fontSize="lg">{monthlyRevenueChart?.series[0].data.slice().reverse()[0]} RON</StatNumber>
											<StatHelpText
												alignSelf="flex-end"
												justifySelf="flex-end"
												m="0px"
												color={previousMonthRevenuePercentage.ron >= 0 ? "green" : "red"}
												fontWeight="bold"
												ps="3px"
												fontSize="md"
											>
												{/* {previousMonthRevenuePercentage.ron >= 0 ? "+" : "-"} */}
												{previousMonthRevenuePercentage.ron === Number.NEGATIVE_INFINITY ||
												previousMonthRevenuePercentage.ron === Number.POSITIVE_INFINITY ||
												isNaN(previousMonthRevenuePercentage.ron)
													? "100"
													: previousMonthRevenuePercentage.ron}
												%
											</StatHelpText>
										</Flex>
										<Flex>
											<StatNumber fontSize="lg" color={textColor}>
												{monthlyRevenueChart?.series[1].data.slice().reverse()[0]} EUR
											</StatNumber>
											<StatHelpText
												alignSelf="flex-end"
												justifySelf="flex-end"
												m="0px"
												color={previousMonthRevenuePercentage.eur >= 0 ? "green" : "red"}
												fontWeight="bold"
												ps="3px"
												fontSize="md"
											>
												{/* {previousMonthRevenuePercentage.eur >= 0 ? "+" : "-"} */}
												{previousMonthRevenuePercentage.eur === Number.POSITIVE_INFINITY ||
												previousMonthRevenuePercentage.eur === Number.NEGATIVE_INFINITY ||
												isNaN(previousMonthRevenuePercentage.eur)
													? "100"
													: previousMonthRevenuePercentage.eur}
												%
											</StatHelpText>
										</Flex>
									</Stack>
								</Stat>
							</Flex>
						</CardBody>
					</Card>
					<Card hidden>
						<CardHeader fontSize="sm" fontWeight="bold" pb=".1rem">
							{t("dashboard.properties")}
						</CardHeader>
						<CardBody>
							{/* <Flex flexDirection="row" align="center" justify="center" w="100%">
								<Stat me="auto">
									<Flex>
										{properties.length === 0 ? (
											<Text>No properties</Text>
										) : (
											availablePropertiesChart && (
												<Chart type="pie" width={200} series={availablePropertiesChart.series} options={availablePropertiesChart.options} />
											)
										)}
									</Flex>
								</Stat>
							</Flex> */}
							<Stack>
								<Stack direction={"row"}>
									<Text>{t("dashboard.occupied")}:</Text>
									<Text fontWeight={"bold"}>
										{properties.filter((p) => p.tenants.filter((t) => !t.former).length > 0 || p.specs.apartmentFeatures.type === "garage").length}
									</Text>
								</Stack>
								<Stack direction={"row"}>
									<Text>{t("dashboard.empty")}:</Text>
									<Text fontWeight={"bold"}>
										{properties.filter((p) => p.tenants.filter((t) => !t.former).length === 0 && p.specs.apartmentFeatures.type !== "garage").length}
									</Text>
								</Stack>
							</Stack>
						</CardBody>
					</Card>
					<Card>
						<CardHeader fontSize="sm" fontWeight="bold" pb=".1rem">
							{t("dashboard.securityDeposits")}
						</CardHeader>
						<CardBody>
							<TableContainer>
								<Table variant="simple" size="sm">
									<Thead>
										<Tr>
											<Th></Th>
											<Th>EUR</Th>
											<Th>RON</Th>
										</Tr>
									</Thead>
									<Tbody>
										{["custodyCash", "custodyBank", "owner", "total", "returned", "returnedTenant"].map((l, index) => (
											<Tr key={index} fontWeight={l === "total" ? "bold" : "normal"}>
												<Td>{t(`types.${l}`)}</Td>
												<Td>{Number(getSecurityDepositsAmount("eur", l)).toFixed(2)}</Td>
												<Td>{Number(getSecurityDepositsAmount("ron", l)).toFixed(2)}</Td>
											</Tr>
										))}
									</Tbody>
								</Table>
							</TableContainer>
						</CardBody>
					</Card>
				</Grid>
			</Flex>
			<Flex flexDirection="column" marginBottom={4}>
				<Grid templateColumns={{ md: "1fr", lg: "1.8fr 1.2fr" }} templateRows={{ md: "1fr auto", lg: "1fr" }} gap="24px">
					<Card p="1.2rem">
						<CardHeader>
							<Text fontSize="xl" fontWeight="bold">
								{t("dashboard.monthlyRevenue")}
							</Text>
						</CardHeader>
						<CardBody style={{ width: "100%" }}>
							{monthlyRevenueChart && (
								<Chart
									type="bar"
									options={{
										...monthlyRevenueChart.options,
										xaxis: {
											...monthlyRevenueChart.options.xaxis,
											labels: { ...monthlyRevenueChart.options.xaxis.labels, style: { ...monthlyRevenueChart.options.xaxis.labels.style, colors: textColor } },
										},
										yaxis: {
											...monthlyRevenueChart.options.yaxis,
											labels: { ...monthlyRevenueChart.options.yaxis.labels, style: { ...monthlyRevenueChart.options.yaxis.labels.style, colors: textColor } },
										},
										tooltip: { ...monthlyRevenueChart.options.tooltip, theme: colorMode },
									}}
									series={monthlyRevenueChart.series}
									style={{ width: "100%" }}
									height="250"
								/>
							)}
						</CardBody>
					</Card>
					<Card p="1rem">
						<CardHeader>
							<Text fontSize="xl" color={textColor} fontWeight="bold">
								{t("dashboard.administrationFee")}
							</Text>
						</CardHeader>
						<CardBody justifyContent="center" alignItems="center" height="100%">
							{monthlyAdministrationFeeChart && (
								<Chart
									type="bar"
									options={{
										...monthlyAdministrationFeeChart.options,
										xaxis: {
											...monthlyAdministrationFeeChart.options.xaxis,
											labels: {
												...monthlyAdministrationFeeChart.options.xaxis.labels,
												style: { ...monthlyAdministrationFeeChart.options.xaxis.labels.style, colors: textColor },
											},
										},
										yaxis: {
											...monthlyAdministrationFeeChart.options.yaxis,
											labels: {
												...monthlyAdministrationFeeChart.options.yaxis.labels,
												style: { ...monthlyAdministrationFeeChart.options.yaxis.labels.style, colors: textColor },
											},
										},
										tooltip: { ...monthlyAdministrationFeeChart.options.tooltip, theme: colorMode },
									}}
									series={monthlyAdministrationFeeChart.series}
									style={{ width: "100%" }}
									height="250"
								/>
							)}
							{/* {properties.length === 0 ? (
								<Text>No properties</Text>
							) : (
								availablePropertiesChart && <Chart type="pie" series={availablePropertiesChart.series} options={availablePropertiesChart.options} width="350" />
							)} */}
						</CardBody>
					</Card>
				</Grid>
			</Flex>

			<Grid marginBottom={4}>
				<Card overflowX={{ sm: "scroll", xl: "hidden" }}>
					<CardHeader>
						<Text fontSize="xl" fontWeight="bold">
							{t("dashboard.openTickets")}
						</Text>
					</CardHeader>
					<CardBody>
						<TableContainer>
							<Table size="sm">
								<Thead>
									<Tr>
										<Th>{t("table.addedDate")}</Th>
										<Th>{t("table.owner")}</Th>
										<Th>{t("table.address")}</Th>
										<Th>{t("table.status")}</Th>
										<Th>{t("table.title")}</Th>
										<Th>{t("table.author")}</Th>
										<Th></Th>
									</Tr>
								</Thead>
								<Tbody>
									{tickets?.map((t, index) => (
										<TicketsTableRow key={index} row={t} author={{ name: name, phoneNumber, email }} owner={t.owner} />
									))}

									{tickets?.length === 0 && (
										<Tr>
											<Td colSpan={7}>{t("table.noData")}</Td>
										</Tr>
									)}
								</Tbody>
							</Table>
						</TableContainer>
					</CardBody>
				</Card>
			</Grid>
		</React.Fragment>
	);
}
