// Import necessary libraries
import React from "react";
import { Button, Flex, Heading, Text, Box, Badge } from "@chakra-ui/react";
import { FaExclamationCircle } from "react-icons/fa"; // Import an icon for additional visual appeal

// FallbackComponent for ErrorBoundary with Refresh Button
export default function FallbackComponent() {
	const resetErrorBoundary = () => {
		// Go to the home URL of the app without any / or /home
		window.location.href = window.location.origin;
	};

	return (
		<Flex
			direction="column"
			align="center"
			justify="center"
			h="100vh"
			bg="gray.100"
			p={8} // Add padding for better spacing
		>
			<Box color="red.500" mb={4} fontSize="6xl">
				<FaExclamationCircle />
			</Box>
			<Badge colorScheme="red" mb={4} fontSize={"xl"}>
				Oops! Something went wrong.
			</Badge>

			<Text mb={8} textAlign="center" fontSize="xl">
				We apologize for the inconvenience. Please try refreshing the page.
			</Text>
			<Button
				colorScheme="teal"
				size="lg"
				px={8} // Add horizontal padding for a larger button
				py={4} // Add vertical padding for a larger button
				fontSize="xl" // Increase font size
				onClick={resetErrorBoundary}
			>
				Refresh
			</Button>
		</Flex>
	);
}
