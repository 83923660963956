import axios from "axios";
import { config } from "./config";
let axiosHeaders = {
	Authorization: localStorage.getItem("authorization"),
	idToken: localStorage.getItem("idtoken"),
};
const getHeaders = () => {
	return axiosHeaders;
};
const setHeaders = (h) => {
	axiosHeaders = h;
	localStorage.removeItem("authorization");
	localStorage.setItem("authorization", h.Authorization);
	localStorage.removeItem("idtoken");
	localStorage.setItem("idtoken", h.idToken);
	API = axios.create({
		baseURL: config.apiUrl,
		headers: getHeaders(),
	});
};
let API = axios.create({
	baseURL: config.apiUrl,
	headers: getHeaders(),
});

export { API, setHeaders, getHeaders };
