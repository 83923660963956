import React, { useState } from "react";
import {
	Flex,
	Table,
	Tbody,
	Text,
	Th,
	Thead,
	Tr,
	useColorModeValue,
	useDisclosure,
	Box,
	Button,
	Stack,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	TableContainer,
	Td,
	Wrap,
	WrapItem,
} from "@chakra-ui/react";
import { FormControl, FormLabel } from "@chakra-ui/react";
import { Card, CardHeader, CardBody } from "@chakra-ui/react";
import PropertiesTableRow from "../../Components/TableRows/PropertiesTableRow";
import Dropzone from "../../Components/Dropzone/Dropzone";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getPropertyAddress } from "../../utils/verifier";
import { selectActiveProperties, selectArchivedProperties, uploadSmartContractAsync } from "../../redux/user/userSlice";
import { _arrayBufferToBase64 } from "../../utils/convertors";
import { getAdministrationStatus, getFinancialStatementStatus, getLeaseStatus, getPropertyStatusText, removeAccents } from "../../utils/utils";
import Loader from "../../Components/Loader/Loader";
import NewPropertyModal from "./NewPropertyModal";
import useDebounce from "../../Components/Hooks/useDebounce";
import SearchBox from "./SearchBox";
import TablePagination from "../../Components/Pagination/TablePagination";
import ArchivedPropertiesTableRow from "../../Components/TableRows/ArchivedPropertiesTableRow";
import { useTranslation } from "react-i18next";

export default function Properties() {
	const { t } = useTranslation();
	const textColor = useColorModeValue("gray.700", "white");
	const [loading, setLoading] = useState(true);
	// eslint-disable-next-line no-unused-vars
	const { isOpen: isUploadContractOpen, onOpen: onUploadContractOpen, onClose: onUploadContractClose } = useDisclosure();
	const properties = useSelector(selectActiveProperties);
	const archivedProperties = useSelector(selectArchivedProperties);
	const [smartContract, setSmartContract] = useState();
	const [query, setquery] = useState("");
	const debouncedValue = removeAccents(useDebounce(query, 500));
	useEffect(() => {
		setLoading(false);
	}, []);

	const dispatch = useDispatch();

	const handleSearch = (query) => {
		setquery(query);
	};
	const onDocumentDrop = async (acceptedFiles) => {
		console.log(acceptedFiles);
		const buffer = await acceptedFiles[0].arrayBuffer();
		const documentName = acceptedFiles[0].name;
		const contentType = acceptedFiles[0].type;
		setSmartContract({ documentData: _arrayBufferToBase64(buffer), documentName, contentType });
	};
	const propertiesFilterFunction = (p) => {
		const address = getPropertyAddress(p);
		const queryValue = debouncedValue.toLowerCase().trim();
		const association = p.association;
		return (
			p.id?.toLowerCase() === queryValue ||
			removeAccents(address)?.toLowerCase()?.includes(queryValue) ||
			removeAccents(p.owner.name)?.toLowerCase()?.includes(queryValue) ||
			removeAccents(p.owner.email)?.toLowerCase()?.includes(queryValue) ||
			p.tenants?.find((t) => removeAccents(t.name)?.toLowerCase()?.includes(queryValue)) ||
			p.tenants?.find((t) => removeAccents(t.email)?.toLowerCase()?.includes(queryValue)) ||
			p.formerTenants?.find((t) => removeAccents(t.name)?.toLowerCase()?.includes(queryValue)) ||
			p.formerTenants?.find((t) => removeAccents(t.email)?.toLowerCase()?.includes(queryValue)) ||
			p.contacts?.find((t) => removeAccents(t.name)?.toLowerCase()?.includes(queryValue)) ||
			p.utilities.gas?.paymentCode?.toLowerCase()?.includes(queryValue) ||
			p.utilities.gas?.clientCode?.toLowerCase()?.includes(queryValue) ||
			p.utilities.water?.nlcCode?.toLowerCase()?.includes(queryValue) ||
			p.utilities.water?.atmCode?.toLowerCase()?.includes(queryValue) ||
			getPropertyStatusText(p.admStatus)?.toLowerCase()?.includes(queryValue) ||
			getPropertyStatusText(p.leaseStatus)?.toLowerCase()?.includes(queryValue) ||
			getPropertyStatusText(p.financialStatementStatus)?.toLowerCase()?.includes(queryValue) ||
			association?.name?.toLowerCase()?.includes(queryValue) ||
			association?.administratorName?.toLowerCase()?.includes(queryValue) ||
			association?.email?.toLowerCase()?.includes(queryValue)
		);
	};
	const addDocument = () => {
		console.log(smartContract);
		//dispatch add property by uploading a smart contract
		// add property, add document, add owner
		dispatch(uploadSmartContractAsync(smartContract));
	};
	//table pagination
	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 10; // Number of items to display per page

	// Calculate the starting and ending indices for the current page
	const startIndex = (currentPage - 1) * itemsPerPage;
	const endIndex = currentPage * itemsPerPage;

	// Assuming `properties` contains your data
	const filteredProperties = properties
		.map((p) => {
			return {
				...p,
				admStatus: getAdministrationStatus(p),
				leaseStatus: getLeaseStatus(p),
				financialStatementStatus: getFinancialStatementStatus(p),
			};
		})
		?.filter(propertiesFilterFunction);
	const dataToShow = filteredProperties.slice(startIndex, endIndex);
	// Function to handle page change
	const handlePageChange = (newPage) => {
		setCurrentPage(newPage);
	};

	const renderUploadContractModal = () => (
		<Modal isOpen={isUploadContractOpen} onClose={onUploadContractClose} size={"3xl"}>
			<ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
			<ModalContent>
				<ModalHeader>
					<Text>Add a Smart Contract</Text>
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Stack>
						<FormControl>
							<FormLabel>Smart Contract:</FormLabel>
							<Dropzone
								onDrop={onDocumentDrop}
								accept={{
									"application/pdf": [".pdf"],
									"application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
									"application/msword": [".doc"],
								}}
								description="Upload PDF,DOC or DOCX Format"
								maxFiles={1}
							/>
						</FormControl>
					</Stack>
				</ModalBody>
				<ModalFooter>
					<Button
						colorScheme="red"
						onClick={() => {
							onUploadContractClose();
						}}
						mr={3}
					>
						{t("misc.cancel")}
					</Button>
					<Button colorScheme="blue" onClick={addDocument}>
						Add
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
	return (
		<>
			<Stack direction="column" spacing={2}>
				{/* <Flex direction="column" pt={{ base: "120px", md: "75px" }}> */}
				<Card>
					<CardHeader fontSize="xl" color={textColor} fontWeight="bold">
						<Wrap align={"center"} justify={"space-between"}>
							<WrapItem>
								<Stack direction="row" alignItems="center">
									<Text>{t("properties.properties")}</Text>
									<Box>
										{/* <Input type="text" placeholder="Search..." value={query} onChange={handleSearchChange} /> */}
										<SearchBox
											handleSearch={handleSearch}
											searchOptions={[
												{
													label: "Address",
													description: "Search by address",
												},
												{
													label: "Owner",
													description: "Search by owner name and email",
												},
												{
													label: "Tenant",
													description: "Search by tenant name and email",
												},
												{
													label: "Former Tenant",
													description: "Search by former tenant name and email",
												},
												{
													label: "Contact",
													description: "Search by contact name",
												},
												{
													label: "Gas Payment Code",
													description: "Search by gas payment code",
												},
												{
													label: "Gas Client Code",
													description: "Search by gas client code",
												},
												{
													label: "Water NLC Code",
													description: "Search by water NLC code",
												},
												{
													label: "Water ATM Code",
													description: "Search by water ATM code",
												},
												{
													label: "Administration Status",
													description: "Search by administration status",
												},
												{
													label: "Lease Status",
													description: "Search by lease status",
												},
												{
													label: "Statement Status",
													description: "Search by financial statement status",
												},
												{
													label: "Association",
													description: "Search by association name",
												},
												{
													label: "Association Administrator",
													description: "Search by association administrator name",
												},
												{
													label: "Association Email",
													description: "Search by association email",
												},
											]}
										/>
									</Box>
								</Stack>
							</WrapItem>
							<WrapItem>
								<Stack direction="row">
									<Text fontWeight={"normal"}>{t("properties.total")}: </Text>
									<Text>
										{properties.filter((p) => p.tenants.filter((t) => !t.former).length > 0 || p.specs.apartmentFeatures.type === "garage").length}{" "}
										{t("properties.occupied")},
									</Text>
									<Text>
										{properties.filter((p) => p.tenants.filter((t) => !t.former).length === 0 && p.specs.apartmentFeatures.type !== "garage").length}{" "}
										{t("properties.empty")}
									</Text>
								</Stack>
							</WrapItem>
						</Wrap>
					</CardHeader>
					<CardBody overflowX="auto">
						<Stack spacing={4}>
							<NewPropertyModal />
							{loading ? (
								<Loader text="Loading Properties..." />
							) : (
								<TableContainer>
									<Table variant="simple" size="small">
										<Thead position="sticky" top={0}>
											<Tr my=".8rem" pl="0px">
												<Th pl="0px">{t("table.address")}</Th>
												<Th>{t("table.owner")}</Th>
												<Th colSpan={3} textAlign={"center"}>
													{t("table.status")}
												</Th>
												<Th>{t("table.rent")}</Th>

												<Th>{t("table.tenants")}</Th>
												<Th></Th>
											</Tr>
											<Tr my=".8rem" pl="0px">
												<Th></Th>
												<Th></Th>
												<Th>{t("table.administration")}</Th>
												<Th>{t("table.lease")}</Th>
												<Th>{t("table.statements")}</Th>
												<Th></Th>
												<Th></Th>
												<Th></Th>
											</Tr>
										</Thead>
										<Tbody>
											{dataToShow.map((row, index) => {
												return <PropertiesTableRow key={index} row={row} />;
											})}
										</Tbody>
									</Table>
								</TableContainer>
							)}
							<TablePagination totalItems={filteredProperties.length} itemsPerPage={itemsPerPage} onPageChange={handlePageChange} />
						</Stack>
					</CardBody>
				</Card>
				<Card>
					<CardHeader fontSize="xl" color={textColor} fontWeight="bold">
						<Text>{t("properties.archivedProperties")}</Text>
					</CardHeader>
					<CardBody overflowX="auto">
						<Stack spacing={4}>
							{loading ? (
								<Loader text={t("misc.loading")} />
							) : (
								<TableContainer>
									<Table variant="simple" size="small">
										<Thead position="sticky" top={0}>
											<Tr>
												<Th>{t("table.address")}</Th>
												<Th>{t("table.owner")}</Th>
												<Th></Th>
											</Tr>
										</Thead>
										<Tbody>
											{archivedProperties.map((row, index) => {
												return <ArchivedPropertiesTableRow key={index} row={row} />;
											})}
											{archivedProperties.length === 0 && (
												<Tr>
													<Td colSpan={3}>{t("table.noData")}</Td>
												</Tr>
											)}
										</Tbody>
									</Table>
								</TableContainer>
							)}
							{/* <TablePagination totalItems={filteredProperties.length} itemsPerPage={itemsPerPage} onPageChange={handlePageChange} /> */}
						</Stack>
					</CardBody>
				</Card>
			</Stack>
			{/* {renderAddPropertyModal()} */}
			{renderUploadContractModal()}
		</>
	);
}
