import React from "react";
import {
	Button,
	Modal,
	ModalOverlay,
	ModalHeader,
	ModalContent,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	Td,
	Text,
	Tr,
	Tooltip,
	Icon,
	useDisclosure,
	Stack,
} from "@chakra-ui/react";
import moment from "moment";
import { DeleteIcon } from "@chakra-ui/icons";
import { useDispatch } from "react-redux";
import { removeActivityAsync } from "../../redux/user/userSlice";
import { fullDateTimeFormat } from "../../utils/types";
import { useTranslation } from "react-i18next";
export default function ActivitiesTableRow({ activity, property }) {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const deleteActivity = (activityId) => {
		const payload = { activityId, propertyId: property.id };
		console.log("delete: ", payload);
		dispatch(removeActivityAsync(payload));
	};
	const saveActivity = () => {};
	const renderActivityModal = () => (
		<Modal isOpen={isOpen} onClose={onClose} size={"3xl"}>
			<ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
			<ModalContent>
				<ModalHeader>
					<Text>Activity details</Text>
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Stack>
						<Stack direction="row">
							<Text fontWeight="bold">Date Added:</Text>
							<Text>{moment(activity.addedDate).format(fullDateTimeFormat)}</Text>
						</Stack>
						<Stack direction="row">
							<Text fontWeight="bold">Type:</Text>
							<Text>{activity.type}</Text>
						</Stack>
						<Stack direction="row">
							<Text fontWeight="bold">Title:</Text>
							<Text>{activity.title}</Text>
						</Stack>
						<Stack direction="row">
							<Text fontWeight="bold">Description:</Text>
							<Text>{activity.description}</Text>
						</Stack>
					</Stack>
				</ModalBody>
				<ModalFooter>
					<Button colorScheme="red" onClick={onClose} mr={3}>
						{t("misc.cancel")}
					</Button>
					<Button colorScheme="blue" onClick={saveActivity}>
						{t("misc.save")}
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
	return (
		<Tr>
			<Td>
				<Text>{moment(activity.addedDate).format(fullDateTimeFormat)}</Text>
			</Td>
			<Td>
				<Text>{activity.title}</Text>
			</Td>
			<Td>
				<Text>{activity.type}</Text>
			</Td>
			<Td>
				<Text>{activity.description}</Text>
			</Td>
			<Td>
				<Stack direction="row">
					<Button onClick={onOpen}>View</Button>
					{renderActivityModal()}
					<Button
						colorScheme={"red"}
						onClick={() => {
							deleteActivity(activity.id);
						}}
					>
						<Tooltip label="Delete">
							<Icon as={DeleteIcon} boxSize={6}></Icon>
						</Tooltip>
					</Button>
				</Stack>
			</Td>
		</Tr>
	);
}
