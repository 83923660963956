import React, { useEffect, useState } from "react";
import {
	Accordion,
	AccordionItem,
	AccordionButton,
	AccordionPanel,
	AccordionIcon,
	Flex,
	Box,
	useColorModeValue,
	Grid,
	FormControl,
	FormLabel,
	Input,
	FormHelperText,
	Textarea,
	Stack,
	Select,
	Button,
	useToast,
	Text,
	Checkbox,
} from "@chakra-ui/react";
import { Card, CardHeader, CardBody, CardFooter } from "@chakra-ui/react";
import { Radio, RadioGroup } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { selectActiveProperties, sendMassNotificationsAsync } from "../../redux/user/userSlice";
import { getPropertyAddress } from "../../utils/verifier";
import moment from "moment";
import { fullDateTimeFormat } from "../../utils/types";
export default function MassNotification() {
	const textColor = useColorModeValue("gray.700", "white");
	// const notifications = useSelector((state) => state.user.user?.notifications);
	const initialNotificationInformation = {
		title: "",
		message: "",
		recipients: { type: "property", notifyOwner: false, propertyId: "" },
	};
	const [value, setValue] = useState("property");
	const [notificationInformation, setNotificationInformation] = useState(initialNotificationInformation);
	const properties = useSelector(selectActiveProperties);
	const notifications = properties
		.map(
			(p) =>
				p.notifications?.map((n) => {
					return { ...n, address: getPropertyAddress(p) };
				}) || []
		)
		.flat();
	const massNotifications = useSelector((state) => state.user.user?.notifications);
	console.log(massNotifications);
	const dispatch = useDispatch();
	const toast = useToast();
	useEffect(() => {
		console.log(notifications);
	}, [notifications]);
	const handleSendNotification = () => {
		console.log("sending notification:", notificationInformation);
		dispatch(sendMassNotificationsAsync({ notification: notificationInformation }))
			.then((r) => {
				console.log(r);
				if (r.error?.message)
					toast({
						title: "Error encountered",
						description: r.error.message,
						status: "error",
						duration: 9000,
						isClosable: true,
					});
				else {
					toast({
						title: "Success!",
						description: "Mass notification sent!",
						status: "success",
						duration: 2000,
						isClosable: true,
					});
				}
			})
			.finally(() => {
				setNotificationInformation(initialNotificationInformation);
				setValue("property");
			});
	};
	const handleNotificationInfoChange = (e) => {
		console.log(e.target.value);
		setNotificationInformation({ ...notificationInformation, [e.target.name]: e.target.value });
		console.log("sending notification:", notificationInformation);
	};
	useEffect(() => {
		console.log(notificationInformation);
	}, [notificationInformation]);

	return (
		<Flex direction="column">
			<Grid templateColumns={{ md: "1fr", lg: "1fr" }} templateRows={{ md: "1fr auto", lg: "1fr" }} gap="24px">
				<Card>
					<CardHeader>
						<Text fontSize="xl" color={textColor} fontWeight="bold">
							Emit a Notification
						</Text>
					</CardHeader>
					<CardBody>
						<Stack direction={"column"} width={{ sm: "100%", md: "50%" }} spacing={2}>
							<FormControl>
								<FormLabel>Title</FormLabel>
								<Input type="text" placeholder="Title" name="title" value={notificationInformation.title} onChange={handleNotificationInfoChange} />
								{/* <FormHelperText>We'll never share your email.</FormHelperText> */}
							</FormControl>
							<FormControl>
								<FormLabel>Message</FormLabel>
								<Textarea placeholder="Message..." name="message" value={notificationInformation.message} onChange={handleNotificationInfoChange} />
								{/* <FormHelperText>We'll never share your email.</FormHelperText> */}
							</FormControl>
							<FormControl>
								<RadioGroup
									onChange={(newValue) => {
										setValue(newValue);
										setNotificationInformation({ ...notificationInformation, recipients: { type: "users", notifyOwners: false, notifyTenants: false } });
									}}
									value={value}
								>
									<Stack direction="row">
										<Radio value="property">Property</Radio>
										<Radio value="users">Users</Radio>
									</Stack>
								</RadioGroup>
							</FormControl>
							{value === "property" ? (
								<>
									<FormControl>
										<FormLabel>Property</FormLabel>
										<Select
											value={notificationInformation.recipients.propertyId}
											name="propertyId"
											placeholder="Select a property"
											onChange={(e) => {
												// handleNotificationInfoChange(e);
												setNotificationInformation({
													...notificationInformation,
													recipients: { type: "property", propertyId: e.target.value, notifyOwner: false },
												});
											}}
										>
											{properties
												?.filter((p) => p.tenants.filter((t) => !t.former).length != 0)
												.map((p, index) => (
													<option value={p.id} key={index}>
														{getPropertyAddress(p)}
													</option>
												))}
										</Select>
									</FormControl>
									<Checkbox
										isChecked={notificationInformation.recipients.notifyOwner}
										name="notifyOwner"
										onChange={(e) => {
											setNotificationInformation({
												...notificationInformation,
												recipients: { ...notificationInformation.recipients, notifyOwner: e.target.checked },
											});
										}}
									>
										<Text>Notify Owner?</Text>
									</Checkbox>
								</>
							) : (
								<Stack>
									<Checkbox
										name="notifyOwners"
										onChange={(e) => {
											setNotificationInformation({
												...notificationInformation,
												recipients: { ...notificationInformation.recipients, notifyOwners: e.target.checked },
											});
										}}
									>
										<Text>All Owners</Text>
									</Checkbox>
									<Checkbox
										name="notifyTenants"
										onChange={(e) => {
											setNotificationInformation({
												...notificationInformation,
												recipients: { ...notificationInformation.recipients, notifyTenants: e.target.checked },
											});
										}}
									>
										<Text>All Tenants</Text>
									</Checkbox>
								</Stack>
							)}
							<Button mt={4} colorScheme="blue" width={"fit-content"} onClick={handleSendNotification}>
								Send
							</Button>
						</Stack>
					</CardBody>
				</Card>
				<Card>
					<CardHeader>
						<Text fontSize="xl" color={textColor} fontWeight="bold">
							Notifications
						</Text>
					</CardHeader>
					<CardBody>
						<Accordion allowMultiple width="100%">
							{notifications
								.slice()
								.reverse()
								.map((n, index) => (
									<AccordionItem key={index}>
										<AccordionButton>
											<Flex flex="1" direction={"column"}>
												<Text textAlign="left" fontSize="md">
													{n.title}
												</Text>
												<Text textAlign="left" fontSize={"small"}>
													{n.address}
												</Text>
											</Flex>
											<AccordionIcon />
										</AccordionButton>

										<AccordionPanel pb={4}>
											<Stack spacing={2}>
												<Box>
													<Text fontSize={"large"}>Message:</Text>
													<Text>{n.text || n.message}</Text>
												</Box>
												<Box>
													<Text fontSize={"large"}>Recipients:</Text>
													<Text>{n.recipients?.join(", ")}</Text>
												</Box>

												<Text fontSize={"small"}>~ {moment(n.timestamp).format("DD MMM YYYY - HH:mm")}</Text>
											</Stack>
										</AccordionPanel>
									</AccordionItem>
								))}
							{notifications.length === 0 && <Text>No notifications sent yet.</Text>}
						</Accordion>
					</CardBody>
				</Card>
				<Card>
					<CardHeader>
						<Text fontSize="xl" color={textColor} fontWeight="bold">
							Mass Notifications
						</Text>
					</CardHeader>
					<CardBody>
						<Accordion allowMultiple width="100%">
							{massNotifications
								.slice()
								.reverse()
								.map((n, index) => (
									<AccordionItem key={index}>
										<AccordionButton>
											<Flex flex="1" direction={"column"}>
												<Text textAlign="left" fontSize="md">
													{n.title} - {moment(n.timestamp).format(fullDateTimeFormat)}
												</Text>
												<Text textAlign="left" fontSize={"small"}>
													{n.notifyOwners ? "All Owners" : ""}
												</Text>
												<Text textAlign="left" fontSize={"small"}>
													{n.notifyTenants ? "All Tenants" : ""}
												</Text>
											</Flex>
											<AccordionIcon />
										</AccordionButton>

										<AccordionPanel pb={4}>
											<Stack spacing={2}>
												<Box>
													<Text fontSize={"large"}>Message:</Text>
													<Text>{n.text || n.message}</Text>
												</Box>
												<Box>
													<Text fontSize={"large"}>Recipients:</Text>
													<Text>{n.recipients?.join(", ")}</Text>
												</Box>

												<Text fontSize={"small"}>~ {moment(n.timestamp).format("DD MMM YYYY - HH:mm")}</Text>
											</Stack>
										</AccordionPanel>
									</AccordionItem>
								))}
							{massNotifications.length === 0 && <Text>No mass notifications sent yet.</Text>}
						</Accordion>
					</CardBody>
				</Card>
			</Grid>
		</Flex>
	);
}
