import {
	Badge,
	Button,
	Flex,
	Modal,
	ModalOverlay,
	ModalHeader,
	ModalContent,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	Td,
	Text,
	Tr,
	List,
	ListItem,
	Box,
	Textarea,
	UnorderedList,
	useToast,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
} from "@chakra-ui/react";
import Dropzone from "../Dropzone/Dropzone";

import { FormControl, FormLabel, Heading, Stack } from "@chakra-ui/react";
import React, { useState } from "react";
import { useDisclosure } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { addReplyToTicketAsync, markTicketAsDoneAsync, removeTicketAsync } from "../../redux/user/userSlice";
import { API } from "../../utils/http";
import { useEffect } from "react";

import moment from "moment";
import Gallery from "../Gallery/Gallery";
import { fullDateTimeFormat } from "../../utils/types";
import { _arrayBufferToBase64 } from "../../utils/convertors";
import { DownloadIcon, HamburgerIcon, Icon } from "@chakra-ui/icons";
import { useTranslation } from "react-i18next";

function TicketsTableRow(props) {
	const { row, author, owner } = props;
	const { t } = useTranslation();
	const newStatusBackground = "red";
	const pendingStatusBackground = "yellow";
	const resolvedStatusBackground = "green";
	const { isOpen: isDeleteConfirmationOpen, onOpen: onDeleteConfirmationOpen, onClose: onDeleteConfirmationClose } = useDisclosure();

	const { isOpen, onOpen, onClose } = useDisclosure();
	const dispatch = useDispatch();
	const initialReply = { message: "" };
	const [documents, setDocuments] = useState([]); // [{documentData,documentName,contentType}
	const [media, setMedia] = useState([]); // [{documentData,documentName,contentType}
	const [reply, setReply] = useState(initialReply);
	const [images, setImages] = useState([]);
	const toast = useToast();
	const handleChange = (e) => {
		setReply({ message: e.target.value });
	};
	const handleGetDocument = (documentId, documentName) => {
		const payload = { propertyId: row.propertyId, documentId, ticketId: row.id, documentName };
		console.log(payload);
		API.put("get-ticket-document", payload)
			.then((r) => {
				console.log(r);
				window.open(r.data.url, "_blank");
			})
			.catch((e) => {
				console.log(e);
				toast({
					title: "Error encountered",
					description: "Error encountered while fetching document",
					status: "error",
					duration: 9000,
					isClosable: true,
				});
			});
	};
	const saveTicket = (e) => {
		const payload = { author, reply, ticketId: row.id, propertyId: row.propertyId, documents, media };
		console.log(payload);
		dispatch(addReplyToTicketAsync(payload)).finally(() => {
			onClose(e);
			setDocuments([]);
			setMedia([]);
			setReply(initialReply);
		});
	};
	const onDocumentDrop = async (acceptedFiles) => {
		console.log(acceptedFiles);
		const documentList = [];
		for (const file of acceptedFiles) {
			const buffer = await file.arrayBuffer();
			const documentName = file.name;
			const contentType = file.type;
			documentList.push({ documentData: _arrayBufferToBase64(buffer), documentName, contentType });
		}

		setDocuments(documentList);
	};
	const onMediaDrop = async (acceptedFiles) => {
		console.log(acceptedFiles);
		const mediaList = [];
		for (const file of acceptedFiles) {
			const buffer = await file.arrayBuffer();
			const documentName = file.name;
			const contentType = file.type;
			mediaList.push({ documentData: _arrayBufferToBase64(buffer), documentName, contentType });
		}

		setMedia(mediaList);
	};
	useEffect(() => {
		const payload = { propertyId: row.propertyId, media: row.media, ticketId: row.id };
		API.put("get-ticket-media-urls", payload).then((r) => {
			setImages(r.data.media);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [row.media]);
	const markTicketAsDone = () => {
		const payload = { author, propertyId: row.propertyId, ticketId: row.id };
		dispatch(markTicketAsDoneAsync(payload)).then(() => {
			onClose();
		});
	};
	const handleDeleteTicket = () => {
		const payload = { propertyId: row.propertyId, ticketId: row.id };
		dispatch(removeTicketAsync(payload))
			.then((r) => {
				if (r.error?.message)
					toast({
						title: "Error encountered",
						description: r.error.message,
						status: "error",
						duration: 9000,
						isClosable: true,
					});
				else {
					toast({
						title: "Success!",
						description: "Ticket deleted!",
						status: "success",
						duration: 2000,
						isClosable: true,
					});
				}
			})
			.catch((e) => {
				toast({
					title: "Error encountered",
					description: "Error encountered while deleting ticket",
					status: "error",
					duration: 9000,
					isClosable: true,
				});
			})
			.finally(() => {
				onDeleteConfirmationClose();
			});
	};
	const renderDeleteConfirmationModal = () => {
		return (
			<Modal isOpen={isDeleteConfirmationOpen} onClose={onDeleteConfirmationClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Confirm delete?</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Text>Are you sure you want to delete this ticket? </Text>
						<Text>
							This action{" "}
							<Text as="span" fontWeight={"bold"}>
								cannot
							</Text>{" "}
							be undone!{" "}
						</Text>
					</ModalBody>

					<ModalFooter>
						<Button colorScheme="red" mr={3} onClick={handleDeleteTicket}>
							Delete
						</Button>
						<Button colorScheme="blue" mr={3} onClick={onDeleteConfirmationClose}>
							Close
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		);
	};
	const renderTicketModal = () => (
		<Modal isOpen={isOpen} onClose={onClose} size={"xl"}>
			<ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
			<ModalContent>
				<ModalHeader>
					<Stack>
						<Heading size="md">Ticket details</Heading>

						<Heading>
							{row.title}
							<Badge
								colorScheme={row.status === "done" ? resolvedStatusBackground : row.status === "pending" ? pendingStatusBackground : newStatusBackground}
								fontSize="16px"
								p="3px 10px"
								ml={2}
							>
								{row.status}
							</Badge>
						</Heading>

						<Heading size="sm" fontWeight={"medium"}>
							~ {row.author.name}, {row?.address}
						</Heading>
					</Stack>
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Flex align={"center"} justify={"center"}>
						<Stack spacing={2} w={"full"} rounded={"xl"} p={3}>
							<Box>
								<Text fontWeight={"bold"}>Description:</Text>
								<Text>{row.description}</Text>
							</Box>
							<Box spacing={2} direction="row">
								<Gallery items={images} />
							</Box>
							<Box hidden={row.documents?.length === 0}>
								<Text fontWeight={"bold"}>Documents:</Text>
								<UnorderedList>
									{row.documents?.map((d, index) => (
										<ListItem key={index}>
											<Text
												color="teal.500"
												textDecorationLine={"underline"}
												cursor={"pointer"}
												onClick={() => {
													handleGetDocument(d.id, d.name);
												}}
											>
												{d.name}
												<DownloadIcon marginLeft={2} />
											</Text>
										</ListItem> //TODO: make documentName clickable and download the clicked document
									))}
								</UnorderedList>
							</Box>
							<List spacing={3}>
								{row.replies.map((m, index) => {
									return (
										<ListItem key={index}>
											<Stack>
												<Text fontWeight={"bold"}>
													{m.author.name} at {moment(m.timestamp).format("DD MMM YYYY - HH:mm")}
												</Text>
												<Text pl={2}>{m.message}</Text>
											</Stack>
										</ListItem>
									);
								})}
							</List>
							<Box hidden={row.status === "done"}>
								<FormControl hidden={row.status === "done"}>
									<FormLabel>Your response:</FormLabel>
									<Textarea
										name="rooms"
										placeholder="Type your message here..."
										// value={propertyInformation?.rooms}
										onChange={handleChange}
										_placeholder={{ color: "gray.500" }}
										type="text"
									/>
								</FormControl>
								<FormControl>
									<FormLabel display={"inline"}>Media:</FormLabel>
									<Dropzone onDrop={onMediaDrop} accept={{ "image/*": [], "video/*": [] }} description="Upload an Image or a Video" />
								</FormControl>
								<FormControl>
									<FormLabel display={"inline"}>Documents:</FormLabel>
									<Dropzone
										onDrop={onDocumentDrop}
										accept={{
											"application/pdf": [".pdf"],
											"application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
											"application/msword": [".doc"],
										}}
										description="Upload PDF,DOC or DOCX Format"
									/>
								</FormControl>
							</Box>
						</Stack>
					</Flex>
				</ModalBody>
				<ModalFooter>
					<Flex gap={2} justifyContent={"space-between"} width={"100%"}>
						<Button colorScheme="blue" onClick={markTicketAsDone} isDisabled={row.status === "done"}>
							Mark as Done
						</Button>
						<Flex gap={2}>
							<Button colorScheme="red" onClick={onClose}>
								{t("misc.cancel")}
							</Button>
							<Button colorScheme="blue" onClick={saveTicket} isDisabled={row.status === "done"}>
								{t("misc.save")}
							</Button>
						</Flex>
					</Flex>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
	return (
		<Tr>
			<Td>
				<Text>{moment(row.addedDate).format(fullDateTimeFormat)}</Text>
			</Td>
			<Td>
				<Text>{owner.name}</Text>
			</Td>
			<Td>
				<Text>{row.address}</Text>
			</Td>
			<Td>
				<Badge colorScheme={row.status === "done" ? resolvedStatusBackground : row.status === "pending" ? pendingStatusBackground : newStatusBackground}>
					{t(`types.${row.status}`)}
				</Badge>
			</Td>
			<Td>
				<Text>{row.title}</Text>
			</Td>
			<Td>
				<Text>{row.author.name}</Text>
			</Td>
			{/* <Td>
				<Button onClick={onOpen}>
					<Text>View</Text>
				</Button>
				{renderTicketModal()}
			</Td> */}
			<Td>
				<Menu closeOnSelect={false}>
					<MenuButton>
						<Icon as={HamburgerIcon} />
					</MenuButton>
					<MenuList>
						<MenuItem onClick={onOpen}>View</MenuItem>
						<MenuItem onClick={onDeleteConfirmationOpen}>Delete</MenuItem>
					</MenuList>
				</Menu>
				{renderTicketModal()}
				{renderDeleteConfirmationModal()}
			</Td>
		</Tr>
	);
}

export default TicketsTableRow;
