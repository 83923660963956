/* eslint-disable react-hooks/exhaustive-deps */
import {
	Badge,
	Button,
	Flex,
	Modal,
	ModalOverlay,
	ModalHeader,
	ModalContent,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	Td,
	Text,
	Tr,
	useColorModeValue,
	List,
	ListItem,
	Box,
	Image,
	Textarea,
	WrapItem,
	Wrap,
	useToast,
} from "@chakra-ui/react";
import { FormControl, FormLabel, Heading, Stack } from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { useDisclosure } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { tenantAddReplyToTicketAsync } from "../../../redux/user/userSlice";
import ImageViewer from "react-simple-image-viewer";
import { API } from "../../../utils/http";
import moment from "moment";
import { fullDateTimeFormat } from "../../../utils/types";
import Gallery from "../../../Components/Gallery/Gallery";
import { useTranslation } from "react-i18next";

function TicketsTableRow(props) {
	const { row, author, propertyId } = props;
	const textColor = useColorModeValue("gray.700", "white");
	const { isOpen, onOpen, onClose } = useDisclosure();
	const dispatch = useDispatch();
	const initialReply = { message: "" };
	const [reply, setReply] = useState(initialReply);
	const handleChange = (e) => {
		setReply({ message: e.target.value });
	};
	const { t } = useTranslation();
	const [images, setImages] = useState([]);

	useEffect(() => {
		console.log("get media urls");
		const payload = { propertyId: propertyId, media: row.media, ticketId: row.id };
		API.put("get-ticket-media-urls", payload).then((r) => {
			console.log(r.data);
			setImages(r.data.media);
		});
	}, [row.media]);

	const toast = useToast();
	const saveTicket = (e) => {
		const payload = { author, reply, ticketId: row.id, propertyId };
		console.log("reply ", payload);
		dispatch(tenantAddReplyToTicketAsync(payload))
			.then((r) => {
				if (r.error?.message)
					toast({
						title: "Error encountered",
						description: r.error.message,
						status: "error",
						duration: 9000,
						isClosable: true,
					});
				else {
					toast({
						title: "Success!",
						description: "Tenant Added!",
						status: "success",
						duration: 2000,
						isClosable: true,
					});
				}
			})
			.finally(() => {
				setReply(initialReply);
				onClose();
			});
	};
	const renderTicketModal = () => (
		<Modal isOpen={isOpen} onClose={onClose} size={"xl"}>
			<ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
			<ModalContent>
				<ModalHeader>
					<Stack>
						<Heading size="md">Ticket details</Heading>
						<Heading>
							{row.title}
							<Badge
								colorScheme={row.status === "done" ? "green" : row.status === "pending" ? "yellow" : "red"}
								fontSize="16px"
								p="3px 10px"
								borderRadius="8px"
								ml={2}
							>
								{row.status}
							</Badge>
						</Heading>

						<Heading size="sm" fontWeight={"medium"}>
							~ {row.author?.name}, {row.address}
						</Heading>
					</Stack>
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Flex align={"center"} justify={"center"}>
						<Stack spacing={2} w={"full"} rounded={"xl"} p={3}>
							<Box>
								<Text fontWeight={"bold"}>Description:</Text>
								<Text>{row.description}</Text>
							</Box>
							<Gallery items={images} />
							<List spacing={3}>
								{row.replies.map((m, index) => {
									return (
										<ListItem key={index}>
											<Stack>
												<Text fontWeight={"bold"}>
													{m.author.name} at {moment(m.timestamp).format("DD MMM YYYY - HH:mm")}
												</Text>
												<Text pl={2}>{m.message}</Text>
											</Stack>
										</ListItem>
									);
								})}
							</List>
							<FormControl>
								<FormLabel>Your response:</FormLabel>
								<Textarea
									name="rooms"
									placeholder="Type your message here..."
									// value={propertyInformation?.rooms}
									onChange={handleChange}
									_placeholder={{ color: "gray.500" }}
									type="text"
								/>
							</FormControl>
						</Stack>
					</Flex>
				</ModalBody>
				<ModalFooter>
					<Button colorScheme="red" onClick={onClose} mr={3}>
						Cancel
					</Button>
					<Button colorScheme="blue" onClick={saveTicket}>
						Save
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
	return (
		<Tr>
			<Td>
				<Text>{moment(row.addedDate).format(fullDateTimeFormat)}</Text>
			</Td>
			<Td>
				<Badge colorScheme={row.status === "done" ? "green" : row.status === "pending" ? "yellow" : "red"} fontSize="16px" p="3px 10px" borderRadius="8px">
					{t(`types.${row.status}`)}
				</Badge>
			</Td>
			<Td>
				<Text fontSize="md" color={textColor} pb=".5rem">
					{row.title}
				</Text>
			</Td>
			<Td>
				<Text fontSize="md" color={textColor} pb=".5rem">
					{row.author.name}
				</Text>
			</Td>
			<Td>
				<Button p="0px" bg="transparent" onClick={onOpen}>
					<Text fontSize="md" cursor="pointer">
						Edit
					</Text>
				</Button>
				{renderTicketModal()}
			</Td>
		</Tr>
	);
}

export default TicketsTableRow;
