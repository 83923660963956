export const en = {
	navbarMenu: {
		dashboard: "Dashboard",
		properties: "Properties",
		outstanding: "Outstanding",
		documents: "Documents",
		utilities: "Utilities",
		financialStatus: "Financial Status",
		statements: "Statements",
		notifications: "Notifications",
		tickets: "Tickets",
		securityDeposits: "Security Deposits",
		profile: "Profile",
		subscription: "Subscription",
		payments: "Payments",
		messages: "Messages",
		customOwnerPlans: "Service Packages",
	},
	types: {
		tax: "Tax",
		centralHeatingVerification: "Central Heating Verification",
		insurance: "Insurance",
		rent: "Rent",
		other: "Other",
		administrationContract: "Administration Contract",
		gasContract: "Gas Contract",
		electricityContract: "Electricity Contract",
		waterContract: "Water Contract",
		optionalInsurance: "Optional Insurance",
		gasVerification: "Gas Verification",
		gasRevision: "Gas Revision",
		lastGasInvoice: "Last Gas Invoice",
		lastElectricityInvoice: "Last Electricity Invoice",
		lastWaterInvoice: "Last Water Invoice",
		tenantAssociationCertificate: "Tenant Association Certificate",
		sanitationDeclaration: "Sanitation Declaration",
		aquisitionAct: "Acquisition Act",
		entryReport: "Entry Report",
		exitReport: "Exit Report",
		observationReport: "Observation Report",
		formerTenant: "Former Tenant",
		ownerSpouse: "Owner Spouse",
		buildingSuper: "Building Superintendent",
		headOfTenantAssociation: "Head of Tenant Association",
		cityHall: "City Hall",
		finances: "Finances",
		water: "Water",
		gas: "Gas",
		electricity: "Electricity",
		internet: "Internet",
		gasUtility: "Gas",
		electricityUtility: "Electricity",
		waterUtility: "Water",
		internetUtility: "Internet",
		sanitationUtility: "Sanitation",
		cleaningUtility: "Cleaning",
		administrationFee: "Administration Fee",
		protocol: "Protocol",
		lease: "Lease",
		custodyCash: "Cash Custody",
		custodyBank: "Bank Custody",
		total: "Total",
		owner: "Owner",
		returned: "Returned",
		returnedTenant: "Returned to Tenant",
		extension: "Extension",
		termination: "Termination",
		rentModification: "Rent Modification",
		pending: "Pending",
		done: "Done",
		new: "New",
		gasAccount: "Gas Account",
		electricityAccount: "Electricity Account",
		waterAssociation: "Water Association",
		ownerIdentificationDocument: "Owner ID",
		tenantAssociationAnnouncement: "Tenant Association Announcement",
		tenants: "Tenants",
		jobCertificate: "Job Certificate",
		meterPictures: "Meter Pictures",
	},
	dashboard: {
		expiringLeases: "Expiring Leases",
		expiringDocuments: "Expiring Documents",
		revenue: "Revenue",
		properties: "Properties",
		securityDeposits: "Security Deposits",
		monthlyRevenue: "Monthly Revenue",
		administrationFee: "Administration Fee",
		openTickets: "Open Tickets",
		occupied: "Occupied",
		empty: "Empty",
	},
	table: {
		date: "Date",
		recipients: "Recipients",
		addedDate: "Added Date",
		issuedDate: "Issued Date",
		dueDate: "Due Date",
		paidDate: "Paid Date",
		method: "Method",
		type: "Type",
		owner: "Owner",
		address: "Address",
		status: "Status",
		title: "Title",
		author: "Author",
		endDate: "End Date",
		amount: "Amount",
		tenant: "Tenant",
		currency: "Currency",
		view: "View",
		delete: "Delete",
		noData: "No data to display",
		previousMonth: "Previous Month",
		nextMonth: "Next Month",
		rent: "Rent",
		tenants: "Tenants",
		lease: "Lease",
		statements: "Statements",
		administration: "Administration",
		incomplete: "Incomplete",
		complete: "Complete",
		warning: "Warning",
		empty: "Empty",
		expired: "Expired",
		last10Activities: "Last 10 Activities",
		markAsPaid: "Mark as Paid",
		name: "Name",
		number: "Number",
		startingDate: "Starting Date",
		signatureDate: "Signature Date",
		expirationDate: "Expiration Date",
		actions: "Actions",
		download: "Download",
		markAsActive: "Mark as Active",
		markAsInactive: "Mark as Inactive",
		due: "Due",
		overDue: "Overdue",
		paid: "Paid",
		rentReceived: "Rent Received",
		administrationFee: "Administration Fee",
		tax: "Tax",
		centralHeating: "Central Heating",
		insurance: "Insurance",
		others: "Others",
		subTotal: "Subtotal",
		year: "Year",
		nextYear: "Next Year",
		previousYear: "Previous Year",
		description: "Description",
		location: "Location",
		custodian: "Custodian",
		updatedDate: "Updated Date",
		lastActivities: "Last Activities",
		statement: "Statement",
		c168: "C168",
		lastMessage: "Last Message",
		price: "Price",
	},
	properties: {
		properties: "Properties",
		addProperty: "Add Property",
		archivedProperties: "Archived Properties",
		location: "Location",
		keys: "Keys",
		agent: "Agent",
		bankDetails: "Bank Details",
		owner: "Owner",
		street: "Street",
		buildingNumber: "Building Number",
		blockNumber: "Block Number",
		apartmentNumber: "Apartment Number",
		entrance: "Entrance",
		area: "Area",
		city: "City",
		county: "County",
		IBAN: "IBAN",
		administrationFeePercentage: "Administration Fee Percentage",
		currency: "Currency",
		name: "Name",
		email: "Email",
		phoneNumber: "Phone Number",
		verifyEmail: "Important: Verify email address carefully; cannot be changed later.",
		total: "Total",
		occupied: "Occupied",
		empty: "Empty",
		iban: "IBAN",
		administrationFeePercent: "Administration Fee Percentage",
	},
	outstanding: {
		outstanding: "Outstanding",
		missingRentPayment: "Missing Rent Payment",
		noOutstandingPayments: "No Outstanding Payments",
	},
	utilities: {
		utilities: "Utilities",
		gas: "Gas",
		water: "Water",
		electricity: "Electricity",
		sanitation: "Sanitation",
		internet: "Internet",
	},

	documents: {
		documents: "Documents",
		exportAsCsv: "Export as CSV",
		type: "Type",
	},

	financialStatus: {
		financialStatus: "Financial Status",
	},
	statements: {
		statements: "Statements",
	},

	tickets: {
		tickets: "Tickets",
	},

	securityDeposits: {
		securityDeposits: "Security Deposits",
		totals: "Totals",
		custodyCash: "Custody Cash",
		custodyBank: "Custody Bank",
		owner: "Owner",
		total: "Total",
		returned: "Returned",
		returnedTenant: "Returned to Tenant",
	},
	property: {
		propertyDetails: "Property Details",
		archive: "Archive",
		restore: "Restore",
		general: "General",
		specs: "Specifications",
		images: "Images",
		contacts: "Contacts",
		utilities: "Utilities",
		payments: "Payments",
		securityDeposits: "Security Deposits",
		financialStatements: "Financial Statements",
		documents: "Documents",
		accounts: "Accounts",
		activities: "Activities",
		tenants: "Tenants",
		notifications: "Notifications",
		tickets: "Tickets",
		messages: "Messages",
		location: "Location",
		county: "County",
		city: "City",
		area: "Area",
		street: "Street",
		buildingNumber: "Building Number",
		blockNumber: "Block Number",
		apartmentNumber: "Apartment Number",
		entrance: "Entrance",
		keys: "Keys",
		description: "Description",
		bankAccount: "Bank Account",
		bankAccountCurrency: "Bank Account Currency",
		administrationFeePercentage: "Administration Fee Percentage",
		administration: "Administration",
		lease: "Lease",
		financial: "Financial",
		rentPrice: "Rent Price",
		rentCollection: "Rent Collection Period",
		rentExchangeRate: "Rent Exchange Rate",
		fixedAdministrationFee: "Fixed Administration Fee",
		agent: "Agent",
		dateAdded: "Date Added",
		editRent: "Edit Rent",
		//images
		addMedia: "Add Media",
		//contacts
		contactInformation: "Contact Information",
		addContact: "Add Contact",
		addAssociation: "Add Association",
		association: "Association",
		editAssociation: "Edit Association",
		removeAssociation: "Remove Association",
		name: "Name",
		email: "Email",
		phoneNumber: "Phone Number",
		phoneNumber2: "Secondary Phone Number",
		administratorName: "Administrator Name",
		iban: "IBAN",
		//utilities
		index: "Index",
		additionalIndex: "Additional Index",
		addIndex: "Add Index",
		addUtilityInvoice: "Add Utility Invoice",
		gas: "Gas",
		electricity: "Electricity",
		water: "Water",
		sanitation: "Sanitation",
		internet: "Internet",
		history: "History",
		requestMeterPicture: "Request Meter Picture",
		gasIndex: "Gas Index",
		electricityIndex: "Electricity Index",
		waterIndex: "Water Index",
		utilityPayments: "Utility Payments",
		markMultipleAsPaid: "Mark Multiple as Paid",
		totalDue: "Total Due",
		totalOverdue: "Total Overdue",
		requestPeriod: "Request Period",
		collectionCode: "Collection Code",
		clientCode: "Client Code",
		nlcCode: "NLC Code",
		atmCode: "ATM Code",
		//payments
		addPayment: "Add Payment",
		addRentPayment: "Add Rent Payment",
		addAdministrationFeePayment: "Add Administration Fee Payment",
		editCustomOwnerPlanList: "Edit service packages",
		paymentDate: "Payment Date",
		paymentDirection: "Payment Direction",
		documentNumber: "Document Number",
		method: "Method",
		amount: "Amount",
		currency: "Currency",
		type: "Type",
		billedTo: "Billed To",
		billedToName: "Billed To Name",
		billedToType: "Billed To Type",
		//security deposits
		addSecurityDeposit: "Add Security Deposit",
		//financial statement extras
		addFinancialStatement: "Add Financial Statement",
		ownerHandlesFinancials: "Owner Handles Financials",
		//documents
		addDocument: "Add Document",
		addProtocol: "Add Protocol",
		tenantAssociationNotification: "Tenant Association Notification",
		protocols: "Protocols",
		//accounts
		addAccount: "Add Account",
		accountInformation: "Account Information",
		provider: "Provider",
		userName: "User Name",
		password: "Password",
		//activities
		addActivity: "Add Activity",
		//tenants
		addLease: "Add Lease Agreement",
		addAdditionalDocument: "Add Additional Document",
		addTenant: "Add Tenant",
		addReport: "Add Report",
		ownerHandlesSanitationDeclaration: "Owner Handles Sanitation Declaration",
		formerTenants: "Former Tenants",
		markFormer: "Mark as Former Tenant",
		markActive: "Mark as Active Tenant",
		formerContacts: "Former Contacts",
		reports: "Reports",
		//notifications
		sendNotification: "Send Notification",
		systemNotifications: "System Notifications",
		//tickets
		addTicket: "Add Ticket",
		//messages
		sendMessage: "Send Message",
	},

	misc: {
		loading: "Loading...",
		search: "Search...",
		legend: "Legend",
		cancel: "Cancel",
		save: "Save",
		edit: "Edit",
	},
};
