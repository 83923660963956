import { Card, CardBody, CardHeader, Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import React from "react";
import TicketsTableRow from "./TicketsTableRow";

export default function PropertyTicketsCard({ tickets, address, author }) {
	return (
		<Card overflowX={{ sm: "scroll", xl: "hidden" }}>
			<CardHeader>
				<Text fontSize="xl">Address: {address}</Text>
			</CardHeader>
			<CardBody>
				<Table variant="simple">
					<Thead>
						<Tr my=".8rem" pl="0px">
							<Th>Added date</Th>
							<Th>Status</Th>
							<Th>Title</Th>
							<Th>Author</Th>
							<Th></Th>
						</Tr>
					</Thead>
					<Tbody>
						{tickets?.map((t, index) => (
							<TicketsTableRow key={index} row={t} author={author} owner={t.owner} propertyId={t.propertyId} />
						))}
						{tickets?.length === 0 && (
							<Tr>
								<Td colSpan={5}>No Tickets Yet</Td>
							</Tr>
						)}
					</Tbody>
				</Table>
			</CardBody>
		</Card>
	);
}
