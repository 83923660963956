import React from "react";
import { List, ListItem, ListIcon } from "@chakra-ui/react";
import { MdCheckCircle } from "react-icons/md";
import { IoMdCloseCircle } from "react-icons/io";
import { documentTypes } from "../../utils/types";
import { useTranslation } from "react-i18next";

export default function DocumentsTooltipLabel({ property }) {
	const getListIcon = (bool) => (bool ? <ListIcon as={MdCheckCircle} color="green.500" /> : <ListIcon as={IoMdCloseCircle} color="red.500" />);
	const documents = property.documents;
	const { t } = useTranslation();
	return (
		<List spacing={3}>
			{documentTypes.map((dt, index) => {
				return (
					<ListItem key={index}>
						{getListIcon(documents.find((d) => d.type === dt.id && d.active))}
						{t(`types.${dt.id}`)}
					</ListItem>
				);
			})}
		</List>
	);
}
