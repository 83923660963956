import React, { useEffect, useState } from "react";
import {
	Accordion,
	AccordionItem,
	AccordionButton,
	AccordionPanel,
	AccordionIcon,
	Flex,
	Box,
	FormControl,
	FormLabel,
	Input,
	Textarea,
	Stack,
	Select,
	Button,
	useToast,
	Text,
	useDisclosure,
	Modal,
	ModalOverlay,
	ModalHeader,
	ModalContent,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	Table,
	Thead,
	Tr,
	Th,
	Tbody,
	Td,
} from "@chakra-ui/react";

import { useDispatch, useSelector } from "react-redux";
import { addMessageAsync } from "../../redux/user/userSlice";
import moment from "moment";
import MessagesTableRow from "../TableRows/MessagesTableRow";
import { useTranslation } from "react-i18next";
export default function MessagesTab({ property }) {
	const { t } = useTranslation();
	const isArchived = property.archived;
	const initialMessageInformation = {
		propertyId: property.id,
		author: {},
		title: "",
		description: "",
		type: "owner",
	};
	const [messageInformation, setMessageInformation] = useState(initialMessageInformation);
	const messages = property.messages || [];
	const { isOpen, onOpen, onClose } = useDisclosure();
	const user = useSelector((state) => state.user.user);

	const dispatch = useDispatch();
	const toast = useToast();
	const handleSendNotification = () => {
		const payload = { ...messageInformation, author: { name: user.name, email: user.email } };
		console.log("sending message:", payload);
		dispatch(addMessageAsync(payload))
			.then((r) => {
				console.log(r);
				if (r.error?.message)
					toast({
						title: "Error encountered",
						description: r.error.message,
						status: "error",
						duration: 9000,
						isClosable: true,
					});
				else {
					toast({
						title: "Success!",
						description: "Mass notification sent!",
						status: "success",
						duration: 2000,
						isClosable: true,
					});
					setMessageInformation({});
				}
			})
			.finally(() => {
				onClose();
				setMessageInformation(initialMessageInformation);
			});
	};
	const handleNotificationInfoChange = (e) => {
		console.log(e.target.value);
		setMessageInformation({ ...messageInformation, [e.target.name]: e.target.value });
		console.log("sending notification:", messageInformation);
	};
	useEffect(() => {
		console.log(messageInformation);
	}, [messageInformation]);

	const renderEmitNotificationModal = () => (
		<Modal isOpen={isOpen} onClose={onClose} size={"xl"}>
			<ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
			<ModalContent>
				<ModalHeader>
					<Text>{t("property.sendMessage")}</Text>
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Stack direction={"column"} width={{ sm: "100%", md: "50%" }} spacing={2}>
						<FormControl>
							<FormLabel>Title</FormLabel>
							<Input type="text" placeholder="Title" name="title" onChange={handleNotificationInfoChange} />
						</FormControl>
						<FormControl>
							<FormLabel>Description</FormLabel>
							<Textarea placeholder="Message..." name="description" onChange={handleNotificationInfoChange} />
						</FormControl>
						<FormControl>
							<FormLabel>Type</FormLabel>
							<Select name="type" onChange={handleNotificationInfoChange}>
								<option value="owner">Owner</option>
								<option value="tenant">Tenant</option>
							</Select>
						</FormControl>
					</Stack>
				</ModalBody>
				<ModalFooter>
					<Button colorScheme="red" onClick={onClose} mr={3}>
						{t("misc.cancel")}
					</Button>
					<Button colorScheme="blue" onClick={handleSendNotification}>
						Send
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);

	return (
		<Stack>
			<Stack direction={"row"}>
				<Button onClick={onOpen} colorScheme="blue" width={"fit-content"} isDisabled={isArchived}>
					{t("property.sendMessage")}
				</Button>

				{renderEmitNotificationModal()}
			</Stack>
			<Table variant="simple" size="sm">
				<Thead>
					<Tr my=".8rem" pl="0px">
						<Th>{t("table.lastMessage")}</Th>
						<Th>{t("table.owner")}</Th>
						{/* <Th>{t("table.address")}</Th> */}
						<Th>{t("table.title")}</Th>
						<Th>{t("table.author")}</Th>
						<Th>{t("table.type")}</Th>
						<Th>{t("table.date")}</Th>
						<Th></Th>
					</Tr>
				</Thead>
				<Tbody>
					{messages?.map((t, index) => (
						<MessagesTableRow key={index} row={t} author={{ name: user.name, email: user.email }} property={property} />
					))}
					{messages?.length === 0 && (
						<Tr>
							<Td>{t("table.noData")}</Td>
						</Tr>
					)}
				</Tbody>
			</Table>
			<Box hidden>
				<Accordion allowMultiple width="100%">
					{messages
						.slice()
						.reverse()
						.map((n, index) => (
							<AccordionItem key={index}>
								<AccordionButton>
									<Flex flex="1" direction={"row"} alignItems={"center"} whiteSpace={"pre"} justifyContent={"space-between"}>
										<Text fontSize="md">{n.title} </Text>
										<Text fontSize={"small"}>~ {moment(n.timestamp).format("DD MMM YYYY - HH:mm")}</Text>
									</Flex>
									<AccordionIcon />
								</AccordionButton>

								<AccordionPanel pb={4}>
									<Stack spacing={2}>
										<Box>
											<Text fontSize={"large"}>Message:</Text>
											<Text>{n.description}</Text>
										</Box>
									</Stack>
								</AccordionPanel>
							</AccordionItem>
						))}
				</Accordion>
			</Box>
		</Stack>
	);
}
