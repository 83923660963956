import { Button, ListItem, Text, UnorderedList } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useDropzone } from "react-dropzone";
// import "./dropzone.css";
export default function Dropzone({ onDrop, accept, open, maxSize, maxFiles, file, description, error }) {
	const { getRootProps, getInputProps, fileRejections, isDragActive, acceptedFiles } = useDropzone({
		accept,
		onDrop,
		maxSize,
		maxFiles,
	});

	const files = () => {
		return (
			<UnorderedList marginTop="5">
				{acceptedFiles.map((f, index) => {
					console.log(f);
					return (
						<ListItem key={index}>
							<Text>{f.name}</Text>
						</ListItem>
					);
				})}
			</UnorderedList>
		);
	};
	useEffect(() => {
		console.log(acceptedFiles);
	}, [acceptedFiles]);
	useEffect(() => {
		if (fileRejections.length > 0) {
			// if (fileRejections[0].errors[0].code === "too-many-files") {
			// 	alert("Too many files");
			// 	console.log("Too many files");
			// } else {
			// 	console.log("Some files are too large");
			// }
			console.log(fileRejections[0].errors[0].message);
			console.log(fileRejections);
			alert("Some files were rejected");
		}
	}, [fileRejections]);
	// const theme = useTheme();

	const dropzoneStyle = {
		padding: "20px",
		border: "3px teal dashed",
		borderColor: error ? "red" : "teal",
		textAlign: "center",
		margin: "auto",
		width: "90%",
		borderRadius: "15px",
	};
	return (
		<div>
			<div {...getRootProps()} style={dropzoneStyle}>
				<input className="input-zone" {...getInputProps()} />
				<div className="text-center">
					{isDragActive ? (
						<p className="dropzone-content">Release to drop the document here</p>
					) : (
						<>
							<p className="dropzone-content">Drag and drop or click to select(MAX 20MB)</p>
							<p className="dropzone-content">{description}</p>
							{/* <p className="dropzone-content">Upload PDF,DOC or DOCX Format (Max 2MB).</p> */}
						</>
					)}
					<Button variant="outlined" onClick={open}>
						Click to select files
					</Button>
				</div>
			</div>
			<>{files()}</>
		</div>
	);
}
