import { Badge, Flex, Td, Text, Tr, useColorModeValue, Icon, Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";

import { HamburgerIcon } from "@chakra-ui/icons";
import React from "react";
import moment from "moment";
import { API } from "../../../utils/http";
import { useTranslation } from "react-i18next";
import { fullDateFormat } from "../../../utils/types";
function DocumentsTableRow(props) {
	const { row, propertyId } = props;
	const { t } = useTranslation();
	const getDocument = (documentId, documentName) => {
		const payload = { documentId, propertyId, documentName };
		console.log("view: ", payload);
		API.put("get-document", payload).then((r) => {
			console.log(r);
			window.open(r.data.url, "_blank");
		});
	};
	return (
		<Tr>
			<Td minWidth={{ sm: "200px" }}>
				<Flex py=".8rem" direction={"column"}>
					<Text fontSize="md">{moment(row.addedDate).format("DD MMM YYYY HH:mm")}</Text>
					{row.active && (
						<Badge width={"fit-content"} colorScheme="green">
							Active
						</Badge>
					)}
				</Flex>
			</Td>
			<Td>
				<Text fontSize="md" pb=".5rem">
					{row.name}
				</Text>
			</Td>
			<Td>
				<Text fontSize="md" pb=".5rem">
					{t(`types.${row.type}`) || "-"}
				</Text>
			</Td>
			<Td>
				<Text fontSize="md" pb=".5rem">
					{row.number}
				</Text>
			</Td>
			<Td>
				<Text fontSize="md" pb=".5rem">
					{row.startingDate ? moment(row.startingDate).format(fullDateFormat) : "-"}
				</Text>
			</Td>
			<Td>
				<Text fontSize="md" pb=".5rem">
					{row.expirationDate ? moment(row.expirationDate).format(fullDateFormat) : "-"}
				</Text>
			</Td>
			<Td>
				<Menu closeOnSelect={false}>
					<MenuButton>
						<Icon as={HamburgerIcon} />
					</MenuButton>
					<MenuList>
						<MenuItem
							onClick={() => {
								getDocument(row.id, row.name);
							}}
						>
							Download
						</MenuItem>
					</MenuList>
				</Menu>
				{/* <Stack direction="row">
					<Button
						onClick={() => {
							getDocument(row.id);
						}}
					>
						<Tooltip label="Download">
							<Icon as={DownloadIcon} color={"teal.400"} boxSize={6}></Icon>
						</Tooltip>
					</Button>
					<Button
						colorScheme={"red"}
						onClick={() => {
							deleteDocument(row.id);
						}}
					>
						<Tooltip label="Download">
							<Icon as={DeleteIcon} boxSize={6}></Icon>
						</Tooltip>
					</Button>
				</Stack> */}
			</Td>
		</Tr>
	);
}

export default DocumentsTableRow;
