import { Badge } from "@chakra-ui/react";
import moment from "moment";

export function getRequestPeriod({ firstDay, lastDay }, date) {
	if (!firstDay || !lastDay) return "N/A";

	const startDate = moment(firstDay, "DD");
	//if the current month has less number of days than the lastDay then the lastDay is the last day of the month
	const endDate = moment(date).endOf("month").date() < lastDay ? moment(date).endOf("month") : moment(lastDay, "DD");

	// Check if the last day is before the first day, indicating a month transition
	if (endDate.isBefore(startDate)) {
		// Increment the month of the end date
		endDate.add(1, "month");
	}

	const formattedStartDate = startDate.format("D MMM");
	const formattedEndDate = endDate.format("D MMM");

	return `${formattedStartDate} - ${formattedEndDate}`;
}
export function removeAccents(str) {
	return str
		.normalize("NFD")
		.replace(/[\u0300-\u036f]/g, "")
		.toLowerCase();
}
export function getRequestPeriodBadge(property, type, date) {
	const requestPeriod = property.utilities[type].requestPeriod;
	let colorScheme = "red";
	//get colorscheme
	//if this month there has not been a request for the type of utility then the colorScheme is red
	//if this month there has been a request for the type of utility then the colorScheme is green
	//if this month there has been a request for the type of utility but the index has not been entered this omnth then the color is yellow
	const lastNotificationForType = property.systemNotifications.find(
		(n) => n.type === `${type}MeterRequest` && moment(n.timestamp).isSame(moment(date), "month")
	);
	if (!lastNotificationForType) colorScheme = "red";
	else {
		colorScheme = "yellow";
	}
	if (Number(requestPeriod.firstDay) > moment().date()) colorScheme = "gray";
	// if the first day of the requestperiod is smaller than the current day then the color is grey
	if (property.utilities[type].indexes.find((i) => moment(i.timestamp).isSame(moment(date), "month"))) colorScheme = "green";
	if (!requestPeriod.firstDay || !requestPeriod.lastDay) colorScheme = "gray";
	return <Badge colorScheme={colorScheme}>{getRequestPeriod(requestPeriod, date)}</Badge>;
}
export const _getLeaseStatus = (lease, lastExtension) => {
	if (!lease) return "incomplete";
	if (!lastExtension) {
		if (moment(lease.expirationDate).unix() < moment().unix()) return "expired";
		if (moment(lease.expirationDate).unix() - moment().unix() < 2592000) return "warning";
		return "complete";
	}
	if (moment(lastExtension.expirationDate).unix() < moment().unix()) return "expired";
	if (moment(lastExtension.expirationDate).unix() - moment().unix() < 2592000) return "warning";
	return "complete";
};
export function getLeaseStatus(property) {
	const lease = property.documents.find((doc) => doc.type === "lease" && doc.active);
	const extension = property.documents.find((doc) => doc.type === "extension" && doc.active);
	if (property.specs.apartmentFeatures.type === "garage")
		return {
			lease: _getLeaseStatus(lease, extension),
		};
	const tenantsWorkCertificate = Object.assign(
		{},
		...property.tenants
			.filter((t) => !t.former)
			.map((t) => {
				return { [t.email]: t.documents.findIndex((doc) => doc.type === "certificate") !== -1 ? "complete" : "incomplete" };
			})
	);
	const exitReport = Object.assign({}, { exitReport: property.reports.find((r) => r.type === "exitReport" && r.active) ? "complete" : null });
	const terminationDocument = property.documents.find((r) => r.type === "termination" && r.active);
	const termination = Object.assign({}, { termination: terminationDocument ? _getDocumentStatus(terminationDocument) : null });

	return {
		lease: _getLeaseStatus(lease, extension),
		entryReport: property.reports.find((r) => r.type === "entryReport" && r.active) ? "complete" : "incomplete",
		// exitReport: property.reports.find((r) => r.type === "exitReport" && r.active) ? "complete" : "incomplete",
		sanitationDeclaration: property.owner.handlesOwnSanitationDeclaration
			? "complete"
			: property.documents.find((doc) => doc.type === "sanitationDeclaration" && doc.active)
			? "complete"
			: "incomplete",
		tenantAssociationAnnouncement: property.additionalSteps.tenantAssociationAnnouncement ? "complete" : "incomplete",
		tenants: property.tenants.filter((t) => !t.former).length > 0 ? "complete" : "empty",
		...tenantsWorkCertificate,
		...exitReport,
		...termination,
	};
}
export const getAccType = (accType) => {
	switch (accType) {
		case "agent":
			return "Agent";
		case "agency":
			return "Agency";
		case "owner":
			return "Owner";
		case "tenant":
			return "Tenant";
		default:
			return "App";
	}
};
export function getFinancialStatementStatus(property) {
	const financialStatements = property.financialStatements;
	//if financial statements has active then return green, else return red
	if (property.specs.apartmentFeatures.type === "garage" || property.owner.handlesOwnFinancialStatements) return { c168: "complete", statement: "complete" };
	if (financialStatements.length > 0) {
		const activeStatement = financialStatements.findIndex((doc) => doc.active && doc.type === "statement");
		const activeC168 = financialStatements.findIndex((doc) => doc.active && doc.type === "c168");
		return {
			c168: activeC168 !== -1 ? "complete" : "incomplete",
			statement: activeStatement !== -1 ? "complete" : "incomplete",
		};
	}
	return {
		c168: "incomplete",
		statement: "incomplete",
	};
}
export const getPropertyStatusText = (status) => {
	if (Object.values(status).includes("empty")) return "empty";
	if (Object.values(status).includes("incomplete")) return "incomplete";
	if (Object.values(status).includes("expired")) return "expired";
	if (Object.values(status).includes("warning")) return "warning";
	return "complete";
};
export function getDocumentBadge(document) {
	//if document is active and expired return yellow badge
	//if document is active and not expired return green badge
	//if document is not active return grey badge
	if (document.active && !document.expirationDate) {
		return (
			<Badge width={"fit-content"} colorScheme="green">
				Active
			</Badge>
		);
	}
	if (document.active && moment(document.expirationDate).isBefore(moment())) {
		return (
			<Badge width={"fit-content"} colorScheme="red">
				Expired
			</Badge>
		);
	}
	//if the document is active and will expire in the next 30 days return yellow badge
	if (document.active && moment(document.expirationDate).isBefore(moment().add(30, "days"))) {
		return (
			<Badge width={"fit-content"} colorScheme="yellow">
				Expiring Soon
			</Badge>
		);
	}
	if (document.active && moment(document.expirationDate).isAfter(moment())) {
		return (
			<Badge width={"fit-content"} colorScheme="green">
				Active
			</Badge>
		);
	}
	if (!document.active) {
		return (
			<Badge width={"fit-content"} colorScheme="gray">
				Archive
			</Badge>
		);
	}
}
export function getPaymentStatus({ paymentDate, dueDate }) {
	if (!paymentDate)
		if (moment(dueDate).endOf("day").unix() < moment().endOf("day").unix()) return "Overdue";
		else return "Due";
	else return "Paid";
}
export function getPaymentStatusColor(status) {
	if (status === "Paid") return "green";
	if (status === "Due") return "yellow";
	return "red";
}
const _getDocumentStatus = (document) => {
	if (!document) return "incomplete";
	if (moment(document.expirationDate).unix() < moment().unix()) return "expired";
	if (moment(document.expirationDate).unix() - moment().unix() < 2592000) return "warning";
	if (Object.keys(document).length > 0) return "complete";
};
export function getAdministrationStatus(property) {
	const documents = property.documents;
	const administrationContract = documents.find((doc) => doc.type === "administrationContract" && doc.active);
	if (property.specs.apartmentFeatures.type === "garage")
		return {
			administrationContract: _getDocumentStatus(administrationContract),
		};
	const insurance = documents.find((doc) => doc.type === "insurance" && doc.active);
	const centralHeatingVerification = documents.find((doc) => doc.type === "centralHeatingVerification" && doc.active);
	const gasContract = documents.find((doc) => doc.type === "gasContract" && doc.active);
	const waterContract = documents.find((doc) => doc.type === "waterContract" && doc.active);
	const lastWaterInvoice = documents.find((doc) => doc.type === "lastWaterInvoice" && doc.active);
	const tenantAssociationCertificate = documents.find((doc) => doc.type === "tenantAssociationCertificate" && doc.active);
	const electricityContract = documents.find((doc) => doc.type === "electricityContract" && doc.active);
	const accounts = property.accounts;
	const gasAccount = accounts.find((acc) => acc.type === "gas");
	const electricityAccount = accounts.find((acc) => acc.type === "electricity");
	// const cityHallAccount = accounts.find((acc) => acc.type === "cityHall");
	// const financeAccount = accounts.find((acc) => acc.type === "finances");

	return {
		administrationContract: _getDocumentStatus(administrationContract),
		insurance: _getDocumentStatus(insurance),
		centralHeatingVerification: _getDocumentStatus(centralHeatingVerification),
		gasContract: _getDocumentStatus(gasContract),
		waterContract: property.specs.apartmentFeatures.water === "waterContract" ? _getDocumentStatus(waterContract) : "complete",
		lastWaterInvoice: property.specs.apartmentFeatures.water === "waterContract" ? _getDocumentStatus(lastWaterInvoice) : "complete",
		tenantAssociationCertificate: property.specs.apartmentFeatures.water === "association" ? _getDocumentStatus(tenantAssociationCertificate) : "complete",
		waterAssociation:
			property.specs.apartmentFeatures.water === "association"
				? property.contacts.find((c) => c.contactType === "buildingSuper") || property.association?.id
					? "complete"
					: "incomplete"
				: "complete",
		electricityContract: _getDocumentStatus(electricityContract),
		protocol: property.protocols.length > 0 ? "complete" : "incomplete",
		gasAccount: gasAccount ? "complete" : "incomplete",
		electricityAccount: electricityAccount ? "complete" : "incomplete",
		// cityHallAccount: cityHallAccount ? "complete" : "incomplete",
		// financeAccount: financeAccount ? "complete" : "incomplete",
	};
}
