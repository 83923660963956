import React, { useState } from "react";
import {
	Button,
	FormControl,
	FormHelperText,
	IconButton,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Textarea,
	useDisclosure,
	useToast,
} from "@chakra-ui/react";
import { MdBugReport } from "react-icons/md";
import { useSelector } from "react-redux";
import { API } from "../../utils/http";
export default function ReportButton() {
	const currentUserEmail = useSelector((state) => state.user.user?.email);
	const [title, setTitle] = useState("");
	const [description, setDescription] = useState("");
	const { isOpen, onOpen, onClose } = useDisclosure();
	const toast = useToast();
	const submitReport = () => {
		console.log("submit report");
		const payload = { title, description, email: currentUserEmail };
		console.log(payload);
		API.post("report-bug", payload)
			.then((r) => {
				console.log(r);
				toast({
					title: "Success!",
					description: "Report submitted",
					status: "success",
					duration: 2000,
					isClosable: true,
				});
				handleCloseModal();
			})
			.catch((e) => {
				toast({
					title: "Error encountered",
					description: e.message,
					status: "error",
					duration: 9000,
					isClosable: true,
				});
			});
	};
	const handleCloseModal = () => {
		onClose();
		setTitle("");
		setDescription("");
	};
	return (
		<>
			<div style={{ position: "fixed", bottom: "20px", right: "20px", zIndex: 999 }}>
				<IconButton
					isRound={true}
					variant="solid"
					colorScheme="teal"
					aria-label="Done"
					fontSize="20px"
					icon={<MdBugReport />}
					title="Report a bug"
					onClick={onOpen}
				/>
			</div>
			<Modal isOpen={isOpen} onClose={handleCloseModal}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Report a bug</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<FormControl mb={4}>
							<Input
								placeholder="Title"
								onChange={(e) => {
									setTitle(e.target.value);
								}}
							/>
							<FormHelperText>Enter a brief title for the bug</FormHelperText>
						</FormControl>

						<FormControl>
							<Textarea
								placeholder="Description"
								onChange={(e) => {
									setDescription(e.target.value);
								}}
							/>
							<FormHelperText>Provide a detailed description of the bug</FormHelperText>
						</FormControl>
					</ModalBody>

					<ModalFooter>
						<Button colorScheme="red" mr={3} onClick={handleCloseModal}>
							Close
						</Button>
						<Button colorScheme="teal" onClick={submitReport}>
							Submit Report
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
}
