import React, { useState } from "react";
import {
	Table,
	Tbody,
	Text,
	Th,
	Thead,
	Tr,
	useColorModeValue,
	Box,
	Stack,
	Td,
	Badge,
	Button,
	FormControl,
	FormLabel,
	Select,
	Wrap,
	WrapItem,
} from "@chakra-ui/react";
import { Card, CardHeader, CardBody } from "@chakra-ui/react";

import { useSelector } from "react-redux";
import { useEffect } from "react";
import { getPropertyAddress } from "../../utils/verifier";
import InvoicesRow from "../../Components/TableRows/InvoicesRow";
import { getPaymentStatus } from "../../utils/utils";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Loader from "../../Components/Loader/Loader";
import SearchBox from "../Properties/SearchBox";
import { selectActiveProperties } from "../../redux/user/userSlice";
import { useTranslation } from "react-i18next";
import { fullDateFormat, fullDateTimeFormat } from "../../utils/types";

export default function Outstanding() {
	const properties = useSelector(selectActiveProperties);
	const { t } = useTranslation();
	const [query, setquery] = useState("");
	const [loading, setLoading] = useState(true);
	const handleSearch = (query) => {
		setquery(query);
	};
	const navigate = useNavigate();
	useEffect(() => {
		setLoading(false);
	}, []);
	const goToProperty = (id) => {
		navigate(`/properties/view/${id}?tab=utilities`);
	};
	const hoverColor = useColorModeValue("gray.100", "gray.700");
	const exportOutstanding = () => {
		// create a csv file that contains the outstanding payments for all properties. the header has owner name,  property address, paymentType, dueDate, amount, currency, status
		// the file should be named outstanding_payments.csv
		// the file should be downloaded
		const header = "Proprietar,Proprietate Adresa,Tipul platii,Data Scadenta,Suma,Valuta,Status\n";
		const csvData = properties
			.map((property) => {
				const address = getPropertyAddress(property);
				const payments = property.payments.filter((p) => !p.paymentDate && moment(p.dueDate).endOf("day").unix() < moment().endOf("day").unix());
				return payments
					.map((payment) => {
						return `${property.owner.name},${address},${t(`types.${payment.type}`)},${moment(payment.dueDate).format(fullDateFormat)},${payment.amount},${
							payment.currency
						},${getPaymentStatus(payment)}\n`;
					})
					.join("");
			})
			.join("");
		const csv = header + csvData;
		const blob = new Blob([csv], { type: "text/csv" });
		const url = window.URL.createObjectURL(blob);
		const a = document.createElement("a");
		a.href = url;
		a.download = `plati_restante_${moment().format("DD-MM-YYYY")}.csv`;
		a.click();
	};
	const paymentTypes = [
		{ id: "all", label: "Toate" },
		{ id: "rent", label: "Chirie" },
		{ id: "tax", label: "Impozit" },
		{ id: "centralHeatingVerification", label: "Verificare centrala" },
		{ id: "insurance", label: "Asigurare" },
		{ id: "other", label: "Altele" },
		{ id: "electricityUtility", label: "Curent" },
		{ id: "gasUtility", label: "Gaz" },
		{ id: "waterUtility", label: "Apa" },
		{ id: "sanitationUtility", label: "Salubritate" },
		{ id: "internetUtility", label: "Internet" },
		{ id: "cleaningUtility", label: "Curatenie" },
	];
	const [paymentType, setPaymentType] = useState("all");
	const handleTypeChange = (e) => {
		setPaymentType(e.target.value);
	};
	return (
		<>
			<Stack direction="column" spacing={2}>
				{/* <Flex direction="column" pt={{ base: "120px", md: "75px" }}> */}
				<Card overflowX={{ sm: "scroll", xl: "hidden" }}>
					<CardHeader fontSize="xl" fontWeight="bold">
						<Stack>
							<Wrap direction={"row"} alignItems="center">
								<WrapItem>
									<Text whiteSpace={"pre"}>{t("outstanding.outstanding")}</Text>
								</WrapItem>
								<WrapItem>
									<SearchBox
										handleSearch={handleSearch}
										searchOptions={[
											{
												label: "Address",
												description: "Search by address",
											},
											{
												label: "Owner",
												description: "Search by owner name",
											},
											{
												label: "Tenant",
												description: "Search by tenant name",
											},
											{
												label: "Contact",
												description: "Search by contact name",
											},
											{
												label: "Status",
												description: "Search by status",
											},
											{
												label: "Amount",
												description: "Search by amount",
											},
											// {
											// 	label: "Type",
											// 	description: "Search by type",
											// },
											{
												label: "Payment Date",
												description: "Search by payment date",
											},
											{
												label: "Due Date",
												description: "Search by due date",
											},
										]}
									/>
								</WrapItem>

								<WrapItem>
									<Select width={"fit-content"} onChange={handleTypeChange} value={paymentType}>
										{paymentTypes.map((d, index) => (
											<option key={index} value={d.id}>
												{d.label}
											</option>
										))}
									</Select>
								</WrapItem>

								<WrapItem>
									<Button onClick={exportOutstanding}>Exporta Toti Restantierii</Button>
								</WrapItem>
							</Wrap>
							<Wrap direction={"row"} alignItems="center">
								{paymentTypes
									.filter((pt) => pt.id.toLowerCase().includes("utility") || pt.id === "rent")
									.map((d, index) => {
										if (d.id !== "all")
											return (
												<Badge key={index}>
													{d.label}:
													{properties.reduce((acc, property) => {
														return (
															acc +
															property.payments.filter(
																(p) => p.type.toLowerCase() === d.id.toLowerCase() && !p.paymentDate && getPaymentStatus(p).toLowerCase() === "overdue"
															).length
														);
													}, 0)}
												</Badge>
											);
										return null;
									})}
							</Wrap>
						</Stack>
					</CardHeader>
					<CardBody overflowX="auto">
						{loading ? (
							<Loader text={t("misc.loading")} />
						) : (
							<Table variant="simple" size="small">
								<Thead>
									<Tr my=".8rem" pl="0px">
										<Th pl="0px">{t("table.issuedDate")}</Th>
										{/* <Th>Address</Th> */}
										<Th>{t("table.status")}</Th>
										<Th>{t("table.dueDate")}</Th>
										<Th>{t("table.amount")}</Th>
										<Th>{t("table.method")}</Th>
										<Th>{t("table.type")}</Th>
										<Th>{t("table.paidDate")}</Th>
										<Th></Th>
									</Tr>
								</Thead>
								<Tbody>
									{properties
										.toSorted((a, b) => {
											const rentA = { ...a.rent };
											rentA.firstRentDay = Number(rentA.firstRentDay);
											rentA.lastRentDay = Number(rentA.lastRentDay);
											const rentB = { ...b.rent };
											rentB.firstRentDay = Number(rentB.firstRentDay);
											rentB.lastRentDay = Number(rentB.lastRentDay);
											if (!rentA.firstRentDay || !rentA.lastRentDay) return 1;
											if (!rentB.firstRentDay || !rentB.lastRentDay) return -1;
											const aRequestPeriod = moment().date() >= rentA.firstRentDay && moment().date() <= rentA.lastRentDay;
											const bRequestPeriod = moment().date() >= rentB.firstRentDay && moment().date() <= rentB.lastRentDay;
											if (aRequestPeriod && !bRequestPeriod) return -1;
											if (!aRequestPeriod && bRequestPeriod) return 1;
											if (rentA.firstRentDay > rentB.firstRentDay) return 1;
											if (rentA.firstRentDay < rentB.firstRentDay) return -1;
											return 0;
										})
										.map((property, index) => {
											const duePayments = property.payments.filter((p) => !p.paymentDate);
											const hasRentPayment =
												property.payments.findIndex(
													(p) => p.type === "rent" && moment(p.issuedDate).isSame(moment(), "year") && moment(p.issuedDate).isSame(moment(), "month")
												) !== -1;
											const inRentPeriod =
												!(Number(property.rent.firstRentDay) && Number(property.rent.lastRentDay)) || moment().date() >= Number(property.rent.firstRentDay);

											return (
												<React.Fragment key={index}>
													<Tr
														whiteSpace={"nowrap"}
														fontWeight={"bold"}
														onClick={() => {
															goToProperty(property.id);
														}}
														_hover={{ bg: hoverColor, cursor: "pointer" }}
													>
														<Td>{property.owner.name}</Td>
														<Td>{getPropertyAddress(property)}</Td>
														<Td>
															{t("table.rent")}: {property.rent.firstRentDay} - {property.rent.lastRentDay}
														</Td>
														<Td>{inRentPeriod && !hasRentPayment ? <Badge colorScheme="purple">{t("outstanding.missingRentPayment")}</Badge> : ""}</Td>
														<Td></Td>
														<Td></Td>
														<Td></Td>
														<Td></Td>
													</Tr>
													{duePayments
														?.filter((payment) => {
															const address = getPropertyAddress(property);
															if (paymentType === "all") {
																return (
																	address.toLowerCase().includes(query.toLowerCase()) ||
																	property.owner.name.toLowerCase().includes(query.toLowerCase()) ||
																	property.tenants.find((t) => t.name.toLowerCase().includes(query.toLowerCase())) ||
																	property.contacts.find((t) => t.name.toLowerCase().includes(query.toLowerCase())) ||
																	getPaymentStatus({ paymentDate: payment.paymentDate, dueDate: payment.dueDate }).toLowerCase() === query.toLowerCase() ||
																	payment.amount.toString().toLowerCase().includes(query.toLowerCase())
																);
															} else {
																return (
																	(address.toLowerCase().includes(query.toLowerCase()) ||
																		property.owner.name.toLowerCase().includes(query.toLowerCase()) ||
																		property.tenants.find((t) => t.name.toLowerCase().includes(query.toLowerCase())) ||
																		property.contacts.find((t) => t.name.toLowerCase().includes(query.toLowerCase())) ||
																		getPaymentStatus({ paymentDate: payment.paymentDate, dueDate: payment.dueDate }).toLowerCase() === query.toLowerCase() ||
																		payment.amount.toString().toLowerCase().includes(query.toLowerCase())) &&
																	payment.type.toLowerCase() === paymentType.toLowerCase()
																);
															}
														})
														.map((row, index) => {
															return (
																<InvoicesRow
																	id={row.id}
																	key={index}
																	date={row.issuedDate}
																	address={getPropertyAddress(property)}
																	paymentDate={row.paymentDate}
																	code={row.number}
																	amount={row.amount}
																	type={row.type}
																	logo={row.logo}
																	format={row.format}
																	method={row.paymentMethod}
																	documents={row.documents}
																	paymentDirection={row.paymentDirection}
																	property={property}
																	currency={row.currency}
																	description={row.description}
																	exchange={row.exchange}
																	dueDate={row.dueDate}
																/>
															);
														})}
													{duePayments.length === 0 && (
														<Tr>
															<Td colSpan={8}>{t("table.noData")}</Td>
														</Tr>
													)}
												</React.Fragment>
											);
										})}
								</Tbody>
							</Table>
						)}
					</CardBody>
				</Card>
			</Stack>
		</>
	);
}
