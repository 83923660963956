import { Card, CardBody, CardHeader, Flex, Grid, List, ListIcon, ListItem, Stack, Text } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { MdCheckCircle } from "react-icons/md";
import { IoMdCloseCircle } from "react-icons/io";

export default function Profile() {
	const user = useSelector((state) => state.user?.user);
	const { t } = useTranslation();
	return (
		<Stack>
			<Card>
				<CardHeader>
					<Text fontSize="xl" fontWeight="bold">
						{t("navbarMenu.profile")}
					</Text>
				</CardHeader>
			</Card>
			<Grid templateColumns={{ md: "1fr", lg: "1fr 1fr" }} templateRows={{ md: "1fr auto", lg: "1fr" }} gap="24px">
				<Card>
					<CardHeader>
						<Text fontSize="xl" fontWeight="bold">
							Informatii Personale
						</Text>
					</CardHeader>
					<CardBody>
						<Stack>
							<Flex align="center">
								<Text fontSize="md" fontWeight="bold" me="10px">
									Nume:{" "}
								</Text>
								<Text fontSize="md" color="gray.500" fontWeight="400">
									{user?.name || "-"}
								</Text>
							</Flex>
							<Flex align="center">
								<Text fontSize="md" fontWeight="bold" me="10px">
									Email:{" "}
								</Text>
								<Text fontSize="md" color="gray.500" fontWeight="400">
									{user?.email || "-"}
								</Text>
							</Flex>
							<Flex align="center">
								<Text fontSize="md" fontWeight="bold" me="10px">
									Numar de telefon:{" "}
								</Text>
								<Text fontSize="md" color="gray.500" fontWeight="400">
									{user?.phoneNumber || "-"}
								</Text>
							</Flex>
							<Flex align="center">
								<Text fontSize="md" fontWeight="bold" me="10px">
									Numar secundar de telefon:{" "}
								</Text>
								<Text fontSize="md" color="gray.500" fontWeight="400">
									{user?.additionalPhoneNumber || "-"}
								</Text>
							</Flex>
						</Stack>
					</CardBody>
				</Card>
				<Card>
					<CardHeader>
						<Text fontSize="xl" fontWeight="bold">
							Documente
						</Text>
					</CardHeader>
					<CardBody>
						<List>
							<ListItem>
								<ListIcon
									as={user.identificationDocument.id ? MdCheckCircle : IoMdCloseCircle}
									color={user.identificationDocument.id ? "green.500" : "red.500"}
								></ListIcon>
								Act de identitate
							</ListItem>
							<ListItem>
								<ListIcon
									as={user.documents.find((d) => d.type === "certificate") ? MdCheckCircle : IoMdCloseCircle}
									color={user.documents.find((d) => d.type === "certificate") ? "green.500" : "red.500"}
								></ListIcon>
								Adeverinta de la locul de munca
							</ListItem>
						</List>
					</CardBody>
				</Card>
			</Grid>
		</Stack>
	);
}
