import React from "react";
import { Box, Heading, Text, Button, Stack } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { signOutAsync } from "../../redux/auth/authSlice";
import PageLoader from "../../Components/PageLoader/PageLoader";

export default function NotFoundPage() {
	const dispatch = useDispatch();
	const apiLoading = useSelector((state) => state.user.status) === "loading";

	const handleSignOut = async () => {
		dispatch(signOutAsync());
	};

	return (
		<div>
			{apiLoading ? (
				<PageLoader />
			) : (
				<Box textAlign="center" py={10} px={6}>
					<Heading display="inline-block" as="h2" size="2xl" bgGradient="linear(to-r, teal.400, teal.600)" backgroundClip="text">
						404
					</Heading>
					<Text fontSize="18px" mt={3} mb={2}>
						Page Not Found
					</Text>
					<Text color={"gray.500"} mb={6}>
						The page you're looking for does not seem to exist
					</Text>
					<Stack alignItems={"center"}>
						<Link to="/" width={"fit-content"}>
							<Button colorScheme="teal" bgGradient="linear(to-r, teal.400, teal.500, teal.600)" color="white" variant="solid" width={"fit-content"}>
								Go To Dashboard
							</Button>
						</Link>
						<Button width={"fit-content"} onClick={handleSignOut}>
							Sign Out
						</Button>
					</Stack>
				</Box>
			)}
		</div>
	);
}
