import {
	Box,
	Button,
	Stack,
	useDisclosure,
	Modal,
	ModalOverlay,
	ModalHeader,
	ModalContent,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	Table,
	Tbody,
	Text,
	Th,
	Thead,
	Tr,
	FormControl,
	FormLabel,
	Input,
	Select,
	useToast,
	WrapItem,
	Wrap,
	Td,
	Textarea,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import Dropzone from "../../Components/Dropzone/Dropzone";
import { addOwnerDocumentAsync, updateOwnerAsync, uploadOwnerIdAsync } from "../../redux/user/userSlice";
import { _arrayBufferToBase64 } from "../../utils/convertors";
import Gallery from "../../Components/Gallery/Gallery";
import { API } from "../../utils/http";
import TenantsDocumentsTableRow from "../../Components/TableRows/TenantsDocumentsTableRow";
import OwnerDocumentsTableRow from "../../Components/TableRows/OwnerDocumentsTableRow";

export default function BillingInformation({ owner }) {
	const initialOwnerInformation = {
		identificationNumber: owner.billingInformation.identificationNumber,
		address: owner.billingInformation.address,
		city: owner.billingInformation.city,
		country: owner.billingInformation.country,
		county: owner.billingInformation.county,
		ownerEmail: owner.email,
	};
	const [error, setError] = useState({});
	const [isEditing, setIsEditing] = useState(false);
	const [ownerInformation, setOwnerInformation] = useState(initialOwnerInformation);
	const dispatch = useDispatch();
	const toast = useToast();

	const handleSave = () => {
		console.log(ownerInformation);
		dispatch(updateOwnerAsync({ type: "billingInformation", ...ownerInformation }));
	};

	const handleOwnerInformationChange = (e) => {
		setOwnerInformation({ ...ownerInformation, [e.target.name]: e.target.value });
	};

	return (
		<Stack>
			<Stack direction={"row"} alignItems="center">
				<Text fontWeight={"bold"}>CIF/CNP:</Text>
				{isEditing ? (
					<Input
						size="sm"
						value={ownerInformation.identificationNumber}
						width="fit-content"
						name="identificationNumber"
						onChange={handleOwnerInformationChange}
					/>
				) : (
					<Text>{owner.billingInformation.identificationNumber || "-"}</Text>
				)}
			</Stack>
			<Stack direction={"row"} alignItems="center">
				<Text fontWeight={"bold"}>Address Line:</Text>
				{isEditing ? (
					<Textarea size="sm" value={ownerInformation.address} width="fit-content" name="address" onChange={handleOwnerInformationChange} />
				) : (
					<Text>{owner.billingInformation.address || "-"}</Text>
				)}
			</Stack>
			<Stack direction={"row"} alignItems="center">
				<Text fontWeight={"bold"}>City:</Text>
				{isEditing ? (
					<Input size="sm" value={ownerInformation.city} width="fit-content" name="city" onChange={handleOwnerInformationChange} />
				) : (
					<Text>{owner.billingInformation.city || "-"}</Text>
				)}
			</Stack>
			<Stack direction={"row"} alignItems="center">
				<Text fontWeight={"bold"}>County:</Text>
				{isEditing ? (
					<Input size="sm" value={ownerInformation.county} width="fit-content" name="county" onChange={handleOwnerInformationChange} />
				) : (
					<Text>{owner.billingInformation.county || "-"}</Text>
				)}
			</Stack>
			<Stack direction={"row"} alignItems="center">
				<Text fontWeight={"bold"}>Country:</Text>
				{isEditing ? (
					<Input size="sm" value={ownerInformation.country} width="fit-content" name="country" onChange={handleOwnerInformationChange} />
				) : (
					<Text>{owner.billingInformation.country || "-"}</Text>
				)}
			</Stack>
			<Stack>
				<Button
					width={"fit-content"}
					colorScheme={"green"}
					onClick={() => {
						if (isEditing) handleSave();
						setIsEditing(!isEditing);
					}}
				>
					{isEditing ? "Save" : "Edit"}
				</Button>
			</Stack>
		</Stack>
	);
}
