import React, { useState } from "react";
import { Table, Tbody, Text, Th, Thead, Tr, useColorModeValue, Box, Button, Stack, Select, Td, Wrap, WrapItem } from "@chakra-ui/react";
import { FormControl, FormLabel } from "@chakra-ui/react";
import { Card, CardHeader, CardBody } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { getPropertyAddress } from "../../utils/verifier";
import { removeAccents } from "../../utils/utils";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Loader from "../../Components/Loader/Loader";
import SearchBox from "../Properties/SearchBox";
import DocumentsTableRow from "../../Components/TableRows/DocumentsTableRow";
import { documentTypes } from "../../utils/types";
import { selectActiveProperties } from "../../redux/user/userSlice";
import { useTranslation } from "react-i18next";

export default function Documents() {
	const properties = [...useSelector(selectActiveProperties)].sort((a, b) => a.owner.name.localeCompare(b.owner.name, "ro", { sensitivity: "base" }));
	const [query, setquery] = useState("");
	const [loading, setLoading] = useState(true);
	const [documentType, setDocumentType] = useState(documentTypes[0].id);
	const handleSearch = (query) => {
		setquery(query);
	};
	const { t } = useTranslation();
	const handleDocumentTypeChange = (e) => {
		setDocumentType(e.target.value);
	};

	const navigate = useNavigate();
	useEffect(() => {
		setLoading(false);
	}, []);
	const goToProperty = (id) => {
		navigate(`/properties/view/${id}?tab=documents`);
	};
	const hoverColor = useColorModeValue("gray.100", "gray.700");
	const handleExport = () => {
		//get all documents from the properties into one flat list
		const allDocuments = properties
			.map((p) =>
				p.documents
					.filter((d) => {
						if (documentType !== "") {
							return d.type === documentType;
						}
						return true;
					})
					.map((d) => {
						return { ...d, owner: p.owner.name, address: getPropertyAddress(p) };
					})
			)
			.flat()
			.sort((a, b) => moment(a.startingDate).unix() - moment(b.startingDate).unix());
		const csv = allDocuments;
		const replacer = (key, value) => (value === null ? "" : value); // specify how you want to handle null values here
		// const header = Object.keys(csv[0]);
		//i want a custom header
		const header = ["startingDate", "owner", "address", "type", "number", "expirationDate"];

		let csvData = csv.map((row) => header.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(","));
		csvData.unshift(header.join(","));
		csvData = csvData.join("\r\n");

		const blob = new Blob([csvData], { type: "text/csv" });
		const url = window.URL.createObjectURL(blob);
		const a = document.createElement("a");
		a.setAttribute("hidden", "");
		a.setAttribute("href", url);
		a.setAttribute("download", "exported_documents.csv");
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	};
	return (
		<>
			<Stack direction="column" spacing={2}>
				<Card overflowX={{ sm: "scroll", xl: "hidden" }}>
					<CardHeader fontSize="xl" fontWeight="bold">
						<Stack direction={"column"}>
							<Text>{t("documents.documents")}</Text>
							<Wrap>
								<WrapItem>
									<Box>
										<SearchBox
											handleSearch={handleSearch}
											searchOptions={[
												{
													label: "Address",
													description: "Search by address",
												},
												{
													label: "Owner",
													description: "Search by owner",
												},
											]}
										/>
									</Box>
								</WrapItem>
								<WrapItem>
									<FormControl display={"flex"} alignItems="center">
										<FormLabel display={"inline"}>{t("documents.type")}:</FormLabel>
										<Select
											width={"fit-content"}
											onChange={handleDocumentTypeChange}
											type="number"
											value={documentType}
											placeholder="Document type"
											_placeholder={{ color: "gray.500" }}
										>
											{documentTypes.map((d, index) => (
												<option key={index} value={d.id}>
													{d.label}
												</option>
											))}
										</Select>
									</FormControl>
								</WrapItem>
								{/* add a button so i can export as csv */}
								<WrapItem>
									<Button onClick={handleExport}>{t("documents.exportAsCsv")}</Button>
								</WrapItem>
							</Wrap>
						</Stack>
					</CardHeader>
					<CardBody overflowX="auto">
						{loading ? (
							<Loader text="Loading Outstanding..." />
						) : (
							<Table variant="simple" size="small">
								<Thead>
									<Tr pl="0px" whiteSpace={"nowrap"}>
										<Th pl="0px">{t("table.addedDate")}</Th>
										<Th>{t("table.name")}</Th>
										<Th>{t("table.type")}</Th>
										<Th>{t("table.number")}</Th>
										<Th>{t("table.startingDate")}</Th>
										<Th>{t("table.expirationDate")}</Th>
										<Th>{t("table.actions")}</Th>
									</Tr>
								</Thead>
								<Tbody>
									{properties
										.filter((p) => {
											const address = removeAccents(getPropertyAddress(p));
											const ownerName = removeAccents(p.owner.name);
											return (
												ownerName.toLowerCase().includes(removeAccents(query.toLowerCase())) ||
												address.toLowerCase().includes(removeAccents(query.toLowerCase()))
											);
										})
										.map((property, index) => {
											const documents = property.documents.filter((d) => {
												if (documentType !== "") {
													return d.type === documentType;
												}
												return true;
											});

											return (
												<React.Fragment key={index}>
													<Tr
														whiteSpace={"nowrap"}
														fontWeight={"bold"}
														onClick={() => {
															goToProperty(property.id);
														}}
														_hover={{ bg: hoverColor, cursor: "pointer" }}
													>
														<Td>{property.owner.name}</Td>
														<Td colSpan={6}>{getPropertyAddress(property)}</Td>
													</Tr>
													{documents
														.filter((d) => {
															if (documentType !== "") {
																return d.type === documentType;
															}
															return true;
														})
														.map((row, index) => {
															return <DocumentsTableRow key={index} row={row} propertyId={property.id} />;
														})}
													{documents.filter((d) => {
														if (documentType !== "") {
															return d.type === documentType;
														}
														return true;
													}).length === 0 && (
														<Tr>
															<Td colSpan={8}>{t("table.noData")}</Td>
														</Tr>
													)}
												</React.Fragment>
											);
										})}
								</Tbody>
							</Table>
						)}
					</CardBody>
				</Card>
			</Stack>
		</>
	);
}
