const contractChecker = (arr, target) => target.every((v) => arr.find((a) => a.type === v && a.active));
const checker = (arr, target) => target.every((v) => arr.find((a) => a.type === v));
const emailRegex = /^\S+@\S+\.\S+$/;
// ^\S+@\S+\.\S+$
export const getPropertyStatus = (property) => {
	const requiredDocumentTypes = ["insurance", "centralHeatingVerification", "gasContract", "electricityContract"];
	const additionalSteps = property.additionalSteps.tenantAssociationAnnouncement;
	const requiredAccountTypes = ["cityHall", "gas", "electricity"];
	if (contractChecker(property.documents, ["administrationContract"])) {
		if (
			checker(property.documents, requiredDocumentTypes) &&
			checker(property.accounts, requiredAccountTypes) &&
			property.owner.identificationDocument &&
			additionalSteps
		)
			return "complete";
		return "incomplete";
	} else {
		return "inactive";
	}
};

export const getPropertyAddress = (property) => {
	// console.log(property);
	return (
		"str. " +
			property.location.street +
			" nr." +
			property.location.buildingNumber +
			" ap." +
			property.location.apartmentNumber +
			" scara " +
			property.location.entrance || "-"
	);
};

export const signUpVerifier = (signUpInformation) => {
	let errors = {};
	Object.entries(signUpInformation).forEach((entry) => {
		const [key, value] = entry;
		if (!value) {
			errors = { ...errors, [key]: "This field is required" };
		}
	});
	if (signUpInformation.accountType !== "agency") {
		delete errors.name;
		delete errors.phoneNumber;
	}
	return errors;
};
export const signInVerifier = (signInInformation) => {
	let errors = {};
	Object.entries(signInInformation).forEach((entry) => {
		const [key, value] = entry;
		if (!value) {
			errors = { ...errors, [key]: "This field is required" };
		}
	});
	return errors;
};
export const addActivityVerifier = (activityInformation) => {
	let errors = {};
	Object.entries(activityInformation).forEach((entry) => {
		const [key, value] = entry;
		if (!value) {
			errors = { ...errors, [key]: "This field is required" };
		}
	});
	return errors;
};
export const generalVerifier = (payload) => {
	let errors = {};
	Object.entries(payload).forEach((entry) => {
		const [key, value] = entry;
		if (!value) {
			errors = { ...errors, [key]: "This field is required" };
		}
	});

	return errors;
};
export const addContactVerifier = (payload) => {
	let errors = {};
	Object.entries(payload).forEach((entry) => {
		const [key, value] = entry;
		if (!value) {
			errors = { ...errors, [key]: "This field is required" };
		}
	});
	delete errors.phoneNumber2;
	delete errors.email;
	delete errors.description;
	return errors;
};
export const addAccountVerifier = (payload) => {
	let errors = {};
	Object.entries(payload).forEach((entry) => {
		const [key, value] = entry;
		if (!value) {
			errors = { ...errors, [key]: "This field is required" };
		}
	});

	return errors;
};
export const addTenantVerifier = (payload) => {
	let errors = {};
	//check if payload.email is in an email format using regex

	if (!emailRegex.test(payload.email)) errors.email = "Invalid email format";
	Object.entries(payload).forEach((entry) => {
		const [key, value] = entry;
		if (!value) {
			errors = { ...errors, [key]: "This field is required" };
		}
	});
	delete errors.existing;
	return errors;
};
export const addLeaseContactVerifier = (payload) => {
	let errors = {};
	Object.entries(payload).forEach((entry) => {
		const [key, value] = entry;
		if (!value) {
			errors = { ...errors, [key]: "This field is required" };
		}
	});
	delete errors.email;
	return errors;
};
export const editRentVerifier = (payload) => {
	let errors = {};
	Object.entries(payload).forEach((entry) => {
		const [key, value] = entry;
		if (!value) {
			errors = { ...errors, [key]: "This field is required" };
		}
	});
	delete errors.fixedAdministrationFeeAmount;
	delete errors.fixedAdministrationFeeCurrency;
	if (payload.fixedAdministrationFeeAmount && !payload.fixedAdministrationFeeCurrency) errors.fixedAdministrationFeeCurrency = "This field is required";
	if (payload.fixedAdministrationFeeCurrency && !payload.fixedAdministrationFeeAmount) errors.fixedAdministrationFeeAmount = "This field is required";

	return errors;
};
export const addUtilityMeterVerifier = (payload) => {
	let errors = {};
	Object.entries(payload).forEach((entry) => {
		const [key, value] = entry;
		if (!value) {
			errors = { ...errors, [key]: "This field is required" };
		}
	});

	return errors;
};
export const markAsPaidVerifier = (payload) => {
	let errors = {};
	Object.entries(payload).forEach((entry) => {
		const [key, value] = entry;
		if (!value) {
			errors = { ...errors, [key]: "This field is required" };
		}
	});
	if (!payload.billedTo.name) errors.billedToName = "This field is required";
	if (!payload.billedTo.type) errors.billedToType = "This field is required";
	if (payload.paymentMethod === "personal") {
		delete errors.documentNumber;
	}
	delete errors.documents;
	return errors;
};
export const makrMultiplePAymentsAsPaidVerifier = (payload) => {
	let errors = {};
	const { payments, billedTo, paymentDate, documentNumber, paymentMethod } = payload;
	if (!billedTo.name) errors.billedToName = "This field is required";
	if (!billedTo.type) errors.billedToType = "This field is required";
	if (!paymentDate) errors.paymentDate = "This field is required";
	if (!documentNumber) errors.documentNumber = "This field is required";
	if (!paymentMethod) errors.paymentMethod = "This field is required";
	if (payments.length < 2) errors.payments = "Please select at least 2 payments";
	return errors;
};
export const addDocumentVerifier = (payload) => {
	let errors = {};
	Object.entries(payload).forEach((entry) => {
		const [key, value] = entry;
		if (!value) {
			errors = { ...errors, [key]: "This field is required" };
		}
	});
	delete errors.expirationDate;
	if (payload.documentType === "gasRevision" || payload.documentType === "gasVerification") {
		delete errors.documentNumber;
		delete errors.documentData;
		delete errors.documentName;
		delete errors.contentType;
	}
	if (payload.documentType === "other") {
		delete errors.startingDate;
		delete errors.documentNumber;
	}
	delete errors.description;
	return errors;
};
export const addLeaseVerifier = (payload) => {
	let errors = {};
	Object.entries(payload).forEach((entry) => {
		const [key, value] = entry;
		if (!value) {
			errors = { ...errors, [key]: "This field is required" };
		}
	});
	if (payload.exchangeRateType === "fixedExchangeRate" && !payload.fixedExchangeRate) errors.fixedExchangeRate = "This field is required";
	return errors;
};

export const addPaymentVerifier = (payload) => {
	let errors = {};
	Object.entries(payload).forEach((entry) => {
		const [key, value] = entry;
		if (!value) {
			errors = { ...errors, [key]: "This field is required" };
		}
	});
	delete errors.documents;
	if (!payload.billedTo.name) errors.billedToName = "This field is required";
	if (!payload.billedTo.type) errors.billedToType = "This field is required";
	if (!payload.equivalentAmount && payload.equivalentCurrency) errors.equivalentAmount = "This field is required";
	else delete errors.equivalentAmount;
	if (payload.equivalentAmount && !payload.equivalentCurrency) errors.equivalentCurrency = "This field is required";
	else delete errors.equivalentCurrency;

	return errors;
};
export const addFinancialStatementVerifier = (payload) => {
	let errors = {};
	Object.entries(payload).forEach((entry) => {
		const [key, value] = entry;
		if (!value) {
			errors = { ...errors, [key]: "This field is required" };
		}
	});

	return errors;
};
export const addAdmFeePaymentVerifier = (payload) => {
	let errors = {};
	//payload contains the following fields: amount, currency
	//if one is empty, the other must be empty too

	if (!payload.amount && payload.currency) errors.amount = "This field is required";
	else delete errors.amount;
	if (payload.amount && !payload.currency) errors.currency = "This field is required";
	else delete errors.currency;

	return errors;
};
export const finalizeMonthVerifier = (payload) => {
	let errors = {};
	Object.entries(payload).forEach((entry) => {
		const [key, value] = entry;
		console.log(value);
		if (value === undefined || value === "" || value === null) {
			errors = { ...errors, [key]: "This field is required" };
		}
	});
	if (payload.month === 0) delete errors.month;
	delete errors.document;

	return errors;
};
export const addSecurityDepositVerifier = (payload) => {
	let errors = {};
	Object.entries(payload).forEach((entry) => {
		const [key, value] = entry;
		if (!value) {
			errors = { ...errors, [key]: "This field is required" };
		}
	});

	return errors;
};
export const addUtilityMeterPictureVerifier = (payload) => {
	let errors = {};
	if (!payload.utilityType) errors.utilityType = "This field is required";
	if (!payload.picture.documentData) errors.picture = "This field is required";
	return errors;
};
export const addPropertyVerifier = (information) => {
	let errors = {};
	Object.entries(information.location).forEach((entry) => {
		const [key, value] = entry;
		if (!value) {
			errors.location = { ...errors.location, [key]: "This field is required" };
		}
	});
	if (!information.keys) errors.keys = "This field is required";
	// if (!information.floor) errors.floor = "This field is required";
	Object.entries(information.bankDetails).forEach((entry) => {
		const [key, value] = entry;
		if (!value) {
			errors.bankDetails = { ...errors.bankDetails, [key]: "This field is required" };
		}
	});
	Object.entries(information.owner).forEach((entry) => {
		const [key, value] = entry;
		if (!value) {
			errors.owner = { ...errors.owner, [key]: "This field is required" };
		}
	});
	if (!emailRegex.test(information.owner.email)) errors.owner.email = "Invalid email format";
	return errors;
};
