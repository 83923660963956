import { API } from "../../utils/http";

export function getDashboardApi() {
	return new Promise((resolve, reject) => {
		API.get("dashboard")
			.then((r) => {
				const user = r.data;
				resolve(user);
			})
			.catch((e) => {
				console.log(e);
				if (e.code === "ERR_NETWORK") reject("Network error");
				reject(e.response.data.error);
			});
	});
}
export function createUserApi(request) {
	return new Promise((resolve, reject) => {
		API.post("create-account", request)
			.then((r) => {
				const user = r.data;
				resolve(user);
			})
			.catch((e) => {
				reject(e.response.data.error);
			});
	});
}
export function createPropertyApi(request) {
	return new Promise((resolve, reject) => {
		API.post("create-property", request)
			.then((r) => {
				const res = r.data;

				resolve(res);
			})
			.catch((e) => {
				reject(e.response.data.error);
			});
	});
}
export function archivePropertyApi(request) {
	return new Promise((resolve, reject) => {
		API.put("archive-property", request)
			.then((r) => {
				const res = r.data;
				resolve(res);
			})
			.catch((e) => {
				reject(e.response.data.error);
			});
	});
}
export function restorePropertyApi(request) {
	return new Promise((resolve, reject) => {
		API.put("restore-property", request)
			.then((r) => {
				const res = r.data;
				resolve(res);
			})
			.catch((e) => {
				reject(e.response.data.error);
			});
	});
}
export function uploadSmartContractApi(request) {
	return new Promise((resolve, reject) => {
		API.post("upload-smart-contract", request)
			.then((r) => {
				const res = r.data;

				resolve(res);
			})
			.catch((e) => {
				reject(e.response.data.error);
			});
	});
}
export function addPaymentApi(request) {
	return new Promise((resolve, reject) => {
		API.put("add-payment", request)
			.then((r) => {
				const res = r.data;

				resolve(res);
			})
			.catch((e) => {
				reject(e.response.data.error);
			});
	});
}
export function addRentPaymentApi(request) {
	return new Promise((resolve, reject) => {
		API.put("add-rent-payment", request)
			.then((r) => {
				const res = r.data;

				resolve(res);
			})
			.catch((e) => {
				reject(e.response.data.error);
			});
	});
}
export function addAdministrationFeePaymentApi(request) {
	return new Promise((resolve, reject) => {
		API.put("add-administration-fee-payment", request)
			.then((r) => {
				const res = r.data;

				resolve(res);
			})
			.catch((e) => {
				reject(e.response.data.error);
			});
	});
}
export function addPaymentDocumentApi(request) {
	return new Promise((resolve, reject) => {
		API.put("add-payment-document", request)
			.then((r) => {
				const res = r.data;

				resolve(res);
			})
			.catch((e) => {
				reject(e.response.data.error);
			});
	});
}
export function addFinancialStatementApi(request) {
	return new Promise((resolve, reject) => {
		API.put("add-financial-statement", request)
			.then((r) => {
				const res = r.data;

				resolve(res);
			})
			.catch((e) => {
				reject(e.response.data.error);
			});
	});
}
export function addSecurityDepositApi(request) {
	return new Promise((resolve, reject) => {
		API.put("add-security-deposit", request)
			.then((r) => {
				const res = r.data;

				resolve(res);
			})
			.catch((e) => {
				reject(e.response.data.error);
			});
	});
}
export function updateSecurityDepositApi(request) {
	return new Promise((resolve, reject) => {
		API.put("update-security-deposit", request)
			.then((r) => {
				const res = r.data;

				resolve(res);
			})
			.catch((e) => {
				reject(e.response.data.error);
			});
	});
}
export function addUtilityPaymentApi(request) {
	return new Promise((resolve, reject) => {
		API.put("add-utility-payment", request)
			.then((r) => {
				const res = r.data;

				resolve(res);
			})
			.catch((e) => {
				reject(e.response.data.error);
			});
	});
}
export function addDocumentApi(request) {
	return new Promise((resolve, reject) => {
		API.put("add-document", request)
			.then((r) => {
				const res = r.data;

				resolve(res);
			})
			.catch((e) => {
				reject(e.response.data.error);
			});
	});
}
export function addReportApi(request) {
	return new Promise((resolve, reject) => {
		API.put("add-report", request)
			.then((r) => {
				const res = r.data;

				resolve(res);
			})
			.catch((e) => {
				reject(e.response.data.error);
			});
	});
}
export function addProtocolApi(request) {
	return new Promise((resolve, reject) => {
		API.put("add-protocol", request)
			.then((r) => {
				const res = r.data;

				resolve(res);
			})
			.catch((e) => {
				reject(e.response.data.error);
			});
	});
}
export function updateAdditionalStepsApi(request) {
	return new Promise((resolve, reject) => {
		API.put("update-additional-steps", request)
			.then((r) => {
				const res = r.data;

				resolve(res);
			})
			.catch((e) => {
				reject(e.response.data.error);
			});
	});
}
export function updateDocumentActiveApi(request) {
	return new Promise((resolve, reject) => {
		API.put("update-document-active", request)
			.then((r) => {
				const res = r.data;

				resolve(res);
			})
			.catch((e) => {
				reject(e.response.data.error);
			});
	});
}
export function updateReportActiveApi(request) {
	return new Promise((resolve, reject) => {
		API.put("update-report-active", request)
			.then((r) => {
				const res = r.data;

				resolve(res);
			})
			.catch((e) => {
				reject(e.response.data.error);
			});
	});
}
export function updateFinancialStatementActiveApi(request) {
	return new Promise((resolve, reject) => {
		API.put("update-financial-statement-active", request)
			.then((r) => {
				const res = r.data;

				resolve(res);
			})
			.catch((e) => {
				reject(e.response.data.error);
			});
	});
}
export function updateSpecsApi(request) {
	return new Promise((resolve, reject) => {
		API.put("update-specs", request)
			.then((r) => {
				const res = r.data;

				resolve(res);
			})
			.catch((e) => {
				reject(e.response.data.error);
			});
	});
}
export function updateRentApi(request) {
	return new Promise((resolve, reject) => {
		API.put("update-rent", request)
			.then((r) => {
				const res = r.data;

				resolve(res);
			})
			.catch((e) => {
				reject(e.response.data.error);
			});
	});
}
export function updateDetailsApi(request) {
	return new Promise((resolve, reject) => {
		API.put("update-details", request)
			.then((r) => {
				const res = r.data;

				resolve(res);
			})
			.catch((e) => {
				console.log(e);
				reject(e.response.data.error);
			});
	});
}
export function updateMeterInformationApi(request) {
	return new Promise((resolve, reject) => {
		API.put("update-meter-information", request)
			.then((r) => {
				const res = r.data;

				resolve(res);
			})
			.catch((e) => {
				reject(e.response.data.error);
			});
	});
}
export function requestMeterPictureApi(request) {
	return new Promise((resolve, reject) => {
		API.post("request-meter-picture", request)
			.then((r) => {
				const res = r.data;

				resolve(res);
			})
			.catch((e) => {
				reject(e.response.data.error);
			});
	});
}
export function updateOwnerApi(request) {
	return new Promise((resolve, reject) => {
		API.put("update-owner", request)
			.then((r) => {
				const res = r.data;

				resolve(res);
			})
			.catch((e) => {
				reject(e.response.data.error);
			});
	});
}
export function generateCombinedAdministrationFeePaymentApi(request) {
	return new Promise((resolve, reject) => {
		API.post("generate-combined-administration-fee-payment", request)
			.then((r) => {
				const res = r.data;
				resolve(res);
			})
			.catch((e) => {
				reject(e.response.data.error);
			});
	});
}
export function updateAgencyApi(request) {
	return new Promise((resolve, reject) => {
		API.put("update-agency", request)
			.then((r) => {
				const res = r.data;

				resolve(res);
			})
			.catch((e) => {
				reject(e.response.data.error);
			});
	});
}
export function updateTemplatesApi(request) {
	return new Promise((resolve, reject) => {
		API.put("update-templates", request)
			.then((r) => {
				const res = r.data;

				resolve(res);
			})
			.catch((e) => {
				reject(e.response.data.error);
			});
	});
}
export function updateTenantApi(request) {
	return new Promise((resolve, reject) => {
		API.put("update-tenant", request)
			.then((r) => {
				const res = r.data;

				resolve(res);
			})
			.catch((e) => {
				reject(e.response.data.error);
			});
	});
}
export function addTenantDocumentApi(request) {
	return new Promise((resolve, reject) => {
		API.put("add-tenant-document", request)
			.then((r) => {
				const res = r.data;

				resolve(res);
			})
			.catch((e) => {
				reject(e.response.data.error);
			});
	});
}
export function addOwnerDocumentApi(request) {
	return new Promise((resolve, reject) => {
		API.put("add-owner-document", request)
			.then((r) => {
				const res = r.data;

				resolve(res);
			})
			.catch((e) => {
				reject(e.response.data.error);
			});
	});
}
export function uploadOwnerIdApi(request) {
	return new Promise((resolve, reject) => {
		API.put("upload-owner-id", request)
			.then((r) => {
				const res = r.data;

				resolve(res);
			})
			.catch((e) => {
				reject(e.response.data.error);
			});
	});
}
export function uploadTenantIdApi(request) {
	return new Promise((resolve, reject) => {
		API.put("upload-tenant-id", request)
			.then((r) => {
				const res = r.data;

				resolve(res);
			})
			.catch((e) => {
				reject(e.response.data.error);
			});
	});
}
export function addContactApi(request) {
	return new Promise((resolve, reject) => {
		API.put("add-contact", request)
			.then((r) => {
				const res = r.data;

				resolve(res);
			})
			.catch((e) => {
				reject(e.response.data.error);
			});
	});
}
export function addActivityApi(request) {
	return new Promise((resolve, reject) => {
		API.put("add-activity", request)
			.then((r) => {
				const res = r.data;

				resolve(res);
			})
			.catch((e) => {
				reject(e.response.data.error);
			});
	});
}
export function addAssociationApi(request) {
	return new Promise((resolve, reject) => {
		API.put("add-association", request)
			.then((r) => {
				const res = r.data;

				resolve(res);
			})
			.catch((e) => {
				reject(e.response.data.error);
			});
	});
}
export function updateAssociationApi(request) {
	return new Promise((resolve, reject) => {
		API.put("update-association", request)
			.then((r) => {
				const res = r.data;

				resolve(res);
			})
			.catch((e) => {
				reject(e.response.data.error);
			});
	});
}
export function removeAssociationApi(request) {
	return new Promise((resolve, reject) => {
		API.put("remove-association", request)
			.then((r) => {
				const res = r.data;

				resolve(res);
			})
			.catch((e) => {
				reject(e.response.data.error);
			});
	});
}
export function addMediaApi(request) {
	return new Promise((resolve, reject) => {
		API.put("add-media", request)
			.then((r) => {
				const res = r.data;

				resolve(res);
			})
			.catch((e) => {
				reject(e.response.data.error);
			});
	});
}
export function addTenantApi(request) {
	return new Promise((resolve, reject) => {
		API.put("add-tenant", request)
			.then((r) => {
				const res = r.data;

				resolve(res);
			})
			.catch((e) => {
				reject(e.response.data.error);
			});
	});
}
export function addLeaseContactApi(request) {
	return new Promise((resolve, reject) => {
		API.put("add-lease-contact", request)
			.then((r) => {
				const res = r.data;

				resolve(res);
			})
			.catch((e) => {
				reject(e.response.data.error);
			});
	});
}
export function updateTenantFormerApi(request) {
	return new Promise((resolve, reject) => {
		API.put("update-tenant-status", request)
			.then((r) => {
				const res = r.data;
				resolve(res);
			})
			.catch((e) => {
				reject(e.response.data.error);
			});
	});
}
export function addAccountApi(request) {
	return new Promise((resolve, reject) => {
		API.put("add-account", request)
			.then((r) => {
				const res = r.data;

				resolve(res);
			})
			.catch((e) => {
				reject(e.response.data.error);
			});
	});
}
export function updateFinancialStatusApi(request) {
	return new Promise((resolve, reject) => {
		API.put("update-financial-status", request)
			.then((r) => {
				const res = r.data;

				resolve(res);
			})
			.catch((e) => {
				reject(e.response.data.error);
			});
	});
}
export function addAgentApi(request) {
	return new Promise((resolve, reject) => {
		API.put("add-agent", request)
			.then((r) => {
				const res = r.data;

				resolve(res);
			})
			.catch((e) => {
				console.log(e);
				reject(e.response.data.error);
			})
			.catch((e) => {
				reject(e.response.data.error);
			});
	});
}
export function addCustomOwnerPlanApi(request) {
	return new Promise((resolve, reject) => {
		API.put("add-custom-owner-plan", request)
			.then((r) => {
				const res = r.data;

				resolve(res);
			})
			.catch((e) => {
				console.log(e);
				reject(e.response.data.error);
			})
			.catch((e) => {
				reject(e.response.data.error);
			});
	});
}
export function updateCustomOwnerPlanApi(request) {
	return new Promise((resolve, reject) => {
		API.put("update-custom-owner-plan", request)
			.then((r) => {
				const res = r.data;

				resolve(res);
			})
			.catch((e) => {
				console.log(e);
				reject(e.response.data.error);
			})
			.catch((e) => {
				reject(e.response.data.error);
			});
	});
}
export function removeCustomOwnerPlanApi(request) {
	return new Promise((resolve, reject) => {
		API.put("remove-custom-owner-plan", request)
			.then((r) => {
				const res = r.data;

				resolve(res);
			})
			.catch((e) => {
				console.log(e);
				reject(e.response.data.error);
			})
			.catch((e) => {
				reject(e.response.data.error);
			});
	});
}
export function updatePropertyCustomOwnerPlanApi(request) {
	return new Promise((resolve, reject) => {
		API.put("update-property-custom-owner-plan", request)
			.then((r) => {
				const res = r.data;

				resolve(res);
			})
			.catch((e) => {
				console.log(e);
				reject(e.response.data.error);
			})
			.catch((e) => {
				reject(e.response.data.error);
			});
	});
}
export function removeDocumentApi(request) {
	return new Promise((resolve, reject) => {
		API.put("remove-document", request)
			.then((r) => {
				const res = r.data;
				resolve(res);
			})
			.catch((e) => {
				reject(e.response.data.error);
			});
	});
}
export function removeActivityApi(request) {
	return new Promise((resolve, reject) => {
		API.put("remove-activity", request)
			.then((r) => {
				const res = r.data;
				resolve(res);
			})
			.catch((e) => {
				reject(e.response.data.error);
			});
	});
}
export function removeTicketApi(request) {
	return new Promise((resolve, reject) => {
		API.put("remove-ticket", request)
			.then((r) => {
				const res = r.data;
				resolve(res);
			})
			.catch((e) => {
				reject(e.response.data.error);
			});
	});
}
export function removeTenantDocumentApi(request) {
	return new Promise((resolve, reject) => {
		API.put("remove-tenant-document", request)
			.then((r) => {
				const res = r.data;

				resolve(res);
			})
			.catch((e) => {
				reject(e.response.data.error);
			});
	});
}
export function removeOwnerDocumentApi(request) {
	return new Promise((resolve, reject) => {
		API.put("remove-owner-document", request)
			.then((r) => {
				const res = r.data;

				resolve(res);
			})
			.catch((e) => {
				reject(e.response.data.error);
			});
	});
}
export function removePaymentApi(request) {
	return new Promise((resolve, reject) => {
		API.put("remove-payment", request)
			.then((r) => {
				const res = r.data;

				resolve(res);
			})
			.catch((e) => {
				reject(e.response.data.error);
			});
	});
}
export function removeSecurityDepositApi(request) {
	return new Promise((resolve, reject) => {
		API.put("remove-security-deposit", request)
			.then((r) => {
				const res = r.data;

				resolve(res);
			})
			.catch((e) => {
				reject(e.response.data.error);
			});
	});
}
export function removeFinancialStatementApi(request) {
	return new Promise((resolve, reject) => {
		API.put("remove-financial-statement", request)
			.then((r) => {
				const res = r.data;

				resolve(res);
			})
			.catch((e) => {
				reject(e.response.data.error);
			});
	});
}
export function removeReportApi(request) {
	return new Promise((resolve, reject) => {
		API.put("remove-report", request)
			.then((r) => {
				const res = r.data;

				resolve(res);
			})
			.catch((e) => {
				reject(e.response.data.error);
			});
	});
}
export function removeProtocolApi(request) {
	return new Promise((resolve, reject) => {
		API.put("remove-protocol", request)
			.then((r) => {
				const res = r.data;

				resolve(res);
			})
			.catch((e) => {
				reject(e.response.data.error);
			});
	});
}
export function removeMediaApi(request) {
	return new Promise((resolve, reject) => {
		API.put("remove-media", request)
			.then((r) => {
				const res = r.data;

				resolve(res);
			})
			.catch((e) => {
				reject(e.response.data.error);
			});
	});
}
export function markTenantPaymentAsPaidApi(request) {
	return new Promise((resolve, reject) => {
		API.put("mark-tenant-payment-as-paid", request)
			.then((r) => {
				const res = r.data;

				resolve(res);
			})
			.catch((e) => {
				reject(e.response.data.error);
			});
	});
}
export function markPaymentAsPaidApi(request) {
	return new Promise((resolve, reject) => {
		API.put("mark-payment-as-paid", request)
			.then((r) => {
				const res = r.data;

				resolve(res);
			})
			.catch((e) => {
				reject(e.response.data.error);
			});
	});
}
export function markMultiplePaymentAsPaidApi(request) {
	return new Promise((resolve, reject) => {
		API.put("mark-multiple-payments-as-paid", request)
			.then((r) => {
				const res = r.data;

				resolve(res);
			})
			.catch((e) => {
				reject(e.response.data.error);
			});
	});
}
export function markRentAsPaidApi(request) {
	return new Promise((resolve, reject) => {
		API.put("mark-rent-as-paid", request)
			.then((r) => {
				const res = r.data;

				resolve(res);
			})
			.catch((e) => {
				reject(e.response.data.error);
			});
	});
}
export function removeContactApi(request) {
	return new Promise((resolve, reject) => {
		API.put("remove-contact", request)
			.then((r) => {
				const res = r.data;

				resolve(res);
			})
			.catch((e) => {
				reject(e.response.data.error);
			});
	});
}
export function removeAccountApi(request) {
	return new Promise((resolve, reject) => {
		API.put("remove-account", request)
			.then((r) => {
				const res = r.data;

				resolve(res);
			})
			.catch((e) => {
				reject(e.response.data.error);
			});
	});
}
export function sendMassNotificationApi(request) {
	return new Promise((resolve, reject) => {
		API.post("send-mass-notification", request)
			.then((r) => {
				const res = r.data;

				resolve(res);
			})
			.catch((e) => {
				console.log(e);
				reject(e.response.data.error);
			});
	});
}
export function addMessageApi(request) {
	return new Promise((resolve, reject) => {
		API.post("add-message", request)
			.then((r) => {
				const res = r.data;
				resolve(res);
			})
			.catch((e) => {
				console.log(e);
				reject(e.response.data.error);
			});
	});
}
export function markTicketAsDoneApi(request) {
	return new Promise((resolve, reject) => {
		API.put("mark-ticket-as-done", request)
			.then((r) => {
				const res = r.data;

				resolve(res);
			})
			.catch((e) => {
				console.log(e);
				reject(e);
			});
	});
}
// Tenant requests

export function addTicketApi(request) {
	return new Promise((resolve, reject) => {
		API.post("add-ticket", request)
			.then((r) => {
				const res = r.data;

				resolve(res);
			})
			.catch((e) => {
				reject(e.response.data.error);
			});
	});
}
export function addReplyToTicketApi(request) {
	return new Promise((resolve, reject) => {
		API.put("reply-to-ticket", request)
			.then((r) => {
				const res = r.data;

				resolve(res);
			})
			.catch((e) => {
				reject(e.response.data.error);
			});
	});
}
export function addReplyToMessageApi(request) {
	return new Promise((resolve, reject) => {
		API.put("reply-to-message", request)
			.then((r) => {
				const res = r.data;

				resolve(res);
			})
			.catch((e) => {
				reject(e.response.data.error);
			});
	});
}
export function addUtilityMeterPictureApi(request) {
	return new Promise((resolve, reject) => {
		API.put("add-meter-picture", request)
			.then((r) => {
				const res = r.data;

				resolve(res);
			})
			.catch((e) => {
				reject(e.response.data.error);
			});
	});
}
